import { Dispatch } from 'react'

import { get, URL } from 'src/utilities/axios'
import { store } from 'src/redux/store'
import { OrderListResponse, OrdersResponse } from 'src/modals'
import { OrderActions } from 'src/redux/types'

export const fetchOrderList = (
  status: string,
  offset?: number,
  limit?: number,
) => {
  return async (dispatch: Dispatch<OrderActions>) => {
    try {
      const res: OrderListResponse = await get(URL.ORDERS, {
        status,
        offset,
        limit,
      })
      if (res.error === null) {
        res.data.orders.forEach(order => {
          order.customer.amount_total = order.amount_total
          order.customer.create_date = order.create_date
          order.customer.order_name = order.name
          order.customer.order_id = order.id
        })
        dispatch({
          type: 'SET_ORDER_LIST',
          payload: [...store.getState().orderReducer.list, ...res.data.orders],
        })
      }
    } catch (error) {
      dispatch({
        type: 'SET_ORDER_ERROR',
        payload: error,
      })
    }
  }
}

export const fetchOrderListByUrl = (
  url: string,
  status: string,
  offset?: number,
  limit?: number,
  params?: object,
) => {
  return async (dispatch: Dispatch<OrderActions>) => {
    try {
      const res: OrdersResponse = await get(url, {
        status,
        offset,
        limit,
        ...params,
      })
      if (res.error === null) {
        dispatch({
          type: 'SET_ORDER_LIST',
          payload: [...store.getState().orderReducer.list, ...res.data.items],
        })
        dispatch({
          type: 'SET_ORDER_COUNT',
          payload: res.data.count,
        })
      }
    } catch (error) {
      dispatch({
        type: 'SET_ORDER_ERROR',
        payload: error,
      })
    }
  }
}

export const resetOrderList = () => {
  return async (dispatch: Dispatch<OrderActions>) => {
    dispatch({
      type: 'RESET_ORDER_LIST',
    })
    dispatch({
      type: 'RESET_ORDER_COUNT',
    })
  }
}
