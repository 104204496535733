import { StyleSheet } from 'react-native'

// styles
import colors from 'styles/colors'

const styles = StyleSheet.create({
  productThumbnail: {
    height: 61,
    width: 61,
  },
  attributeContainer: {
    marginTop: 10,
  },
  attribute: {
    backgroundColor: colors.bgLightBlue,
    paddingVertical: 4,
    marginRight: 10,
  },
  inputSpinner: {
    shadowOpacity: 0,
    borderWidth: 1,
    borderColor: colors.bgGrey,
    padding: 6,
  },
  stepperText: {
    color: colors.primaryTextBlack,
    paddingHorizontal: 6,
  },
  moreVerticalThumbnail: {
    height: 24,
    width: 24,
  },
  option: {
    borderBottomWidth: 1,
    borderBottomColor: colors.borderGrey,
  },
})

export default styles
