export default {
  LOGIN: {
    WELCOME: 'Welcome to OMS Application',
    LOGOUT_MSG: 'Are you sure you want to log out?',
    LOGIN_VIA_KEYCLOAK: 'Login Via Keycloak',
  },
  OTA_UPDATE: {
    UPDATE_MSG: 'A new update is available, click',
    BTN_UPDATE: 'UPDATE',
  },
  HOME: {
    WELCOME: 'Welcome!',
    OUTSTANDING_SALES: 'Outstanding Sales',
    CREATE_ORDER: 'Create Order',
    CONFIRM_ORDER: 'Confirm Order',
    ORDER_HISTORY: 'Order History',
    CREATE_INVOICE: 'Create Invoice',
    REGENERATE_INVOICE: 'Regenerate Invoice',
    CREATE_CREDIT_NOTE: 'Create Credit Note',
    INVOICE_HISTORY: 'Invoice History',
    OUTSTANDING_INVOICES: 'Outstanding Invoices',
    UNBILLED_DISPATCH_VOUCHERS: 'Unbilled Dispatch Vouchers',
    INWARD_GRN: 'Inward GRN',
    GRN_HISTORY: 'GRN History',
    PENDING_GRN_NUMBER: 'Number of Pending GRNs',
    CREATE_PO: 'Create PO',
    VIEW_POS: 'View POs',
    UNBILLED_VOUCHERS_TODAY: 'Unbilled Vouchers Today',
    MANAGE_PICKER_ALLOCATION: 'Manage Picker Allocation',
    VIEW_ORDER_HISTORY: 'View Order History',
    VENDORS: 'Vendors',
    VENDOR_BILL: 'Vendor Bill',
  },
  CARD_TYPE: {
    LIST: 'list',
    CART: 'cart',
    DETAIL: 'detail',
    EDIT: 'edit',
  },
  FILTER_LIST: [
    {
      id: 1,
      name: 'All',
      isSelected: true,
    },
    {
      id: 2,
      name: 'Fruits',
      isSelected: false,
    },
    {
      id: 3,
      name: 'OPG',
      isSelected: false,
    },
    {
      id: 4,
      name: 'Fresh Vegetables',
      isSelected: false,
    },
  ],
  FILTER: {
    PO_STATUS: 'PO Status',
    COMPLETE_GRNS: 'Complete GRNs',
    IN_PROGRESS_GRNS: 'In Progress GRNs',
    BEFORE_TODAY: 'Before Today',
    TODAY: 'Today',
    TOMORROW: 'Tomorrow',
    NEXT_7_DAYS: 'Next 7 days',
    CUSTOM_DATE: 'Custom Date',
    DELIVERY_DATE: 'Delivery Date',
    BY_DEHAAT: 'By Dehaat',
    BY_SUPPLIER: 'By Supplier',
    QC_RESPONSIBILITY: 'QC Responsibility',
    YOURSELF: 'Yourself',
    MIS_TEAM: 'MIS Team',
    PO_CREATED_BY: 'PO Created By',
    FILTER_BY: 'Filter By',
    SELECT_PICKUP_MANAGER: 'Select Pickup Manager',
  },
  SORT: {
    A_TO_Z: {
      name: ' A to Z',
      value: 'aToz',
    },
    Z_TO_A: {
      name: ' Z to A',
      value: 'zToa',
    },
    DELIVERY_DATE: 'Delivery Date:',
    CREATED_DATE: 'Created Date:',
    LATEST_UPDATED_GRN_DATE: 'Latest Updated GRN Date:',
  },
  MISCELLANEOUS: {
    SELECTED: 'Selected',
    TOTAL_PRODUCTS: 'Total Products',
    PRODUCTS: 'Product(s)',
    AVAILABLE_VARIANTS: 'Available Variants',
    TOTAL: 'Total',
    UOM: 'Uom',
    OUTSTANDING_BALANCE: 'Outstanding Balance',
    ADDRESS_UPDATE: 'Address not updated',
    ORDER_ID: 'Order ID - ',
    FILLED: 'Filled',
    QTY: 'Qty',
    EMPTY_CART: 'Your cart is empty!',
    EMPTY_CART_MSG: 'Please create an order add products to the cart',
    DISPATCH: 'Dispatch',
    VOUCHERS: 'Vouchers',
    DATE_NOTE_AVAILABLE: 'Date not available',
    TIME_NOTE_AVAILABLE: 'Time not available',
    NOT_AVAILABLE: 'Not Available',
    DRAFT: 'draft',
    CANCEL: 'cancel',
    DOWNLOAD_SUCCESSFULLY: 'Downloaded Sucessfully',
    SORT_BY_DATE: 'Sort by Date',
    NO_CAMERA_ACCESS: 'No access to camera',
    WEIGHMENT_SLIP: 'Weighment slip',
    TRANSPORT_BILTY: 'Transport bilty',
    S3_IMAGE_UPLOAD_ERROR_MSG: 'Error in uploading to S3',
    SORT_BY: 'Sort By',
    OLD_TO_NEW: 'Old to New',
    NEW_TO_OLD: 'New to Old',
    WENT_WRONG: 'Something went wrong!',
    TRY_AGAIN: 'Please try again.',
    PO_ID_WITH_HYPHEN: 'PO ID - ',
    GRN_ID_WITH_HYPHEN: 'GRN ID - ',
    LOT_ID_WITH_HYPHEN: 'LOT ID - ',
    GRN_DOWNLOAD_MSG: 'GRN downloaded successfully!',
    VIEW_DETAILS: 'View Details',
    REMAINING_QTY: 'Remaining QTY',
    FILTERS: 'Filters',
    LOADING: 'Loading',
    DISPLAYING: 'Displaying',
    POS: 'POs',
    SORT: 'Sort',
    TOTAL_QUANTITY: 'Total Quantity',
    VENDOR_BILL_ID_WITH_HYPHEN: 'Vendor Bill ID - ',
    YES: 'Yes',
    NO: 'No',
    CONTACT_ERROR: 'Contact number must be 10 digits',
    EMAIL_ERROR: 'Email is not valid',
  },
  BUTTON: {
    PLACE_ORDER: 'Place Order',
    CONTINUE: 'Continue',
    SELECT_PRODUCT: 'Select Product',
    CREATE_ORDER: 'CREATE ORDER',
    TOTAL_ORDER_AMOUNT: 'Total Order Amount',
    CANCEL: 'Cancel',
    EDIT_ORDER: 'Edit Order',
    GO_TO_HOME: 'Go To Home',
    GO_TO_CART: 'Go to Cart',
    DONE: 'Done',
    ADD_MORE_VARIANTS: 'Add More Variants',
    REMOVE: 'Remove',
    YES: 'Yes',
    NO: 'No',
    CONFIRM: 'Confirm',
    EDIT: 'Edit',
    DELETE: 'Delete',
    UPDATE_ORDER: 'Update Order',
    FILL_DETAILS: 'Fill Details',
    SAVE_CHANGES: 'Save Changes',
    EDIT_PRODUCT: 'Edit Product',
    ADD_MORE_EXPENSES: 'Add More Expenses',
    CREATE_INVOICE: 'Create Invoice',
    DOWNLOAD_INVOICE: 'Download Invoice',
    REGENERATE_INVOICE: 'Regenerate Invoice',
    CREATE_CREDIT_NOTE: 'Create Credit Note',
    CONFIRM_CREDIT_NOTE: 'Confirm Credit Note',
    QUALITY_ISSUE: 'Quality Issue',
    PRICING_ISSUE: 'Pricing Issue',
    UPLOAD_BILTY_INVOICE: 'Upload Bilty & invoice to Inward GRN',
    UPLOAD_TRANSPORT_BILTY: 'Upload Transport Bilty',
    UPLOAD_VENDOR_INVOICE: 'Upload Vendor Invoice',
    INWARD_GRN: 'Inward GRN',
    GALLERY: 'Gallery',
    PREVIEW: 'Preview',
    CAMERA: 'Camera',
    RESCHEDULE: 'Reschedule',
    CANCEL_NO_RESCHEDULE: 'Cancel (no reschedule)',
    READ_MORE: 'Read more',
    READ_LESS: 'Read less',
    NEXT: 'Next',
    ADD_PARAMETER: 'Add Parameter',
    VIEW_FULL_ADDRESS: 'View full address',
    PREVIOUS: 'Previous',
    CREATE_PO: 'Create PO',
    DOWNLOAD_PO: 'Download PO',
    UPLOAD: 'Upload',
    RETAKE_PHOTO: 'Retake Photo',
    GO_TO_GALLERY: 'Go To Gallery',
    ALL: 'All',
    CLEAR_ALL: 'Clear All',
    APPLY: 'Apply',
    NO_RESULT: 'No Result',
    DOWNLOAD_GRN: 'Download GRN',
    CREATE_VENDOR_BILL_FOR_GRN: 'Create Vendor Bill for this GRN',
    CANCEL_ORDER: 'Cancel Order',
    VIEW_VENDOR_BILL_SUMMARY: 'View Vendor Bill Summary',
    ADD_MORE_SERVICES: 'Add More Services',
    SAVE_AS_DRAFT: 'Save as draft',
    VIEW_DETAILS: 'View Details',
    CREATE_VENDOR_BILL: 'Create Vendor Bill',
    CREATE: 'Create',
    CREATE_MORE_VENDOR_BILL: 'Create More Vendor Bill',
    DOWNLOAD_VENDOR_BILL: 'Download Vendor Bill',
  },
  ORDER: {
    NO_ORDER: 'No Orders',
    CANCEL_WARNING:
      'Are you sure you want to cancel this Order? This action can’t be undone.',
    ORDER_OF: 'Order of',
    DELETE_WARNING:
      'Are you sure you want to delete this product? This action can’t be undone.',
    ITEM_PRESENT_MSG: 'Items already in cart',
    DELETE_CART_ITEMS_WARNING:
      'Your cart contains products from a different order. Would you like to reset your cart before creating this order?',
    IN_PROGRESS: 'In-Progress',
    PAST_ORDERS: 'Past Orders',
    DELIVERED: 'Delivered',
    DELIVERY_TIME: 'Delivery Time',
    IN: 'in',
    PROGRESS: 'Progress',
  },
  SCREEN_TITLE: {
    ORDERS: 'Orders',
    ORDER_DETAILS: 'Order Details',
    CART: 'Cart',
    CREATE_ORDER: 'Create Order',
    PRODUCT_DETAILS: 'Product Details',
    SELECT_PRODUCTS: 'Select Products',
    EDIT_ORDER: 'Edit Order',
    ORDER_HISTORY: 'Order History',
    UNBILLED_DISPATCH_VOUCHERS: 'Unbilled Dispatch Vouchers',
    REGENERATE_INVOICE: 'Regenerate Invoice',
    VOUCHER_LISTING: 'Voucher Listing',
    INVOICE_LISTING: 'Invoice Listing',
    INVOICE_DETAILS: 'Invoice Details',
    PENDING_GRNS: 'Pending GRNs',
    PO_LISTING: 'PO Listing',
    PENDING_GRN_DETAILS: 'Pending GRN Details',
    UPLOAD_BILTY_AND_INVOICE: 'Upload Bilty & Invoice',
    RESCHEDULE: 'Reschedule',
    CREATE_PO: 'Create PO',
    VIEW_POS: 'View POs',
    GRN_DETAILS: 'GRN Details',
    UPLOAD_DOCUMENTS: 'Upload Documents',
    TRANSPORT_DETAILS: 'Transport Details',
    PENDING_GRN_LISTING: 'Pending GRN Listing',
    RESCHEDULE_OR_CANCEL_ORDER: 'Reschedule or Cancel Order',
    VENDOR_LISTING: 'Vendor Listing',
    ADD_VENDOR: 'Add Vendor',
    CREATE_VENDOR_BILL: 'Create Vendor Bill',
    VENDOR_BILL_SUMMARY: 'Vendor Bill Summary',
    VOUCHER_DETAILS: 'Voucher Details',
  },
  SUCCESS: {
    CONGRATULATION: 'Congratulations!',
    ORDER_CREATED: 'Your Order has been created successfully.',
    ORDER_CONFIRM: 'Your Order has been confirmed successfully.',
    ORDER_CANCEL: 'Your Order has been cancelled successfully',
    ORDER_UPDATE: 'Your Order has been updated successfully',
    TRANSPORT_BILTY_UPLOADED: 'Transport bilty uploaded successfully!',
    VENDOR_INVOICE_UPLOADED: 'Vendor Invoice uploaded successfully!',
    WEIGHMENT_SLIP_UPLOADED: 'Weighment Slip Uploaded successfully!',
    SUCCESS: 'success',
  },
  FORM_FIELDS: {
    SELECT_VARIANT: 'Select Variant*',
    ENTER_QUANTITY: 'Enter Quantity',
    ENTER_PRICE: 'Enter Price*',
    ENTER_PRICE_WITHOUT_ASTERISK: 'Enter Price',
    INCOMPLETE_FIELD_WARNING:
      'To go to the cart, you need to fill in all products details or delete the product.',
    SELECT_A_OPTION: 'Select an option',
    ADD_DESCRIPTION: 'Add Description',
    ENTER_TRANSPORT_BILTY: 'Enter Transport Bilty Number*',
    BILTY_PLACEHOLDER: 'TB123465',
  },
  ORDER_DETAILS: {
    SHOW_MORE: 'Show More',
    VOUCHER_DATE: 'Vocher Date',
    VOUCHER_TIME: 'Vocher Time',
    TOTAL_AMOUNT: 'Total Amount',
    READY_FOR_PICKUP: 'Ready for Pickup',
    CANCEL_VOUCHER: 'Cancel Voucher',
    VOUCHER_ID: 'Voucher ID',
    CANCEL_VOUCHER_WARNING: `Are you sure you want to delete this voucher? This action can't be undone.`,
  },
  UNBILLED_VOUCHERS: {
    VOUCHER_ID: 'Voucher ID',
    VOUCHER_DATE: 'Voucher Date',
    VOUCHER_TIME: 'Voucher Time',
    DISPATCH_VOUCHERS: 'Dispatch Vouchers',
    RECENT: 'Recent',
    SCHEDULED_DATE: 'scheduled_date',
  },
  VOUCHER: {
    VOUCHER_DETAILS: 'Voucher Details',
    MORE_DETAILS: 'More Details',
    CUSTOMER_DETAILS: 'Customer Details',
    SALES_MANAGER_DETAILS: 'Sales Manager Details',
    ADDITIONAL_NOTE: 'Additional Note',
    NAME: 'Name',
    SO_ID: 'SO ID',
    AMOUNT: 'Amount',
    UOM: 'UOM-',
    VOUCHER_ID: 'Voucher ID',
    VOUCHER_DATE: 'Voucher Date',
    VOUCHER_TIME: 'Voucher Time',
    ORDERED: 'Ordered',
    DONE: 'Done',
    UNIT_PRICE: 'Unit Price',
    CONTINUE_TO_INVOICE: 'Continue to Create Invoice',
    CONTACT_NUMBER: 'Contact Number',
    DELIVERY_LOCATION: 'Delivery location',
    UPDATE_BANK: 'Update Bank',
    BILLING_LOCATION: 'Billing Location',
    ADD_ADDITIONAL_EXPENSES: 'Add additional expenses',
    ADD_NOTE: 'Add Note ',
    EDIT_NOTE: 'Edit Note',
    NOTE_MAX_LENGTH: '(500 words max)',
    NOTE_INFO: ' You can view this note in more detail section',
    RESET_PRICE: 'Reset all Unit Price Value to Default',
    CANCEL: 'Cancel',
    DELETE_NOTE:
      'Are you sure you want delete this note? This action cannot be undone.',
    CREATE_INVOICE_WARNING:
      'Are you sure you want to create this invoice? This action can’t be undone.',
    REGENERATE_INVOICE_WARNING:
      'Are you sure you want to regenerate this invoice? This action can’t be undone.',
    NOTES_CHECK: 'By You (Not Saved)',
    VIEW_INVOICE: 'View Invoice',
  },
  NOTES_MAPPING: [
    { key: 'dispatch_voucher', value: 'By You (Sales Biller)' },
    { key: 'sale_order', value: 'By Order Pickup Executive' },
  ],
  INVOICE: {
    INVOICE: 'Invoice',
    INVOICES: 'Invoices',
    INVOICE_ID: 'Invoice ID',
    INVOICE_DATE: 'Invoice Date',
    INVOICE_TIME: 'Invoice Time',
    INVOICES_FOR: 'Invoice for ',
    INVOICES_CREATED: ' has been created successfully.',
    INVOICE_ID_HYPHNE: 'Invoice ID - ',
    CREDIT_NOTE: 'Credit Note',
    INVOICE_DETAILS: 'Invoice Details',
    INVOICE_DOWNLOADED: 'Invoice downloaded successfully!',
    MORE_DETAILS: 'More Details',
    CONTINUE_TO_REGENERATE_INVOICE: 'Continue to Regenerate Invoice',
    CREDIT_NOTE_DOWNLOADED: 'Credit Note downloaded successfully',
  },
  CREDIT_NOTE: {
    SELECT_REASON: 'Select Reason',
    CREATE_CREDIT_NOTE:
      'Are you sure you want to create this credit note? To complete this process, you also need to create a new invoice against this credit note.',
    CREDIT_NOTE_AND_INVOICE: 'Your Credit Note & a new Invoice for ',
  },
  GRNS: {
    GRN: 'GRN',
    TOTAL_PENDING_GRNS: 'Total Pending GRNs',
    GRN_DATE: 'GRN Date',
    GRN_TIME: 'GRN Time',
    AMOUNT: 'Amount',
    GRN_DETAILS: 'GRN Details',
    UNIT_PRICE: 'U/Price',
    DEMAND: 'Demand',
    RECEIVED: 'Received',
    VENDOR_NAME: 'Vendor Name',
    PO_ID: 'PO ID',
    LOCATION: 'Location',
    QUANTITY_MISMATCH: 'Demanded Qty is not matching with the Received Qty',
    DELETE_DOCUMENT:
      'Are you sure you want delete this document? This action cannot be undone.',
    TRANSPORT_BILTY_UPLOADED: 'Transport Bilty Uploaded',
    MANDATORY_TRANSPORT_BILTY: `It's mandatory to upload transport bilty`,
    VENDOR_INVOICE_UPLOADED: 'Vendor Invoice Uploaded',
    UPLOAD_VENDOR_INVOICE: 'Upload Vendor Invoice',
    OPTIONAL_VENDOR_INVOICE: `It's optional. Skip if you don't have a vendor invoice`,
    RESCHEDULE_READ_MORE:
      'Reschedule if you expect to Inward the remaining products later.' +
      ' A Pending GRN will automatically get created.',
    CANCEL_RESCHEDULE_READ_MORE:
      `Cancel if you don't want the remaining products in the Inventory.` +
      ' You can view the cancelled GRN in the GRN History section.',
    RESCHEDULE_HEADER_MSG:
      'You have received fewer items/units than original demand.',
    PROC_RESCHEDULE_HEADER_MSG:
      'You have received fewer items/units than the original demand.',
    UPLOAD_WEIGHMENT_SLIP: 'Upload Weighment Slip',
    WEIGHMENT_SLIP_UPLOADED: 'Weighment Slip Uploaded',
    TRANSPORT_BILTY_CHECKBOX_INFO:
      'Only applicable for Mandi operations or if the vendor is a farmer.',
    PENDING_GRN_QUANTITY: 'Pending GRN Quantity',
    INWARD_GRN_WARNING: (type: string) =>
      `Are you sure you want to ${type} the` +
      ` remaining order?  This action cannot be undone!`,
    RESCHEDULE: 'reschedule',
    RECEIVED_QUANTITY_MORE_THAN_DEMANDED:
      'Received Qty is more than the Demanded Qty.',
    COMPLETED_GRNS: 'Completed GRNs',
    GRN_DATE_AND_TIME: 'GRN Date & Time',
    GRN_ID: 'GRN ID',
    INWARD_GRN: 'Inward GRN',
  },
  PURCHASE_ORDER: {
    TOTAL_POS: 'Total POs',
    TOTAL_AMOUNT: 'Total Amount',
    TOTAL_GRNS: 'Total GRNs',
    LAST_UPDATED_GRN_DATE: 'Last Updated GRN Date',
    LAST_UPDATED_GRN_TIME: 'Last Updated GRN Time',
    VENDOR_TAB_TITLE: 'Vendor',
    PRODUCT_TAB_TITLE: 'Product',
    QC_TAB_TITLE: 'QC',
    LOCATION_TAB_TITLE: 'Location',
    QC_RESPONSIBILITY_OWNERSHIP: 'QC Ownership',
    QC_PARAMETERS: 'QC Parameters',
    QC_PARAMETER: 'QC Parameter',
    VALUE: 'Value',
    PRODUCT_NAME: 'Product Name',
    QUANTITY: 'Quantity',
    TAX: 'Tax',
    PRICE_PER_UNIT: 'Price/Unit',
    NODE: 'Node',
    CONTINUE_TO_INWARD_GRN: 'Continue to Inward GRN',
    DELIVERY_DATE: 'Delivery Date',
    ACTUAL: 'Actual',
    PO_ID_WITHOUT_COLON: 'PO ID',
    PO_ID: 'PO ID: ',
    QC_RESPONSIBILITY: 'QC RESPONSIBILITY',
    PAYMENT_TERMS: 'Payment Terms',
    VENDOR_NAME: 'Vendor Name',
    PICKUP_LOCATION: 'Pickup Location',
    CUSTOMER_DELIVERY_LOCATION: 'Customer Delivery Location',
    DEHAAT_WAREHOUSE_LOCATION: 'DeHaat Warehouse Location for GRN',
    PICKUP_DATE: 'Pickup Date',
    PO_CREATION_SUCCESS_MSG: {
      START: 'PO for ',
      END: ' is successfully created',
    },
    ALL_POS: 'All POs',
    SEARCH_BY_VENDOR: 'Search by PO, vendor name or product name',
    VALUE_MISMATCH: 'Entered value is not matching with the required value',
    WARNING_MSG:
      `Are you sure to discard the GRN\n` +
      `process? If you select yes, the filled data\n` +
      `will be lost.`,
    TRANSPORT_BILTY_INFO: `Select if you don't have the transport bilty`,
    INWARD_GRN_CONFIRMATION_MSG:
      `Are you sure you want to Inward ` +
      `this GRN? This action cannot be undone!`,
    SELECT_ADVANCED_PAYMENT:
      'Did the Vendor provide advance payment to the\nTransporter?',
    TRANSPORTER_NAME: 'Transporter Name',
    TRANSPORT_NAME_SNAKE_CASE: 'transport_name',
    ENTER_TRANSPORTER_NAME: 'Enter Transporter Name',
    VEHICLE_NUMBER: 'Vehicle Number',
    VEHICLE_NUMBER_SNAKE_CASE: 'vehicle_number',
    ENTER_VEHICLE_NUMBER: 'Enter Vehicle Number',
    TRANSPORT_INVOICE_NUMBER: 'Transport Invoice Number (optional)',
    TRANSPORT_INVOICE_NUMBER_SNAKE_CASE: 'transport_invoice_number',
    ENTER_TRANSPORT_INVOICE_NUMBER: 'Enter Transport Invoice Number',
    DRIVER_NAME: 'Driver Name (optional)',
    DRIVER_NAME_SNAKE_CASE: 'driver_name',
    ENTER_DRIVER_NAME: 'Enter Driver Name',
    DRIVER_NUMBER: 'Driver Number (optional)',
    DRIVER_NUMBER_SNAKE_CASE: 'driver_number',
    ENTER_DRIVER_NUMBER: 'Enter Driver Number',
    ADVANCE_PAYMENT: 'Advance Payment',
    ADVANCE_PAYMENT_SNAKE_CASE: 'advance_payment',
    ENTER_ADVANCE_PAYMENT: '₹Enter Advance Payment',
    QC_BY: 'QC by',
    RECIEVED_QTY_MISMATCH: 'Received Qty is more than the Demanded Qty.',
  },
  CREATE_PO: {
    SELECT_NODE: 'Select Node',
    NODE: 'Node',
    SELECT_VENDOR: 'Select Vendor',
    VENDOR: 'Vendor',
    PAYMENT_TERMS: 'Payment Terms',
    SELECT_PAYMENT_TERMS: 'Select Payment Terms',
    SEARCH_BY_NODE_NAME: 'Search by node name',
    SEARCH_BY_VENDOR_NAME: 'Search by vendor name',
    SEARCH_BY_PAYMENT_TERMS: 'Search by payment terms',
    SEARCH_BY: 'Search By',
    SELECT_QC_RESPONSIBILITY: 'Select QC Responsibility',
    QC_PARAMETERS: 'QC Parameters',
    NCDEX_PARAMETERS: 'NCDEX Parameters',
    NCDEX_INFO:
      `The National Commodity & Derivatives Exchange (NCDEX) is one ` +
      `of the top commodity exchanges in India. They have their ` +
      `standard QC parameters. If you select NCDEX parameters, ` +
      `you don't need to choose QC Parameters separately.`,
    SELECT_PRODUCT: 'Select Product',
    SEARCH_BY_PRODUCT_NAME: 'Search by product name',
    PRODUCT: 'Product',
    QUANTITY: 'Quantity',
    ENTER_QUANTITY: 'Enter Quantity',
    SELECT_UOM: 'Select UOM',
    SEARCH_BY_UOM: 'Search by UOM name',
    UOM_ERROR:
      'Are you sure you want to change the UOM? We will suggest to use the default UOM.',
    PRICE_UNIT: 'Price/Unit',
    ENTER_PRICE_UNIT: 'Enter Price/Unit',
    TAX: 'Tax%',
    SELECT_TAX: 'Select Tax',
    SEARCH_BY_TAX_NAME: 'Search by tax name',
    ERROR_QUANTITY_ZERO: 'Please enter quantity more than 0',
    UOM: 'UOM',
    PICKUP_LOCATION: 'Pickup Location',
    SELECT_PICKUP_LOCATION: 'Select Pickup Location',
    PICKUP_DATE: 'Pickup Date',
    DELIVERY_DATE: 'Delivery Date',
    DEHAAT_WAREHOUSE_LOCATION: 'DeHaat Warehouse Location',
    SELECT_DEHAAT_WH_LOC: 'Select DeHaat Warehouse Location',
    DEHAAT_WH_LOC_GRN: 'DeHaat Warehouse Location for GRN',
    SELECT_DEHAAT_WH_LOC_GRN: 'Select DeHaat Warehouse Location for GRN',
    CUSTOMER_DELIVERY_LOCATION: 'Customer Delivery Location',
    SELECT_DELIVERY_LOCATION: 'Select Delivery Location',
    SELECT_DELIVERY_DATE: 'Select Delivery Date',
    SELECT_CUST_DELI_LOC: 'Select Customer Delivery Location',
    SEARCH_LOC_PLACEHOLDER: 'Search by customer name & delivery address',
    CREATE_PO_BACK:
      `Are you sure to discard the PO` +
      ` creation process? If you select yes, the filled data will be lost.`,
    PO_DOWNLOAD_MSG: 'PO downloaded successfully!',
    NCDEX_PARAM_MATCH: 'Matches NCDEX parameters',
  },
  SEARCH_PO: {
    NO_PO_CREATED: 'No PO Created Yet!',
    PLEASE_CREATE_A_PO: "You haven't created any PO,\nplease create one",
    NO_PO_FOUND: 'No PO Found!',
    CHANGE_SEARCH_PARAMETERS: `For better results,\ntry to change your search inputs.`,
  },
  INWARD_GRN: {
    PENDING_GRNS: 'Pending GRNs',
    PRODUCTS_FROM: 'Products from ',
    SUCCESS_MSG:
      ` are \n` +
      ` successfully added to the warehouse inventory \n` +
      ` & \n` +
      `A Pending GRN for this PO created successfully.`,
    SUCCESS_MSG_FOR_CANCEL_RESCEDULE:
      ` are \n` +
      ` successfully added to the warehouse inventory \n` +
      ` & \n` +
      `The remaining qty is canceled successfully.`,
  },
  VENDOR_LISTING: {
    ADD_NEW_VENDOR: 'Add New Vendor',
  },
  ADD_VENDOR: {
    VENDOR_TAB_TITLE: 'Vendor',
    DOCS_TAB_TITLE: 'Docs',
    ADDRESS_TAB_TITLE: 'Address',
    BANK_TAB_TITLE: 'Bank',
    SELECT_BUSINESS_TYPE: 'Select Business Type',
    IS_GST_REGISTERED: 'Is the vendor GST Registered?',
    FRESH_BUSINESS: 'Fresh Business',
    DRY_BUSINESS: 'Dry Business',
    VENDOR_TYPE: 'Vendor Type',
    NODE: 'Node',
    SELECT_VENDOR_TYPE: 'Select Vendor Type',
    SEARCH_BY_VENDOR_TYPE: 'Search by Vendor Type',
    SELECT_NODE: 'Select Node',
    SEARCH_BY_NODE_NAME: 'Search by Node name',
    VENDOR_CONTACT_NUMBER: "Vendor's Contact Number",
    CONTACT_PLACEHOLDER: "Enter Vendor's Contact No",
    VENDOR_EMAIL: "Vendor's Email",
    EMAIL_PLACEHOLDER: "Enter Vendor's Email",
  },
  VENDOR_BILL: {
    VENDOR_BILL: 'Vendor Bill',
    CREATE_VENDOR_BILL: 'Create Vendor Bill',
    VIEW_VENDOR_BILL: 'View Vendor bill',
    INWARDED: 'Inwarded',
    DOCUMENTS: 'Documents',
    CREATE_VENDOR_BILL_FOR_ALL_GRNS: (total: number) =>
      `Create Vendor Bill for all ${total} GRNs`,
    DOCUMENT_DOWNLOADED: 'Document downloaded successfully!',
    GRN_SELECTED: ' GRN Selected',
    UPLOAD_MSG: `Upload Vendor Invoice or \n Purchase Voucher`,
    SUCCESSFULLY_UPLOADED_MSG: `Vendor Invoice or \n Purchase Voucher Uploaded`,
    DOCUMENT_UPLOADED_SUCCESSFULLY: 'Document Uploaded Successfully',
    VENDOR_BILL_CONFIRMATION_MSG:
      `Are you sure you want to change GRN\n` +
      `selection? If you select yes, the filled data\n` +
      `will be lost.`,
    PAYMENT_TERMS: 'Payment Terms: ',
    VENDOR_INVOICE_NUMBER: 'Vendor Invoice Number',
    VENDOR_INVOICE_DATE: 'Vendor Invoice Date',
    COMMENTS: 'Comments (optional)',
    SELECTED_GRN_DETAILS: 'Selected GRN Details',
    CHANGE_SELECTION: 'Change Selection',
    OK: 'Ok',
    ADD_YOUR_COMMENTS: 'Add your comments here',
    SELECT_INVOICE_DATE: 'Select Vendor Invoice Date',
    ENTER_INVOICE_NUMBER: 'Enter Vendor Invoice Number',
    PRODUCT_AMOUNT: 'Product Amount',
    ADVANCE_PAYMENT: 'Advance Payment',
    COMMISSION: 'Commission',
    TOTAL_TAXES: 'Total Taxes',
    GRAND_TOTAL: 'Grand Total',
    VENDOR_BILL_FOR: 'Vendor Bill for ',
    SUCCESS_MSG: ` is \n successfully created`,
    TAX_DETAILS: 'Tax Details',
    CREATE_VENDOR_BILL_CONFIRMATION_MSG:
      `Are you sure you want to Create this\n` +
      `Vendor Bill? This action cannot be\n` +
      `undone!`,
    CANCELLED_ORDER_DISCLAIMER: 'The Remaining Order has been cancelled',
    QTY_CANCELLED: 'Qty Cancelled: ',
    AMOUNT: 'Amount: ',
  },
  DRAFT_PO: {
    CREATE_DRAFT_PO:
      'Are you sure to save this in the draft and create this PO later?',
    SAVE_DRAFT_SUCCESS_MSG:
      'Po successfully saved in draft. You can access the same from View POs.',
  },
}
