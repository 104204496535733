import React, { useState, useEffect } from 'react'
import BouncyCheckbox from 'react-native-bouncy-checkbox'

import { Product } from 'src/modals'

// styles
import global from 'styles/global'
import colors from 'styles/colors'
import styles from './style'

interface CheckboxProps {
  data: Product
  onSelect: Function
  selected?: boolean
}

const Checkbox = ({ data, onSelect, selected = false }: CheckboxProps) => {
  const [checkbox, setCheckbox] = useState<boolean>(selected)

  const toggleCheckbox = (isSelected: boolean) => {
    setCheckbox(isSelected)
    onSelect(data.id)
  }

  useEffect(() => {
    if (selected) setCheckbox(selected)
  }, [selected])

  useEffect(() => {
    setCheckbox(!(data.isSelected === undefined || data.isSelected === false))
  }, [data.isSelected])

  return (
    <BouncyCheckbox
      onPress={() => toggleCheckbox(!checkbox)}
      iconStyle={[styles.checkbox]}
      fillColor={colors.secondaryOrange}
      style={[styles.checkBoxContainer, global.positionAbsolute]}
      isChecked={checkbox}
      disableBuiltInState
    />
  )
}

export default Checkbox
