import * as AuthSession from 'expo-auth-session'
import { KEYCLOAK_URL, KEYCLOAK_CLIENT_ID } from 'react-native-dotenv'
import axios from 'axios'
import AsyncStorage from '@react-native-async-storage/async-storage'

export const getRealmURL = config => {
  const { url, realm } = config
  const slash = url.endsWith('/') ? '' : '/'
  return `${url + slash}realms/${encodeURIComponent(realm)}`
}

export function getCurrentTimeInSeconds() {
  return Math.floor(Date.now() / 1000)
}

export const handleTokenExchange = async ({ response, discovery, config }) => {
  try {
    if (response?.type === 'success' && !!discovery?.tokenEndpoint) {
      const token = await AuthSession.exchangeCodeAsync(
        { code: response.params.code, ...config },
        discovery,
      )
      return token
    }
    if (response?.type === 'error') {
      return null
    }
    return null
  } catch (error) {
    return null
  }
}

export const handleInterval = (() => {
  let intervalId = null
  return {
    set: siID => {
      intervalId = siID
    },
    get: () => intervalId,
  }
})()

export const autoUpdateToken = async () => {
  try {
    const token = await AsyncStorage.getItem('refreshToken')
    if (token) {
      const formdata = new URLSearchParams()
      formdata.append('grant_type', 'refresh_token')
      formdata.append('client_id', KEYCLOAK_CLIENT_ID)
      formdata.append('refresh_token', token)
      axios({
        method: 'post',
        url: `${KEYCLOAK_URL}/realms/dehaat/protocol/openid-connect/token`,
        data: formdata,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
        .then(response => {
          AsyncStorage.setItem('accessToken', response.data?.access_token)
          AsyncStorage.setItem('refreshToken', response.data?.refresh_token)
        })
        .catch(err => {
          clearInterval(handleInterval.get())
          console.error(err)
        })
    }
  } catch (err) {
    console.error(err)
  }
}
