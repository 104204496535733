import { Customer } from 'src/modals'
import { CustomerActions } from 'src/redux/types'

type CustomersState = {
  list: Customer[]
  customer: Customer
  error: string | null
}

const initialState = {
  list: [] as Customer[],
  customer: {} as Customer,
  error: null,
}

const CustomersReducer = (
  state: CustomersState = initialState,
  action: CustomerActions,
) => {
  switch (action.type) {
    case 'SET_CUSTOMER_LIST': {
      return {
        ...state,
        list: action.payload,
      }
    }
    case 'SET_CUSTOMER': {
      return {
        ...state,
        customer: action.payload,
      }
    }
    case 'SET_CUSTOMER_ERROR': {
      return {
        ...state,
        error: action.payload,
      }
    }
    default:
      return state
  }
}

export { CustomersReducer }
