import { StyleSheet } from 'react-native'
import colors from 'styles/colors'

const styles = StyleSheet.create({
  footer: {
    paddingVertical: 12,
  },
  footerBtn: {
    height: 45,
    minHeight: 50,
  },
  successIcon: {
    width: 200,
    height: 200,
  },
  drawerButtonBorder: {
    borderBottomWidth: 1,
    borderBottomColor: colors.lightGrey,
  },
  iconCancel: {
    width: 10,
    height: 10,
  },
})

export default styles
