import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  footerBtn: {
    height: 45,
    maxHeight: 50,
  },
})

export default styles
