import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  imagestyle: {
    width: 60,
    height: 60,
  },
  headerStyle: {
    lineHeight: 27,
  },
  descriptionStyle: {
    lineHeight: 18,
  },
  buttonStyle: {
    width: 280,
    height: 45,
  },
})

export default styles
