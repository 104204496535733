import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Animated, Dimensions, View, Image } from 'react-native'
import { NativeBaseProvider } from 'native-base'
import { TabView, TabBar, SceneMap } from 'react-native-tab-view'
import tw from 'tailwind-react-native-classnames'

import { ApplicationState } from 'src/redux/types'
import STRINGS from 'constants/strings'
import { ProcVendorGRNDetailsProps } from 'src/navigations/types'
import { fetchGRNDetailsByPOId } from 'src/redux/actions'

// components
import ToastNotification from 'src/components/ToastNotification'
import PrimaryDetails, { ProcDetailsProps } from './PrimaryDetails'
import MoreDetails from './MoreDetails'

// styles
// eslint-disable-next-line import/order
import global from 'styles/global'
import style from './style'

const renderScene = SceneMap({
  primaryDetails: ({ route }: ProcDetailsProps) => (
    <PrimaryDetails route={route} />
  ),
  moreDetails: ({ route }: ProcDetailsProps) => <MoreDetails route={route} />,
})

interface RenderLabelRoute {
  route: {
    index: number
    title: string
  }
  focused: boolean
}

const GRNDetails = ({ route, navigation }: ProcVendorGRNDetailsProps) => {
  const [index, setIndex] = useState<number>(0)
  const { id } = route.params
  const dispatch = useDispatch()

  const { grnDetailsByPO } = useSelector(
    (state: ApplicationState) => state.purchaseOrder,
  )

  useEffect(() => {
    if (id) dispatch(fetchGRNDetailsByPOId(id))
  }, [dispatch, id])

  useEffect(() => {
    return () => {
      dispatch({
        type: 'SET_GRN_DETAILS_BY_PO',
        payload: {},
      })
    }
  }, [dispatch])

  const getImage = (focused: boolean, title: string) => {
    if (focused) {
      if (title === STRINGS.GRNS.GRN_DETAILS) {
        return (
          <Image
            source={require('images/confirm-order.png')}
            style={tw`w-3.5 h-3.5`}
          />
        )
      }
      return (
        <Image
          source={require('images/more-details.png')}
          style={tw`w-3.5 h-3.5`}
        />
      )
    }
    if (title === STRINGS.GRNS.GRN_DETAILS) {
      return (
        <Image
          source={require('images/page-grey.png')}
          style={tw`w-3.5 h-3.5`}
        />
      )
    }
    return (
      <Image
        source={require('images/more-details-disabled.png')}
        style={tw`w-3.5 h-3.5`}
      />
    )
  }

  const renderTabBar = (props: any) => (
    <TabBar
      {...props}
      style={global.bgWhite}
      // TODO: Provide different name to the route variable
      // eslint-disable-next-line @typescript-eslint/no-shadow
      renderLabel={({ route, focused }: RenderLabelRoute) => {
        return (
          <View style={tw`flex-row`}>
            {getImage(focused, route.title)}
            <Animated.Text
              style={
                focused
                  ? [global.textPrimaryBlack, global.textMedium, tw`ml-1`]
                  : [global.textSecondaryGrey, global.textMedium, tw`ml-1`]
              }
            >
              {route.title}
            </Animated.Text>
          </View>
        )
      }}
      indicatorStyle={style.tabIndicator}
    />
  )

  const routes = [
    {
      key: 'primaryDetails',
      title: `${STRINGS.GRNS.GRN_DETAILS}`,
      grnDetailsByPO,
      navigation,
    },
    {
      key: 'moreDetails',
      title: `${STRINGS.VOUCHER.MORE_DETAILS}`,
      grnDetailsByPO,
    },
  ]

  return (
    <NativeBaseProvider>
      <ToastNotification />
      <TabView
        navigationState={{ index, routes }}
        renderScene={renderScene}
        renderTabBar={renderTabBar}
        onIndexChange={setIndex}
        initialLayout={{
          width: Dimensions.get('window').width,
        }}
      />
    </NativeBaseProvider>
  )
}

export default GRNDetails
