import { StyleSheet } from 'react-native'
import colors from 'styles/colors'

const styles = StyleSheet.create({
  checkBoxContainer: {
    top: '12%',
    right: 0,
  },
  checkbox: {
    borderRadius: 4,
    borderColor: colors.secondaryOrange,
  },
})

export default styles
