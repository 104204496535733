import { StyleSheet } from 'react-native'
import colors from 'styles/colors'

const styles = StyleSheet.create({
  tabIndicator: {
    width: '24%',
    height: 4,
    borderRadius: 2,
    left: '13.5%',
    backgroundColor: colors.bgLightGreen,
  },
})

export default styles
