import { StyleSheet } from 'react-native'

const style = StyleSheet.create({
  iconHeader: {
    width: 22,
    height: 23,
  },
  verticleLine: {
    height: '100%',
    width: 1,
  },
})

export default style
