import React, { useEffect, useState } from 'react'
import { View, Text, Image } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import tw from 'tailwind-react-native-classnames'
import { AxiosError, AxiosResponse } from 'axios'

import STRINGS from 'constants/strings'
import { ApplicationState } from 'src/redux/types'
import { get, URL } from 'src/utilities/axios'
import { store } from 'src/redux/store'
import {
  formatter,
  isDecimalNumberValid,
  showToastNotification,
} from 'src/utilities/helpers'
import { DryProduct, DryProductTax, ProductUOM } from 'src/modals/products'
import { ProductDetails } from 'src/modals'
import { CreatePOTabProps } from 'types/procurement'
import { DOWNLOAD } from 'src/config/download'
import {
  setDraftPODetails,
  // commented code for Release v0.9.1
  // toggleBackDraftPOModal,
} from 'src/redux/actions/purchase-order'

// component
import Button from 'src/components/form-fields/Button'
import InputTextField from 'src/components/form-fields/InputTextField'
import SearchOptions, {
  SearchOptionsProps,
} from 'src/components/drawers/SearchOptions'

// style
import global from 'styles/global'
import colors from 'styles/colors'
import styles from './style'

const { CREATE_PO } = STRINGS

const Product = (props: { props: CreatePOTabProps }) => {
  // TODO: Destructure props to { props }
  // eslint-disable-next-line react/destructuring-assignment
  const { setIndex, navigation, id } = props?.props
  const createPOStore = useSelector(
    (state: ApplicationState) => state.procurement.createPO,
  )
  const dispatch = useDispatch()

  const [isDrawerOpen, setDrawerOpen] = useState<boolean>(false)
  const [productList, setProductList] = useState<Array<DryProduct>>([])
  const [taxList, setProductTaxList] = useState<Array<DryProductTax>>([])
  const [searchOptionsProps, setSearchOptionsProps] = useState<any>({})
  const [taxRate, setTaxRate] = useState<number>(1)
  const [mount, setMount] = useState(false)

  const isFieldsDisabled = !createPOStore.product?.id
  const isQtyErrorDisplayed = parseInt(createPOStore.productQuantity, 10) === 0
  const [totalAmount, setTotalAmount] = useState<string>(formatter.format(0))

  useEffect(() => {
    setTotalAmount(
      formatter.format(
        createPOStore.productPrice * createPOStore.productQuantity * taxRate ||
          0,
      ),
    )
  }, [createPOStore.productPrice, createPOStore.productQuantity, taxRate])

  useEffect(() => {
    get(URL.DRY_PRODUCT_LIST, { limit: 100, offset: 0 })
      .then((res: AxiosResponse) => {
        setProductList(res?.data)
        if (res?.data?.length === 1) {
          if (
            (!mount && !createPOStore.product?.id) ||
            (mount && createPOStore.product?.id)
          ) {
            dispatch({
              type: 'SET_CREATE_PO_FIELD',
              payload: { ...createPOStore, product: res.data[0] },
            })
          }
        }
      })
      .catch((err: AxiosError) => {
        showToastNotification(DOWNLOAD.ERROR, err.response?.data.error)
      })
    // TODO: Provide an explanation for why all deps are not included or fix it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // TODO: Provide a different name to the variable taxRate
    // eslint-disable-next-line @typescript-eslint/no-shadow
    let taxRate = 0
    if (createPOStore?.productTax?.name) {
      const tax = Number(createPOStore.productTax.name.match(/@(.*)%/)[1])
      if (typeof taxRate === 'number') taxRate = tax
    }
    const finalTax = (100 + taxRate) / 100
    setTaxRate(finalTax)
  }, [createPOStore.productTax])

  useEffect(() => {
    dispatch({
      type: 'SET_CREATE_PO_FIELD',
      payload: { ...createPOStore, totalAmount },
    })
    // TODO: Provide an explanation for why all deps are not included or fix it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalAmount])

  useEffect(() => {
    const productDetails: ProductDetails = {}
    if (createPOStore.product.id)
      productDetails.product_id = createPOStore.product.id
    if (createPOStore.product.name)
      productDetails.product_name = createPOStore.product.name
    if (createPOStore.productQuantity)
      productDetails.product_qty = createPOStore.productQuantity
    if (createPOStore.productUOM.id)
      productDetails.product_uom = createPOStore.productUOM.id
    if (createPOStore.productPrice)
      productDetails.price_unit = createPOStore.productPrice
    if (createPOStore.productTax.id)
      productDetails.tax_id = createPOStore.productTax.id
    if (Object.keys(productDetails).length > 0)
      dispatch(
        setDraftPODetails({
          items: [productDetails],
        }),
      )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    createPOStore.product?.id,
    createPOStore.productQuantity,
    createPOStore.productUOM?.id,
    createPOStore.productPrice,
    createPOStore.productTax?.id,
  ])

  const handleProductUOM = () => {
    if (
      createPOStore.product?.uoms?.length > 0 &&
      ((!mount && !createPOStore.productUOM?.id) ||
        (mount && createPOStore.productUOM?.id))
    ) {
      const defaultUOM =
        createPOStore.product.uoms.find((uom: ProductUOM) => uom?.default) || {}
      dispatch({
        type: 'SET_CREATE_PO_FIELD',
        payload: { ...createPOStore, productUOM: defaultUOM },
      })
    }
  }

  const handleProductTax = () => {
    get(`${URL.PRODUCT_TAX_LIST}/${createPOStore.product?.id}`, {
      limit: 100,
      offset: 0,
    })
      .then((res: AxiosResponse) => {
        if (res?.data?.length > 0) {
          setProductTaxList(res.data)
          if (
            (!mount && !createPOStore.productUOM?.id) ||
            (mount && createPOStore.productUOM?.id)
          ) {
            const defaultTax =
              res.data.find((tax: DryProductTax) => tax?.default) || {}
            const { createPO } = store.getState().procurement
            dispatch({
              type: 'SET_CREATE_PO_FIELD',
              payload: { ...createPO, productTax: defaultTax },
            })
          }
        }
        if (!mount) setMount(true)
      })
      .catch((err: AxiosError) => {
        showToastNotification(DOWNLOAD.ERROR, err.response?.data.error)
      })
  }

  useEffect(() => {
    if (createPOStore.product?.id) {
      handleProductUOM()
      handleProductTax()
    }
    // TODO: Provide an explanation for why all deps are not included or fix it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createPOStore.product?.id])

  const onDropdownClick = (drawerProps: SearchOptionsProps) => {
    setDrawerOpen(true)
    setSearchOptionsProps(drawerProps)
  }

  const onSelectOptionDrawerClose = (
    selectedItem: { id: string; name: string },
    key: string,
  ) => {
    setDrawerOpen(false)
    if (selectedItem?.id)
      dispatch({
        type: 'SET_CREATE_PO_FIELD',
        payload: { ...createPOStore, [key]: selectedItem },
      })
  }

  const getSelectOptionsProps = (options: any) => ({
    title: options.title,
    searchPlaceholder: options.searchPlaceholder,
    onLoadValue: createPOStore[options.key],
    closeDrawer: (item: any) => onSelectOptionDrawerClose(item, options.key),
    keyDisplayName: 'name',
    searchKeys: 'name',
    itemListOnLoad: options.itemList,
    apiToLoadOption: options.apiToLoadOptions,
    hideSearchBoxItemLimit: options.hideSearchBoxItemLimit,
    errorMessage: options.errorMessage,
    localItemList: options.localItemList,
  })

  const uomProps = {
    title: CREATE_PO.SELECT_UOM,
    searchPlaceholder: CREATE_PO.SEARCH_BY_UOM,
    key: 'productUOM',
    localItemList: createPOStore.product?.uoms,
    errorMessage: CREATE_PO.UOM_ERROR,
  }

  const taxProps = {
    title: CREATE_PO.SELECT_TAX,
    searchPlaceholder: CREATE_PO.SEARCH_BY_TAX_NAME,
    key: 'productTax',
    localItemList: taxList,
  }

  const productProps = {
    title: CREATE_PO.SELECT_PRODUCT,
    searchPlaceholder: CREATE_PO.SEARCH_BY_PRODUCT_NAME,
    key: 'product',
    itemList: productList,
    apiToLoadOptions: URL.DRY_PRODUCT_LIST,
  }

  const leftHeader = (text: string, disable: boolean) => (
    <View style={tw`flex-row items-center mt-5 mb-2.5`}>
      <Text
        style={[
          global.textRegular,
          tw`text-xs`,
          disable ? global.textDarkGrey : global.textColorGrey,
        ]}
      >
        {text}
      </Text>
      <Text style={disable ? global.textDarkGrey : global.textRed}>*</Text>
    </View>
  )

  const getImage = (disabled: boolean) => (
    <Image
      source={
        disabled
          ? require('images/arrow-down-disabled.png')
          : require('images/arrow-down-enabled.png')
      }
      style={tw`mr-2.5 w-2.5 h-1.5`}
    />
  )

  const onProductQuantityChange = (value: string) => {
    if (isDecimalNumberValid(value))
      dispatch({
        type: 'SET_CREATE_PO_FIELD',
        payload: { ...createPOStore, productQuantity: value },
      })
  }

  const onProductPriceChange = (value: string) => {
    if (isDecimalNumberValid(value))
      dispatch({
        type: 'SET_CREATE_PO_FIELD',
        payload: { ...createPOStore, productPrice: value },
      })
  }

  const isNextButtonDisable = () =>
    !(
      createPOStore.product?.id &&
      createPOStore.productQuantity > 0 &&
      createPOStore.productUOM?.id &&
      createPOStore.productPrice > 0 &&
      createPOStore.productTax?.id
    )

  return (
    <View style={tw`h-0 flex-1`}>
      <View
        style={[tw`h-0 flex-1`, isDrawerOpen && global.drawerBackground]}
        pointerEvents={!isDrawerOpen ? 'auto' : 'none'}
      >
        <InputTextField
          leftHeader={leftHeader(CREATE_PO.PRODUCT, false)}
          value={createPOStore.product?.name || ''}
          isDisabled={false}
          onClick={() => onDropdownClick(getSelectOptionsProps(productProps))}
          placeholder={CREATE_PO.SELECT_PRODUCT}
          rightIcon={getImage(false)}
          placeholderTextColor={colors.darkGrey}
          style={tw`mr-5 h-11 justify-between items-center rounded-md`}
          containerStyle={tw`ml-5`}
          innerStyle={[
            global.textRegular,
            global.textColorGrey,
            tw`text-sm ml-2.5`,
            createPOStore.product?.id && global.textMedium,
          ]}
          openKeypadOnTouch={false}
          inputEditable={false}
        />
        <View style={tw`flex-row justify-between`}>
          <InputTextField
            leftHeader={leftHeader(CREATE_PO.QUANTITY, isFieldsDisabled)}
            onTextChange={(value: string) => onProductQuantityChange(value)}
            value={createPOStore.productQuantity}
            inputEditable={!isFieldsDisabled}
            placeholder={CREATE_PO.ENTER_QUANTITY}
            errorMessage={
              isQtyErrorDisplayed ? CREATE_PO.ERROR_QUANTITY_ZERO : undefined
            }
            placeholderTextColor={colors.darkGrey}
            keyboardType="numeric"
            style={[
              tw`mr-5 h-11 rounded-md`,
              isQtyErrorDisplayed && global.borderColorRed,
            ]}
            containerStyle={[global.widthFortyFive, tw`ml-5`]}
            errorStyle={[global.textRed, tw`text-xs`]}
            innerStyle={[
              global.textRegular,
              global.textColorGrey,
              tw`text-sm ml-2.5`,
              createPOStore.productQuantity && global.textMedium,
            ]}
          />
          <InputTextField
            leftHeader={leftHeader(CREATE_PO.UOM, isFieldsDisabled)}
            value={createPOStore.productUOM?.name || ''}
            onClick={() => onDropdownClick(getSelectOptionsProps(uomProps))}
            rightIcon={getImage(isFieldsDisabled)}
            isDisabled={isFieldsDisabled}
            placeholder={CREATE_PO.SELECT_UOM}
            placeholderTextColor={colors.darkGrey}
            style={tw`mr-5 h-11 justify-between items-center rounded-md`}
            containerStyle={global.widthFortyFive}
            innerStyle={[
              global.textRegular,
              global.textColorGrey,
              tw`text-sm ml-2.5`,
              createPOStore.productUOM?.id && global.textMedium,
            ]}
            openKeypadOnTouch={false}
            inputEditable={false}
          />
        </View>
        <View style={tw`flex-row justify-between`}>
          <InputTextField
            leftHeader={leftHeader(CREATE_PO.PRICE_UNIT, isFieldsDisabled)}
            onTextChange={(value: string) => onProductPriceChange(value)}
            value={createPOStore.productPrice || ''}
            inputEditable={!isFieldsDisabled}
            placeholder={CREATE_PO.ENTER_PRICE_UNIT}
            keyboardType="numeric"
            placeholderTextColor={colors.darkGrey}
            style={[tw`mr-5 h-11 rounded-md`]}
            containerStyle={[global.widthFortyFive, tw`ml-5`]}
            innerStyle={[
              global.textRegular,
              global.textColorGrey,
              tw`text-sm ml-2.5`,
              createPOStore.productQuantity && global.textMedium,
            ]}
          />
          <InputTextField
            leftHeader={leftHeader(CREATE_PO.TAX, isFieldsDisabled)}
            value={createPOStore.productTax?.name || ''}
            onClick={() => onDropdownClick(getSelectOptionsProps(taxProps))}
            rightIcon={getImage(isFieldsDisabled)}
            isDisabled={isFieldsDisabled}
            placeholder={CREATE_PO.SELECT_TAX}
            placeholderTextColor={colors.darkGrey}
            style={tw`mr-5 h-11 justify-between items-center rounded-md`}
            containerStyle={global.widthFortyFive}
            innerStyle={[
              global.textRegular,
              global.textColorGrey,
              tw`text-sm ml-2.5`,
              createPOStore.productUOM?.id && global.textMedium,
            ]}
            openKeypadOnTouch={false}
            inputEditable={false}
          />
        </View>
        <View
          style={[
            global.bgLightGreen,
            tw`mx-5 mt-5 justify-center rounded-md`,
            styles.heightAmountContainer,
            !createPOStore.product?.id && global.drawerBackground,
          ]}
        >
          <Text
            style={[
              global.textMedium,
              tw`text-lg  ml-2.5`,
              createPOStore?.product?.id
                ? global.textPrimaryBlack
                : global.textGreenDisabled,
            ]}
          >
            {totalAmount === `₹0.00` ? '₹00' : totalAmount}
          </Text>
        </View>
      </View>
      <View
        style={[
          global.borderColorDarkGrey,
          tw`bg-white py-2.5 px-5`,
          // commented code for Release v0.9.1
          // tw`bg-white py-5 px-5 border-t flex-row justify-between`,
        ]}
      >
        {/* commented code for Release v0.9.1 */}
        {/* <Button
          title={STRINGS.BUTTON.SAVE_AS_DRAFT}
          style={[tw`rounded-lg w-5/12`, styles.nextButton]}
          btnType="secondary"
          textStyle={global.textPrimaryGreen}
          onTap={() => dispatch(toggleBackDraftPOModal())}
        /> */}
        <Button
          disable={isNextButtonDisable()}
          title={
            id !== undefined ? STRINGS.BUTTON.PREVIEW : STRINGS.BUTTON.NEXT
          }
          style={[tw`rounded-lg`, styles.nextButton]}
          // commented code for Release v0.9.1
          // style={[tw`rounded-lg w-1/2`, styles.nextButton]}
          textStyle={isNextButtonDisable() ? global.textMint : global.textWhite}
          onTap={() =>
            id !== undefined
              ? navigation.navigate('POPreview')
              : setIndex && setIndex(2)
          }
        />
      </View>
      {isDrawerOpen && (
        <SearchOptions
          title={searchOptionsProps.title}
          searchPlaceholder={searchOptionsProps.searchPlaceholder}
          onLoadValue={searchOptionsProps.onLoadValue}
          closeDrawer={searchOptionsProps.closeDrawer}
          keyDisplayName={searchOptionsProps.keyDisplayName}
          searchKeys={searchOptionsProps.searchKeys}
          itemListOnLoad={searchOptionsProps.itemListOnLoad}
          apiToLoadOption={searchOptionsProps.apiToLoadOption}
          localItemList={searchOptionsProps.localItemList}
          errorMessage={searchOptionsProps.errorMessage}
          hideSearchBoxItemLimit={searchOptionsProps.hideSearchBoxItemLimit}
        />
      )}
    </View>
  )
}

export default Product
