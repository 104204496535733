import { StyleSheet } from 'react-native'
import colors from 'styles/colors'

const styles = StyleSheet.create({
  header: {
    marginTop: 16,
    marginBottom: 8,
  },
  iconRecent: {
    width: 16,
    height: 16,
  },
  marginLeftFive: {
    marginLeft: 5,
  },
  paddingTopfive: {
    paddingTop: 5,
  },
  itemLine: {
    borderTopColor: colors.bgLightBlue,
    borderTopWidth: 1,
  },
  calenderIcon: {
    width: 12,
    height: 12,
  },
  label: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    width: 125,
    marginTop: 18,
  },
  attributeIcons: {
    width: 26,
    height: 26,
  },
})

export default styles
