import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Animated, Dimensions } from 'react-native'
import { NativeBaseProvider } from 'native-base'
import { TabView, TabBar, SceneMap } from 'react-native-tab-view'

import { fetchInvoiceById } from 'src/redux/actions'
import { ApplicationState } from 'src/redux/types'
import { InvoiceHistoryDetailsProps } from 'src/navigations/types'
import STRINGS from 'constants/strings'
import { Invoice } from 'src/modals'

// components
import MoreDetails, {
  MoreDetailsProps,
} from 'src/components/voucher/MoreDetailsTab'
import ToastNotification from 'src/components/ToastNotification'
import global from 'styles/global'
import PrimaryDetails, { PrimaryDetailsProps } from './PrimaryDetails'

// styles
import styles from './style'

const renderScene = SceneMap({
  invoiceDetails: ({ route }: PrimaryDetailsProps) => (
    <PrimaryDetails route={route} />
  ),
  moreDetails: ({ route }: MoreDetailsProps) => <MoreDetails route={route} />,
})

const InvoiceDetails = ({ route }: InvoiceHistoryDetailsProps) => {
  const { id } = route.params
  const dispatch = useDispatch()
  const [index, setIndex] = useState<number>(0)
  const [invoiceDetails, setInvoice] = useState<Invoice | null>()

  const { invoice } = useSelector(
    (state: ApplicationState) => state.invoiceReducer,
  )

  useEffect(() => {
    if (invoice) setInvoice(invoice)
  }, [invoice])

  useEffect(() => {
    dispatch(fetchInvoiceById(id))
    // TODO: Provide an explanation for why all deps are not included or fix it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  const renderTabBar = (props: any) => (
    <TabBar
      {...props}
      style={global.bgWhite}
      // TODO: Provide different variable name for route
      // eslint-disable-next-line @typescript-eslint/no-shadow
      renderLabel={({ route, focused }) => (
        <Animated.Text
          style={
            focused
              ? [global.textPrimaryBlack, global.textMedium]
              : [global.textSecondaryGrey, global.textMedium]
          }
        >
          {route.title}
        </Animated.Text>
      )}
      indicatorStyle={styles.tabIndicator}
    />
  )

  const routes = [
    {
      key: 'invoiceDetails',
      title: `${STRINGS.INVOICE.INVOICE_DETAILS}`,
      invoice: invoiceDetails,
    },
    {
      key: 'moreDetails',
      title: `${STRINGS.VOUCHER.MORE_DETAILS}`,
      invoice: invoiceDetails,
    },
  ]

  return (
    <NativeBaseProvider>
      <ToastNotification />
      <TabView
        navigationState={{ index, routes }}
        renderScene={renderScene}
        renderTabBar={renderTabBar}
        onIndexChange={setIndex}
        initialLayout={{
          width: Dimensions.get('window').width,
        }}
      />
    </NativeBaseProvider>
  )
}

export default InvoiceDetails
