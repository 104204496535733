import React, { useState, ReactElement, useEffect } from 'react'
import { Image, Text, View } from 'react-native'
import * as ImagePicker from 'expo-image-picker'
import tw from 'tailwind-react-native-classnames'

import STRINGS from 'constants/strings'

import Modal from 'src/components/Modal'
import Button from 'src/components/form-fields/Button'
import ImagePreviewerOrSelector from 'src/components/ImagePreviewerOrSelector'
import Camera from 'src/components/camera'

// style
import global from 'styles/global'
import style from './style'

interface ImageUploadCardProps {
  setImage: Function
  text: string
  secondaryText?: string
  buttonText: string
  successMsg?: string
  image?: string
  deleteBtnContainer?: object
  btnTextStyle?: object
  btnContainerStyle?: object
  isAsterik?: boolean
}

const ImageUploadCard = ({
  setImage,
  text,
  secondaryText,
  buttonText,
  successMsg,
  image,
  deleteBtnContainer,
  btnTextStyle,
  btnContainerStyle,
  isAsterik,
}: ImageUploadCardProps) => {
  const [selectedImage, selectImage] = useState<string>()
  const [showView, setPreview] = useState<boolean>(false)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [modalBody, setModalBody] = useState<ReactElement>(<></>)
  const [modalFooter, setModalFooter] = useState<ReactElement>(<></>)
  const [showCamera, setCameraVisibility] = useState<boolean>(false)
  const [retryButtonText, setRetryButtonText] = useState<string>('')
  const [imageResolution, setImageResolution] = useState({
    width: 0,
    height: 0,
  })

  useEffect(() => {
    if (showCamera) setRetryButtonText(STRINGS.BUTTON.RETAKE_PHOTO)
    if (showView && image) selectImage(image)
  }, [showCamera, showView, image])

  const openFile = async () => {
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      quality: 1,
      base64: false,
    })
    if (!result.cancelled) {
      setImageResolution({
        width: result.width,
        height: result.height,
      })
      setPreview(true)
      selectImage(result.uri)
      setRetryButtonText('')
    }
  }

  const handleDelete = () => {
    setImage(image, true)
    setShowModal(false)
  }

  const closePreview = (flag: boolean) => {
    setPreview(flag)
    setRetryButtonText('')
  }

  const modal = () => {
    setModalBody(
      <View style={[global.alignItemsCenter, style.paddingTopForty]}>
        <Image style={style.warning} source={require('images/warning.png')} />
        <Text
          style={[
            global.fontFourteen,
            global.textRegular,
            global.textColorGrey,
            global.textAlignCenter,
            global.paddingTwenty,
            global.lineHeightTwenty,
            style.paddingBottomForty,
          ]}
        >
          {STRINGS.GRNS.DELETE_DOCUMENT}
        </Text>
      </View>,
    )
    setModalFooter(
      <View
        style={[
          global.borderColorLightGrey,
          tw`flex-row justify-between border-t`,
        ]}
      >
        <Button
          title={STRINGS.BUTTON.YES}
          onTap={handleDelete}
          btnType="secondary"
          style={[
            global.borderColorLightGrey,
            tw`w-1/2 border-0 rounded-bl-lg`,
          ]}
          textStyle={[
            global.textRegular,
            global.textSecondaryOrange,
            tw`text-sm`,
          ]}
        />
        <Button
          title={STRINGS.BUTTON.NO}
          onTap={() => setShowModal(false)}
          btnType="secondary"
          style={[
            global.borderColorLightGrey,
            tw`w-1/2 border-0 rounded-br-lg`,
          ]}
          textStyle={[global.textRegular, tw`text-sm`]}
        />
      </View>,
    )
    setShowModal(true)
  }

  return (
    <View>
      <View style={global.directionRow}>
        <Image
          source={
            !image
              ? require('images/upload-file.png')
              : require('images/doc-uploaded.png')
          }
          style={style.fileIcon}
        />
        <View style={tw`px-2.5 justify-center`}>
          <Text
            style={[
              global.textSecondaryBlue,
              global.fontFourteen,
              global.textMedium,
            ]}
          >
            {text}
            {isAsterik && <Text style={global.textRed}>*</Text>}
          </Text>
          {secondaryText && (
            <Text
              style={[
                global.textColorGrey,
                global.fontTwelve,
                global.textRegular,
                style.paddingTopFour,
              ]}
            >
              {secondaryText}
            </Text>
          )}
        </View>
      </View>
      <View style={[global.directionRow, global.justifyContentSpaceBetween]}>
        <View
          style={[
            global.directionRow,
            global.alignItemsCenter,
            global.vPaddingSixteen,
          ]}
        >
          <Button
            title={image ? STRINGS.BUTTON.DELETE : STRINGS.BUTTON.GALLERY}
            onTap={() => (image ? modal() : openFile())}
            style={[
              global.directionRow,
              global.justifyContentCenter,
              global.alignItemsCenter,
              global.borderRadiusFive,
              global.bgLightMint,
              global.vPaddingSixteen,
              style.iconButtonMain,
              btnContainerStyle,
              image && deleteBtnContainer,
            ]}
            iconPath={
              image
                ? require('images/delete-red.png')
                : require('images/gallery.png')
            }
            textStyle={[
              global.textPrimaryGreen,
              tw`text-xs ml-1 underline`,
              image && btnTextStyle,
            ]}
          />
        </View>
        <View
          style={[
            global.directionRow,
            global.alignItemsCenter,
            global.vPaddingSixteen,
          ]}
        >
          <Button
            title={image ? STRINGS.BUTTON.PREVIEW : STRINGS.BUTTON.CAMERA}
            onTap={() => (image ? setPreview(true) : setCameraVisibility(true))}
            style={[
              global.directionRow,
              global.justifyContentCenter,
              global.alignItemsCenter,
              global.borderRadiusFive,
              global.vPaddingSixteen,
              global.bgLightMint,
              style.iconButtonMain,
              btnContainerStyle,
            ]}
            iconPath={
              image
                ? require('images/preview.png')
                : require('images/camera.png')
            }
            textStyle={[global.textPrimaryGreen, tw`text-xs ml-1 underline`]}
          />
        </View>
      </View>
      {showCamera && (
        <Camera
          hideCamera={setCameraVisibility}
          selectImage={selectImage}
          setPreview={setPreview}
          setImageResolution={setImageResolution}
        />
      )}
      {showModal && (
        <Modal
          body={modalBody}
          footer={modalFooter}
          close={() => setShowModal(false)}
          style={global.paddingZero}
        />
      )}
      {showView && (
        <ImagePreviewerOrSelector
          image={selectedImage}
          closePreview={closePreview}
          successMsg={successMsg}
          buttonText={!image ? buttonText : undefined}
          uploadImage={!image ? setImage : undefined}
          onRetryButton={
            retryButtonText === STRINGS.BUTTON.RETAKE_PHOTO
              ? setCameraVisibility
              : openFile
          }
          retryButtonText={retryButtonText}
          imageResolution={imageResolution}
        />
      )}
    </View>
  )
}

export default ImageUploadCard
