import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { View, ActivityIndicator, FlatList } from 'react-native'
import { Box, Center, NativeBaseProvider } from 'native-base'

import { Order } from 'src/modals'
import { ApplicationState } from 'src/redux/types'
import { fetchOrderList } from 'src/redux/actions'
import { ConfirmOrderProps } from 'src/navigations/types'

// components
import CustomerCard from 'src/components/CustomerCard'

// styles
import global from 'styles/global'
import colors from 'styles/colors'

const Orders = ({ navigation }: ConfirmOrderProps) => {
  const dispatch = useDispatch()
  const [showLoader, setShowLoader] = useState(true)
  const [orderList, setOrderList] = useState<Order[]>([])
  const [offset, setOffset] = useState<number>(0)
  const [numberOfOrders] = useState<number>(10)

  const orderStore = useSelector(
    (state: ApplicationState) => state.orderReducer,
  )

  const loadMore = () => {
    if (orderList.length !== orderStore.count && !showLoader) {
      setOffset(numberOfOrders + offset)
      setShowLoader(true)
    }
  }

  useEffect(() => {
    if (orderStore.list) setOrderList(orderStore.list)
    if (orderStore.list.length > 0) setShowLoader(false)
  }, [orderStore.list])

  useEffect(() => {
    dispatch(fetchOrderList('pending', offset, numberOfOrders))
    setShowLoader(false)
    // TODO: Provide an explanation for why all deps are not included or fix it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset])

  return (
    <NativeBaseProvider>
      <Box flex={1} safeAreaTop>
        <Center
          flex={1}
          style={[global.hPaddingTwenty, global.vPaddingSixteen]}
        >
          <FlatList
            data={orderList}
            renderItem={({ item }) => (
              <View key={item.id}>
                <CustomerCard data={item.customer} navigation={navigation} />
              </View>
            )}
            onEndReachedThreshold={1}
            onEndReached={loadMore}
            ListEmptyComponent={
              <ActivityIndicator size="large" color={colors.primaryGreen} />
            }
          />
          {showLoader && orderList.length > 0 && (
            <ActivityIndicator size="large" color={colors.primaryGreen} />
          )}
        </Center>
      </Box>
    </NativeBaseProvider>
  )
}

export default Orders
