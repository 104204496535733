import React from 'react'
import { View, Text, TextInput, Image } from 'react-native'

import {
  formatterWithoutDecimals,
  getEndTruncatedString,
} from 'src/utilities/helpers'
import STRINGS from 'constants/strings'

// components
import S3Image from 'src/components/S3Image'

// styles
import global from 'styles/global'
import style from './style'

const Strings = STRINGS.VOUCHER

interface DetailItemCardProps {
  name: string
  tax: string
  orderQty?: number
  onPriceEdit?(text: string): void
  imgUrl?: string
  imageS3Key?: string
  customUOM?: string
  detailColumns?: Array<{ key: string; value: number | string }>
  editableField?: { key: string; value: number | string }
  totalAmount?: number | string
  doneQty?: number
  unitOfMeasure?: string
  unitPrice?: number
}

const DetailItemCard = ({
  name,
  tax,
  orderQty,
  onPriceEdit,
  imgUrl,
  imageS3Key,
  customUOM,
  detailColumns,
  editableField,
  totalAmount,
  doneQty,
  unitOfMeasure,
  unitPrice,
}: DetailItemCardProps) => {
  return (
    <View
      style={[
        global.hPaddingSixteen,
        global.bgWhite,
        global.vPaddingThirty,
        style.itemLine,
      ]}
    >
      <View style={[global.directionRow, global.justifyContentSpaceBetween]}>
        <View style={style.itemThumbnail}>
          {imgUrl ? (
            <Image
              source={{ uri: `data:image/png;base64, ${imgUrl}` }}
              style={[global.borderRadiusEight, style.itemThumbnail]}
            />
          ) : (
            <S3Image
              imageKey={imageS3Key!}
              style={[global.borderRadiusEight, style.itemThumbnail]}
            />
          )}
        </View>
        <View
          style={[
            global.directionRow,
            global.justifyContentSpaceBetween,
            style.itemDetails,
          ]}
        >
          <View>
            <Text
              style={[
                global.textSecondaryBlue,
                global.textMedium,
                global.fontTwelve,
                global.textCapitalize,
              ]}
            >
              {getEndTruncatedString(name)}
            </Text>
            <Text
              style={[
                global.vMarginEight,
                global.textSecondaryBlue,
                global.textMedium,
                global.fontTwelve,
                global.borderRadiusTwo,
                global.hPaddingTen,
                global.textAlignCenter,
                style.uomBackground,
              ]}
            >
              {customUOM || `${Strings.UOM}${unitOfMeasure}`}
            </Text>
          </View>
          {orderQty != null && (
            <View>
              <Text
                style={[global.textPink, global.fontTwelve, global.textRegular]}
              >
                {Strings.ORDERED}
              </Text>
              <Text
                style={[
                  global.textPrimaryBlack,
                  global.fontTwelve,
                  global.vMarginSixteen,
                  global.textMedium,
                ]}
              >
                {orderQty}
              </Text>
            </View>
          )}
          {detailColumns &&
            detailColumns.length > 0 &&
            detailColumns.map((column, index) => (
              // TODO: Need to provide reason for why index key or else fix it
              // eslint-disable-next-line react/no-array-index-key
              <View key={`${column.value}${index}`}>
                <Text
                  style={[
                    global.textPink,
                    global.fontTwelve,
                    global.textRegular,
                  ]}
                >
                  {column.key}
                </Text>
                <Text
                  style={[
                    global.textPrimaryBlack,
                    global.fontTwelve,
                    global.vMarginSixteen,
                    global.textMedium,
                  ]}
                >
                  {column.value}
                </Text>
              </View>
            ))}
          {doneQty != null && (
            <View>
              <Text
                style={[global.textPink, global.fontTwelve, global.textRegular]}
              >
                {Strings.DONE}
              </Text>
              <Text
                style={[
                  global.textPrimaryBlack,
                  global.fontTwelve,
                  global.vMarginSixteen,
                  global.textMedium,
                ]}
              >
                {doneQty}
              </Text>
            </View>
          )}
          {(editableField?.key || unitPrice !== undefined) && (
            <View>
              <Text
                style={[global.textPink, global.fontTwelve, global.textRegular]}
              >
                {editableField ? editableField.key : Strings.UNIT_PRICE}
              </Text>
              <View
                style={[
                  global.directionRow,
                  global.justifyContentSpaceEven,
                  global.borderRadiusFour,
                  global.vMarginEight,
                  global.paddingEight,
                  onPriceEdit && style.priceBox,
                ]}
              >
                <Text style={[global.textBold, global.fontTwelve]}>₹</Text>
                {onPriceEdit ? (
                  <TextInput
                    style={[
                      global.marginLeftEight,
                      global.textBold,
                      global.fontTwelve,
                      style.priceInput,
                    ]}
                    keyboardType="numeric"
                    value={
                      editableField
                        ? String(editableField?.value)
                        : String(unitPrice)
                    }
                    onChangeText={onPriceEdit}
                  />
                ) : (
                  <Text style={[global.textBold, global.fontTwelve]}>
                    {editableField ? editableField.value : unitPrice}
                  </Text>
                )}
              </View>
            </View>
          )}
        </View>
      </View>
      <View
        style={[
          global.directionRow,
          global.justifyContentSpaceBetween,
          global.bgWhite,
        ]}
      >
        <Text style={[global.textPink, global.fontTwelve, global.textRegular]}>
          {tax}
        </Text>
        <View style={[global.directionRow, global.justifyContentSpaceEven]}>
          <Text
            style={[global.fontTwelve, global.textRegular, global.textPink]}
          >
            {Strings.AMOUNT}
          </Text>
          <Text
            style={[
              global.textPrimaryBlack,
              global.fontTwelve,
              global.marginLeftEight,
              global.textBold,
            ]}
          >
            {totalAmount ||
              (doneQty &&
                unitPrice &&
                formatterWithoutDecimals.format(doneQty * unitPrice || 0))}
          </Text>
        </View>
      </View>
    </View>
  )
}

export default DetailItemCard
