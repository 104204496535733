import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  iconCancel: {
    width: 14,
    height: 14,
  },
  footerBtn: {
    height: 45,
    maxHeight: 50,
  },
})

export default styles
