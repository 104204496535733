const PERSISTED_STORE_KEY: string = 'dehaat-oms-root'
const INVALID_PH_NUM_ERROR: string = 'Oops! your phone number is invalid'
const INCORRECT_OTP_ERROR: string = 'Oops! your OTP is not correct'

const AWS_API_VERSION: string = '2006-03-01'
const AWS_APP_REGION: string = 'ap-southeast-1'

export  {
  PERSISTED_STORE_KEY,
  INVALID_PH_NUM_ERROR,
  INCORRECT_OTP_ERROR,
  AWS_API_VERSION,
  AWS_APP_REGION,
}
