import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  unbilledVoucher: {
    marginTop: 16,
    marginBottom: 8,
  },
  iconRecent: {
    width: 20,
    height: 21,
  },
  marginLeftSeven: {
    marginLeft: 7,
  },
})

export default styles
