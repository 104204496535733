import React, { useState } from 'react'
import { Animated, Dimensions } from 'react-native'
import { NativeBaseProvider } from 'native-base'
import { TabView, TabBar, SceneMap } from 'react-native-tab-view'

import { WHOrderHistoryProps } from 'src/navigations/types'
import STRINGS from 'constants/strings'

// component
import global from 'styles/global'
import Tab, { WHOrderHistoryTabProps } from './Tab'

// styles
import styles from './style'

const renderScene = SceneMap({
  inProgressOrderList: ({ route }: WHOrderHistoryTabProps) => (
    <Tab route={route} />
  ),
  deliveredOrderList: ({ route }: WHOrderHistoryTabProps) => (
    <Tab route={route} />
  ),
})

const OrderHistory = ({ navigation }: WHOrderHistoryProps) => {
  const [index, setIndex] = useState<number>(0)
  const [activeIndex, setActiveIndex] = useState<number>(0)
  const renderTabBar = (props: any) => (
    <TabBar
      {...props}
      style={global.bgWhite}
      renderLabel={({ route, focused }) => (
        <Animated.Text
          style={
            focused
              ? [global.textPrimaryBlack, global.textMedium]
              : [global.textSecondaryGrey, global.textMedium]
          }
        >
          {route.title}
        </Animated.Text>
      )}
      indicatorStyle={styles.tabIndicator}
      onTabPress={(tabProps: WHOrderHistoryTabProps) => {
        // TODO: Provide prop type for preventDefault
        // eslint-disable-next-line react/prop-types
        setActiveIndex(tabProps?.route?.index)
      }}
    />
  )

  const routes = [
    {
      key: 'inProgressOrderList',
      title: STRINGS.ORDER.IN_PROGRESS,
      index: 0,
      activeIndex,
      navigation,
    },
    {
      key: 'deliveredOrderList',
      title: STRINGS.ORDER.DELIVERED,
      index: 1,
      activeIndex,
      navigation,
    },
  ]

  return (
    <NativeBaseProvider>
      <TabView
        navigationState={{ index, routes }}
        renderScene={renderScene}
        renderTabBar={renderTabBar}
        onIndexChange={setIndex}
        initialLayout={{
          width: Dimensions.get('window').width,
        }}
      />
    </NativeBaseProvider>
  )
}

export default OrderHistory
