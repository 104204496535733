import { combineReducers } from 'redux'
import { AuthReducer } from 'src/redux/reducers/auth.reducers'
import { ProductsReducer } from 'src/redux/reducers/products.reducers'
import { CustomersReducer } from 'src/redux/reducers/customers.reducers'
import { OrdersReducer } from 'src/redux/reducers/orders.reducers'
import { UserReducer } from 'src/redux/reducers/user.reducers'
import { CartReducer } from 'src/redux/reducers/cart.reducers'
import { VoucherReducer } from 'src/redux/reducers/voucher.reducers'
import { InvoiceReducer } from 'src/redux/reducers/invoice.reducers'
import { GRNReducer } from 'src/redux/reducers/grn.reducers'
import { PushNotificationReducer } from 'src/redux/reducers/pushnotification.reducers'
import { purchaseOrderReducer } from './purchase-order.reducers'
import { procurementReducer } from './procurement.reducers'
import { vendorReducer } from './vendor.reducers'
import { appReducer } from './app.reducers'

const rootReducer = combineReducers({
  authReducer: AuthReducer,
  productsReducer: ProductsReducer,
  customersReducer: CustomersReducer,
  orderReducer: OrdersReducer,
  userReducer: UserReducer,
  cartReducer: CartReducer,
  voucherReducer: VoucherReducer,
  invoiceReducer: InvoiceReducer,
  grnReducer: GRNReducer,
  pushNotification: PushNotificationReducer,
  purchaseOrder: purchaseOrderReducer,
  procurement: procurementReducer,
  vendor: vendorReducer,
  app: appReducer,
})

export default rootReducer
