import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  paddingTopThree: {
    paddingTop: 3,
  },
  bottomBorder: {
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(0, 0, 0, 0.05)',
  },
})

export default styles
