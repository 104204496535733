import React, { ReactElement } from 'react'
import { View, Text, Image, Pressable } from 'react-native'
import tw from 'tailwind-react-native-classnames'

// style
import global from 'styles/global'

export interface SearchOptionsProps {
  title: string
  description?: string
  body?: ReactElement
  closeDrawer: Function
  headerStyle?: Object
  bodyStyle?: Object
}

const Info = (props: SearchOptionsProps) => {
  const {
    title, // Title of drawer
    description,
    body,
    closeDrawer, // Function on close drawer
    headerStyle,
    bodyStyle,
  } = props

  return (
    <View style={global.drawer}>
      <View style={tw`justify-between flex-row m-5`}>
        <Text
          style={[global.textColorGrey, tw`font-bold text-sm`, headerStyle]}
        >
          {title}
        </Text>
        <Pressable onPress={() => closeDrawer()} testID="closeDrawer">
          <Image
            source={require('images/cancel.png')}
            style={tw`w-2.5 h-2.5`}
          />
        </Pressable>
      </View>
      <View
        style={[
          global.textMedium,
          global.borderColorGrey,
          tw`flex-row mx-5 items-center h-9 mb-5 mt-8`,
          bodyStyle,
        ]}
      >
        {body || (
          <Text style={[global.textRegular, global.textColorGrey, tw`text-sm`]}>
            {description || 'N/A'}
          </Text>
        )}
      </View>
    </View>
  )
}

export default Info
