import { StyleSheet } from 'react-native'
import colors from 'styles/colors'

const styles = StyleSheet.create({
  iconCancel: {
    width: 10,
    height: 10,
  },
  iconInfo: {
    width: 13,
    height: 13,
  },
  noteDrawerWrapper: {
    height: 309,
  },
  footerBtn: {
    height: 45,
    maxHeight: 50,
  },
  heightAuto: {
    height: 'auto',
  },
  footer: {
    bottom: 0,
  },
  inputWrapper: {
    height: 123,
    borderColor: colors.primaryTextBlack30,
  },
  warningMsg: {
    marginTop: 30,
    lineHeight: 22,
  },
  fileAddIcon: {
    width: 50,
    height: 60,
    marginTop: 41,
  },
  btnContainer: {
    marginTop: 34,
    borderTopWidth: 1,
    borderTopColor: colors.lightGrey,
  },
  btnYesCover: {
    borderWidth: 0,
    borderColor: colors.lightGrey,
  },
  btnNoCover: {
    borderWidth: 0,
    borderColor: colors.lightGrey,
  },
  successIcon: {
    width: 200,
    height: 200,
  },
  /** AddNoteContent */
  pickerWrapper: {
    borderWidth: 0,
  },
  expensePickerWrapper: {
    width: '70%',
  },
  expenseBtn: {
    padding: 2,
  },
  priceInput: {
    padding: 7,
  },
  /** OptionsContent */
  drawerButtonBorder: {
    borderBottomWidth: 1,
    borderBottomColor: colors.lightGrey,
  },
  /** ExpenseContent */
  expenseDrawerWrapper: {
    height: 600,
  },
  marginBottomTen: {
    marginBottom: 10,
  },
  marginBottomTwenty: {
    marginBottom: 20,
  },
  autoDrawerHeight: {
    height: 'auto',
  },
  optionFooterButton: {
    marginTop: 10,
    marginBottom: 30,
    marginLeft: 20,
    height: 45,
    maxHeight: 50,
  },
  optionButton: {
    marginRight: 20,
    height: 45,
  },
})

export default styles
