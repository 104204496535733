import React, { ReactElement, useEffect, useState } from 'react'
import { View, Text, Image, ActivityIndicator, ScrollView } from 'react-native'
import { NativeBaseProvider, Box, HStack, Center } from 'native-base'
import BouncyCheckbox from 'react-native-bouncy-checkbox'
import { APP_AWS_BUCKET } from 'react-native-dotenv'
import { AxiosError, AxiosResponse } from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import tw from 'tailwind-react-native-classnames'

import STRINGS from 'constants/strings'
import { ApplicationState } from 'src/redux/types'

import S3Singleton from 'src/utilities/s3'
import { post, URL } from 'src/utilities/axios'
import { showToastNotification } from 'src/utilities/helpers'
import { DOWNLOAD } from 'src/config/download'
import { UploadDocumentsProps } from 'src/navigations/types'
import useDownloadFrmS3 from 'src/utilities/hooks/useDownloadFrmS3'

// components
import ToastNotification from 'src/components/ToastNotification'
import ImageUploadCard from 'src/components/ImageUploadCard'
import Button from 'src/components/form-fields/Button'
import Modal from 'src/components/Modal'

// styles
import global from 'styles/global'
import colors from 'styles/colors'

const UploadDocuments = ({ navigation, route }: UploadDocumentsProps) => {
  const { moveId, productId, quantityDone, qcParams, grnDetails, documents } =
    useSelector((state: ApplicationState) => state.grnReducer)
  const [weighmentSlip, setWeighmentSlip] = useState<string>(
    '' || documents?.weighmentSlip,
  )
  const [showLoader, setShowLoader] = useState<boolean>(false)
  const [transportBilty, setTransportBilty] = useState<string>(
    '' || documents?.transportBilty,
  )
  const [modalBody, setModalBody] = useState<ReactElement>(<></>)
  const [showModal, setShowModal] = useState<Boolean>(false)
  const [modalFooter, setModalFooter] = useState<ReactElement>(<></>)
  const [checkbox, setCheckbox] = useState<boolean>(false)
  const [imageType, setImageType] = useState<string>('')
  const { id } = route.params
  const { downloadDocFrmS3 } = useDownloadFrmS3()
  const dispatch = useDispatch()

  useEffect(() => {
    if (weighmentSlip !== '' || transportBilty !== '')
      dispatch({
        type: 'SET_UPLOADED_DOCUMENTS',
        payload: {
          weighmentSlip,
          transportBilty,
        },
      })
  }, [weighmentSlip, transportBilty, dispatch])

  const goToSuccessScreen = () => {
    setShowLoader(true)
    const payload = {
      data: [
        {
          move_id: moveId,
          product_id: productId,
          qty_done: quantityDone,
          qc_params: qcParams,
        },
      ],
      create_back_order: 'false',
    }
    post(`${URL.INWARD_GRN}/${id}`, payload)
      .then((res: AxiosResponse) => {
        if (res?.data) {
          setShowLoader(false)
          // eslint-disable-next-line @typescript-eslint/naming-convention
          const { grn_id, store_fname, lot_data = { [moveId]: [] } } = res.data
          navigation.navigate('SuccessScreen', {
            iconImg: (
              <Image
                source={require('images/success-icon.png')}
                style={tw`h-32 w-32`}
              />
            ),
            customMsg: (
              <Text
                style={[
                  global.textRegular,
                  global.textPrimaryBlack,
                  tw`leading-6 text-sm p-5 text-center flex-row`,
                ]}
              >
                {STRINGS.INWARD_GRN.PRODUCTS_FROM}
                <Text style={tw`font-bold`}>
                  {grnDetails.vendor_name ? grnDetails?.vendor_name[1] : 'N/A'}
                </Text>
                {STRINGS.INWARD_GRN.SUCCESS_MSG}
              </Text>
            ),
            greeting: true,
            idFields: [
              {
                title: STRINGS.MISCELLANEOUS.GRN_ID_WITH_HYPHEN,
                value: grn_id,
              },
              {
                title: STRINGS.MISCELLANEOUS.LOT_ID_WITH_HYPHEN,
                value:
                  (lot_data && lot_data[moveId] && lot_data[moveId][1]) ||
                  'N/A',
              },
            ],
            footerButtons: [
              // commented code for Release v0.9.1
              // {
              //   title: STRINGS.BUTTON.CREATE_VENDOR_BILL_FOR_GRN,
              //   onClick: () => navigation.navigate('CreateVendorBill'),
              //   bgColor: global.bgPrimaryGrren.backgroundColor,
              //   style: tw`w-full`,
              // },
              {
                title: STRINGS.BUTTON.DOWNLOAD_GRN,
                onClick: () => {
                  downloadDocFrmS3(
                    store_fname,
                    grn_id,
                    STRINGS.MISCELLANEOUS.GRN_DOWNLOAD_MSG,
                  )
                },
                buttonStyle: [
                  tw`rounded-lg border bg-white`,
                  global.borderColorPrimaryGreen,
                ],
                style: tw`w-full`,
                textStyle: [global.textPrimaryGreen],
              },
            ],
            homeButtonStyle: [
              tw`border-0 underline bg-transparent`,
              global.textDecorationColorPrimaryGreen,
            ],
            buttonContainerStyle: tw`flex-col`,
          })
        }
      })
      .catch((err: AxiosError) => {
        setShowLoader(false)
        showToastNotification(DOWNLOAD.ERROR, err?.response?.data.error)
      })
  }

  useEffect(() => {
    const unsubscribe = navigation.addListener(
      'beforeRemove',
      (navigationEvent: any) => {
        navigation.dispatch(navigationEvent.data.action)
      },
    )
    return unsubscribe
  }, [navigation])

  const uploadGRNAttachment = (storeFname: string, docType: string) => {
    const payload = {
      grn_id: id,
      store_fname: storeFname,
      mimetype: `image/${imageType}`,
      file_name: `${docType}.${imageType}`,
      document_type: docType,
    }
    post(URL.GRN_ATTACHMENTS, payload)
      .then((res: AxiosResponse) => {
        if (
          res?.data &&
          checkbox &&
          grnDetails?.items !== undefined &&
          Number(grnDetails?.items[0]?.quantity) <= quantityDone
        )
          goToSuccessScreen()
        else if (!checkbox) {
          setShowLoader(false)
          navigation.navigate('TransportDetails', { id })
        } else if (
          checkbox &&
          grnDetails?.items !== undefined &&
          Number(grnDetails?.items[0]?.quantity) > quantityDone
        ) {
          setShowLoader(false)
          navigation.navigate('ProcReschedule', { id })
        }
      })
      .catch((err: AxiosError) => {
        setShowLoader(false)
        showToastNotification(DOWNLOAD.ERROR, err.response?.data.error)
      })
  }

  const uploadImageToS3 = async (
    image: string,
    UUID: string,
    docType: string,
  ) => {
    let response
    try {
      const instance = await S3Singleton.getInstance()
      response = await instance.upload(
        {
          Bucket: APP_AWS_BUCKET,
          Key: UUID,
          Body: image,
        },
        (err: any, res: any) => {
          if (res?.key) {
            uploadGRNAttachment(UUID, docType)
          }
          if (err) {
            setShowLoader(false)
            return Promise.reject(
              showToastNotification(
                DOWNLOAD.ERROR,
                STRINGS.MISCELLANEOUS.S3_IMAGE_UPLOAD_ERROR_MSG,
              ),
            )
          }
          return Promise.resolve(res)
        },
      )
      return await Promise.resolve(response)
    } catch {
      setShowLoader(false)
      return Promise.reject(
        showToastNotification(
          DOWNLOAD.ERROR,
          STRINGS.MISCELLANEOUS.S3_IMAGE_UPLOAD_ERROR_MSG,
        ),
      )
    }
  }

  const uploadAllFilesAtOnce = async (uploadApiCalls: any) => {
    await Promise.all<any, any>(uploadApiCalls)
    setShowModal(false)
  }

  const goToNextScreen = () => {
    setShowLoader(true)
    uploadAllFilesAtOnce([
      uploadImageToS3(
        weighmentSlip,
        weighmentSlip.substring(weighmentSlip.lastIndexOf('/') + 1),
        STRINGS.MISCELLANEOUS.WEIGHMENT_SLIP,
      ),
    ])
  }

  const uploadFile = (
    image: string,
    type: string,
    isDeleteTapped?: boolean,
  ) => {
    const imageExtension = image.substring(
      'data:image/'.length,
      image.indexOf(';base64'),
    )
    setImageType(imageExtension)
    const isWeighmentSlip = type === STRINGS.MISCELLANEOUS.WEIGHMENT_SLIP
    if (!isDeleteTapped) {
      if (isWeighmentSlip) setWeighmentSlip(image)
      else setTransportBilty(image)
    } else if (isWeighmentSlip) {
      setWeighmentSlip('')
      dispatch({
        type: 'SET_UPLOADED_DOCUMENTS',
        payload: {
          weighmentSlip: '',
          transportBilty,
        },
      })
    } else {
      setTransportBilty('')
      dispatch({
        type: 'SET_UPLOADED_DOCUMENTS',
        payload: {
          weighmentSlip,
          transportBilty: '',
        },
      })
    }
  }

  const modal = () => {
    setModalBody(
      <View style={tw`items-center mt-9`}>
        <Image style={tw`h-12 w-14`} source={require('images/warning.png')} />
        <View style={tw`w-full`}>
          <Text
            style={[
              global.textRegular,
              global.textColorGrey,
              tw`text-sm text-center leading-5 mt-6 mx-5 mt-7`,
            ]}
          >
            {STRINGS.PURCHASE_ORDER.INWARD_GRN_CONFIRMATION_MSG}
          </Text>
        </View>
      </View>,
    )
    setModalFooter(
      <View
        style={[
          global.borderColorLightGrey,
          tw`mt-12 flex-row justify-between border-t`,
        ]}
      >
        <Button
          title={STRINGS.BUTTON.NO}
          onTap={() => {
            setShowModal(false)
          }}
          btnType="secondary"
          style={[
            global.borderColorLightGrey,
            tw`w-1/2  border-0 border-r rounded-bl-lg`,
          ]}
          textStyle={[
            global.textRegular,
            global.textSecondaryOrange,
            tw`text-sm`,
          ]}
        />
        <Button
          title={STRINGS.BUTTON.INWARD_GRN}
          onTap={goToNextScreen}
          btnType="secondary"
          style={[
            global.borderColorLightGrey,
            tw`w-1/2 border-0 rounded-br-lg`,
          ]}
          textStyle={[global.textRegular, tw`text-sm`]}
        />
      </View>,
    )
    setShowModal(true)
  }

  const navigateToNextScreen = () => {
    if (
      checkbox &&
      grnDetails?.items !== undefined &&
      Number(grnDetails?.items[0]?.quantity) <= quantityDone
    ) {
      modal()
    } else if (!checkbox) {
      setShowLoader(true)
      uploadAllFilesAtOnce([
        uploadImageToS3(
          weighmentSlip,
          weighmentSlip.substring(weighmentSlip.lastIndexOf('/') + 1),
          STRINGS.MISCELLANEOUS.WEIGHMENT_SLIP,
        ),
        uploadImageToS3(
          transportBilty,
          transportBilty.substring(transportBilty.lastIndexOf('/') + 1),
          STRINGS.MISCELLANEOUS.TRANSPORT_BILTY,
        ),
      ])
    } else goToNextScreen()
  }

  return (
    <NativeBaseProvider>
      <Box flex={1} safeAreaTop style={tw`bg-white`}>
        <ToastNotification />
        <ScrollView style={tw`px-5`}>
          <View style={tw`mt-10`}>
            <ImageUploadCard
              setImage={(image: string, isDeleteTapped?: boolean) =>
                uploadFile(
                  image,
                  STRINGS.MISCELLANEOUS.WEIGHMENT_SLIP,
                  isDeleteTapped,
                )
              }
              image={weighmentSlip || documents?.weighmentSlip}
              text={
                weighmentSlip
                  ? STRINGS.GRNS.WEIGHMENT_SLIP_UPLOADED
                  : STRINGS.GRNS.UPLOAD_WEIGHMENT_SLIP
              }
              buttonText={STRINGS.BUTTON.UPLOAD}
              deleteBtnContainer={global.bgCandyRed}
              btnTextStyle={global.textRed}
              btnContainerStyle={global.bgLightGreen}
              isAsterik={!weighmentSlip}
            />
          </View>
          <View
            pointerEvents={checkbox ? 'none' : 'auto'}
            style={[tw`mt-10`, checkbox && tw`opacity-25`]}
          >
            <ImageUploadCard
              setImage={(image: string, isDeleteTapped?: boolean) =>
                uploadFile(
                  image,
                  STRINGS.MISCELLANEOUS.TRANSPORT_BILTY,
                  isDeleteTapped,
                )
              }
              image={transportBilty || documents?.transportBilty}
              text={
                transportBilty
                  ? STRINGS.GRNS.TRANSPORT_BILTY_UPLOADED
                  : STRINGS.BUTTON.UPLOAD_TRANSPORT_BILTY
              }
              buttonText={STRINGS.BUTTON.UPLOAD}
              deleteBtnContainer={global.bgCandyRed}
              btnTextStyle={global.textRed}
              btnContainerStyle={global.bgLightGreen}
              isAsterik={!transportBilty}
            />
          </View>
          <View style={tw`mt-2.5`}>
            <BouncyCheckbox
              onPress={(isChecked: boolean) => setCheckbox(isChecked)}
              size={18}
              iconStyle={[global.borderColorPrimaryGreen, tw`rounded`]}
              fillColor={colors.primaryGreen}
              text={STRINGS.PURCHASE_ORDER.TRANSPORT_BILTY_INFO}
              textStyle={[
                global.textMedium,
                global.textColorGrey,
                tw`text-sm no-underline`,
              ]}
              textContainerStyle={tw`ml-2.5`}
            />
          </View>
          <Text
            style={[global.textColorGrey, global.textRegular, tw`ml-7 text-xs`]}
          >
            {STRINGS.GRNS.TRANSPORT_BILTY_CHECKBOX_INFO}
          </Text>
        </ScrollView>
        <Center flex={1} />
        <HStack safeAreaBottom>
          <View
            style={[
              global.borderColorDarkGrey,
              tw`py-5 px-5 w-full bg-white border-t`,
            ]}
          >
            <View style={tw`h-11 max-h-12`}>
              <Button
                title={
                  (grnDetails?.items !== undefined &&
                    Number(grnDetails?.items[0]?.quantity) > quantityDone) ||
                  (checkbox && !weighmentSlip) ||
                  !weighmentSlip ||
                  (!checkbox && transportBilty && weighmentSlip) ||
                  (!checkbox && !transportBilty)
                    ? STRINGS.PURCHASE_ORDER.CONTINUE_TO_INWARD_GRN
                    : STRINGS.BUTTON.INWARD_GRN
                }
                onTap={navigateToNextScreen}
                style={tw`rounded-lg`}
                disable={
                  (checkbox && !weighmentSlip) ||
                  !weighmentSlip ||
                  (!checkbox && !transportBilty) ||
                  showLoader
                }
              />
            </View>
          </View>
        </HStack>
      </Box>
      {showModal && (
        <Modal
          body={modalBody}
          footer={modalFooter}
          close={() => setShowModal(false)}
          style={tw`p-0`}
        />
      )}
      {showLoader && (
        <View style={tw`justify-center items-center absolute inset-0`}>
          <ActivityIndicator
            size="large"
            color={colors.primaryGreen}
            style={tw`my-4`}
          />
        </View>
      )}
    </NativeBaseProvider>
  )
}

export default UploadDocuments
