import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  nextButton: {
    height: 45,
  },
  heightAmountContainer: {
    height: 51,
  },
})

export default styles
