import React from 'react'
import { View, Text, Image, Pressable } from 'react-native'
import tw from 'tailwind-react-native-classnames'

import STRINGS from 'constants/strings'

// style
import global from 'styles/global'
import style from '../style'

interface VendorCardProps {
  nodeName: string
  payment: string
  vendorName: string
  onEdit?: Function
}

const VendorCard = ({
  nodeName,
  payment,
  vendorName,
  onEdit,
}: VendorCardProps) => {
  return (
    <View style={tw`pl-2`}>
      <Image
        source={require('images/tick-circle.png')}
        style={[tw`absolute left-0`, style.tickIcon]}
      />
      <View style={tw`flex-row pl-5 justify-between`}>
        <Text
          style={[
            global.textPrimaryBlack,
            global.textMedium,
            global.fontSixteen,
          ]}
        >
          {STRINGS.PURCHASE_ORDER.VENDOR_TAB_TITLE}
        </Text>
        {onEdit && (
          <Pressable style={tw`flex-row items-center`} onPress={() => onEdit()}>
            <Image
              source={require('images/note-orange.png')}
              style={tw`w-3.5 h-3.5 mr-1.5`}
            />
            <Text
              style={[
                tw`underline`,
                global.fontFourteen,
                global.textSecondaryOrange,
                global.textMedium,
              ]}
            >
              {STRINGS.BUTTON.EDIT}
            </Text>
          </Pressable>
        )}
      </View>
      <View
        style={[
          tw`pt-8 pb-16 pl-5 pr-4 border-l`,
          global.borderColorGreenDisabled,
        ]}
      >
        <View style={tw`flex-row justify-between`}>
          <View>
            <Text
              style={[tw`text-xs`, global.textColorGrey, global.textRegular]}
            >
              {STRINGS.PURCHASE_ORDER.NODE}
            </Text>
            <Text
              style={[
                tw`pt-1.5 text-sm`,
                global.textPrimaryBlack,
                global.textMedium,
              ]}
            >
              {nodeName}
            </Text>
          </View>
          <View>
            <Text
              style={[tw`text-xs`, global.textColorGrey, global.textRegular]}
            >
              {STRINGS.PURCHASE_ORDER.PAYMENT_TERMS}
            </Text>
            <Text
              style={[
                tw`pt-1.5 text-sm`,
                global.textPrimaryBlack,
                global.textMedium,
              ]}
            >
              {payment}
            </Text>
          </View>
        </View>
        <View style={tw`pt-8`}>
          <Text style={[tw`text-xs`, global.textColorGrey, global.textRegular]}>
            {STRINGS.PURCHASE_ORDER.VENDOR_NAME}
          </Text>
          <Text
            style={[
              tw`pt-1.5 text-sm`,
              global.textPrimaryBlack,
              global.textMedium,
            ]}
          >
            {vendorName}
          </Text>
        </View>
      </View>
    </View>
  )
}

export default VendorCard
