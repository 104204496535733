import { StyleSheet } from 'react-native'
import colors from 'styles/colors'

const styles = StyleSheet.create({
  filterContainer: {
    paddingVertical: 6,
    paddingHorizontal: 15,
    borderWidth: 1,
    borderColor: colors.primaryGreen,
    marginRight: 20,
    backgroundColor: colors.white,
  },
  filterText: {
    color: colors.primaryGreen,
    fontSize: 14,
  },
  selectedFilterBg: {
    backgroundColor: colors.primaryGreen,
  },
  colorWhite: {
    color: colors.white,
  },
})

export default styles
