import React, { useState, useEffect } from 'react'
import { Pressable, ScrollView, View, Image, Text } from 'react-native'
import { NativeBaseProvider, Box, HStack, Center } from 'native-base'
import { useDispatch, useSelector } from 'react-redux'

import { OrderDetailsEditProps } from 'src/navigations/types'
import { ifArraysEqual } from 'src/utilities/helpers'
import STRINGS from 'constants/strings'
import { Product, Item, Attribute } from 'src/modals'
import { ApplicationState } from 'src/redux/types'

// components
import ProductEdit from 'src/components/product/ProductEdit'
import ProductCard from 'src/components/product/ProductCard'
import Button from 'src/components/form-fields/Button'
import global from 'styles/global'
import Header from './Header'

// styles
import styles from './style'

const OrderDetailsEdit = ({ navigation }: OrderDetailsEditProps) => {
  const dispatch = useDispatch()
  const cartStore = useSelector((state: ApplicationState) => state.cartReducer)
  const { products } = cartStore
  const [currentProduct, setCurrentProduct] = useState({
    index: 0,
    data: products[0],
  })
  const [selectedVariants, setSelectedVariants] = useState({} as any)
  const [showDrawer, setDrawer] = useState<boolean>(false)

  const dummyVariant = {
    id: 0,
    name: '',
    price_unit: 0,
    product_uom_qty: 1,
    quality_request: {},
  }

  // Current product change
  const onStepper = (type: string) => {
    if (type === 'inc') {
      if (currentProduct.index + 1 === products.length) {
        return
      }
      const updatedIndex = currentProduct.index + 1
      setCurrentProduct({ index: updatedIndex, data: products[updatedIndex] })
    } else {
      if (currentProduct.index === 0) {
        return
      }
      const updatedIndex = currentProduct.index - 1
      setCurrentProduct({ index: updatedIndex, data: products[updatedIndex] })
    }
  }

  // Delete a product
  const deleteProduct = (product: Product) => {
    dispatch({
      type: 'REMOVE_FROM_CART',
      payload: product,
    })
    dispatch({
      type: 'TOGGLE_PRODUCT',
      payload: product,
    })
  }

  // Update the current selected product after delete a product
  useEffect(() => {
    if (products.length === 0) {
      navigation.navigate('ProductList')
      return
    }
    const selectedIndex =
      currentProduct.index !== 0
        ? currentProduct.index - 1
        : currentProduct.index
    setCurrentProduct({ index: selectedIndex, data: products[selectedIndex] })
    // TODO: Provide an explanation for why all deps are not included or fix it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products.length])

  // Add a new variant in the current product
  const addVariant = () => {
    const copyOfProduct = { ...currentProduct }
    let name: string = ''
    const defaultVariants: any = {}
    dummyVariant.id =
      currentProduct.data.variants !== undefined
        ? currentProduct.data.variants[0].id
        : 0
    Object.keys(currentProduct.data.attributes).forEach(
      (attrName: string, index: number) => {
        name += `${index !== 0 ? ', ' : ''}${
          currentProduct.data.attributes[attrName][0].name
        }`
        defaultVariants[attrName] =
          currentProduct.data.attributes[attrName][0].id
      },
    )
    dummyVariant.name = name
    const variants = {
      selectedAttributes: { ...defaultVariants },
      index: currentProduct.data.selected_variants.length,
    }
    dummyVariant.quality_request =
      currentProduct.data.selected_variants[0]?.quality_request
    setSelectedVariants(variants)
    copyOfProduct.data.selected_variants.push(dummyVariant)
    setCurrentProduct(copyOfProduct)
    dispatch({
      type: 'UPDATE_CART',
      payload: copyOfProduct.data,
    })
  }

  // Delete variant from the current product
  const deleteVariant = (variantIndex: number) => {
    const copyOfProduct = { ...currentProduct }
    copyOfProduct.data.selected_variants.splice(variantIndex, 1)
    setCurrentProduct(copyOfProduct)
    dispatch({
      type: 'UPDATE_CART',
      payload: copyOfProduct.data,
    })
  }

  const setVariant = (index: number, attribute: string, id: number) => {
    const variants = {
      selectedAttributes: { ...selectedVariants.selectedAttributes },
      index,
    }
    variants.selectedAttributes[attribute] = Number(id)
    setSelectedVariants(variants)
  }

  const setAttribute = (
    index: number,
    keyName: string,
    value: number | string,
  ) => {
    const copyOfProduct = { ...currentProduct }
    const { data } = copyOfProduct
    if (data.selected_variants) {
      if (typeof value === 'string') {
        data.selected_variants[index].quality_request = {
          ...data.selected_variants[index].quality_request,
          [keyName]: value,
        }
      }
      if (typeof value === 'number') {
        data.selected_variants[index][keyName] = value
      }
      setCurrentProduct({ ...currentProduct, data })
      dispatch({
        type: 'UPDATE_CART',
        payload: copyOfProduct,
      })
    }
  }

  const moveToCart = () => {
    let allFilled: boolean = true
    products.forEach(product => {
      product.selected_variants.forEach((variant: Item) => {
        if (variant.price_unit === 0 || variant.product_uom_qty === 0) {
          allFilled = false
        }
      })
    })
    if (!allFilled) {
      setDrawer(true)
      return
    }
    navigation.navigate('ProductCart')
  }

  useEffect(() => {
    if (selectedVariants.selectedAttributes) {
      const { index, selectedAttributes } = selectedVariants
      const { data } = currentProduct
      if (
        Object.keys(selectedAttributes).length <
        Object.keys(data.attributes).length
      ) {
        Object.keys(data.attributes).forEach(attr => {
          if (!selectedAttributes[attr]) {
            setVariant(currentProduct.index, attr, data?.attributes[attr][0].id)
          }
        })
      }
      data.variants?.forEach((variant: any) => {
        if (
          ifArraysEqual(
            variant.attribute_value_ids,
            Object.values(selectedAttributes),
          )
        ) {
          let name: string = ''
          Object.keys(selectedAttributes).forEach((attr: string) => {
            data.attributes[attr].forEach((attribute: Attribute) => {
              if (attribute.id === selectedAttributes[attr]) {
                name += `${attribute.name} `
              }
            })
          })
          data.selected_variants[index].name = name
          data.selected_variants[index].id = variant.id
          dispatch({
            type: 'UPDATE_CART',
            payload: data,
          })
          setCurrentProduct({ ...currentProduct, data })
        }
      })
    }
    // TODO: Provide an explanation for why all deps are not included or fix it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVariants.selectedAttributes])

  // content of drawer to be displayed when quanitity/price is 0
  const DrawerContent = () => (
    <View style={global.drawer}>
      <View style={global.alignItemsRight}>
        <Pressable
          onPress={() => setDrawer(false)}
          style={global.paddingSixteen}
        >
          <Image
            source={require('images/cancel.png')}
            style={[styles.iconCancel]}
          />
        </Pressable>
      </View>
      <View style={[global.alignItemsCenter, global.hPaddingTwenty]}>
        <Text
          style={[
            global.fontFourteen,
            global.textRegular,
            global.textColorGrey,
            styles.warningMsg,
          ]}
        >
          {STRINGS.FORM_FIELDS.INCOMPLETE_FIELD_WARNING}
        </Text>
      </View>
      <View
        style={[
          global.directionRow,
          global.justifyContentSpaceBetween,
          global.hPaddingTwenty,
          styles.btnContainer,
        ]}
      >
        <View
          style={[
            global.vPaddingSixteen,
            global.widthHalf,
            global.directionRow,
          ]}
        >
          <Text
            style={[
              global.textSecondaryBlue,
              global.fontFourteen,
              global.textBold,
            ]}
          >
            {currentProduct.index}
          </Text>
          <Text style={[global.textSecondaryBlue, global.fontFourteen]}>
            {`/${products.length} ${STRINGS.MISCELLANEOUS.FILLED}`}
          </Text>
        </View>
        <Button
          title={STRINGS.BUTTON.FILL_DETAILS}
          onTap={() => setDrawer(false)}
          btnType="primary"
          style={[global.widthHalf, global.borderRadiusEight]}
          textStyle={[global.textRegular, global.textBold]}
        />
      </View>
    </View>
  )

  return (
    <NativeBaseProvider>
      <Box flex={1} safeAreaTop>
        <Center flex={1} style={[showDrawer && global.drawerBackground]}>
          <Header
            index={currentProduct.index}
            total={products.length}
            stepper={(type: string) => onStepper(type)}
            style={[global.widthFull]}
          />
          <ProductCard
            data={currentProduct.data}
            index={currentProduct.index}
            deletable={() => deleteProduct(currentProduct.data)}
            navigation={navigation}
            style={[global.bgNone, global.widthFull, styles.productCard]}
          />
          <ScrollView style={[global.widthFull]}>
            {currentProduct.data &&
              currentProduct.data.selected_variants.map(
                (item: Item, index: number) => (
                  <ProductEdit
                    // TODO: Need to provide reason for why index key or else fix it
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    data={currentProduct.data}
                    variantIndex={index}
                    setAttribute={setAttribute}
                    removeVariant={deleteVariant}
                    setVariant={setVariant}
                  />
                ),
              )}
          </ScrollView>
          {currentProduct.data.variants &&
            currentProduct.data.variants.length > 1 && (
              <View
                style={[
                  global.directionRow,
                  global.paddingTen,
                  global.widthFull,
                ]}
              >
                <Pressable onPress={() => addVariant()}>
                  <Image
                    source={require('images/plus.png')}
                    style={[styles.arrowBtn]}
                  />
                </Pressable>
                <Pressable onPress={() => addVariant()}>
                  <Text style={[global.fontFourteen, styles.addBtn]}>
                    {STRINGS.BUTTON.ADD_MORE_VARIANTS}
                  </Text>
                </Pressable>
              </View>
            )}
        </Center>
        <HStack
          safeAreaBottom
          shadow={6}
          style={[showDrawer && global.drawerBackground]}
        >
          <View
            style={[
              global.directionRow,
              global.justifyContentSpaceBetween,
              global.bgWhite,
              global.hPaddingTwenty,
              global.widthFull,
              global.flexOne,
              styles.footer,
            ]}
          >
            <View style={[global.vPaddingSixteen]}>
              <Text>{`${currentProduct.index}/${products.length} ${STRINGS.MISCELLANEOUS.FILLED}`}</Text>
            </View>
            <View style={[styles.footerBtnContainer]}>
              {currentProduct.index === products.length - 1 ? (
                <Button
                  title={STRINGS.BUTTON.GO_TO_CART}
                  onTap={() => moveToCart()}
                  style={global.borderRadiusEight}
                />
              ) : (
                <Button
                  title={STRINGS.BUTTON.DONE}
                  onTap={() => onStepper('inc')}
                  style={[global.borderRadiusEight, styles.btnDone]}
                />
              )}
            </View>
          </View>
        </HStack>
      </Box>
      {showDrawer && <DrawerContent />}
    </NativeBaseProvider>
  )
}

export default OrderDetailsEdit
