import React, { useEffect, useState } from 'react'
import { View, Text, Pressable, Image, Modal } from 'react-native'
import tw from 'tailwind-react-native-classnames'
import { ScrollView } from 'react-native-gesture-handler'

import STRINGS from 'constants/strings'
import { get } from 'src/utilities/axios'
import {
  debounce,
  onLayoutChange,
  showToastNotification,
} from 'src/utilities/helpers'
import {
  FilterConfigProps,
  FilterEngineProps,
  FilterPayloadProps,
} from 'types/filter'
import { DOWNLOAD } from 'src/config/download'

// component
import Button from 'src/components/form-fields/Button'
import global from 'styles/global'
import CustomFilter from './CustomFilter'

// style
import styles from './style'

export const initialFilter = {
  apiFilterPayload: {},
  selectedFilter: {},
}

/**
 * Filter Engine
 *
 * Pass props to use Filter engine
 *
 * @example
 * title={'Filters'}
 * handleClose={() => function() }
 * getSelectedFilters={(selectedFilters: any) => function(selectedFilters)}
 * selectedFilters={selectedFilter}
 * filterHeadingStyle={{}}
 * filterHeadingStyle={{}}
 * filterTitleStyle={{}}
 * apiGetCount={URL.NODE_LIST}
 * apiGetCountEndPoint={''}
 * apiGetCountParams={{}}
 * filterButtonStyle={{}}
 * filterButtonActiveStyle={{}}
 * filterButtonTextStyle={{}}
 * filterButtonActiveTextStyle={{}}
 * filterConfig={[
 *          {
 *            filterList: [
 *               { displayName: 'Before Today', id: 'before_today' },
 *               { displayName: 'Today', id: 'today' },
 *               { displayName: 'Tomorrow', id: 'tomorrow' },
 *               { displayName: 'Next 7 days', id: 'week' },
 *               {
 *                 displayName: 'Custom Date', id: 'calendar',
 *                 type: 'calendar', dateFormat: '', buttonDateFormat: ''
 *               },
 *             ],
 *            id: 'delivery_date',
 *            title: 'Delivery Date',
 *            filterPayloadType: 'array',
 *           },
 *           {
 *             filterList: [
 *               { displayName: 'By Dehaat', id: 'dehaat' },
 *               { displayName: 'By Suplier', id: 'supplier' }
 *             ],
 *             title: 'Qc Reposbility',
 *             id: 'qc_by',
 *             multiSelect: true,
 *           },
 *           {
 *            apiLoadFilterList: URL.NODE_LIST,
 *            apiParams: {},
 *            apiSource: 'name',
 *            apiResultEndPoint: '',
 *            filterList: [
 *              { displayName: 'By Dehhat', id: 'dehaat' },
 *              { displayName: 'By Suplier', id: 'supplier' }
 *             ],
 *            title: 'PO Created',
 *            id: 'po',
 *           }]
 */

const FilterEngine = (props: FilterEngineProps) => {
  const {
    title,
    handleClose,
    filterConfig, // Contains filter list
    selectedFilters,
    getSelectedFilters,
    apiGetCount, // Api to get count on filter selection
    apiGetCountEndPoint, // Key in get count api result
    apiGetCountParams, // params to pass in get count api
    filterHeadingStyle,
  } = props

  const [filterPayload, setFilterPayload] =
    useState<FilterPayloadProps>(selectedFilters)
  const [filterResultCount, setFilterResultCount] = useState<number>(0)
  const [drawerHeight, setDrawerHeight] = useState<number>(0)

  const isFilterSelected =
    filterPayload?.apiFilterPayload &&
    Object.keys(filterPayload?.apiFilterPayload)?.length > 0

  const getFilterCount = () => {
    get(apiGetCount, {
      ...apiGetCountParams,
      ...filterPayload?.apiFilterPayload,
    })
      .then(res => {
        if (res) {
          let count = 0
          if (apiGetCountEndPoint) count = res?.data?.[apiGetCountEndPoint]
          else count = res?.data?.length
          setFilterResultCount(count)
        }
      })
      .catch(err =>
        showToastNotification(DOWNLOAD.ERROR, err.response?.data.error),
      )
  }
  const getCount = debounce(getFilterCount, 1000)

  useEffect(() => {
    getCount()
    // TODO: Provide an explanation for why all deps are not included or fix it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterPayload])

  const getButtonTitle = () =>
    filterResultCount > 0
      ? `${STRINGS.BUTTON.APPLY}(${filterResultCount})`
      : STRINGS.BUTTON.NO_RESULT

  return (
    <Modal
      animationType="none"
      visible
      transparent
      onRequestClose={() => handleClose()}
    >
      <View
        style={[
          global.drawer,
          { height: drawerHeight === 0 ? 'auto' : drawerHeight },
        ]}
        onLayout={(event: any) => setDrawerHeight(onLayoutChange(event, 20))}
      >
        <View style={tw`justify-between flex-row m-5`}>
          <Text
            style={[
              global.textMedium,
              global.textColorGrey,
              tw`text-sm`,
              filterHeadingStyle,
            ]}
          >
            {title}
          </Text>
          <Pressable onPress={() => handleClose()}>
            <Image
              source={require('images/cancel.png')}
              style={tw`w-2.5 h-2.5`}
            />
          </Pressable>
        </View>
        <ScrollView>
          <View>
            {filterConfig?.length > 0 &&
              filterConfig.map((config: FilterConfigProps) => (
                <CustomFilter
                  config={config}
                  params={props}
                  filterPayload={filterPayload}
                  saveFilter={setFilterPayload}
                  key={config.id}
                />
              ))}
          </View>
          <View style={tw`mx-5 my-7 flex-wrap flex-row justify-between`}>
            <Button
              title={STRINGS.BUTTON.CLEAR_ALL}
              onTap={() => setFilterPayload(initialFilter)}
              style={[
                styles.clearAllButton,
                !isFilterSelected && global.borderColorGreenDisabled,
                tw`rounded-lg`,
              ]}
              textStyle={[
                global.textBlack,
                global.textPrimaryGreen,
                tw`text-sm`,
                !isFilterSelected && global.textGreenDisabled,
              ]}
              btnType="secondary"
              disable={!isFilterSelected}
            />
            <Button
              title={getButtonTitle()}
              onTap={() => getSelectedFilters(filterPayload)}
              style={[styles.applyButton, tw`rounded-lg`]}
              textStyle={[global.textBlack, tw`text-sm text-white`]}
              btnType="primary"
              disable={filterResultCount === 0}
            />
          </View>
        </ScrollView>
      </View>
    </Modal>
  )
}

export default FilterEngine
