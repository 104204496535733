import React, { useState, useEffect } from 'react'
import {
  View,
  ActivityIndicator,
  Text,
  Pressable,
  Image,
  FlatList,
} from 'react-native'
import { useIsFocused } from '@react-navigation/native'
import { useDispatch, useSelector } from 'react-redux'
import { Badge, Box, NativeBaseProvider, VStack } from 'native-base'
import tw from 'tailwind-react-native-classnames'

import { fetchOrderListByUrl, resetOrderList } from 'src/redux/actions'
import { URL } from 'src/utilities/axios'
import { ApplicationState } from 'src/redux/types'
import STRINGS from 'constants/strings'
import { getEndTruncatedString, utcToLocalTime } from 'src/utilities/helpers'
import { voucherDateFormat, voucherTimeFormat } from 'src/config/voucher'
import { Order } from 'src/modals'
import FilterEngine, {
  initialFilter,
} from 'src/components/drawers/FilterEngine'
import { FilterPayloadProps } from 'types/filter'

// components
import VoucherCard from 'src/components/voucher/VoucherCard'
import Label from 'src/components/card/Label'
import ProfileBar from 'src/components/card/ProfileBar'
import VoucherMetadata from 'src/components/voucher/VoucherMetadata'
import CustomerInformation from 'src/components/CustomerInformation'

// styles
import global from 'styles/global'
import colors from 'styles/colors'
import style from './style'

export interface WHOrderHistoryTabProps {
  route: {
    index: number
    activeIndex: number
    navigation: {
      navigate: Function
    }
  }
}

const batchSize = 10

const Tab = ({ route }: WHOrderHistoryTabProps) => {
  const { index, activeIndex, navigation } = route
  const dispatch = useDispatch()
  const isFocused = useIsFocused()
  const [showLoader, setShowLoader] = useState<boolean>(true)
  const [offset, setOffset] = useState<number>(0)
  const [showFilter, setShowFilter] = useState<boolean>(false)
  const [appliedFilter, setAppliedFilter] =
    useState<FilterPayloadProps>(initialFilter)
  const { list, count } = useSelector(
    (state: ApplicationState) => state.orderReducer,
  )
  const displayOrderStatus =
    index === 0
      ? `${STRINGS.ORDER.IN} ${STRINGS.ORDER.PROGRESS}`
      : STRINGS.ORDER.DELIVERED

  useEffect(() => {
    dispatch(resetOrderList())
    setOffset(0)
  }, [dispatch, activeIndex])

  useEffect(() => {
    if (index === activeIndex && isFocused)
      dispatch(
        fetchOrderListByUrl(
          URL.ORDER_HISTORY_LIST,
          index === 0 ? 'in_progress' : 'completed',
          offset,
          batchSize,
          appliedFilter.apiFilterPayload,
        ),
      )
    // TODO: Provide different variable names for productIndex and variantIndex
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset, activeIndex, isFocused])

  useEffect(() => {
    if (list?.length > 0) setShowLoader(false)
    else setShowLoader(true)
  }, [list.length])

  useEffect(() => {
    return () => {
      dispatch(resetOrderList())
    }
  }, [dispatch])

  const loadMore = () => {
    if (list.length !== count && !showLoader) {
      setShowLoader(true)
      setOffset(offset + batchSize)
    }
  }

  const renderItem = ({ item }: { item: Order }) => (
    <Pressable
      onPress={() => navigation.navigate('WHOrderDetails', { id: item.id })}
      style={tw`my-2 mx-5`}
    >
      <VoucherCard
        header={
          <Label
            image={require('images/tag.png')}
            text={item.origin}
            containerStyle={[
              global.bgMint,
              tw`rounded-bl-none rounded-tl-none items-center`,
              style.label,
            ]}
            textStyle={[
              global.textPrimaryBlack,
              global.textMedium,
              global.hPaddingFive,
              tw`text-xs`,
            ]}
            imageStyle={tw`w-3 h-3`}
          />
        }
        body={
          <>
            <CustomerInformation
              customerName={
                (item?.partner_id && item?.partner_id[1].toString()) ||
                STRINGS.MISCELLANEOUS.NOT_AVAILABLE
              }
              style={tw`mt-4 mb-3 px-5`}
            />
            <ProfileBar
              imageKey={item?.owner?.image_key || ''}
              name={item?.owner?.name || 'N/A'}
              containerStyle={global.bgLightBlue}
            />
          </>
        }
        footer={
          <VoucherMetadata
            id={item.id}
            attributes={[
              {
                attrName: STRINGS.PURCHASE_ORDER.DELIVERY_DATE,
                attrValue: item.scheduled_date
                  ? utcToLocalTime(item.scheduled_date, voucherDateFormat)
                  : getEndTruncatedString(
                      STRINGS.MISCELLANEOUS.DATE_NOTE_AVAILABLE,
                    ),
                icon: require('images/calendar.png'),
                iconStyle: style.attributeIcons,
                attrStyle: [global.textColorGrey, tw`text-xs`],
                attrValueStyle: [
                  global.textBold,
                  global.textSecondaryBlue,
                  tw`text-sm pt-0.5`,
                ],
              },
              {
                attrName: STRINGS.ORDER.DELIVERY_TIME,
                attrValue: item.scheduled_date
                  ? utcToLocalTime(item.scheduled_date, voucherTimeFormat)
                  : getEndTruncatedString(
                      STRINGS.MISCELLANEOUS.TIME_NOTE_AVAILABLE,
                    ),
                icon: require('images/clock.png'),
                iconStyle: style.attributeIcons,
                attrStyle: [global.textColorGrey, tw`text-xs`],
                attrValueStyle: [
                  global.textBold,
                  global.textSecondaryBlue,
                  tw`text-sm pt-0.5`,
                ],
              },
            ]}
            containerStyle={[global.bgNone, tw`px-4`]}
          />
        }
      />
    </Pressable>
  )

  const applyFilter = (selectedFilters: FilterPayloadProps) => {
    dispatch(resetOrderList())
    setAppliedFilter(selectedFilters)
    setShowFilter(false)
    if (offset !== 0) {
      setOffset(0)
      return
    }
    dispatch(
      fetchOrderListByUrl(
        URL.ORDER_HISTORY_LIST,
        index === 0 ? 'in_progress' : 'completed',
        offset,
        batchSize,
        selectedFilters.apiFilterPayload,
      ),
    )
  }

  return (
    <NativeBaseProvider>
      <Box flex={1} safeAreaTop>
        <View style={tw`mx-5 flex-row justify-between items-center mt-4 mb-2`}>
          <Text
            style={[global.textSecondaryGrey, global.textRegular, tw`text-xs`]}
          >
            {`${STRINGS.MISCELLANEOUS.DISPLAYING} ` +
              `${count} ${STRINGS.MISCELLANEOUS.VOUCHERS} ${displayOrderStatus}`}
          </Text>
          <Pressable
            style={tw`flex-row justify-between`}
            onPress={() => setShowFilter(true)}
          >
            <VStack>
              {Object.keys(appliedFilter.apiFilterPayload).length > 0 && (
                <Badge
                  variant="solid"
                  style={[
                    global.bgPrimaryGrren,
                    style.iconPosition,
                    tw`rounded-full absolute self-end z-10`,
                  ]}
                >
                  <Text style={[global.fontTen, tw`font-bold text-white`]}>
                    {Object.keys(appliedFilter.apiFilterPayload).length}
                  </Text>
                </Badge>
              )}
              <Image
                source={require('images/filter-orange.png')}
                style={tw`w-5 h-5`}
              />
            </VStack>
          </Pressable>
        </View>
        <FlatList
          keyExtractor={item => `${item.id.toString()}`}
          renderItem={renderItem}
          data={list}
          onEndReached={loadMore}
          onEndReachedThreshold={0.2}
          ListEmptyComponent={
            <ActivityIndicator size="large" color={colors.primaryGreen} />
          }
        />
        {showFilter && (
          <FilterEngine
            title={STRINGS.FILTER.FILTER_BY}
            handleClose={() => setShowFilter(false)}
            getSelectedFilters={(selectedFilters: any) =>
              applyFilter(selectedFilters)
            }
            selectedFilters={appliedFilter}
            apiGetCount={URL.ORDER_HISTORY_LIST}
            apiGetCountEndPoint="count"
            apiGetCountParams={{
              status: index === 0 ? 'in_progress' : 'completed',
            }}
            filterConfig={[
              {
                filterList: [
                  { displayName: STRINGS.FILTER.TODAY, id: 'today' },
                  { displayName: STRINGS.FILTER.TOMORROW, id: 'tomorrow' },
                  { displayName: STRINGS.FILTER.NEXT_7_DAYS, id: 'week' },
                  {
                    displayName: STRINGS.FILTER.CUSTOM_DATE,
                    id: 'calendar',
                    type: 'calendar',
                  },
                ],
                id: 'delivery_date',
                title: STRINGS.FILTER.DELIVERY_DATE,
                filterPayloadType: 'array',
              },
              {
                apiLoadFilterList: URL.PICKING_MANAGER_LIST,
                apiSource: 'name',
                title: STRINGS.FILTER.SELECT_PICKUP_MANAGER,
                id: 'picking_manager',
              },
            ]}
          />
        )}
        {showLoader && list.length > 0 && (
          <ActivityIndicator size="large" color={colors.primaryGreen} />
        )}
      </Box>
    </NativeBaseProvider>
  )
}

export default Tab
