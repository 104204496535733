import React, { useState, useEffect } from 'react'
import { View, Text, ScrollView, Image, Pressable } from 'react-native'
import { NativeBaseProvider, Box, HStack, Center } from 'native-base'
import { useDispatch, useSelector } from 'react-redux'

import { ProductCartProps } from 'src/navigations/types'
import STRINGS from 'constants/strings'
import {
  formatter,
  currentTimeStamp,
  getVariantsCount,
} from 'src/utilities/helpers'
import { post, URL } from 'src/utilities/axios'
import { Product, Item } from 'src/modals'
import { ApplicationState } from 'src/redux/types'

// components
import Button from 'src/components/form-fields/Button'
import ProductCard from 'src/components/product/ProductCard'

// styles
import global from 'styles/global'
import style from './style'

const ProductCart = ({ navigation }: ProductCartProps) => {
  const [showDrawer, setDrawer] = useState<boolean>(false)
  const [variantIndex, setVariantIndex] = useState<number>(0)
  const [productIndex, setProductIndex] = useState<number>(0)
  const dispatch = useDispatch()
  const cartStore = useSelector((state: ApplicationState) => state.cartReducer)
  const { products } = cartStore
  const customerStore = useSelector(
    (state: ApplicationState) => state.customersReducer,
  )
  const { customer } = customerStore
  const [total, setTotal] = useState<number>(0)

  const changeQuantity = (
    // TODO: Provide different variable names for productIndex and variantIndex
    // eslint-disable-next-line @typescript-eslint/no-shadow
    productIndex: number,
    // eslint-disable-next-line @typescript-eslint/no-shadow
    variantIndex: number,
    qty: number,
  ) => {
    const copyOfProducts = [...products]
    copyOfProducts[productIndex].selected_variants[
      variantIndex
    ].product_uom_qty = qty
    dispatch({
      type: 'UPDATE_CART',
      payload: copyOfProducts[productIndex],
    })
  }

  // TODO: Provide different variable names for productIndex and variantIndex
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const onDelete = (productIndex: number, variantIndex: number) => {
    const copyOfProducts = [...products]
    copyOfProducts[productIndex].selected_variants.splice(variantIndex, 1)
    if (copyOfProducts[productIndex].selected_variants.length === 0) {
      dispatch({
        type: 'REMOVE_FROM_CART',
        payload: copyOfProducts[productIndex],
      })
      dispatch({
        type: 'TOGGLE_PRODUCT',
        payload: copyOfProducts[productIndex],
      })
    } else
      dispatch({
        type: 'UPDATE_CART',
        payload: copyOfProducts[productIndex],
      })
  }

  const onEdit = () => {
    navigation.navigate('OrderDetailsEdit')
  }

  const DrawerContent = () => (
    <View style={global.drawer}>
      <View style={global.alignItemsRight}>
        <Pressable
          onPress={() => setDrawer(false)}
          style={global.paddingSixteen}
        >
          <Image
            source={require('images/cancel.png')}
            style={[style.iconCancel]}
          />
        </Pressable>
      </View>
      <Pressable
        onPress={() => {
          onEdit()
          setDrawer(false)
        }}
        style={[global.widthFull]}
      >
        <Text
          style={[
            global.fontFourteen,
            global.textRegular,
            global.textColorGrey,
            global.textAlignCenter,
            global.paddingSixteen,
            style.drawerButtonBorder,
          ]}
        >
          {STRINGS.BUTTON.EDIT}
        </Text>
      </Pressable>
      <Pressable
        onPress={() => {
          onDelete(productIndex, variantIndex)
          setDrawer(false)
        }}
        style={[global.widthFull]}
      >
        <Text
          style={[
            global.fontFourteen,
            global.textRegular,
            global.textColorGrey,
            global.textAlignCenter,
            global.paddingSixteen,
            style.drawerButtonBorder,
          ]}
        >
          {STRINGS.BUTTON.DELETE}
        </Text>
      </Pressable>
      <Pressable onPress={() => setDrawer(false)}>
        <Text
          style={[
            global.fontFourteen,
            global.textRegular,
            global.textColorGrey,
            global.textAlignCenter,
            global.paddingSixteen,
          ]}
        >
          {STRINGS.BUTTON.CANCEL}
        </Text>
      </Pressable>
    </View>
  )

  const getTotal = () => {
    let sum = 0
    // FIXME: Using reduce would be better here
    products.map((product: Product) =>
      product.selected_variants.forEach((item: Item) => {
        sum += (item.price_unit || 0) * (item.product_uom_qty || 0)
      }),
    )
    setTotal(sum)
  }

  const createCartData = () => {
    const items: any = []
    products.forEach((product: Product) => {
      // FIXME: A map with concat would have been a better option
      product.selected_variants.forEach((variant: Item) => {
        const itemTemplate = {
          product_id: variant.id,
          price_unit: variant.price_unit,
          product_uom_qty: variant.product_uom_qty,
          quality_request: variant.quality_request,
        }
        items.push(itemTemplate)
      })
    })
    return items
  }

  const confirm = () => {
    const obj = {
      partner_id: customer?.id,
      epoch_timestamp: currentTimeStamp(),
      items: createCartData(),
    }
    post(URL.ORDERS, obj).then((res: { data: number }) => {
      dispatch({
        type: 'RESET_CART',
      })
      dispatch({
        type: 'RESET_PRODUCT_LIST',
      })
      navigation.navigate('SuccessScreen', {
        id: res.data,
        iconImg: (
          <Image
            source={require('images/success-icon.png')}
            style={style.successIcon}
          />
        ),
        successMsg: STRINGS.SUCCESS.ORDER_CREATED,
      })
    })
  }

  useEffect(() => {
    getTotal()
    // TODO: Provide an explanation for why all deps are not included or fix it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products])

  return (
    <NativeBaseProvider>
      <Box flex={1} safeAreaTop style={[showDrawer && global.drawerBackground]}>
        <Center flex={1}>
          <View
            style={[
              global.bgWhite,
              global.paddingTwenty,
              global.directionRow,
              global.widthFull,
            ]}
          >
            <Text style={[global.fontSixteen, global.textRegular]}>
              {`${STRINGS.ORDER.ORDER_OF} `}
            </Text>
            <Text style={[global.fontSixteen, global.textBold]}>
              {customer?.name}
            </Text>
          </View>
          <View
            style={[
              global.paddingTwenty,
              global.directionRow,
              global.justifyContentSpaceBetween,
              global.widthFull,
            ]}
          >
            <Text
              style={[
                global.fontFourteen,
                global.textRegular,
                global.textSecondaryBlue,
              ]}
            >
              {`${getVariantsCount(products)} ${
                STRINGS.MISCELLANEOUS.PRODUCTS
              }`}
            </Text>
            <Text
              style={[
                global.fontFourteen,
                global.textBlack,
                global.textSecondaryBlue,
              ]}
            >
              {`${STRINGS.MISCELLANEOUS.TOTAL}: ${formatter.format(
                Number(total),
              )}`}
            </Text>
          </View>
          <ScrollView style={global.widthFull}>
            {products.map((product: Product, pIndex: number) =>
              product.selected_variants.map(
                // TODO: Provide a different name for variantIndex
                // eslint-disable-next-line @typescript-eslint/no-shadow
                (variant: Item, variantIndex: number) => (
                  <ProductCard
                    // TODO: Need to provide reason for why index key or else fix it
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${pIndex}${variantIndex}`}
                    data={product}
                    name={variant.name}
                    unitPrice={variant.price_unit}
                    quantity={variant.product_uom_qty}
                    navigation={navigation}
                    changeQuantity={(qty: number) =>
                      changeQuantity(pIndex, variantIndex, qty)
                    }
                    showQuantity
                    showDrawer={() => {
                      setVariantIndex(variantIndex)
                      setProductIndex(pIndex)
                      setDrawer(true)
                    }}
                  />
                ),
              ),
            )}
          </ScrollView>
        </Center>
        <HStack safeAreaBottom shadow={6}>
          <View
            style={[
              global.directionRow,
              global.justifyContentSpaceBetween,
              global.bgWhite,
              global.hPaddingTwenty,
              global.widthFull,
              style.footer,
            ]}
          >
            <View style={[style.footerBtn, global.justifyContentCenter]}>
              <Text
                style={[
                  global.fontTwenty,
                  global.textBlack,
                  global.textPrimaryBlack,
                ]}
              >
                {formatter.format(Number(total))}
              </Text>
            </View>
            <View style={[style.footerBtn]}>
              <Button
                title={STRINGS.BUTTON.PLACE_ORDER}
                onTap={() => confirm()}
                style={[global.borderRadiusEight, global.hPaddingTwenty]}
              />
            </View>
          </View>
        </HStack>
      </Box>
      {showDrawer && <DrawerContent />}
    </NativeBaseProvider>
  )
}

export default ProductCart
