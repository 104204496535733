import { StyleSheet } from 'react-native'
import colors from 'styles/colors'

const styles = StyleSheet.create({
  tabIndicator: {
    width: '24%',
    height: 4,
    borderRadius: 2,
    left: '13.5%',
    backgroundColor: colors.bgLightGreen,
  },
  attributeIcons: {
    width: 28,
    height: 28,
  },
  currencyIcons: {
    width: 28,
    height: 28,
  },
  lineSeperator: {
    borderColor: colors.grey,
  },
  continueToInwardGRNContainer: {
    borderTopColor: colors.lightSecondaryGrey,
  },
  continueToInwardGRNButton: {
    height: 45,
  },
  paddingTopThree: {
    paddingTop: 3,
  },
  marginLeftFive: {
    marginLeft: 5,
  },
  paddingTopThirty: {
    paddingTop: 30,
  },
  paddingLeftTwentyThree: {
    paddingLeft: 23.23,
  },
  paddingLeftFortySix: {
    paddingLeft: 46.46,
  },
  hMarginFifty: {
    paddingHorizontal: 50,
  },
  marginBottomThirty: {
    marginBottom: 30,
  },
  marginTopThirtyEight: {
    marginTop: 38,
  },
  warning: {
    width: 50,
    height: 44,
    marginTop: 34,
  },
  warningMsg: {
    marginTop: 30,
  },
  warningDescMsg: {
    marginTop: 5,
  },
  btnContainer: {
    marginTop: 34,
    borderTopColor: colors.lightGrey,
  },
  btnYesCover: {
    borderColor: colors.lightGrey,
  },
  btnNoCover: {
    borderColor: colors.lightGrey,
  },
  btnNo: {
    color: colors.secondaryOrange,
  },
})

export default styles
