import React from 'react'
import { View, Image } from 'react-native'

import STRINGS from 'constants/strings'

// components
import Attribute from 'src/components/voucher/VoucherAttribute'

// styles
import global from 'styles/global'
import style from './style'

interface Props {
  id?: number
  date?: string
  time?: string
  amount?: string
}

const VoucherHeader = ({ date, time, amount, id }: Props) => (
  <View
    style={[
      global.paddingTen,
      global.bgLightBlue,
      global.directionRow,
      global.justifyContentSpaceBetween,
    ]}
  >
    {id && (
      <Attribute
        icon={
          <Image source={require('images/id.png')} style={style.iconHeader} />
        }
        attrName={STRINGS.ORDER_DETAILS.VOUCHER_ID}
        attrValue={id}
      />
    )}
    {id && <View style={style.verticleLine} />}
    {date && (
      <Attribute
        icon={
          <Image
            source={require('images/calendar.png')}
            style={style.iconHeader}
          />
        }
        attrName={STRINGS.ORDER_DETAILS.VOUCHER_DATE}
        attrValue={date}
      />
    )}
    {date && <View style={style.verticleLine} />}
    {time && (
      <Attribute
        icon={
          <Image
            source={require('images/clock.png')}
            style={style.iconHeader}
          />
        }
        attrName={STRINGS.ORDER_DETAILS.VOUCHER_TIME}
        attrValue={time}
        styles={style.headerAttribute}
      />
    )}
    {time && <View style={style.verticleLine} />}
    {amount !== undefined && (
      <View>
        <Attribute
          icon={
            <Image
              source={require('images/currency.png')}
              style={style.iconHeader}
            />
          }
          attrName={STRINGS.ORDER_DETAILS.TOTAL_AMOUNT}
          attrValue={amount}
          styles={style.headerAttribute}
        />
      </View>
    )}
  </View>
)

export default VoucherHeader
