import React, { ReactChild } from 'react'
import { View, TextInput } from 'react-native'

// styles
import global from 'styles/global'
import styles from './style'

interface InputNumberFieldProps {
  placeholder: string
  isSecure?: boolean
  onTextChange: Function
  onFocusOut?: Function
  icon?: ReactChild
  maxLength?: number
  style?: object
  innerStyle?: object
  value?: string
  reference?: any
  onKeyTap?: Function
}

const InputNumberField: React.FC<InputNumberFieldProps> = ({
  placeholder,
  isSecure,
  onTextChange,
  onFocusOut,
  icon,
  maxLength,
  style,
  innerStyle,
  value,
  reference,
  onKeyTap = () => {},
}) => {
  return (
    <View
      style={[
        styles.inputContainer,
        global.justifyContentCenter,
        global.alignItemsCenter,
        global.directionRow,
        global.bgWhite,
        style,
      ]}
    >
      {icon}
      <TextInput
        ref={reference}
        placeholder={placeholder}
        style={[global.flexOne, styles.input, innerStyle]}
        onChangeText={text => onTextChange(text)}
        secureTextEntry={isSecure}
        onBlur={onFocusOut && onFocusOut()}
        underlineColorAndroid="transparent"
        keyboardType="phone-pad"
        maxLength={maxLength}
        value={value}
        onKeyPress={e => onKeyTap(e.nativeEvent.key)}
      />
    </View>
  )
}

InputNumberField.defaultProps = {
  isSecure: false,
  onFocusOut: () => {},
  icon: <></>,
  maxLength: 0,
  style: {},
}

export default InputNumberField
