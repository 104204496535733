const setAddVendorField = (fieldName: string, fieldValue: any) => ({
  type: 'SET_ADD_VENDOR_FIELD',
  payload: {
    fieldName,
    fieldValue,
  },
})

const resetAddVendorState = () => ({
  type: 'RESET_ADD_VENDOR_STATE',
})

export { setAddVendorField, resetAddVendorState }
