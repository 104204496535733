import { AuthActions } from 'src/redux/types'

type AuthState = {
  tokens: string | null
  error: string | null
}

const initialState = {
  tokens: null,
  error: '',
}

const AuthReducer = (state: AuthState = initialState, action: AuthActions) => {
  switch (action.type) {
    case 'SET_USER_AUTH_TOKEN': {
      return {
        ...state,
        tokens: action.payload,
      }
    }
    case 'SET_AUTH_ERROR': {
      return {
        ...state,
        error: action.payload,
      }
    }
    case 'RESET_AUTH_TOKEN': {
      return initialState
    }
    default:
      return state
  }
}

export { AuthReducer }
