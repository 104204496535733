import { Product } from 'src/modals'
import { ProductActions } from 'src/redux/types'

type ProductsState = {
  list: Product[]
  count: number
  error: string | null
}

const initialState = {
  list: [] as Product[],
  count: 0,
  error: null,
}

const ProductsReducer = (
  state: ProductsState = initialState,
  action: ProductActions,
) => {
  switch (action.type) {
    case 'SET_PRODUCT_LIST': {
      return {
        ...state,
        list: action.payload,
      }
    }
    case 'SET_PRODUCT_COUNT': {
      return {
        ...state,
        count: action.payload,
      }
    }
    case 'RESET_PRODUCT_LIST': {
      return {
        ...state,
        list: initialState.list,
      }
    }
    case 'TOGGLE_PRODUCT': {
      const elementsIndex = state.list.findIndex(
        element => element.id === action.payload.id,
      )
      const newArray: Product[] = [...state.list]
      newArray[elementsIndex].isSelected = !newArray[elementsIndex].isSelected
      return {
        ...state,
        list: newArray,
      }
    }
    case 'SET_PRODUCT_ERROR': {
      return {
        ...state,
        error: action.payload,
      }
    }
    default:
      return state
  }
}

export { ProductsReducer }
