const colors = {
  primaryGreen: '#32a05f',
  secondaryOrange: '#ff7f5c',
  primaryTextBlack: '#282c40',
  primaryTextBlack30: 'rgba(40, 44, 64, 0.3)',
  primaryTextBlack50: '#282c4080',
  secondaryTextBlue: '#131c6b',
  secondaryTextBlue50: 'rgba(81, 93, 126, 0.5)',
  secondaryTextBlue70: 'rgba(81, 93, 126, 0.7)',
  secondaryTextGrey: '#82869A',
  white: '#ffffff',
  bgGrey: '#e5e5e5',
  bgGreen: '#ecffed',
  bgLightGreen: '#00C689',
  borderGrey: '#e7e8f0',
  textGrey: '#515d7e',
  bgLightBlue: '#f1f3f8',
  lightGrey: '#f2f2f2',
  black: '#000000',
  dropShadow: '#8d8d8d',
  inactiveGreen: '#99cfaf',
  seaGreen: '#4ab969',
  yellow: '#ff971e',
  red: '#ff6969',
  lightGreen: '#32a05f1a',
  lightRed: '#ff69691a',
  pink: '#7177a6',
  limeGreen: '#36D670',
  lightSecondaryBlue: '#131c6b1a',
  lightSecondaryGrey: '#82869a33',
  mint: '#ddffdf',
  candyRed: '#ffe2e2',
  lightMint: '#eaf6ef',
  darkGrey: '#e1e2e6',
  greenDisabled: '#94caaa',
  grey: '#f5f5f5',
  bgLightGrey: '#fcfcfc',
}

export default colors
