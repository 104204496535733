import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  View,
  Text,
  Image,
  Pressable,
  ActivityIndicator,
  FlatList,
} from 'react-native'
import { Box, NativeBaseProvider } from 'native-base'

import STRINGS from 'constants/strings'
import {
  getEndTruncatedString,
  getTruncatedString,
  utcToLocalTime,
} from 'src/utilities/helpers'
import { voucherDateFormat, voucherTimeFormat } from 'src/config/voucher'
import { ApplicationState } from 'src/redux/types'
import { fetchBilledVoucher, ResetVoucherList } from 'src/redux/actions'
import { VoucherListProps } from 'src/navigations/types'
import { Voucher } from 'src/modals'

// component
import CustomerInformation from 'src/components/CustomerInformation'
import TotalAmount from 'src/components/voucher/TotalAmount'
import VoucherCard from 'src/components/voucher/VoucherCard'
import VoucherMetadata from 'src/components/voucher/VoucherMetadata'

// style
import global from 'styles/global'
import colors from 'styles/colors'
import style from './style'

const voucherSelector = (state: ApplicationState) => state.voucherReducer

const VoucherList = ({ navigation }: VoucherListProps) => {
  const dispatch = useDispatch()
  const [vouchers, setVouchers] = useState<Voucher[]>([])
  const [offset, setOffset] = useState<number>(0)
  const [numberOfVouchers] = useState<number>(10)
  const [showLoader, setShowLoader] = useState<boolean>(true)
  const [sortingOrder, setSortingOrder] = useState<string>('DESC')

  const { voucherList, voucherListCount } = useSelector(voucherSelector)

  useEffect(() => {
    if (voucherList) setVouchers(voucherList)
    if (voucherList.length > 0) setShowLoader(false)
  }, [voucherList])

  useEffect(() => {
    dispatch(fetchBilledVoucher(numberOfVouchers, offset, sortingOrder))
    setShowLoader(false)
    // TODO: Provide an explanation for why all deps are not included or fix it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset])

  useEffect(() => {
    dispatch(ResetVoucherList())
    // TODO: Provide an explanation for why all deps are not included or fix it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const loadMore = () => {
    if (voucherList.length !== voucherListCount && !showLoader) {
      setShowLoader(true)
      setOffset(offset + numberOfVouchers)
    }
  }

  const applyFilter = () => {
    dispatch(ResetVoucherList())
    if (sortingOrder === 'DESC') setSortingOrder('ASC')
    else setSortingOrder('DESC')
    if (offset === 0) {
      dispatch(fetchBilledVoucher(numberOfVouchers, offset, sortingOrder))
    }
    setOffset(0)
  }

  const renderItem = ({ item }: { item: Voucher }) => (
    <Pressable
      onPress={() => navigation.navigate('InvoiceList', { voucher: item })}
      style={[global.vMarginEight, global.hMarginTwenty]}
    >
      <VoucherCard
        header={
          <VoucherMetadata
            id={item?.id}
            attributes={[
              {
                attrName: STRINGS.UNBILLED_VOUCHERS.VOUCHER_ID,
                attrValue:
                  item?.voucher_id.length > 10
                    ? getTruncatedString(item.voucher_id)
                    : item.voucher_id,
                icon: require('images/id.png'),
              },
              {
                attrName: STRINGS.UNBILLED_VOUCHERS.VOUCHER_DATE,
                attrValue: item.date
                  ? utcToLocalTime(item.date, voucherDateFormat)
                  : getEndTruncatedString(
                      STRINGS.MISCELLANEOUS.DATE_NOTE_AVAILABLE,
                    ),
                icon: require('images/calendar.png'),
              },
              {
                attrName: STRINGS.UNBILLED_VOUCHERS.VOUCHER_TIME,
                attrValue: item.date
                  ? utcToLocalTime(item.date, voucherTimeFormat)
                  : getEndTruncatedString(
                      STRINGS.MISCELLANEOUS.TIME_NOTE_AVAILABLE,
                    ),
                icon: require('images/clock.png'),
              },
            ]}
          />
        }
        body={
          <>
            <CustomerInformation
              customerName={
                item?.partner_name || STRINGS.MISCELLANEOUS.NOT_AVAILABLE
              }
              mobile={item.contact}
              id={item?.so_id}
              address={item.address}
              style={global.paddingTwenty}
              truncateAddress
            />
            <TotalAmount
              amount={item.amount_total || 0}
              containerStyle={style.paddingTopfive}
            />
          </>
        }
        footer={
          <View style={global.hPaddingTwenty}>
            <View
              style={[
                global.directionRow,
                global.justifyContentSpaceBetween,
                global.vPaddingTwenty,
                style.itemLine,
              ]}
            >
              <Text
                style={[
                  global.textPrimaryGreen,
                  global.fontFourteen,
                  global.textBold,
                ]}
              >
                {STRINGS.VOUCHER.VIEW_INVOICE}
              </Text>
              <Image
                source={require('images/arrow-right.png')}
                style={style.iconRecent}
              />
            </View>
          </View>
        }
      />
    </Pressable>
  )

  return (
    <NativeBaseProvider>
      <Box flex={1} safeAreaTop>
        <View
          style={[
            global.hMarginTwenty,
            global.directionRow,
            global.justifyContentSpaceBetween,
            global.alignItemsCenter,
            style.unbilledVoucher,
          ]}
        >
          <Text
            style={[
              global.textSecondaryGrey,
              global.textRegular,
              global.fontTwelve,
            ]}
          >
            {`${STRINGS.UNBILLED_VOUCHERS.DISPATCH_VOUCHERS}: ${voucherListCount}`}
          </Text>
          <Pressable
            style={[global.directionRow, global.justifyContentSpaceBetween]}
            onPress={() => applyFilter()}
          >
            <Image
              source={require('images/exchange.png')}
              style={style.iconRecent}
            />
            <Text
              style={[
                global.textMedium,
                global.fontFourteen,
                global.textPrimaryGreen,
                style.marginLeftSeven,
              ]}
            >
              {STRINGS.UNBILLED_VOUCHERS.RECENT}
            </Text>
          </Pressable>
        </View>
        <FlatList
          keyExtractor={item => `${item.id.toString()}billed-dispatch-voucher`}
          renderItem={renderItem}
          data={vouchers}
          onEndReached={loadMore}
          onEndReachedThreshold={0.2}
          ListEmptyComponent={
            <ActivityIndicator size="large" color={colors.primaryGreen} />
          }
        />
        {showLoader && voucherList.length > 0 && (
          <ActivityIndicator size="large" color={colors.primaryGreen} />
        )}
      </Box>
    </NativeBaseProvider>
  )
}

export default VoucherList
