const DOWNLOAD = {
  DOWNLOAD_FOLDER: 'download',
  INVOICE_PDF_FILE_NAME: 'Invoice',
  APPLICATION_PDF: 'application/pdf',
  PDF_FILE: 'data:application/pdf;base64,',
  SUCCESS: 'success',
  ERROR: 'error',
  POSTION_TOP: 'top',
  PLATFORM_ANDROID: 'android',
  PLATFORM_WEB: 'web',
  CREDIT_NOTE_FILE_NAME: 'Credit Note',
  TOAST_VISIBILITY_TIME: 5000,
  ENCODING_STRING:
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/',
  INVOICE_DOC_TYPE: 'invoice',
  CREDIT_DOC_TYPE: 'credit_note',
  GRN_DOC_TYPE: 'grn',
  INVOICE_DOCUMENT: 'ID',
  CREDIT_DOCUMENT: 'CD',
  DOCUMENT: 'Document',
  GRANTED: 'granted',
  DEFAULT: 'default',
  INTENT_VIEW: 'android.intent.action.VIEW',
  DOWNLOAD_SUCCESSFULLY: 'Downloaded Sucessfully',
}

export { DOWNLOAD }
