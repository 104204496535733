import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  successIcon: {
    width: 100,
    height: 100,
  },
})

export default styles
