import React from 'react'
import { View } from 'react-native'
import tw from 'tailwind-react-native-classnames'

import STRING from 'constants/strings'
import { HomeProps } from 'src/navigations/types'

// components
import TileButton from 'src/components/home/TileButton'

const ProcurementManager = ({ navigation }: HomeProps) => {
  return (
    <View style={tw`pt-52`}>
      <View style={tw`mx-5 flex-row justify-between`}>
        <View style={tw`flex-col my-2`}>
          <TileButton
            icon={require('images/create-order.png')}
            iconStyle={tw`w-6 h-6`}
            title={STRING.HOME.CREATE_PO}
            onPress={() => navigation.navigate('CreatePO', { id: undefined })}
          />
          {/* commented code for Release v0.9.1 */}
          {/* <TileButton
            icon={require('images/vendor-bill.png')}
            iconStyle={tw`w-6 h-6`}
            title={STRING.HOME.VENDOR_BILL}
            onPress={() => navigation.navigate('CreateVendorBill')}
            containerStyle={tw`mt-5`}
          /> */}
        </View>
        <View style={tw`flex-col my-2`}>
          <TileButton
            icon={require('images/confirm-order.png')}
            iconStyle={tw`w-6 h-6`}
            title={STRING.HOME.VIEW_POS}
            onPress={() => navigation.navigate('POList')}
          />
          {/* commented code for Release v0.9.1 */}
          {/* <TileButton
            icon={require('images/add-vendor.png')}
            iconStyle={tw`w-6 h-6`}
            title={STRING.HOME.VENDORS}
            onPress={() => navigation.navigate('VendorListing')}
            containerStyle={tw`mt-5`}
          /> */}
        </View>
        <View style={tw`flex-col my-2`}>
          <TileButton
            icon={require('images/green-truck.png')}
            iconStyle={tw`w-6 h-6`}
            title={STRING.HOME.INWARD_GRN}
            onPress={() => navigation.navigate('ProcGRNList')}
          />
        </View>
      </View>
    </View>
  )
}

export default ProcurementManager
