import React, { useState } from 'react'
import {
  GestureResponderEvent,
  View,
  Pressable,
  Image,
  Text,
} from 'react-native'

// component
import global from 'styles/global'
import Button from '../form-fields/Button'

// style
import styles from './style'

interface OptionButtonProps {
  name: string
  id: number
}

interface FooterButtonProps {
  title: string
  onClick: Function
}

interface OptionSelectorProps {
  closeDrawer(event: GestureResponderEvent): void
  optionButtons: OptionButtonProps[]
  footerButton: FooterButtonProps
  title: string
  optionOnClick: Function
}

const OptionSelector = ({
  closeDrawer,
  optionButtons,
  footerButton,
  title,
  optionOnClick,
}: OptionSelectorProps) => {
  const [activeButtonIndex, setActiveButtonIndex] = useState<number | null>(
    null,
  )

  const onOptionButtonClick = (index: number) => {
    const activeIndex =
      activeButtonIndex === null || activeButtonIndex !== index ? index : null
    setActiveButtonIndex(activeIndex)
    optionOnClick(activeIndex)
  }

  return (
    <View style={[global.drawer, styles.autoDrawerHeight]}>
      <View style={global.alignItemsRight}>
        <Pressable onPress={closeDrawer} style={global.paddingSixteen}>
          <Image
            source={require('images/cancel.png')}
            style={styles.iconCancel}
          />
        </Pressable>
      </View>
      <View
        style={[
          global.directionRow,
          global.hMarginTwenty,
          styles.marginBottomTen,
        ]}
      >
        <Text>{title}</Text>
        <Text style={global.textRed}>*</Text>
      </View>
      <View
        style={[global.flexWrap, global.directionRow, global.hMarginTwenty]}
      >
        {optionButtons?.length > 0 &&
          optionButtons.map((option, index: any) => {
            return (
              <View
                style={[global.widthFortyFive, styles.marginBottomTwenty]}
                key={option.id}
              >
                <Button
                  title={option?.name}
                  onTap={() => onOptionButtonClick(index)}
                  btnType={
                    activeButtonIndex === index ? 'primary' : 'secondary'
                  }
                  style={[global.borderRadiusTen, styles.optionButton]}
                  textStyle={[global.fontTwelve, global.marginLeftFour]}
                />
              </View>
            )
          })}
      </View>
      <View style={[global.justifyContentCenter, styles.optionFooterButton]}>
        <Button
          title={footerButton.title}
          onTap={footerButton.onClick}
          disable={activeButtonIndex === null}
          btnType="primary"
          style={[global.borderRadiusEight, global.widthNinety]}
        />
      </View>
    </View>
  )
}

export default OptionSelector
