import React, { useState, useEffect, ReactElement } from 'react'
import { View, Text, Image, ScrollView, ActivityIndicator } from 'react-native'
import { NativeBaseProvider, Box, HStack, Center } from 'native-base'

import { OrderDetailsProps } from 'src/navigations/types'
import { get, put, URL } from 'src/utilities/axios'
import STRINGS from 'constants/strings'
import { formatter, utcToLocalTime } from 'src/utilities/helpers'
import { customerCreateDateFormat } from 'src/config/voucher'
import { Item, OrderDetails as OrderDetailsModal } from 'src/modals'

// components
import Modal from 'src/components/Modal'
import Button from 'src/components/form-fields/Button'
import Dropdown from 'src/components/form-fields/Dropdown'
import ProductCard from 'src/components/product/ProductCard'
import CustomerInformation from 'src/components/CustomerInformation'

// styles
import global from 'styles/global'
import colors from 'styles/colors'
import styles from './style'

const OrderDetails = ({ route, navigation }: OrderDetailsProps) => {
  const { orderId } = route.params
  const [items, setItem] = useState<Item[]>([])
  const [orderDetails, setOrderDetails] = useState<OrderDetailsModal>()
  const [showModal, setShowModal] = useState(false)
  const [modalBody, setModalBody] = useState<ReactElement>(<></>)
  const [modalFooter, setModalFooter] = useState<ReactElement>(<></>)
  const [cancelReason, setCancelReason] = useState<number>()
  const [cancelFlag, setCancelFlag] = useState(false)

  const createCartData = () => {
    const cartData = {
      items,
      orderId: orderDetails?.id,
    }
    return cartData
  }

  const editOrder = () => {
    navigation.navigate('EditOrder', { ...createCartData() })
  }

  const createCancellationDropdownList = (arr: any) => {
    const dataPicker: Array<{ label: string; value: string }> = []
    arr.map((item: any) =>
      dataPicker.push({ label: item.name, value: item.id }),
    )
    return dataPicker
  }

  const cancelOrder = () => {
    setCancelFlag(true)
  }

  const cancel = () => {
    get(`${URL.ORDER_CANCELLATION_REASONS}`).then(data => {
      setModalBody(
        <View style={global.alignItemsCenter}>
          <Image
            style={[styles.warning]}
            source={require('images/warning.png')}
          />
          <Text
            style={[
              styles.warningMsg,
              global.fontFourteen,
              global.textRegular,
              global.textColorGrey,
            ]}
          >
            {STRINGS.ORDER.CANCEL_WARNING}
          </Text>
          <View style={[global.widthFull, global.vMarginSixteen]}>
            <Dropdown
              options={createCancellationDropdownList(data?.data)}
              onChange={(selected: number) => setCancelReason(selected)}
            />
          </View>
        </View>,
      )
      setModalFooter(
        <View
          style={[
            styles.btnContainer,
            global.directionRow,
            global.justifyContentSpaceBetween,
          ]}
        >
          <Button
            title={STRINGS.BUTTON.YES}
            onTap={() => {
              cancelOrder()
            }}
            btnType="secondary"
            style={[styles.btnYesCover, global.widthHalf]}
            textStyle={global.textRegular}
          />
          <Button
            title={STRINGS.BUTTON.NO}
            onTap={() => {
              setShowModal(false)
            }}
            btnType="secondary"
            style={[styles.btnNoCover, global.widthHalf]}
            textStyle={[styles.btnNo, global.textRegular]}
          />
        </View>,
      )
    })
    setShowModal(true)
  }

  const confirm = () => {
    const body = {
      id: orderId,
      op: 'confirm',
    }
    put(URL.ORDERS, body).then(
      (res: {
        data: {
          success: boolean
        }
      }) => {
        if (res.data.success) {
          navigation.navigate('SuccessScreen', {
            id: orderId,
            iconImg: (
              <Image
                source={require('images/success-icon.png')}
                style={styles.successIcon}
              />
            ),
            successMsg: STRINGS.SUCCESS.ORDER_CONFIRM,
            greeting: true,
          })
        }
      },
    )
  }

  const fetchOrderDetails = () => {
    try {
      get(`${URL.ORDERS}/${orderId}`)
        .then(data => {
          setItem(data.data.items)
          setOrderDetails(data.data)
        })
        .catch(() => {
          console.error('Some Error occurred')
        })
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    fetchOrderDetails()
    // TODO: Provide an explanation for why all deps are not included or fix it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId])

  useEffect(() => {
    if (cancelReason && cancelFlag) {
      const obj = {
        id: orderId,
        op: 'cancel',
        cancellation_reason_id: Number(cancelReason),
      }
      put(URL.ORDERS, obj).then(
        (res: {
          data: {
            success: boolean
          }
        }) => {
          if (res.data.success) {
            setShowModal(false)
            navigation.navigate('SuccessScreen', {
              id: orderId,
              iconImg: (
                <Image
                  source={require('images/success-icon.png')}
                  style={styles.successIcon}
                />
              ),
              successMsg: STRINGS.SUCCESS.ORDER_CANCEL,
              greeting: false,
            })
          } else setCancelFlag(false)
        },
      )
    }
    // TODO: Provide an explanation for why all deps are not included or fix it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cancelReason, cancel])

  return (
    <NativeBaseProvider>
      <Box flex={1} safeAreaTop>
        <Center flex={1}>
          <View
            style={[global.bgWhite, global.paddingTwenty, global.widthFull]}
          >
            {orderDetails?.customer?.name ? (
              <CustomerInformation
                customerName={orderDetails?.customer?.name}
                id={orderDetails?.id}
                mobile={orderDetails?.customer?.mobile}
                address={orderDetails?.customer?.address}
                createDate={
                  orderDetails?.create_date &&
                  utcToLocalTime(
                    orderDetails.create_date,
                    customerCreateDateFormat,
                  )
                }
              />
            ) : (
              <ActivityIndicator size="small" color={colors.primaryGreen} />
            )}
          </View>
          <View
            style={[
              global.directionRow,
              global.paddingTwenty,
              global.justifyContentSpaceBetween,
              global.widthFull,
            ]}
          >
            <View>
              <Text
                style={[
                  global.textBold,
                  global.fontFourteen,
                  styles.totalAmount,
                ]}
              >
                {`${STRINGS.MISCELLANEOUS.TOTAL}: ${formatter.format(
                  Number(orderDetails?.amount_total) || 0,
                )}`}
              </Text>
              <Text
                style={[
                  global.textRegular,
                  global.fontTwelve,
                  styles.productNumber,
                ]}
              >
                {`${items?.length} ${STRINGS.MISCELLANEOUS.PRODUCTS}`}
              </Text>
            </View>
            <View
              style={[
                global.directionRow,
                global.alignItemsCenter,
                styles.btnEditContainer,
              ]}
            >
              <Image
                style={styles.headerIcon}
                source={require('images/edit-orange.png')}
              />
              <Button
                title={STRINGS.BUTTON.EDIT_ORDER}
                onTap={() => editOrder()}
                style={[global.bgNone]}
                textStyle={[global.fontFourteen, styles.btnEdit]}
              />
            </View>
          </View>
          <ScrollView style={global.widthFull}>
            {items?.length > 0 ? (
              items.map((item: Item, index: number) => (
                <View key={item.id}>
                  <ProductCard
                    data={item}
                    navigation={navigation}
                    showQuantity
                    index={index}
                    itemList={items}
                    orderId={orderId}
                    quantity={item.product_uom_qty}
                    unitPrice={item.price_unit}
                  />
                </View>
              ))
            ) : (
              <View style={[global.vMarginSixteen, global.widthFull]}>
                {orderDetails?.customer?.name ? (
                  <Text
                    style={[
                      global.fontSixteen,
                      global.textBold,
                      global.textAlignCenter,
                    ]}
                  >
                    {STRINGS.ORDER.NO_ORDER}
                  </Text>
                ) : (
                  <ActivityIndicator size="large" color={colors.primaryGreen} />
                )}
              </View>
            )}
          </ScrollView>
        </Center>
        <HStack safeAreaBottom shadow={6}>
          <View
            style={[
              global.directionRow,
              global.justifyContentSpaceBetween,
              global.bgWhite,
              global.hPaddingTwenty,
              global.widthFull,
              styles.footer,
            ]}
          >
            <View style={[styles.footerBtn]}>
              <Button
                title={STRINGS.BUTTON.CANCEL}
                onTap={() => cancel()}
                style={[global.borderRadiusEight, global.hPaddingTwenty]}
                btnType="secondary"
              />
            </View>
            <View style={[styles.footerBtn]}>
              <Button
                title={STRINGS.BUTTON.CONFIRM}
                onTap={() => confirm()}
                style={[global.borderRadiusEight, global.hPaddingTwenty]}
              />
            </View>
          </View>
          {showModal && (
            <Modal
              body={modalBody}
              footer={modalFooter}
              close={() => setShowModal(false)}
            />
          )}
        </HStack>
      </Box>
    </NativeBaseProvider>
  )
}

export default OrderDetails
