import produce from 'immer'
import {
  PurchaseOrder,
  ProcsPO,
  PODetails,
  AdditionalService,
  DraftPODetails,
} from 'src/modals/purchase-order'
import { PurchaseOrderActions } from 'src/redux/types'

type PurchaseOrderState = {
  purchaseOrderList: PurchaseOrder[] | ProcsPO[] | null
  totalPurchaseOrderCount: number | null
  isPOListLoading: boolean
  poDetails: PODetails
  grnDetailsByPO: ProcsPO | null
  error: string | null
  vendorBill: {
    invoiceNumber: string
    invoiceDate: string
    image: string
    comment: string
    additionalServices: AdditionalService[]
  }
  draftPODetails: DraftPODetails
  isDraftPOModalOpen: boolean
}

const initialState = {
  purchaseOrderList: null,
  totalPurchaseOrderCount: null,
  isPOListLoading: false,
  poDetails: {} as PODetails,
  grnDetailsByPO: null,
  vendorBill: {
    invoiceNumber: '',
    invoiceDate: '',
    image: '',
    comment: '',
    additionalServices: [],
  },
  error: '',
  draftPODetails: {},
  isDraftPOModalOpen: false,
}

const purchaseOrderReducer = (
  state: PurchaseOrderState = initialState,
  action: PurchaseOrderActions,
) => {
  switch (action.type) {
    case 'TOGGLE_PO_FLAG': {
      return {
        ...state,
        [action.payload]: !state[action.payload],
      }
    }
    case 'RESET_PURCHASE_ORDER_LIST': {
      return initialState
    }
    case 'APPEND_PURCHASE_ORDER_LIST': {
      return {
        ...state,
        purchaseOrderList: [
          ...(state.purchaseOrderList || []),
          ...action.payload,
        ],
      }
    }
    case 'SET_PURCHASE_ORDER_TOTAL_COUNT': {
      return {
        ...state,
        totalPurchaseOrderCount: action.payload,
      }
    }
    case 'SET_PO_DETAILS': {
      return {
        ...state,
        poDetails: action.payload,
      }
    }
    case 'SET_GRN_DETAILS_BY_PO': {
      return {
        ...state,
        grnDetailsByPO: action.payload,
      }
    }
    case 'SET_VENDOR_INVOICE_NUMBER': {
      return produce(state, proxy => {
        proxy.vendorBill.invoiceNumber = action.payload
      })
    }
    case 'SET_VENDOR_INVOICE_DATE': {
      return produce(state, proxy => {
        proxy.vendorBill.invoiceDate = action.payload
      })
    }
    case 'RESET_VENDOR_BILL_FIELDS': {
      return {
        ...state,
        vendorBill: initialState.vendorBill,
      }
    }
    case 'SET_VENDOR_BILL_IMAGE': {
      return produce(state, proxy => {
        proxy.vendorBill.image = action.payload
      })
    }
    case 'SET_VENDOR_BILL_COMMENT': {
      return produce(state, proxy => {
        proxy.vendorBill.comment = action.payload
      })
    }
    case 'SET_ADDITIONAL_SERVICES': {
      return produce(state, proxy => {
        proxy.vendorBill.additionalServices = action.payload
      })
    }
    case 'SET_ERROR': {
      return {
        ...state,
        error: action.payload,
      }
    }
    case 'SET_DRAFT_PO_DETAILS': {
      return {
        ...state,
        draftPODetails: {
          ...state.draftPODetails,
          ...action.payload,
        },
      }
    }
    case 'TOGGLE_BACK_DRAFT_PO_MODAL': {
      return {
        ...state,
        isDraftPOModalOpen: !state.isDraftPOModalOpen,
      }
    }
    default:
      return state
  }
}

export { purchaseOrderReducer }
