import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Animated, Dimensions, View, Image } from 'react-native'
import { NativeBaseProvider } from 'native-base'
import { TabView, TabBar, SceneMap } from 'react-native-tab-view'
import tw from 'tailwind-react-native-classnames'

import { ApplicationState } from 'src/redux/types'
import STRINGS from 'constants/strings'
import { ProcGRNDetailsProps } from 'src/navigations/types'
import { fetchProcGRNDetailsById } from 'src/redux/actions/grn'

// components
import ToastNotification from 'src/components/ToastNotification'
import global from 'styles/global'
import PrimaryDetails, { GRNPrimaryDetailsProps } from './PrimaryDetails'
import MoreDetails from './MoreDetails'

// styles
import styles from './style'

const renderScene = SceneMap({
  primaryDetails: ({ route }: GRNPrimaryDetailsProps) => (
    <PrimaryDetails route={route} />
  ),
  moreDetails: ({ route }: GRNPrimaryDetailsProps) => (
    <MoreDetails route={route} />
  ),
})

const GRNDetails = ({ route, navigation }: ProcGRNDetailsProps) => {
  const [index, setIndex] = useState<number>(0)
  const { id } = route.params
  const dispatch = useDispatch()

  const { grnDetails } = useSelector(
    (state: ApplicationState) => state.grnReducer,
  )

  useEffect(() => {
    if (id) dispatch(fetchProcGRNDetailsById(id))
  }, [dispatch, id])

  useEffect(() => {
    return () => {
      dispatch({
        type: 'SET_GRN_DETAILS',
        payload: {},
      })
      dispatch({
        type: 'RESET_UPLOADED_DOCUMENTS',
        payload: {
          weighmentSlip: '',
          transportBilty: '',
        },
      })
    }
  }, [dispatch])

  const focusedTabs = (title?: string) =>
    title === STRINGS.GRNS.GRN_DETAILS ? (
      <Image
        source={require('images/confirm-order.png')}
        style={tw`w-3.5 h-3.5`}
      />
    ) : (
      <Image
        source={require('images/more-details.png')}
        style={tw`w-3.5 h-3.5`}
      />
    )

  const unFocusedTabs = (title?: string) =>
    title === STRINGS.GRNS.GRN_DETAILS ? (
      <Image source={require('images/page-grey.png')} style={tw`w-3.5 h-3.5`} />
    ) : (
      <Image
        source={require('images/more-details-disabled.png')}
        style={tw`w-3.5 h-3.5`}
      />
    )

  const renderTabBar = (props: any) => (
    <TabBar
      {...props}
      style={global.bgWhite}
      renderLabel={({ route: param, focused }) => {
        return (
          <View style={tw`flex-row`}>
            {focused ? focusedTabs(param.title) : unFocusedTabs(param.title)}
            <Animated.Text
              style={
                focused
                  ? [global.textPrimaryBlack, global.textMedium, tw`ml-1`]
                  : [global.textSecondaryGrey, global.textMedium, tw`ml-1`]
              }
            >
              {param.title}
            </Animated.Text>
          </View>
        )
      }}
      indicatorStyle={styles.tabIndicator}
    />
  )

  const routes = [
    {
      key: 'primaryDetails',
      title: `${STRINGS.GRNS.GRN_DETAILS}`,
      grnDetails,
      navigation,
    },
    {
      key: 'moreDetails',
      title: `${STRINGS.VOUCHER.MORE_DETAILS}`,
      grnDetails,
    },
  ]

  return (
    <NativeBaseProvider>
      <ToastNotification />
      <TabView
        navigationState={{ index, routes }}
        renderScene={renderScene}
        renderTabBar={renderTabBar}
        onIndexChange={setIndex}
        initialLayout={{
          width: Dimensions.get('window').width,
        }}
      />
    </NativeBaseProvider>
  )
}

export default GRNDetails
