import React, { ReactElement, useState } from 'react'
import { View, Image, Text } from 'react-native'

import {
  iconlabelButtonObj,
  voucherDateFormat,
  orderStatusObj,
} from 'src/config/voucher'
import { formatterWithoutDecimals, utcToLocalTime } from 'src/utilities/helpers'
import STRING from 'constants/strings'
import { put, URL } from 'src/utilities/axios'
import { OrderVoucherDetails, VocherItemProps } from 'src/modals'

// components
import ItemCard from 'src/components/voucher/ItemCard'
import OrderDateStatus from 'src/components/voucher/VoucherStatus'
import Button from 'src/components/form-fields/Button'
import Modal from 'src/components/Modal'

// styles
import global from 'styles/global'
import style from './style'

interface AttributeProps {
  date: string
  orderStatus: string
  orderId: Number
  voucherId: Number
  navigation: { navigate: Function }
}

interface BodyProps {
  data: OrderVoucherDetails
  orderItems: Array<VocherItemProps>
  orderId: Number
  navigation: { navigate: Function }
}

const BodyHeader = ({
  orderStatus,
  date,
  orderId,
  voucherId,
  navigation,
}: AttributeProps) => {
  const [showModal, setShowModal] = useState(false)
  const [modalBody, setModalBody] = useState<ReactElement>(<></>)
  const [modalFooter, setModalFooter] = useState<ReactElement>(<></>)

  const onCancelVoucher = () => {
    const payload = {
      id: orderId,
      op: 'cancel_voucher',
      voucher_id: voucherId,
    }
    put(URL.ORDERS, payload)
      .then((res: { data: { success: boolean } }) => {
        if (res.data.success) {
          navigation.navigate('OrderHistory')
        }
      })
      .catch(error => console.error(error))
    setShowModal(false)
  }

  const openCancelModal = () => {
    setModalBody(
      <View style={global.alignItemsCenter}>
        <Image style={style.warning} source={require('images/warning.png')} />
        <Text
          style={[
            global.fontFourteen,
            global.textRegular,
            global.textColorGrey,
            global.textAlignCenter,
            style.warningMsg,
          ]}
        >
          {STRING.ORDER_DETAILS.CANCEL_VOUCHER_WARNING}
        </Text>
      </View>,
    )
    setModalFooter(
      <View
        style={[
          global.directionRow,
          global.justifyContentSpaceBetween,
          style.btnContainer,
        ]}
      >
        <Button
          title={STRING.BUTTON.YES}
          onTap={() => onCancelVoucher()}
          btnType="secondary"
          style={[global.widthHalf, style.btnYesCancel]}
          textStyle={global.textRegular}
        />
        <Button
          title={STRING.BUTTON.NO}
          onTap={() => setShowModal(false)}
          btnType="secondary"
          style={[global.widthHalf, style.btnNoCancel]}
          textStyle={[global.textRegular, global.textRed]}
        />
      </View>,
    )
    setShowModal(true)
  }

  return (
    <View>
      <View style={[global.directionRow, global.justifyContentSpaceBetween]}>
        {orderStatus && (
          <View style={global.vMarginEight}>
            <OrderDateStatus
              iconPath={
                orderStatusObj[orderStatus] &&
                orderStatusObj[orderStatus].iconPath
              }
              status={orderStatus}
              textColor={
                orderStatusObj[orderStatus] && orderStatusObj[orderStatus].color
              }
              date={date}
              dateAligned
            />
          </View>
        )}
        {orderStatus === STRING.ORDER_DETAILS.READY_FOR_PICKUP && (
          <View>
            <Button
              title={STRING.ORDER_DETAILS.CANCEL_VOUCHER}
              onTap={() => openCancelModal()}
              style={[
                global.directionRow,
                global.justifyContentCenter,
                global.alignItemsCenter,
                global.borderRadiusFive,
                style.iconButtonMain,
                {
                  backgroundColor: iconlabelButtonObj['Cancel Voucher'].bgColor,
                },
              ]}
              iconPath={iconlabelButtonObj['Cancel Voucher'].iconPath}
              textStyle={[
                global.fontTwelve,
                global.textMedium,
                global.marginLeftFour,
                style.textUnderLine,
                { color: iconlabelButtonObj['Cancel Voucher'].textColor },
              ]}
            />
          </View>
        )}
      </View>
      {showModal && (
        <Modal
          body={modalBody}
          footer={modalFooter}
          close={() => setShowModal(false)}
        />
      )}
    </View>
  )
}

const Body = ({ data, orderItems, orderId, navigation }: BodyProps) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { write_date, state, id } = data

  return (
    <View style={global.paddingSixteen}>
      <BodyHeader
        orderStatus={state}
        date={utcToLocalTime(write_date, voucherDateFormat)}
        orderId={orderId}
        voucherId={id}
        navigation={navigation}
      />
      {orderItems &&
        orderItems.length > 0 &&
        orderItems.map((item: VocherItemProps) => {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          const { product_id, product_qty, price_unit, image, product_uom } =
            item
          return (
            <ItemCard
              key={product_id.join()}
              imgUrl={image}
              itemName={product_id && product_id[1]}
              itemQuantity={`${product_qty} ${product_uom && product_uom[1]}`}
              itemRate={formatterWithoutDecimals.format(Number(price_unit))}
            />
          )
        })}
    </View>
  )
}

export default Body
