const URL = {
  /* Sales Manager Endpoints */
  PRODUCT_LIST: 'v1/sales-manager/products',
  CUSTOMER_LIST: 'v1/sales-manager/customers',
  ORDERS: 'v1/sales-manager/orders',
  USER_DETAILS: 'v1/oms-user/me',
  OUTSTANDING_AMOUNT: 'v1/sales-manager/outstanding-amount',
  ORDER_CANCELLATION_REASONS: 'v1/sales-manager/order-cancellation-reasons',

  /* Sales Biller Endpoints */
  SB_OUTSTANDING_AMOUNT: 'v1/sales-biller/outstanding-amount',
  SB_UNBILLED_DV_COUNT: 'v1/sales-biller/unbilled-dispatch-vouchers-count',
  UNBILLED_VOUCHERS: 'v1/sales-biller/unbilled-dispatch-vouchers',
  VOUCHER_DETAILS: 'v1/sales-biller/dispatch-vouchers',
  INVOICE_LIST: 'v1/sales-biller/invoices',
  CANCEL_INVOICE: 'v1/sales-biller/cancel-invoice',
  BILLED_DISPATCHED_VOUCHERS: 'v1/sales-biller/billed-dispatch-vouchers',
  INVOICE_DOWNLOAD: 'v1/sales-biller/invoice-attachments',
  CREDIT_NOTE_CATEGORY: '/v1/sales-biller/credit-note/category',
  CREATE_CREDIT_NOTE: '/v1/sales-biller/credit-note',
  UPDATE_INVOICE: '/v1/sales-biller/update-invoice',

  /* Inventory Manager Endpoints */
  PENDING_GRN_COUNT: 'v1/inventory-manager/pending-grn-count',
  PENDING_GRN_LIST: 'v1/inventory-manager/pending-grn-list',
  PURCHASE_ORDER_LIST: 'v1/inventory-manager/purchase-orders',
  GRN_DETAILS: 'v1/inventory-manager/grn_details',
  VALIDATE_GRN: 'v1/inventory-manager/validate-grn',

  /* Procurement Manager Endpoints */
  NODE_LIST: 'v1/procurement-manager/nodes',
  VENDOR_LIST: 'v1/procurement-manager/vendors',
  PAYMENT_TERMS: 'v1/procurement-manager/payment-terms',
  QC_PARAMS_LIST: 'v1/procurement-manager/product-details',
  DRY_PRODUCT_LIST: 'v1/procurement-manager/products',
  PRODUCT_TAX_LIST: 'v1/procurement-manager/product-tax-list',
  LOCATIONS: 'v1/procurement-manager/locations',
  CREATE_PO: 'v1/proc-manager/create-po',
  PROCS_PO_LIST: 'v1/procurement-manager/purchase-orders',
  PO_DETAILS: 'v1/procurement-manager/po-details',
  PROC_GRN_LIST: 'v1/procurement-manager/pending-proc-grn-list',
  PROC_GRN_DETAILS: 'v1/procurement-manager/grn-details',
  GRN_ATTACHMENTS: 'v1/procurement-manager/grn-attachments',
  INWARD_GRN: 'v1/procurement-manager/inward-grn',
  PROC_VENDOR_GRN_DETAILS_BY_ID: (id: number) =>
    `/v1/procurement-manager/purchase-order/${id}/grn-details`,
  CREATE_DRAFT_PO: 'v1/procurement-manager/draft-purchase-order',
  VENDOR_BILL: 'v1/procurement-manager/vendor-bill',
  VENDOR_TYPES: 'v1/procurement-manager/vendor-types',

  /* Warehouse Manager Endpoints */
  UNBILLED_VOUCHERS_COUNT:
    'v1/warehouse-manager/unbilled-dispatch-vouchers-count',
  ORDER_HISTORY_LIST: 'v1/warehouse-manager/dispatch-vouchers',
  PICKING_MANAGER_LIST: 'v1/picking-managers',
  ORDER_DETAILS: '/v1/warehouse-manager/dv_order_details',
}

export { URL }
