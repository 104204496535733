/* eslint-disable no-plusplus */
/* eslint-disable no-bitwise */
/* eslint-disable no-var */
/* eslint-disable vars-on-top */
/* eslint-disable no-cond-assign */
/**
 * The code was extracted from:
 * https://github.com/davidchambers/Base64.js
 */

const chars =
  'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/='

function InvalidCharacterError(message) {
  this.message = message
}

InvalidCharacterError.prototype = new Error()
InvalidCharacterError.prototype.name = 'InvalidCharacterError'

function polyfill(input) {
  const str = String(input).replace(/=+$/, '')
  if (str.length % 4 === 1) {
    // TODO: Fix this eslint issue
    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw new InvalidCharacterError(
      "'atob' failed: The string to be decoded is not correctly encoded.",
    )
  }
  for (
    // initialize result and counters
    var bc = 0, bs, buffer, idx = 0, output = '';
    // get next character
    (buffer = str.charAt(idx++));
    // character found in table? initialize bit storage and add its ascii value;
    ~buffer &&
    ((bs = bc % 4 ? bs * 64 + buffer : buffer),
    // and if not first of each 4 characters,
    // convert the first 8 bits to one ascii character
    bc++ % 4)
      ? (output += String.fromCharCode(255 & (bs >> ((-2 * bc) & 6))))
      : 0
  ) {
    // try to find character in table (0-63, not found => -1)
    buffer = chars.indexOf(buffer)
  }
  // TODO: Declared inside for loop but used outside.
  // eslint-disable-next-line block-scoped-var
  return output
}

export default (typeof window !== 'undefined' &&
  window.atob &&
  window.atob.bind(window)) ||
  polyfill
