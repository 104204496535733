import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Animated, Dimensions } from 'react-native'
import { NativeBaseProvider } from 'native-base'
import { TabView, TabBar, SceneMap } from 'react-native-tab-view'

import { fetchVoucher } from 'src/redux/actions'
import { ApplicationState } from 'src/redux/types'
import { VDProps } from 'src/navigations/types'
import STRINGS from 'constants/strings'
import { Voucher } from 'src/modals'

// components
import MoreDetails, {
  MoreDetailsProps,
} from 'src/components/voucher/MoreDetailsTab'
import global from 'styles/global'
import VoucherPrimaryDetails, {
  VoucherPrimaryDetailsProps,
} from './VoucherPrimaryDetails'

// styles
import styles from './style'

const renderScene = SceneMap({
  voucherDetails: ({ route }: VoucherPrimaryDetailsProps) => (
    <VoucherPrimaryDetails route={route} />
  ),
  moreDetails: ({ route }: MoreDetailsProps) => <MoreDetails route={route} />,
})

const VoucherDetails = ({ navigation, route }: VDProps) => {
  const { id } = route.params
  const dispatch = useDispatch()
  const [index, setIndex] = useState<number>(0)
  const [voucher, setVoucher] = useState<Voucher | null>()

  const voucherStore: { voucher: Voucher | null } = useSelector(
    (state: ApplicationState) => state.voucherReducer,
  )

  useEffect(() => {
    setVoucher(voucherStore.voucher)
  }, [voucherStore.voucher])

  useEffect(() => {
    dispatch(fetchVoucher(id))
    // TODO: Provide an explanation for why all deps are not included or fix it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  const renderTabBar = (props: any) => (
    <TabBar
      {...props}
      style={global.bgWhite}
      // TODO: Provide a different variable name route
      // eslint-disable-next-line @typescript-eslint/no-shadow
      renderLabel={({ route, focused }) => (
        <Animated.Text
          style={
            focused
              ? [global.textPrimaryBlack, global.textMedium]
              : [global.textSecondaryGrey, global.textMedium]
          }
        >
          {route.title}
        </Animated.Text>
      )}
      indicatorStyle={styles.tabIndicator}
    />
  )

  const routes = [
    {
      key: 'voucherDetails',
      title: `${STRINGS.VOUCHER.VOUCHER_DETAILS}`,
      voucher,
      navigation,
    },
    {
      key: 'moreDetails',
      title: `${STRINGS.VOUCHER.MORE_DETAILS}`,
      voucher,
    },
  ]

  return (
    <NativeBaseProvider>
      <TabView
        navigationState={{ index, routes }}
        renderScene={renderScene}
        renderTabBar={renderTabBar}
        onIndexChange={setIndex}
        initialLayout={{
          width: Dimensions.get('window').width,
        }}
      />
    </NativeBaseProvider>
  )
}

export default VoucherDetails
