import { StyleSheet } from 'react-native'
import colors from 'styles/colors'

const styles = StyleSheet.create({
  unbilledVoucher: {
    marginTop: 16,
    marginBottom: 8,
  },
  iconRecent: {
    width: 20,
    height: 21,
  },
  marginLeftSeven: {
    marginLeft: 7,
  },
  paddingTopfive: {
    paddingTop: 5,
  },
  itemLine: {
    borderTopColor: colors.bgLightBlue,
    borderTopWidth: 1,
  },
})

export default styles
