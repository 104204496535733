import { StyleSheet } from 'react-native'
import colors from 'styles/colors'

const styles = StyleSheet.create({
  tabIndicator: {
    width: '14%',
    height: 4,
    borderRadius: 2,
    left: '18%',
    backgroundColor: colors.bgLightGreen,
  },
  attributeIcons: {
    width: 26,
    height: 26,
  },
  grnDetailsFooter: {
    borderTopWidth: 1,
    borderTopColor: colors.lightSecondaryGrey,
  },
  grnDetailsFooterButton: {
    height: 45,
  },
  errorIconSize: {
    width: 12,
    height: 12,
  },
  marginLeftFive: {
    marginLeft: 5,
  },
  errorContainer: {
    paddingBottom: 10,
  },
  paddingTopZero: {
    paddingTop: 0,
  },
  paddingTopThree: {
    paddingTop: 3,
  },
})

export default styles
