import React, { ReactElement, useEffect, useState } from 'react'
import { Box, NativeBaseProvider } from 'native-base'
import {
  View,
  Text,
  Pressable,
  ScrollView,
  Image,
  ActivityIndicator,
} from 'react-native'
import { AxiosError, AxiosResponse } from 'axios'
import tw from 'tailwind-react-native-classnames'
import { useSelector } from 'react-redux'
import { APP_AWS_BUCKET } from 'react-native-dotenv'
import moment from 'moment'

import STRINGS from 'constants/strings'
import S3Singleton from 'src/utilities/s3'
import {
  formatterWithoutDecimals,
  showToastNotification,
  utcToLocalTime,
} from 'src/utilities/helpers'
import { ProcVendorBillSummaryProps } from 'src/navigations/types'
import { DOWNLOAD } from 'src/config/download'
import { post, URL } from 'src/utilities/axios'
import { ApplicationState } from 'src/redux/types'
import { voucherDateFormat, voucherTimeFormat } from 'src/config/voucher'
import useDownloadFrmS3 from 'src/utilities/hooks/useDownloadFrmS3'

// components
import Info from 'src/components/drawers/Info'
import Button from 'src/components/form-fields/Button'
import VoucherMetadata from 'src/components/voucher/VoucherMetadata'
import VoucherCard from 'src/components/voucher/VoucherCard'
import Modal from 'src/components/Modal'
import ToastNotification from 'src/components/ToastNotification'
import CardInfo from 'src/components/drawers/CardInfo'
import ItemCard from 'src/screens/procurement-manager/common/ItemCard'

// styles
import global from 'styles/global'
import colors from 'styles/colors'
import style from './style'

const BillSummary = ({ navigation, route }: ProcVendorBillSummaryProps) => {
  const { id } = route.params
  const { downloadDocFrmS3 } = useDownloadFrmS3()
  const [showLoader, setShowLoader] = useState<boolean>(false)
  const [showDrawer, setShowDrawer] = useState<boolean>(false)
  const [selectedGRN, setSelectedGRN] = useState<any>([])
  const [modalBody, setModalBody] = useState<ReactElement>(<></>)
  const [modalType, setModalType] = useState<string>('')
  const [modalFooter, setModalFooter] = useState<ReactElement>(<></>)

  const { grnDetailsByPO, vendorBill } = useSelector(
    (state: ApplicationState) => state.purchaseOrder,
  )

  useEffect(() => {
    if (!grnDetailsByPO?.consolidated_vendor_bill_allowed && id) {
      const filteredGRN = grnDetailsByPO?.grn_details?.filter((item: any) => {
        return item.id === id
      })
      setSelectedGRN(filteredGRN)
    } else {
      setSelectedGRN(grnDetailsByPO?.grn_details)
    }
  }, [
    grnDetailsByPO?.consolidated_vendor_bill_allowed,
    grnDetailsByPO?.grn_details,
    id,
  ])

  const calculateTaxAmount = (item: any) => {
    // eslint-disable-next-line no-useless-escape
    const regex: RegExp = /\@(.*?)\%/
    const regexResult = regex.exec(item?.items[0]?.tax_display_name)
    if (!regexResult) {
      return '0'
    }
    const taxPercentage = item?.items[0]?.tax_display_name ? regexResult[1] : 0
    return (
      (Number(item?.items[0]?.amount) / 100) *
      Number(taxPercentage)
    ).toFixed(2)
  }

  const calculateTotalTaxes = () => {
    return grnDetailsByPO?.product_tax_amount || 0
  }

  const calculateGrandTotal = () => {
    return (grnDetailsByPO?.amount_done || 0) + calculateTotalTaxes()
  }

  const calculateTotalCGST = () => {
    return grnDetailsByPO?.product_cgst_amount || 0
  }

  const calculateTotalSGST = () => {
    return grnDetailsByPO?.product_sgst_amount || 0
  }

  const calculateTotalIGST = () => {
    return grnDetailsByPO?.product_igst_amount || 0
  }

  const calculateTaxBifurcation = () => {
    return (
      grnDetailsByPO?.tax_bifurcation?.child_taxes?.map(taxItem => {
        const result = {}
        if (taxItem?.tax_name.toLowerCase().includes('cgst'))
          Object.assign(result, taxItem, { tax_amount: calculateTotalCGST() })
        if (taxItem?.tax_name.toLowerCase().includes('sgst'))
          Object.assign(result, taxItem, { tax_amount: calculateTotalSGST() })
        if (taxItem?.tax_name.toLowerCase().includes('igst'))
          Object.assign(result, taxItem, { tax_amount: calculateTotalIGST() })
        return result
      }) || []
    )
  }

  const createVendorBill = (UUID: string) => {
    const payload: any = {
      po_id: grnDetailsByPO?.po_id,
      grn_id: grnDetailsByPO?.consolidated_vendor_bill_allowed
        ? grnDetailsByPO?.grn_details?.map((item: any) => item.id)
        : [id],
      payment_term_id:
        grnDetailsByPO?.more_details?.payment_terms &&
        grnDetailsByPO?.more_details.payment_terms[0],
      vendor_invoice_date: moment(vendorBill?.invoiceDate, 'DD-MM-YYYY').format(
        'YYYY-MM-DD',
      ),
      store_fname: UUID,
      vendor_bill_no: vendorBill?.invoiceNumber,
      mimetype: 'image/png',
      file_name: `vendor-bill-${UUID}.jpeg`,
      tax_lines: calculateTaxBifurcation(),
      additional_services: [],
      deductions: [],
    }
    if (vendorBill?.comment) {
      payload.note = vendorBill?.comment
    }
    post(URL.VENDOR_BILL, payload)
      .then((res: AxiosResponse) => {
        setShowLoader(false)
        if (res?.data) {
          setModalType('')
          // eslint-disable-next-line @typescript-eslint/naming-convention
          const { po_id, store_fname, vendor_bill_id } = res.data
          navigation.navigate('SuccessScreen', {
            iconImg: (
              <Image
                source={require('images/success-icon.png')}
                style={tw`h-32 w-32`}
              />
            ),
            customMsg: (
              <Text
                style={[
                  global.textRegular,
                  global.textPrimaryBlack,
                  tw`leading-6 text-sm p-5 text-center flex-row`,
                ]}
              >
                {STRINGS.VENDOR_BILL.VENDOR_BILL_FOR}
                <Text style={tw`font-bold`}>
                  {grnDetailsByPO?.more_details?.vendor_name?.[1] || 'N/A'}
                </Text>
                {STRINGS.VENDOR_BILL.SUCCESS_MSG}
              </Text>
            ),
            greeting: true,
            idFields: [
              {
                title: STRINGS.MISCELLANEOUS.PO_ID_WITH_HYPHEN,
                value: po_id,
              },
              {
                title: STRINGS.MISCELLANEOUS.VENDOR_BILL_ID_WITH_HYPHEN,
                value: vendor_bill_id,
              },
            ],
            footerButtons: [
              {
                title: STRINGS.BUTTON.CREATE_MORE_VENDOR_BILL,
                onClick: () => {
                  navigation.navigate('CreateVendorBill')
                },
                bgColor: global.bgPrimaryGrren.backgroundColor,
                style: tw`w-full`,
              },
              {
                title: STRINGS.BUTTON.DOWNLOAD_VENDOR_BILL,
                onClick: () => {
                  downloadDocFrmS3(
                    store_fname,
                    po_id,
                    STRINGS.MISCELLANEOUS.GRN_DOWNLOAD_MSG,
                  )
                },
                buttonStyle: [
                  tw`rounded-lg border bg-white`,
                  global.borderColorPrimaryGreen,
                ],
                style: tw`w-full`,
                textStyle: [global.textPrimaryGreen],
              },
            ],
            homeButtonStyle: [
              tw`border-0 underline bg-transparent`,
              global.textDecorationColorPrimaryGreen,
            ],
          })
        }
      })
      .catch((error: AxiosError) => {
        setShowLoader(false)
        setModalType('')
        showToastNotification(DOWNLOAD.ERROR, error.response?.data.error)
      })
  }

  const uploadImageToS3 = async () => {
    setShowLoader(true)
    setModalType('')
    const UUID = vendorBill?.image.substring(
      vendorBill?.image.lastIndexOf('/') + 1,
    )
    let response
    try {
      const instance = await S3Singleton.getInstance()
      response = await instance.upload(
        {
          Bucket: APP_AWS_BUCKET,
          Key: UUID,
          Body: vendorBill?.image,
        },
        (err: any, res: any) => {
          if (res?.key) {
            createVendorBill(UUID)
          }
          if (err) {
            setShowLoader(false)
            return Promise.reject(
              showToastNotification(
                DOWNLOAD.ERROR,
                STRINGS.MISCELLANEOUS.S3_IMAGE_UPLOAD_ERROR_MSG,
              ),
            )
          }
          return Promise.resolve(res)
        },
      )
      return await Promise.resolve(response)
    } catch {
      setShowLoader(false)
      return Promise.reject(
        showToastNotification(
          DOWNLOAD.ERROR,
          STRINGS.MISCELLANEOUS.S3_IMAGE_UPLOAD_ERROR_MSG,
        ),
      )
    }
  }

  const modal = () => {
    setModalBody(
      <View style={tw`items-center mt-9`}>
        <Image style={tw`h-12 w-14`} source={require('images/warning.png')} />
        <View style={tw`w-full`}>
          <Text
            style={[
              global.textRegular,
              global.textColorGrey,
              tw`text-sm text-center leading-5 mx-5 mt-7`,
            ]}
          >
            {STRINGS.VENDOR_BILL.CREATE_VENDOR_BILL_CONFIRMATION_MSG}
          </Text>
        </View>
      </View>,
    )
    setModalFooter(
      <View
        style={[
          global.borderColorLightGrey,
          tw`mt-12 flex-row justify-between border-t`,
        ]}
      >
        <Button
          title={STRINGS.BUTTON.NO}
          onTap={() => setModalType('')}
          btnType="secondary"
          style={[
            global.borderColorLightGrey,
            tw`w-1/2 border-0 rounded-bl-lg`,
          ]}
          textStyle={[
            global.textRegular,
            global.textSecondaryOrange,
            tw`text-sm`,
          ]}
        />
        <Button
          title={STRINGS.BUTTON.CREATE}
          onTap={() => uploadImageToS3()}
          btnType="secondary"
          style={[
            global.borderColorLightGrey,
            tw`w-1/2 border-0 rounded-br-lg`,
          ]}
          textStyle={[global.textRegular, tw`text-sm`]}
        />
      </View>,
    )
    setModalType('confirmationPopup')
  }

  const checkQuantityMismatch = (item: any) => {
    if (!item || item?.item === undefined) {
      return false
    }
    return item?.items[0]?.quantity_done > item?.items[0]?.product_uom_qty
  }

  const checkVendorBillAvailable = () => {
    if (
      id &&
      !grnDetailsByPO?.consolidated_vendor_bill_allowed &&
      selectedGRN &&
      selectedGRN[0]?.vendor_bill_available
    ) {
      return true
    }
    return false
  }

  const downloadVendorBill = () => {
    downloadDocFrmS3(
      selectedGRN && selectedGRN[0]?.vendor_bill_available?.store_fname,
      STRINGS.MISCELLANEOUS.GRN_DOWNLOAD_MSG,
    )
  }

  const renderTaxDetails = () => {
    return (
      <View style={tw`w-full`}>
        <View style={tw`flex-row w-full justify-between mb-5`}>
          <Text style={[global.textColorGrey, tw`text-sm`]}>
            {`Product Tax ${
              (grnDetailsByPO?.product_tax_amount ||
                0 / (grnDetailsByPO?.amount_done || 0) ||
                0) * 100
            }%`}
          </Text>
          <Text style={[global.textColorGrey, global.textMedium, tw`text-sm`]}>
            {formatterWithoutDecimals.format(
              Number(grnDetailsByPO?.product_tax_amount),
            )}
          </Text>
        </View>
        <View style={tw`flex-row w-full justify-between mt-2.5`}>
          <Text
            style={[global.textPrimaryBlack, global.textMedium, tw`text-sm`]}
          >
            {STRINGS.VENDOR_BILL.TOTAL_TAXES}
          </Text>
          <Text
            style={[global.textPrimaryBlack, global.textMedium, tw`text-sm`]}
          >
            {formatterWithoutDecimals.format(Number(calculateTotalTaxes()))}
          </Text>
        </View>
      </View>
    )
  }

  return (
    <NativeBaseProvider>
      <ToastNotification />
      <Box flex={1}>
        <ScrollView style={tw`flex-1 bg-white`}>
          <View
            style={[
              global.bgLightGreen,
              tw`px-5 flex-row w-full h-14 justify-between items-center`,
            ]}
          >
            <Text style={tw`text-sm items-center`}>
              <Text style={tw`text-sm font-bold`}>
                {selectedGRN?.length < 10
                  ? `0${selectedGRN?.length}`
                  : `${selectedGRN?.length}`}
              </Text>
              {STRINGS.VENDOR_BILL.GRN_SELECTED}
            </Text>
            <Pressable onPress={() => setModalType('GRNS')}>
              <Text
                style={[
                  global.textRegular,
                  global.textPrimaryGreen,
                  tw`text-xs underline`,
                ]}
                testID="selected-grn-details"
              >
                {STRINGS.BUTTON.VIEW_DETAILS}
              </Text>
            </Pressable>
          </View>
          <View>
            <View
              style={tw`px-5 flex-row w-full pt-5 justify-between items-center`}
            >
              <Text style={[global.textColorGrey, tw`text-sm`]}>
                {STRINGS.VENDOR_BILL.PRODUCT_AMOUNT}
              </Text>
              <Text
                style={[global.textColorGrey, global.textMedium, tw`text-sm`]}
              >
                {formatterWithoutDecimals.format(
                  Number(grnDetailsByPO?.amount_done),
                )}
              </Text>
            </View>
            <View style={tw`pt-12 px-5`}>
              <View style={tw`flex-row w-full justify-between items-center`}>
                <Text
                  style={[
                    global.textPrimaryBlack,
                    global.textMedium,
                    tw`text-sm`,
                  ]}
                >
                  {STRINGS.VENDOR_BILL.TOTAL_TAXES}
                </Text>
                <Text
                  style={[
                    global.textPrimaryBlack,
                    global.textMedium,
                    tw`text-sm`,
                  ]}
                >
                  {calculateTotalTaxes()}
                </Text>
              </View>
              <View style={tw`pt-1 w-full items-end`}>
                <Pressable
                  onPress={() => {
                    setShowDrawer(true)
                  }}
                >
                  <Text
                    style={[
                      global.textRegular,
                      global.textPrimaryGreen,
                      tw`text-xs underline`,
                    ]}
                    testID="tax-details"
                  >
                    {STRINGS.BUTTON.VIEW_DETAILS}
                  </Text>
                </Pressable>
              </View>
            </View>
            <View
              style={tw`px-5 flex-row w-full pt-4 justify-between items-center`}
            >
              <Text style={[global.textColorGrey, tw`text-xs`]}>
                {`CGST ${
                  (calculateTotalCGST() / calculateTotalTaxes() || 0) * 100
                }%`}
              </Text>
              <Text
                style={[global.textColorGrey, global.textMedium, tw`text-xs`]}
              >
                {formatterWithoutDecimals.format(Number(calculateTotalCGST()))}
              </Text>
            </View>
            <View
              style={tw`px-5 flex-row w-full pt-2.5 justify-between items-center`}
            >
              <Text style={[global.textColorGrey, tw`text-xs`]}>
                {`SGST ${
                  (calculateTotalSGST() / calculateTotalTaxes() || 0) * 100
                }%`}
              </Text>
              <Text
                style={[global.textColorGrey, global.textMedium, tw`text-xs`]}
              >
                {formatterWithoutDecimals.format(Number(calculateTotalSGST()))}
              </Text>
            </View>
            <View
              style={tw`px-5 flex-row w-full pt-2.5 pb-2.5 justify-between items-center`}
            >
              <Text style={[global.textColorGrey, tw`text-xs`]}>
                {`IGST ${
                  (calculateTotalIGST() / calculateTotalTaxes() || 0) * 100
                }%`}
              </Text>
              <Text
                style={[global.textColorGrey, global.textMedium, tw`text-xs`]}
              >
                {formatterWithoutDecimals.format(Number(calculateTotalIGST()))}
              </Text>
            </View>
          </View>
        </ScrollView>
        <View
          style={[
            global.bgLightGreen,
            tw`px-5 flex-row w-full h-10 justify-between items-center`,
          ]}
        >
          <Text style={[global.textMedium, tw`text-base items-center`]}>
            {STRINGS.VENDOR_BILL.GRAND_TOTAL}
          </Text>
          <Text style={[global.textMedium, tw`text-base items-center`]}>
            {formatterWithoutDecimals.format(calculateGrandTotal())}
          </Text>
        </View>
        <View
          style={[
            global.borderColorLightSecondaryGrey,
            tw`bg-white py-5 px-5 border-t`,
          ]}
        >
          <Button
            title={
              !checkVendorBillAvailable()
                ? STRINGS.BUTTON.CREATE_VENDOR_BILL
                : STRINGS.BUTTON.DOWNLOAD_VENDOR_BILL
            }
            style={tw`rounded-lg h-11`}
            onTap={!checkVendorBillAvailable() ? modal : downloadVendorBill}
            disable={showLoader}
          />
        </View>
      </Box>
      {showDrawer && (
        <Info
          title={STRINGS.VENDOR_BILL.TAX_DETAILS}
          body={renderTaxDetails()}
          closeDrawer={() => setShowDrawer(false)}
        />
      )}
      {modalType === 'GRNS' && (
        <CardInfo
          header={STRINGS.VENDOR_BILL.SELECTED_GRN_DETAILS}
          bodyStyle={tw`mt-0 pb-12 h-full mx-0`}
          body={
            <ScrollView style={tw`px-5`}>
              {selectedGRN?.map((item: any, index: number) => (
                <View key={item.id}>
                  <VoucherCard
                    header={
                      <View>
                        <VoucherMetadata
                          id={item?.id}
                          attributes={[
                            {
                              attrName: STRINGS.GRNS.GRN_ID,
                              attrValue: item.id,
                              icon: require('images/id.png'),
                              iconStyle: tw`w-7	h-7`,
                              attrContainerStyle: global.flexThree,
                              attrStyle: tw`text-xs`,
                              attrValueStyle: [tw`text-sm`, global.textBold],
                            },
                            {
                              attrName: STRINGS.GRNS.GRN_DATE_AND_TIME,
                              attrValue: `${utcToLocalTime(
                                item?.scheduled_date || '',
                                voucherDateFormat,
                              )}${
                                item.state !== 'assigned'
                                  ? `-${utcToLocalTime(
                                      item?.scheduled_date || '',
                                      voucherTimeFormat,
                                    )}`
                                  : ''
                              }`,
                              icon: require('images/calendar.png'),
                              iconStyle: tw`w-7	h-7`,
                              attrContainerStyle: global.flexFour,
                              attrStyle: tw`text-xs`,
                              attrValueStyle: [tw`text-sm`, global.textBold],
                            },
                          ]}
                          containerStyle={tw`py-5 bg-white`}
                          lineSeparator={false}
                        />
                      </View>
                    }
                    body={
                      <>
                        <ItemCard
                          name={
                            item?.items[0] !== undefined &&
                            item.items[0]?.product_id
                              ? item.items[0]?.product_id[1].toString()
                              : 'N/A'
                          }
                          customUOM={`${
                            item?.items !== undefined &&
                            item?.items[0]?.product_uom
                              ? item?.items[0]?.product_uom[1]
                              : ''
                          }`}
                          tax={item?.items[0]?.tax_display_name || 'N/A'}
                          imageS3Key={item?.items[0]?.product_image}
                          errorQuantityMismatch={checkQuantityMismatch(item)}
                          detailColumns={[
                            {
                              key: STRINGS.GRNS.UNIT_PRICE,
                              value: `₹${
                                item?.items !== undefined
                                  ? item?.items[0]?.price_unit
                                  : 0
                              }`,
                              keyStyle: global.textColorGrey,
                            },
                            {
                              key: STRINGS.GRNS.DEMAND,
                              value:
                                item?.items !== undefined
                                  ? item?.items[0]?.product_uom_qty || 0
                                  : 0,
                              keyStyle: global.textColorGrey,
                            },
                          ]}
                          editableField={{
                            key: STRINGS.GRNS.RECEIVED,
                            value:
                              item?.items !== undefined
                                ? item?.items[0]?.quantity_done || 0
                                : 0,
                            keyStyle: [tw`pr-5`, global.textColorGrey],
                            valueContainerStyle: tw`mt-2.5 justify-start`,
                            valueStyle: global.textMedium,
                          }}
                          isTaxAmountVisible
                          hideReceivedCurrency
                          totalAmount={formatterWithoutDecimals.format(
                            item?.items[0]?.amount || 0,
                          )}
                          containerStyle={[
                            tw`pt-5 border-0 flex-1`,
                            index < selectedGRN.length - 1 &&
                              tw`border-b my-2.5`,
                            global.borderColorSmokeGrey,
                          ]}
                          totalAmountKeyStyle={global.textColorGrey}
                          taxValueStyle={global.textColorGrey}
                          errorMismatchMsg={
                            STRINGS.PURCHASE_ORDER.RECIEVED_QTY_MISMATCH
                          }
                          taxAmount={calculateTaxAmount(item) || '0'}
                        />
                      </>
                    }
                    cardStyle={tw`border-0`}
                  />
                </View>
              ))}
            </ScrollView>
          }
          footer={
            <View
              style={[
                global.borderColorDarkGrey,
                tw`p-5 w-full border-t bg-white flex-row justify-between`,
              ]}
            >
              <View style={[style.footerBtn, tw`w-1/2 pr-2.5`]}>
                <Button
                  title={STRINGS.VENDOR_BILL.CHANGE_SELECTION}
                  onTap={() => navigation.navigate('ProcsPO', { id })}
                  style={tw`rounded-lg`}
                  textStyle={[
                    global.textBlack,
                    global.textPrimaryGreen,
                    tw`text-sm`,
                  ]}
                  btnType="secondary"
                />
              </View>
              <View style={[style.footerBtn, tw`w-1/2 pl-2.5`]}>
                <Button
                  title={STRINGS.VENDOR_BILL.OK}
                  onTap={() => setModalType('')}
                  style={tw`rounded-lg`}
                  textStyle={[global.textBlack, tw`text-sm text-white`]}
                  btnType="primary"
                />
              </View>
            </View>
          }
          closeDrawer={() => setModalType('')}
          containerStyle={[tw`flex-1`, selectedGRN?.length > 1 && tw`h-full`]}
        />
      )}
      {modalType === 'confirmationPopup' && (
        <Modal
          body={modalBody}
          footer={modalFooter}
          close={() => setModalType('')}
          style={tw`p-0`}
        />
      )}
      {showLoader && (
        <View style={tw`justify-center items-center absolute inset-0`}>
          <ActivityIndicator
            size="large"
            color={colors.primaryGreen}
            style={tw`my-4`}
            testID="spinner"
          />
        </View>
      )}
    </NativeBaseProvider>
  )
}

export default BillSummary
