import axios, { AxiosError, AxiosResponse } from 'axios'
import { Platform } from 'react-native'
import * as Sentry from 'sentry-expo'
import AsyncStorage from '@react-native-async-storage/async-storage'

import { BASE_URL } from 'react-native-dotenv'

const axiosInstance = axios.create({
  baseURL: BASE_URL,
})

axiosInstance.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
axiosInstance.defaults.headers.post['Content-Type'] = 'application/json'

// Request Interceptor
axiosInstance.interceptors.request.use(
  async config => {
    const token = await AsyncStorage.getItem('accessToken')
    if (token) {
      config.headers.common.Authorization = `Bearer ${token}`
    }
    return config
  },
  error => Promise.reject(error),
)

// Response Interceptor
axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => response.data,
  (error: AxiosError) => {
    if (Platform.OS === 'windows') Sentry.Browser.captureException(error)
    if (Platform.OS === 'android') Sentry.Native.captureException(error)
    return Promise.reject(error)
  },
)

export { axiosInstance }
