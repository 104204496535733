import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  View,
  Text,
  Image,
  Pressable,
  ActivityIndicator,
  FlatList,
} from 'react-native'
import { Box, NativeBaseProvider } from 'native-base'

import STRINGS from 'constants/strings'
import { ApplicationState } from 'src/redux/types'
import { fetchPendingGRNList, resetGRNList } from 'src/redux/actions'
import { PendingGRNListProps } from 'src/navigations/types'
import { GRN } from 'src/modals'
import {
  formatterWithoutDecimals,
  getEndTruncatedString,
  utcToLocalTime,
} from 'src/utilities/helpers'
import { voucherDateFormat, voucherTimeFormat } from 'src/config/voucher'

// component
import VoucherCard from 'src/components/voucher/VoucherCard'
import Label from 'src/components/card/Label'
import ProfileBar from 'src/components/card/ProfileBar'
import VoucherMetadata from 'src/components/voucher/VoucherMetadata'
import CustomerInformation from 'src/components/CustomerInformation'

// style
import global from 'styles/global'
import colors from 'styles/colors'
import style from './style'

const grnSelector = (state: ApplicationState) => state.grnReducer

const PendingGRNList = ({ navigation }: PendingGRNListProps) => {
  const dispatch = useDispatch()
  const [grns, setGRNs] = useState<GRN[]>([])
  const [offset, setOffset] = useState<number>(0)
  const [numberOfGRNs] = useState<number>(10)
  const [showLoader, setShowLoader] = useState<boolean>(true)
  const [sortingOrder, setSortingOrder] = useState<string>('DESC')

  const { grnList, grnCount } = useSelector(grnSelector)

  useEffect(() => {
    if (grnList) setGRNs(grnList)
    if (grnList.length > 0) setShowLoader(false)
  }, [grnList])

  useEffect(() => {
    dispatch(fetchPendingGRNList(numberOfGRNs, offset, sortingOrder))
    setShowLoader(false)
    // TODO: Provide an explanation for why all deps are not included or fix it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset])

  useEffect(() => {
    return () => {
      dispatch(resetGRNList())
    }
    // TODO: Provide an explanation for why all deps are not included or fix it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const loadMore = () => {
    if (grnList.length !== grnCount && !showLoader) {
      setShowLoader(true)
      setOffset(offset + numberOfGRNs)
    }
  }

  const applyFilter = () => {
    dispatch(resetGRNList())
    if (sortingOrder === 'DESC') setSortingOrder('ASC')
    else setSortingOrder('DESC')
    if (offset === 0) {
      dispatch(fetchPendingGRNList(numberOfGRNs, offset, sortingOrder))
    }
    setOffset(0)
  }

  const renderItem = ({ item }: { item: GRN }) => (
    <Pressable
      onPress={() => navigation.navigate('PendingGRNDetails', { id: item.id })}
      style={[global.vMarginEight, global.hMarginTwenty]}
    >
      <VoucherCard
        header={
          <Label
            image={require('images/tag.png')}
            text={item.origin}
            containerStyle={[global.bgMint, style.label]}
            textStyle={[
              global.textPrimaryBlack,
              global.textMedium,
              global.fontTwelve,
              global.hPaddingFive,
            ]}
            imageStyle={style.calenderIcon}
          />
        }
        body={
          <>
            <CustomerInformation
              customerName={
                item.partner_id[1].toString() ||
                STRINGS.MISCELLANEOUS.NOT_AVAILABLE
              }
              address={item.address}
              style={global.hPaddingTwenty}
            />
            <ProfileBar
              name={item.manager_name}
              imageKey={item?.manager_image || ''}
              designation={item?.designation || 'N/A'}
              mobile={item.mobile}
              containerStyle={global.bgLightBlue}
            />
          </>
        }
        footer={
          <VoucherMetadata
            id={item.id}
            attributes={[
              {
                attrName: STRINGS.GRNS.GRN_DATE,
                attrValue: item.date
                  ? utcToLocalTime(item.date, voucherDateFormat)
                  : getEndTruncatedString(
                      STRINGS.MISCELLANEOUS.DATE_NOTE_AVAILABLE,
                    ),
                icon: require('images/calendar.png'),
                iconStyle: style.attributeIcons,
              },
              {
                attrName: STRINGS.GRNS.GRN_TIME,
                attrValue: item.date
                  ? utcToLocalTime(item.date, voucherTimeFormat)
                  : getEndTruncatedString(
                      STRINGS.MISCELLANEOUS.TIME_NOTE_AVAILABLE,
                    ),
                icon: require('images/clock.png'),
                iconStyle: style.attributeIcons,
              },
              {
                attrName: STRINGS.GRNS.AMOUNT,
                attrValue: formatterWithoutDecimals.format(item.total_amt),
                icon: require('images/currency.png'),
                iconStyle: style.attributeIcons,
              },
            ]}
            containerStyle={[global.bgNone, global.hPaddingSixteen]}
          />
        }
      />
    </Pressable>
  )

  return (
    <NativeBaseProvider>
      <Box flex={1} safeAreaTop>
        <View
          style={[
            global.hMarginTwenty,
            global.directionRow,
            global.justifyContentSpaceBetween,
            global.alignItemsCenter,
            style.header,
          ]}
        >
          <Text
            style={[
              global.textSecondaryGrey,
              global.textRegular,
              global.fontTwelve,
            ]}
          >
            {`${STRINGS.GRNS.TOTAL_PENDING_GRNS}: ${grnCount}`}
          </Text>
          <Pressable
            style={[global.directionRow, global.justifyContentSpaceBetween]}
            onPress={() => applyFilter()}
          >
            <Image
              source={require('images/exchange.png')}
              style={style.iconRecent}
            />
            <Text
              style={[
                global.textMedium,
                global.fontFourteen,
                global.textPrimaryGreen,
                style.marginLeftFive,
              ]}
            >
              {STRINGS.MISCELLANEOUS.SORT_BY_DATE}
            </Text>
          </Pressable>
        </View>
        <FlatList
          keyExtractor={item => `${item.id.toString()}pending-grns`}
          renderItem={renderItem}
          data={grns}
          onEndReached={loadMore}
          onEndReachedThreshold={0.2}
          ListEmptyComponent={
            <ActivityIndicator size="large" color={colors.primaryGreen} />
          }
        />
        {showLoader && grnList.length > 0 && (
          <ActivityIndicator size="large" color={colors.primaryGreen} />
        )}
      </Box>
    </NativeBaseProvider>
  )
}

export default PendingGRNList
