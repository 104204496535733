import React, { useEffect, useState } from 'react'
import { ScrollView, View } from 'react-native'
import { useDispatch } from 'react-redux'

import STRINGS from 'constants/strings'
import { voucherDateFormat, voucherTimeFormat } from 'src/config/voucher'
import {
  formatterWithoutDecimals,
  getEndTruncatedString,
  utcToLocalTime,
} from 'src/utilities/helpers'
import { GRNItems, GRNMoreDetails, GRNProductDetails } from 'src/modals'
import { PendingGRNDetailsScreenNavigationProp } from 'src/navigations/types'

// component
import ProfileBar from 'src/components/card/ProfileBar'
import VoucherMetadata from 'src/components/voucher/VoucherMetadata'
import Button from 'src/components/form-fields/Button'
import DetailItemCard from 'src/components/voucher/DetailItemCard'
import Label from 'src/components/card/Label'

// style
import global from 'styles/global'
import styles from '../style'

export interface PrimaryDetailsProps {
  route: {
    grnDetails: GRNItems[]
    grnMoreDetails: GRNMoreDetails
    navigation: PendingGRNDetailsScreenNavigationProp
  }
}

const PrimaryDetails = ({ route }: PrimaryDetailsProps) => {
  const { grnDetails, grnMoreDetails, navigation } = route
  const dispatch = useDispatch()
  const pendingGrnDetails: any = grnDetails?.length > 0 && grnDetails[0]
  const [errorQuantityMismatch, setErrorQuantityMismatch] = useState<
    Array<string>
  >([])

  const onQuantityEditAtIndex =
    (index: number) => (updatedQuantity: string) => {
      const orderedQuantity =
        pendingGrnDetails?.product_details[index]?.ordered_qty
      if (orderedQuantity) {
        if (
          orderedQuantity !== Number(updatedQuantity) &&
          orderedQuantity < Number(updatedQuantity)
        ) {
          return
        }
        const newErrorQuantityMismatch = [...errorQuantityMismatch]
        if (orderedQuantity > Number(updatedQuantity)) {
          newErrorQuantityMismatch.splice(index, 1, 'true')
          setErrorQuantityMismatch(newErrorQuantityMismatch)
        } else if (orderedQuantity === Number(updatedQuantity)) {
          newErrorQuantityMismatch.splice(index, 1, 'false')
          setErrorQuantityMismatch(newErrorQuantityMismatch)
        }
      }
      dispatch({
        type: 'UPDATE_GRN_RECEIVED_VALUE',
        payload: {
          index,
          updatedValue: Number(updatedQuantity),
        },
      })
    }

  useEffect(() => {
    const productDetails =
      pendingGrnDetails && pendingGrnDetails?.product_details
    if (productDetails && productDetails.length > 0) {
      // TODO: Provide a different name to the variable errorQuantityMismatch
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const errorQuantityMismatch = []
      for (let i = 0; i < productDetails.length; i++) {
        errorQuantityMismatch.push('false')
      }
      setErrorQuantityMismatch(errorQuantityMismatch)
    }
    // TODO: Provide an explanation for why all deps are not included or fix it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendingGrnDetails && pendingGrnDetails.id])

  return pendingGrnDetails && grnMoreDetails ? (
    <View>
      <View style={global.bgLightBlue}>
        <ProfileBar
          name={grnMoreDetails.po_manager?.name}
          imageKey={grnMoreDetails.po_manager?.manager_image}
          designation={grnMoreDetails.po_manager?.designation}
          mobile={grnMoreDetails.po_manager?.mobile}
          containerStyle={global.bgLightBlue}
        />
        <VoucherMetadata
          id={pendingGrnDetails?.id}
          attributes={[
            {
              attrName: STRINGS.GRNS.GRN_DATE,
              attrValue: pendingGrnDetails.write_date
                ? utcToLocalTime(
                    pendingGrnDetails.write_date,
                    voucherDateFormat,
                  )
                : getEndTruncatedString(
                    STRINGS.MISCELLANEOUS.DATE_NOTE_AVAILABLE,
                  ),
              icon: require('images/calendar.png'),
              iconStyle: styles.attributeIcons,
              attrValueStyle: [
                global.fontThirteen,
                global.textBold,
                styles.paddingTopThree,
              ],
            },
            {
              attrName: STRINGS.GRNS.GRN_TIME,
              attrValue: pendingGrnDetails.write_date
                ? utcToLocalTime(
                    pendingGrnDetails.write_date,
                    voucherTimeFormat,
                  )
                : getEndTruncatedString(
                    STRINGS.MISCELLANEOUS.TIME_NOTE_AVAILABLE,
                  ),
              icon: require('images/clock.png'),
              iconStyle: styles.attributeIcons,
              attrValueStyle: [
                global.fontThirteen,
                global.textBold,
                styles.paddingTopThree,
              ],
            },
            {
              attrName: STRINGS.PURCHASE_ORDER.TOTAL_AMOUNT,
              attrValue: formatterWithoutDecimals.format(
                pendingGrnDetails?.grn_amount || 0,
              ),
              icon: require('images/currency.png'),
              iconStyle: styles.attributeIcons,
              attrValueStyle: [
                global.fontThirteen,
                global.textBold,
                styles.paddingTopThree,
              ],
            },
          ]}
          containerStyle={[
            global.bgNone,
            global.hPaddingSixteen,
            styles.paddingTopZero,
          ]}
        />
      </View>
      <View style={[global.flexOne, global.zeroHeight]}>
        <ScrollView>
          {pendingGrnDetails?.product_details?.map(
            (product: GRNProductDetails, index: number) => (
              <DetailItemCard
                // TODO: Need to provide reason for why index key or else fix it
                // eslint-disable-next-line react/no-array-index-key
                key={`${product.id}__${index}`}
                name={product.name}
                imageS3Key={product.product_image}
                customUOM={`${product.product_uom[0]} ${product.product_uom[1]} `}
                tax={product?.tax_details?.display_name}
                onPriceEdit={onQuantityEditAtIndex(index)}
                detailColumns={[
                  {
                    key: STRINGS.GRNS.UNIT_PRICE,
                    value: `₹${product?.price_unit}`,
                  },
                  {
                    key: STRINGS.GRNS.DEMAND,
                    value: product?.ordered_qty,
                  },
                ]}
                editableField={{
                  key: STRINGS.GRNS.RECEIVED,
                  value: product?.quantity_done,
                }}
                totalAmount={formatterWithoutDecimals.format(
                  product?.quantity_done * product?.price_unit || 0,
                )}
              />
            ),
          )}
        </ScrollView>
      </View>
      {errorQuantityMismatch.find(item => item === 'true') && (
        <View>
          <Label
            text={STRINGS.GRNS.QUANTITY_MISMATCH}
            image={require('images/error-info.png')}
            containerStyle={[
              global.widthFull,
              global.bgCandyRed,
              global.borderRadiusZero,
            ]}
            textStyle={[
              global.textRed,
              global.textRegular,
              global.fontTwelve,
              styles.marginLeftFive,
            ]}
            imageStyle={styles.errorIconSize}
          />
        </View>
      )}
      <View
        style={[
          global.bgWhite,
          global.vPaddingTen,
          global.hPaddingTwenty,
          styles.grnDetailsFooter,
        ]}
      >
        <Button
          title={STRINGS.BUTTON.UPLOAD_BILTY_INVOICE}
          style={[global.borderRadiusEight, styles.grnDetailsFooterButton]}
          btnType="primary"
          onTap={() => navigation.navigate('UploadBiltyAndInvoice')}
        />
      </View>
    </View>
  ) : null
}

export default PrimaryDetails
