import React from 'react'
import { View, Image, Text, ImageSourcePropType } from 'react-native'

// styles
import global from 'styles/global'
import style from './style'

interface OrderDateStatusProps {
  status: string
  date: string
  iconPath: ImageSourcePropType
  textColor: object
  dateAligned: boolean
}

const OrderDateStatus = ({
  status,
  date,
  iconPath,
  textColor,
  dateAligned = false,
}: OrderDateStatusProps) => (
  <View style={global.directionRow}>
    <View>
      <Image source={iconPath} style={style.orderStatusIcon} />
    </View>
    <View style={global.marginLeftEight}>
      <Text
        style={[
          global.alignItemsLeft,
          global.fontTwelve,
          global.textBold,
          global.textCapitalize,
          textColor,
        ]}
      >
        {status}
      </Text>
      {!dateAligned && (
        <Text
          style={[
            global.textRegular,
            global.fontTwelve,
            global.textColorGrey,
            global.vPaddingFour,
          ]}
        >
          {`(${date})`}
        </Text>
      )}
    </View>
    {dateAligned && (
      <Text
        style={[
          global.textRegular,
          global.fontTwelve,
          global.textColorGrey,
          global.marginLeftEight,
        ]}
      >
        {`(${date})`}
      </Text>
    )}
  </View>
)

export default OrderDateStatus
