import { StyleSheet } from 'react-native'
import colors from 'styles/colors'

const styles = StyleSheet.create({
  logout: {
    height: 20,
    width: 20,
  },
  avatar: {
    height: 40,
    width: 40,
  },
  cart: {
    height: 24,
    width: 24,
  },
  itemsCountHome: {
    backgroundColor: colors.secondaryOrange,
    color: colors.white,
    width: 16,
    height: 16,
    top: 12,
    right: 8,
  },
  itemsCount: {
    backgroundColor: colors.secondaryOrange,
    color: colors.white,
    width: 16,
    height: 16,
    top: 15,
    right: 8,
  },
  homeHeader: {
    paddingTop: 36,
  },
  warning: {
    width: 50,
    height: 44,
    marginTop: 10,
  },
  warningMsg: {
    marginTop: 34,
  },
  btnContainer: {
    marginTop: 60,
    borderTopWidth: 1,
    borderTopColor: colors.lightGrey,
  },
  btnYesCover: {
    borderWidth: 0,
    borderColor: colors.lightGrey,
  },
  btnNoCover: {
    borderWidth: 0,
    borderLeftWidth: 1,
    borderColor: colors.lightGrey,
  },
  btnNo: {
    color: colors.secondaryOrange,
  },
  userNameContainer: {
    marginLeft: 11,
  },
  locationContainer: {
    marginTop: 3,
  },
  locationIcon: {
    width: 14,
    height: 14,
  },
  location: {
    marginLeft: 3,
  },
  backArrow: {
    width: 20,
    height: 18,
  },
})

export default styles
