import React, { useState } from 'react'
import { Provider } from 'react-redux'
import * as Font from 'expo-font'
import AppLoading from 'expo-app-loading'
import * as Sentry from 'sentry-expo'
import {
  SENTRY_DSN,
  KEYCLOAK_CLIENT_ID,
  KEYCLOAK_REALM,
  KEYCLOAK_URL,
  BUILD_FOR,
} from 'react-native-dotenv'

import { KeycloakProvider } from './src/utilities/keycloak'
import './src/aws-exports'
import AppConfig from './app.json'
import Navigation from './src/navigations'
import { store } from './src/redux/store'
import StorybookUIRoot from './storybook'

const fetchFonts = () => {
  return Font.loadAsync({
    'roboto-black': require('./assets/fonts/Roboto-Black.ttf'),
    'roboto-bold': require('./assets/fonts/Roboto-Bold.ttf'),
    'roboto-light': require('./assets/fonts/Roboto-Light.ttf'),
    'roboto-medium': require('./assets/fonts/Roboto-Medium.ttf'),
    'roboto-regular': require('./assets/fonts/Roboto-Regular.ttf'),
    'roboto-thin': require('./assets/fonts/Roboto-Thin.ttf'),
  })
}

Sentry.init({
  dsn: SENTRY_DSN,
  debug: false, //true if in dev env
})

function App() {
  const [dataLoaded, setDataLoaded] = useState(false)
  if (!dataLoaded) {
    return (
      <AppLoading
        startAsync={fetchFonts}
        onFinish={() => setDataLoaded(true)}
        onError={console.warn}
      />
    )
  }
  const keycloakConfiguration = {
    clientId: KEYCLOAK_CLIENT_ID,
    realm: KEYCLOAK_REALM,
    url: KEYCLOAK_URL,
    scheme: AppConfig.expo.scheme,
    extraParams: {
      scope: "openid offline_access",
    }
  }

  return (
    <KeycloakProvider {...keycloakConfiguration}>
      <Provider store={store}>
        <Navigation />
      </Provider>
    </KeycloakProvider>
  )
}

const ExportedApp = BUILD_FOR == 'storybook' ? StorybookUIRoot : App

export default ExportedApp
