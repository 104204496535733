import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  View,
  Text,
  Image,
  ActivityIndicator,
  Pressable,
  FlatList,
} from 'react-native'
import { NativeBaseProvider, Box, HStack, Center } from 'native-base'

import { fetchProductList, resetProductList } from 'src/redux/actions'
import { sort } from 'src/utilities/helpers'
import { ProductListProps } from 'src/navigations/types'
import STRINGS from 'constants/strings'
import { Product } from 'src/modals'
import { ApplicationState } from 'src/redux/types'

// components
import Button from 'src/components/form-fields/Button'
import Filter from 'src/components/product/Filter'
import ProductCard from 'src/components/product/ProductCard'

// styles
import global from 'styles/global'
import colors from 'styles/colors'
import styles from './style'

interface FilterType {
  id: number
  name: string
  isSelected: boolean
}

interface SortType {
  sort: string
  sortText: string
}

const ProductList = ({ navigation }: ProductListProps) => {
  const dispatch = useDispatch()
  const [productList, setProductList] = useState<Product[]>([])
  const [selectedSort, setSort] = useState<SortType>({
    sort: STRINGS.SORT.A_TO_Z.name,
    sortText: STRINGS.SORT.A_TO_Z.name,
  })
  const [showLoader, setShowLoader] = useState(true)
  const [offset, setOffset] = useState<number>(0)
  const [numberOfProducts] = useState<number>(10)
  const [currentFilter, setCurrentFilter] = useState<string>(
    STRINGS.FILTER_LIST[0].name,
  )

  const productStore = useSelector(
    (state: ApplicationState) => state.productsReducer,
  )
  const cartStore = useSelector((state: ApplicationState) => state.cartReducer)
  const { products } = cartStore

  const applyFilter = (filter: FilterType) => {
    setOffset(0)
    setCurrentFilter(filter.name)
    dispatch(resetProductList())
  }

  const addDefaultVariant = (product: Product) => {
    product.selected_variants = []
    if (product?.variants) {
      let name: string = ''
      let qualityRequest: any
      Object.keys(product.attributes).forEach(
        (attrName: string, index: number) => {
          name += `${index !== 0 ? ', ' : ''}${
            product.attributes[attrName][0].name
          }`
        },
      )
      const dummyVariant = {
        id: product.variants[0].id,
        name,
        price_unit: 0,
        product_uom_qty: 1,
        quality_request: {},
      }
      if (product.quality_attributes_json) {
        Object.keys(product.quality_attributes_json).forEach(
          (keyName: string) => {
            qualityRequest = {
              ...qualityRequest,
              [keyName]: product.quality_attributes_json[keyName][0],
            }
          },
        )
      }
      dummyVariant.quality_request = qualityRequest
      product.selected_variants.push(dummyVariant)
      return product
    }
    return undefined
  }

  const selectProduct = (product: Product) => {
    if (products?.findIndex(item => item.id === product.id) === -1) {
      const productWithDefaultVariant = addDefaultVariant(product)
      dispatch({
        type: 'ADD_TO_CART',
        payload: productWithDefaultVariant,
      })
      dispatch({
        type: 'TOGGLE_PRODUCT',
        payload: product,
      })
    } else {
      dispatch({
        type: 'REMOVE_FROM_CART',
        payload: product,
      })
      dispatch({
        type: 'TOGGLE_PRODUCT',
        payload: product,
      })
    }
  }

  const next = () => {
    if (products.length === 0) {
      return
    }
    navigation.navigate('OrderDetailsEdit')
  }

  const applySort = () => {
    let selected
    if (selectedSort.sort === '') {
      selected = STRINGS.SORT.Z_TO_A.value
      setSort({
        sort: STRINGS.SORT.Z_TO_A.value,
        sortText: STRINGS.SORT.Z_TO_A.name,
      })
    } else if (selectedSort.sort === STRINGS.SORT.A_TO_Z.value) {
      selected = STRINGS.SORT.Z_TO_A.value
      setSort({
        sort: STRINGS.SORT.Z_TO_A.value,
        sortText: STRINGS.SORT.Z_TO_A.name,
      })
    } else {
      selected = STRINGS.SORT.A_TO_Z.value
      setSort({
        sort: STRINGS.SORT.A_TO_Z.value,
        sortText: STRINGS.SORT.A_TO_Z.name,
      })
    }
    dispatch({
      type: 'SET_PRODUCT_LIST',
      payload: sort(selected, productList),
    })
  }

  const loadMore = () => {
    if (productList.length !== productStore.count && !showLoader) {
      setShowLoader(true)
      setOffset(offset + numberOfProducts)
    }
  }

  useEffect(() => {
    dispatch(resetProductList())
    // TODO: Provide an explanation for why all deps are not included or fix it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (productStore.list) {
      if (products.length > 0) {
        products.forEach(product => {
          productStore.list.forEach(addedProduct => {
            if (addedProduct.id === product.id) {
              addedProduct.isSelected = true
            }
          })
        })
      }
      setProductList(productStore.list)
    }
    // TODO: Provide an explanation for why all deps are not included or fix it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productStore.list])

  useEffect(() => {
    if (productList.length > 0) setShowLoader(false)
  }, [productList])

  useEffect(() => {
    dispatch(fetchProductList(currentFilter, offset, numberOfProducts))
    // TODO: Provide an explanation for why all deps are not included or fix it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset])

  return (
    <NativeBaseProvider>
      <Box flex={1} safeAreaTop>
        <Center flex={1} style={[global.hPaddingTwenty]}>
          <View style={[global.directionRow, global.widthFull]}>
            <Filter filterUpdate={(item: FilterType) => applyFilter(item)} />
          </View>
          <View
            style={[
              global.directionRow,
              global.justifyContentSpaceBetween,
              global.vPaddingSixteen,
              global.widthFull,
            ]}
          >
            <Text style={[styles.totalText]}>
              {`${STRINGS.MISCELLANEOUS.TOTAL_PRODUCTS}: ${productStore.count}`}
            </Text>
            <Pressable onPress={() => applySort()}>
              <View style={[global.directionRow]}>
                <Image
                  source={require('images/exchange.png')}
                  style={[styles.exhangeThumbnail]}
                />
                <Text
                  style={[
                    global.fontFourteen,
                    selectedSort.sort === ''
                      ? styles.totalText
                      : styles.AToZText,
                  ]}
                >
                  {selectedSort.sortText}
                </Text>
              </View>
            </Pressable>
          </View>
          <FlatList
            style={[global.widthFull]}
            data={productList}
            renderItem={({ item }) => (
              <View key={item.id}>
                <ProductCard
                  data={item}
                  navigation={navigation}
                  onSelect={() => selectProduct(item)}
                />
              </View>
            )}
            ListEmptyComponent={
              <ActivityIndicator size="large" color={colors.primaryGreen} />
            }
            onEndReachedThreshold={1}
            onEndReached={loadMore}
          />
          {showLoader && productList.length > 0 && (
            <ActivityIndicator size="large" color={colors.primaryGreen} />
          )}
        </Center>
        <HStack safeAreaBottom shadow={6}>
          <View
            style={[
              global.directionRow,
              global.justifyContentSpaceBetween,
              global.bgWhite,
              global.hPaddingTwenty,
              global.widthFull,
              styles.footer,
            ]}
          >
            <View style={[global.vPaddingSixteen]}>
              <Text>{`${products.length} ${STRINGS.MISCELLANEOUS.SELECTED}`}</Text>
            </View>
            <View style={[styles.continueBtn]}>
              <Button
                title={STRINGS.BUTTON.CONTINUE}
                onTap={() => next()}
                style={global.borderRadiusEight}
              />
            </View>
          </View>
        </HStack>
      </Box>
    </NativeBaseProvider>
  )
}

export default ProductList
