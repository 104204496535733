import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  marginBottomThirty: {
    marginBottom: 30,
  },
  marginTopThirtyEight: {
    marginTop: 38,
  },
})

export default styles
