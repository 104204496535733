import { StyleSheet } from 'react-native'
import colors from 'styles/colors'

const style = StyleSheet.create({
  verticleLine: {
    height: '100%',
    width: 1,
    backgroundColor: colors.white,
  },
  calenderIcon: {
    width: 20,
    height: 22,
  },
  clockIcon: {
    width: 22,
    height: 22,
  },
  currencyIcon: {
    width: 14,
    height: 22,
  },
  iconSize: {
    width: 16,
    height: 16,
  },
  iconButtonMain: {
    height: 48,
  },
  textUnderLine: {
    textDecorationLine: 'underline',
  },
  arrowBottom: {
    width: 12,
    height: 7.41,
  },
  showMore: {
    marginBottom: 7,
  },
  warning: {
    width: 50,
    height: 44,
    marginTop: 10,
  },
  warningMsg: {
    marginTop: 30,
    lineHeight: 22,
  },
  btnContainer: {
    marginTop: 34,
    borderTopWidth: 1,
    borderTopColor: colors.lightGrey,
  },
  btnYesCancel: {
    borderWidth: 0,
    borderColor: colors.lightGreen,
  },
  btnNoCancel: {
    borderWidth: 0,
    borderLeftWidth: 1,
    borderColor: colors.lightRed,
  },
  iconHeader: {
    width: 22,
    height: 23,
  },
  headerAttribute: {
    fontSize: 10,
  },
})

export default style
