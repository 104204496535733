import React from 'react'
import { View, Text } from 'react-native'

import STRINGS from 'constants/strings'
import { formatter } from 'src/utilities/helpers'

// styles
import global from 'styles/global'

interface FooterProps {
  amount: number
  textStyle?: object
  containerStyle?: object
}

const TotalAmount = ({ amount, textStyle, containerStyle }: FooterProps) => (
  <View
    style={[
      global.directionRow,
      global.justifyContentSpaceBetween,
      global.alignItemsCenter,
      global.hMarginTwenty,
      global.vPaddingTwenty,
      containerStyle,
    ]}
  >
    <Text style={[global.textRegular, global.fontFourteen, textStyle]}>
      {STRINGS.ORDER_DETAILS.TOTAL_AMOUNT}
    </Text>
    <Text style={[global.textBold, global.fontSixteen, textStyle]}>
      {formatter.format(Number(amount))}
    </Text>
  </View>
)

export default TotalAmount
