import { Box, NativeBaseProvider } from 'native-base'
import React from 'react'
import { View, ScrollView } from 'react-native'
import tw from 'tailwind-react-native-classnames'

import STRINGS from 'constants/strings'
import { getEndTruncatedString, utcToLocalTime } from 'src/utilities/helpers'
import { voucherDateFormat } from 'src/config/voucher'

// components
import VoucherMetadata from 'src/components/voucher/VoucherMetadata'
import Attribute from 'src/components/voucher/VoucherAttribute'
import { ProcGRNDetailsProps } from 'src/modals'

// styles
import global from 'styles/global'
import styles from '../style'

const MoreDetails = ({ route }: ProcGRNDetailsProps) => {
  const { grnDetails } = route
  return (
    <NativeBaseProvider>
      <Box flex={1}>
        <View style={tw`bg-white flex-1`}>
          <ScrollView>
            <View style={[global.bgLightBlue]}>
              <VoucherMetadata
                id={grnDetails?.id}
                attributes={[
                  {
                    attrName: STRINGS.PURCHASE_ORDER.PO_ID_WITHOUT_COLON,
                    attrValue: grnDetails?.po_id,
                    icon: require('images/id-dark-blue.png'),
                    iconStyle: styles.attributeIcons,
                    attrContainerStyle: [tw`text-xs w-1/2`],
                    attrStyle: tw`text-xs`,
                    attrValueStyle: [
                      global.textBold,
                      styles.paddingTopThree,
                      tw`text-sm`,
                    ],
                  },
                  {
                    attrName: STRINGS.PURCHASE_ORDER.QC_RESPONSIBILITY,
                    attrValue: grnDetails?.qc_by || 'N/A',
                    icon: require('images/shield.png'),
                    iconStyle: styles.currencyIcons,
                    attrContainerStyle: tw`w-1/2`,
                    attrStyle: tw`text-xs`,
                    attrValueStyle: [
                      global.textBold,
                      styles.paddingTopThree,
                      tw`text-sm`,
                    ],
                  },
                  {
                    attrName: STRINGS.PURCHASE_ORDER.DEHAAT_WAREHOUSE_LOCATION,
                    attrValue:
                      (grnDetails?.delivery_warehouse?.length &&
                        grnDetails.delivery_warehouse[1]) ||
                      'N/A',
                    icon: require('images/location.png'),
                    iconStyle: styles.attributeIcons,
                    attrContainerStyle: tw` w-full pt-5`,
                    attrStyle: tw`text-xs`,
                    attrValueStyle: [
                      global.textBold,
                      styles.paddingTopThree,
                      tw`text-sm`,
                    ],
                  },
                ]}
                containerStyle={tw`px-4 flex flex-wrap`}
                lineSeparator={false}
              />
            </View>
            <View style={tw`px-4 flex-row flex-wrap`}>
              <Attribute
                attrName={STRINGS.PURCHASE_ORDER.NODE}
                attrStyle={tw`text-xs`}
                attrValue={grnDetails?.node?.length && grnDetails?.node[1]}
                attrValueStyle={[
                  global.textMedium,
                  global.textPrimaryBlack,
                  styles.paddingTopThree,
                  tw`text-sm`,
                ]}
                styles={[
                  styles.marginBottomThirty,
                  styles.marginTopThirtyEight,
                  tw`w-1/2`,
                ]}
              />
              <Attribute
                attrName={STRINGS.PURCHASE_ORDER.PAYMENT_TERMS}
                attrStyle={tw`text-xs`}
                attrValue={
                  (grnDetails?.node?.length && grnDetails?.node[1]) || 'N/A'
                }
                attrValueStyle={[
                  global.textMedium,
                  global.textPrimaryBlack,
                  styles.paddingTopThree,
                  tw`text-sm`,
                ]}
                styles={[
                  styles.marginBottomThirty,
                  styles.marginTopThirtyEight,
                  tw`w-1/2`,
                ]}
              />
              <Attribute
                attrName={STRINGS.PURCHASE_ORDER.VENDOR_NAME}
                attrStyle={tw`text-xs`}
                attrValue={
                  grnDetails?.vendor_name?.length && grnDetails?.vendor_name[1]
                }
                attrValueStyle={[
                  global.textMedium,
                  global.textPrimaryBlack,
                  styles.paddingTopThree,
                  tw`text-sm`,
                ]}
                styles={[tw`w-1/2`, styles.marginBottomThirty]}
              />
              <Attribute
                attrName={STRINGS.PURCHASE_ORDER.PICKUP_LOCATION}
                attrStyle={tw`text-xs flex-shrink`}
                attrValue={
                  grnDetails?.pickup_location?.length &&
                  grnDetails?.pickup_location[1]
                }
                attrValueStyle={[
                  global.textMedium,
                  global.textPrimaryBlack,
                  styles.paddingTopThree,
                  tw`flex-shrink text-sm`,
                ]}
                styles={[tw`w-full flex-shrink`, styles.marginBottomThirty]}
              />
              <Attribute
                attrName={STRINGS.PURCHASE_ORDER.CUSTOMER_DELIVERY_LOCATION}
                attrStyle={tw`text-xs flex-shrink`}
                attrValue={
                  (grnDetails?.customer_delivery_location?.length &&
                    grnDetails?.customer_delivery_location[1]) ||
                  'N/A'
                }
                attrValueStyle={[
                  global.textMedium,
                  global.textPrimaryBlack,
                  styles.paddingTopThree,
                  tw`flex-shrink text-sm`,
                ]}
                styles={[tw`w-full flex-shrink`, styles.marginBottomThirty]}
              />
              <Attribute
                attrName={STRINGS.PURCHASE_ORDER.PICKUP_DATE}
                attrStyle={tw`text-xs`}
                attrValue={
                  grnDetails?.pickup_date
                    ? utcToLocalTime(grnDetails.pickup_date, voucherDateFormat)
                    : getEndTruncatedString(
                        STRINGS.MISCELLANEOUS.DATE_NOTE_AVAILABLE,
                      )
                }
                attrValueStyle={[
                  global.textMedium,
                  global.textPrimaryBlack,
                  styles.paddingTopThree,
                  tw`text-sm`,
                ]}
                styles={[tw`w-1/2`, styles.marginBottomThirty]}
              />
              <Attribute
                attrName={STRINGS.PURCHASE_ORDER.DELIVERY_DATE}
                attrStyle={tw`text-xs`}
                attrValue={
                  grnDetails?.delivery_date
                    ? utcToLocalTime(grnDetails.pickup_date, voucherDateFormat)
                    : getEndTruncatedString(
                        STRINGS.MISCELLANEOUS.DATE_NOTE_AVAILABLE,
                      )
                }
                attrValueStyle={[
                  global.textMedium,
                  global.textPrimaryBlack,
                  styles.paddingTopThree,
                  tw`text-sm`,
                ]}
                styles={[tw`w-1/2`, styles.marginBottomThirty]}
              />
            </View>
          </ScrollView>
        </View>
      </Box>
    </NativeBaseProvider>
  )
}

export default MoreDetails
