import React from 'react'
import { View, Text, Image, Pressable } from 'react-native'
import tw from 'tailwind-react-native-classnames'

import STRINGS from 'constants/strings'

// style
import global from 'styles/global'
import style from '../style'

interface ProductCardProps {
  productName: string
  quantity: string
  pricePerUnit: number
  totalPrice: string
  tax: string
  onEdit?: Function
}

const ProductCard = ({
  productName,
  quantity,
  pricePerUnit,
  totalPrice,
  tax,
  onEdit,
}: ProductCardProps) => {
  return (
    <View style={tw`pl-2`}>
      <Image
        source={require('images/tick-circle.png')}
        style={[tw`absolute left-0`, style.tickIcon]}
      />
      <View style={tw`flex-row pl-5 justify-between`}>
        <Text
          style={[
            global.textPrimaryBlack,
            global.textMedium,
            global.fontSixteen,
          ]}
        >
          {STRINGS.PURCHASE_ORDER.PRODUCT_TAB_TITLE}
        </Text>
        {onEdit && (
          <Pressable style={tw`flex-row items-center`} onPress={() => onEdit()}>
            <Image
              source={require('images/note-orange.png')}
              style={tw`w-3.5 h-3.5 mr-1.5`}
            />
            <Text
              style={[
                tw`underline`,
                global.fontFourteen,
                global.textSecondaryOrange,
                global.textMedium,
              ]}
            >
              {STRINGS.BUTTON.EDIT}
            </Text>
          </Pressable>
        )}
      </View>
      <View
        style={[
          tw`pt-8 pb-16 pl-5 pr-4 border-l`,
          global.borderColorGreenDisabled,
        ]}
      >
        <View>
          <Text style={[tw`text-xs`, global.textColorGrey, global.textRegular]}>
            {STRINGS.PURCHASE_ORDER.PRODUCT_NAME}
          </Text>
          <Text
            style={[
              tw`pt-1.5 text-sm`,
              global.textPrimaryBlack,
              global.textMedium,
            ]}
          >
            {productName}
          </Text>
        </View>
        <View style={tw`flex-row justify-between pt-8`}>
          <View>
            <Text
              style={[tw`text-xs`, global.textColorGrey, global.textRegular]}
            >
              {STRINGS.PURCHASE_ORDER.QUANTITY}
            </Text>
            <Text
              style={[
                tw`pt-1.5 text-sm`,
                global.textPrimaryBlack,
                global.textMedium,
              ]}
            >
              {quantity}
            </Text>
            <View style={tw`pt-8`}>
              <Text
                style={[tw`text-xs`, global.textColorGrey, global.textRegular]}
              >
                {STRINGS.PURCHASE_ORDER.TAX}
              </Text>
              <Text
                style={[
                  tw`pt-1.5 text-sm`,
                  global.textPrimaryBlack,
                  global.textMedium,
                ]}
              >
                {tax}
              </Text>
            </View>
          </View>
          <View>
            <Text
              style={[tw`text-xs`, global.textColorGrey, global.textRegular]}
            >
              {STRINGS.PURCHASE_ORDER.PRICE_PER_UNIT}
            </Text>
            <Text
              style={[
                tw`pt-1.5 text-sm`,
                global.textPrimaryBlack,
                global.textMedium,
              ]}
            >
              {pricePerUnit}
            </Text>
            <View style={tw`pt-8`}>
              <Text
                style={[tw`text-xs`, global.textColorGrey, global.textRegular]}
              >
                {STRINGS.PURCHASE_ORDER.TOTAL_AMOUNT}
              </Text>
              <Text
                style={[
                  tw`pt-1.5 text-sm`,
                  global.textPrimaryBlack,
                  global.textMedium,
                ]}
              >
                {totalPrice}
              </Text>
            </View>
          </View>
        </View>
      </View>
    </View>
  )
}

export default ProductCard
