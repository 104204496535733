import colors from 'styles/colors'
import global from 'styles/global'

const nullifiedObj = {
  iconPath: require('images/nullified.png'),
  color: global.textRed,
}

const orderStatusObj: any = {
  Dispatched: {
    iconPath: require('images/truck.png'),
    color: global.textYellow,
  },
  Delivered: {
    iconPath: require('images/red-tick.png'),
    color: global.textPrimaryGreen,
  },
  Cancelled: {
    iconPath: require('images/close-circle.png'),
    color: global.textRed,
  },
  'Ready for Pickup': {
    iconPath: require('images/pickup.png'),
    color: global.textRed,
  },
  Assigned: {
    iconPath: require('images/tick.png'),
    color: global.textPrimaryGreen,
  },
  // will remove or change accordingly after discussion with pm on monday
  cancel: nullifiedObj,
  open: nullifiedObj,
  draft: nullifiedObj,
}

const iconlabelButtonObj = {
  Invoice: {
    iconPath: require('images/download.png'),
    bgColor: colors.lightGreen,
    textColor: colors.primaryGreen,
  },
  'Credit Note': {
    iconPath: require('images/download.png'),
    bgColor: colors.lightGreen,
    textColor: colors.primaryGreen,
  },
  'Cancel Voucher': {
    iconPath: require('images/close-circle.png'),
    bgColor: colors.lightRed,
    textColor: colors.red,
  },
}

const voucherDateFormat = 'DD/MM/YYYY'

const voucherTimeFormat = 'hh:mm A'

const MAX_ITEM_LIMIT = 3

const customerCreateDateFormat = 'Do MMMM YYYY'

export {
  customerCreateDateFormat,
  orderStatusObj,
  iconlabelButtonObj,
  voucherDateFormat,
  voucherTimeFormat,
  MAX_ITEM_LIMIT,
}
