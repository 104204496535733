import React, { useState } from 'react'
import { View, Text, ScrollView, TouchableHighlight } from 'react-native'

// styles
import global from 'styles/global'
import STRINGS from 'constants/strings'
import styles from './style'

interface FilterProps {
  filterUpdate: Function
}

interface FilterType {
  id: number
  name: string
  isSelected: boolean
}

const Filter = ({ filterUpdate }: FilterProps) => {
  const [filterList, setFilterList] = useState<FilterType[]>(
    STRINGS.FILTER_LIST,
  )

  const applyFilter = (filter: FilterType) => {
    if (filter.isSelected) {
      return
    }
    filterUpdate(filter)
    // FIXME: The code can be reduced to a single for loop
    /**
     * const tempFilter = filterList.map(item => ({...item, isSelected: item.id === filter.id}))
     */
    const tempFilter = [...filterList]
    tempFilter.forEach(item => {
      item.isSelected = false
    })
    tempFilter.forEach(item => {
      if (item.id === filter.id) {
        item.isSelected = true
      }
    })
    setFilterList(tempFilter)
  }

  return (
    <ScrollView horizontal style={[global.vPaddingSixteen]}>
      {filterList.map((filter: FilterType) => (
        <View
          style={[
            global.borderRadiusFour,
            styles.filterContainer,
            filter.isSelected ? styles.selectedFilterBg : global.bgWhite,
          ]}
          key={filter.id}
        >
          <TouchableHighlight onPress={() => applyFilter(filter)}>
            <Text
              style={[
                styles.filterText,
                filter.isSelected && styles.colorWhite,
              ]}
            >
              {filter.name}
            </Text>
          </TouchableHighlight>
        </View>
      ))}
    </ScrollView>
  )
}

export default Filter
