import React, { useState } from 'react'
import { View, Image, Text, Pressable } from 'react-native'
import { Box, NativeBaseProvider } from 'native-base'
import { ScrollView } from 'react-native-gesture-handler'
import tw from 'tailwind-react-native-classnames'

import STRINGS from 'constants/strings'
import { voucherDateFormat, voucherTimeFormat } from 'src/config/voucher'
import { ProcVendorGRNDetailsNavigationProp } from 'src/navigations/types'
import { DownloadInvoiceProps, ProcsPO } from 'src/modals'
import {
  formatterWithoutDecimals,
  getEndTruncatedString,
  utcToLocalTime,
  getPercentage,
} from 'src/utilities/helpers'
import useDownloadFrmS3 from 'src/utilities/hooks/useDownloadFrmS3'

// components
import VoucherMetadata from 'src/components/voucher/VoucherMetadata'
import Button from 'src/components/form-fields/Button'
import DownloadDoc from 'src/components/drawers/DownloadDoc'
import VoucherCard from 'src/components/voucher/VoucherCard'
import ProgressBar from 'src/components/ProgressBar'
import ItemCard from 'src/screens/procurement-manager/common/ItemCard'
import FirstNumberBold from 'src/components/card/FirstNumberBold'
import ButtonWithIcon from 'src/components/form-fields/ButtonWithIcon'
import Label from 'src/components/card/Label'

// styles
import global from 'styles/global'
import colors from 'styles/colors'
import style from './style'

export interface ProcDetailsProps {
  route: {
    grnDetailsByPO: ProcsPO
    navigation: ProcVendorGRNDetailsNavigationProp
  }
}

interface DrawerProps {
  isDrawerOpen: boolean
  drawerDoc: DownloadInvoiceProps[]
  successMsg: string
}

const PrimaryDetails = ({ route }: ProcDetailsProps) => {
  const { grnDetailsByPO, navigation } = route
  const { downloadDocFrmS3 } = useDownloadFrmS3()
  const [drawer, setDrawer] = useState<DrawerProps>({
    isDrawerOpen: false,
    drawerDoc: [],
    successMsg: '',
  })
  const isGRNsCompleted =
    grnDetailsByPO?.grn_total === grnDetailsByPO?.grn_completed

  const getProgressBarColor = (partialValue: number, totalValue: number) => {
    if (partialValue === totalValue) {
      return colors.primaryGreen
    }
    if (partialValue < totalValue) {
      return colors.textGrey
    }
    return `linear-gradient(270deg, #FF7F5C 16.07%, #32A05F 96.43%)`
  }

  const getFooterText = (item: any) => {
    if (item.state === 'assigned') {
      return STRINGS.GRNS.INWARD_GRN
    }
    if (!item.vendor_bill_available) {
      return STRINGS.VENDOR_BILL.CREATE_VENDOR_BILL
    }
    return STRINGS.VENDOR_BILL.VIEW_VENDOR_BILL
  }

  const getFooterNavigation = (item: any) => {
    if (item?.state === 'done' && !item?.vendor_bill_available)
      navigation.navigate('UpdateVendorBill', { id: item.id })
    if (item?.state === 'assigned')
      navigation.navigate('ProcGRNDetails', { id: item?.id })
    if (item.vendor_bill_available)
      navigation.navigate('ProcVendorBillSummary', { id: item?.id })
  }

  const downloadDocs = (item: any) => {
    if (item?.grn_attachments?.length > 1)
      setDrawer({
        isDrawerOpen: true,
        drawerDoc: item?.grn_attachments,
        successMsg: STRINGS.VENDOR_BILL.DOCUMENT_DOWNLOADED,
      })
    else {
      downloadDocFrmS3(
        item?.grn_attachments[0]?.store_fname,
        item?.grn_attachments[0]?.res_name,
        STRINGS.VENDOR_BILL.DOCUMENT_DOWNLOADED,
      )
    }
  }

  const calculateTaxAmount = (item: any) => {
    // eslint-disable-next-line no-useless-escape
    const regex: RegExp = /\@(.*?)\%/
    const regexResult = regex.exec(item?.items[0]?.tax_display_name)
    if (!regexResult) {
      return '0'
    }
    const taxPercentage = item?.items[0]?.tax_display_name ? regexResult[1] : 0
    return (
      (Number(item?.items[0]?.amount) / 100) *
      Number(taxPercentage)
    ).toFixed(2)
  }

  const checkQuantityMismatch = (item: any) => {
    if (!item || item?.item === undefined) {
      return false
    }
    return item?.items[0]?.quantity_done > item?.items[0]?.product_uom_qty
  }

  const cancelledDisclaimerMsg = () => (
    <>
      <View style={tw`flex-col`}>
        <Text style={[global.textRed, global.textRegular, tw`text-xs`]}>
          {STRINGS.VENDOR_BILL.CANCELLED_ORDER_DISCLAIMER}
        </Text>
        <View style={tw`flex-row mt-1`}>
          <Text style={[global.textRed, global.textRegular, tw`text-xs`]}>
            {STRINGS.VENDOR_BILL.QTY_CANCELLED}
          </Text>
          <Text style={[global.textBold, global.textRed, tw`mr-2.5 text-xs`]}>
            {(grnDetailsByPO?.qty_cancelled || 0).toLocaleString()}
          </Text>
          <Text style={[global.textRed, global.textRegular, tw`text-xs`]}>
            {STRINGS.VENDOR_BILL.AMOUNT}
          </Text>
          <Text style={[global.textBold, global.textRed, tw`mr-2.5 text-xs`]}>
            {formatterWithoutDecimals.format(
              grnDetailsByPO?.amount_cancelled || 0,
            )}
          </Text>
        </View>
      </View>
    </>
  )

  return (
    <NativeBaseProvider>
      <Box flex={1}>
        <View style={tw`flex-1`}>
          <ScrollView>
            <VoucherMetadata
              id={Number(grnDetailsByPO?.po_name)}
              attributes={[
                {
                  attrName: STRINGS.PURCHASE_ORDER.DELIVERY_DATE,
                  attrValue: grnDetailsByPO?.date_delivery
                    ? utcToLocalTime(
                        grnDetailsByPO.date_delivery,
                        voucherDateFormat,
                      )
                    : getEndTruncatedString(
                        STRINGS.MISCELLANEOUS.DATE_NOTE_AVAILABLE,
                      ),
                  icon: isGRNsCompleted
                    ? require('images/calendar-black.png')
                    : require('images/calendar.png'),
                  iconStyle: tw`w-7	h-7`,
                  attrContainerStyle: global.flexFour,
                  attrStyle: [
                    tw`text-xs`,
                    isGRNsCompleted && global.textColorGrey,
                  ],
                  attrValueStyle: [
                    tw`text-sm`,
                    global.textBold,
                    isGRNsCompleted && global.textPrimaryBlack,
                  ],
                },
                {
                  attrName: STRINGS.GRNS.COMPLETED_GRNS,
                  attrValue: (
                    <FirstNumberBold
                      first={grnDetailsByPO?.grn_completed || 0}
                      second={
                        (grnDetailsByPO?.grn_total || 0) -
                          (grnDetailsByPO?.grn_cancelled || 0) || 0
                      }
                      separator="/"
                    />
                  ),
                  icon: isGRNsCompleted
                    ? require('images/note-cascade-black.png')
                    : require('images/note_cascade.png'),
                  attrContainerStyle: global.flexFour,
                  iconStyle: tw`w-7 h-7`,
                  attrStyle: [
                    tw`text-xs`,
                    isGRNsCompleted && global.textColorGrey,
                  ],
                  attrValueStyle: [
                    tw`text-sm`,
                    global.textBold,
                    isGRNsCompleted && global.textPrimaryBlack,
                  ],
                },
              ]}
              containerStyle={[
                tw`p-5`,
                global.bgLightBlue,
                isGRNsCompleted && global.bgMint,
              ]}
              lineSeparator={false}
            />
            <View style={tw`p-5 bg-white rounded-b-xl`}>
              <View style={tw`flex-row justify-between pb-1.5`}>
                <Text style={[global.textColorGrey, tw`text-xs`]}>
                  {STRINGS.MISCELLANEOUS.TOTAL_QUANTITY}
                </Text>
                <Text style={[global.textPrimaryBlack, tw`text-xs`]}>
                  <FirstNumberBold
                    first={grnDetailsByPO?.qty_done || 0}
                    second={
                      (grnDetailsByPO?.qty_ordererd || 0) -
                        (grnDetailsByPO?.qty_cancelled || 0) || 0
                    }
                    separator="/"
                  />
                </Text>
              </View>
              <ProgressBar
                fillerColor={getProgressBarColor(
                  grnDetailsByPO?.qty_done || 0,
                  (grnDetailsByPO?.qty_ordererd || 0) -
                    (grnDetailsByPO?.qty_cancelled || 0) || 0,
                )}
                fillerWidth={`${getPercentage(
                  grnDetailsByPO?.qty_done || 0,
                  (grnDetailsByPO?.qty_ordererd || 0) -
                    (grnDetailsByPO?.qty_cancelled || 0) || 0,
                )}%`}
              />
              <View
                style={[
                  tw`pb-2.5`,
                  (grnDetailsByPO?.grn_cancelled || 0) > 0 && tw`pb-5`,
                ]}
              >
                <View style={tw`flex-row justify-between pb-1.5 pt-5`}>
                  <Text style={[global.textColorGrey, tw`text-xs`]}>
                    {STRINGS.PURCHASE_ORDER.TOTAL_AMOUNT}
                  </Text>
                  <Text style={[global.textPrimaryBlack, tw`text-xs`]}>
                    <FirstNumberBold
                      first={grnDetailsByPO?.amount_done || 0}
                      second={
                        (grnDetailsByPO?.total_amount || 0) -
                          (grnDetailsByPO?.amount_cancelled || 0) || 0
                      }
                      separator="/"
                    />
                  </Text>
                </View>
                <ProgressBar
                  fillerColor={getProgressBarColor(
                    grnDetailsByPO?.amount_done || 0,
                    (grnDetailsByPO?.total_amount || 0) -
                      (grnDetailsByPO?.amount_cancelled || 0) || 0,
                  )}
                  fillerWidth={`${getPercentage(
                    grnDetailsByPO?.amount_done || 0,
                    (grnDetailsByPO?.total_amount || 0) -
                      (grnDetailsByPO?.amount_cancelled || 0) || 0,
                  )}%`}
                />
              </View>
              {(grnDetailsByPO?.grn_cancelled || 0) > 0 && (
                <Label
                  text={cancelledDisclaimerMsg()}
                  image={require('images/info-red.png')}
                  containerStyle={[global.bgCandyRed, tw`mt-2.5 px-2.5`]}
                  textStyle={[
                    global.textRed,
                    global.textRegular,
                    tw`text-xs mt-5`,
                    style.marginLeftFive,
                  ]}
                  imageStyle={tw`w-4 h-4`}
                />
              )}
            </View>
            <View style={tw`pb-7`}>
              {grnDetailsByPO?.grn_details?.map((item, index) => (
                <View key={item.id} style={tw`px-2.5 pt-4`}>
                  {item.state !== 'cancel' && (
                    <VoucherCard
                      header={
                        <View>
                          <VoucherMetadata
                            id={item?.id || index}
                            attributes={[
                              {
                                attrName:
                                  item.state === 'assigned'
                                    ? STRINGS.PURCHASE_ORDER.PO_ID_WITHOUT_COLON
                                    : STRINGS.GRNS.GRN_ID,
                                attrValue:
                                  item.state === 'assigned'
                                    ? item.origin
                                    : item.id,
                                icon: require('images/id.png'),
                                attrContainerStyle: global.flexThree,
                                iconStyle: tw`w-7 h-7`,
                                attrStyle: tw`text-xs`,
                                attrValueStyle: [tw`text-sm`, global.textBold],
                              },
                              {
                                attrName:
                                  item.state === 'assigned'
                                    ? STRINGS.PURCHASE_ORDER.DELIVERY_DATE
                                    : STRINGS.GRNS.GRN_DATE_AND_TIME,
                                attrValue: `${utcToLocalTime(
                                  item?.scheduled_date || '',
                                  voucherDateFormat,
                                )}${
                                  item.state !== 'assigned'
                                    ? `-${utcToLocalTime(
                                        item?.scheduled_date || '',
                                        voucherTimeFormat,
                                      )}`
                                    : ''
                                }`,
                                icon: require('images/calendar.png'),
                                attrContainerStyle: global.flexFour,
                                iconStyle: tw`w-7 h-7`,
                                attrStyle: tw`text-xs`,
                                attrValueStyle: [tw`text-sm`, global.textBold],
                              },
                            ]}
                            containerStyle={[
                              tw`py-5 mb-2.5 px-2.5`,
                              global.bgSecondaryLightGrey,
                            ]}
                            lineSeparator={false}
                          />
                          {item.state !== 'assigned' && (
                            <View style={tw`p-2.5 flex-row justify-between`}>
                              <View style={tw`flex-row items-center`}>
                                <Image
                                  source={require('images/blue-tick.png')}
                                  style={tw`w-5 h-5`}
                                />
                                <Text
                                  style={[
                                    tw`font-bold pl-2.5 text-xs`,
                                    global.textSecondaryBlue,
                                    global.textMedium,
                                  ]}
                                >
                                  {STRINGS.VENDOR_BILL.INWARDED}
                                </Text>
                              </View>
                              <Pressable
                                style={tw`flex-row items-center`}
                                onPress={() => downloadDocs(item)}
                              >
                                <Image
                                  source={require('images/download.png')}
                                  style={tw`w-5 h-5`}
                                />
                                <Text
                                  style={[
                                    tw`pl-1.5 text-xs underline`,
                                    global.textPrimaryGreen,
                                    global.textMedium,
                                  ]}
                                >
                                  {STRINGS.VENDOR_BILL.DOCUMENTS}
                                </Text>
                              </Pressable>
                            </View>
                          )}
                        </View>
                      }
                      body={
                        <>
                          {item?.items !== undefined && (
                            <ItemCard
                              name={
                                item?.items[0] !== undefined &&
                                item.items[0]?.product_id
                                  ? item.items[0]?.product_id[1].toString()
                                  : 'N/A'
                              }
                              customUOM={`${
                                item?.items !== undefined &&
                                item?.items[0]?.product_uom
                                  ? item?.items[0]?.product_uom[1]
                                  : ''
                              }`}
                              tax={item?.items[0]?.tax_display_name || 'N/A'}
                              imageS3Key={item?.items[0]?.product_image}
                              errorQuantityMismatch={checkQuantityMismatch(
                                item,
                              )}
                              detailColumns={[
                                {
                                  key: STRINGS.GRNS.UNIT_PRICE,
                                  value: `₹${
                                    item?.items !== undefined
                                      ? item?.items[0]?.price_unit
                                      : 0
                                  }`,
                                  keyStyle: [global.textColorGrey],
                                },
                                {
                                  key: STRINGS.GRNS.DEMAND,
                                  value:
                                    item?.items !== undefined
                                      ? item?.items[0]?.product_uom_qty || 0
                                      : 0,
                                  keyStyle: [global.textColorGrey],
                                },
                              ]}
                              editableField={{
                                key: STRINGS.GRNS.RECEIVED,
                                value:
                                  item?.items !== undefined
                                    ? item?.items[0]?.quantity_done || 0
                                    : 0,
                                keyStyle: [tw`pr-5`, global.textColorGrey],
                                valueContainerStyle: [tw`mt-2.5 justify-start`],
                                valueStyle: [global.textMedium],
                              }}
                              isTaxAmountVisible
                              hideReceivedCurrency
                              totalAmount={formatterWithoutDecimals.format(
                                item?.items[0]?.amount || 0,
                              )}
                              containerStyle={tw`pt-5 mt-2.5 px-2.5 border-0`}
                              totalAmountKeyStyle={global.textColorGrey}
                              taxValueStyle={global.textColorGrey}
                              errorMismatchMsg={
                                STRINGS.PURCHASE_ORDER.RECIEVED_QTY_MISMATCH
                              }
                              taxAmount={calculateTaxAmount(item) || '0'}
                            />
                          )}
                        </>
                      }
                      footer={
                        <ButtonWithIcon
                          icon={require('images/arrow-right.png')}
                          text={getFooterText(item)}
                          textStyle={[
                            global.textPrimaryGreen,
                            global.textMedium,
                            tw`text-sm font-bold no-underline`,
                          ]}
                          imageStyle={tw`w-5 h-4`}
                          onClick={() => getFooterNavigation(item)}
                          imageAfterText
                          containerStyle={[
                            tw`px-4 py-5 flex-row justify-between`,
                            global.bgMint,
                          ]}
                        />
                      }
                      cardStyle={global.borderColorDarkGrey}
                    />
                  )}
                </View>
              ))}
            </View>
          </ScrollView>
          {grnDetailsByPO?.consolidated_vendor_bill_allowed && (
            <View
              style={[
                tw`bg-white py-5 px-5 border-t`,
                global.borderColorLightSecondaryGrey,
              ]}
            >
              <Button
                title={STRINGS.VENDOR_BILL.CREATE_VENDOR_BILL_FOR_ALL_GRNS(
                  grnDetailsByPO?.grn_details?.length || 0,
                )}
                style={[tw`rounded-lg`, global.buttonHeight]}
                onTap={() =>
                  navigation.navigate('UpdateVendorBill', { id: undefined })
                }
              />
            </View>
          )}
          {drawer.isDrawerOpen && (
            <DownloadDoc
              closeDrawer={() =>
                setDrawer({
                  isDrawerOpen: false,
                  drawerDoc: [],
                  successMsg: '',
                })
              }
              docs={drawer.drawerDoc}
              successMsg={drawer.successMsg}
            />
          )}
        </View>
      </Box>
    </NativeBaseProvider>
  )
}

export default PrimaryDetails
