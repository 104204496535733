import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  headerIcon: {
    width: 12,
    height: 12,
    marginRight: 6,
  },
  marginRightTwenty: {
    marginRight: 20,
  },
  paddingRightFifteen: {
    paddingRight: 15,
  },
  marginTopfive: {
    marginTop: 4,
  },
})

export default styles
