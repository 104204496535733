import React from 'react'
import { Image, View, Modal, Pressable, Dimensions } from 'react-native'
import { NativeBaseProvider, Box, HStack, Center } from 'native-base'
import tw from 'tailwind-react-native-classnames'

import STRINGS from 'constants/strings'

import { showToastNotification } from 'src/utilities/helpers'
import { DOWNLOAD } from 'src/config/download'

// components
import Button from 'src/components/form-fields/Button'

// styles
import global from 'styles/global'
import style from './style'

interface ImagePreviewerOrSelectorProps {
  closePreview: Function
  successMsg?: string
  buttonText?: string
  uploadImage?: Function
  image?: string
  onRetryButton?: Function
  retryButtonText?: string
  imageResolution?: {
    width: number
    height: number
  }
}

const ImagePreviewerOrSelector = ({
  closePreview,
  buttonText,
  uploadImage,
  successMsg,
  image,
  onRetryButton,
  retryButtonText,
  imageResolution,
}: ImagePreviewerOrSelectorProps) => {
  const getSize = () => {
    const maxHeight = Dimensions.get('window').height - 40
    const maxWidth = Dimensions.get('window').width
    const width = imageResolution?.width || 0
    const height = imageResolution?.height || 0
    let ratio
    if (width > height) ratio = Math.min(maxWidth / width, maxHeight / height)
    else ratio = Math.min(maxHeight / height, maxWidth / width)
    return { width: width * ratio, height: height * ratio }
  }

  return (
    <Modal
      statusBarTranslucent
      presentationStyle="pageSheet"
      visible
      onRequestClose={() => closePreview(false)}
    >
      <NativeBaseProvider>
        <Box flex={1} safeAreaTop>
          <View style={[global.alignItemsRight, tw`my-5`]}>
            <Pressable
              onPress={() => closePreview(false)}
              style={global.paddingSixteen}
            >
              <Image
                source={require('images/red-cancel.png')}
                style={style.iconCancel}
              />
            </Pressable>
          </View>
          <Center flex={1}>
            <Image source={{ uri: image }} style={{ ...getSize() }} />
          </Center>
          <HStack safeAreaBottom shadow={6}>
            {buttonText && uploadImage && (
              <View
                style={[
                  global.borderColorDarkGrey,
                  tw`p-5 justify-between w-full flex-row bg-white border-t`,
                ]}
              >
                {onRetryButton && (
                  <View style={[style.footerBtn, tw`w-1/2 pr-2.5`]}>
                    <Button
                      title={
                        retryButtonText === STRINGS.BUTTON.RETAKE_PHOTO
                          ? STRINGS.BUTTON.RETAKE_PHOTO
                          : STRINGS.BUTTON.GO_TO_GALLERY
                      }
                      onTap={() => {
                        if (retryButtonText === STRINGS.BUTTON.RETAKE_PHOTO) {
                          onRetryButton(true)
                        } else {
                          onRetryButton()
                        }
                        closePreview(false)
                      }}
                      style={[global.borderRadiusEight, tw`text-sm`]}
                      btnType="secondary"
                    />
                  </View>
                )}
                <View style={[style.footerBtn, tw`w-1/2 pl-2.5`]}>
                  <Button
                    title={buttonText}
                    onTap={() => {
                      uploadImage(image)
                      closePreview(false)
                      if (successMsg) {
                        showToastNotification(DOWNLOAD.SUCCESS, successMsg)
                      }
                    }}
                    style={[global.borderRadiusEight, tw`text-sm`]}
                  />
                </View>
              </View>
            )}
          </HStack>
        </Box>
      </NativeBaseProvider>
    </Modal>
  )
}

export default ImagePreviewerOrSelector
