import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  tickIcon: {
    width: 16,
    height: 16,
  },
  status: {
    paddingLeft: 20,
    width: 125,
  },
  card: {
    marginTop: 15,
    marginHorizontal: 20,
  },
})

export default styles
