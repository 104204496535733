import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { View, Text } from 'react-native'

import {
  fetchUserDetails,
  fetchOutstandingAmount,
  fetchSBOutstandingAmount,
  fetchUnbilledDVCount,
  fetchPendingGRNCount,
  fetchUnbilledVoucherCount,
} from 'src/redux/actions'
import STRING from 'constants/strings'
import { HomeProps } from 'src/navigations/types'
import { ApplicationState } from 'src/redux/types'

// components
import SalesManager from 'src/screens/common/Home/SalesManager'
import SalesBiller from 'src/screens/common/Home/SalesBiller'
import InventoryManager from 'src/screens/common/Home/InventoryManager'
import ProcurementManager from 'src/screens/common/Home/ProcurementManager'
import globals from 'styles/global'
import WarehouseManager from './WarehouseManager'

// styles
import styles from './style'

const Home = ({ navigation }: HomeProps) => {
  const dispatch = useDispatch()

  const { userDetails } = useSelector(
    (state: ApplicationState) => state.userReducer,
  )

  useEffect(() => {
    dispatch(fetchUserDetails())
    // TODO: Provide an explanation for why all deps are not included or fix it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (userDetails?.groups.includes('FarmsnFarmers / OMS Sales Manager')) {
      dispatch(fetchOutstandingAmount())
    } else if (
      userDetails?.groups.includes('FarmsnFarmers / OMS Sales Biller')
    ) {
      dispatch(fetchSBOutstandingAmount())
      dispatch(fetchUnbilledDVCount())
    } else if (
      userDetails?.groups.includes('FarmsnFarmers / OMS Inventory Manager')
    ) {
      dispatch(fetchPendingGRNCount())
    } else if (userDetails?.name === 'OMS Warehouse Manager') {
      dispatch(fetchUnbilledVoucherCount())
    }
  }, [dispatch, userDetails])

  return (
    <View>
      <View style={globals.paddingTwenty}>
        <Text style={[globals.textBold, styles.welcomeMsg]}>
          {STRING.HOME.WELCOME}
        </Text>
        <Text style={[globals.textBold, styles.welcomeMsg]}>
          {userDetails?.name}
        </Text>
      </View>
      <View>
        {userDetails?.groups.includes('FarmsnFarmers / OMS Sales Manager') && (
          <SalesManager navigation={navigation} />
        )}
        {userDetails?.groups.includes('FarmsnFarmers / OMS Sales Biller') && (
          <SalesBiller navigation={navigation} />
        )}
        {userDetails?.groups.includes(
          'FarmsnFarmers / OMS Inventory Manager',
        ) && <InventoryManager navigation={navigation} />}
        {userDetails?.groups.includes('FarmsnFarmers / OMS Picker') && (
          <View>
            <Text>Picker Tiles</Text>
          </View>
        )}
        {userDetails?.groups.includes(
          'FarmsnFarmers / OMS Warehouse Manager',
        ) && <WarehouseManager navigation={navigation} />}
        {userDetails?.groups.includes(
          'FarmsnFarmers / OMS Procurement Manager',
        ) && <ProcurementManager navigation={navigation} />}
      </View>
    </View>
  )
}

export default Home
