import React, { useEffect, useState } from 'react'
import {
  View,
  Text,
  Image,
  Pressable,
  ActivityIndicator,
  FlatList,
} from 'react-native'
import { Badge, Box, NativeBaseProvider, VStack } from 'native-base'
import { useDispatch, useSelector } from 'react-redux'
import tw from 'tailwind-react-native-classnames'

import STRINGS from 'constants/strings'
import { get, URL } from 'src/utilities/axios'
import { POListProps } from 'src/navigations/types'
import { ApplicationState } from 'src/redux/types'
import { ProcsPO } from 'src/modals/purchase-order'
import {
  fetchProcsPO,
  resetPurchaseOrderList,
} from 'src/redux/actions/purchase-order'
import { voucherDateFormat } from 'src/config/voucher'
import { getEndTruncatedString, utcToLocalTime } from 'src/utilities/helpers'
import { FilterPayloadProps } from 'types/filter'

// components
import HorizontalScrollableList, {
  FilterType,
} from 'src/components/filters/HorizontalScrollableList'
import CustomerInformation from 'src/components/CustomerInformation'
import VoucherCard from 'src/components/voucher/VoucherCard'
import Label from 'src/components/card/Label'
import VoucherMetadata from 'src/components/voucher/VoucherMetadata'
import { Sort } from 'src/components/drawers'
import FilterEngine, {
  initialFilter,
} from 'src/components/drawers/FilterEngine'
import SearchBox from 'src/components/SearchBox'
import FirstNumberBold from 'src/components/card/FirstNumberBold'

// style
import global from 'styles/global'
import colors from 'styles/colors'
import style from './style'

interface POReducerType {
  purchaseOrderList: ProcsPO[] | null
  totalPurchaseOrderCount: number | null
  isPOListLoading: boolean
}

const listFetchLimit: number = 10
const sortConfig = {
  items: [
    {
      id: 'delivery_date',
      name: STRINGS.SORT.DELIVERY_DATE,
    },
    {
      id: 'create_date',
      name: STRINGS.SORT.CREATED_DATE,
    },
    {
      id: 'latest_grn_update_date',
      name: STRINGS.SORT.LATEST_UPDATED_GRN_DATE,
    },
  ],
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CreateVendorBill = ({ navigation }: POListProps) => {
  const dispatch = useDispatch()
  const [filterList, setFilterList] = useState<Array<FilterType>>([])
  const [searchInput, setSearchInput] = useState<string>('')
  const [listOffset, setListOffset] = useState<number>(0)
  const [selectedNode, setSelectedNode] = useState<string | undefined>()
  const [showFilter, setShowFilter] = useState<boolean>(false)
  const [appliedFilter, setAppliedFilter] =
    useState<FilterPayloadProps>(initialFilter)
  const [isFilterApplied, setIsFilterApplied] = useState<boolean>(false)
  const [showSortDrawer, setShowSortDrawer] = useState<boolean>(false)
  const [sort, selectSort] = useState<string | null>()
  const {
    purchaseOrderList,
    totalPurchaseOrderCount,
    isPOListLoading,
  }: POReducerType = useSelector(
    (state: ApplicationState) => state.purchaseOrder,
  )

  useEffect(() => {
    dispatch(
      fetchProcsPO({
        limit: listFetchLimit,
        offset: listOffset,
        query: searchInput.length > 3 ? searchInput : undefined,
        node: selectedNode,
        sort_by: sort ? sort.split('/')[0] : undefined,
        order: sort ? sort.split('/')[1] : undefined,
        ...(isFilterApplied ? appliedFilter?.apiFilterPayload : {}),
      }),
    )
    // TODO: Provide an explanation for why all deps are not included or fix it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listOffset])

  useEffect(() => {
    return () => {
      dispatch(resetPurchaseOrderList())
    }
  }, [dispatch])

  useEffect(() => {
    get(URL.NODE_LIST).then((res: any) => {
      res?.data.map((item: FilterType) => {
        item.isSelected = false
        return item
      })
      res.data.splice(0, 0, { id: 0, name: 'All', isSelected: true })
      setFilterList(res.data)
    })
  }, [])

  const loadNextPage = () => {
    if (
      purchaseOrderList!.length !== totalPurchaseOrderCount &&
      !isPOListLoading
    ) {
      setListOffset(listOffset + listFetchLimit)
    }
  }

  const applySort = (selectedValue: string, sortBy: string, order: string) => {
    selectSort(selectedValue)
    dispatch(resetPurchaseOrderList())
    if (listOffset === 0) {
      dispatch(
        fetchProcsPO({
          limit: listFetchLimit,
          offset: listOffset,
          query: searchInput.length > 3 ? searchInput : undefined,
          node: selectedNode,
          sort_by: sortBy,
          order,
          ...appliedFilter?.apiFilterPayload,
        }),
      )
      return
    }
    setListOffset(0)
  }

  const selectFilter = (filter: FilterType) => {
    const selectedIndex = filterList.findIndex(
      (item: FilterType) => item.id === filter.id,
    )
    const copyOfFilterList: Array<FilterType> = [...filterList]
    if (copyOfFilterList[selectedIndex].isSelected) {
      return
    }
    copyOfFilterList.map((item: FilterType) => {
      item.isSelected = false
      return item
    })
    copyOfFilterList[selectedIndex].isSelected = true
    setSelectedNode(filter.name)
    setFilterList(copyOfFilterList)
    dispatch(resetPurchaseOrderList())
    if (listOffset === 0) {
      dispatch(
        fetchProcsPO({
          limit: listFetchLimit,
          offset: listOffset,
          query: searchInput.length > 3 ? searchInput : undefined,
          node: filter.name === 'All' ? undefined : filter.name,
          sort_by: sort ? sort.split('/')[0] : undefined,
          order: sort ? sort.split('/')[1] : undefined,
          ...(isFilterApplied ? appliedFilter?.apiFilterPayload : {}),
        }),
      )
      return
    }
    setListOffset(0)
  }

  const fetchPOByText = (text: string) => {
    setSearchInput(text)
    if (text.length >= 3) {
      dispatch(resetPurchaseOrderList())
      if (listOffset !== 0) {
        setListOffset(0)
        return
      }
      dispatch(
        fetchProcsPO({
          limit: listFetchLimit,
          offset: listOffset,
          query: text.length > 3 ? text : undefined,
          node: selectedNode === 'All' ? undefined : selectedNode,
          sort_by: sort ? sort.split('/')[0] : undefined,
          order: sort ? sort.split('/')[1] : undefined,
          ...(isFilterApplied ? appliedFilter?.apiFilterPayload : {}),
        }),
      )
    }
  }

  const applyFilter = (selectedFilter: FilterPayloadProps) => {
    dispatch(resetPurchaseOrderList())
    setIsFilterApplied(true)
    setAppliedFilter(selectedFilter)
    setShowFilter(false)
    if (listOffset !== 0) {
      setListOffset(0)
      return
    }
    dispatch(
      fetchProcsPO({
        limit: listFetchLimit,
        offset: listOffset,
        sort_by: sort ? sort.split('/')[0] : undefined,
        order: sort ? sort.split('/')[1] : undefined,
        ...selectedFilter?.apiFilterPayload,
      }),
    )
  }

  const renderItem = ({ item, index }: { item: ProcsPO; index: number }) => (
    <Pressable
      onPress={() => navigation.navigate('ProcsPO', { id: item?.id || index })}
      style={tw`my-2 mx-5`}
    >
      <VoucherCard
        header={
          <Label
            image={require('images/id-black.png')}
            text={item.po_name}
            containerStyle={[global.bgMint, style.label]}
            textStyle={[
              global.textPrimaryBlack,
              global.textMedium,
              global.hPaddingFive,
              tw`text-xs rounded-bl-none rounded-tl-none`,
            ]}
            imageStyle={tw`w-3 h-3`}
          />
        }
        body={
          <CustomerInformation
            customerName={item?.vendor_name || 'N/A'}
            address={item.node}
            style={tw`pt-2.5 px-5`}
            product={item.product_info?.name.replace(/\n/g, ' ') || 'N/A'}
            quantity={
              item.product_info
                ? `(${item.product_info?.product_quantity}${item.product_info?.product_quantity})`
                : undefined
            }
            productImage={require('images/product.png')}
            addressIconStyle={tw`w-3.5 h-3.5`}
            addressTextStyle={tw`text-sm`}
            qcBy={item?.product_info?.qc_by || 'N/A'}
            qcImage={require('images/qc-blue.png')}
          />
        }
        footer={
          <>
            <VoucherMetadata
              id={item?.id || index}
              attributes={[
                {
                  attrName: STRINGS.PURCHASE_ORDER.DELIVERY_DATE,
                  attrValue: item.date_delivery
                    ? utcToLocalTime(item.date_delivery, voucherDateFormat)
                    : getEndTruncatedString(
                        STRINGS.MISCELLANEOUS.DATE_NOTE_AVAILABLE,
                      ),
                  icon: require('images/calendar.png'),
                  iconStyle: tw`w-7 h-7`,
                  attrStyle: tw`text-sm`,
                  attrContainerStyle: global.flexFive,
                },
                {
                  attrName: STRINGS.PURCHASE_ORDER.TOTAL_AMOUNT,
                  attrValue: Intl.NumberFormat('en-IN', {
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0,
                  }).format(item?.total_amount || 0),
                  icon: require('images/currency.png'),
                  iconStyle: tw`w-7 h-7`,
                  attrStyle: tw`text-sm`,
                  attrContainerStyle: global.flexFive,
                },
              ]}
              containerStyle={[global.bgNone, tw`px-4 pt-5`]}
            />
            <VoucherMetadata
              id={item.id || 0}
              attributes={[
                {
                  attrName: STRINGS.GRNS.COMPLETED_GRNS,
                  attrValue: (
                    <FirstNumberBold
                      first={item.grn_completed || 0}
                      second={item.grn_total || 0}
                      separator="/"
                    />
                  ),
                  icon: require('images/note_cascade.png'),
                  iconStyle: tw`w-7 h-7`,
                  attrStyle: tw`text-sm`,
                  attrValueStyle: tw`text-sm`,
                  attrContainerStyle: global.flexFive,
                },
                {
                  attrName: STRINGS.VENDOR_BILL.VENDOR_BILL,
                  attrValue: item.vendor_bill_count || 'None',
                  icon: require('images/note-currency.png'),
                  iconStyle: tw`w-7 h-7`,
                  attrStyle: tw`text-sm`,
                  attrValueStyle: [global.textBold, tw`text-sm`],
                  attrContainerStyle: global.flexFive,
                },
              ]}
              containerStyle={[
                global.bgNone,
                item.grn_completed !== item.grn_total
                  ? global.bgSecondaryLightGrey
                  : global.bgMint,
                tw`pt-5 rounded-b-lg px-4`,
              ]}
              lineSeparator={false}
            />
          </>
        }
      />
    </Pressable>
  )

  return (
    <NativeBaseProvider>
      <Box flex={1} safeAreaTop>
        <View style={tw`flex-row p-5 items-center`}>
          <SearchBox
            placeholder={STRINGS.PURCHASE_ORDER.SEARCH_BY_VENDOR}
            searchInput={searchInput}
            onchangeFunc={fetchPOByText}
          />
          <Pressable
            onPress={() => {
              setShowFilter(true)
            }}
            style={tw`ml-5`}
          >
            <VStack>
              {Object.keys(appliedFilter.apiFilterPayload).length > 0 && (
                <Badge
                  variant="solid"
                  style={[
                    global.bgPrimaryGrren,
                    style.iconPosition,
                    tw`rounded-full absolute self-end z-10`,
                  ]}
                >
                  <Text style={[global.fontTen, tw`font-bold text-white`]}>
                    {Object.keys(appliedFilter.apiFilterPayload).length}
                  </Text>
                </Badge>
              )}
              <Image
                source={require('images/filter-orange.png')}
                style={tw`w-5 h-5`}
              />
            </VStack>
          </Pressable>
        </View>
        <View>
          {filterList.length > 1 && (
            <HorizontalScrollableList
              filterList={filterList}
              setFilter={selectFilter}
            />
          )}
          <View style={tw`flex-row justify-between pb-3 pt-5 px-5`}>
            <Text
              style={[
                global.textSecondaryGrey,
                global.textRegular,
                tw`text-xs`,
              ]}
            >
              {`${STRINGS.MISCELLANEOUS.POS}: ${totalPurchaseOrderCount}`}
            </Text>
            <Pressable
              style={tw`flex-row`}
              onPress={() => setShowSortDrawer(true)}
            >
              <Image
                source={require('images/exchange-orange.png')}
                style={tw`w-3.5 h-3.5`}
              />
              <Text
                style={[
                  global.textSecondaryOrange,
                  global.textRegular,
                  tw`text-xs ml-1.5`,
                ]}
              >
                {STRINGS.MISCELLANEOUS.SORT}
              </Text>
            </Pressable>
          </View>
        </View>
        <FlatList
          keyExtractor={item => `${(item?.id || 0).toString()}po`}
          renderItem={renderItem}
          data={purchaseOrderList}
          onEndReached={loadNextPage}
          onEndReachedThreshold={0.2}
        />
        {isPOListLoading && (
          <ActivityIndicator size="large" color={colors.primaryGreen} />
        )}
        {showFilter && (
          <FilterEngine
            title={STRINGS.MISCELLANEOUS.FILTERS}
            handleClose={() => setShowFilter(false)}
            getSelectedFilters={(selectedFilters: any) =>
              applyFilter(selectedFilters)
            }
            selectedFilters={appliedFilter}
            apiGetCount={`${URL.PROCS_PO_LIST}?query=${
              searchInput.length > 3 ? searchInput : ''
            }&node=${selectedNode === undefined ? '' : selectedNode}&sort_by=${
              sort ? sort.split('/')[0] : ''
            }&order=${sort ? sort.split('/')[1] : ''}`}
            apiGetCountEndPoint="total_count"
            filterConfig={[
              {
                title: `${STRINGS.FILTER.PO_STATUS}`,
                id: 'po_status',
                filterList: [
                  {
                    displayName: `${STRINGS.FILTER.COMPLETE_GRNS}`,
                    id: 'complete_grn',
                  },
                  {
                    displayName: `${STRINGS.FILTER.IN_PROGRESS_GRNS}`,
                    id: 'in_progress_grn',
                  },
                ],
              },
              {
                filterList: [
                  {
                    displayName: `${STRINGS.FILTER.BEFORE_TODAY}`,
                    id: 'before_today',
                  },
                  { displayName: `${STRINGS.FILTER.TODAY}`, id: 'today' },
                  { displayName: `${STRINGS.FILTER.TOMORROW}`, id: 'tomorrow' },
                  { displayName: `${STRINGS.FILTER.NEXT_7_DAYS}`, id: 'week' },
                  {
                    displayName: `${STRINGS.FILTER.CUSTOM_DATE}`,
                    id: 'calendar',
                    type: 'calendar',
                  },
                ],
                id: 'delivery_date',
                title: `${STRINGS.FILTER.DELIVERY_DATE}`,
                filterPayloadType: 'array',
              },
              {
                filterList: [
                  { displayName: `${STRINGS.FILTER.BY_DEHAAT}`, id: 'dehaat' },
                  {
                    displayName: `${STRINGS.FILTER.BY_SUPPLIER}`,
                    id: 'supplier',
                  },
                ],
                title: `${STRINGS.FILTER.QC_RESPONSIBILITY}`,
                id: 'qc_by',
              },
              {
                filterList: [
                  { displayName: `${STRINGS.FILTER.YOURSELF}`, id: 'yourself' },
                  { displayName: `${STRINGS.FILTER.MIS_TEAM}`, id: 'others' },
                ],
                title: `${STRINGS.FILTER.PO_CREATED_BY}`,
                id: 'created_by',
              },
            ]}
          />
        )}
        {showSortDrawer && (
          <Sort
            closeDrawer={setShowSortDrawer}
            applySort={applySort}
            items={sortConfig.items}
            selectedValue={sort}
          />
        )}
      </Box>
    </NativeBaseProvider>
  )
}

export default CreateVendorBill
