const FILTER_DATE_FORMAT = 'YYYY-MM-DD'

const FILTER_DATE_BUTTON_FORMAT = 'DD MMM'

const CALENDAR_TYPE = 'calendar'

const DEFAULT_BUTTON_ID = 'defaultButtonId'

export {
  FILTER_DATE_FORMAT,
  FILTER_DATE_BUTTON_FORMAT,
  CALENDAR_TYPE,
  DEFAULT_BUTTON_ID,
}
