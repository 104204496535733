import { useEffect, useState } from 'react'
import { DownloadInvoiceProps, InvoiceAttachment } from 'src/modals'

import { get } from 'src/utilities/axios'
import {
  CustomDownloadButton,
  CustomDownloadButtonConfig,
  IconButton,
} from 'types/success'
import useDownloadFrmS3 from './useDownloadFrmS3'

const initialDownloadDrawerConfig: DownloadDrawerProps = {
  isDrawerOpen: false,
  drawerDoc: [],
  successMsg: '',
  close: () => {},
}

interface DownloadDrawerProps {
  isDrawerOpen: boolean
  drawerDoc: DownloadInvoiceProps[]
  successMsg?: string
  close: Function
}

const useSetDownloadConfig = (props: CustomDownloadButtonConfig) => {
  const { url, buttonConfig } = props
  const [downloadButtonConfig, setDownloadButtonConfig] = useState<
    IconButton[]
  >([])
  const [downloadDrawerConfig, setDownloadDrawerConfig] =
    useState<DownloadDrawerProps>(initialDownloadDrawerConfig)
  const { downloadDocFrmS3 } = useDownloadFrmS3()

  const onDownloadButtonClick = (
    config: CustomDownloadButton,
    downloadData: InvoiceAttachment[],
  ) => {
    const filteredDoc =
      (downloadData?.length > 0 &&
        downloadData?.filter(
          (doc: InvoiceAttachment) => doc?.type === config?.type,
        )) ||
      []
    if (filteredDoc?.length > 1) {
      // TODO: Rename the variable
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const downloadDrawerConfig = {
        isDrawerOpen: true,
        drawerDoc: filteredDoc,
        successMsg: config?.successMsg,
        close: () => setDownloadDrawerConfig(initialDownloadDrawerConfig),
      }
      setDownloadDrawerConfig(downloadDrawerConfig)
    } else if (filteredDoc?.length === 1) {
      downloadDocFrmS3(
        filteredDoc[0]?.store_fname,
        config?.fileName,
        config?.successMsg,
      )
    }
  }

  const createCustomButton = (data: InvoiceAttachment[]) => {
    const customButton: IconButton[] = []
    buttonConfig?.forEach((config: CustomDownloadButton) => {
      const isDocAvilable = data.find((item: any) => item.type === config.type)
      if (isDocAvilable) {
        customButton.push({
          title: config?.title,
          iconPath: config?.buttonIcon,
          onClick: () => onDownloadButtonClick(config, data),
          style: config?.buttonStyle,
        })
      }
    })
    return customButton
  }

  useEffect(() => {
    if (url && buttonConfig) {
      get(url)
        .then(data => {
          if (data?.data?.length > 0) {
            const customButton = createCustomButton(data.data)
            setDownloadButtonConfig(customButton)
          }
        })
        .catch(error => console.error(error))
    }
    // TODO: Provide an explanation for why all deps are not included or fix it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, buttonConfig])

  return { downloadButtonConfig, downloadDrawerConfig }
}

export default useSetDownloadConfig
