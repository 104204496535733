import { StyleSheet } from 'react-native'

import colors from 'styles/colors'

const styles = StyleSheet.create({
  input: {
    maxWidth: '100%',
    minWidth: 40,
  },
  inputContainer: {
    borderWidth: 1,
    borderColor: colors.borderGrey,
  },
})

export default styles
