import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  profileIcon: {
    height: 36,
    width: 36,
    borderRadius: 20,
  },
  phoneIcon: {
    width: 12,
    height: 12,
  },
  paddingTopTwo: {
    paddingTop: 2,
  },
})

export default styles
