import 'intl'
import 'intl/locale-data/jsonp/en'
import Toast from 'react-native-toast-message'
import moment from 'moment'
import { Dimensions, Platform, StatusBar } from 'react-native'

import STRINGS from 'constants/strings'
import { DOWNLOAD } from 'src/config/download'
import { voucherDateFormat } from 'src/config/voucher'
import { DownloadInvoiceProps, Product } from 'src/modals'

const sort = (type: string, arr: any[]) =>
  arr.sort((a, b) => {
    if (a.name < b.name) {
      return type === STRINGS.SORT.A_TO_Z.value ? -1 : 1
    }
    if (a.name > b.name) {
      return type === STRINGS.SORT.A_TO_Z.value ? 1 : -1
    }
    return 0
  })

const formatter = new Intl.NumberFormat('en-IN', {
  style: 'currency',
  currency: 'INR',
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
})

const formatterWithoutDecimals = new Intl.NumberFormat('en-IN', {
  style: 'currency',
  currency: 'INR',
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
})

const currentTimeStamp = () => {
  return Math.round(
    new Date(
      new Date().getTime() + new Date().getTimezoneOffset() * 60000,
    ).getTime() / 1000,
  )
}

const ifArraysEqual = (a: Array<number>, b: Array<number>) => {
  if (a === b) return true
  if (a == null || b == null) return false
  if (a.length !== b.length) return false
  for (let i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false
  }
  return true
}

const getVariantsCount = (products: Product[] = []) => {
  let count = 0
  // FIXME: reduce would be the right way of handling it
  products.forEach(product => {
    count += product?.selected_variants.length
  })
  return count
}

const utcToLocalTime = (utcDate: string, format: string) => {
  if (utcDate) {
    const localTime = moment.utc(utcDate).local().format(format)
    return localTime
  }
  return STRINGS.MISCELLANEOUS.DATE_NOTE_AVAILABLE
}

const formatMoment = (date = moment(), format = 'DD-MM-YYYY') =>
  moment(date, 'DD-MM-YYYY').format(format)

const dateSort = (arr: any[], key: string) => {
  arr.sort((a, b) => new Date(b[key]).valueOf() - new Date(a[key]).valueOf())
  return arr
}

const isPhoneNumberValid = (phoneNumber: string) => {
  const reg = new RegExp(/^[1-9]\d{9}$/)
  return reg.test(phoneNumber)
}

const isEmailValid = (email: string) => {
  // https://stackoverflow.com/a/46181/6119373
  const reg = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  )
  return reg.test(email)
}

const isDecimalNumberValid = (number: string) =>
  // eslint-disable-next-line no-useless-escape
  new RegExp(/^[\d]{0,10}([\.]\d{0,3})?$/).test(number)

const getTruncatedString = (str: string) =>
  `${str.substring(0, 4)}...${str.substring(str.length - 4, str.length)}`

const getEndTruncatedString = (str: string) =>
  str.length < 15 ? str : `${str.substring(0, 11)}...`

const showToastNotification = (
  type: string,
  sucessMessage: string,
  position: any = DOWNLOAD.POSTION_TOP,
  visibilityTime: number = DOWNLOAD.TOAST_VISIBILITY_TIME,
) =>
  Toast.show({
    type,
    position,
    visibilityTime,
    props: {
      successMsg: sucessMessage,
      isVisible: true,
    },
  })

const getDocName = (doc: DownloadInvoiceProps) => {
  if (doc?.type === DOWNLOAD.INVOICE_DOC_TYPE)
    return `${STRINGS.INVOICE.INVOICE} (${utcToLocalTime(
      doc?.date_invoice,
      voucherDateFormat,
    )}) - ${DOWNLOAD.INVOICE_DOCUMENT}-${doc.id}`
  if (doc?.type === DOWNLOAD.CREDIT_DOC_TYPE)
    return `${STRINGS.INVOICE.CREDIT_NOTE} (${utcToLocalTime(
      doc?.date_invoice,
      voucherDateFormat,
    )}) - ${DOWNLOAD.CREDIT_DOCUMENT}-${doc.id}`
  return DOWNLOAD.DOCUMENT
}

let timeout: any
const debounce = (func: Function, wait: number, immediate?: boolean) => {
  return (...args: any) => {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      timeout = null
      if (!immediate) func(...args)
    }, wait)
    if (immediate && !timeout) func(...args)
  }
}

const onLayoutChange = (event: any, headerMargin: number) => {
  const { height } = event.nativeEvent.layout
  const windowHeight = Dimensions.get('window').height
  const statusBarHeight = StatusBar.currentHeight as number
  const topHeaderMargin = headerMargin

  const topRemovableHeight =
    Platform.OS !== 'web' ? statusBarHeight + topHeaderMargin : topHeaderMargin

  if (height > windowHeight - topRemovableHeight) {
    return windowHeight - topRemovableHeight
  }
  return height
}

const getPercentage = (partialValue: number, totalValue: number) =>
  totalValue !== 0 ? (100 * partialValue) / totalValue : 100

export {
  sort,
  formatter,
  currentTimeStamp,
  ifArraysEqual,
  getVariantsCount,
  formatterWithoutDecimals,
  utcToLocalTime,
  dateSort,
  isPhoneNumberValid,
  isEmailValid,
  getTruncatedString,
  getEndTruncatedString,
  getDocName,
  showToastNotification,
  isDecimalNumberValid,
  debounce,
  onLayoutChange,
  getPercentage,
  formatMoment,
}
