import produce from 'immer'

import { GRN, GRNDetails, ProcImageDoc, ProcTransportDetails } from 'src/modals'
import { GRNActions } from 'src/redux/types'

type GRNState = {
  grnCount: number | null
  grnList: GRN[]
  grnDetails: GRNDetails
  qcParams: Object
  quantityDone: string | number
  productId: number | string
  moveId: number | string
  transportDetails: ProcTransportDetails
  documents: ProcImageDoc
  error: string | null
}

const initialState = {
  grnCount: null,
  grnList: [] as GRN[],
  grnDetails: {} as GRNDetails,
  qcParams: {},
  quantityDone: '',
  productId: '',
  moveId: '',
  transportDetails: {} as ProcTransportDetails,
  documents: {} as ProcImageDoc,
  error: '',
}

const GRNReducer = (state: GRNState = initialState, action: GRNActions) => {
  switch (action.type) {
    case 'SET_GRN_COUNT': {
      return {
        ...state,
        grnCount: action.payload,
      }
    }
    case 'APPEND_GRN_LIST': {
      return {
        ...state,
        grnList: [...(state.grnList || []), ...action.payload],
      }
    }
    case 'RESET_GRN_LIST': {
      return {
        ...state,
        grnList: initialState.grnList,
        grnCount: initialState.grnCount,
      }
    }
    case 'SET_GRN_DETAILS': {
      return {
        ...state,
        grnDetails: action.payload,
      }
    }
    case 'SET_INWARD_GRN_QC_PARAMS': {
      return produce(state, proxy => {
        proxy.qcParams = action.payload
      })
    }
    case 'SET_RECEIVED_QUANTITY_DONE': {
      return produce(state, proxy => {
        proxy.quantityDone = action.payload
      })
    }
    case 'SET_PRODUCT_ID': {
      return produce(state, proxy => {
        proxy.productId = action.payload
      })
    }
    case 'SET_MOVE_ID': {
      return produce(state, proxy => {
        proxy.moveId = action.payload
      })
    }
    case 'SET_TRANSPORT_DETAILS': {
      return {
        ...state,
        transportDetails: action.payload,
      }
    }
    case 'UPDATE_GRN_RECEIVED_VALUE': {
      return produce(state, proxy => {
        const { index, updatedValue } = action.payload
        proxy.grnDetails!.items![0].product_details![index].quantity_done =
          updatedValue
      })
    }
    case 'SET_UPLOADED_DOCUMENTS': {
      return {
        ...state,
        documents: action.payload,
      }
    }
    case 'RESET_UPLOADED_DOCUMENTS': {
      return {
        ...state,
        documents: action.payload,
      }
    }
    case 'SET_ERROR': {
      return {
        ...state,
        error: action.payload,
      }
    }
    default:
      return state
  }
}

export { GRNReducer }
