import { StyleSheet } from 'react-native'
import colors from 'styles/colors'

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.bgGreen,
  },
  cloud: {
    width: '100%',
    height: '100%',
    right: 0,
    top: 0,
  },
  tileText: {
    width: 68,
    paddingTop: 12,
  },
  topMargin: {
    marginTop: 20,
  },
  zIndexOne: {
    zIndex: 1,
  },
  dropShadow: {
    shadowColor: colors.dropShadow,
    shadowOffset: { width: 4, height: 6 },
    shadowOpacity: 0.15,
    shadowRadius: 19,
    elevation: 27,
  },
})

export default styles
