import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Animated, Dimensions } from 'react-native'
import { NativeBaseProvider } from 'native-base'
import { TabView, TabBar, SceneMap } from 'react-native-tab-view'

import { InvoiceDetailsProps } from 'src/navigations/types'
import { Invoice, Voucher } from 'src/modals'
import { fetchInvoiceById, fetchVoucher } from 'src/redux/actions'
import STRINGS from 'constants/strings'
import { ApplicationState } from 'src/redux/types'

// components
import MoreDetails from 'src/components/voucher/MoreDetailsTab'

// styles
import global from 'styles/global'
import PrimaryDetails, { PrimaryDetailsProps } from './PrimaryDetails'
import styles from './style'

const Strings = STRINGS.INVOICE

const renderScene = SceneMap({
  invoiceDetails: ({ route }: PrimaryDetailsProps) => (
    <PrimaryDetails route={route} />
  ),
  moreDetails: ({ route }) => <MoreDetails route={route} />,
})

const InvoiceDetails = ({ navigation, route }: InvoiceDetailsProps) => {
  const { id } = route.params
  const [index, setIndex] = useState<number>(0)
  const [invoice, setInvoice] = useState<Invoice | null>()
  const [voucher, setVoucher] = useState<Voucher | null>()
  const dispatch = useDispatch()

  const invoiceStore: { invoice: Invoice | null } = useSelector(
    (state: ApplicationState) => state.invoiceReducer,
  )

  const voucherStore: { voucher: Voucher | null } = useSelector(
    (state: ApplicationState) => state.voucherReducer,
  )

  useEffect(() => {
    if (voucherStore.voucher) {
      setVoucher(voucherStore.voucher)
    }
  }, [voucherStore.voucher])

  useEffect(() => {
    if (invoiceStore.invoice) {
      setInvoice(invoiceStore.invoice)
      dispatch(fetchVoucher(Number(invoiceStore.invoice.dv_id![0])))
    }
  }, [dispatch, invoiceStore.invoice])

  useEffect(() => {
    dispatch(fetchInvoiceById(id))
    // TODO: Provide an explanation for why all deps are not included or fix it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  const renderTabBar = (props: any) => (
    <TabBar
      {...props}
      style={global.bgWhite}
      // TODO: Provide different name to the route variable
      // eslint-disable-next-line @typescript-eslint/no-shadow
      renderLabel={({ route, focused }) => (
        <Animated.Text
          style={
            focused
              ? [global.textPrimaryBlack, global.textMedium]
              : [global.textSecondaryGrey, global.textMedium]
          }
        >
          {route.title}
        </Animated.Text>
      )}
      indicatorStyle={styles.tabIndicator}
    />
  )

  const routes = [
    {
      key: 'invoiceDetails',
      title: `${Strings.INVOICE_DETAILS}`,
      invoice,
      voucher,
      navigation,
    },
    {
      key: 'moreDetails',
      title: `${Strings.MORE_DETAILS}`,
      voucher,
    },
  ]

  return (
    <NativeBaseProvider>
      <TabView
        navigationState={{ index, routes }}
        renderScene={renderScene}
        renderTabBar={renderTabBar}
        onIndexChange={setIndex}
        initialLayout={{
          width: Dimensions.get('window').width,
        }}
      />
    </NativeBaseProvider>
  )
}

export default InvoiceDetails
