import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  filterButton: {
    height: 38,
  },
  applyButton: {
    height: 45,
    width: 180,
  },
  clearAllButton: {
    height: 45,
    width: 120,
  },
  filterDrawer: {
    height: 'auto',
  },
})

export default styles
