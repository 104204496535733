import React, { useState, useEffect } from 'react'
import { View, ScrollView, ActivityIndicator } from 'react-native'

import { Order } from 'src/modals'

// components
import CustomerCard from 'src/components/CustomerCard'

// styles
import global from 'styles/global'
import colors from 'styles/colors'
import FooterButton from './FooterButton'

export interface TabProps {
  route: {
    orderList: Order[]
    navigation: {
      navigate: Function
    }
  }
}

const Tab = ({ route }: TabProps) => {
  const { orderList, navigation } = route
  const [showLoader, setShowLoader] = useState<boolean>(true)

  useEffect(() => {
    if (orderList.length > 0) setShowLoader(false)
    else setShowLoader(true)
  }, [orderList.length])

  return (
    <ScrollView>
      <View style={[global.paddingTwenty]}>
        {orderList?.map((order: Order) => (
          <CustomerCard
            key={order.id}
            data={order.customer}
            navigation={navigation}
            voucherCount={order.dispatch_voucher_count}
            orderId={order.id}
            footerButton={
              <FooterButton voucherCount={order.dispatch_voucher_count} />
            }
          />
        ))}
      </View>
      {showLoader && (
        <ActivityIndicator size="large" color={colors.primaryGreen} />
      )}
    </ScrollView>
  )
}

export default Tab
