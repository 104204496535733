import React from 'react'
import { View, Text, TextInput, Image } from 'react-native'
import tw from 'tailwind-react-native-classnames'

import { formatterWithoutDecimals } from 'src/utilities/helpers'
import STRINGS from 'constants/strings'

// components
import S3Image from 'src/components/S3Image'
import Label from 'src/components/card/Label'

// styles
import global from 'styles/global'
import style from './style'

const Strings = STRINGS.VOUCHER

interface ItemCardProps {
  name: string
  tax: string
  onPriceEdit?(text: string): void
  imgUrl?: string
  imageS3Key?: string
  customUOM?: string
  detailColumns?: Array<{
    key: string
    value: number | string
    keyStyle?: Object
    valueStyle?: Object
  }>
  editableField?: {
    key: string
    value: number | string
    keyStyle?: Object
    valueStyle?: Object
    valueContainerStyle?: Object
  }
  totalAmount?: number | string
  doneQty?: number
  unitOfMeasure?: string
  unitPrice?: number
  errorQuantityMismatch?: Boolean
  isTaxAmountVisible?: Boolean
  hideReceivedCurrency?: Boolean
  containerStyle?: Object
  totalAmountKeyStyle?: Object
  taxValueStyle?: Object
  errorMismatchMsg?: string
  taxAmount?: string
  maxLength?: number
}

const ItemCard = ({
  name,
  tax,
  onPriceEdit,
  imgUrl,
  imageS3Key,
  customUOM,
  detailColumns,
  editableField,
  totalAmount,
  doneQty,
  unitOfMeasure,
  unitPrice,
  errorQuantityMismatch,
  isTaxAmountVisible,
  hideReceivedCurrency,
  containerStyle,
  totalAmountKeyStyle,
  taxValueStyle,
  errorMismatchMsg,
  taxAmount,
  maxLength,
}: ItemCardProps) => {
  const calculateTaxAmount = () => {
    // eslint-disable-next-line no-useless-escape
    const regex: RegExp = /\@(.*?)\%/
    const taxPercentage = tax ? regex.exec(tax)?.[1] : 0
    return (
      (Number(totalAmount?.toString().replace(/,/g, '').slice(1)) / 100) *
      Number(taxPercentage)
    ).toFixed(2)
  }

  return (
    <View
      style={[tw`pt-2.5 bg-white border-t`, style.itemLine, containerStyle]}
    >
      <View style={tw`flex-row justify-between`}>
        <View style={style.itemThumbnail}>
          {imgUrl ? (
            <Image
              source={{ uri: `data:image/png;base64, ${imgUrl}` }}
              style={[tw`rounded-lg`, style.itemThumbnail]}
            />
          ) : (
            <S3Image
              imageKey={imageS3Key || ''}
              style={[tw`rounded-lg`, style.itemThumbnail]}
            />
          )}
        </View>
        <View style={[style.itemDetails, tw`pl-2.5`]}>
          <View style={tw`flex-row`}>
            <Text
              style={[
                global.textSecondaryBlue,
                tw`text-xs font-medium capitalize`,
              ]}
            >
              {name}
            </Text>
            <Text
              style={[
                global.textPrimaryBlack,
                global.textBold,
                style.marginLeftFive,
                tw`text-xs capitalize`,
              ]}
            >
              ({customUOM || `${Strings.UOM}${unitOfMeasure}`})
            </Text>
          </View>
          <View style={[tw`mt-1.5 flex-row justify-between`]}>
            {detailColumns &&
              detailColumns.length > 0 &&
              detailColumns.map((column, index) => (
                // TODO: Need to provide reason for why index key or else fix it
                // eslint-disable-next-line react/no-array-index-key
                <View key={`${column.value}${index}`}>
                  <Text
                    style={[
                      tw`text-xs`,
                      global.textPink,
                      global.textRegular,
                      column.keyStyle,
                    ]}
                  >
                    {column.key}
                  </Text>
                  <Text
                    style={[
                      global.textPrimaryBlack,
                      tw`text-xs my-2.5`,
                      global.textMedium,
                      column.valueStyle,
                    ]}
                  >
                    {column.value}
                  </Text>
                </View>
              ))}
            <View>
              <Text
                style={[
                  global.textPink,
                  tw`text-xs`,
                  global.textRegular,
                  editableField?.keyStyle,
                ]}
              >
                {editableField ? editableField.key : Strings.UNIT_PRICE}
              </Text>
              <View
                style={[
                  tw`mt-1 flex-row justify-evenly`,
                  !hideReceivedCurrency && tw`relative`,
                  editableField?.valueContainerStyle,
                ]}
              >
                {!hideReceivedCurrency && (
                  <Text
                    style={[
                      global.textBold,
                      tw`text-xs absolute`,
                      style.alignUnit,
                    ]}
                  >
                    ₹
                  </Text>
                )}
                {onPriceEdit ? (
                  <TextInput
                    style={[
                      global.textMedium,
                      tw`rounded-sm border px-2.5 text-xs`,
                      style.priceInput,
                    ]}
                    value={
                      editableField
                        ? String(editableField?.value)
                        : String(unitPrice)
                    }
                    onChangeText={(value: string) => onPriceEdit(value)}
                    keyboardType="number-pad"
                    maxLength={maxLength}
                  />
                ) : (
                  <Text
                    style={[
                      global.textBold,
                      tw`text-xs`,
                      editableField?.valueStyle,
                    ]}
                  >
                    {editableField ? editableField.value : unitPrice}
                  </Text>
                )}
              </View>
            </View>
          </View>
        </View>
      </View>
      <View
        style={[
          tw`mt-2.5 flex-row justify-between bg-white`,
          !errorQuantityMismatch && tw`mb-5`,
        ]}
      >
        <View style={[tw`flex-row`]}>
          <Text
            style={[
              global.textPink,
              global.textRegular,
              tw`mr-1.5 text-xs`,
              taxValueStyle,
            ]}
          >
            {tax}
          </Text>
          {isTaxAmountVisible &&
          (editableField?.value === '' || editableField?.value === 0) ? (
            <Text
              style={[
                global.textPink,
                global.textRegular,
                tw`font-bold text-xs`,
              ]}
            >
              ₹00.00
            </Text>
          ) : (
            <Text
              style={[
                global.textPrimaryBlack,
                global.textBold,
                tw`font-bold text-xs`,
              ]}
            >
              ₹{taxAmount || calculateTaxAmount()}
            </Text>
          )}
        </View>
        <View style={[tw`flex-row justify-evenly`]}>
          <Text
            style={[
              global.textRegular,
              global.textPink,
              tw`text-xs`,
              totalAmountKeyStyle,
            ]}
          >
            {Strings.AMOUNT}
          </Text>
          <Text
            style={[
              totalAmount !== '₹0' ? global.textPrimaryBlack : global.textPink,
              global.textBold,
              tw`pl-2 text-xs`,
            ]}
          >
            {totalAmount ||
              (doneQty &&
                unitPrice &&
                formatterWithoutDecimals.format(doneQty * unitPrice || 0))}
          </Text>
        </View>
      </View>
      {errorQuantityMismatch && (
        <Label
          text={errorMismatchMsg || STRINGS.GRNS.QUANTITY_MISMATCH}
          image={require('images/warning-solid.png')}
          containerStyle={[global.bgCandyRed, tw`mt-2.5 mb-2.5 px-2.5`]}
          textStyle={[
            global.textRed,
            global.textRegular,
            tw`text-xs`,
            style.marginLeftFive,
            style.lineHeightFourteen,
          ]}
          imageStyle={tw`w-3 h-3`}
        />
      )}
    </View>
  )
}

export default ItemCard
