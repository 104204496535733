import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  tickIcon: {
    height: 18,
    width: 18,
  },
})

export default styles
