import React from 'react'
import { View, Image, Pressable, Text } from 'react-native'

import STRINGS from 'constants/strings'
import { getDocName } from 'src/utilities/helpers'
import { DownloadInvoiceProps } from 'src/modals'
import useDownloadFrmS3 from 'src/utilities/hooks/useDownloadFrmS3'

// style
import global from 'styles/global'
import styles from './style'

interface DownloadDocProps {
  closeDrawer: Function
  docs: DownloadInvoiceProps[]
  successMsg?: string
}

const DownloadDoc = ({ closeDrawer, docs, successMsg }: DownloadDocProps) => {
  const { downloadDocFrmS3 } = useDownloadFrmS3()
  return (
    <View style={global.drawer}>
      <View style={global.alignItemsRight}>
        <Pressable
          style={global.paddingSixteen}
          onPress={() => closeDrawer(false)}
        >
          <Image
            source={require('images/cancel.png')}
            style={styles.iconCancel}
          />
        </Pressable>
      </View>
      {docs?.length > 0 &&
        docs.map(doc => {
          const docName = getDocName(doc)
          return (
            <Pressable
              onPress={() => {
                closeDrawer(false)
                downloadDocFrmS3(doc?.store_fname, docName, successMsg)
              }}
              style={global.widthFull}
              key={doc?.id}
            >
              <Text
                style={[
                  global.fontFourteen,
                  global.textRegular,
                  global.textColorGrey,
                  global.textAlignCenter,
                  global.paddingSixteen,
                  styles.drawerButtonBorder,
                ]}
              >
                {docName}
              </Text>
            </Pressable>
          )
        })}
      <Pressable onPress={() => closeDrawer(false)}>
        <Text
          style={[
            global.fontFourteen,
            global.textRegular,
            global.textColorGrey,
            global.textAlignCenter,
            global.paddingSixteen,
          ]}
        >
          {STRINGS.BUTTON.CANCEL}
        </Text>
      </Pressable>
    </View>
  )
}

export default DownloadDoc
