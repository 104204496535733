import React from 'react'
import { Text, ScrollView, Pressable } from 'react-native'
import tw from 'tailwind-react-native-classnames'

// styles
import global from 'styles/global'
import styles from './style'

interface HorizontalScrollableListProps {
  filterList: Array<FilterType>
  setFilter: Function
  containerStyle?: object
}

export interface FilterType {
  id: number
  name: string
  isSelected: boolean
}

const HorizontalScrollableList = ({
  filterList,
  setFilter,
  containerStyle,
}: HorizontalScrollableListProps) => (
  <ScrollView horizontal style={containerStyle}>
    {filterList.map((filter: FilterType, index: number) => (
      <Pressable
        onPress={() => setFilter(filter)}
        style={[
          tw`rounded p-2.5 border mr-2.5 bg-white`,
          index === 0 && tw`ml-5`,
          styles.filterContainer,
          filter.isSelected ? global.bgPrimaryGrren : tw`bg-white`,
        ]}
        key={filter.id}
      >
        <Text
          style={[
            tw`text-sm`,
            global.textPrimaryGreen,
            filter.isSelected && tw`text-white`,
          ]}
        >
          {filter.name}
        </Text>
      </Pressable>
    ))}
  </ScrollView>
)

export default HorizontalScrollableList
