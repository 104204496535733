import React, { ReactElement } from 'react'
import { View, Modal as RNModal } from 'react-native'

// styles
import global from 'styles/global'
import styles from './style'

interface ModalProps {
  body: ReactElement<any, any>
  footer?: ReactElement<any, any>
  close: Function
  style?: object
  containerStyle?: object
}

const Modal = ({ body, footer, close, style, containerStyle }: ModalProps) => {
  return (
    <View style={[global.justifyContentCenter, global.alignItemsCenter]}>
      <RNModal
        animationType="slide"
        transparent
        visible
        onRequestClose={() => {
          close(false)
        }}
      >
        <View
          style={[
            styles.bodyContainer,
            global.flexOne,
            global.justifyContentCenter,
            global.alignItemsCenter,
          ]}
        >
          <View
            style={[
              styles.modalView,
              global.borderRadiusEight,
              global.alignItemsCenter,
              containerStyle,
            ]}
          >
            <View style={[global.paddingTwenty, style]}>
              {body}
              {footer}
            </View>
          </View>
        </View>
      </RNModal>
    </View>
  )
}

export default Modal
