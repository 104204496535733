import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { View, ScrollView, ActivityIndicator } from 'react-native'

import { fetchCustomerList } from 'src/redux/actions'
import { CreateOrderProps } from 'src/navigations/types'
import { ApplicationState } from 'src/redux/types'
import { Customer } from 'src/modals'

// components
import CustomerCard from 'src/components/CustomerCard'

// styles
import globals from 'styles/global'
import colors from 'styles/colors'

const CreateOrder = ({ navigation }: CreateOrderProps) => {
  const dispatch = useDispatch()
  const [showLoader, setShowLoader] = useState(true)

  const customerStore = useSelector(
    (state: ApplicationState) => state.customersReducer,
  )
  const { list } = customerStore

  useEffect(() => {
    if (list.length > 0) setShowLoader(false)
  }, [list])

  useEffect(() => {
    dispatch(fetchCustomerList())
  }, [dispatch])

  return (
    <View style={[globals.paddingTwenty]}>
      {showLoader && (
        <ActivityIndicator size="large" color={colors.primaryGreen} />
      )}
      <ScrollView>
        {list.length > 0 &&
          list.map((customer: Customer) => (
            <View key={customer.id}>
              <CustomerCard data={customer} navigation={navigation} />
            </View>
          ))}
      </ScrollView>
    </View>
  )
}

export default CreateOrder
