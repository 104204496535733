import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Animated, Dimensions, View } from 'react-native'
import { NativeBaseProvider } from 'native-base'
import { TabView, TabBar, SceneMap } from 'react-native-tab-view'
import tw from 'tailwind-react-native-classnames'

import { fetchVoucherDetails, resetVoucher } from 'src/redux/actions'
import STRINGS from 'constants/strings'
import { URL } from 'src/utilities/axios'
import { ApplicationState } from 'src/redux/types'

// components
import PrimaryDetails, {
  PrimaryDetailsProps,
} from 'src/screens/warehouse-manager/OrderHistory/OrderDetails/PrimaryDetails'

// styles
import global from 'styles/global'
import style from './style'

const renderScene = SceneMap({
  invoiceDetails: ({ route }: PrimaryDetailsProps) => (
    <PrimaryDetails route={route} />
  ),
  moreDetails: () => <View />,
})

const OrderDetails = ({ navigation, route }: any) => {
  const { id } = route.params
  const [index, setIndex] = useState<number>(0)
  const dispatch = useDispatch()
  const { voucher } = useSelector(
    (state: ApplicationState) => state.voucherReducer,
  )
  useEffect(() => {
    if (id) dispatch(fetchVoucherDetails(id, URL.ORDER_DETAILS))
  }, [dispatch, id])

  useEffect(() => {
    return () => {
      dispatch(resetVoucher())
    }
  }, [dispatch])

  const renderTabBar = (props: any) => (
    <TabBar
      {...props}
      style={global.bgWhite}
      // TODO: Provide different name to the route variable
      // eslint-disable-next-line @typescript-eslint/no-shadow
      renderLabel={({ route, focused }) => (
        <Animated.Text
          style={
            focused
              ? [global.textPrimaryBlack, global.textMedium]
              : [global.textSecondaryGrey, global.textMedium]
          }
        >
          {route.title}
        </Animated.Text>
      )}
      indicatorStyle={[tw`rounded-sm h-1`, style.tabIndicator]}
    />
  )

  const routes = [
    {
      key: 'invoiceDetails',
      title: `${STRINGS.VOUCHER.VOUCHER_DETAILS}`,
      voucher,
      navigation,
    },
    {
      key: 'moreDetails',
      voucher,
      title: `${STRINGS.INVOICE.MORE_DETAILS}`,
    },
  ]

  return (
    <NativeBaseProvider>
      <TabView
        navigationState={{ index, routes }}
        renderScene={renderScene}
        renderTabBar={renderTabBar}
        onIndexChange={setIndex}
        initialLayout={{
          width: Dimensions.get('window').width,
        }}
      />
    </NativeBaseProvider>
  )
}

export default OrderDetails
