import React, { useEffect, useState } from 'react'
import { View, Text } from 'react-native'
import tw from 'tailwind-react-native-classnames'
import moment, { Moment } from 'moment'
import CalendarPicker from 'react-native-calendar-picker'

import STRINGS from 'constants/strings'
import { get } from 'src/utilities/axios'
import {
  CALENDAR_TYPE,
  DEFAULT_BUTTON_ID,
  FILTER_DATE_BUTTON_FORMAT,
  FILTER_DATE_FORMAT,
} from 'src/config/filter'
import {
  CustomFilterProps,
  FilterListProps,
  FilterPayloadProps,
} from 'types/filter'
import { showToastNotification } from 'src/utilities/helpers'
import { DOWNLOAD } from 'src/config/download'

// component
import Button from 'src/components/form-fields/Button'
import Modal from 'src/components/Modal'

// style
import global from 'styles/global'
import colors from 'styles/colors'
import styles from './style'

const CustomFilter = (props: CustomFilterProps) => {
  const { params, config, filterPayload, saveFilter } = props

  const {
    filterButtonStyle,
    filterButtonActiveStyle,
    filterButtonTextStyle,
    filterButtonActiveTextStyle,
    filterTitleStyle,
  } = params

  const {
    title,
    filterList,
    id, // Key that goes into api payload for a filter
    multiSelect,
    filterPayloadType,
    /* * Provide (filterPayloadType: 'array') in case of
     * single selection to get filter payload as array
     */ apiLoadFilterList,
    apiParams,
    apiSource, // key to pick data from api object
    apiResultEndPoint, // end point in api response
  } = config

  let calendarDate: Array<string> = []
  const activeButtons = filterPayload?.selectedFilter?.[id] || []

  const [customFilterList, setCustomFilterList] = useState<Array<object>>([])
  const [showCustomComponent, setCustomComponent] = useState<String>('')

  // Get option from server for filter list and set filter item list
  const serverFilterList = (response: any) => {
    const filterResponse = apiResultEndPoint
      ? response.data[apiResultEndPoint]
      : response.data
    let filterItemList: FilterListProps[] = []
    if (filterResponse?.length && apiSource) {
      for (let index = 0; index < filterResponse.length; index++) {
        const filterElement = filterResponse[index]
        if (filterElement?.[apiSource])
          filterItemList.push({
            displayName: filterElement[apiSource],
            id: filterElement[apiSource],
          })
      }
    }
    if (filterList?.length > 0)
      filterItemList = [...filterItemList, ...filterList]
    setCustomFilterList(filterItemList)
  }

  useEffect(() => {
    if (apiLoadFilterList) {
      get(apiLoadFilterList, apiParams)
        .then((res: any) => {
          if (res) serverFilterList(res)
        })
        .catch(err =>
          showToastNotification(DOWNLOAD.ERROR, err.response?.data.error),
        )
    } else setCustomFilterList(filterList)
    // TODO: Provide an explanation for why all deps are not included or fix it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Return calendar filter params
  const getCalendarFilterParam = (
    filter: FilterPayloadProps,
    filterDetails: FilterListProps | undefined,
  ) => {
    const filterIds = filter.selectedFilter?.[id] || []
    let selectedFilterIds = [...filterIds]
    let apiPayload: any
    if (multiSelect) {
      const apiFilter = filter.apiFilterPayload?.[id] || []
      apiPayload = [...apiFilter, ...calendarDate]
      selectedFilterIds.push(filterDetails?.id)
    } else {
      apiPayload = calendarDate
      selectedFilterIds = []
      selectedFilterIds.push(filterDetails?.id)
    }
    calendarDate = []
    return { apiPayload, selectedFilterIds }
  }

  // Get filter payload
  const getFilter = (
    filter: FilterPayloadProps,
    selectedFilter: Object,
    apiPayload?: Object,
  ) => {
    if (apiPayload) {
      return {
        apiFilterPayload: {
          ...filter.apiFilterPayload,
          [id]: apiPayload,
        },
        selectedFilter: {
          ...filter.selectedFilter,
          [id]: selectedFilter,
        },
      }
    }
    return {
      ...filter,
      selectedFilter: {
        ...filter.selectedFilter,
        [id]: selectedFilter,
      },
    }
  }

  // Get called on date selection is calendar component
  const onDateChange = (date: Moment, type: string) => {
    const calendarFilter: FilterListProps | undefined = filterList?.find(
      (filter: FilterListProps) => filter.type === CALENDAR_TYPE,
    )
    if (type === 'END_DATE') {
      calendarDate.push(
        moment(date).format(calendarFilter?.dateFormat || FILTER_DATE_FORMAT),
      )
      let filter = { ...filterPayload }
      const { apiPayload, selectedFilterIds } = getCalendarFilterParam(
        filter,
        calendarFilter,
      )
      filter = getFilter(filter, selectedFilterIds, apiPayload)
      setCustomComponent('')
      saveFilter(filter)
    } else {
      calendarDate.push(
        moment(date).format(calendarFilter?.dateFormat || FILTER_DATE_FORMAT),
      )
    }
  }

  // Get called on calendar filter button click
  const onCalendarButtonClick = (filterDetails: FilterListProps) => {
    let filter = { ...filterPayload }
    const filterIds = filter.selectedFilter?.[id] || []
    let selectedFilterIds = [...filterIds]
    // If Calendar button is selected then deselect it
    if (selectedFilterIds.includes(filterDetails?.id)) {
      let apiPayload: any
      const apiFilter = filter.apiFilterPayload?.[id] || []
      // If selection is multiselect
      // It will remove two dates, bcs calendar set two date in filter
      if (multiSelect && apiFilter.length > 2) {
        apiPayload = [...apiFilter]
        const index = selectedFilterIds.findIndex(
          (item: string | number) => item === filterDetails?.id,
        )
        apiPayload.splice(index, 2)
        selectedFilterIds.splice(index, 1)
      }
      // If selection type is single selection
      else {
        delete filter.apiFilterPayload?.[id]
        selectedFilterIds = []
      }
      filter = getFilter(filter, selectedFilterIds, apiPayload)
      saveFilter(filter)
    }
    // Open Calendar component
    else {
      setCustomComponent(CALENDAR_TYPE)
    }
  }

  /** Get call on default button click
   * It set active button as default button and flush selected filter
   */
  const onDefaultButtonClick = () => {
    const filter = { ...filterPayload }
    if (filter.apiFilterPayload?.[id] && filter.selectedFilter?.[id]) {
      delete filter.apiFilterPayload?.[id]
      delete filter.selectedFilter?.[id]
      saveFilter(filter)
    }
  }

  /** Get called on any filter button click
   * It create payload for selected filter and set active buttons
   */
  const onFilterButtonClick = (filterDetails: FilterListProps) => {
    if (filterDetails?.id === DEFAULT_BUTTON_ID) {
      onDefaultButtonClick()
    } else if (filterDetails?.type === CALENDAR_TYPE) {
      onCalendarButtonClick(filterDetails)
    } else {
      let filter = { ...filterPayload }
      const filterIds = filter.selectedFilter?.[id] || []
      let selectedFilterIds = [...filterIds]
      const apiFilter = filter.apiFilterPayload?.[id] || []
      let apiPayload: any
      // If button is selected then deselect it
      if (selectedFilterIds.includes(filterDetails?.id)) {
        // If selection type is multiselect
        if (multiSelect && selectedFilterIds?.length > 1) {
          apiPayload = [...apiFilter]
          const filterIdindex = selectedFilterIds.findIndex(
            (item: string | number) => item === filterDetails?.id,
          )
          const payloadIndex = apiPayload.findIndex(
            (item: string | number) => item === filterDetails?.id,
          )
          apiPayload.splice(payloadIndex, 1)
          selectedFilterIds.splice(filterIdindex, 1)
        }
        // If selection type is single select
        else {
          delete filter.apiFilterPayload?.[id]
          selectedFilterIds = []
        }
      } else {
        // If button is not selcted
        // eslint-disable-next-line no-lonely-if
        if (multiSelect) {
          apiPayload = [...apiFilter]
          apiPayload.push(filterDetails?.id)
          selectedFilterIds.push(filterDetails?.id)
        } else {
          apiPayload =
            filterPayloadType === 'array'
              ? [filterDetails?.id]
              : filterDetails?.id
          selectedFilterIds = []
          selectedFilterIds.push(filterDetails?.id)
        }
      }
      filter = getFilter(filter, selectedFilterIds, apiPayload)
      saveFilter(filter)
    }
  }

  /** Return filter button name
   * in special case of calendar button it return name as selected dates
   */
  const getFilterButtonName = (filterDetails: FilterListProps) => {
    if (!filterDetails.type) return filterDetails?.displayName
    if (filterDetails.type === CALENDAR_TYPE) {
      const selectedFilter = filterPayload?.selectedFilter?.[id]
      const index =
        selectedFilter?.length > 0 &&
        selectedFilter.findIndex(
          // TODO: Provide a different variable name to id
          // eslint-disable-next-line @typescript-eslint/no-shadow
          (id: string | number) => id === filterDetails.id,
        )
      if (index !== false && index >= 0) {
        const apiFilter = filterPayload?.apiFilterPayload?.[id]
        const filterDate = apiFilter?.slice(index, index + 2)
        const calButtonStart = moment(filterDate?.[0]).format(
          filterDetails?.buttonDateFormat || FILTER_DATE_BUTTON_FORMAT,
        )
        const calButtonEnd = moment(filterDate?.[1]).format(
          filterDetails?.buttonDateFormat || FILTER_DATE_BUTTON_FORMAT,
        )
        return `${calButtonStart} - ${calButtonEnd}`
      }
    }
    return filterDetails?.displayName
  }

  return (
    <View style={tw`mx-5`} key={id}>
      <Text
        style={[
          global.textRegular,
          global.textColorGrey,
          tw`text-xs my-5`,
          filterTitleStyle,
        ]}
      >
        {title}
      </Text>
      <View style={tw`flex-wrap flex-row`}>
        <Button
          title={STRINGS.BUTTON.ALL}
          style={[
            global.borderRadiusFive,
            global.borderColorSecondaryTextGrey,
            styles.filterButton,
            tw`bg-white border mr-5 mb-5`,
            filterButtonStyle,
            activeButtons?.length === 0 &&
              (filterButtonActiveStyle || [
                global.bgSecondaryOrange,
                global.borderColorSecondaryOrange,
              ]),
          ]}
          textStyle={[
            global.textRegular,
            global.textSecondaryGrey,
            tw`text-xs`,
            filterButtonTextStyle,
            activeButtons?.length === 0 &&
              (filterButtonActiveTextStyle || [
                global.textBold,
                global.textWhite,
              ]),
          ]}
          onTap={() =>
            onFilterButtonClick({
              displayName: STRINGS.BUTTON.ALL,
              id: DEFAULT_BUTTON_ID,
            })
          }
        />
        {customFilterList.length > 0 &&
          customFilterList.map((filter: any) => {
            const isButtonActive = activeButtons.includes(filter?.id)
            return (
              <View key={filter?.id}>
                <Button
                  title={getFilterButtonName(filter)}
                  onTap={() => onFilterButtonClick(filter)}
                  style={[
                    global.borderRadiusFive,
                    global.borderColorSecondaryTextGrey,
                    styles.filterButton,
                    tw`bg-white border mr-5 mb-5`,
                    filterButtonStyle,
                    isButtonActive &&
                      (filterButtonActiveStyle || [
                        global.bgSecondaryOrange,
                        global.borderColorSecondaryOrange,
                      ]),
                  ]}
                  textStyle={[
                    global.textRegular,
                    global.textSecondaryGrey,
                    tw`text-xs`,
                    filterButtonTextStyle,
                    isButtonActive &&
                      (filterButtonActiveTextStyle || [
                        global.textBold,
                        tw`text-white`,
                      ]),
                  ]}
                />
              </View>
            )
          })}
      </View>
      {showCustomComponent === CALENDAR_TYPE && (
        <Modal
          body={
            <CalendarPicker
              startFromMonday
              onDateChange={(date: Moment, type) => onDateChange(date, type)}
              weekdays={['S', 'M', 'T', 'W', 'T', 'F', 'S']}
              selectedDayTextColor={colors.white}
              previousTitle={STRINGS.BUTTON.PREVIOUS}
              nextTitle={STRINGS.BUTTON.NEXT}
              allowRangeSelection
            />
          }
          containerStyle={tw`max-w-full`}
          close={() => setCustomComponent('')}
        />
      )}
    </View>
  )
}

export default CustomFilter
