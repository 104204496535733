import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  View,
  Image,
  ScrollView,
  ActivityIndicator,
  TouchableOpacity,
} from 'react-native'

import STRINGS from 'constants/strings'
import { utcToLocalTime } from 'src/utilities/helpers'
import {
  voucherDateFormat,
  voucherTimeFormat,
  orderStatusObj,
} from 'src/config/voucher'
import { VoucherDetailsScreenNavigationProp } from 'src/navigations/types'
import { Invoice } from 'src/modals'

// components
import Button from 'src/components/form-fields/Button'
import TotalAmount from 'src/components/voucher/TotalAmount'
import VoucherMetadata from 'src/components/voucher/VoucherMetadata'
import VoucherStatus from 'src/components/voucher/VoucherStatus'
import DetailItemCard from 'src/components/voucher/DetailItemCard'
import {
  OptionsContent,
  AddNoteContent,
  ExpenseContent,
} from 'src/components/drawers'

// styles
import global from 'styles/global'
import colors from 'styles/colors'
import styles from '../style'

const String = STRINGS.VOUCHER

export interface PrimaryDetailsProps {
  route: {
    invoice: Invoice
    creditNoteId: number
    navigation: VoucherDetailsScreenNavigationProp
  }
}

const PrimaryDetails = ({ route }: PrimaryDetailsProps) => {
  const dispatch = useDispatch()
  const { invoice, navigation } = route
  const [showOptionsDrawer, setShowOptionsDrawer] = useState<boolean>(false)
  const [showAddNoteDrawer, setShowAddNoteDrawer] = useState<boolean>(false)
  const [showExpenseDrawer, setShowExpenseDrawer] = useState<boolean>(false)

  const onPriceReset = () => {
    dispatch({
      type: 'PRICE_RESET_INVOICE_ITEM',
    })
    setShowOptionsDrawer(false)
  }

  const onInvokeAddNote = () => {
    setShowOptionsDrawer(false)
    setShowAddNoteDrawer(true)
  }

  const onPriceEditAtIndex = (index: number) => (updatedPrice: string) => {
    dispatch({
      type: 'UPDATE_INVOICE_ITEM_PRICE',
      payload: {
        index,
        updatedPrice: Number(updatedPrice),
      },
    })
  }

  const onNoteAdd = (note: string) => {
    setShowAddNoteDrawer(false)
    dispatch({
      type: 'ADD_INVOICE_NOTE',
      payload: note,
    })
  }

  const invoiceAttributes = [
    {
      icon: require('images/id.png'),
      attrName: String.VOUCHER_ID,
      attrValue: invoice?.invoice_id || STRINGS.MISCELLANEOUS.NOT_AVAILABLE,
    },
    {
      icon: require('images/calendar.png'),
      attrName: String.VOUCHER_DATE,
      attrValue: invoice.write_date
        ? utcToLocalTime(invoice.write_date, voucherDateFormat)
        : STRINGS.MISCELLANEOUS.DATE_NOTE_AVAILABLE,
    },
    {
      icon: require('images/clock.png'),
      attrName: String.VOUCHER_TIME,
      attrValue: invoice.write_date
        ? utcToLocalTime(invoice.write_date, voucherTimeFormat)
        : STRINGS.MISCELLANEOUS.DATE_NOTE_AVAILABLE,
    },
  ]

  return (
    <>
      {invoice ? (
        <View
          style={[
            (showOptionsDrawer || showAddNoteDrawer || showExpenseDrawer) &&
              global.drawerBackground,
            global.flexOne,
            global.zeroHeight,
          ]}
        >
          <VoucherMetadata id={invoice.id} attributes={invoiceAttributes} />
          <View style={global.bgWhite}>
            <TotalAmount
              amount={invoice?.total_amount || 0}
              textStyle={global.textPrimaryBlack}
              containerStyle={[
                global.borderTopWidthOne,
                global.borderTopColorbgLightBlue,
              ]}
            />
          </View>
          <View
            style={[
              global.bgWhite,
              global.hPaddingTwenty,
              global.vPaddingSixteen,
              global.directionRow,
              global.justifyContentSpaceBetween,
            ]}
          >
            <VoucherStatus
              dateAligned
              status={invoice.state}
              date={
                invoice.write_date
                  ? utcToLocalTime(invoice.write_date, voucherDateFormat)
                  : STRINGS.MISCELLANEOUS.DATE_NOTE_AVAILABLE
              }
              iconPath={
                orderStatusObj[invoice.state] &&
                orderStatusObj[invoice.state].iconPath
              }
              textColor={
                orderStatusObj[invoice.state] &&
                orderStatusObj[invoice.state].color
              }
            />
            <View>
              <TouchableOpacity
                onPress={() => setShowOptionsDrawer(true)}
                style={styles.showMoreContainer}
              >
                <Image
                  source={require('images/more-vertical.png')}
                  style={styles.showMoreStyle}
                />
              </TouchableOpacity>
            </View>
          </View>
          <View style={[global.flexOne, global.zeroHeight]}>
            <ScrollView>
              {invoice?.items?.map((voucherItem, index) => (
                <DetailItemCard
                  // TODO: Need to provide reason for why index key or else fix it
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${voucherItem.product_name}__${index}`}
                  name={voucherItem.product_name}
                  imageS3Key={voucherItem.product_image}
                  doneQty={voucherItem.quantity_done}
                  unitOfMeasure={voucherItem.product_uom[1]}
                  unitPrice={voucherItem.price_unit}
                  onPriceEdit={onPriceEditAtIndex(index)}
                  tax={voucherItem.tax_display_name}
                />
              ))}
            </ScrollView>
            <View
              style={[
                global.bgWhite,
                global.vPaddingTen,
                global.hPaddingTwenty,
                styles.continueToInvoiceContainer,
              ]}
            >
              <Button
                title={String.CONTINUE_TO_INVOICE}
                style={[
                  global.borderRadiusEight,
                  styles.continueToInvoiceButton,
                ]}
                onTap={() => {
                  setShowExpenseDrawer(true)
                }}
              />
            </View>
          </View>
        </View>
      ) : (
        <ActivityIndicator
          size="large"
          color={colors.primaryGreen}
          style={global.vMarginSixteen}
        />
      )}
      {showOptionsDrawer && (
        <OptionsContent
          closeDrawer={() => setShowOptionsDrawer(false)}
          onInvokeAddNote={onInvokeAddNote}
          onPriceReset={onPriceReset}
        />
      )}
      {showAddNoteDrawer && (
        <AddNoteContent setDrawer={setShowAddNoteDrawer} addNote={onNoteAdd} />
      )}
      {showExpenseDrawer && (
        <ExpenseContent
          navigation={navigation}
          invoice={invoice}
          setDrawer={setShowExpenseDrawer}
          editAdditionalExpense
        />
      )}
    </>
  )
}

export default PrimaryDetails
