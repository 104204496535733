import { DryProduct, DryProductTax, ProductUOM } from 'src/modals'
import { NodeProps, VendorProps } from 'types/procurement'
import { ProcurementAction } from '../types/procurement'

type ProcurementState = {
  createPO: {
    node: NodeProps
    vendor: VendorProps
    paymentTerm: NodeProps
    qcParams: Object
    qcBy: string | null
    product: DryProduct
    productQuantity: string
    productUOM: ProductUOM
    productTax: DryProductTax
    productPrice: string
    delivery: string
    pickup: string
    pickupLocation: Object
    dehaatWarehouseLocation: Object
    customerWarehouseLocation: Object
    customerDeliveryAddress: Object
    deliveryLocationType: string | null
    totalAmount: number
    NCDEX: boolean
    addedQcParam: Object
    removedQcParam: Object
  }
}

const initialState = {
  createPO: {
    node: {},
    vendor: {},
    paymentTerm: {},
    qcParams: {},
    qcBy: null,
    product: {},
    productQuantity: '',
    productUOM: {},
    productTax: {},
    productPrice: '',
    delivery: '',
    pickup: '',
    pickupLocation: {},
    dehaatWarehouseLocation: {},
    customerWarehouseLocation: {},
    customerDeliveryAddress: {},
    deliveryLocationType: null,
    totalAmount: 0,
    NCDEX: false,
    addedQcParam: {},
    removedQcParam: {},
  },
}

const procurementReducer = (
  state: ProcurementState = initialState,
  action: ProcurementAction,
) => {
  switch (action.type) {
    case 'SET_CREATE_PO_FIELD': {
      return {
        ...state,
        createPO: action.payload,
      }
    }
    case 'RESET_CREATE_PO_FIELD': {
      return {
        ...state,
        createPO: initialState.createPO,
      }
    }
    default:
      return state
  }
}

export { procurementReducer }
