import React from 'react'
import { View } from 'react-native'

import {
  boolean,
  number,
  object,
  text,
  withKnobs,
} from '@storybook/addon-knobs'
import { storiesOf } from '@storybook/react-native'

import CustInfo from '.'

storiesOf('OMS', module)
  .addDecorator(withKnobs)
  .addDecorator(storyFn => (
    <View style={{ flex: 1, marginHorizontal: 20 }}>{storyFn()}</View>
  ))
  .add('Customer Information', () => (
    <CustInfo
      customerName={text('Customer name', 'Foo bar')}
      createDate={text(
        'Created Date',
        new Intl.DateTimeFormat('en-IN').format(new Date()),
      )}
      mobile={number('Mobile', 1234567890)}
      address={text('Address', '')}
      style={object('Style', {})}
      truncateAddress={boolean('Truncate address', false)}
      productImage={object('Product image', {
        uri: 'http://placekitten.com/200/300',
      })}
      productImageStyle={object('Product image style', {})}
      product={text('Product name', 'Product name')}
      quantity={text('Quantity', '5')}
      addressIconStyle={object('Address icon style', {})}
      addressTextStyle={object('Address text style', {})}
      qcBy={text('QC by', 'QC user')}
      qcImage={object('QC image', { uri: 'http://placekitten.com/200/300' })}
      qcImageStyle={object('QC image style', {})}
    />
  ))
