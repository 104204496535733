import React, { useState } from 'react'
import { View, Text } from 'react-native'
import { NativeBaseProvider, Box, HStack, Center } from 'native-base'

// components
import ToastNotification from 'src/components/ToastNotification'
import ImageUploadCard from 'src/components/ImageUploadCard'
import InputTextField from 'src/components/form-fields/InputTextField'
import Button from 'src/components/form-fields/Button'

import STRINGS from 'constants/strings'
import { UploadBiltyAndInvoiceProps } from 'src/navigations/types'

import global from 'styles/global'
import style from './style'

const UploadBiltyAndInvoice = ({ navigation }: UploadBiltyAndInvoiceProps) => {
  const [bilty, setBilty] = useState<string>()
  const [invoice, setInvoice] = useState<string>()
  const [biltyNumber, setBiltyNumber] = useState<number>()

  return (
    <NativeBaseProvider>
      <Box flex={1} safeAreaTop style={global.bgWhite}>
        <ToastNotification />
        <View style={global.hPaddingTwenty}>
          <View style={style.card}>
            <ImageUploadCard
              setImage={setBilty}
              image={bilty}
              text={
                bilty
                  ? STRINGS.GRNS.TRANSPORT_BILTY_UPLOADED
                  : STRINGS.BUTTON.UPLOAD_TRANSPORT_BILTY
              }
              secondaryText={STRINGS.GRNS.MANDATORY_TRANSPORT_BILTY}
              buttonText={STRINGS.BUTTON.UPLOAD_TRANSPORT_BILTY}
              successMsg={STRINGS.SUCCESS.TRANSPORT_BILTY_UPLOADED}
            />
          </View>
          <View style={style.card}>
            <ImageUploadCard
              setImage={setInvoice}
              image={invoice}
              text={
                invoice
                  ? STRINGS.GRNS.VENDOR_INVOICE_UPLOADED
                  : STRINGS.GRNS.UPLOAD_VENDOR_INVOICE
              }
              secondaryText={STRINGS.GRNS.OPTIONAL_VENDOR_INVOICE}
              buttonText={STRINGS.BUTTON.UPLOAD_VENDOR_INVOICE}
              successMsg={STRINGS.SUCCESS.VENDOR_INVOICE_UPLOADED}
            />
          </View>
          <View style={style.card}>
            <Text
              style={[
                global.textColorGrey,
                global.fontTwelve,
                global.textRegular,
              ]}
            >
              {STRINGS.FORM_FIELDS.ENTER_TRANSPORT_BILTY}
            </Text>
            <View style={[global.vPaddingTen]}>
              <InputTextField
                placeholder={STRINGS.FORM_FIELDS.BILTY_PLACEHOLDER}
                onTextChange={(value: number) => setBiltyNumber(value)}
                style={[global.borderRadiusEight, style.biltyInput]}
                innerStyle={[
                  global.textColorGrey,
                  global.fontFourteen,
                  global.textMedium,
                ]}
              />
            </View>
          </View>
        </View>
        <Center flex={1} />
        <HStack safeAreaBottom>
          <View
            style={[global.paddingTwenty, global.widthFull, global.bgWhite]}
          >
            <View style={style.footerBtn}>
              <Button
                title={STRINGS.BUTTON.INWARD_GRN}
                onTap={() => navigation.navigate('Reschedule')}
                style={global.borderRadiusEight}
                disable={!bilty || !biltyNumber}
              />
            </View>
          </View>
        </HStack>
      </Box>
    </NativeBaseProvider>
  )
}

export default UploadBiltyAndInvoice
