import React, { useState, useEffect } from 'react'
import { View } from 'react-native'

import {
  MAX_ITEM_LIMIT,
  voucherTimeFormat,
  voucherDateFormat,
} from 'src/config/voucher'
import { formatterWithoutDecimals, utcToLocalTime } from 'src/utilities/helpers'
import { OrderVoucherDetails, VocherItemProps } from 'src/modals'

// component
import Voucher from 'src/components/voucher/VoucherCard'
import global from 'styles/global'
import VoucherHeader from './Header'
import Body from './Body'
import Footer from './Footer'

// style

interface VoucherCardProp {
  voucherData: OrderVoucherDetails
  orderId: Number
  navigation: { navigate: Function }
}

const VoucherCard = ({ voucherData, orderId, navigation }: VoucherCardProp) => {
  const [orderItems, setOrderItems] = useState<Array<VocherItemProps>>([])
  const [showMoreVisible, setShowMoreVisible] = useState<boolean>(true)
  useEffect(() => {
    if (voucherData && voucherData.items) {
      if (voucherData.items.length > 3) {
        const newOrderItems = voucherData.items.slice(0, 3)
        setOrderItems(newOrderItems)
      } else {
        setOrderItems(voucherData.items)
      }
    }
  }, [voucherData])

  const showMoreClick = () => {
    setOrderItems(voucherData.items)
    setShowMoreVisible(!showMoreVisible)
  }

  return (
    <View style={global.margingEight}>
      <Voucher
        header={
          <VoucherHeader
            amount={formatterWithoutDecimals.format(
              Number(voucherData.total_amount),
            )}
            date={utcToLocalTime(voucherData.date, voucherDateFormat)}
            time={utcToLocalTime(voucherData.date, voucherTimeFormat)}
          />
        }
        body={
          <Body
            data={voucherData}
            orderItems={orderItems}
            orderId={orderId}
            navigation={navigation}
          />
        }
        footer={
          voucherData?.items?.length > MAX_ITEM_LIMIT ? (
            <Footer
              onShowMoreClick={showMoreClick}
              showMore={showMoreVisible}
            />
          ) : (
            <View />
          )
        }
      />
    </View>
  )
}

export default VoucherCard
