import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Animated, Dimensions } from 'react-native'
import { NativeBaseProvider } from 'native-base'
import { TabView, TabBar, SceneMap } from 'react-native-tab-view'

import { ApplicationState } from 'src/redux/types'
import STRINGS from 'constants/strings'
import { fetchGRNDetailsById } from 'src/redux/actions'
import { PendingGRNDetailsProps } from 'src/navigations/types'

// components
import ToastNotification from 'src/components/ToastNotification'
import MoreDetails, {
  MoreDetailsProps,
} from 'src/screens/inventory-manager/InwardGRN/GRNDetails/MoreDetails'
import global from 'styles/global'
import PrimaryDetails, { PrimaryDetailsProps } from './PrimaryDetails'

// styles
import styles from './style'

const renderScene = SceneMap({
  grnDetails: ({ route }: PrimaryDetailsProps) => (
    <PrimaryDetails route={route} />
  ),
  moreDetails: ({ route }: MoreDetailsProps) => <MoreDetails route={route} />,
})

const PendingGRNDetails = ({ route, navigation }: PendingGRNDetailsProps) => {
  const { id } = route.params
  const dispatch = useDispatch()
  const [index, setIndex] = useState<number>(0)

  const { grnDetails } = useSelector(
    (state: ApplicationState) => state.grnReducer,
  )

  useEffect(() => {
    dispatch(fetchGRNDetailsById(id))
    // TODO: Provide an explanation for why all deps are not included or fix it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  useEffect(() => {
    return () => {
      dispatch({
        type: 'SET_GRN_DETAILS',
        payload: null,
      })
    }
    // TODO: Provide an explanation for why all deps are not included or fix it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderTabBar = (props: any) => (
    <TabBar
      {...props}
      style={global.bgWhite}
      // TODO: Provide a different variable name to route
      // eslint-disable-next-line @typescript-eslint/no-shadow
      renderLabel={({ route, focused }) => (
        <Animated.Text
          style={
            focused
              ? [global.textPrimaryBlack, global.textMedium]
              : [global.textSecondaryGrey, global.textMedium]
          }
        >
          {route.title}
        </Animated.Text>
      )}
      indicatorStyle={styles.tabIndicator}
    />
  )

  const routes = [
    {
      key: 'grnDetails',
      title: `${STRINGS.GRNS.GRN_DETAILS}`,
      grnDetails: grnDetails?.items,
      grnMoreDetails: grnDetails?.more_details,
      navigation,
    },
    {
      key: 'moreDetails',
      title: `${STRINGS.VOUCHER.MORE_DETAILS}`,
      grnMoreDetails: grnDetails?.more_details,
    },
  ]

  return (
    <NativeBaseProvider>
      <ToastNotification />
      <TabView
        navigationState={{ index, routes }}
        renderScene={renderScene}
        renderTabBar={renderTabBar}
        onIndexChange={setIndex}
        initialLayout={{
          width: Dimensions.get('window').width,
        }}
      />
    </NativeBaseProvider>
  )
}

export default PendingGRNDetails
