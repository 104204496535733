/* eslint-disable no-bitwise */
import AWS from 'aws-sdk'
import { Platform } from 'react-native'
import { APP_AWS_BUCKET } from 'react-native-dotenv'
import * as FileSystem from 'expo-file-system'
import * as IntentLauncher from 'expo-intent-launcher'
import { useSelector } from 'react-redux'

import { DOWNLOAD } from 'src/config/download'
import { showToastNotification } from 'src/utilities/helpers'
import strings from 'constants/strings'
import { ApplicationState } from 'src/redux/types'

const useDownloadFrmS3 = () => {
  const { schedulePushNotification } = useSelector(
    (state: ApplicationState) => state.pushNotification,
  )

  const uintArrayToBase64 = (buffer: any) => {
    let base64 = ''
    const encodings = DOWNLOAD.ENCODING_STRING
    const bytes = new Uint8Array(buffer)
    const { byteLength } = bytes
    const byteRemainder = byteLength % 3
    const mainLength = byteLength - byteRemainder
    let a
    let b
    let c
    let d
    let chunk
    for (let i = 0; i < mainLength; i += 3) {
      chunk = (bytes[i] << 16) | (bytes[i + 1] << 8) | bytes[i + 2]
      a = (chunk & 16515072) >> 18
      b = (chunk & 258048) >> 12
      c = (chunk & 4032) >> 6
      d = chunk & 63
      base64 += encodings[a] + encodings[b] + encodings[c] + encodings[d]
    }
    if (byteRemainder === 1) {
      chunk = bytes[mainLength]
      a = (chunk & 252) >> 2
      b = (chunk & 3) << 4
      base64 += `${encodings[a] + encodings[b]}==`
    } else if (byteRemainder === 2) {
      chunk = (bytes[mainLength] << 8) | bytes[mainLength + 1]
      a = (chunk & 64512) >> 10
      b = (chunk & 1008) >> 4
      c = (chunk & 15) << 2
      base64 += `${encodings[a] + encodings[b] + encodings[c]}=`
    }
    return base64
  }

  const downloadForAOS = async (
    pdfBase64Str: any,
    storeFolder: string,
    fileName: string,
    fileType: string,
  ) => {
    const folder =
      FileSystem.StorageAccessFramework.getUriForDirectoryInRoot(storeFolder)
    const permissions: any =
      await FileSystem.StorageAccessFramework.requestDirectoryPermissionsAsync(
        folder,
      )
    try {
      const filePath = await FileSystem.StorageAccessFramework.createFileAsync(
        permissions.directoryUri,
        fileName,
        fileType,
      )
      await FileSystem.StorageAccessFramework.writeAsStringAsync(
        filePath,
        pdfBase64Str,
        { encoding: FileSystem.EncodingType.Base64 },
      )
      return { downloadSuccess: true, filePath }
    } catch (err) {
      console.error(err)
      return false
    }
  }

  const downloadForWeb = (data: any, fileName: string) => {
    try {
      const blob = new Blob([data.Body as BlobPart], { type: data.ContentType })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = fileName
      link.click()
      return true
    } catch (err) {
      console.error(err)
      return false
    }
  }

  const showPushNotification = (downloadResponse: any) => {
    IntentLauncher.startActivityAsync(DOWNLOAD.INTENT_VIEW as any, {
      data: downloadResponse.filePath,
      flags: 1,
    })
  }

  async function downloadDocFrmS3(
    s3DocKey: string,
    fileName: string,
    successMsg?: string,
    pushNotification: boolean = true,
  ) {
    if (s3DocKey) {
      const s3 = new AWS.S3()
      s3.getObject(
        { Bucket: APP_AWS_BUCKET, Key: `${s3DocKey}` },
        async (error, data) => {
          if (error != null) {
            console.error(error)
          }
          if (data?.Body) {
            let isDownloadSuccess: any = false
            if (Platform.OS === DOWNLOAD.PLATFORM_ANDROID) {
              const base64Value = uintArrayToBase64(data.Body)
              const downloadResponse: any = await downloadForAOS(
                base64Value,
                DOWNLOAD.DOWNLOAD_FOLDER,
                fileName,
                DOWNLOAD.APPLICATION_PDF,
              )
              isDownloadSuccess = downloadResponse?.downloadSuccess
              if (isDownloadSuccess && pushNotification) {
                schedulePushNotification(
                  () => () => showPushNotification(downloadResponse),
                  fileName,
                  strings.MISCELLANEOUS.DOWNLOAD_SUCCESSFULLY,
                )
              }
            } else if (Platform.OS === DOWNLOAD.PLATFORM_WEB) {
              isDownloadSuccess = downloadForWeb(data, fileName)
            }
            if (isDownloadSuccess && successMsg)
              showToastNotification(DOWNLOAD.SUCCESS, successMsg)
          }
        },
      )
    }
  }
  return { downloadDocFrmS3 }
}

export default useDownloadFrmS3
