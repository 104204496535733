import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Animated, Dimensions } from 'react-native'
import { NativeBaseProvider } from 'native-base'
import { TabView, TabBar, SceneMap } from 'react-native-tab-view'

import { UpdateInvoiceDetailsProps } from 'src/navigations/types'
import STRINGS from 'constants/strings'
import { Invoice } from 'src/modals'
import { ApplicationState } from 'src/redux/types'

// components
import MoreDetails, {
  MoreDetailsProps,
} from 'src/components/voucher/MoreDetailsTab'
import ToastNotification from 'src/components/ToastNotification'

// styles
import global from 'styles/global'
import PrimaryDetails, { PrimaryDetailsProps } from './PrimaryDetails'
import styles from './style'

const renderScene = SceneMap({
  updateInvoiceDetails: ({ route }: PrimaryDetailsProps) => (
    <PrimaryDetails route={route} />
  ),
  moreDetails: ({ route }: MoreDetailsProps) => <MoreDetails route={route} />,
})

const UpdateInvoiceDetails = ({
  route,
  navigation,
}: UpdateInvoiceDetailsProps) => {
  const { invoice, creditNoteId } = route.params
  const dispatch = useDispatch()
  const [index, setIndex] = useState<number>(0)

  const invoiceStore: { invoice: Invoice | null } = useSelector(
    (state: ApplicationState) => state.invoiceReducer,
  )

  useEffect(() => {
    dispatch({
      type: 'SET_INVOICE',
      payload: invoice,
    })
    return () => {
      dispatch({
        type: 'SET_INVOICE',
        payload: null,
      })
    }
    // TODO: Provide an explanation for why all deps are not included or fix it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoice])

  const renderTabBar = (props: any) => (
    <TabBar
      {...props}
      style={global.bgWhite}
      // TODO: Provide different variable name for route
      // eslint-disable-next-line @typescript-eslint/no-shadow
      renderLabel={({ route, focused }) => (
        <Animated.Text
          style={
            focused
              ? [global.textPrimaryBlack, global.textMedium]
              : [global.textSecondaryGrey, global.textMedium]
          }
        >
          {route.title}
        </Animated.Text>
      )}
      indicatorStyle={styles.tabIndicator}
    />
  )

  const routes = [
    {
      key: 'updateInvoiceDetails',
      title: `${STRINGS.INVOICE.INVOICE_DETAILS}`,
      invoice: invoiceStore.invoice,
      creditNoteId,
      navigation,
    },
    {
      key: 'moreDetails',
      title: `${STRINGS.VOUCHER.MORE_DETAILS}`,
      invoice: invoiceStore.invoice,
    },
  ]

  return (
    <NativeBaseProvider>
      <ToastNotification />
      <TabView
        navigationState={{ index, routes }}
        renderScene={renderScene}
        renderTabBar={renderTabBar}
        onIndexChange={setIndex}
        initialLayout={{
          width: Dimensions.get('window').width,
        }}
      />
    </NativeBaseProvider>
  )
}

export default UpdateInvoiceDetails
