import React, { useEffect, useState } from 'react'
import { Image } from 'react-native'
import { APP_AWS_BUCKET } from 'react-native-dotenv'

import S3Singleton from 'src/utilities/s3'

interface S3ImageObjectProps {
  imageKey: string
  style: object
}

const S3Image = ({ imageKey, style }: S3ImageObjectProps) => {
  const [imageSrc, setImageSrc] = useState<string>()

  useEffect(() => {
    if (imageKey) {
      ;(async () => {
        try {
          const s3 = await S3Singleton.getInstance()
          s3.getObject(
            {
              Bucket: APP_AWS_BUCKET,
              Key: imageKey,
            },
            (err: any, data: any) => {
              if (err) return
              if (data) {
                setImageSrc(
                  `data:image/png;base64, ${data.Body.toString('base64')}`,
                )
              }
            },
          )
        } catch (e) {
          console.error(e)
        }
      })()
    }
  }, [imageKey])

  return (
    <Image
      style={style}
      source={
        imageSrc && imageKey ? { uri: imageSrc } : require('images/default.png')
      }
    />
  )
}

export default S3Image
