import React from 'react'
import { View, Image, Text } from 'react-native'
import tw from 'tailwind-react-native-classnames'

import STRINGS from 'constants/strings'

// style
import global from 'styles/global'
import styles from './styles'

interface CustomerInformationProps {
  customerName: string
  createDate?: string
  mobile?: Number
  id?: number | string
  address?: string
  style?: object
  truncateAddress?: boolean
  productImage?: any
  productImageStyle?: object
  product?: string
  quantity?: string
  addressIconStyle?: object
  addressTextStyle?: object
  qcBy?: string
  qcImage?: any
  qcImageStyle?: object
}

const CustomerInformation = ({ ...props }: CustomerInformationProps) => {
  const {
    customerName,
    createDate,
    mobile,
    id,
    address,
    style,
    truncateAddress = false,
    productImage,
    productImageStyle,
    product,
    quantity,
    addressIconStyle,
    addressTextStyle,
    qcBy,
    qcImage,
    qcImageStyle,
  } = props
  return (
    <View style={style}>
      <Text
        style={[
          global.fontSixteen,
          global.textMedium,
          global.textPrimaryBlack,
          global.vMarginSix,
        ]}
      >
        {customerName}
      </Text>
      {id || mobile || createDate ? (
        <View
          style={[
            global.directionRow,
            global.justifyContentSpaceBetween,
            global.vMarginSix,
          ]}
        >
          {createDate && (
            <View style={[global.directionRow, global.alignItemsCenter]}>
              <Image
                style={[styles.headerIcon, global.alignItemsCenter]}
                source={require('images/calendar.png')}
              />
              <Text
                style={[
                  global.fontTwelve,
                  global.textRegular,
                  global.textSecondaryBlue,
                ]}
              >
                {createDate}
              </Text>
            </View>
          )}
          {mobile && (
            <View style={[global.directionRow, global.alignItemsCenter]}>
              <Image
                style={styles.headerIcon}
                source={require('images/mobile.png')}
              />
              <Text
                style={[
                  global.fontTwelve,
                  global.textRegular,
                  global.textSecondaryBlue,
                ]}
              >
                {mobile}
              </Text>
            </View>
          )}
          {id && (
            <View style={[global.directionRow, global.alignItemsCenter]}>
              <Image
                style={styles.headerIcon}
                source={require('images/tag.png')}
              />
              <Text
                style={[
                  global.fontTwelve,
                  global.textRegular,
                  global.textSecondaryBlue,
                ]}
              >
                {id}
              </Text>
            </View>
          )}
        </View>
      ) : null}
      {address || qcBy ? (
        <View style={tw`flex-row`}>
          <View style={tw`flex-row my-1.5`}>
            <Image
              style={[
                styles.headerIcon,
                styles.marginTopfive,
                addressIconStyle,
              ]}
              source={require('images/location.png')}
            />
            <Text
              style={[
                global.fontTwelve,
                global.textRegular,
                global.textSecondaryBlue,
                global.lineHeightTwenty,
                truncateAddress && styles.paddingRightFifteen,
                addressTextStyle,
              ]}
              numberOfLines={truncateAddress ? 1 : undefined}
            >
              {address || STRINGS.MISCELLANEOUS.ADDRESS_UPDATE}
            </Text>
          </View>
          <View style={tw`pl-5 items-center flex-row my-1.5`}>
            <Image style={[tw`w-3.5 h-3.5`, qcImageStyle]} source={qcImage} />
            <Text
              style={[
                tw`text-sm pl-1.5`,
                global.textRegular,
                global.textSecondaryBlue,
              ]}
            >
              {qcBy}
            </Text>
          </View>
          <View />
        </View>
      ) : null}
      {product ? (
        <View style={tw`flex-row my-1.5 items-center`}>
          <Image
            style={[tw`w-3.5 h-3.5`, productImageStyle]}
            source={productImage}
          />
          <Text
            style={[
              tw`text-sm pl-1.5`,
              global.textRegular,
              global.textSecondaryBlue,
            ]}
          >
            {product}
          </Text>
          {quantity && (
            <Text
              style={[
                tw`text-sm px-px`,
                global.textMedium,
                global.textSecondaryBlue,
              ]}
            >
              {quantity}
            </Text>
          )}
        </View>
      ) : null}
    </View>
  )
}

export default CustomerInformation
