import React, { ReactChild, ReactElement } from 'react'
import {
  View,
  TextInput,
  Pressable,
  KeyboardTypeOptions,
  Text,
} from 'react-native'
import tw from 'tailwind-react-native-classnames'

// styles
import global from 'styles/global'
import styles from './style'

interface InputTextFieldProps {
  placeholder?: string
  onTextChange?: Function
  isSecure?: boolean
  onFocusOut?: Function
  icon?: ReactChild
  maxLength?: number
  style?: object
  innerStyle?: object
  value?: string
  multiline?: boolean
  placeholderTextColor?: string
  leftHeader?: ReactElement
  rightHeader?: ReactElement
  isDisabled?: boolean
  onClick?: Function
  rightIcon?: ReactChild
  containerStyle?: object
  errorMessage?: string
  errorStyle?: object
  keyboardType?: KeyboardTypeOptions
  openKeypadOnTouch?: boolean
  inputEditable?: boolean
}

const InputTextField = ({
  placeholder,
  onTextChange,
  isSecure,
  onFocusOut,
  icon,
  maxLength,
  style,
  innerStyle,
  value,
  multiline,
  placeholderTextColor,
  leftHeader,
  rightHeader,
  isDisabled,
  onClick,
  rightIcon,
  containerStyle,
  errorMessage,
  errorStyle,
  keyboardType = 'default',
  openKeypadOnTouch = true,
  inputEditable = true,
}: InputTextFieldProps) => (
  <View style={containerStyle}>
    <View style={tw`flex-row justify-between`}>
      <View>{leftHeader}</View>
      <View>{rightHeader}</View>
    </View>
    <Pressable disabled={isDisabled} onPress={() => onClick && onClick()}>
      <View
        style={[tw`flex-row border bg-white`, global.borderColorGrey, style]}
      >
        {icon}
        <TextInput
          placeholder={placeholder}
          style={[styles.input, innerStyle]}
          onChangeText={(text: string) => onTextChange && onTextChange(text)}
          secureTextEntry={isSecure}
          onBlur={onFocusOut && onFocusOut()}
          underlineColorAndroid="transparent"
          maxLength={maxLength}
          value={value}
          multiline={multiline}
          placeholderTextColor={placeholderTextColor}
          showSoftInputOnFocus={openKeypadOnTouch}
          editable={inputEditable}
          keyboardType={keyboardType}
        />
        {rightIcon}
      </View>
    </Pressable>
    {errorMessage && <Text style={errorStyle}>{errorMessage}</Text>}
  </View>
)

export default InputTextField
