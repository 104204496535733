import { StyleSheet } from 'react-native'
import colors from 'styles/colors'

const styles = StyleSheet.create({
  tabIndicator: {
    width: '14%',
    height: 4,
    borderRadius: 2,
    left: '18%',
    backgroundColor: colors.bgLightGreen,
  },
  iconDownload: {
    width: 16,
    height: 16,
  },
  iconButton: {
    height: 48,
  },
  creditFooter: {
    borderTopWidth: 1,
    borderTopColor: colors.lightSecondaryGrey,
  },
  creditFooterButton: {
    height: 45,
  },
  iconButtonText: {
    marginLeft: 6,
  },
  showMoreContainer: {
    marginTop: 8,
  },
  showMoreStyle: {
    height: 5,
    width: 24,
    transform: [{ rotate: '90deg' }],
  },
  continueToInvoiceContainer: {
    borderTopColor: colors.lightSecondaryGrey,
  },
  continueToInvoiceButton: {
    height: 45,
  },
})

export default styles
