import React, { useEffect, useState } from 'react'
import {
  View,
  Text,
  Image,
  Pressable,
  FlatList,
  ActivityIndicator,
} from 'react-native'
import { Box, NativeBaseProvider } from 'native-base'

import { Voucher } from 'src/modals'
import { UnbilledDispatchVoucherProps } from 'src/navigations/types'
import STRINGS from 'constants/strings'
import {
  getEndTruncatedString,
  getTruncatedString,
  utcToLocalTime,
} from 'src/utilities/helpers'
import { get, URL } from 'src/utilities/axios'
import { voucherDateFormat, voucherTimeFormat } from 'src/config/voucher'

// component
import CustomerInformation from 'src/components/CustomerInformation'
import UnbilledVoucherFooter from 'src/components/voucher/TotalAmount'
import VoucherCard from 'src/components/voucher/VoucherCard'
import VoucherMetadata from 'src/components/voucher/VoucherMetadata'

// style
import global from 'styles/global'
import colors from 'styles/colors'
import style from './style'

export interface Vouchers {
  items: Voucher[]
  total_count: number
}

const UnbilledDispatchVoucher = ({
  navigation,
}: UnbilledDispatchVoucherProps) => {
  const [vouchers, setVouchers] = useState<Vouchers>({
    items: [],
    total_count: 0,
  })
  const [fetchRecord, setFetchRecord] = useState<boolean>(false)
  const [offset, setOffset] = useState<number>(0)
  const [numberOfInvoice] = useState<number>(10)
  const [sortingOrder, setSortingOrder] = useState<string>('ASC')

  const sortDispatchVouchers = () => {
    setVouchers({ items: [], total_count: 0 })
    if (sortingOrder === 'ASC') setSortingOrder('DESC')
    else setSortingOrder('ASC')
  }

  const fetchVouchers = () => {
    setFetchRecord(true)
    get(URL.UNBILLED_VOUCHERS, {
      limit: numberOfInvoice,
      offset,
      order: sortingOrder,
    })
      .then(data => {
        if (data && data.data) {
          setVouchers({
            items: [...vouchers.items, ...data.data?.items],
            total_count: data.data?.total_count,
          })
        }
        setFetchRecord(false)
      })
      .catch(error => console.error(error))
  }

  useEffect(() => {
    if (offset > 0) setOffset(0)
    // TODO: Provide an explanation for why all deps are not included or fix it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortingOrder])

  useEffect(() => {
    fetchVouchers()
    // TODO: Provide an explanation for why all deps are not included or fix it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset])

  const onLoadMore = () => {
    if (vouchers.items.length !== vouchers.total_count && !fetchRecord) {
      setOffset(offset + numberOfInvoice)
    }
  }

  const renderItem = ({ item }: { item: Voucher }) => (
    <Pressable
      key={item?.id}
      onPress={() => navigation.navigate('VoucherDetails', { id: item.id })}
      style={[global.vMarginEight, global.hMarginTwenty]}
    >
      <VoucherCard
        header={
          <VoucherMetadata
            id={item?.id}
            attributes={[
              {
                attrName: STRINGS.UNBILLED_VOUCHERS.VOUCHER_ID,
                attrValue:
                  item.name && item.name.length > 10
                    ? getTruncatedString(item.name)
                    : item.name || 'NA',
                icon: require('images/id.png'),
              },
              {
                attrName: STRINGS.UNBILLED_VOUCHERS.VOUCHER_DATE,
                attrValue: item?.scheduled_date
                  ? utcToLocalTime(item.scheduled_date, voucherDateFormat)
                  : getEndTruncatedString(
                      STRINGS.MISCELLANEOUS.DATE_NOTE_AVAILABLE,
                    ),
                icon: require('images/calendar.png'),
              },
              {
                attrName: STRINGS.UNBILLED_VOUCHERS.VOUCHER_TIME,
                attrValue: item?.scheduled_date
                  ? utcToLocalTime(item.scheduled_date, voucherTimeFormat)
                  : getEndTruncatedString(
                      STRINGS.MISCELLANEOUS.DATE_NOTE_AVAILABLE,
                    ),
                icon: require('images/clock.png'),
              },
            ]}
          />
        }
        body={
          <CustomerInformation
            customerName={item.partner || 'NA'}
            mobile={item?.contact}
            id={item?.origin}
            style={global.paddingTwenty}
          />
        }
        footer={<UnbilledVoucherFooter amount={item.total_amt || 0} />}
      />
    </Pressable>
  )

  return (
    <NativeBaseProvider>
      <Box flex={1} safeAreaTop>
        <View
          style={[
            global.hMarginTwenty,
            global.directionRow,
            global.justifyContentSpaceBetween,
            global.alignItemsCenter,
            style.unbilledVoucher,
          ]}
        >
          <Text
            style={[
              global.textSecondaryGrey,
              global.textRegular,
              global.fontTwelve,
            ]}
          >
            {`${STRINGS.UNBILLED_VOUCHERS.DISPATCH_VOUCHERS}: ${vouchers.total_count}`}
          </Text>
          <Pressable
            style={[global.directionRow, global.justifyContentSpaceBetween]}
            onPress={() => sortDispatchVouchers()}
          >
            <Image
              source={require('images/exchange.png')}
              style={style.iconRecent}
            />
            <Text
              style={[
                global.textMedium,
                global.fontFourteen,
                global.textPrimaryGreen,
                style.marginLeftSeven,
              ]}
            >
              {STRINGS.UNBILLED_VOUCHERS.RECENT}
            </Text>
          </Pressable>
        </View>
        <FlatList
          keyExtractor={item => `voucher${item.id}`}
          renderItem={renderItem}
          data={vouchers.items}
          onEndReached={onLoadMore}
          onEndReachedThreshold={0.2}
        />
        {fetchRecord && (
          <ActivityIndicator size="large" color={colors.primaryGreen} />
        )}
      </Box>
    </NativeBaseProvider>
  )
}

export default UnbilledDispatchVoucher
