import { StyleSheet } from 'react-native'
import colors from './colors'

const global = StyleSheet.create({
  flexOne: {
    flex: 1,
  },
  flexTwo: {
    flex: 2,
  },
  flexThree: {
    flex: 3,
  },
  flexFour: {
    flex: 4,
  },
  flexFive: {
    flex: 5,
  },
  flexSix: {
    flex: 6,
  },
  flexSeven: {
    flex: 7,
  },
  flexEight: {
    flex: 8,
  },
  flexNine: {
    flex: 9,
  },
  flexWrap: {
    flexWrap: 'wrap',
  },
  flexBasisAuto: {
    flexBasis: 'auto',
  },
  alignItemsCenter: {
    alignItems: 'center',
  },
  alignItemsLeft: {
    alignItems: 'flex-start',
  },
  alignItemsRight: {
    alignItems: 'flex-end',
  },
  justifyContentCenter: {
    justifyContent: 'center',
  },
  justifyContentTop: {
    justifyContent: 'flex-start',
  },
  justifyContentBottom: {
    justifyContent: 'flex-end',
  },
  justifyContentSpaceEven: {
    justifyContent: 'space-evenly',
  },
  justifyContentSpaceBetween: {
    justifyContent: 'space-between',
  },
  alignSelfCenter: {
    alignSelf: 'center',
  },
  bgNone: {
    backgroundColor: 'transparent',
  },
  bgWhite: {
    backgroundColor: colors.white,
  },
  bgGrey: {
    backgroundColor: colors.bgGrey,
  },
  bgLightGrey: {
    backgroundColor: colors.lightGrey,
  },
  bgLightBlue: {
    backgroundColor: colors.bgLightBlue,
  },
  bgMint: {
    backgroundColor: colors.mint,
  },
  bgLightMint: {
    backgroundColor: colors.lightMint,
  },
  bgPrimaryGrren: {
    backgroundColor: colors.primaryGreen,
  },
  bgCandyRed: {
    backgroundColor: colors.candyRed,
  },
  bgRed: {
    backgroundColor: colors.red,
  },
  bgSmokeGrey: {
    backgroundColor: colors.grey,
  },
  bgDarkGrey: {
    backgroundColor: colors.darkGrey,
  },
  bgSecondaryLightGrey: {
    backgroundColor: colors.bgLightGrey,
  },
  paddingZero: {
    padding: 0,
  },
  hPaddingFive: {
    paddingHorizontal: 5,
  },
  paddingEight: {
    padding: 8,
  },
  hPaddingEight: {
    paddingHorizontal: 8,
  },
  vPaddingFour: {
    paddingVertical: 4,
  },
  vPaddingEight: {
    paddingVertical: 8,
  },
  vPaddingFive: {
    paddingVertical: 5,
  },
  paddingTen: {
    padding: 10,
  },
  hPaddingTen: {
    paddingHorizontal: 10,
  },
  vPaddingTen: {
    paddingVertical: 10,
  },
  paddingSixteen: {
    padding: 16,
  },
  hPaddingSixteen: {
    paddingHorizontal: 16,
  },
  vPaddingSixteen: {
    paddingVertical: 16,
  },
  paddingTwenty: {
    padding: 20,
  },
  hPaddingTwenty: {
    paddingHorizontal: 20,
  },
  vPaddingTwenty: {
    paddingVertical: 20,
  },
  vPaddingThirty: {
    paddingVertical: 30,
  },
  paddingBottomSixteen: {
    paddingBottom: 16,
  },
  paddingBottomTen: {
    paddingBottom: 10,
  },
  paddingTopTwenty: {
    paddingTop: 20,
  },
  paddingTopFifteen: {
    paddingTop: 15,
  },
  paddingTopTen: {
    paddingTop: 10,
  },
  paddingTopFour: {
    paddingTop: 4,
  },
  directionRow: {
    flexDirection: 'row',
  },
  directionCol: {
    flexDirection: 'column',
  },
  borderRadiusZero: {
    borderRadius: 0,
  },
  borderRadiusTwo: {
    borderRadius: 2,
  },
  borderRadiusFour: {
    borderRadius: 4,
  },
  borderRadiusEight: {
    borderRadius: 8,
  },
  borderRadiusFive: {
    borderRadius: 5,
  },
  borderRadiusTen: {
    borderRadius: 10,
  },
  widthOneForth: {
    width: '25%',
  },
  widthFortyFive: {
    width: '45%',
  },
  widthHalf: {
    width: '50%',
  },
  widthThreeForth: {
    width: '75%',
  },
  widthNinety: {
    width: '90%',
  },
  widthFull: {
    width: '100%',
  },
  heightFull: {
    height: '100%',
  },
  heightAuto: {
    height: 'auto',
  },
  buttonHeight: {
    height: 45,
  },
  textAlignCenter: {
    textAlign: 'center',
  },
  textAlignTop: {
    textAlignVertical: 'top',
  },
  horizonalLine: {
    borderWidth: 1,
    borderColor: colors.borderGrey,
    marginTop: 10,
    marginBottom: 5,
    borderTopWidth: 0,
    borderRightWidth: 0,
    borderLeftWidth: 0,
  },
  textBlack: {
    fontFamily: 'roboto-black',
  },
  textBold: {
    fontFamily: 'roboto-bold',
  },
  textLight: {
    fontFamily: 'roboto-light',
  },
  textMedium: {
    fontFamily: 'roboto-medium',
  },
  textRegular: {
    fontFamily: 'roboto-regular',
  },
  textThin: {
    fontFamily: 'roboto-thin',
  },
  textItalic: {
    fontStyle: 'italic',
  },
  textCapitalize: {
    textTransform: 'capitalize',
  },
  vMarginTwo: {
    marginVertical: 2,
  },
  vMarginSix: {
    marginVertical: 6,
  },
  vMarginEight: {
    marginVertical: 8,
  },
  vMarginSixteen: {
    marginVertical: 16,
  },
  vMarginTen: {
    marginVertical: 10,
  },
  positionRelative: {
    position: 'relative',
  },
  positionAbsolute: {
    position: 'absolute',
  },
  hMarginEight: {
    marginHorizontal: 8,
  },
  hMarginTwenty: {
    marginHorizontal: 20,
  },
  marginTopFifteen: {
    marginTop: 15,
  },
  fontEight: {
    fontSize: 8,
  },
  fontTen: {
    fontSize: 10,
  },
  fontTwelve: {
    fontSize: 12,
  },
  fontThirteen: {
    fontSize: 13,
  },
  fontFourteen: {
    fontSize: 14,
  },
  fontEighteen: {
    fontSize: 18,
  },
  fontTwenty: {
    fontSize: 20,
  },
  fontTwentyFour: {
    fontSize: 24,
  },
  fontTwentyEight: {
    fontSize: 28,
  },
  flexGrowTwo: {
    flexGrow: 2,
  },
  marginLeftEight: {
    marginLeft: 8,
  },
  marginLeftFour: {
    marginLeft: 4,
  },
  marginLeftSixteen: {
    marginLeft: 16,
  },
  margingEight: {
    margin: 8,
  },
  fontSixteen: {
    fontSize: 16,
  },
  zeroHeight: {
    height: 0,
  },
  lineHeightSixteen: {
    lineHeight: 16,
  },
  lineHeightTwenty: {
    lineHeight: 20,
  },
  textPrimaryBlack: {
    color: colors.primaryTextBlack,
  },
  textColorGrey: {
    color: colors.textGrey,
  },
  textSecondaryGrey: {
    color: colors.secondaryTextGrey,
  },
  textDarkGrey: {
    color: colors.darkGrey,
  },
  textPink: {
    color: colors.pink,
  },
  textGreenDisabled: {
    color: colors.greenDisabled,
  },
  textMint: {
    color: colors.mint,
  },
  borderColorGrey: {
    borderColor: colors.bgGrey,
  },
  borderColorSmokeGrey: {
    borderColor: colors.grey,
  },
  borderColorLightGrey: {
    borderColor: colors.lightGrey,
  },
  borderColorDarkGrey: {
    borderColor: colors.darkGrey,
  },
  borderColorRed: {
    borderColor: colors.red,
  },
  borderColorGreenDisabled: {
    borderColor: colors.greenDisabled,
  },
  borderColorPrimaryGreen: {
    borderColor: colors.primaryGreen,
  },
  borderTopColorbgLightBlue: {
    borderTopColor: colors.bgLightBlue,
  },
  borderColorLightSecondaryGrey: {
    borderColor: colors.lightSecondaryGrey,
  },
  borderColorSecondaryOrange: {
    borderColor: colors.secondaryOrange,
  },
  borderColorSecondaryTextGrey: {
    borderColor: colors.secondaryTextGrey,
  },
  marginZero: {
    margin: 0,
  },
  borderWidthOne: {
    borderWidth: 1,
  },
  borderTopWidthOne: {
    borderTopWidth: 1,
  },
  textSecondaryBlue: {
    color: colors.secondaryTextBlue,
  },
  textSecondaryBlue50: {
    color: colors.secondaryTextBlue50,
  },
  textSecondaryBlue70: {
    color: colors.secondaryTextBlue70,
  },
  drawer: {
    shadowColor: colors.black,
    shadowOpacity: 0.5,
    shadowRadius: 3,
    backgroundColor: colors.white,
    borderRadius: 8,
    height: 200,
    left: 0,
    bottom: 0,
    opacity: 1,
    position: 'absolute',
    width: '100%',
  },
  drawerBackground: {
    opacity: 0.5,
  },
  textYellow: {
    color: colors.yellow,
  },
  textPrimaryGreen: {
    color: colors.primaryGreen,
  },
  textRed: {
    color: colors.red,
  },
  textSecondaryOrange: {
    color: colors.secondaryOrange,
  },
  textDecorationLine: {
    textDecorationLine: 'underline',
  },
  textDecorationColorPrimaryGreen: {
    textDecorationColor: colors.primaryGreen,
  },
  bgLimeGreen: {
    backgroundColor: colors.limeGreen,
  },
  bgLightGreen: {
    backgroundColor: colors.bgGreen,
  },
  bgScondaryGrey: {
    backgroundColor: colors.textGrey,
  },
  bgSecondaryOrange: {
    backgroundColor: colors.secondaryOrange,
  },
  textWhite: {
    color: colors.white,
  },
  bgSeaGreen: {
    backgroundColor: colors.seaGreen,
  },
})

export default global
