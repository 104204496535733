import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { View, Text, Image } from 'react-native'
import tw from 'tailwind-react-native-classnames'
import { AxiosError, AxiosResponse } from 'axios'

import STRINGS from 'constants/strings'
import { get, URL } from 'src/utilities/axios'
import {
  CloseNodeDrawerProps,
  ClosePaymentTermProps,
  CloseVendorDrawerProps,
  CreatePOTabProps,
  NodeProps,
  VendorProps,
} from 'types/procurement'
import { ApplicationState } from 'src/redux/types'
import { store } from 'src/redux/store'
import { showToastNotification } from 'src/utilities/helpers'
import { DOWNLOAD } from 'src/config/download'
import {
  setDraftPODetails,
  // commented code for Release v0.9.1
  // toggleBackDraftPOModal,
} from 'src/redux/actions/purchase-order'

// componnet
import Button from 'src/components/form-fields/Button'
import { SearchOptions } from 'src/components/drawers'
import { SearchOptionsProps } from 'src/components/drawers/SearchOptions'
import InputTextField from 'src/components/form-fields/InputTextField'

// style
import global from 'styles/global'
import colors from 'styles/colors'
import styles from './style'

const { CREATE_PO } = STRINGS

const Vendor = (props: { props: CreatePOTabProps }) => {
  // TODO: Destructure the props to { props }
  // eslint-disable-next-line react/destructuring-assignment
  const { setIndex, id, navigation } = props?.props
  const createPOStore = useSelector(
    (state: ApplicationState) => state.procurement.createPO,
  )
  const dispatch = useDispatch()

  const [searchOptionsProps, setSearchOptionsProps] = useState<any>({})
  const [isDrawerOpen, setDrawerOpen] = useState<boolean>(false)
  const [nodeList, setNodeList] = useState<Array<NodeProps>>([])
  const [vendorList, setVendorList] = useState<Array<NodeProps>>([])
  const [paymentTermList, setPaymentTermList] = useState<Array<VendorProps>>([])
  const [mount, setMount] = useState(false)

  const isVendorDisabled = !(vendorList?.length > 0 && createPOStore.node?.id)
  const isPaymentTermDisabled = !(
    paymentTermList?.length > 0 && createPOStore.node?.id
  )

  const onDropdownClick = (drawerProps: SearchOptionsProps) => {
    setDrawerOpen(true)
    setSearchOptionsProps(drawerProps)
  }

  const onSelectNodeDrawerClose = (selectedItem: CloseNodeDrawerProps) => {
    setDrawerOpen(false)
    if (selectedItem?.id) {
      const procStore = store.getState().procurement.createPO
      dispatch({
        type: 'SET_CREATE_PO_FIELD',
        payload: { ...procStore, node: selectedItem },
      })
    }
  }

  const onSelectVendorDrawerClose = (selectedItem: CloseVendorDrawerProps) => {
    setDrawerOpen(false)
    if (selectedItem?.id) {
      const procStore = store.getState().procurement.createPO
      dispatch({
        type: 'SET_CREATE_PO_FIELD',
        payload: { ...procStore, vendor: selectedItem },
      })
    }
  }

  const onSelectPaymentTermsClose = (selectedItem: ClosePaymentTermProps) => {
    setDrawerOpen(false)
    if (selectedItem?.id) {
      const procStore = store.getState().procurement.createPO
      dispatch({
        type: 'SET_CREATE_PO_FIELD',
        payload: { ...procStore, paymentTerm: selectedItem },
      })
    }
  }

  const selectNodeProps = {
    title: CREATE_PO.SELECT_NODE,
    searchPlaceholder: CREATE_PO.SEARCH_BY_NODE_NAME,
    onLoadValue: createPOStore.node,
    closeDrawer: onSelectNodeDrawerClose,
    keyDisplayName: 'name',
    searchKeys: 'name',
    localItemList: nodeList,
  }

  const selectVendorProps = {
    title: CREATE_PO.SELECT_VENDOR,
    searchPlaceholder: CREATE_PO.SEARCH_BY_VENDOR_NAME,
    onLoadValue: createPOStore.vendor,
    closeDrawer: onSelectVendorDrawerClose,
    keyDisplayName: 'display_name',
    searchKeys: 'name',
    itemListOnLoad: vendorList,
    apiToLoadOption: `${URL.VENDOR_LIST}?node_id=${createPOStore.node?.id}&`,
  }

  const selectPaymentTermsProps = {
    title: CREATE_PO.SELECT_PAYMENT_TERMS,
    searchPlaceholder: CREATE_PO.SEARCH_BY_PAYMENT_TERMS,
    onLoadValue: createPOStore.paymentTerm,
    closeDrawer: onSelectPaymentTermsClose,
    keyDisplayName: 'name',
    searchKeys: 'name',
    localItemList: paymentTermList,
  }

  const handleVendorDropdown = () => {
    get(URL.VENDOR_LIST, {
      node_id: createPOStore.node?.id,
      limit: 100,
      offset: 0,
    })
      .then((res: AxiosResponse) => {
        setVendorList(res?.data)
        if (
          (!mount && !createPOStore.vendor?.id) ||
          (mount && createPOStore.vendor?.id)
        ) {
          let payload = {}
          // TODO: can be changed to payload = {...res.data[0]}
          // eslint-disable-next-line prefer-destructuring
          if (res?.data?.length === 1) payload = res.data[0]
          const procStore = store.getState().procurement.createPO
          dispatch({
            type: 'SET_CREATE_PO_FIELD',
            payload: { ...procStore, vendor: payload },
          })
        }
      })
      .catch((err: AxiosError) => {
        showToastNotification(DOWNLOAD.ERROR, err.response?.data.error)
      })
  }

  const handlePaymentTermDropdown = () => {
    get(URL.PAYMENT_TERMS)
      .then((res: AxiosResponse) => {
        setPaymentTermList(res?.data)
        if (
          (!mount && !createPOStore.paymentTerm?.id) ||
          (mount && createPOStore.paymentTerm?.id)
        ) {
          let payload = {}
          // TODO: can be changed to payload = {...res.data[0]}
          // eslint-disable-next-line prefer-destructuring
          if (res?.data?.length === 1) payload = res.data[0]
          const { createPO } = store.getState().procurement
          dispatch({
            type: 'SET_CREATE_PO_FIELD',
            payload: { ...createPO, paymentTerm: payload },
          })
        }
      })
      .catch((err: AxiosError) => {
        showToastNotification(DOWNLOAD.ERROR, err.response?.data.error)
      })
  }

  useEffect(() => {
    get(URL.NODE_LIST)
      .then((res: AxiosResponse) => {
        setNodeList(res?.data)
        if (res?.data?.length === 1) {
          if (
            (!mount && !createPOStore.paymentTerm?.id) ||
            (mount && createPOStore.paymentTerm?.id)
          ) {
            dispatch({
              type: 'SET_CREATE_PO_FIELD',
              payload: { ...createPOStore, node: res.data[0] },
            })
          }
        }
      })
      .catch((err: AxiosError) => {
        showToastNotification(DOWNLOAD.ERROR, err.response?.data.error)
      })
    // TODO: Provide an explanation for why all deps are not included or fix it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (createPOStore.node?.id) {
      handleVendorDropdown()
      if (paymentTermList?.length === 0) handlePaymentTermDropdown()
      if (!mount) setMount(true)
    }
    // TODO: Provide an explanation for why all deps are not included or fix it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createPOStore.node?.id])

  useEffect(() => {
    if (
      createPOStore.node?.id &&
      createPOStore.vendor?.id &&
      createPOStore.paymentTerm?.id
    )
      dispatch(
        setDraftPODetails({
          vendor_id: createPOStore.vendor.id,
          node_id: createPOStore.node?.id,
          payment_term_id: createPOStore.paymentTerm?.id,
        }),
      )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    createPOStore.node?.id,
    createPOStore.vendor?.id,
    createPOStore.paymentTerm?.id,
  ])

  const isNextButtonDisable = () =>
    !(
      createPOStore.node?.id &&
      createPOStore.vendor?.id &&
      createPOStore.paymentTerm?.id
    )

  const leftHeader = (text: string, disable: boolean) => (
    <View style={tw`flex-row items-center mt-5 ml-5 mb-2.5 `}>
      <Text
        style={[
          global.textRegular,
          tw`text-xs`,
          disable ? global.textDarkGrey : global.textColorGrey,
        ]}
      >
        {text}
      </Text>
      <Text style={disable ? global.textDarkGrey : global.textRed}>*</Text>
    </View>
  )

  const getImage = (disabled: boolean) => (
    <Image
      source={
        disabled
          ? require('images/arrow-down-disabled.png')
          : require('images/arrow-down-enabled.png')
      }
      style={tw`mr-2.5 w-2.5 h-1.5`}
    />
  )

  return (
    <View style={tw`h-0 flex-1`}>
      <View
        style={[tw`h-0 flex-1`, isDrawerOpen && global.drawerBackground]}
        pointerEvents={!isDrawerOpen ? 'auto' : 'none'}
      >
        <InputTextField
          leftHeader={leftHeader(CREATE_PO.NODE, false)}
          value={createPOStore.node?.name || ''}
          isDisabled={false}
          onClick={() => onDropdownClick(selectNodeProps)}
          placeholder={CREATE_PO.SELECT_NODE}
          rightIcon={getImage(false)}
          style={tw`mx-5 h-11 justify-between items-center rounded-md`}
          placeholderTextColor={colors.darkGrey}
          innerStyle={[
            global.textRegular,
            global.textColorGrey,
            tw`text-sm ml-2.5`,
            createPOStore.node?.id && global.textMedium,
          ]}
          openKeypadOnTouch={false}
          inputEditable={false}
        />
        <InputTextField
          leftHeader={leftHeader(CREATE_PO.VENDOR, isVendorDisabled)}
          isDisabled={isVendorDisabled}
          value={createPOStore.vendor?.display_name || ''}
          onClick={() => onDropdownClick(selectVendorProps)}
          placeholder={CREATE_PO.SELECT_VENDOR}
          rightIcon={getImage(isVendorDisabled)}
          style={tw`mx-5 h-11 justify-between items-center rounded-md`}
          placeholderTextColor={colors.darkGrey}
          innerStyle={[
            global.textRegular,
            global.textColorGrey,
            tw`text-sm ml-2.5`,
            createPOStore.vendor?.id && global.textMedium,
          ]}
          openKeypadOnTouch={false}
          inputEditable={false}
        />
        <InputTextField
          leftHeader={leftHeader(
            CREATE_PO.PAYMENT_TERMS,
            isPaymentTermDisabled,
          )}
          isDisabled={isPaymentTermDisabled}
          value={createPOStore.paymentTerm?.name || ''}
          onClick={() => onDropdownClick(selectPaymentTermsProps)}
          placeholder={CREATE_PO.SELECT_PAYMENT_TERMS}
          rightIcon={getImage(isPaymentTermDisabled)}
          style={tw`mx-5 h-11 justify-between items-center rounded-md`}
          placeholderTextColor={colors.darkGrey}
          innerStyle={[
            global.textRegular,
            global.textColorGrey,
            tw`text-sm ml-2.5`,
            createPOStore.paymentTerm?.id && global.textMedium,
          ]}
          openKeypadOnTouch={false}
          inputEditable={false}
        />
      </View>
      <View
        style={[
          global.borderColorLightSecondaryGrey,
          // commented code for Release v0.9.1
          // tw`bg-white py-5 px-5 flex-row justify-between`,
          tw`bg-white py-2.5 px-5`,
        ]}
      >
        {/* commented code for Release v0.9.1 */}
        {/* <Button
          disable={isNextButtonDisable()}
          title={STRINGS.BUTTON.SAVE_AS_DRAFT}
          style={[tw`rounded-lg w-5/12`, styles.nextButton]}
          onTap={() => dispatch(toggleBackDraftPOModal())}
          btnType="secondary"
          textStyle={
            isNextButtonDisable()
              ? global.textGreenDisabled
              : global.textPrimaryGreen
          }
        /> */}
        <Button
          disable={isNextButtonDisable()}
          title={
            id !== undefined ? STRINGS.BUTTON.PREVIEW : STRINGS.BUTTON.NEXT
          }
          style={[tw`rounded-lg`, styles.nextButton]}
          // commented code for Release v0.9.1
          // style={[tw`rounded-lg w-1/2`, styles.nextButton]}
          onTap={() =>
            id !== undefined
              ? navigation.navigate('POPreview')
              : setIndex && setIndex(1)
          }
        />
      </View>
      {isDrawerOpen && (
        <SearchOptions
          title={searchOptionsProps.title}
          searchPlaceholder={searchOptionsProps.searchPlaceholder}
          onLoadValue={searchOptionsProps.onLoadValue}
          closeDrawer={searchOptionsProps.closeDrawer}
          keyDisplayName={searchOptionsProps.keyDisplayName}
          searchKeys={searchOptionsProps.searchKeys}
          itemListOnLoad={searchOptionsProps.itemListOnLoad}
          apiToLoadOption={searchOptionsProps.apiToLoadOption}
          hideSearchBoxItemLimit={searchOptionsProps.hideSearchBoxItemLimit}
          localItemList={searchOptionsProps.localItemList}
        />
      )}
    </View>
  )
}

export default Vendor
