import React, { useState, useEffect, ReactElement } from 'react'
import {
  View,
  Text,
  ScrollView,
  ActivityIndicator,
  Image,
  Pressable,
} from 'react-native'

import { Invoice, Note as NoteType, Voucher } from 'src/modals'
import STRINGS from 'constants/strings'
import { utcToLocalTime } from 'src/utilities/helpers'
import { voucherDateFormat } from 'src/config/voucher'

// components
import EditDelete from 'src/components/drawers/EditDelete'
import Modal from 'src/components/Modal'
import Button from 'src/components/form-fields/Button'
import { AddNoteContent } from 'src/components/drawers'

// styles
import global from 'styles/global'
import colors from 'styles/colors'
import styles from './style'

export interface MoreDetailsProps {
  route: {
    voucher?: Voucher
    invoice?: Invoice
  }
}

const MoreDetails = ({ route }: MoreDetailsProps) => {
  const { voucher, invoice } = route
  const [data, setData] = useState(voucher || invoice)
  const [showLoader, setShowLoader] = useState<boolean>(true)
  const [noteExists, setNoteExistance] = useState<boolean>(false)
  const [showDrawer, setDrawer] = useState<boolean>(false)
  const [showModal, setShowModal] = useState(false)
  const [modalBody, setModalBody] = useState<ReactElement>(<></>)
  const [modalFooter, setModalFooter] = useState<ReactElement>(<></>)
  const [noteDrawer, setNoteDrawer] = useState<boolean>(false)
  const [selectedNote, selectNote] = useState<NoteType>()

  useEffect(() => {
    if (invoice || voucher) {
      setData(invoice || voucher)
      setShowLoader(false)
    } else setShowLoader(true)
  }, [invoice, voucher])

  useEffect(() => {
    if (data) {
      data.notes.forEach(note => {
        if (note.body !== false && !noteExists) {
          setNoteExistance(true)
        }
      })
    }
    // TODO: Provide an explanation for why all deps are not included or fix it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  // if note is passed then it will modify the selected note else will delete the selected note
  const modifyNote = (note?: string) => {
    const copyOfSelectedNote = { ...selectedNote }
    if (note) {
      copyOfSelectedNote!.body = note
    } else {
      copyOfSelectedNote!.body = false
    }
    selectNote(copyOfSelectedNote as NoteType)
  }

  // modal showing warning on delete note
  const modal = () => {
    setModalBody(
      <View style={[global.alignItemsCenter, styles.paddingTopForty]}>
        <Image style={styles.warning} source={require('images/warning.png')} />
        <Text
          style={[
            global.fontFourteen,
            global.textRegular,
            global.textColorGrey,
            global.textAlignCenter,
            global.paddingTwenty,
            styles.paddingBottomForty,
          ]}
        >
          {STRINGS.VOUCHER.DELETE_NOTE}
        </Text>
      </View>,
    )
    setModalFooter(
      <View
        style={[
          global.directionRow,
          global.justifyContentSpaceBetween,
          styles.btnContainer,
        ]}
      >
        <Button
          title={STRINGS.BUTTON.YES}
          onTap={() => modifyNote()}
          btnType="secondary"
          style={[global.widthHalf, global.vPaddingSixteen, styles.btnYesCover]}
          textStyle={global.textRegular}
        />
        <Button
          title={STRINGS.BUTTON.NO}
          onTap={() => setShowModal(false)}
          btnType="secondary"
          style={[global.widthHalf, global.vPaddingSixteen, styles.btnNoCover]}
          textStyle={[global.textRegular, global.textSecondaryOrange]}
        />
      </View>,
    )
    setShowModal(true)
  }

  return (
    <>
      <View
        style={[
          global.bgWhite,
          global.heightFull,
          (noteDrawer || showDrawer) && global.drawerBackground,
        ]}
      >
        <ScrollView>
          {data && (
            <View>
              <View
                style={[
                  global.hPaddingTwenty,
                  global.vPaddingThirty,
                  styles.bottomBorder,
                ]}
              >
                <View>
                  <Text
                    style={[
                      global.textPrimaryBlack,
                      global.fontFourteen,
                      global.paddingBottomTen,
                      global.textMedium,
                    ]}
                  >
                    {STRINGS.VOUCHER.CUSTOMER_DETAILS}
                  </Text>
                  <Text
                    style={[
                      global.textPink,
                      global.fontTwelve,
                      global.textRegular,
                    ]}
                  >
                    {STRINGS.VOUCHER.NAME}
                  </Text>
                  <Text
                    style={[
                      global.textSecondaryBlue,
                      global.fontFourteen,
                      global.textRegular,
                      styles.paddingTopThree,
                    ]}
                  >
                    {data.customer.name}
                  </Text>
                </View>
                <View style={[global.vPaddingTwenty, global.widthThreeForth]}>
                  <View
                    style={[
                      global.directionRow,
                      global.justifyContentSpaceBetween,
                    ]}
                  >
                    <Text
                      style={[
                        global.textPink,
                        global.fontTwelve,
                        global.textRegular,
                      ]}
                    >
                      {STRINGS.VOUCHER.SO_ID}
                    </Text>
                    <Text
                      style={[
                        global.textPink,
                        global.fontTwelve,
                        global.textRegular,
                      ]}
                    >
                      {STRINGS.VOUCHER.CONTACT_NUMBER}
                    </Text>
                  </View>
                  <View
                    style={[
                      global.directionRow,
                      global.justifyContentSpaceBetween,
                    ]}
                  >
                    <Text
                      style={[
                        global.textSecondaryBlue,
                        global.fontFourteen,
                        global.textRegular,
                        styles.paddingTopThree,
                      ]}
                    >
                      {data.so_id}
                    </Text>
                    <Text
                      style={[
                        global.textSecondaryBlue,
                        global.fontFourteen,
                        global.textRegular,
                        styles.paddingTopThree,
                      ]}
                    >
                      {data.customer.mobile ||
                        STRINGS.MISCELLANEOUS.NOT_AVAILABLE}
                    </Text>
                  </View>
                </View>
                <Text
                  style={[
                    global.textPink,
                    global.fontTwelve,
                    global.textRegular,
                  ]}
                >
                  {STRINGS.VOUCHER.DELIVERY_LOCATION}
                </Text>
                <Text
                  style={[
                    global.textSecondaryBlue,
                    global.fontFourteen,
                    global.textRegular,
                  ]}
                >
                  {data.destination || STRINGS.MISCELLANEOUS.NOT_AVAILABLE}
                </Text>
              </View>
              {data.sales_manager && (
                <View style={[global.paddingTwenty, styles.bottomBorder]}>
                  <View>
                    <Text
                      style={[
                        global.textPrimaryBlack,
                        global.fontFourteen,
                        global.vPaddingTen,
                        global.textMedium,
                      ]}
                    >
                      {STRINGS.VOUCHER.SALES_MANAGER_DETAILS}
                    </Text>
                  </View>
                  <View style={[global.vPaddingTen, global.widthThreeForth]}>
                    <View
                      style={[
                        global.directionRow,
                        global.justifyContentSpaceBetween,
                      ]}
                    >
                      <Text
                        style={[
                          global.textPink,
                          global.fontTwelve,
                          global.textRegular,
                        ]}
                      >
                        {STRINGS.VOUCHER.NAME}
                      </Text>
                      <Text
                        style={[
                          global.textPink,
                          global.fontTwelve,
                          global.textRegular,
                        ]}
                      >
                        {STRINGS.VOUCHER.CONTACT_NUMBER}
                      </Text>
                    </View>
                    <View
                      style={[
                        global.directionRow,
                        global.justifyContentSpaceBetween,
                      ]}
                    >
                      <Text
                        style={[
                          global.textSecondaryBlue,
                          global.fontFourteen,
                          global.textRegular,
                          styles.paddingTopThree,
                        ]}
                      >
                        {data.sales_manager.name}
                      </Text>
                      <Text
                        style={[
                          global.textSecondaryBlue,
                          global.fontFourteen,
                          global.textRegular,
                          styles.paddingTopThree,
                        ]}
                      >
                        {data.sales_manager.mobile}
                      </Text>
                    </View>
                  </View>
                </View>
              )}
              <View style={global.paddingTwenty}>
                {noteExists && (
                  <View>
                    <Text
                      style={[
                        global.textPrimaryBlack,
                        global.fontFourteen,
                        global.vPaddingTen,
                        global.textMedium,
                      ]}
                    >
                      {STRINGS.VOUCHER.ADDITIONAL_NOTE}
                    </Text>
                  </View>
                )}
                {data.notes &&
                  data.notes.map((note: NoteType, index: number) => (
                    // TODO: Need to provide reason for why index key or else fix it
                    // eslint-disable-next-line react/no-array-index-key
                    <View key={`${note.origin}${index}`}>
                      {note.body && (
                        <View style={global.vPaddingTen}>
                          <View
                            style={[
                              global.directionRow,
                              global.justifyContentSpaceBetween,
                            ]}
                          >
                            <Text
                              style={[
                                global.textPink,
                                global.fontTwelve,
                                global.textRegular,
                                global.textItalic,
                                global.textCapitalize,
                              ]}
                            >
                              {note.origin}
                            </Text>
                            <View
                              style={[
                                global.directionRow,
                                global.alignItemsCenter,
                              ]}
                            >
                              <Text
                                style={[
                                  global.textPink,
                                  global.fontTwelve,
                                  global.textRegular,
                                  global.textItalic,
                                  global.textCapitalize,
                                  note.origin ===
                                    STRINGS.NOTES_MAPPING[0].value &&
                                    global.hPaddingTen,
                                ]}
                              >
                                {utcToLocalTime(note.date, voucherDateFormat)}
                              </Text>
                              {note.origin ===
                                STRINGS.NOTES_MAPPING[0].value && (
                                <Pressable
                                  onPress={() => {
                                    selectNote(note)
                                    setDrawer(true)
                                  }}
                                >
                                  <Image
                                    source={require('images/dots-vertical.png')}
                                    style={styles.verticalIcon}
                                  />
                                </Pressable>
                              )}
                            </View>
                          </View>
                          <View style={global.vPaddingSixteen}>
                            <Text
                              style={[
                                global.textPink,
                                global.fontTwelve,
                                global.textRegular,
                              ]}
                            >
                              {note.body}
                            </Text>
                          </View>
                        </View>
                      )}
                    </View>
                  ))}
              </View>
            </View>
          )}
          {showLoader && (
            <ActivityIndicator
              size="large"
              color={colors.primaryGreen}
              style={global.vMarginSixteen}
            />
          )}
        </ScrollView>
      </View>
      {showDrawer && (
        <EditDelete
          closeDrawer={setDrawer}
          onDelete={() => modal()}
          onEdit={() => setNoteDrawer(true)}
        />
      )}
      {showModal && (
        <Modal
          body={modalBody}
          footer={modalFooter}
          close={() => setShowModal(false)}
          style={global.paddingZero}
        />
      )}
      {noteDrawer && (
        <AddNoteContent
          setDrawer={setNoteDrawer}
          addNote={(text: string) => modifyNote(text)}
          note={
            typeof selectedNote?.body !== 'boolean' ? selectedNote?.body : ''
          }
        />
      )}
    </>
  )
}

export default MoreDetails
