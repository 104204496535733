import React, { useState } from 'react'
import { ScrollView, View, Image, Text } from 'react-native'
import { NativeBaseProvider, Box, HStack, Center } from 'native-base'

import { ProductDetailsEditProps } from 'src/navigations/types'
import STRINGS from 'constants/strings'
import { Item } from 'src/modals'

// components
import ProductEdit from 'src/components/product/ProductEdit'
import ProductCard from 'src/components/product/ProductCard'
import Button from 'src/components/form-fields/Button'
import Modal from 'src/components/Modal'

// styles
import global from 'styles/global'
import styles from './style'

const ProductDetailsEdit = ({ route, navigation }: ProductDetailsEditProps) => {
  const { items, index, orderId } = route.params
  const [currentItem, setCurrentitem] = useState<Item>(items[index])
  const [showModal, setShowModal] = useState(false)
  const [modalBody, setModalBody] = useState(<></>)
  const [modalFooter, setModalFooter] = useState(<></>)

  const onDelete = () => {
    if (items.length === 1) {
      setShowModal(false)
      return
    }
    const copyOfItems = [...items]
    copyOfItems.splice(index, 1)
    navigation.navigate('EditOrder', {
      items: copyOfItems,
      orderId,
    })
  }

  const deleteVariant = () => {
    setModalBody(
      <View style={global.alignItemsCenter}>
        <Image
          style={[styles.warning]}
          source={require('images/warning.png')}
        />
        <Text
          style={[
            styles.warningMsg,
            global.fontFourteen,
            global.textRegular,
            global.textColorGrey,
            global.textAlignCenter,
          ]}
        >
          {STRINGS.ORDER.DELETE_WARNING}
        </Text>
      </View>,
    )
    setModalFooter(
      <View
        style={[
          styles.btnContainer,
          global.directionRow,
          global.justifyContentSpaceBetween,
        ]}
      >
        <Button
          title={STRINGS.BUTTON.YES}
          onTap={() => onDelete()}
          btnType="secondary"
          style={[styles.btnYesCover, global.widthHalf]}
          textStyle={global.textRegular}
        />
        <Button
          title={STRINGS.BUTTON.NO}
          onTap={() => {
            setShowModal(false)
          }}
          btnType="secondary"
          style={[styles.btnNoCover, global.widthHalf]}
          textStyle={[styles.btnNo, global.textRegular]}
        />
      </View>,
    )
    setShowModal(true)
  }

  const setAttribute = (_: number, keyName: string, value: number | string) => {
    const copyOfCurrentItem: any = { ...currentItem }
    if (typeof value === 'string') {
      copyOfCurrentItem.quality_request = {
        ...copyOfCurrentItem.quality_request,
        [keyName]: value,
      }
    }
    if (typeof value === 'number') {
      copyOfCurrentItem[keyName] = value
    }
    setCurrentitem(copyOfCurrentItem)
  }

  const save = () => {
    if (currentItem.price_unit === 0 || currentItem.product_uom_qty === 0) {
      return
    }
    const copyOfItems = [...items]
    copyOfItems[index] = currentItem
    navigation.navigate('EditOrder', {
      items: copyOfItems,
      orderId,
    })
  }

  return (
    <NativeBaseProvider>
      <Box flex={1} safeAreaTop>
        <Center flex={1}>
          <ProductCard
            data={currentItem}
            index={index}
            deletable={() => deleteVariant()}
            navigation={navigation}
            style={[global.bgNone, global.widthFull, styles.productCard]}
          />
          <ScrollView style={[global.widthFull]}>
            <ProductEdit
              key={index}
              data={currentItem}
              variantIndex={0}
              setAttribute={setAttribute}
            />
          </ScrollView>
        </Center>
        <HStack safeAreaBottom shadow={6}>
          <View
            style={[
              global.bgWhite,
              global.hPaddingTwenty,
              global.widthFull,
              global.flexOne,
              styles.footer,
            ]}
          >
            <View style={[styles.footerBtnContainer]}>
              <Button
                title={STRINGS.BUTTON.SAVE_CHANGES}
                onTap={() => save()}
                style={global.borderRadiusEight}
              />
            </View>
            {showModal && (
              <Modal
                body={modalBody}
                footer={modalFooter}
                close={() => setShowModal(false)}
              />
            )}
          </View>
        </HStack>
      </Box>
    </NativeBaseProvider>
  )
}

export default ProductDetailsEdit
