import { StyleSheet } from 'react-native'
import colors from 'styles/colors'

const styles = StyleSheet.create({
  welcomeMsg: {
    color: colors.primaryTextBlack,
    fontSize: 28,
  },
})

export default styles
