import { Dispatch } from 'react'

import { get, URL } from 'src/utilities/axios'
import { UserActions } from 'src/redux/types'
import {
  OutstandingAmountResponse,
  SBOutstandingAmountResponse,
  SBUnbilledDVCountResponse,
  UserDetailsResponse,
} from 'src/modals'

export const fetchUserDetails = () => {
  return async (dispatch: Dispatch<UserActions>) => {
    try {
      const res: UserDetailsResponse = await get(URL.USER_DETAILS)
      if (res.error === null) {
        dispatch({
          type: 'SET_USER_DETAILS',
          payload: res.data,
        })
      }
    } catch (error) {
      dispatch({
        type: 'SET_USER_DETAILS_ERROR',
        payload: String(error),
      })
    }
  }
}

export const fetchOutstandingAmount = () => {
  return async (dispatch: Dispatch<UserActions>) => {
    try {
      const res: OutstandingAmountResponse = await get(URL.OUTSTANDING_AMOUNT)
      if (res.error === null) {
        dispatch({
          type: 'SET_OUTSTANDING_AMOUNT',
          payload: res.data,
        })
      }
    } catch (error) {
      dispatch({
        type: 'SET_OUTSTANDING_AMOUNT_ERROR',
        payload: String(error),
      })
    }
  }
}

export const fetchSBOutstandingAmount =
  () => async (dispatch: Dispatch<UserActions>) => {
    try {
      const response: SBOutstandingAmountResponse = await get(
        URL.SB_OUTSTANDING_AMOUNT,
      )
      if (response.error === null) {
        dispatch({
          type: 'SET_OUTSTANDING_AMOUNT',
          payload: response.data.outstanding_amount,
        })
      }
    } catch (error) {
      dispatch({
        type: 'SET_OUTSTANDING_AMOUNT_ERROR',
        payload: String(error),
      })
    }
  }

export const fetchUnbilledDVCount =
  () => async (dispatch: Dispatch<UserActions>) => {
    try {
      const response: SBUnbilledDVCountResponse = await get(
        URL.SB_UNBILLED_DV_COUNT,
      )
      if (response.error === null) {
        dispatch({
          type: 'SET_UNBILLED_DV_COUNT',
          payload: response.data.count,
        })
      }
    } catch (error) {
      dispatch({
        type: 'SET_UNBILLED_DV_COUNT_ERROR',
        payload: String(error),
      })
    }
  }
