import React from 'react'
import { View, Image } from 'react-native'
import { useSelector } from 'react-redux'

import { ApplicationState } from 'src/redux/types'
import STRING from 'constants/strings'
import { formatterWithoutDecimals } from 'src/utilities/helpers'
import { HomeProps } from 'src/navigations/types'

// components
import TileButton from 'src/components/home/TileButton'
import SummaryTile from 'src/components/home/SummaryTile'

// styles
import globals from 'styles/global'
import styles from './style'

const SalesManager = ({ navigation }: HomeProps) => {
  const userStore = useSelector((state: ApplicationState) => state.userReducer)
  const { outstandingAmount } = userStore

  return (
    <View>
      <View style={globals.hMarginTwenty}>
        <SummaryTile
          amountType={STRING.HOME.OUTSTANDING_SALES}
          displayValue={formatterWithoutDecimals.format(outstandingAmount || 0)}
          containerStyle={[
            globals.hPaddingTwenty,
            styles.outstandingContainerStyle,
          ]}
          displayValueStyle={styles.displayValueStyle}
          backgroundImg={
            <Image
              source={require('images/cloud.png')}
              style={[globals.positionAbsolute, styles.cloudBackground]}
            />
          }
        />
      </View>
      <View
        style={[
          globals.directionRow,
          globals.justifyContentSpaceBetween,
          globals.vMarginEight,
          globals.hMarginTwenty,
        ]}
      >
        <TileButton
          icon={require('images/create-order.png')}
          iconStyle={styles.tileButtonIcon}
          title={STRING.HOME.CREATE_ORDER}
          onPress={() => navigation.navigate('CreateOrder')}
        />
        <TileButton
          icon={require('images/confirm-order.png')}
          iconStyle={styles.tileButtonIcon}
          title={STRING.HOME.CONFIRM_ORDER}
          onPress={() => navigation.navigate('Orders')}
        />
        <TileButton
          icon={require('images/order-history.png')}
          iconStyle={styles.tileButtonIcon}
          title={STRING.HOME.ORDER_HISTORY}
          onPress={() => navigation.navigate('OrderHistory')}
        />
      </View>
    </View>
  )
}

export default SalesManager
