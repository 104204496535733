import { Dispatch } from 'react'

import { get, URL } from 'src/utilities/axios'
import { GRNActions } from 'src/redux/types'
import { store } from 'src/redux/store'
import {
  GRNDetailsResponse,
  PendingGRNCountResponse,
  GRNListResponse,
} from 'src/modals'
import { showToastNotification } from 'src/utilities/helpers'
import { DOWNLOAD } from 'src/config/download'
import STRINGS from 'constants/strings'

export const fetchPendingGRNCount = () => {
  return async (dispatch: Dispatch<GRNActions>) => {
    try {
      const res: PendingGRNCountResponse = await get(URL.PENDING_GRN_COUNT)
      if (res.error === null) {
        dispatch({
          type: 'SET_GRN_COUNT',
          payload: res.data.count,
        })
      }
    } catch (error) {
      dispatch({
        type: 'SET_ERROR',
        payload: error,
      })
    }
  }
}

export const fetchPendingGRNList =
  (limit: number, offset: number, order: string) =>
  async (dispatch: Dispatch<GRNActions>) => {
    try {
      const res: GRNListResponse = await get(URL.PENDING_GRN_LIST, {
        limit,
        offset,
        order,
        sort_by: 'date',
      })
      if (res.error === null) {
        dispatch({
          type: 'APPEND_GRN_LIST',
          payload: [...store.getState().grnReducer.grnList, ...res.data.items],
        })
      }
    } catch (error) {
      dispatch({
        type: 'SET_ERROR',
        payload: error,
      })
    }
  }

export const resetGRNList = () => {
  return async (dispatch: Dispatch<GRNActions>) => {
    dispatch({
      type: 'RESET_GRN_LIST',
    })
  }
}

export const fetchGRNDetailsById =
  (id: number) => async (dispatch: Dispatch<GRNActions>) => {
    try {
      const res: GRNDetailsResponse = await get(`${URL.GRN_DETAILS}/${id}`)
      if (res.error === null) {
        const { data } = res
        dispatch({
          type: 'SET_GRN_DETAILS',
          payload: data,
        })
      }
    } catch (error) {
      dispatch({
        type: 'SET_ERROR',
        payload: String(error),
      })
    }
  }

export const fetchProcGRNList =
  (params: any) => async (dispatch: Dispatch<GRNActions>) => {
    try {
      const response: GRNListResponse = await get(URL.PROC_GRN_LIST, {
        ...params,
      })
      dispatch({
        type: 'APPEND_GRN_LIST',
        payload: response.data.items,
      })
      dispatch({
        type: 'SET_GRN_COUNT',
        payload: response.data.total_count,
      })
    } catch (error) {
      showToastNotification(
        DOWNLOAD.ERROR,
        `${STRINGS.MISCELLANEOUS.WENT_WRONG} ${STRINGS.MISCELLANEOUS.TRY_AGAIN}`,
      )
    }
  }

export const fetchProcGRNDetailsById =
  (grn_id: number) => async (dispatch: Dispatch<GRNActions>) => {
    try {
      const res: GRNDetailsResponse = await get(URL.PROC_GRN_DETAILS, {
        grn_id,
      })
      const { data } = res
      dispatch({
        type: 'SET_GRN_DETAILS',
        payload: data,
      })
    } catch (error) {
      showToastNotification(
        DOWNLOAD.ERROR,
        `${STRINGS.MISCELLANEOUS.WENT_WRONG} ${STRINGS.MISCELLANEOUS.TRY_AGAIN}`,
      )
      dispatch({
        type: 'SET_ERROR',
        payload: String(error),
      })
    }
  }
