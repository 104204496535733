import { Box, NativeBaseProvider } from 'native-base'
import React from 'react'
import { View, ScrollView } from 'react-native'
import tw from 'tailwind-react-native-classnames'

import STRINGS from 'constants/strings'
import { getEndTruncatedString, utcToLocalTime } from 'src/utilities/helpers'
import { voucherDateFormat } from 'src/config/voucher'
// eslint-disable-next-line import/order
import { ProcDetailsProps } from '../PrimaryDetails'

// components
import VoucherMetadata from 'src/components/voucher/VoucherMetadata'
import Attribute from 'src/components/voucher/VoucherAttribute'

// styles
import global from 'styles/global'
import styles from './style'

const MoreDetails = ({ route }: ProcDetailsProps) => {
  const { grnDetailsByPO } = route

  return (
    <NativeBaseProvider>
      <Box flex={1}>
        <View style={tw`bg-white flex-1`}>
          <ScrollView>
            <View style={global.bgLightBlue}>
              <VoucherMetadata
                id={Number(grnDetailsByPO?.po_name)}
                attributes={[
                  {
                    attrName: STRINGS.PURCHASE_ORDER.PO_ID_WITHOUT_COLON,
                    attrValue: grnDetailsByPO?.po_name,
                    icon: require('images/id-dark-blue.png'),
                    iconStyle: tw`w-7 h-7`,
                    attrContainerStyle: tw`text-xs w-1/2`,
                    attrStyle: tw`text-xs`,
                    attrValueStyle: [global.textBold, tw`text-sm`],
                  },
                  {
                    attrName: STRINGS.PURCHASE_ORDER.QC_RESPONSIBILITY,
                    attrValue: grnDetailsByPO?.more_details?.qc_by || 'N/A',
                    icon: require('images/shield.png'),
                    iconStyle: tw`w-7 h-7`,
                    attrContainerStyle: tw`w-1/2`,
                    attrStyle: tw`text-xs`,
                    attrValueStyle: [global.textBold, tw`text-sm`],
                  },
                  {
                    attrName: STRINGS.PURCHASE_ORDER.DEHAAT_WAREHOUSE_LOCATION,
                    attrValue:
                      (grnDetailsByPO?.more_details?.delivery_warehouse
                        ?.length &&
                        grnDetailsByPO?.more_details?.delivery_warehouse[1]) ||
                      'N/A',
                    icon: require('images/location.png'),
                    iconStyle: tw`w-7 h-7`,
                    attrContainerStyle: tw` w-full pt-5`,
                    attrStyle: tw`text-xs`,
                    attrValueStyle: [global.textBold, tw`text-sm`],
                  },
                ]}
                containerStyle={tw`p-5 flex flex-wrap`}
                lineSeparator={false}
              />
            </View>
            <View style={tw`px-4 flex-row flex-wrap`}>
              <Attribute
                attrName={STRINGS.PURCHASE_ORDER.NODE}
                attrStyle={tw`text-xs`}
                attrValue={
                  grnDetailsByPO?.more_details?.node?.length &&
                  grnDetailsByPO?.more_details?.node[1]
                }
                attrValueStyle={[
                  global.textMedium,
                  global.textPrimaryBlack,
                  tw`text-sm pt-0.5`,
                ]}
                styles={[
                  styles.marginBottomThirty,
                  styles.marginTopThirtyEight,
                  tw`w-1/2`,
                ]}
              />
              <Attribute
                attrName={STRINGS.PURCHASE_ORDER.PAYMENT_TERMS}
                attrStyle={tw`text-xs`}
                attrValue={
                  (grnDetailsByPO?.more_details?.payment_terms?.length &&
                    grnDetailsByPO?.more_details?.payment_terms[1]) ||
                  'N/A'
                }
                attrValueStyle={[
                  global.textMedium,
                  global.textPrimaryBlack,
                  tw`text-sm pt-0.5`,
                ]}
                styles={[
                  styles.marginBottomThirty,
                  styles.marginTopThirtyEight,
                  tw`w-1/2`,
                ]}
              />
              <Attribute
                attrName={STRINGS.PURCHASE_ORDER.VENDOR_NAME}
                attrStyle={tw`text-xs`}
                attrValue={
                  grnDetailsByPO?.more_details?.vendor_name?.length &&
                  grnDetailsByPO?.more_details?.vendor_name[1]
                }
                attrValueStyle={[
                  global.textMedium,
                  global.textPrimaryBlack,
                  tw`text-sm pt-0.5`,
                ]}
                styles={[tw`w-1/2`, styles.marginBottomThirty]}
              />
              <Attribute
                attrName={STRINGS.PURCHASE_ORDER.PICKUP_LOCATION}
                attrStyle={tw`text-xs flex-shrink`}
                attrValue={
                  (grnDetailsByPO?.more_details?.pickup_location?.length &&
                    grnDetailsByPO?.more_details?.pickup_location[1]) ||
                  'N/A'
                }
                attrValueStyle={[
                  global.textMedium,
                  global.textPrimaryBlack,
                  tw`flex-shrink text-sm pt-0.5`,
                ]}
                styles={[tw`w-full flex-shrink`, styles.marginBottomThirty]}
              />
              <Attribute
                attrName={STRINGS.PURCHASE_ORDER.CUSTOMER_DELIVERY_LOCATION}
                attrStyle={tw`text-xs flex-shrink`}
                attrValue={
                  (grnDetailsByPO?.more_details?.customer_delivery_location
                    ?.length &&
                    grnDetailsByPO?.more_details
                      ?.customer_delivery_location[1]) ||
                  'N/A'
                }
                attrValueStyle={[
                  global.textMedium,
                  global.textPrimaryBlack,
                  tw`flex-shrink text-sm pt-0.5`,
                ]}
                styles={[tw`w-full flex-shrink`, styles.marginBottomThirty]}
              />
              <Attribute
                attrName={STRINGS.PURCHASE_ORDER.PICKUP_DATE}
                attrStyle={tw`text-xs`}
                attrValue={
                  grnDetailsByPO?.more_details?.pickup_date
                    ? utcToLocalTime(
                        grnDetailsByPO?.more_details?.pickup_date,
                        voucherDateFormat,
                      )
                    : STRINGS.MISCELLANEOUS.DATE_NOTE_AVAILABLE
                }
                attrValueStyle={[
                  global.textMedium,
                  global.textPrimaryBlack,
                  tw`text-sm pt-0.5`,
                ]}
                styles={[tw`w-1/2`, styles.marginBottomThirty]}
              />
              <Attribute
                attrName={STRINGS.PURCHASE_ORDER.DELIVERY_DATE}
                attrStyle={tw`text-xs`}
                attrValue={
                  grnDetailsByPO?.more_details?.delivery_date
                    ? utcToLocalTime(
                        grnDetailsByPO?.more_details?.delivery_date,
                        voucherDateFormat,
                      )
                    : getEndTruncatedString(
                        STRINGS.MISCELLANEOUS.DATE_NOTE_AVAILABLE,
                      )
                }
                attrValueStyle={[
                  global.textMedium,
                  global.textPrimaryBlack,
                  tw`text-sm pt-0.5`,
                ]}
                styles={[tw`w-1/2`, styles.marginBottomThirty]}
              />
            </View>
          </ScrollView>
        </View>
      </Box>
    </NativeBaseProvider>
  )
}

export default MoreDetails
