import { Dispatch } from 'react'
import {
  ProcsPO,
  PurchaseOrder,
  PurchaseOrderListResponse,
  SortingOrder,
  PODetailsResponse,
  GRNDetailsByPOResponse,
  DraftPODetails,
} from 'src/modals/purchase-order'
import { get, URL } from 'src/utilities/axios'
import { PurchaseOrderActions } from '../types'

const fetchPurchaseOrders =
  (listFetchLimit: number, listOffset: number, sortingOrder: SortingOrder) =>
  async (dispatch: Dispatch<PurchaseOrderActions>) => {
    try {
      dispatch({
        type: 'TOGGLE_PO_FLAG',
        payload: 'isPOListLoading',
      })
      const response: PurchaseOrderListResponse = await get(
        URL.PURCHASE_ORDER_LIST,
        {
          limit: listFetchLimit,
          offset: listOffset,
          order: sortingOrder,
          sort_by: 'date_order',
        },
      )
      if (response.error === null) {
        dispatch({
          type: 'APPEND_PURCHASE_ORDER_LIST',
          payload: response.data.orders as PurchaseOrder[],
        })
        dispatch({
          type: 'SET_PURCHASE_ORDER_TOTAL_COUNT',
          payload: response.data.count as number,
        })
      }
    } catch (error) {
      dispatch({
        type: 'SET_ERROR',
        payload: String(error),
      })
    } finally {
      dispatch({
        type: 'TOGGLE_PO_FLAG',
        payload: 'isPOListLoading',
      })
    }
  }

const fetchProcsPO =
  (params: any) => async (dispatch: Dispatch<PurchaseOrderActions>) => {
    try {
      dispatch({
        type: 'TOGGLE_PO_FLAG',
        payload: 'isPOListLoading',
      })
      const response: PurchaseOrderListResponse = await get(URL.PROCS_PO_LIST, {
        state: 'purchase',
        ...params,
      })
      if (response.error === null) {
        dispatch({
          type: 'APPEND_PURCHASE_ORDER_LIST',
          payload: response.data.items as ProcsPO[],
        })
        dispatch({
          type: 'SET_PURCHASE_ORDER_TOTAL_COUNT',
          payload: response.data.total_count as number,
        })
      }
    } catch (error) {
      dispatch({
        type: 'SET_ERROR',
        payload: String(error),
      })
    } finally {
      dispatch({
        type: 'TOGGLE_PO_FLAG',
        payload: 'isPOListLoading',
      })
    }
  }

const resetPurchaseOrderList = () => ({
  type: 'RESET_PURCHASE_ORDER_LIST',
  payload: null,
})

const fetchPODetailsById =
  (id: number) => async (dispatch: Dispatch<PurchaseOrderActions>) => {
    try {
      const res: PODetailsResponse = await get(`${URL.PO_DETAILS}/${id}`)
      if (res.error === null) {
        const { data } = res
        dispatch({
          type: 'SET_PO_DETAILS',
          payload: data[0],
        })
      }
    } catch (error) {
      dispatch({
        type: 'SET_ERROR',
        payload: String(error),
      })
    }
  }

const fetchGRNDetailsByPOId =
  (id: number) => async (dispatch: Dispatch<PurchaseOrderActions>) => {
    try {
      const res: GRNDetailsByPOResponse = await get(
        URL.PROC_VENDOR_GRN_DETAILS_BY_ID(id),
      )
      if (res.error === null) {
        const { data } = res
        dispatch({
          type: 'SET_GRN_DETAILS_BY_PO',
          payload: data,
        })
      }
    } catch (error) {
      dispatch({
        type: 'SET_ERROR',
        payload: String(error),
      })
    }
  }

const resetVendorBillFields = () => ({
  type: 'RESET_VENDOR_BILL_FIELDS',
})

const setDraftPODetails = (data: DraftPODetails) => ({
  type: 'SET_DRAFT_PO_DETAILS',
  payload: data,
})

const toggleBackDraftPOModal = () => ({
  type: 'TOGGLE_BACK_DRAFT_PO_MODAL',
})

export {
  fetchPurchaseOrders,
  resetPurchaseOrderList,
  fetchProcsPO,
  fetchPODetailsById,
  fetchGRNDetailsByPOId,
  resetVendorBillFields,
  setDraftPODetails,
  toggleBackDraftPOModal,
}
