import { StyleSheet } from 'react-native'
import colors from 'styles/colors'

const styles = StyleSheet.create({
  tabIndicator: {
    width: '14%',
    height: 4,
    borderRadius: 2,
    left: '18%',
    backgroundColor: colors.bgLightGreen,
  },
  iconButtonText: {
    marginLeft: 6,
  },
  iconButton: {
    height: 48,
  },
})

export default styles
