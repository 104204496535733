import { PushNotificationActions } from 'src/redux/types'

type PushNotificationState = {
  schedulePushNotification: Function
}

const initialState = {
  schedulePushNotification: () => {},
}

const PushNotificationReducer = (
  state: PushNotificationState = initialState,
  action: PushNotificationActions,
) => {
  switch (action.type) {
    case 'SET_PUSH_NOTIFICATION': {
      return {
        ...state,
        schedulePushNotification: action.payload,
      }
    }
    default:
      return state
  }
}

export { PushNotificationReducer }
