import React, { useState } from 'react'
import { View, Text, TextInput } from 'react-native'
import tw from 'tailwind-react-native-classnames'

import STRINGS from 'constants/strings'
import { getEndTruncatedString } from 'src/utilities/helpers'
import {
  QCParameterListItemProps,
  QCParameterListProps,
} from 'types/procurement'

// components
import Label from 'src/components/card/Label'

// styles
import global from 'styles/global'
import style from './style'

export const QCParameterListItem = ({
  qualityMeasureValue,
  qualityMeasureKey,
  qcResponsibility,
  qcParamTextInputs,
  onChangeQCParam,
}: QCParameterListItemProps) => {
  const [isValueEnteredOnce, setIsValueEnteredOnce] = useState<Boolean>(false)
  const demandedValue = qualityMeasureValue
    .replace(/[!a-zA-Z%]/g, '')
    .split('-')

  const handleOnChangePercentage = (value: string) => {
    if (!isValueEnteredOnce) setIsValueEnteredOnce(true)
    if (onChangeQCParam) onChangeQCParam(value, qualityMeasureKey)
  }

  const isQCActualMismatch = () => {
    if (!isValueEnteredOnce) return false
    const currentActualValue = qcParamTextInputs[qualityMeasureKey]
    const result = !(
      Number(currentActualValue) >= Number(demandedValue[0]) &&
      Number(currentActualValue) <= Number(demandedValue[1])
    )
    return result
  }

  const getErrorMessage = () => {
    if (isQCActualMismatch()) {
      return (
        <View>
          <Label
            text={STRINGS.PURCHASE_ORDER.VALUE_MISMATCH}
            image={require('images/warning-solid.png')}
            containerStyle={tw`pt-1 pl-0`}
            textStyle={[
              global.textRed,
              global.textRegular,
              global.fontTen,
              style.marginLeftFive,
              style.lineHeightTwelve,
            ]}
            imageStyle={tw`w-3 h-2.5`}
          />
        </View>
      )
    }
    return null
  }

  return (
    <>
      <View
        style={[
          tw`flex-col`,
          isQCActualMismatch()
            ? style.paddingBottomFifteen
            : style.paddingBottomThirty,
        ]}
      >
        <View
          style={[
            tw`flex-row bg-white`,
            qcResponsibility === 'supplier' && tw`justify-between`,
          ]}
        >
          <View style={tw`w-1/2 justify-center`}>
            <Text
              style={[
                global.textPrimaryBlack,
                global.textMedium,
                tw`font-medium text-xs`,
              ]}
            >
              {getEndTruncatedString(qualityMeasureKey)}
            </Text>
          </View>
          <View style={[tw`justify-center`, style.widthThirtyOnePercent]}>
            <Text
              style={[
                global.textPrimaryBlack,
                global.textMedium,
                tw`font-medium text-xs`,
              ]}
            >
              {qualityMeasureValue}
            </Text>
          </View>
          {qcResponsibility === 'dehaat' && (
            <View style={[tw`items-end`, style.widthNinteenPercent]}>
              <TextInput
                style={[
                  global.textMedium,
                  tw`rounded-sm border px-2.5 text-xs`,
                  style.priceInput,
                ]}
                value={String(qcParamTextInputs[qualityMeasureKey])}
                keyboardType="numeric"
                placeholder="00"
                onChangeText={handleOnChangePercentage}
              />
            </View>
          )}
        </View>
        <View>{getErrorMessage()}</View>
      </View>
    </>
  )
}

const QCParameterList = ({
  qualityRequest,
  qcResponsibility,
  qcParamTextInputs,
  onChangeQCParam,
}: QCParameterListProps) => {
  return (
    <View style={tw`bg-white`}>
      <View style={tw`flex-row justify-between pt-5 pb-2.5`}>
        <Text style={[tw`w-1/2 text-xs`, global.textPink, global.textRegular]}>
          {STRINGS.PURCHASE_ORDER.QC_PARAMETER}
        </Text>
        <Text
          style={[
            tw`text-xs`,
            style.widthThirtyOnePercent,
            global.textPink,
            global.textRegular,
          ]}
        >
          {STRINGS.PURCHASE_ORDER.VALUE}
        </Text>
        {qcResponsibility === 'dehaat' && (
          <View style={[tw`items-end`, style.widthNinteenPercent]}>
            <Text
              style={[
                tw`text-xs`,
                global.textPink,
                global.textRegular,
                style.widthSeventy,
              ]}
            >
              {STRINGS.PURCHASE_ORDER.ACTUAL}
            </Text>
          </View>
        )}
      </View>
      {qualityRequest &&
        Object.keys(qualityRequest).map((qualityMeasureType: string, index) => {
          return (
            <QCParameterListItem
              // TODO: Need to provide reason for why index key or else fix it
              // eslint-disable-next-line react/no-array-index-key
              key={`${qualityMeasureType}${index}`}
              qualityMeasureValue={qualityRequest[qualityMeasureType]}
              qualityMeasureKey={qualityMeasureType}
              qcResponsibility={qcResponsibility}
              {...{ qcParamTextInputs, onChangeQCParam }}
            />
          )
        })}
    </View>
  )
}
export default QCParameterList
