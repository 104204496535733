import { StyleSheet } from 'react-native'

const style = StyleSheet.create({
  orderStatusIcon: {
    width: 20,
    height: 20,
  },
})

export default style
