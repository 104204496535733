import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  homeBtn: {
    height: 50,
  },
  idField: {
    marginBottom: 5,
  },
  iconButtonMain: {
    height: 48,
  },
  iconButton: {
    marginBottom: 20,
  },
})

export default styles
