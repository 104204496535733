import { StyleSheet, Dimensions, Platform } from 'react-native'

import colors from 'styles/colors'

const windowWidth = Dimensions.get('window').width

const style = StyleSheet.create({
  itemLine: {
    borderTopColor: colors.bgLightBlue,
    borderTopWidth: 1,
  },
  itemThumbnail: {
    height: 50,
    width: 50,
  },
  itemDetails: {
    marginLeft: 8,
    width: windowWidth - 106,
  },
  uomBackground: {
    backgroundColor: colors.lightSecondaryBlue,
    paddingVertical: 6,
  },
  priceBox: {
    borderWidth: 1,
    borderColor: colors.primaryTextBlack50,
  },
  priceInput: {
    width: 28,
    height: 16,
    borderBottomWidth: 1,
    borderBottomColor: colors.primaryTextBlack,
    ...Platform.select({
      ios: {
        marginTop: 0,
      },
      android: {
        marginTop: 0,
      },
      default: {
        marginTop: 0,
      },
    }),
  },
})

export default style
