// TODO: Add default propTypes for KeycloackProvider
/* eslint-disable react/prop-types */
import React, { useCallback, useEffect } from 'react'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { Platform } from 'react-native'
import * as AuthSession from 'expo-auth-session'
import { useAuthRequest, useAutoDiscovery } from 'expo-auth-session'
import * as WebBrowser from 'expo-web-browser'

import { KeycloakContext } from './KeycloakContext'
import useTokenStorage from './useTokenStorage'
import { handleTokenExchange, getRealmURL } from './helpers'
import { NATIVE_REDIRECT_PATH } from './const'

WebBrowser.maybeCompleteAuthSession()

export const KeycloakProvider = ({
  realm,
  clientId,
  url,
  extraParams = {},
  children,
  ...options
}) => {
  const discovery = useAutoDiscovery(getRealmURL({ realm, url }))
  const redirectUri = AuthSession.makeRedirectUri({
    native: `${options.scheme ?? 'exp'}://${
      options.nativeRedirectPath ?? NATIVE_REDIRECT_PATH
    }`,
    useProxy: !options.scheme,
  })

  const config = { redirectUri, clientId, realm, url, extraParams }

  const [request, response, promptAsync] = useAuthRequest(
    { usePKCE: false, ...config },
    discovery,
  )
  const [currentToken, updateToken] = useTokenStorage(
    options,
    config,
    discovery,
  )

  const handleLogin = useCallback(() => {
    return promptAsync()
    // TODO: Provide an explanation for why all deps are not included or fix it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request])

  const handleLogout = async () => {
    const token = await AsyncStorage.getItem('accessToken')
    if (!token) throw new Error('Not logged in.')
    const refreshToken = await AsyncStorage.getItem('refreshToken')
    try {
      if (discovery.revocationEndpoint) {
        AuthSession.revokeAsync({ token, ...config }, discovery)
      }
      if (discovery.endSessionEndpoint) {
        fetch(`${discovery.endSessionEndpoint}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: `client_id=${clientId}&refresh_token=${refreshToken}`,
        })
      }
      if (Platform.OS === 'ios') {
        AuthSession.dismiss()
      }
    } catch (error) {
      console.error(error)
    }
    updateToken(null)
    await AsyncStorage.removeItem('accessToken', async () => {
      await AsyncStorage.removeItem('refreshToken')
    })
    // TODO: Are we sure about using an undefined function here
    // eslint-disable-next-line no-undef
    clearInterval(handleInterval.get())
  }

  useEffect(() => {
    if (response) {
      handleTokenExchange({ response, discovery, config }).then(updateToken)
    }
    // TODO: Provide an explanation for why all deps are not included or fix it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response])

  return (
    // TODO: Do we need to change the extensiont to jsx
    // eslint-disable-next-line react/jsx-filename-extension
    <KeycloakContext.Provider
      value={{
        isLoggedIn: currentToken === undefined ? undefined : !!currentToken,
        login: handleLogin,
        logout: handleLogout,
        ready:
          discovery !== null && request !== null && currentToken !== undefined,
        token: currentToken,
      }}
    >
      {children}
    </KeycloakContext.Provider>
  )
}
