import { StyleSheet } from 'react-native'
import colors from 'styles/colors'

const styles = StyleSheet.create({
  arrowBtn: {
    width: 22,
    height: 23,
  },
  bottomPaddingZero: {
    paddingBottom: 0,
  },
  addBtn: {
    textDecorationLine: 'underline',
    color: colors.secondaryOrange,
    padding: 3,
  },
  footerBtnContainer: {
    height: 45,
    minHeight: 50,
  },
  btnDone: {
    paddingHorizontal: 40,
  },
  footer: {
    paddingVertical: 10,
  },
  warning: {
    width: 50,
    height: 44,
    marginTop: 10,
  },
  warningMsg: {
    paddingTop: 14,
  },
  btnContainer: {
    paddingTop: 30,
  },
  productCard: {
    marginBottom: 0,
    paddingBottom: 0,
  },
  iconCancel: {
    width: 10,
    height: 10,
  },
})

export default styles
