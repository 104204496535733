import React, { ReactElement, useState } from 'react'
import {
  View,
  Text,
  Image,
  Pressable,
  LayoutEvent,
  Dimensions,
  Platform,
  StatusBar,
  Modal,
} from 'react-native'
import tw from 'tailwind-react-native-classnames'

import { useKeyboard } from 'src/utilities/hooks/useKeyboard'

// style
import global from 'styles/global'

interface CardInfoProps {
  header: string
  closeDrawer: Function
  body?: ReactElement
  headerStyle?: Object
  bodyStyle?: Object
  footer?: ReactElement
  containerStyle?: Object
}

const CardInfo = (props: CardInfoProps) => {
  const {
    header, // header of drawer
    closeDrawer, // Function on close drawer
    body,
    headerStyle,
    bodyStyle,
    footer,
    containerStyle,
  } = props

  const [drawerHeight, setDrawerHeight] = useState<number>(0)
  const keyboardHeight = useKeyboard()

  const onLayout = (event: LayoutEvent) => {
    const { height } = event.nativeEvent.layout
    const windowHeight = Dimensions.get('window').height
    const statusBarHeight = StatusBar.currentHeight as number
    const topHeaderMargin = 21

    const topRemovableHeight =
      Platform.OS !== 'web'
        ? statusBarHeight + topHeaderMargin
        : topHeaderMargin

    if (height > windowHeight - topRemovableHeight) {
      setDrawerHeight(windowHeight - topRemovableHeight)
    }
  }

  return (
    <Modal
      animationType="none"
      visible
      transparent
      onRequestClose={() => closeDrawer()}
    >
      <View
        style={[
          global.drawer,
          {
            height: drawerHeight === 0 ? 'auto' : drawerHeight - keyboardHeight,
          },
          containerStyle,
        ]}
        onLayout={(event: LayoutEvent) => onLayout(event)}
      >
        <View style={footer && tw`flex-1`}>
          <View style={tw`justify-between flex-row m-5`}>
            <Text
              style={[global.textColorGrey, tw`font-bold text-sm`, headerStyle]}
            >
              {header}
            </Text>
            <Pressable onPress={() => closeDrawer()} testID="closeDrawer">
              <Image
                source={require('images/cancel.png')}
                style={tw`w-2.5 h-2.5`}
              />
            </Pressable>
          </View>
          <View style={[tw`flex-row mx-5 h-9 mt-8`, bodyStyle]}>{body}</View>
        </View>
        <View>{footer || <></>}</View>
      </View>
    </Modal>
  )
}

export default CardInfo
