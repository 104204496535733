import { StyleSheet } from 'react-native'
import colors from 'styles/colors'

const styles = StyleSheet.create({
  tabIndicator: {
    width: '14%',
    left: '18%',
    backgroundColor: colors.bgLightGreen,
  },
  calendarIcon: {
    width: 26,
    height: 26,
  },
})

export default styles
