import React, { useState } from 'react'
import { Animated, useWindowDimensions, View, Image } from 'react-native'
import { NativeBaseProvider } from 'native-base'
import { TabView, TabBar } from 'react-native-tab-view'
import tw from 'tailwind-react-native-classnames'

import STRINGS from 'constants/strings'
import { AddNewVendorProps } from 'src/navigations/types'
import { AddNewVendorTabProps, TabPressProps } from 'types/procurement'

// components
import ToastNotification from 'src/components/ToastNotification'
import VendorTab from 'src/screens/procurement-manager/AddVendor/tabs/VendorTab'
import DocsTab from 'src/screens/procurement-manager/AddVendor/tabs/DocsTab'
import AddressTab from 'src/screens/procurement-manager/AddVendor/tabs/AddressTab'
import BankTab from 'src/screens/procurement-manager/AddVendor/tabs/BankTab'

// styles
import global from 'styles/global'
import styles from './styles'

const { ADD_VENDOR } = STRINGS

interface RenderLabelRoute {
  route: {
    index: number
    title: string
  }
  focused: boolean
}

const renderScene = (props: { route: AddNewVendorTabProps }) => {
  switch (props.route.key) {
    case 'vendor':
      return <VendorTab {...props?.route} />
    case 'docs':
      return <DocsTab {...props?.route} />
    case 'address':
      return <AddressTab {...props?.route} />
    case 'bank':
      return <BankTab {...props?.route} />
    default:
      return <></>
  }
}

const AddNewVendor = ({ navigation, route }: AddNewVendorProps) => {
  const layout = useWindowDimensions()
  const [index, setIndex] = useState<number>(route?.params?.activeTabIndex || 0)
  const [tabBackStack, setTabBackStack] = useState<Array<number>>([index])

  const pushOnBackStack = (tabIndex: number) => {
    setTabBackStack([...tabBackStack, tabIndex])
    setIndex(tabIndex)
  }

  const fromPreview = route?.params?.activeTabIndex !== undefined
  const routes = [
    {
      key: 'vendor',
      title: ADD_VENDOR.VENDOR_TAB_TITLE,
      testID: 'vendor-tab',
      index: 0,
      goToNextTab: () => pushOnBackStack(1),
      fromPreview,
      navigation,
    },
    {
      key: 'docs',
      title: ADD_VENDOR.DOCS_TAB_TITLE,
      testID: 'docs-tab',
      index: 1,
      goToNextTab: () => pushOnBackStack(2),
      fromPreview,
      navigation,
    },
    {
      key: 'address',
      title: ADD_VENDOR.ADDRESS_TAB_TITLE,
      testID: 'address-tab',
      index: 2,
      goToNextTab: () => pushOnBackStack(3),
      fromPreview,
      navigation,
    },
    {
      key: 'bank',
      title: ADD_VENDOR.BANK_TAB_TITLE,
      testID: 'bank-tab',
      index: 3,
      goToNextTab: () => undefined,
      fromPreview,
      navigation,
    },
  ]

  const renderTabBar = (props: any) => {
    const isEdited = route?.params?.isEdited

    return (
      <TabBar
        {...props}
        style={tw`bg-white`}
        renderLabel={({ route: tabRoute, focused }: RenderLabelRoute) => {
          return (
            <View style={tw`flex-row`}>
              {tabRoute.index === tabBackStack.slice(-1)[0] && !isEdited && (
                <Image
                  source={require('images/unlock.png')}
                  style={tw`w-3.5 h-3.5`}
                />
              )}
              {(tabRoute.index !== tabBackStack.slice(-1)[0] || isEdited) &&
                (tabBackStack.includes(tabRoute.index) ? (
                  <Image
                    source={require('images/tick.png')}
                    style={tw`w-3.5 h-3.5`}
                  />
                ) : (
                  <Image
                    source={require('images/lock.png')}
                    style={tw`w-3.5 h-3.5`}
                  />
                ))}
              <Animated.Text
                style={
                  focused || tabBackStack.includes(tabRoute.index)
                    ? [global.textPrimaryBlack, tw`text-sm leading-4 ml-1`]
                    : [global.textSecondaryGrey, tw`text-sm leading-4 ml-1`]
                }
              >
                {tabRoute.title}
              </Animated.Text>
            </View>
          )
        }}
        indicatorStyle={styles.tabIndicator}
        onTabPress={(tabPressEvent: TabPressProps) => {
          if (tabBackStack.indexOf(tabPressEvent.route.index) < 0)
            tabPressEvent.preventDefault()
        }}
      />
    )
  }

  return (
    <NativeBaseProvider>
      <ToastNotification />
      <TabView
        navigationState={{ index, routes }}
        renderScene={renderScene}
        onIndexChange={setIndex}
        renderTabBar={renderTabBar}
        initialLayout={{
          width: layout.width,
        }}
        swipeEnabled={false}
      />
    </NativeBaseProvider>
  )
}

export default AddNewVendor
