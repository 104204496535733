import { StyleSheet } from 'react-native'
import colors from 'styles/colors'

const styles = StyleSheet.create({
  fileIcon: {
    height: 32,
    width: 32,
  },
  paddingTopFour: {
    paddingTop: 4,
  },
  imageIcon: {
    height: 16,
    width: 16,
  },
  iconButtonMain: {
    height: 48,
    paddingHorizontal: 44,
  },
  paddingTopForty: {
    paddingTop: 40,
  },
  warning: {
    width: 50,
    height: 44,
  },
  paddingBottomForty: {
    paddingBottom: 40,
  },
  btnContainer: {
    borderTopWidth: 1,
    borderTopColor: colors.lightGrey,
  },
  btnYesCover: {
    borderWidth: 0,
    borderColor: colors.lightGrey,
    borderBottomLeftRadius: 10,
  },
  btnNoCover: {
    borderWidth: 0,
    borderLeftWidth: 1,
    borderColor: colors.lightGrey,
    borderBottomRightRadius: 10,
  },
  camera: {
    flex: 1,
  },
})

export default styles
