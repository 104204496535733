import { Order } from 'src/modals'
import { OrderActions } from 'src/redux/types'

type OrdersState = {
  list: Order[]
  count: number
  error: string | null
}

const initialState = {
  list: [] as Order[],
  count: 0,
  error: null,
}

const OrdersReducer = (
  state: OrdersState = initialState,
  action: OrderActions,
) => {
  switch (action.type) {
    case 'SET_ORDER_LIST': {
      return {
        ...state,
        list: action.payload,
      }
    }
    case 'SET_ORDER_COUNT': {
      return {
        ...state,
        count: action.payload,
      }
    }
    case 'SET_ORDER_ERROR': {
      return {
        ...state,
        error: action.payload,
      }
    }
    case 'RESET_ORDER_LIST': {
      return {
        ...state,
        list: initialState.list,
      }
    }
    case 'RESET_ORDER_COUNT': {
      return {
        ...state,
        count: initialState.count,
      }
    }
    default:
      return state
  }
}

export { OrdersReducer }
