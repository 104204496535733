import React, { ReactElement, useState } from 'react'
import { View, Text, Image, Pressable, ActivityIndicator } from 'react-native'
import { NativeBaseProvider, Box, HStack } from 'native-base'
import ReadMore from 'react-native-read-more-text'
import { useSelector } from 'react-redux'
import tw from 'tailwind-react-native-classnames'
import { AxiosError, AxiosResponse } from 'axios'

// components
import Button from 'src/components/form-fields/Button'
import Modal from 'src/components/Modal'
import ToastNotification from 'src/components/ToastNotification'

// utilities
import {
  formatterWithoutDecimals,
  showToastNotification,
} from 'src/utilities/helpers'
import { post, URL } from 'src/utilities/axios'
import useDownloadFrmS3 from 'src/utilities/hooks/useDownloadFrmS3'

import { ProcRescheduleProps } from 'src/navigations/types'
import { ApplicationState } from 'src/redux/types'
import { DOWNLOAD } from 'src/config/download'

import STRINGS from 'constants/strings'

// styles
import global from 'styles/global'
import colors from 'styles/colors'
import ItemCard from '../../common/ItemCard'

const ProcReschedule = ({ navigation, route }: ProcRescheduleProps) => {
  const { id } = route.params
  const { moveId, productId, quantityDone, qcParams, grnDetails } = useSelector(
    (state: ApplicationState) => state.grnReducer,
  )
  const product = grnDetails.items !== undefined ? grnDetails?.items[0] : null
  const { downloadDocFrmS3 } = useDownloadFrmS3()

  const [drawer, setDrawer] = useState<string>('')
  const [modalBody, setModalBody] = useState<ReactElement>(<></>)
  const [showModal, setShowModal] = useState<Boolean>(false)
  const [modalFooter, setModalFooter] = useState<ReactElement>(<></>)
  const [showLoader, setShowLoader] = useState<boolean>(false)

  const renderTruncatedFooter = (handlePress: any) => {
    return (
      <Text
        style={[global.textPrimaryGreen, tw`text-xs mb-2.5`]}
        onPress={handlePress}
      >
        {STRINGS.BUTTON.READ_MORE}
      </Text>
    )
  }

  const renderRevealedFooter = (handlePress: any) => {
    return (
      <Text
        style={[global.textPrimaryGreen, tw`text-xs mb-2.5`]}
        onPress={handlePress}
      >
        {STRINGS.BUTTON.READ_LESS}
      </Text>
    )
  }

  const getSuccessScreenProps = (
    lotNumber: number,
    storeFname: string,
    successId: string,
    reschedule?: boolean,
  ) => ({
    iconImg: (
      <Image
        source={require('images/success-icon.png')}
        style={tw`h-32 w-32`}
      />
    ),
    customMsg: (
      <Text
        style={[
          global.textRegular,
          global.textPrimaryBlack,
          tw`leading-6 text-sm p-5 text-center flex-row`,
        ]}
      >
        {STRINGS.INWARD_GRN.PRODUCTS_FROM}
        <Text style={tw`font-bold`}>
          {grnDetails.vendor_name ? grnDetails?.vendor_name[1] : 'N/A'}
        </Text>
        {reschedule
          ? STRINGS.INWARD_GRN.SUCCESS_MSG
          : STRINGS.INWARD_GRN.SUCCESS_MSG_FOR_CANCEL_RESCEDULE}
      </Text>
    ),
    greeting: true,
    idFields: [
      {
        title: `${
          reschedule
            ? STRINGS.MISCELLANEOUS.GRN_ID_WITH_HYPHEN
            : STRINGS.MISCELLANEOUS.PO_ID_WITH_HYPHEN
        }`,
        value: successId,
      },
      {
        title: STRINGS.MISCELLANEOUS.LOT_ID_WITH_HYPHEN,
        value: lotNumber,
      },
    ],
    footerButtons: [
      // commented code for Release v0.9.1
      // {
      //   title: STRINGS.BUTTON.CREATE_VENDOR_BILL_FOR_GRN,
      //   onClick: () => navigation.navigate('CreateVendorBill'),
      //   bgColor: global.bgPrimaryGrren.backgroundColor,
      //   style: tw`w-full`,
      // },
      {
        title: STRINGS.BUTTON.DOWNLOAD_GRN,
        onClick: () => {
          downloadDocFrmS3(
            storeFname,
            successId,
            STRINGS.MISCELLANEOUS.GRN_DOWNLOAD_MSG,
          )
        },
        buttonStyle: [
          tw`rounded-lg border bg-white`,
          global.borderColorPrimaryGreen,
        ],
        style: tw`w-full`,
        textStyle: [global.textPrimaryGreen],
      },
    ],
    homeButtonStyle: [
      tw`border-0 underline bg-transparent`,
      global.textDecorationColorPrimaryGreen,
    ],
    buttonContainerStyle: tw`flex-col`,
  })

  const rescheduleOrCancel = (reschedule?: boolean) => {
    setShowModal(false)
    setShowLoader(true)
    const payload = {
      data: [
        {
          move_id: moveId,
          product_id: productId,
          qty_done: quantityDone,
          qc_params: qcParams,
        },
      ],
      create_back_order: reschedule ? 'true' : 'false',
    }
    post(`${URL.INWARD_GRN}/${id}`, payload)
      .then((res: AxiosResponse) => {
        if (res?.data) {
          setShowModal(false)
          const {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            grn_id,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            po_id,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            store_fname,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            lot_data = { [moveId]: [] },
          } = res.data
          setShowLoader(false)
          navigation.navigate(
            'SuccessScreen',
            getSuccessScreenProps(
              lot_data[moveId][1],
              store_fname,
              reschedule ? grn_id : po_id,
              reschedule,
            ),
          )
        }
      })
      .catch((err: AxiosError) => {
        console.error(err)
        setShowLoader(false)
        setShowModal(false)
        showToastNotification(DOWNLOAD.ERROR, err?.response?.data.error)
      })
  }

  const createPopup = (reschedule?: boolean) => {
    setModalBody(
      <View style={tw`items-center mt-9`}>
        <Image style={tw`h-12 w-14`} source={require('images/warning.png')} />
        <View style={tw`w-full`}>
          <Text
            style={[
              global.textRegular,
              global.textColorGrey,
              tw`text-sm text-center leading-5 mt-6 mx-5 mt-7`,
            ]}
          >
            {STRINGS.GRNS.INWARD_GRN_WARNING(
              reschedule
                ? STRINGS.GRNS.RESCHEDULE
                : STRINGS.MISCELLANEOUS.CANCEL,
            )}
          </Text>
        </View>
      </View>,
    )
    setModalFooter(
      <View
        style={[
          global.borderColorLightGrey,
          tw`mt-8 flex-row justify-between border-t`,
        ]}
      >
        <Button
          title={STRINGS.BUTTON.NO}
          onTap={() => setShowModal(false)}
          btnType="secondary"
          style={[
            global.borderColorLightGrey,
            tw`w-1/2 border-0 border-r rounded-bl-lg`,
          ]}
          textStyle={[
            global.textRegular,
            global.textSecondaryOrange,
            tw`text-sm`,
          ]}
        />
        <Button
          title={
            reschedule ? STRINGS.BUTTON.RESCHEDULE : STRINGS.BUTTON.CANCEL_ORDER
          }
          onTap={() => rescheduleOrCancel(reschedule)}
          btnType="secondary"
          style={[
            global.borderColorLightGrey,
            tw`w-1/2 border-0 rounded-br-lg`,
          ]}
          textStyle={[global.textRegular, tw`text-sm`]}
        />
      </View>,
    )
    setShowModal(true)
  }

  const calculateTaxAmount = (amount: number) => {
    if (product?.tax_display_name === undefined) {
      return 0
    }
    // TODO: Can remove \ before @ and % symbols
    // eslint-disable-next-line no-useless-escape
    const regex: RegExp = /\@(.*?)\%/
    const regexResult = regex.exec(product?.tax_display_name)
    if (!regexResult) {
      return '0'
    }
    const taxPercentage = product?.tax_display_name ? regexResult[1] : 0
    return ((Number(amount) / 100) * Number(taxPercentage)).toFixed(2)
  }

  const calculateRemainingTotal = (withoutTax?: boolean) => {
    if (!product?.price_unit || !product?.quantity) {
      return 0
    }
    const totalWithoutTax =
      product?.price_unit * (product.quantity - Number(quantityDone))
    if (withoutTax) {
      return totalWithoutTax
    }
    return Number(
      `${totalWithoutTax + Number(calculateTaxAmount(totalWithoutTax))}`,
    )
  }

  return product ? (
    <NativeBaseProvider>
      <ToastNotification />
      <Box
        flex={1}
        safeAreaTop
        style={[
          tw`bg-white`,
          drawer === 'grn_details' && global.drawerBackground,
        ]}
      >
        <View style={[tw`flex-1`, global.zeroHeight]}>
          <View style={tw`px-5 pt-8`}>
            <Text
              style={[
                global.textPrimaryBlack,
                tw`text-base font-medium border-b pb-5`,
                global.borderColorSmokeGrey,
              ]}
            >
              {STRINGS.GRNS.PROC_RESCHEDULE_HEADER_MSG}
            </Text>
          </View>
          <ItemCard
            name={
              product?.product_name
                ? product?.product_name[1].toString()
                : 'N/A'
            }
            customUOM={
              product?.product_uom ? product?.product_uom[1].toString() : 'N/A'
            }
            tax={product?.tax_display_name || 'N/A'}
            imageS3Key={product.product_image}
            errorQuantityMismatch={false}
            detailColumns={[
              {
                key: STRINGS.GRNS.UNIT_PRICE,
                value: `₹${product?.price_unit}`,
                keyStyle: global.textColorGrey,
              },
              {
                key: STRINGS.GRNS.DEMAND,
                value: product?.quantity || 0,
                keyStyle: global.textColorGrey,
              },
            ]}
            editableField={{
              key: STRINGS.GRNS.RECEIVED,
              value: quantityDone,
              keyStyle: [tw`pr-5`, global.textColorGrey],
              valueContainerStyle: [tw`mt-2.5 justify-start`],
              valueStyle: global.textMedium,
            }}
            isTaxAmountVisible
            hideReceivedCurrency
            totalAmount={formatterWithoutDecimals.format(
              product?.price_total || 0,
            )}
            containerStyle={[
              tw`m-5 mb-0 mt-2.5 border-0 border-b`,
              global.borderColorSmokeGrey,
            ]}
            totalAmountKeyStyle={global.textColorGrey}
            taxValueStyle={global.textColorGrey}
            taxAmount={calculateTaxAmount(product?.price_total || 0).toString()}
          />
          <View style={tw`p-5`}>
            <View
              style={[
                tw`p-5 flex-row justify-between rounded`,
                global.bgLightGreen,
              ]}
            >
              <View>
                <Text
                  style={[
                    global.textColorGrey,
                    global.textRegular,
                    tw`text-xs`,
                  ]}
                >
                  {STRINGS.GRNS.PENDING_GRN_QUANTITY}
                </Text>
                <Text
                  style={[
                    global.textPrimaryBlack,
                    global.textMedium,
                    tw`text-sm pt-1`,
                  ]}
                >
                  {product?.quantity
                    ? `${product?.quantity - Number(quantityDone)}`
                    : '0'}{' '}
                  {product?.product_uom
                    ? product?.product_uom[1].toString()
                    : ''}
                </Text>
              </View>
              <Pressable onPress={() => setDrawer('grn_details')}>
                <Text
                  style={[
                    global.textPrimaryGreen,
                    global.textRegular,
                    tw`underline text-xs`,
                  ]}
                >
                  {STRINGS.MISCELLANEOUS.VIEW_DETAILS}
                </Text>
              </Pressable>
            </View>
          </View>
        </View>
        <HStack safeAreaBottom>
          <View
            style={[
              tw`p-5 w-full bg-white border-t`,
              global.borderColorDarkGrey,
            ]}
          >
            <View style={tw`mb-10`}>
              <ReadMore
                numberOfLines={1}
                renderTruncatedFooter={renderTruncatedFooter}
                renderRevealedFooter={renderRevealedFooter}
              >
                <Image
                  source={require('images/info-icon.png')}
                  style={tw`w-3 h-3`}
                />
                <Text style={[global.textSecondaryBlue, tw`text-xs`]}>
                  {`  ${STRINGS.GRNS.RESCHEDULE_READ_MORE}`}
                </Text>
              </ReadMore>
              <Button
                title={STRINGS.BUTTON.RESCHEDULE}
                onTap={() => createPopup(true)}
                style={tw`rounded-lg h-11`}
                textStyle={tw`text-sm`}
                disable={showLoader}
              />
            </View>
            <View>
              <ReadMore
                numberOfLines={1}
                renderTruncatedFooter={renderTruncatedFooter}
                renderRevealedFooter={renderRevealedFooter}
              >
                <Image
                  source={require('images/info-icon.png')}
                  style={tw`w-3 h-3`}
                />
                <Text style={[global.textSecondaryBlue, tw`text-xs`]}>
                  {`  ${STRINGS.GRNS.CANCEL_RESCHEDULE_READ_MORE}`}
                </Text>
              </ReadMore>
              <Button
                title={STRINGS.BUTTON.CANCEL_NO_RESCHEDULE}
                onTap={createPopup}
                style={tw`rounded-lg h-11`}
                textStyle={tw`text-sm`}
                disable={showLoader}
              />
            </View>
          </View>
        </HStack>
      </Box>
      {drawer === 'grn_details' && (
        <View style={[tw`pb-10`, global.drawer, global.heightAuto]}>
          <View style={tw`flex-row justify-between items-center p-5`}>
            <Text
              style={[tw`text-sm`, global.textColorGrey, global.textMedium]}
            >
              {STRINGS.SCREEN_TITLE.PENDING_GRN_DETAILS}
            </Text>
            <Pressable onPress={() => setDrawer('')}>
              <Image
                source={require('images/cancel.png')}
                style={tw`w-3 h-3`}
              />
            </Pressable>
          </View>
          <View style={tw`pt-2.5 pb-5 flex-row px-5`}>
            <Text
              style={[global.textSecondaryBlue, global.textMedium, tw`text-xs`]}
            >
              {product?.product_name
                ? product?.product_name[1].toString()
                : 'N/A'}
            </Text>
            <Text
              style={[global.textPrimaryBlack, global.textBold, tw`text-xs`]}
            >
              (
              {product?.product_uom
                ? product?.product_uom[1].toString()
                : 'N/A'}
              )
            </Text>
          </View>
          <View style={tw`flex-row justify-between px-5`}>
            <Text
              style={[global.textColorGrey, global.textRegular, tw`text-xs`]}
            >
              {STRINGS.GRNS.UNIT_PRICE}
            </Text>
            <Text
              style={[global.textPrimaryBlack, global.textMedium, tw`text-xs`]}
            >
              {`₹${product?.price_unit}`}
            </Text>
          </View>
          <View
            style={[
              tw`flex-row justify-between px-5 border-b`,
              global.borderColorSmokeGrey,
            ]}
          >
            <Text
              style={[
                global.textColorGrey,
                global.textRegular,
                tw`py-5 text-xs`,
              ]}
            >
              {STRINGS.MISCELLANEOUS.REMAINING_QTY}
            </Text>
            <Text
              style={[
                global.textPrimaryBlack,
                global.textMedium,
                tw`py-5 text-xs`,
              ]}
            >
              {product?.quantity
                ? `${product?.quantity - Number(quantityDone)}`
                : '0'}{' '}
              {product?.product_uom
                ? product?.product_uom[1].toString()
                : 'N/A'}
            </Text>
          </View>
          <View style={tw`flex-row justify-between px-5 pt-5`}>
            <Text
              style={[global.textColorGrey, global.textRegular, tw`text-xs`]}
            >
              {STRINGS.GRNS.AMOUNT}
            </Text>
            <Text
              style={[global.textPrimaryBlack, global.textMedium, tw`text-xs`]}
            >
              {`₹${calculateRemainingTotal(true)}`}
            </Text>
          </View>
          <View
            style={[
              tw`flex-row justify-between px-5 pt-5 border-b py-5 `,
              global.borderColorSmokeGrey,
            ]}
          >
            <Text
              style={[global.textColorGrey, global.textRegular, tw`text-xs`]}
            >
              {product?.tax_display_name || 'N/A'}
            </Text>
            <Text
              style={[global.textPrimaryBlack, global.textMedium, tw`text-xs`]}
            >
              {calculateTaxAmount(calculateRemainingTotal(true))}
            </Text>
          </View>
          <View style={tw`flex-row justify-between px-5 pt-5 pb-2.5`}>
            <View>
              <Text
                style={[global.textColorGrey, global.textMedium, tw`text-sm`]}
              >
                {STRINGS.PURCHASE_ORDER.TOTAL_AMOUNT}
              </Text>
            </View>
            <View>
              <Text
                style={[global.textPrimaryBlack, global.textBold, tw`text-xs`]}
              >
                {`₹${calculateRemainingTotal()}`}
              </Text>
            </View>
          </View>
        </View>
      )}
      {showModal && (
        <Modal
          body={modalBody}
          footer={modalFooter}
          close={() => setShowModal(false)}
          style={tw`p-0`}
        />
      )}
      {showLoader && (
        <View style={tw`justify-center items-center absolute inset-0`}>
          <ActivityIndicator
            size="large"
            color={colors.primaryGreen}
            style={tw`my-4`}
          />
        </View>
      )}
    </NativeBaseProvider>
  ) : null
}

export default ProcReschedule
