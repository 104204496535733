import React from 'react'
import { View, Text, Image, Pressable } from 'react-native'
import Toast from 'react-native-toast-message'
import tw from 'tailwind-react-native-classnames'

// style
import global from 'styles/global'
import styles from './style'

interface ConfigType {
  // TODO: Change the name since props have different meaning in react
  // eslint-disable-next-line react/no-unused-prop-types
  props: {
    successMsg: string
    isVisible: boolean
  }
}

interface ToastProps {
  successContainerStyle?: object
  successMsgStyle?: object
}

const ToastNotification = (props: ToastProps) => {
  const { successContainerStyle, successMsgStyle } = props
  const toastConfig = {
    // TODO: Change the variable name of props
    // eslint-disable-next-line @typescript-eslint/no-shadow
    success: ({ props: { successMsg, isVisible } }: ConfigType) => {
      return (
        <View
          style={[
            tw`flex-row items-center justify-between rounded-md`,
            global.bgLimeGreen,
            styles.notification,
            successContainerStyle,
            isVisible ? tw`opacity-100` : tw`opacity-0`,
          ]}
        >
          <View style={tw`flex-row items-center w-4/5`}>
            <Image
              source={require('images/white_tick.png')}
              style={styles.tickIcon}
            />
            <Text
              style={[
                global.textMedium,
                global.fontTwelve,
                global.textWhite,
                styles.msg,
                successMsgStyle,
              ]}
            >
              {successMsg}
            </Text>
          </View>
          <View style={tw`flex-row items-center`}>
            <Image
              source={require('images/line.png')}
              style={styles.lineIcon}
            />
            <Pressable onPress={() => Toast.hide()}>
              <Image
                source={require('images/white_close.png')}
                style={styles.closeIcon}
              />
            </Pressable>
          </View>
        </View>
      )
    },
    error: ({ props: { successMsg } }: ConfigType) => {
      return (
        <View
          style={[
            tw`flex-row items-center justify-between rounded-md`,
            global.bgRed,
            styles.notification,
            successContainerStyle,
          ]}
        >
          <View style={tw`flex-row items-center`}>
            <Image
              source={require('images/cancel-white.png')}
              style={styles.tickIcon}
            />
            <Text
              style={[
                global.textMedium,
                global.fontTwelve,
                global.textWhite,
                styles.msg,
                successMsgStyle,
              ]}
            >
              {successMsg}
            </Text>
          </View>
          <View style={tw`flex-row items-center`}>
            <Image
              source={require('images/line.png')}
              style={styles.lineIcon}
            />
            <Pressable onPress={() => Toast.hide()}>
              <Image
                source={require('images/white_close.png')}
                style={styles.closeIcon}
              />
            </Pressable>
          </View>
        </View>
      )
    },
  }

  return (
    <View style={styles.toastContainer}>
      <Toast config={toastConfig} ref={ref => Toast && Toast.setRef(ref)} />
    </View>
  )
}

export default ToastNotification
