import { useContext } from 'react'
import AsyncStorage from '@react-native-async-storage/async-storage'

import { KeycloakContext } from './KeycloakContext'
import atob from './atob'

const parseToken = token => {
  try {
    if (token) {
      const splitted = token.split('.')[1]
      const decoded = atob(unescape(encodeURIComponent(splitted))).toString()
      return JSON.parse(decoded)
    }
  } catch (e) {
    console.error(e)
  }
  return undefined
}

export const useKeycloak = () => {
  const {
    isLoggedIn,
    login,
    logout,
    ready = false,
    token = null,
  } = useContext(KeycloakContext)

  if (token?.accessToken) {
    AsyncStorage.setItem('accessToken', token?.accessToken)
    AsyncStorage.setItem('refreshToken', token?.refreshToken)
  }

  return {
    isLoggedIn,
    login,
    logout,
    ready,
    token: token?.accessToken ?? null,
    tokenParsed: parseToken(token?.accessToken),
  }
}
