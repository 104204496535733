import React from 'react'
import { Text, Image, Pressable, View } from 'react-native'
import STRINGS from 'constants/strings'

// styles
import global from 'styles/global'
import style from './style'

interface Props {
  onShowMoreClick: Function
  showMore: boolean
}

const Footer = ({ onShowMoreClick, showMore }: Props) =>
  showMore ? (
    <Pressable
      style={[
        global.paddingBottomSixteen,
        global.justifyContentCenter,
        global.alignItemsCenter,
      ]}
      onPress={() => onShowMoreClick()}
    >
      <Text
        style={[
          global.fontFourteen,
          global.textRegular,
          global.textSecondaryBlue,
          style.showMore,
        ]}
      >
        {STRINGS.ORDER_DETAILS.SHOW_MORE}
      </Text>
      <Image
        source={require('images/arrow-down.png')}
        style={style.arrowBottom}
      />
    </Pressable>
  ) : (
    <View />
  )

export default Footer
