import { Dispatch } from 'react'

import { get, URL } from 'src/utilities/axios'
import { CustomerListResponse } from 'src/modals'
import { CustomerActions } from 'src/redux/types'

export const fetchCustomerList = () => {
  return async (dispatch: Dispatch<CustomerActions>) => {
    try {
      const res: CustomerListResponse = await get(URL.CUSTOMER_LIST)
      if (res.error === null) {
        dispatch({
          type: 'SET_CUSTOMER_LIST',
          payload: res.data,
        })
      }
    } catch (error) {
      dispatch({
        type: 'SET_CUSTOMER_ERROR',
        payload: error,
      })
    }
  }
}
