import React, { ReactElement, useState } from 'react'
import { View, Text, Image, ScrollView, ActivityIndicator } from 'react-native'
import RadioForm, {
  RadioButton,
  RadioButtonInput,
  RadioButtonLabel,
} from 'react-native-simple-radio-button'
import { useDispatch, useSelector } from 'react-redux'
import { Box, NativeBaseProvider } from 'native-base'
import tw from 'tailwind-react-native-classnames'
import { AxiosError, AxiosResponse } from 'axios'

import STRINGS from 'constants/strings'
import {
  formatterWithoutDecimals,
  showToastNotification,
} from 'src/utilities/helpers'
import useDownloadFrmS3 from 'src/utilities/hooks/useDownloadFrmS3'
import { DOWNLOAD } from 'src/config/download'
import { post, URL } from 'src/utilities/axios'

import { ApplicationState } from 'src/redux/types'
import { ProcTransportDetails } from 'src/modals'
import { TransportDetailsProps } from 'src/navigations/types'

// components
import InputTextField from 'src/components/form-fields/InputTextField'
import Button from 'src/components/form-fields/Button'
import Modal from 'src/components/Modal'

// styles
import global from 'styles/global'
import colors from 'styles/colors'

const TransportDetails = ({ navigation, route }: TransportDetailsProps) => {
  const { id } = route.params
  const dispatch = useDispatch()
  const [showLoader, setShowLoader] = useState<boolean>(false)
  const { moveId, grnDetails, productId, quantityDone, qcParams } = useSelector(
    (state: ApplicationState) => state.grnReducer,
  )
  const { downloadDocFrmS3 } = useDownloadFrmS3()

  const transportDetailsInitialValues = {
    transport_name: '',
    vehicle_number: '',
    advance_payment: '',
    transport_invoice_number: '',
    driver_name: '',
    driver_number: '',
  }
  const [transportDetails, setTransportDetails] =
    useState<ProcTransportDetails>(transportDetailsInitialValues)
  const [modalBody, setModalBody] = useState<ReactElement>(<></>)
  const [showModal, setShowModal] = useState<Boolean>(false)
  const [modalFooter, setModalFooter] = useState<ReactElement>(<></>)
  const [isAdvancePayment, setIsAdvancePayment] = useState<string>('')
  const isAdvancePaymentProvided = [
    { label: 'Yes', value: 'Yes', selected: false },
    { label: 'No', value: 'No', selected: false },
  ]

  const isInwardGRNBtnDisabled = () =>
    !(
      transportDetails?.transport_name &&
      transportDetails?.vehicle_number &&
      (isAdvancePayment === 'Yes'
        ? transportDetails?.advance_payment
        : isAdvancePayment)
    )

  const handleInputChange = (value: string, name: string) => {
    if (
      name === STRINGS.PURCHASE_ORDER.ADVANCE_PAYMENT_SNAKE_CASE &&
      value.includes(`₹`)
    )
      // TODO: Create a copy of param and use it instead
      // eslint-disable-next-line no-param-reassign
      value = value.replace(/,/g, '').substring(1)
    setTransportDetails({
      ...transportDetails,
      [name]: value,
    })
  }

  const goToSuccessScreen = () => {
    setShowModal(false)
    setShowLoader(true)
    const payload = {
      data: [
        {
          move_id: moveId,
          product_id: productId,
          qty_done: quantityDone,
          qc_params: qcParams,
        },
      ],
      create_back_order: 'false',
      transport_details: {
        name: transportDetails?.transport_name,
        vehicle_no: transportDetails?.vehicle_number,
        drivers_name: transportDetails?.driver_name,
        drivers_no: transportDetails?.driver_number,
        advance_payment: transportDetails?.advance_payment,
        bill_no: transportDetails?.transport_invoice_number,
      },
    }
    post(`${URL.INWARD_GRN}/${grnDetails?.id}`, payload)
      .then((res: AxiosResponse) => {
        if (res?.data) {
          setShowLoader(false)
          // eslint-disable-next-line @typescript-eslint/naming-convention
          const { grn_id, store_fname, lot_data = { [moveId]: [] } } = res.data
          navigation.navigate('SuccessScreen', {
            iconImg: (
              <Image
                source={require('images/success-icon.png')}
                style={tw`h-32 w-32`}
              />
            ),
            customMsg: (
              <Text
                style={[
                  global.textRegular,
                  global.textPrimaryBlack,
                  tw`leading-6 text-sm p-5 text-center flex-row`,
                ]}
              >
                {STRINGS.INWARD_GRN.PRODUCTS_FROM}
                <Text style={tw`font-bold`}>
                  {grnDetails?.vendor_name !== undefined
                    ? grnDetails?.vendor_name[1]
                    : ''}
                </Text>
                {STRINGS.INWARD_GRN.SUCCESS_MSG}
              </Text>
            ),
            greeting: true,
            idFields: [
              {
                title: STRINGS.MISCELLANEOUS.GRN_ID_WITH_HYPHEN,
                value: grn_id,
              },
              {
                title: STRINGS.MISCELLANEOUS.LOT_ID_WITH_HYPHEN,
                value: lot_data[moveId][1],
              },
            ],
            footerButtons: [
              // commented code for Release v0.9.1
              // {
              //   title: STRINGS.BUTTON.CREATE_VENDOR_BILL_FOR_GRN,
              //   onClick: () => navigation.navigate('CreateVendorBill'),
              //   bgColor: global.bgPrimaryGrren.backgroundColor,
              //   style: tw`w-full`,
              // },
              {
                title: STRINGS.BUTTON.DOWNLOAD_GRN,
                onClick: () => {
                  downloadDocFrmS3(
                    store_fname,
                    grn_id,
                    STRINGS.MISCELLANEOUS.GRN_DOWNLOAD_MSG,
                  )
                },
                buttonStyle: [
                  tw`rounded-lg border bg-white`,
                  global.borderColorPrimaryGreen,
                ],
                style: tw`w-full`,
                textStyle: [global.textPrimaryGreen],
              },
            ],
            homeButtonStyle: [
              tw`border-0 underline bg-transparent`,
              global.textDecorationColorPrimaryGreen,
            ],
            buttonContainerStyle: tw`flex-col`,
          })
        }
      })
      .catch((err: AxiosError) => {
        setShowLoader(false)
        showToastNotification(DOWNLOAD.ERROR, err?.response?.data.error)
      })
  }

  const leftHeader = (text: string, disable: boolean, isAsterik: boolean) => (
    <View style={tw`flex-row items-center mt-5 ml-5 mb-2.5 `}>
      <Text
        style={[
          global.textRegular,
          tw`text-xs`,
          disable ? global.textDarkGrey : global.textColorGrey,
        ]}
      >
        {text}
      </Text>
      {isAsterik && (
        <Text style={disable ? global.textDarkGrey : global.textRed}>*</Text>
      )}
    </View>
  )

  const modal = () => {
    setModalBody(
      <View style={tw`items-center mt-9`}>
        <Image style={tw`h-12 w-14`} source={require('images/warning.png')} />
        <View style={tw`w-full`}>
          <Text
            style={[
              global.textRegular,
              global.textColorGrey,
              tw`text-sm text-center leading-5 mt-6 mx-5 mt-7`,
            ]}
          >
            {STRINGS.PURCHASE_ORDER.INWARD_GRN_CONFIRMATION_MSG}
          </Text>
        </View>
      </View>,
    )
    setModalFooter(
      <View
        style={[
          global.borderColorLightGrey,
          tw`mt-8 flex-row justify-between border-t`,
        ]}
      >
        <Button
          title={STRINGS.BUTTON.NO}
          onTap={() => {
            setShowModal(false)
          }}
          btnType="secondary"
          style={[
            global.borderColorLightGrey,
            tw`w-1/2  border-0 border-r rounded-bl-lg`,
          ]}
          textStyle={[
            global.textRegular,
            global.textSecondaryOrange,
            tw`text-sm`,
          ]}
        />
        <Button
          title={STRINGS.BUTTON.INWARD_GRN}
          onTap={() => goToSuccessScreen()}
          btnType="secondary"
          style={[
            global.borderColorLightGrey,
            tw`w-1/2  border-0 rounded-br-lg`,
          ]}
          textStyle={[global.textRegular, tw`text-sm`]}
        />
      </View>,
    )
    setShowModal(true)
  }

  const goToNextScreen = () => {
    setShowModal(false)
    dispatch({
      type: 'SET_TRANSPORT_DETAILS',
      payload: {
        transport_name: transportDetails?.transport_name,
        vehicle_number: transportDetails?.vehicle_number,
        advance_payment: transportDetails?.advance_payment,
        transport_invoice_number: transportDetails?.transport_invoice_number,
        driver_name: transportDetails?.driver_name,
        driver_number: transportDetails?.driver_number,
      },
    })
    if (
      grnDetails?.items !== undefined &&
      Number(grnDetails?.items[0]?.quantity) <= quantityDone
    ) {
      modal()
    } else {
      navigation.navigate('ProcReschedule', { id })
    }
  }

  return (
    <NativeBaseProvider>
      <Box style={tw`flex-1`}>
        <ScrollView style={tw`flex-1`}>
          <InputTextField
            leftHeader={leftHeader(
              STRINGS.PURCHASE_ORDER.TRANSPORTER_NAME,
              false,
              true,
            )}
            value={transportDetails?.transport_name}
            onTextChange={(value: string) => {
              handleInputChange(
                value,
                STRINGS.PURCHASE_ORDER.TRANSPORT_NAME_SNAKE_CASE,
              )
            }}
            placeholder={STRINGS.PURCHASE_ORDER.ENTER_TRANSPORTER_NAME}
            style={tw`mx-5 h-11 justify-between items-center rounded-md`}
            placeholderTextColor={colors.darkGrey}
            innerStyle={[
              global.textMedium,
              global.textColorGrey,
              tw`text-sm mx-2.5 w-full`,
            ]}
            openKeypadOnTouch
            inputEditable
          />
          <InputTextField
            leftHeader={leftHeader(
              STRINGS.PURCHASE_ORDER.VEHICLE_NUMBER,
              false,
              true,
            )}
            value={transportDetails?.vehicle_number.toString()}
            isDisabled={false}
            onTextChange={(value: string) => {
              handleInputChange(
                value,
                STRINGS.PURCHASE_ORDER.VEHICLE_NUMBER_SNAKE_CASE,
              )
            }}
            placeholder={STRINGS.PURCHASE_ORDER.ENTER_VEHICLE_NUMBER}
            style={tw`mx-5 h-11 justify-between items-center rounded-md`}
            placeholderTextColor={colors.darkGrey}
            innerStyle={[
              global.textMedium,
              global.textColorGrey,
              tw`text-sm mx-2.5 w-full`,
            ]}
            openKeypadOnTouch
            inputEditable
          />
          <View style={tw`px-5 pt-5`}>
            <View style={tw`flex-row`}>
              <Text
                style={[global.textRegular, global.textColorGrey, tw`text-xs`]}
              >
                {STRINGS.PURCHASE_ORDER.SELECT_ADVANCED_PAYMENT}
                <Text style={global.textRed}>*</Text>
              </Text>
            </View>
            <View style={tw`mt-4`}>
              <RadioForm formHorizontal>
                {isAdvancePaymentProvided.map((providedOption, index) => (
                  // TODO: Need to provide reason for why index key or else fix it
                  // eslint-disable-next-line react/no-array-index-key
                  <RadioButton labelHorizontal key={index}>
                    <RadioButtonInput
                      obj={providedOption}
                      index={index}
                      isSelected={
                        isAdvancePayment ===
                        isAdvancePaymentProvided[index].value
                      }
                      buttonInnerColor={colors.primaryGreen}
                      buttonOuterColor={
                        isAdvancePayment ===
                        isAdvancePaymentProvided[index].value
                          ? colors.primaryGreen
                          : colors.primaryGreen
                      }
                      buttonSize={7.3}
                      onPress={() => setIsAdvancePayment(providedOption.value)}
                      buttonOuterSize={16.67}
                      buttonStyle={tw`mr-2 border-2 mt-0.5`}
                    />
                    <RadioButtonLabel
                      obj={providedOption}
                      index={index}
                      labelHorizontal
                      onPress={() => setIsAdvancePayment(providedOption.value)}
                      labelStyle={[
                        global.textColorGrey,
                        global.textMedium,
                        tw`text-sm pl-0`,
                      ]}
                      labelWrapStyle={tw`mr-14`}
                    />
                  </RadioButton>
                ))}
              </RadioForm>
            </View>
          </View>
          {isAdvancePayment === 'Yes' && (
            <InputTextField
              leftHeader={leftHeader(
                STRINGS.PURCHASE_ORDER.ADVANCE_PAYMENT,
                false,
                true,
              )}
              value={
                transportDetails?.advance_payment &&
                formatterWithoutDecimals.format(
                  Number(transportDetails?.advance_payment),
                )
              }
              isDisabled={false}
              onTextChange={(value: string) => {
                handleInputChange(
                  value,
                  STRINGS.PURCHASE_ORDER.ADVANCE_PAYMENT_SNAKE_CASE,
                )
              }}
              placeholder={STRINGS.PURCHASE_ORDER.ENTER_ADVANCE_PAYMENT}
              style={tw`mx-5 h-11 justify-between items-center rounded-md`}
              placeholderTextColor={colors.darkGrey}
              innerStyle={[
                global.textMedium,
                global.textColorGrey,
                tw`text-sm mx-2.5 w-full`,
              ]}
              openKeypadOnTouch
              inputEditable
              keyboardType="numeric"
            />
          )}
          <InputTextField
            leftHeader={leftHeader(
              STRINGS.PURCHASE_ORDER.TRANSPORT_INVOICE_NUMBER,
              false,
              false,
            )}
            value={transportDetails?.transport_invoice_number?.toString()}
            isDisabled={false}
            onTextChange={(value: string) => {
              handleInputChange(
                value,
                STRINGS.PURCHASE_ORDER.TRANSPORT_INVOICE_NUMBER_SNAKE_CASE,
              )
            }}
            placeholder={STRINGS.PURCHASE_ORDER.ENTER_TRANSPORT_INVOICE_NUMBER}
            style={tw`mx-5 h-11 justify-between items-center rounded-md`}
            placeholderTextColor={colors.darkGrey}
            innerStyle={[
              global.textMedium,
              global.textColorGrey,
              tw`text-sm mx-2.5 w-full`,
            ]}
            openKeypadOnTouch
            inputEditable
          />
          <InputTextField
            leftHeader={leftHeader(
              STRINGS.PURCHASE_ORDER.DRIVER_NAME,
              false,
              false,
            )}
            value={transportDetails?.driver_name}
            isDisabled={false}
            onTextChange={(value: string) => {
              handleInputChange(
                value,
                STRINGS.PURCHASE_ORDER.DRIVER_NAME_SNAKE_CASE,
              )
            }}
            placeholder={STRINGS.PURCHASE_ORDER.ENTER_DRIVER_NAME}
            style={tw`mx-5 h-11 justify-between items-center rounded-md`}
            placeholderTextColor={colors.darkGrey}
            innerStyle={[
              global.textMedium,
              global.textColorGrey,
              tw`text-sm mx-2.5 w-full`,
            ]}
            openKeypadOnTouch
            inputEditable
          />
          <InputTextField
            leftHeader={leftHeader(
              STRINGS.PURCHASE_ORDER.DRIVER_NUMBER,
              false,
              false,
            )}
            value={transportDetails?.driver_number?.toString()}
            isDisabled={false}
            onTextChange={(value: string) => {
              handleInputChange(
                value,
                STRINGS.PURCHASE_ORDER.DRIVER_NUMBER_SNAKE_CASE,
              )
            }}
            placeholder={STRINGS.PURCHASE_ORDER.ENTER_DRIVER_NAME}
            style={tw`mx-5 h-11 justify-between items-center rounded-md`}
            placeholderTextColor={colors.darkGrey}
            innerStyle={[
              global.textMedium,
              global.textColorGrey,
              tw`text-sm mx-2.5 w-full`,
            ]}
            containerStyle={tw`pb-5`}
            openKeypadOnTouch
            inputEditable
            keyboardType="numeric"
            maxLength={10}
          />
        </ScrollView>
        <View
          style={[
            global.borderColorLightSecondaryGrey,
            tw`bg-white py-5 px-5 border-t`,
          ]}
        >
          <Button
            title={
              grnDetails?.items !== undefined &&
              Number(grnDetails?.items[0]?.quantity) > quantityDone
                ? STRINGS.PURCHASE_ORDER.CONTINUE_TO_INWARD_GRN
                : STRINGS.BUTTON.INWARD_GRN
            }
            style={tw`rounded-lg h-11`}
            onTap={goToNextScreen}
            disable={isInwardGRNBtnDisabled() || showLoader}
          />
        </View>
      </Box>
      {showModal && (
        <Modal
          body={modalBody}
          footer={modalFooter}
          close={() => setShowModal(false)}
          style={tw`p-0`}
        />
      )}
      {showLoader && (
        <View style={tw`justify-center items-center absolute inset-0`}>
          <ActivityIndicator
            size="large"
            color={colors.primaryGreen}
            style={tw`my-4`}
          />
        </View>
      )}
    </NativeBaseProvider>
  )
}

export default TransportDetails
