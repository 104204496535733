import { StyleSheet } from 'react-native'
import colors from 'styles/colors'

const styles = StyleSheet.create({
  tabIndicator: {
    width: '14%',
    height: 4,
    borderRadius: 2,
    left: '18%',
    backgroundColor: colors.bgLightGreen,
  },
  iconDownload: {
    width: 16,
    height: 16,
  },
  iconButton: {
    height: 48,
  },
  creditFooter: {
    borderTopWidth: 1,
    borderTopColor: colors.lightSecondaryGrey,
  },
  creditFooterButton: {
    height: 45,
  },
  iconButtonText: {
    marginLeft: 6,
  },
  fileAddIcon: {
    width: 50,
    height: 60,
    marginTop: 41,
  },
  btnContainer: {
    marginTop: 34,
    borderTopWidth: 1,
    borderTopColor: colors.lightGrey,
  },
  btnYesCover: {
    borderWidth: 0,
    borderColor: colors.lightGrey,
  },
  btnNoCover: {
    borderWidth: 0,
    borderColor: colors.lightGrey,
  },
  warningMsg: {
    marginTop: 30,
    lineHeight: 22,
  },
})

export default styles
