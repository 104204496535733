import React from 'react'
import {
  TouchableOpacity,
  Text,
  Image,
  ImageSourcePropType,
  ActivityIndicator,
} from 'react-native'

// styles
import global from 'styles/global'
import colors from 'styles/colors'
import styles from './style'

interface ButtonProps {
  title: string
  onTap: Function
  style?: object
  textStyle?: object
  btnType?: string
  disable?: boolean
  iconPath?: ImageSourcePropType
  reference?: any
  loader?: boolean
}

const Button: React.FC<ButtonProps> = ({
  title,
  onTap,
  style,
  textStyle,
  btnType,
  disable,
  iconPath,
  reference,
  loader,
}) => {
  return (
    <TouchableOpacity
      activeOpacity={0.95}
      style={[
        styles.button,
        global.justifyContentCenter,
        global.alignItemsCenter,
        btnType === 'primary' && { backgroundColor: colors.primaryGreen },
        btnType === 'secondary' && styles.secondaryBtn,
        btnType === 'primary' &&
          disable && { backgroundColor: colors.inactiveGreen },
        btnType === 'secondary' && disable && styles.secondaryBtnDisable,
        style,
      ]}
      onPress={() => onTap()}
      disabled={disable}
      ref={reference}
    >
      {iconPath && <Image source={iconPath} style={styles.iconSize} />}
      {loader ? (
        <ActivityIndicator color={colors.primaryGreen} />
      ) : (
        <Text
          style={[
            styles.text,
            global.textBold,
            btnType === 'secondary' && { color: colors.primaryGreen },
            textStyle,
          ]}
        >
          {title}
        </Text>
      )}
    </TouchableOpacity>
  )
}

Button.defaultProps = {
  style: {},
  textStyle: {},
  btnType: 'primary',
  disable: false,
}

export default Button
