import React from 'react'
import { View, Text, Pressable, Image } from 'react-native'
import STRINGS from 'constants/strings'

// styles
import global from 'styles/global'
import styles from '../style'

interface HeaderProps {
  index: number
  total: number
  stepper: Function
  style?: object
}

const Header = ({ index, total, stepper, style }: HeaderProps) => {
  return (
    <View
      style={[
        global.directionRow,
        global.justifyContentSpaceBetween,
        global.bgWhite,
        global.paddingSixteen,
        style,
      ]}
    >
      <View>
        <Text
          style={[
            global.textBold,
            global.fontFourteen,
            global.textSecondaryBlue,
          ]}
        >
          {`${index + 1}/${total} ${STRINGS.MISCELLANEOUS.PRODUCTS}`}
        </Text>
      </View>
      <View style={[global.directionRow, global.justifyContentSpaceBetween]}>
        <Pressable
          onPress={() => stepper('dec')}
          style={[global.hPaddingTwenty]}
        >
          {index === 0 ? (
            <Image
              source={require('images/disabled-left.png')}
              style={[styles.arrowBtn]}
            />
          ) : (
            <Image
              source={require('images/left.png')}
              style={[styles.arrowBtn]}
            />
          )}
        </Pressable>
        <Pressable onPress={() => stepper('inc')}>
          {index === total - 1 ? (
            <Image
              source={require('images/disabled-right.png')}
              style={[styles.arrowBtn]}
            />
          ) : (
            <Image
              source={require('images/right.png')}
              style={[styles.arrowBtn]}
            />
          )}
        </Pressable>
      </View>
    </View>
  )
}

export default Header
