import React, { useState } from 'react'
import { View, Text, Image, Pressable, Modal } from 'react-native'
import tw from 'tailwind-react-native-classnames'
import RadioForm, {
  RadioButton,
  RadioButtonInput,
} from 'react-native-simple-radio-button'

import STRINGS from 'constants/strings'

// styles
import global from 'styles/global'
import colors from 'styles/colors'
import style from './style'

interface Item {
  id: string
  name: string
  childItems?: Array<{
    id: string
    name: string
  }>
}

interface SortProps {
  closeDrawer: Function
  applySort: Function
  items: Array<Item>
  title?: string
  selectedValue?: string | null
}

/**
 * Sort Drawer
 *
 * Pass props to display options or hide.
 *
 * @example
 *
 * title: 'Sort By:',
 * items: [
 *  {
 *   id: 'scheduled_date',
 *   name: 'Delivery Date:',
 *   childItems: [
 *     {
 *       id: 'aToz',
 *       name: 'A To Z',
 *     },
 *     {
 *       id: 'zToa',
 *       name: 'Z To A',
 *     }
 *   ]
 * },
 * {
 *   id: 'create_date',
 *   name: 'Created Date:',
 * }
 *]
 *
 * */

const defaultChildItems = [
  {
    id: 'ASC',
    name: STRINGS.MISCELLANEOUS.OLD_TO_NEW,
  },
  {
    id: 'DESC',
    name: STRINGS.MISCELLANEOUS.NEW_TO_OLD,
  },
]

const Sort = ({
  closeDrawer,
  applySort,
  items,
  title,
  selectedValue,
}: SortProps) => {
  const [option, setOption] = useState<null | string>()

  const handleCheckbox = (value: string, sortBy: string, order: string) => {
    if (value !== option) {
      applySort(value, sortBy, order)
      setOption(value)
      closeDrawer(false)
    }
  }

  return (
    <Modal
      animationType="none"
      visible
      transparent
      onRequestClose={() => closeDrawer()}
    >
      <View style={[tw`pb-2.5`, global.drawer, style.heightAuto]}>
        <View style={tw`flex-row justify-between items-center p-5 pb-0`}>
          <Text
            style={[
              tw`text-sm font-bold`,
              global.textColorGrey,
              global.textMedium,
            ]}
          >
            {title || STRINGS.MISCELLANEOUS.SORT_BY}
          </Text>
          <Pressable onPress={() => closeDrawer()}>
            <Image source={require('images/cancel.png')} style={tw`w-3 h-3`} />
          </Pressable>
        </View>
        {items?.map((item: Item, index: number) => (
          <View
            key={item.id}
            style={[
              tw`pt-2.5 pb-5 px-5 mt-5 ${
                index < items.length - 1 ? 'border-b' : ''
              }`,
              index < items.length - 1 && global.borderColorSmokeGrey,
            ]}
          >
            <Text
              style={[tw`text-xs`, global.textColorGrey, global.textMedium]}
            >
              {item.name}
            </Text>
            {item?.childItems
              ? item?.childItems.map((childItem: Item) => (
                  <View key={childItem.id}>
                    <View
                      style={tw`flex-row justify-between items-center pt-4 pb-2.5`}
                    >
                      <Pressable
                        style={[
                          tw`text-sm`,
                          `${item.id}/${childItem.id}` ===
                          (option || selectedValue)
                            ? global.textPrimaryBlack
                            : global.textColorGrey,
                          `${item.id}/${childItem.id}` ===
                          (option || selectedValue)
                            ? global.textMedium
                            : global.textRegular,
                        ]}
                        onPress={() =>
                          handleCheckbox(
                            `${item.id}/${childItem.id}`,
                            item.id,
                            childItem.id,
                          )
                        }
                      >
                        {childItem.name}
                      </Pressable>
                      <RadioForm formHorizontal>
                        <RadioButton style={tw`mb-0`}>
                          <RadioButtonInput
                            index={0}
                            obj={{
                              label: childItem.name,
                              value: `${item.id}/${childItem.id}`,
                            }}
                            isSelected={
                              `${item.id}/${childItem.id}` ===
                              (option || selectedValue)
                            }
                            buttonInnerColor={colors.secondaryOrange}
                            buttonOuterColor={colors.secondaryOrange}
                            buttonSize={8.3}
                            onPress={value =>
                              handleCheckbox(value, item.id, childItem.id)
                            }
                            buttonOuterSize={16.67}
                          />
                        </RadioButton>
                      </RadioForm>
                    </View>
                  </View>
                ))
              : defaultChildItems.map((childItem: Item) => (
                  <View key={childItem.id}>
                    <View
                      style={tw`flex-row justify-between items-center pt-4 pb-2.5`}
                    >
                      <Text
                        style={[
                          tw`text-sm`,
                          `${item.id}/${childItem.id}` ===
                          (option || selectedValue)
                            ? global.textPrimaryBlack
                            : global.textColorGrey,
                          `${item.id}/${childItem.id}` ===
                          (option || selectedValue)
                            ? global.textMedium
                            : global.textRegular,
                        ]}
                        onPress={() =>
                          handleCheckbox(
                            `${item.id}/${childItem.id}`,
                            item.id,
                            childItem.id,
                          )
                        }
                      >
                        {childItem.name}
                      </Text>
                      <RadioForm formHorizontal>
                        <RadioButton style={tw`mb-0`}>
                          <RadioButtonInput
                            index={0}
                            obj={{
                              label: childItem.name,
                              value: `${item.id}/${childItem.id}`,
                            }}
                            isSelected={
                              `${item.id}/${childItem.id}` ===
                              (option || selectedValue)
                            }
                            buttonInnerColor={colors.secondaryOrange}
                            buttonOuterColor={colors.secondaryOrange}
                            buttonSize={8.3}
                            onPress={value =>
                              handleCheckbox(value, item.id, childItem.id)
                            }
                            buttonOuterSize={16.67}
                          />
                        </RadioButton>
                      </RadioForm>
                    </View>
                  </View>
                ))}
          </View>
        ))}
      </View>
    </Modal>
  )
}

export default Sort
