import React from 'react'
import { View, Image } from 'react-native'
import { useSelector } from 'react-redux'

import { ApplicationState } from 'src/redux/types'
import STRING from 'constants/strings'
import { HomeProps } from 'src/navigations/types'

// components
import TileButton from 'src/components/home/TileButton'
import SummaryTile from 'src/components/home/SummaryTile'

// styles
import globals from 'styles/global'
import styles from './style'

const InventoryManager = ({ navigation }: HomeProps) => {
  const { grnCount } = useSelector(
    (state: ApplicationState) => state.grnReducer,
  )

  return (
    <View>
      <View style={globals.hMarginTwenty}>
        <SummaryTile
          amountType={STRING.HOME.PENDING_GRN_NUMBER}
          displayValue={grnCount || 0}
          containerStyle={[
            globals.hPaddingTwenty,
            styles.outstandingContainerStyle,
          ]}
          displayValueStyle={styles.displayValueStyle}
          backgroundImg={
            <Image
              source={require('images/cloud.png')}
              style={[globals.positionAbsolute, styles.cloudBackground]}
            />
          }
        />
      </View>
      <View
        style={[
          globals.directionRow,
          globals.justifyContentSpaceBetween,
          globals.hMarginTwenty,
          styles.paddingTopFifty,
        ]}
      >
        <TileButton
          icon={require('images/create-order.png')}
          iconStyle={styles.tileButtonIcon}
          title={STRING.HOME.INWARD_GRN}
          onPress={() => navigation.navigate('PendingGRNList')}
          containerStyle={styles.tileContainer}
        />
        <TileButton
          icon={require('images/order-history.png')}
          iconStyle={styles.tileButtonIcon}
          title={STRING.HOME.GRN_HISTORY}
          onPress={() => navigation.navigate('GRNHistory')}
          containerStyle={styles.tileContainer}
        />
      </View>
    </View>
  )
}

export default InventoryManager
