import React from 'react'
import { View, Image } from 'react-native'
import { useSelector } from 'react-redux'
import tw from 'tailwind-react-native-classnames'

import { ApplicationState } from 'src/redux/types'
import STRING from 'constants/strings'
import { HomeProps } from 'src/navigations/types'

// components
import TileButton from 'src/components/home/TileButton'
import SummaryTile from 'src/components/home/SummaryTile'

// styles
import styles from './style'

const WarehouseManager = ({ navigation }: HomeProps) => {
  const { unbilledVoucherCount } = useSelector(
    (state: ApplicationState) => state.voucherReducer,
  )

  return (
    <View>
      <View style={tw`mx-5`}>
        <SummaryTile
          amountType={STRING.HOME.UNBILLED_VOUCHERS_TODAY}
          displayValue={unbilledVoucherCount}
          containerStyle={[tw`px-5 pb-11`, styles.outstandingContainerStyle]}
          displayValueStyle={tw`mt-4`}
          backgroundImg={
            <Image
              source={require('images/cloud.png')}
              style={[tw`absolute top-0 right-0 h-20`, styles.cloudBackground]}
            />
          }
        />
      </View>
      <View style={[tw`flex-row mx-5 justify-between`, styles.paddingTopFifty]}>
        <TileButton
          icon={require('images/manage_inventory_icon.png')}
          iconStyle={tw`w-5 h-5`}
          title={STRING.HOME.MANAGE_PICKER_ALLOCATION}
          onPress={() => {}}
          containerStyle={styles.tileContainer}
          textStyle={styles.textStyle}
        />
        <TileButton
          icon={require('images/order-history.png')}
          iconStyle={tw`w-5 h-5`}
          title={STRING.HOME.VIEW_ORDER_HISTORY}
          onPress={() => navigation.navigate('WHOrderHistory')}
          containerStyle={styles.tileContainer}
        />
      </View>
    </View>
  )
}

export default WarehouseManager
