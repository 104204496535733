import produce from 'immer'
import moment from 'moment'

import { Voucher } from 'src/modals'
import { VoucherActions } from 'src/redux/types'

type VoucherState = {
  voucher: Voucher | null
  voucherList: Voucher[]
  voucherListCount: number
  error: string | null
  voucherListError: string | null
  unbilledVoucherCount: number
  unbilledVoucherCountError: string | null
}

const initialState = {
  voucher: null,
  voucherList: [] as Voucher[],
  voucherListCount: 0,
  error: null,
  voucherListError: null,
  unbilledVoucherCount: 0,
  unbilledVoucherCountError: null,
}

const VoucherReducer = (
  state: VoucherState = initialState,
  action: VoucherActions,
) => {
  switch (action.type) {
    case 'SET_VOUCHER': {
      return {
        ...state,
        voucher: action.payload,
      }
    }
    case 'SET_VOUCHER_ERROR': {
      return {
        ...state,
        error: action.payload,
      }
    }
    case 'SET_VOUCHER_LIST': {
      return {
        ...state,
        voucherList: action.payload,
      }
    }
    case 'SET_VOUCHER_LIST_COUNT': {
      return {
        ...state,
        voucherListCount: action.payload,
      }
    }
    case 'RESET_VOUCHER_LIST': {
      return {
        ...state,
        voucherList: initialState.voucherList,
      }
    }
    case 'SET_VOUCHER_LIST_ERROR': {
      return {
        ...state,
        voucherListError: action.payload,
      }
    }
    case 'UPDATE_VOUCHER_ITEM_PRICE': {
      return produce(state, proxy => {
        const { index, updatedPrice } = action.payload
        proxy.voucher!.items[index].price_unit = updatedPrice || 0
      })
    }
    case 'PRICE_RESET_VOUCHER_ITEM': {
      return produce(state, proxy => {
        for (let i = 0; i < proxy.voucher!.items.length; i++) {
          proxy.voucher!.items[i].price_unit! =
            proxy.voucher!.items[i].initial_price!
        }
      })
    }
    case 'ADD_VOUCHER_NOTE': {
      return produce(state, proxy => {
        proxy.voucher!.notes.push({
          body: action.payload,
          origin: 'By You (Not Saved)',
          date: moment().format('YYYY-MM-DD HH:MM:SS'),
        })
      })
    }
    case 'SET_UNBILLED_VOUCHER_COUNT': {
      return {
        ...state,
        unbilledVoucherCount: action.payload,
      }
    }
    case 'SET_UNBILLED_VOUCHER_COUNT_ERROR': {
      return {
        ...state,
        unbilledVoucherCountError: action.payload,
      }
    }
    case 'RESET_VOUCHER': {
      return {
        ...state,
        voucher: initialState.voucher,
      }
    }
    default:
      return state
  }
}

export { VoucherReducer }
