import produce from 'immer'
import moment from 'moment'

import { Invoice } from 'src/modals'
import { InvoiceActions } from 'src/redux/types'

type InvoiceState = {
  invoice: Invoice | null
  invoiceList: Invoice[] | null
  invoiceListError: string | null
  invoiceError: string | null
}

const initialState = {
  invoice: null,
  invoiceList: [] as Invoice[],
  invoiceListError: null,
  invoiceError: null,
}

const InvoiceReducer = (
  state: InvoiceState = initialState,
  action: InvoiceActions,
) => {
  switch (action.type) {
    case 'SET_INVOICE_LIST': {
      return {
        ...state,
        invoiceList: action.payload,
      }
    }
    case 'SET_INVOICE_LIST_ERROR': {
      return {
        ...state,
        invoiceListError: action.payload,
      }
    }
    case 'SET_INVOICE': {
      return produce(state, proxy => {
        proxy.invoice = action.payload
      })
    }
    case 'SET_INVOICE_ERROR': {
      return produce(state, proxy => {
        proxy.invoiceError = action.payload
      })
    }
    case 'ADD_INVOICE_NOTE': {
      return produce(state, proxy => {
        if (!proxy.invoice?.notes) {
          proxy.invoice!.notes = []
        }
        proxy.invoice?.notes.push({
          body: action.payload,
          origin: 'By You (Not Saved)',
          date: moment().format('YYYY-MM-DD HH:MM:SS'),
        })
      })
    }
    case 'UPDATE_INVOICE_ITEM_PRICE': {
      return produce(state, proxy => {
        const { index, updatedPrice } = action.payload
        proxy.invoice!.items![index].price_unit = updatedPrice
      })
    }
    case 'PRICE_RESET_INVOICE_ITEM': {
      return produce(state, proxy => {
        for (let i = 0; i < proxy.invoice!.items!.length; i++) {
          proxy.invoice!.items![i].price_unit =
            proxy.invoice!.items![i].initial_price!
        }
      })
    }
    default:
      return state
  }
}

export { InvoiceReducer }
