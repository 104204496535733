import React from 'react'
import { View, ScrollView } from 'react-native'

import { OrderHistoryDetailsProps } from 'src/navigations/types'
import { utcToLocalTime } from 'src/utilities/helpers'
import { customerCreateDateFormat } from 'src/config/voucher'

// component
import OrderDetailsVoucher from 'src/components/voucher/VoucherDetailsCard'
import CustomerInformation from 'src/components/CustomerInformation'

// style
import global from 'styles/global'
import style from './styles'

const OrderHistoryDetails = ({
  route,
  navigation,
}: OrderHistoryDetailsProps) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { customer, dispatch_vouchers, create_date, id } =
    route.params?.data || {}
  return (
    <View>
      {customer && (
        <CustomerInformation
          customerName={customer?.name}
          id={customer?.id}
          mobile={customer?.mobile}
          address={customer?.address}
          createDate={
            create_date && utcToLocalTime(create_date, customerCreateDateFormat)
          }
          style={[
            global.bgWhite,
            global.hPaddingTwenty,
            global.vPaddingSixteen,
            style.customerInformation,
          ]}
        />
      )}
      {dispatch_vouchers?.length > 0 && (
        <ScrollView>
          {dispatch_vouchers.map(orders => (
            <OrderDetailsVoucher
              key={orders.id}
              voucherData={orders}
              orderId={id}
              navigation={navigation}
            />
          ))}
        </ScrollView>
      )}
    </View>
  )
}

export default OrderHistoryDetails
