import React, { useEffect, useState } from 'react'
import {
  View,
  Text,
  Image,
  Pressable,
  ActivityIndicator,
  FlatList,
} from 'react-native'
import { Box, NativeBaseProvider } from 'native-base'
import { useDispatch, useSelector } from 'react-redux'
import tw from 'tailwind-react-native-classnames'
import { AxiosError } from 'axios'

import STRINGS from 'constants/strings'
import { get, URL } from 'src/utilities/axios'
import { ApplicationState } from 'src/redux/types'
import { fetchProcGRNList, resetGRNList } from 'src/redux/actions'
import { ProcGRNListProps } from 'src/navigations/types'
import { GRN } from 'src/modals'
import {
  getEndTruncatedString,
  showToastNotification,
  utcToLocalTime,
} from 'src/utilities/helpers'
import { voucherDateFormat } from 'src/config/voucher'
import { DOWNLOAD } from 'src/config/download'
import { FilterPayloadProps } from 'types/filter'

// components
import HorizontalScrollableList, {
  FilterType,
} from 'src/components/filters/HorizontalScrollableList'
import CustomerInformation from 'src/components/CustomerInformation'
import VoucherCard from 'src/components/voucher/VoucherCard'
import Label from 'src/components/card/Label'
import VoucherMetadata from 'src/components/voucher/VoucherMetadata'
import { Sort } from 'src/components/drawers'
import ToastNotification from 'src/components/ToastNotification'
import SearchBox from 'src/components/SearchBox'
import FilterEngine, {
  initialFilter,
} from 'src/components/drawers/FilterEngine'

// style
import global from 'styles/global'
import colors from 'styles/colors'
import style from './style'

interface ProcurementReducerType {
  grnCount: number | null
  grnList: Array<GRN>
}

const ProcGRNList = ({ navigation }: ProcGRNListProps) => {
  const { grnCount, grnList }: ProcurementReducerType = useSelector(
    (state: ApplicationState) => state.grnReducer,
  )
  const dispatch = useDispatch()
  const [filterList, setFilterList] = useState<Array<FilterType>>([])
  const [searchInput, setSearchInput] = useState<string>('')
  const [listOffset, setListOffset] = useState<number>(0)
  const [node, setNode] = useState<string | null>()
  const [showLoader, setShowLoader] = useState<boolean>(true)
  const [sortDrawer, showSortDrawer] = useState<boolean>(false)
  const [sort, selectSort] = useState<string | null>()
  const [showFilter, setShowFilter] = useState<boolean>(false)
  const [appliedFilter, setAppliedFilter] =
    useState<FilterPayloadProps>(initialFilter)
  const [isFilterApplied, setIsFilterApplied] = useState<boolean>(false)
  const listFetchLimit: number = 10

  const sortConfig = {
    items: [
      {
        id: 'scheduled_date',
        name: STRINGS.SORT.DELIVERY_DATE,
      },
      {
        id: 'create_date',
        name: STRINGS.SORT.CREATED_DATE,
      },
    ],
  }

  useEffect(() => {
    return () => {
      dispatch(resetGRNList())
    }
  }, [dispatch])

  useEffect(() => {
    if (grnList?.length > 0) setShowLoader(false)
  }, [grnList])

  useEffect(() => {
    get(URL.NODE_LIST)
      .then((res: any) => {
        res?.data.map((item: FilterType) => {
          item.isSelected = false
          return item
        })
        res.data.splice(0, 0, { id: 0, name: 'All', isSelected: true })
        setFilterList(res.data)
      })
      .catch((err: AxiosError) => {
        showToastNotification(DOWNLOAD.ERROR, err.response?.data.error)
      })
  }, [])

  useEffect(() => {
    dispatch(
      fetchProcGRNList({
        limit: listFetchLimit,
        offset: listOffset,
        query: searchInput.length > 3 ? searchInput : undefined,
        node,
        sort_by: sort ? sort.split('/')[0] : undefined,
        order: sort ? sort.split('/')[1] : undefined,
        ...(isFilterApplied ? appliedFilter?.apiFilterPayload : {}),
      }),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listOffset])

  const loadNextPage = () => {
    if (grnList?.length !== grnCount && !showLoader) {
      setListOffset(listOffset + listFetchLimit)
    }
  }

  const fetchGRNByText = (text: string) => {
    setSearchInput(text)
    if (text.length >= 3) {
      dispatch(resetGRNList())
      if (listOffset !== 0) {
        setListOffset(0)
        return
      }
      dispatch(
        fetchProcGRNList({
          limit: listFetchLimit,
          offset: listOffset,
          query: text.length > 3 ? text : undefined,
          node,
          sort_by: sort ? sort.split('/')[0] : undefined,
          order: sort ? sort.split('/')[1] : undefined,
          ...(isFilterApplied ? appliedFilter?.apiFilterPayload : {}),
        }),
      )
    }
  }

  const applyFilter = (selectedFilter: FilterPayloadProps) => {
    setIsFilterApplied(true)
    dispatch(resetGRNList())
    setAppliedFilter(selectedFilter)
    setShowFilter(false)
    if (listOffset !== 0) {
      setListOffset(0)
      return
    }
    dispatch(
      fetchProcGRNList({
        limit: listFetchLimit,
        offset: listOffset,
        query: searchInput.length > 3 ? searchInput : undefined,
        node,
        sort_by: sort ? sort.split('/')[0] : undefined,
        order: sort ? sort.split('/')[1] : undefined,
        ...selectedFilter?.apiFilterPayload,
      }),
    )
  }

  const selectFilter = (filter: FilterType) => {
    const selectedIndex = filterList.findIndex(
      (item: FilterType) => item.id === filter.id,
    )
    const copyOfFilterList: Array<FilterType> = [...filterList]
    if (copyOfFilterList[selectedIndex].isSelected) {
      return
    }
    copyOfFilterList.map((item: FilterType) => {
      item.isSelected = false
      return item
    })
    copyOfFilterList[selectedIndex].isSelected = true
    setFilterList(copyOfFilterList)
    setNode(filter.name)
    dispatch(resetGRNList())
    if (listOffset === 0) {
      dispatch(
        fetchProcGRNList({
          limit: listFetchLimit,
          offset: listOffset,
          query: searchInput.length > 3 ? searchInput : undefined,
          node: filter.name === 'All' ? undefined : filter.name,
          sort_by: sort ? sort.split('/')[0] : undefined,
          order: sort ? sort.split('/')[1] : undefined,
          ...(isFilterApplied ? appliedFilter?.apiFilterPayload : {}),
        }),
      )
      return
    }
    setListOffset(0)
  }

  const applySort = (selectedValue: string, sortBy: string, order: string) => {
    selectSort(selectedValue)
    dispatch(resetGRNList())
    if (listOffset === 0) {
      dispatch(
        fetchProcGRNList({
          limit: listFetchLimit,
          offset: listOffset,
          query: searchInput.length > 3 ? searchInput : undefined,
          node,
          sort_by: sortBy,
          order,
          ...(isFilterApplied ? appliedFilter?.apiFilterPayload : {}),
        }),
      )
      return
    }
    setListOffset(0)
  }

  const renderItem = ({ item }: { item: GRN }) => (
    <Pressable
      onPress={() => navigation.navigate('ProcGRNDetails', { id: item.id })}
      style={tw`my-2 mx-5`}
    >
      <VoucherCard
        header={
          <Label
            image={require('images/id-black.png')}
            text={item.origin}
            containerStyle={[global.bgMint, style.label]}
            textStyle={[
              tw`text-xs`,
              global.textPrimaryBlack,
              global.textMedium,
              global.hPaddingFive,
            ]}
            imageStyle={tw`w-3 h-3`}
          />
        }
        body={
          <CustomerInformation
            customerName={item.partner_id[1].toString() || 'N/A'}
            address={item.node}
            style={tw`pt-2.5 px-5`}
            product={
              item?.product_id?.length ? item?.product_id[1].toString() : 'N/A'
            }
            quantity={
              item.product_uom
                ? `(${item.product_uom[1]}${item.product_uom[0]})`
                : undefined
            }
            productImage={require('images/product.png')}
            addressIconStyle={tw`w-3.5 h-3.5`}
            addressTextStyle={tw`text-sm`}
            qcBy={item.qc_by || 'N/A'}
            qcImage={require('images/qc-blue.png')}
          />
        }
        footer={
          <VoucherMetadata
            id={item.id}
            attributes={[
              {
                attrName: STRINGS.PURCHASE_ORDER.DELIVERY_DATE,
                attrValue: item.date_planned
                  ? utcToLocalTime(item.date_planned, voucherDateFormat)
                  : getEndTruncatedString(
                      STRINGS.MISCELLANEOUS.DATE_NOTE_AVAILABLE,
                    ),
                icon: require('images/calendar.png'),
                iconStyle: tw`w-7 h-7`,
                attrStyle: tw`text-sm`,
                attrContainerStyle: global.flexFive,
              },
              {
                attrName: STRINGS.PURCHASE_ORDER.TOTAL_AMOUNT,
                attrValue: Intl.NumberFormat('en-IN', {
                  maximumFractionDigits: 0,
                  minimumFractionDigits: 0,
                }).format(item.total_amt),
                icon: require('images/currency.png'),
                iconStyle: tw`w-7 h-7`,
                attrStyle: tw`text-sm`,
                attrContainerStyle: global.flexFive,
              },
            ]}
            containerStyle={[global.bgNone, tw`pt-5 px-4`]}
          />
        }
      />
    </Pressable>
  )

  return (
    <NativeBaseProvider>
      <Box
        flex={1}
        safeAreaTop
        style={(sortDrawer || showFilter) && global.drawerBackground}
      >
        <ToastNotification />
        <View style={tw`p-5 flex-row items-center justify-between`}>
          <SearchBox
            placeholder={STRINGS.PURCHASE_ORDER.SEARCH_BY_VENDOR}
            searchInput={searchInput}
            onchangeFunc={fetchGRNByText}
          />
          <Pressable
            style={tw`ml-5`}
            onPress={() => {
              setShowFilter(true)
            }}
          >
            <Image
              source={require('images/filter-orange.png')}
              style={tw`w-5 h-5`}
            />
          </Pressable>
        </View>
        <View>
          {filterList.length > 1 && (
            <HorizontalScrollableList
              filterList={filterList}
              setFilter={selectFilter}
            />
          )}
          <View
            style={tw`pb-3 pt-5 px-5 flex-row justify-between items-center`}
          >
            {grnCount ? (
              <Text
                style={[tw`text-xs`, global.textColorGrey, global.textRegular]}
              >
                {`${STRINGS.INWARD_GRN.PENDING_GRNS}: ${grnCount}`}
              </Text>
            ) : (
              <></>
            )}
            <Pressable
              style={tw`flex-row justify-between items-center`}
              onPress={() => showSortDrawer(true)}
            >
              <Image
                source={require('images/exchange-orange.png')}
                style={tw`w-3.5 h-3.5`}
              />
              <Text
                style={[
                  tw`text-xs pl-1.5`,
                  global.textMedium,
                  global.textSecondaryOrange,
                ]}
              >
                {STRINGS.MISCELLANEOUS.SORT_BY}
              </Text>
            </Pressable>
          </View>
        </View>
        <FlatList
          keyExtractor={item => `${item.id.toString()}procGRN`}
          renderItem={renderItem}
          data={grnList}
          onEndReached={loadNextPage}
          onEndReachedThreshold={0.2}
          ListEmptyComponent={
            <ActivityIndicator size="large" color={colors.primaryGreen} />
          }
        />
        {showLoader && grnList?.length > 0 && (
          <ActivityIndicator size="large" color={colors.primaryGreen} />
        )}
      </Box>
      {showFilter && (
        <FilterEngine
          title={STRINGS.MISCELLANEOUS.FILTERS}
          handleClose={() => setShowFilter(false)}
          getSelectedFilters={(selectedFilters: any) =>
            applyFilter(selectedFilters)
          }
          selectedFilters={appliedFilter}
          apiGetCount={`${URL.PROC_GRN_LIST}?query=${
            searchInput.length > 3 ? searchInput : ''
          }&node=${node === undefined ? '' : node}&sort_by=${
            sort ? sort.split('/')[0] : ''
          }&order=${sort ? sort.split('/')[1] : ''}`}
          apiGetCountEndPoint="total_count"
          filterConfig={[
            {
              filterList: [
                {
                  displayName: `${STRINGS.FILTER.BEFORE_TODAY}`,
                  id: 'before_today',
                },
                { displayName: `${STRINGS.FILTER.TODAY}`, id: 'today' },
                { displayName: `${STRINGS.FILTER.TOMORROW}`, id: 'tomorrow' },
                { displayName: `${STRINGS.FILTER.NEXT_7_DAYS}`, id: 'week' },
                {
                  displayName: `${STRINGS.FILTER.CUSTOM_DATE}`,
                  id: 'calendar',
                  type: 'calendar',
                  dateFormat: '',
                  buttonDateFormat: '',
                },
              ],
              id: 'date_planned',
              title: `${STRINGS.FILTER.DELIVERY_DATE}`,
              filterPayloadType: 'array',
            },
            {
              filterList: [
                { displayName: `${STRINGS.FILTER.BY_DEHAAT}`, id: 'dehaat' },
                {
                  displayName: `${STRINGS.FILTER.BY_SUPPLIER}`,
                  id: 'supplier',
                },
              ],
              title: `${STRINGS.FILTER.QC_RESPONSIBILITY}`,
              id: 'qc_by',
            },
            {
              filterList: [
                { displayName: `${STRINGS.FILTER.YOURSELF}`, id: 'yourself' },
                { displayName: `${STRINGS.FILTER.MIS_TEAM}`, id: 'others' },
              ],
              title: `${STRINGS.FILTER.PO_CREATED_BY}`,
              id: 'created_by',
            },
          ]}
        />
      )}
      {sortDrawer && (
        <Sort
          items={sortConfig.items}
          closeDrawer={showSortDrawer}
          applySort={applySort}
          selectedValue={sort}
        />
      )}
    </NativeBaseProvider>
  )
}

export default ProcGRNList
