import { StyleSheet } from 'react-native'
import colors from 'styles/colors'

const styles = StyleSheet.create({
  bottomBorder: {
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(0, 0, 0, 0.05)',
  },
  paddingTopThree: {
    paddingTop: 3,
  },
  verticalIcon: {
    height: 20,
    width: 10,
  },
  warning: {
    width: 50,
    height: 44,
  },
  btnContainer: {
    borderTopWidth: 1,
    borderTopColor: colors.lightGrey,
  },
  btnYesCover: {
    borderWidth: 0,
    borderColor: colors.lightGrey,
  },
  btnNoCover: {
    borderWidth: 0,
    borderLeftWidth: 1,
    borderColor: colors.lightGrey,
  },
  paddingTopForty: {
    paddingTop: 40,
  },
  paddingBottomForty: {
    paddingBottom: 40,
  },
})

export default styles
