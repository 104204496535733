import { NodeProps, VendorProps } from 'types/procurement'
import { VendorAction } from '../types/vendor'

type VendorState = {
  addVendor: {
    hasFresh: boolean
    hasDry: boolean
    isGSTRegistered: boolean | null
    vendor: VendorProps | null
    node: NodeProps | null
  }
}

const initialState = {
  addVendor: {
    hasFresh: false,
    hasDry: false,
    isGSTRegistered: null,
    vendor: null,
    node: null,
  },
}

const vendorReducer = (
  state: VendorState = initialState,
  action: VendorAction,
) => {
  switch (action.type) {
    case 'SET_ADD_VENDOR_FIELD': {
      return {
        ...state,
        addVendor: {
          ...state.addVendor,
          [action.payload.fieldName]: action.payload.fieldValue,
        },
      }
    }
    case 'RESET_ADD_VENDOR_STATE': {
      return {
        ...state,
        addVendor: initialState.addVendor,
      }
    }
    default:
      return state
  }
}

export { vendorReducer }
