import React from 'react'
import { View, Text, Image, ScrollView } from 'react-native'
import { NativeBaseProvider, Box, HStack } from 'native-base'
import ReadMore from 'react-native-read-more-text'
import { useSelector } from 'react-redux'
import tw from 'tailwind-react-native-classnames'

// components
import Button from 'src/components/form-fields/Button'
import DetailItemCard from 'src/components/voucher/DetailItemCard'

// utilities
import { formatterWithoutDecimals } from 'src/utilities/helpers'
import { get, URL } from 'src/utilities/axios'

import { RescheduleProps } from 'src/navigations/types'
import { ApplicationState } from 'src/redux/types'

import STRINGS from 'constants/strings'
import global from 'styles/global'

// TODO: Remove naviation if not required
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Reschedule = ({ navigation }: RescheduleProps) => {
  const { grnDetails } = useSelector(
    (state: ApplicationState) => state.grnReducer,
  )
  const pendingGrnDetails =
    grnDetails.items !== undefined && grnDetails?.items[0]

  const renderTruncatedFooter = (handlePress: any) => {
    return (
      <Text
        style={[global.textPrimaryGreen, tw`text-xs mb-2.5`]}
        onPress={handlePress}
      >
        {STRINGS.BUTTON.READ_MORE}
      </Text>
    )
  }

  const renderRevealedFooter = (handlePress: any) => {
    return (
      <Text
        style={[global.textPrimaryGreen, tw`text-xs mb-2.5`]}
        onPress={handlePress}
      >
        {STRINGS.BUTTON.READ_LESS}
      </Text>
    )
  }

  const rescheduleGRN = async () => {
    try {
      await get(
        `${URL.VALIDATE_GRN}/${grnDetails.more_details?.id}/reschedule`,
      ).then(data => {
        if (data) {
          console.warn('Success screen needs to be integrate')
          // Navigate to success screen
        } else {
          console.error('Something wrong with Reschedule GRN API')
        }
      })
    } catch (e) {
      console.error(`Reschedule GRN API failed to execute:${e}`)
    }
  }

  const cancelGRN = async () => {
    try {
      await get(
        `${URL.VALIDATE_GRN}/${grnDetails.more_details?.id}/cancel`,
      ).then(data => {
        if (data) {
          console.warn('Success screen needs to be integrate')
          // Navigate to success screen
        } else {
          console.error('Something wrong with Cancel GRN API')
        }
      })
    } catch (e) {
      console.error('Cancel GRN API failed to execute')
    }
  }

  return pendingGrnDetails ? (
    <NativeBaseProvider>
      <Box flex={1} safeAreaTop style={tw`bg-white`}>
        <View style={[tw`flex-1`, global.zeroHeight]}>
          <View style={tw`m-5 mt-8`}>
            <Text style={[global.textColorGrey, tw`text-base font-medium`]}>
              {STRINGS.GRNS.RESCHEDULE_HEADER_MSG}
            </Text>
          </View>
          <ScrollView>
            {pendingGrnDetails?.product_details?.map((grn, index) => (
              <DetailItemCard
                // TODO: Need to provide reason for why index key or else fix it
                // eslint-disable-next-line react/no-array-index-key
                key={`${grn.id}__${index}`}
                name={grn.name}
                imageS3Key={grn.product_image}
                customUOM={`${grn.product_uom[0]} ${grn.product_uom[1]} `}
                tax={grn?.tax_details?.display_name}
                detailColumns={[
                  {
                    key: STRINGS.GRNS.UNIT_PRICE,
                    value: `₹${grn?.price_unit}`,
                  },
                  {
                    key: STRINGS.GRNS.DEMAND,
                    value: grn?.ordered_qty,
                  },
                ]}
                editableField={{
                  key: STRINGS.GRNS.RECEIVED,
                  value: grn?.quantity_done,
                }}
                totalAmount={formatterWithoutDecimals.format(
                  grn?.quantity_done * grn?.price_unit || 0,
                )}
              />
            ))}
          </ScrollView>
        </View>
        <HStack safeAreaBottom>
          <View style={tw`p-5 w-full bg-white`}>
            <View
              style={[tw`border-t border-solid pt-8`, global.borderColorGrey]}
            >
              <View style={tw`mb-8`}>
                <View>
                  <ReadMore
                    numberOfLines={1}
                    renderTruncatedFooter={renderTruncatedFooter}
                    renderRevealedFooter={renderRevealedFooter}
                  >
                    <Image
                      source={require('images/info-icon.png')}
                      style={tw`w-3 h-3`}
                    />
                    <Text style={[global.textSecondaryBlue, tw`text-xs`]}>
                      {`  ${STRINGS.GRNS.RESCHEDULE_READ_MORE}`}
                    </Text>
                  </ReadMore>
                </View>
                <Button
                  title={STRINGS.BUTTON.RESCHEDULE}
                  onTap={rescheduleGRN}
                  style={tw`rounded-lg h-11`}
                  textStyle={tw`text-sm`}
                />
              </View>
              <View>
                <View>
                  <ReadMore
                    numberOfLines={1}
                    renderTruncatedFooter={renderTruncatedFooter}
                    renderRevealedFooter={renderRevealedFooter}
                  >
                    <Image
                      source={require('images/info-icon.png')}
                      style={tw`w-3 h-3`}
                    />
                    <Text style={[global.textSecondaryBlue, tw`text-xs`]}>
                      {`  ${STRINGS.GRNS.CANCEL_RESCHEDULE_READ_MORE}`}
                    </Text>
                  </ReadMore>
                </View>
                <Button
                  title={STRINGS.BUTTON.CANCEL_NO_RESCHEDULE}
                  onTap={cancelGRN}
                  style={tw`rounded-lg h-11`}
                  textStyle={tw`text-sm`}
                />
              </View>
            </View>
          </View>
        </HStack>
      </Box>
    </NativeBaseProvider>
  ) : null
}

export default Reschedule
