import './rn-addons';

import AppLoading from 'expo-app-loading';
import { useFonts } from 'expo-font';

import { withKnobs } from '@storybook/addon-knobs';
import { addDecorator, configure, getStorybookUI } from '@storybook/react-native';

import { loadStories } from './storyLoader';

// enables knobs for all stories
addDecorator(withKnobs)

// import stories
configure(
  () => {
    loadStories()
  },
  {
    ...module,
    webpackFinal: async config => {
      config.resolve.alias = {
        ...config.resolve.alias,
        src: '../src',
        constants: '../constants',
        images: '../images',
        styles: '../styles',
        types: '../types',
      }
      return config
    },
  },
)

// Refer to https://github.com/storybookjs/react-native/tree/master/app/react-native#getstorybookui-options
// To find allowed options for getStorybookUI
const StorybookUIRoot = getStorybookUI({ asyncStorage: null })

const LinkStorybookUIRoot = () => {
  let [fontsLoaded] = useFonts({
    'roboto-black': require('../assets/fonts/Roboto-Black.ttf'),
    'roboto-bold': require('../assets/fonts/Roboto-Bold.ttf'),
    'roboto-light': require('../assets/fonts/Roboto-Light.ttf'),
    'roboto-medium': require('../assets/fonts/Roboto-Medium.ttf'),
    'roboto-regular': require('../assets/fonts/Roboto-Regular.ttf'),
    'roboto-thin': require('../assets/fonts/Roboto-Thin.ttf'),
  })

  if (!fontsLoaded) {
    return <AppLoading />
  }
  return <StorybookUIRoot />
}

export default LinkStorybookUIRoot
