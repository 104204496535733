import React from 'react'
import { View, Text } from 'react-native'
import tw from 'tailwind-react-native-classnames'

// style
import global from 'styles/global'

interface FirstNumberBoldProps {
  first: number
  second: number
  separator: string
}

const FirstNumberBold = ({
  first,
  second,
  separator,
}: FirstNumberBoldProps) => (
  <View style={tw`flex-row`}>
    <Text style={global.textBold}>
      {first < 10 && '0'}
      {first}
      {separator}
    </Text>
    <Text style={global.textRegular}>
      {second < 10 && '0'}
      {second}
    </Text>
  </View>
)

export default FirstNumberBold
