import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  label: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    width: 125,
    marginTop: 18,
  },
  searchBar: {
    width: '88.7%',
  },
})

export default styles
