import { AppActions } from '../types/app'

type AppState = {
  isModalOpen: boolean
}

const initialState = {
  isModalOpen: false,
}

const appReducer = (state: AppState = initialState, action: AppActions) => {
  switch (action.type) {
    case 'TOGGLE_BACK_MODAL': {
      return {
        ...state,
        isModalOpen: !state.isModalOpen,
      }
    }
    default:
      return state
  }
}

export { appReducer }
