import React from 'react'
import { View, Image, Pressable, Text } from 'react-native'

import STRINGS from 'constants/strings'

// styles
import global from 'styles/global'
import style from './style'

interface EditDeleteProps {
  closeDrawer: Function
  onEdit: Function
  onDelete: Function
}

/**
 * EditDelete is drawer which require function closeDrawer to close
 * drawer and onEdit, onDelete to perform actions
 */

const EditDelete = ({ closeDrawer, onEdit, onDelete }: EditDeleteProps) => (
  <View style={global.drawer}>
    <View style={global.alignItemsRight}>
      <Pressable
        onPress={() => closeDrawer(false)}
        style={global.paddingSixteen}
      >
        <Image source={require('images/cancel.png')} style={style.iconCancel} />
      </Pressable>
    </View>
    <Pressable
      onPress={() => {
        onEdit()
        closeDrawer(false)
      }}
      style={global.widthFull}
    >
      <Text
        style={[
          global.fontFourteen,
          global.textRegular,
          global.textColorGrey,
          global.textAlignCenter,
          global.paddingSixteen,
          style.drawerButtonBorder,
        ]}
      >
        {STRINGS.BUTTON.EDIT}
      </Text>
    </Pressable>
    <Pressable
      onPress={() => {
        onDelete()
        closeDrawer(false)
      }}
      style={global.widthFull}
    >
      <Text
        style={[
          global.fontFourteen,
          global.textRegular,
          global.textRed,
          global.textAlignCenter,
          global.paddingSixteen,
          style.drawerButtonBorder,
        ]}
      >
        {STRINGS.BUTTON.DELETE}
      </Text>
    </Pressable>
    <Pressable onPress={() => closeDrawer(false)}>
      <Text
        style={[
          global.fontFourteen,
          global.textRegular,
          global.textColorGrey,
          global.textAlignCenter,
          global.paddingSixteen,
        ]}
      >
        {STRINGS.BUTTON.CANCEL}
      </Text>
    </Pressable>
  </View>
)

export default EditDelete
