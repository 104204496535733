import React, { useState, useEffect } from 'react'
import { View, Text, Pressable, Image } from 'react-native'
import {
  Box,
  HStack,
  NativeBaseProvider,
  Center,
  ScrollView,
} from 'native-base'
import { useDispatch, useSelector } from 'react-redux'
import tw from 'tailwind-react-native-classnames'
import CalendarPicker from 'react-native-calendar-picker'
import RadioForm, {
  RadioButton,
  RadioButtonInput,
  RadioButtonLabel,
} from 'react-native-simple-radio-button'
import moment, { Moment } from 'moment'
import { AxiosError, AxiosResponse } from 'axios'

import STRINGS from 'constants/strings'
import { ApplicationState } from 'src/redux/types'
import { CreatePOTabProps, Location as LocationModal } from 'types/procurement'
import { get, URL } from 'src/utilities/axios'
import { store } from 'src/redux/store'
import { formatMoment, showToastNotification } from 'src/utilities/helpers'
import { DOWNLOAD } from 'src/config/download'
import {
  setDraftPODetails,
  // commented code for Release v0.9.1
  // toggleBackDraftPOModal,
} from 'src/redux/actions/purchase-order'

// components
import Button from 'src/components/form-fields/Button'
import InputTextField from 'src/components/form-fields/InputTextField'
import { SearchOptions } from 'src/components/drawers'
import { SearchOptionsProps } from 'src/components/drawers/SearchOptions'
import Info from 'src/components/drawers/Info'

// styles
import global from 'styles/global'
import colors from 'styles/colors'

const { CREATE_PO } = STRINGS

const Locations = (props: { props: CreatePOTabProps }) => {
  const dispatch = useDispatch()
  const procStore = useSelector(
    (state: ApplicationState) => state.procurement.createPO,
  )
  // TODO: Destructure props to { props }
  // eslint-disable-next-line react/destructuring-assignment
  const { navigation } = props?.props
  const locations = [
    { label: 'DeHaat Warehouse', value: 'DeHaat Warehouse' },
    { label: 'Customer Location', value: 'Customer Location' },
  ]
  const [deliverLocation, SetDeliveryLocation] = useState<string | null>(
    procStore?.deliveryLocationType || null,
  )
  const [showCalender, setShowCalender] = useState<boolean>(false)
  const [dateType, setDateType] = useState<string>('')
  const [showLocationsDrawer, setShowLocationsDrawer] = useState<boolean>(false)
  const [searchOptionsProps, setSearchOptionsProps] = useState<any>({})
  const [showAddress, setShowAddress] = useState<boolean>(false)
  const [addressType, setAddressType] = useState<string>('')
  const searchKeyword = 'a'

  const leftHeader = (text: string) => (
    <View style={tw`flex-row items-center`}>
      <Text style={[global.textColorGrey, global.textRegular, tw`text-xs`]}>
        {text}
      </Text>
      <Text style={global.textRed}>*</Text>
    </View>
  )

  const viewFullAddress = (key: string) => {
    setShowAddress(true)
    setAddressType(key)
  }

  const rightHeader = (key: string) => (
    <Pressable onPress={() => viewFullAddress(key)}>
      <Text
        style={[
          global.textRegular,
          global.textPrimaryGreen,
          tw`text-xs underline`,
        ]}
      >
        {STRINGS.BUTTON.VIEW_FULL_ADDRESS}
      </Text>
    </Pressable>
  )

  const onDateChange = (date: Moment) => {
    setShowCalender(false)
    dispatch({
      type: 'SET_CREATE_PO_FIELD',
      payload: {
        ...procStore,
        [dateType]: date.format('DD/MM/YYYY').toString(),
      },
    })
  }

  const selectDate = (type: string) => {
    setDateType(type)
    setShowCalender(true)
  }

  const customText = (item: LocationModal) => (
    <View>
      <Text style={[global.textMedium, tw`text-xs`]}>{item.name}</Text>
      <Text
        style={[global.textColorGrey, global.textRegular, tw`text-xs mt-1`]}
        numberOfLines={1}
        ellipsizeMode="tail"
      >
        {item.address}
      </Text>
    </View>
  )

  useEffect(() => {
    if (procStore?.vendor?.id)
      get(URL.LOCATIONS, { type: 'vendor', vendor_id: procStore.vendor.id })
        .then((res: AxiosResponse) => {
          const proc = store.getState().procurement.createPO
          dispatch({
            type: 'SET_CREATE_PO_FIELD',
            payload: { ...proc, pickupLocation: res?.data[0] },
          })
        })
        .catch((err: AxiosError) => {
          showToastNotification(DOWNLOAD.ERROR, err.response?.data.error)
        })
    // TODO: Provide an explanation for why all deps are not included or fix it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [procStore.vendor?.id])

  const getActionPayload = (response: Location[]) => {
    const customerWarehouseLocation = response?.filter(
      (item: any) => item.is_virtual && item.default,
    )
    let actionPayload = {}
    if (customerWarehouseLocation?.length > 0)
      actionPayload = {
        customerWarehouseLocation: customerWarehouseLocation[0],
      }
    if (response?.length === 1)
      actionPayload = {
        ...actionPayload,
        dehaatWarehouseLocation: response[0],
      }
    return actionPayload
  }

  useEffect(() => {
    if (procStore.node?.id)
      get(URL.LOCATIONS, { type: 'warehouse', node_id: procStore.node?.id })
        .then((res: AxiosResponse) => {
          if (res?.data) {
            const proc = store.getState().procurement.createPO
            const actionPayload = getActionPayload(res.data)
            dispatch({
              type: 'SET_CREATE_PO_FIELD',
              payload: {
                ...proc,
                ...actionPayload,
                pickup: moment().format('DD/MM/YYYY').toString(),
              },
            })
          }
        })
        .catch((err: AxiosError) => {
          showToastNotification(DOWNLOAD.ERROR, err.response?.data.error)
        })
    // TODO: Provide an explanation for why all deps are not included or fix it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [procStore.node?.id])

  useEffect(() => {
    get(URL.LOCATIONS, { type: 'customer', search_key: searchKeyword })
      .then((res: AxiosResponse) => {
        if (res?.data?.addresses?.length === 1) {
          const proc = store.getState().procurement.createPO
          dispatch({
            type: 'SET_CREATE_PO_FIELD',
            payload: {
              ...proc,
              customerDeliveryAddress: res?.data?.addresses[0],
            },
          })
        }
      })
      .catch((err: AxiosError) => {
        showToastNotification(DOWNLOAD.ERROR, err.response?.data.error)
      })
    // TODO: Provide an explanation for why all deps are not included or fix it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKeyword])

  useEffect(() => {
    dispatch({
      type: 'SET_CREATE_PO_FIELD',
      payload: {
        ...procStore,
        deliveryLocationType: deliverLocation,
      },
    })
    // TODO: Provide an explanation for why all deps are not included or fix it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliverLocation])

  const onSearchDrawerClose = (item: any, key: string) => {
    setShowLocationsDrawer(false)
    dispatch({
      type: 'SET_CREATE_PO_FIELD',
      payload: { ...procStore, [key]: item },
    })
  }

  const getSelectOptionsProps = (options: any) => ({
    title: options.title,
    searchPlaceholder: options.searchPlaceholder,
    onLoadValue: procStore[options.key],
    closeDrawer: (item: any) => onSearchDrawerClose(item, options.key),
    keyDisplayName: options.keyDisplayName,
    searchKeys: options.searchKeys,
    itemListOnLoad: options.productList,
    apiToLoadOption: options.apiToLoadOption,
    hideSearchBoxItemLimit: options.hideSearchBoxItemLimit,
    errorMessage: options.errorMessage,
    localItemList: options.localItemList,
    apiResultEndPoint: options.apiResultEndPoint,
    renderCustomItem: options.renderCustomItem,
  })

  const customerLocationDrawerOptions = {
    title: CREATE_PO.SELECT_CUST_DELI_LOC,
    searchPlaceholder: CREATE_PO.SEARCH_LOC_PLACEHOLDER,
    keyDisplayName: 'address',
    searchKeys: 'search_key',
    apiToLoadOption: `${URL.LOCATIONS}?type=customer&`,
    apiResultEndPoint: 'addresses',
    onLoadValue: procStore?.customerDeliveryAddress,
    key: 'customerDeliveryAddress',
    renderCustomItem: customText,
  }

  const vendorLocationDrawerOptions = {
    title: CREATE_PO.SELECT_PICKUP_LOCATION,
    searchPlaceholder: CREATE_PO.SEARCH_LOC_PLACEHOLDER,
    keyDisplayName: 'name',
    searchKeys: 'search_key',
    apiToLoadOption: `${URL.LOCATIONS}?type=vendor&vendor_id=${procStore?.vendor?.id}&`,
    onLoadValue: procStore.pickupLocation,
    key: 'pickupLocation',
    renderCustomItem: customText,
  }

  const warehouseLocationDrawerOptions = {
    title:
      deliverLocation === locations[1].value
        ? CREATE_PO.SELECT_DEHAAT_WH_LOC_GRN
        : CREATE_PO.SELECT_DEHAAT_WH_LOC,
    searchPlaceholder: CREATE_PO.SEARCH_LOC_PLACEHOLDER,
    keyDisplayName: 'name',
    searchKeys: 'search_key',
    apiToLoadOption: `${URL.LOCATIONS}?type=warehouse&node_id=${procStore?.node?.id}&`,
    onLoadValue:
      deliverLocation === locations[1].value
        ? procStore.dehaatWarehouseLocation
        : procStore.customerWarehouseLocation,
    key:
      deliverLocation === locations[1].value
        ? 'customerWarehouseLocation'
        : 'dehaatWarehouseLocation',
  }

  const showDrawer = (drawerProps: SearchOptionsProps) => {
    setShowLocationsDrawer(true)
    setSearchOptionsProps(drawerProps)
  }

  useEffect(() => {
    let locationObject: any = {}

    if (
      deliverLocation === locations[0].value &&
      procStore.dehaatWarehouseLocation?.id &&
      procStore.delivery
    ) {
      locationObject.delivery_address = undefined
      locationObject.delivery_warehouse = procStore.dehaatWarehouseLocation?.id
      locationObject.delivery_date = formatMoment(
        procStore?.delivery,
        'YYYY-MM-DD',
      )
    } else if (
      deliverLocation === locations[1].value &&
      procStore.customerDeliveryAddress?.id &&
      procStore.customerWarehouseLocation?.id &&
      procStore.delivery
    ) {
      locationObject.delivery_address = procStore.customerDeliveryAddress?.id
      locationObject.delivery_warehouse =
        procStore.customerWarehouseLocation?.id
      locationObject.delivery_date = formatMoment(
        procStore?.delivery,
        'YYYY-MM-DD',
      )
    } else {
      locationObject = {
        pickup_address: procStore.pickupLocation?.id,
        pickup_date:
          procStore?.pickup && formatMoment(procStore?.pickup, 'YYYY-MM-DD'),
        delivery_warehouse: undefined,
        delivery_date: undefined,
        delivery_address: undefined,
      }
    }

    dispatch(setDraftPODetails(locationObject))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    procStore?.customerWarehouseLocation?.id,
    procStore?.pickupLocation?.id,
    procStore?.pickup,
    procStore?.delivery,
    procStore?.customerDeliveryAddress?.id,
    procStore?.dehaatWarehouseLocation?.id,
    procStore?.deliveryLocationType,
  ])

  const isValidToSubmit = () => {
    if (deliverLocation === locations[1].value) {
      return (
        procStore?.customerWarehouseLocation?.id &&
        procStore?.pickupLocation?.id &&
        procStore?.pickup &&
        procStore?.delivery &&
        procStore?.customerDeliveryAddress?.id
      )
    }
    if (deliverLocation === locations[0].value) {
      return (
        procStore?.dehaatWarehouseLocation?.id &&
        procStore?.pickupLocation?.id &&
        procStore?.pickup &&
        procStore?.delivery
      )
    }
    return undefined
  }

  return (
    <NativeBaseProvider>
      <Box flex={1} safeAreaTop>
        <Center flex={1}>
          <ScrollView
            style={[
              (showAddress || showLocationsDrawer) && global.drawerBackground,
              tw`w-full h-full p-5`,
            ]}
            onTouchStart={() => setShowCalender(false)}
          >
            <View
              pointerEvents={
                showLocationsDrawer || showAddress ? 'none' : 'auto'
              }
            >
              <InputTextField
                leftHeader={leftHeader(CREATE_PO.PICKUP_LOCATION)}
                rightHeader={rightHeader('pickupLocation')}
                value={procStore.pickupLocation?.name}
                onClick={() =>
                  showDrawer(getSelectOptionsProps(vendorLocationDrawerOptions))
                }
                placeholder={CREATE_PO.CUSTOMER_DELIVERY_LOCATION}
                style={tw`h-11 mt-4 justify-between items-center rounded-md`}
                placeholderTextColor={colors.darkGrey}
                innerStyle={[
                  global.textColorGrey,
                  global.textMedium,
                  tw`text-sm ml-2.5`,
                ]}
                openKeypadOnTouch={false}
                inputEditable={false}
                rightIcon={
                  <Image
                    source={require('images/arrow-down-enabled.png')}
                    style={tw`mr-2.5 w-2.5 h-1.5`}
                  />
                }
              />
              <InputTextField
                leftHeader={leftHeader(CREATE_PO.PICKUP_DATE)}
                value={procStore?.pickup}
                onClick={() => selectDate('pickup')}
                style={tw`h-11 mt-4 justify-between items-center rounded-md`}
                innerStyle={[
                  global.textColorGrey,
                  global.textMedium,
                  tw`text-sm ml-2.5`,
                ]}
                openKeypadOnTouch={false}
                inputEditable={false}
                rightIcon={
                  <Image
                    source={require('images/calendar-orange.png')}
                    style={tw`mr-2.5 w-4 h-4`}
                  />
                }
                containerStyle={tw`mt-5`}
              />
            </View>
            <View
              style={tw`mt-10`}
              pointerEvents={
                showLocationsDrawer || showAddress ? 'none' : 'auto'
              }
            >
              <View style={tw`flex-row`}>
                <Text
                  style={[
                    global.textRegular,
                    global.textColorGrey,
                    tw`text-xs`,
                  ]}
                >
                  {CREATE_PO.SELECT_DELIVERY_LOCATION}
                </Text>
                <Text style={global.textRed}>*</Text>
              </View>
              <View style={tw`mt-4 mb-8`}>
                <RadioForm formHorizontal>
                  {locations.map((obj, i) => (
                    // TODO: Need to provide reason for why index key or else fix it
                    // eslint-disable-next-line react/no-array-index-key
                    <RadioButton labelHorizontal key={i}>
                      <RadioButtonInput
                        obj={obj}
                        index={i}
                        isSelected={deliverLocation === obj.value}
                        buttonInnerColor={colors.primaryGreen}
                        buttonOuterColor={
                          deliverLocation === obj.value
                            ? colors.primaryGreen
                            : colors.primaryGreen
                        }
                        buttonSize={8.3}
                        onPress={() => SetDeliveryLocation(obj.value)}
                        buttonOuterSize={16.67}
                        buttonStyle={tw`mr-2`}
                      />
                      <RadioButtonLabel
                        obj={obj}
                        index={i}
                        labelHorizontal
                        onPress={() => SetDeliveryLocation(obj.value)}
                        labelStyle={[
                          global.textColorGrey,
                          global.textMedium,
                          tw`text-sm pl-0`,
                        ]}
                        labelWrapStyle={tw`mr-6`}
                      />
                    </RadioButton>
                  ))}
                </RadioForm>
              </View>
            </View>
            {deliverLocation !== null && (
              <View pointerEvents={showAddress ? 'none' : 'auto'}>
                {deliverLocation === locations[1].value && (
                  <InputTextField
                    leftHeader={leftHeader(
                      CREATE_PO.CUSTOMER_DELIVERY_LOCATION,
                    )}
                    value={procStore.customerDeliveryAddress?.name || ''}
                    onClick={() =>
                      showDrawer(
                        getSelectOptionsProps(customerLocationDrawerOptions),
                      )
                    }
                    rightHeader={rightHeader('customerDeliveryAddress')}
                    placeholder={CREATE_PO.SELECT_CUST_DELI_LOC}
                    style={tw`h-11 mt-4 justify-between items-center rounded-md`}
                    placeholderTextColor={colors.darkGrey}
                    innerStyle={[
                      global.textColorGrey,
                      global.textMedium,
                      tw`text-sm ml-2.5`,
                    ]}
                    openKeypadOnTouch={false}
                    inputEditable={false}
                    rightIcon={
                      <Image
                        source={require('images/arrow-down-enabled.png')}
                        style={tw`mr-2.5 w-2.5 h-1.5`}
                      />
                    }
                    containerStyle={tw`mb-5`}
                  />
                )}
                <InputTextField
                  leftHeader={leftHeader(
                    deliverLocation === locations[1].value
                      ? CREATE_PO.DEHAAT_WH_LOC_GRN
                      : CREATE_PO.DEHAAT_WAREHOUSE_LOCATION,
                  )}
                  value={
                    deliverLocation === locations[1].value
                      ? procStore.customerWarehouseLocation.name
                      : procStore.dehaatWarehouseLocation.name || ''
                  }
                  onClick={() =>
                    showDrawer(
                      getSelectOptionsProps(warehouseLocationDrawerOptions),
                    )
                  }
                  placeholder={
                    deliverLocation === locations[1].value
                      ? CREATE_PO.SELECT_DEHAAT_WH_LOC
                      : CREATE_PO.SELECT_DEHAAT_WH_LOC_GRN
                  }
                  style={tw`h-11 mt-4 justify-between items-center rounded-md`}
                  placeholderTextColor={colors.darkGrey}
                  innerStyle={[
                    global.textColorGrey,
                    global.textMedium,
                    tw`text-sm ml-2.5`,
                  ]}
                  openKeypadOnTouch={false}
                  inputEditable={false}
                  rightIcon={
                    <Image
                      source={require('images/arrow-down-enabled.png')}
                      style={tw`mr-2.5 w-2.5 h-1.5`}
                    />
                  }
                  containerStyle={tw`mb-5`}
                />
                <InputTextField
                  leftHeader={leftHeader(CREATE_PO.DELIVERY_DATE)}
                  value={procStore?.delivery}
                  onClick={() => selectDate('delivery')}
                  style={tw`h-11 mt-4 justify-between items-center rounded-md`}
                  placeholder={CREATE_PO.SELECT_DELIVERY_DATE}
                  placeholderTextColor={colors.darkGrey}
                  innerStyle={[
                    global.textColorGrey,
                    global.textMedium,
                    tw`text-sm ml-2.5`,
                  ]}
                  openKeypadOnTouch={false}
                  inputEditable={false}
                  rightIcon={
                    <Image
                      source={require('images/calendar-orange.png')}
                      style={tw`mr-2.5 w-4 h-4`}
                    />
                  }
                  containerStyle={tw`mb-5`}
                />
              </View>
            )}
          </ScrollView>
        </Center>
        <HStack safeAreaBottom>
          <View style={tw`p-5 w-full bg-white`}>
            {/* commented code for Release v0.9.1 */}
            {/* <View style={tw`p-5 w-full bg-white flex-row justify-between`}> */}
            {showCalender && (
              <CalendarPicker
                startFromMonday
                onDateChange={(date: Moment) => onDateChange(date)}
                weekdays={['S', 'M', 'T', 'W', 'T', 'F', 'S']}
                selectedDayTextColor={colors.white}
                previousTitle={STRINGS.BUTTON.PREVIOUS}
                nextTitle={STRINGS.BUTTON.NEXT}
              />
            )}
            {/* commented code for Release v0.9.1 */}
            {/* <Button
              title={STRINGS.BUTTON.SAVE_AS_DRAFT}
              onTap={() => dispatch(toggleBackDraftPOModal())}
              style={tw`rounded-lg h-11 w-5/12`}
              btnType="secondary"
              textStyle={global.textPrimaryGreen}
            /> */}
            <Button
              title={STRINGS.BUTTON.PREVIEW}
              onTap={() => navigation.navigate('POPreview')}
              // commented code for Release v0.9.1
              // style={tw`rounded-lg h-11 w-1/2`}
              style={tw`rounded-lg h-11`}
              textStyle={tw`text-sm`}
              disable={!isValidToSubmit()}
            />
          </View>
        </HStack>
        {showLocationsDrawer && (
          <SearchOptions
            title={searchOptionsProps.title}
            searchPlaceholder={searchOptionsProps.searchPlaceholder}
            closeDrawer={searchOptionsProps.closeDrawer}
            keyDisplayName={searchOptionsProps.keyDisplayName}
            searchKeys={searchOptionsProps.searchKeys}
            apiToLoadOption={searchOptionsProps.apiToLoadOption}
            hideSearchBoxItemLimit={searchOptionsProps.hideSearchBoxItemLimit}
            apiResultEndPoint={searchOptionsProps.apiResultEndPoint}
            key={searchOptionsProps.key}
            onLoadValue={searchOptionsProps.onLoadValue}
            renderCustomItem={searchOptionsProps.renderCustomItem}
          />
        )}
        {showAddress && (
          <Info
            title={
              addressType === 'customerDeliveryAddress'
                ? CREATE_PO.CUSTOMER_DELIVERY_LOCATION
                : CREATE_PO.PICKUP_LOCATION
            }
            description={
              addressType === 'customerDeliveryAddress'
                ? procStore.customerDeliveryAddress.address
                : procStore.pickupLocation.address
            }
            closeDrawer={() => setShowAddress(false)}
          />
        )}
      </Box>
    </NativeBaseProvider>
  )
}

export default Locations
