import React from 'react'
import { Text, Image, Pressable } from 'react-native'

// styles
import globals from 'styles/global'
import styles from './styles'

interface TileButtonProps {
  title: string
  icon: any
  iconStyle: object
  onPress: Function
  containerStyle?: object
  textStyle?: object
}

const TileButton = ({
  title,
  icon,
  iconStyle,
  onPress,
  containerStyle,
  textStyle,
}: TileButtonProps) => {
  return (
    <Pressable
      style={[
        globals.bgWhite,
        globals.borderRadiusEight,
        globals.alignItemsCenter,
        globals.vPaddingTwenty,
        globals.hPaddingSixteen,
        styles.dropShadow,
        containerStyle,
      ]}
      onPress={() => onPress()}
    >
      <Image source={icon} style={iconStyle} />
      <Text
        style={[
          globals.fontTwelve,
          globals.textRegular,
          globals.textAlignCenter,
          globals.textColorGrey,
          styles.tileText,
          textStyle,
        ]}
      >
        {title}
      </Text>
    </Pressable>
  )
}

export default TileButton
