import React, { useEffect, useState } from 'react'
import { View, Text, Image, Pressable, ScrollView } from 'react-native'
import { TouchableOpacity } from 'react-native-gesture-handler'
import { useDispatch, useSelector } from 'react-redux'
import { Box, HStack, NativeBaseProvider, Checkbox, Center } from 'native-base'
import tw from 'tailwind-react-native-classnames'
import RadioForm, {
  RadioButton,
  RadioButtonInput,
  RadioButtonLabel,
} from 'react-native-simple-radio-button'

import { ApplicationState } from 'src/redux/types'
import { get, URL } from 'src/utilities/axios'
import { store } from 'src/redux/store'
import STRINGS from 'constants/strings'
import { CreatePOTabProps, QCParam, QCParamObject } from 'types/procurement'
import { showToastNotification } from 'src/utilities/helpers'
import { AxiosError, AxiosResponse } from 'axios'
import { DOWNLOAD } from 'src/config/download'
import {
  setDraftPODetails,
  // commented code for Release v0.9.1
  // toggleBackDraftPOModal,
} from 'src/redux/actions/purchase-order'
import { DraftPODetails } from 'src/modals/purchase-order'

// components
import Button from 'src/components/form-fields/Button'
import InputTextField from 'src/components/form-fields/InputTextField'
import SearchOptions, {
  SearchOptionsProps,
} from 'src/components/drawers/SearchOptions'
import ButtonWithIcon from 'src/components/form-fields/ButtonWithIcon'
import Info from 'src/components/drawers/Info'

// styles
import global from 'styles/global'
import colors from 'styles/colors'
// commented code for Release v0.9.1
// import styles from '../Vendor/style'

const { CREATE_PO } = STRINGS

type PurchaseOrderState = {
  draftPODetails: DraftPODetails
}

const QC = (props: { props: CreatePOTabProps }) => {
  const dispatch = useDispatch()
  const procStore = useSelector(
    (state: ApplicationState) => state.procurement.createPO,
  )
  const purchaseOrder: PurchaseOrderState = useSelector(
    (state: ApplicationState) => state.purchaseOrder,
  )
  // TODO: Destructure props to { props }
  // eslint-disable-next-line react/destructuring-assignment
  const { setIndex, navigation, id } = props?.props
  const [qcResponsible, setQcResponsible] = useState<string | null>(
    procStore?.qcBy || null,
  )

  const qcResponsibility = [
    { label: 'DeHaat', value: 'DeHaat', selected: false },
    { label: 'Supplier', value: 'Supplier', selected: false },
  ]
  const [isNCDEXDrawerOpen, setNCDEXDrawerOpen] = useState<boolean>(false)
  const [isDrawerOpen, setDrawerOpen] = useState<boolean>(false)
  const [searchOptionsProps, setSearchOptionsProps] = useState<any>({})
  const [qcParamList, setQCParamList] = useState<QCParamObject>({})
  const [addedQcParams, setAddedQcParams] = useState<QCParamObject>({})
  const [removedQcParams, setRemovedQcParams] = useState<QCParamObject>({})
  const [mountId, setMountId] = useState<number | undefined>(id)
  const [defaultQCExists, setDefaultQCExistance] = useState<boolean>(true)

  const isNCDEXCheck = procStore.NCDEX
  const isFieldEnable = qcResponsible && !isNCDEXCheck
  const pid = procStore?.product?.id

  const toggleNCDEX = () => {
    const { createPO } = store.getState().procurement
    dispatch({
      type: 'SET_CREATE_PO_FIELD',
      payload: {
        ...createPO,
        NCDEX: !isNCDEXCheck,
      },
    })
  }

  useEffect(() => {
    if (qcResponsible !== null) {
      dispatch({
        type: 'SET_CREATE_PO_FIELD',
        payload: {
          ...procStore,
          qcBy: qcResponsible,
        },
      })
    }
    // TODO: Provide an explanation for why all deps are not included or fix it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qcResponsible])

  useEffect(() => {
    if (procStore?.NCDEX && qcResponsible) {
      dispatch(
        setDraftPODetails({
          items: purchaseOrder?.draftPODetails.items?.map((item: any) => ({
            ...item,
            qc_by: qcResponsible,
            qc_parameters: { NCDEX: true },
          })),
        }),
      )
    } else if (qcResponsible) {
      const qcParameters: any = {}
      Object.keys(procStore.qcParams).forEach(item => {
        qcParameters[item] = procStore.qcParams[item].id
      })

      dispatch(
        setDraftPODetails({
          items: purchaseOrder?.draftPODetails.items?.map((item: any) => ({
            ...item,
            qc_by: qcResponsible,
            qc_parameters: qcParameters,
          })),
        }),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qcResponsible, procStore?.NCDEX, procStore?.qcParams])

  useEffect(() => {
    if (mountId === undefined) {
      let tempObj = {}
      Object.keys(addedQcParams).forEach(qcKey => {
        tempObj = {
          ...tempObj,
          [qcKey]: procStore.qcParams?.[qcKey] || addedQcParams[qcKey][0],
        }
      })
      dispatch({
        type: 'SET_CREATE_PO_FIELD',
        payload: {
          ...procStore,
          qcParams: tempObj,
          addedQcParam: addedQcParams,
          removedQcParam: removedQcParams,
        },
      })
    }
    // TODO: Provide an explanation for why all deps are not included or fix it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addedQcParams, removedQcParams])

  useEffect(() => {
    if (pid)
      get(`${URL.QC_PARAMS_LIST}/${pid}`)
        .then((res: AxiosResponse) => {
          const tempQCAttr = { ...res?.data[0]?.quality_attributes_json }
          Object.keys(tempQCAttr)?.forEach(item => {
            const tempAttr: { id: string; name: string }[] = []
            tempQCAttr[item].forEach((qc: string) => {
              tempAttr.push({
                id: qc,
                name: qc,
              })
            })
            tempQCAttr[item] = tempAttr
          })
          setQCParamList(tempQCAttr)
          if (
            !res.data[0].default_atrributes ||
            res.data[0].default_atrributes?.length === 0
          ) {
            setDefaultQCExistance(false)
          } else {
            setDefaultQCExistance(true)
          }
          if (mountId === undefined) {
            const defaultQc: any = {}
            if (res.data[0].default_atrributes) {
              res.data[0].default_atrributes?.forEach((item: string) => {
                if (tempQCAttr[item]) {
                  defaultQc[item] = tempQCAttr[item]
                  delete tempQCAttr[item]
                }
              })
            }
            setAddedQcParams(
              res.data[0].default_atrributes?.length ? defaultQc : {},
            )
            setRemovedQcParams(tempQCAttr)
          } else if (mountId !== undefined && mountId >= 0) {
            setAddedQcParams(procStore.addedQcParam)
            setRemovedQcParams(procStore.removedQcParam)
          }
          if (mountId !== undefined && mountId >= 0) setMountId(undefined)
        })
        .catch((err: AxiosError) => {
          showToastNotification(DOWNLOAD.ERROR, err.response?.data.error)
        })
    // TODO: Provide an explanation for why all deps are not included or fix it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pid])

  const leftHeader = (text: string, disable: boolean) => (
    <View style={tw`flex-row items-center`}>
      <Text style={disable ? global.textDarkGrey : global.textColorGrey}>
        {text}
      </Text>
      <Text style={disable ? global.textDarkGrey : global.textRed}>*</Text>
    </View>
  )

  const removeQcParam = (param: QCParam[], key: string) => {
    if (
      (addedQcParams && Object.keys(addedQcParams).length > 1) ||
      !defaultQCExists
    ) {
      const tempAddedQcParams = { ...addedQcParams }
      delete tempAddedQcParams[key]
      setAddedQcParams(tempAddedQcParams)
      let tempRemovedQcParams = {}
      tempRemovedQcParams = { ...removedQcParams, [key]: param }
      setRemovedQcParams(tempRemovedQcParams)
    }
  }

  const rightHeader = (param: Array<QCParam>, key: string) => (
    <Pressable
      disabled={!isFieldEnable}
      onPress={() => removeQcParam(param, key)}
    >
      <Text
        style={[
          global.textRegular,
          tw`text-xs underline`,
          isFieldEnable ? global.textRed : global.textDarkGrey,
        ]}
      >
        {STRINGS.BUTTON.REMOVE}
      </Text>
    </Pressable>
  )

  const onDropdownClick = (drawerProps: SearchOptionsProps) => {
    setSearchOptionsProps(drawerProps)
    setDrawerOpen(true)
  }

  const onSelectOptionDrawerClose = (
    selectedItem: { id: string; name: string },
    key: string,
  ) => {
    setDrawerOpen(false)
    if (key !== 'Parameter' && selectedItem?.id !== null) {
      dispatch({
        type: 'SET_CREATE_PO_FIELD',
        payload: {
          ...procStore,
          qcParams: {
            ...procStore.qcParams,
            [key]: selectedItem,
          },
        },
      })
    } else if (key === 'Parameter' && selectedItem?.id !== null) {
      const tempAddedQcParams = { ...addedQcParams }
      tempAddedQcParams[selectedItem?.name] = qcParamList[selectedItem?.name]
      setAddedQcParams(tempAddedQcParams)
      const tempRemovedQcParams = { ...removedQcParams }
      delete tempRemovedQcParams[selectedItem?.name]
      setRemovedQcParams(tempRemovedQcParams)
    }
  }

  const selectOptionProps = (options: Array<QCParam>, key: string) => {
    return {
      title: `Select ${key}`,
      searchPlaceholder: `${CREATE_PO.SEARCH_BY} ${key}`,
      closeDrawer: (item: any) => onSelectOptionDrawerClose(item, key),
      keyDisplayName: 'name',
      searchKeys: 'name',
      serverItemList: options,
      key,
      onLoadValue: procStore.qcParams[key],
      hideSearchBoxItemLimit: 100,
    }
  }

  const addQcParam = () => {
    if (removedQcParams && Object.keys(removedQcParams).length === 1) {
      let tempAddedQcParams = {}
      tempAddedQcParams = { ...addedQcParams, ...removedQcParams }
      setAddedQcParams(tempAddedQcParams)
      setRemovedQcParams({})
    } else if (removedQcParams && Object.keys(removedQcParams).length > 1) {
      const tempQcParamList: Array<{ id: number; name: string }> = []
      Object.keys(removedQcParams).forEach((key, i) => {
        const temp = { id: i, name: key }
        tempQcParamList.push(temp)
      })
      onDropdownClick(selectOptionProps(tempQcParamList, 'Parameter'))
    }
  }

  const getArrowIcon = (disabled: boolean) => (
    <Image
      source={
        disabled
          ? require('images/arrow-down-disabled.png')
          : require('images/arrow-down-enabled.png')
      }
      style={tw`mr-2.5 w-2.5 h-1.5`}
    />
  )

  return (
    <NativeBaseProvider>
      <Box flex={1} safeAreaTop>
        <Center flex={1}>
          <View
            style={[
              tw`w-full h-full`,
              (isDrawerOpen || isNCDEXDrawerOpen) && global.drawerBackground,
            ]}
            pointerEvents={!isDrawerOpen ? 'auto' : 'none'}
          >
            <View style={tw`bg-white p-5`}>
              <View style={tw`flex-row`}>
                <Text
                  style={[
                    global.textRegular,
                    global.textColorGrey,
                    tw`text-xs`,
                  ]}
                >
                  {CREATE_PO.SELECT_QC_RESPONSIBILITY}
                </Text>
                <Text style={global.textRed}>*</Text>
              </View>
              <View style={tw`mt-4`}>
                <RadioForm formHorizontal>
                  {qcResponsibility.map((obj, i) => (
                    // TODO: Need to provide reason for why index key or else fix it
                    // eslint-disable-next-line react/no-array-index-key
                    <RadioButton labelHorizontal key={i}>
                      <RadioButtonInput
                        obj={obj}
                        index={i}
                        isSelected={qcResponsible === qcResponsibility[i].value}
                        buttonInnerColor={colors.primaryGreen}
                        buttonOuterColor={
                          qcResponsible === qcResponsibility[i].value
                            ? colors.primaryGreen
                            : colors.primaryGreen
                        }
                        buttonSize={8.3}
                        onPress={() => setQcResponsible(obj.value)}
                        buttonOuterSize={16.67}
                        buttonStyle={tw`mr-2`}
                      />
                      <RadioButtonLabel
                        obj={obj}
                        index={i}
                        labelHorizontal
                        onPress={() => setQcResponsible(obj.value)}
                        labelStyle={[
                          global.textColorGrey,
                          global.textMedium,
                          tw`text-sm pl-0`,
                        ]}
                        labelWrapStyle={tw`mr-14`}
                      />
                    </RadioButton>
                  ))}
                </RadioForm>
              </View>
            </View>
            <View
              pointerEvents={qcResponsible !== null ? 'auto' : 'none'}
              style={tw`p-5`}
            >
              <View
                style={tw`items-start flex-row justify-between`}
                key={qcResponsible}
              >
                <Checkbox
                  size="sm"
                  colorScheme="green"
                  value="NCDEX"
                  accessibilityLabel={CREATE_PO.NCDEX_PARAMETERS}
                  onChange={toggleNCDEX}
                  isChecked={isNCDEXCheck}
                >
                  <Text
                    style={[
                      global.textRegular,
                      qcResponsible !== null
                        ? global.textColorGrey
                        : global.textDarkGrey,
                      tw`text-sm pl-2`,
                    ]}
                  >
                    {CREATE_PO.NCDEX_PARAMETERS}
                  </Text>
                </Checkbox>
                <TouchableOpacity
                  onPress={() => setNCDEXDrawerOpen(true)}
                  testID="NCDEXInfoDrawerIcon"
                >
                  <Image
                    source={
                      qcResponsible === null
                        ? require('images/info-icon-disable.png')
                        : require('images/info-icon.png')
                    }
                    style={tw`w-4 h-4`}
                  />
                </TouchableOpacity>
              </View>
              {Object.keys(addedQcParams)?.length > 0 && (
                <View style={tw`flex-row text-xs pt-8`}>
                  <Text
                    style={[
                      global.textRegular,
                      tw`text-xs`,
                      isFieldEnable
                        ? global.textColorGrey
                        : global.textDarkGrey,
                    ]}
                  >
                    {CREATE_PO.QC_PARAMETERS}
                  </Text>
                  <Text
                    style={
                      !isFieldEnable ? global.textDarkGrey : global.textRed
                    }
                  >
                    *
                  </Text>
                </View>
              )}
              <ScrollView
                pointerEvents={isNCDEXCheck ? 'auto' : 'none'}
                style={tw`mt-5 max-h-80`}
              >
                {Object.keys(addedQcParams).map((key: string) => (
                  <View
                    pointerEvents={!isDrawerOpen ? 'auto' : 'none'}
                    style={tw`mb-8`}
                    key={key}
                  >
                    <InputTextField
                      leftHeader={leftHeader(key, !isFieldEnable)}
                      rightHeader={rightHeader(addedQcParams[key], key)}
                      isDisabled={qcResponsible === null}
                      value={procStore?.qcParams[key]?.name || ''}
                      onClick={() => {
                        if (isFieldEnable) {
                          onDropdownClick(
                            selectOptionProps(addedQcParams[key], key),
                          )
                        }
                      }}
                      placeholder={addedQcParams[key][0].name}
                      style={tw`h-11 mt-4 justify-between items-center rounded-md`}
                      placeholderTextColor={colors.darkGrey}
                      innerStyle={[
                        tw`text-sm ml-2.5`,
                        isFieldEnable
                          ? global.textColorGrey
                          : global.textDarkGrey,
                        procStore.qcParams[key]?.id && tw`font-bold`,
                      ]}
                      openKeypadOnTouch={false}
                      inputEditable={false}
                      key={key}
                      rightIcon={getArrowIcon(!isFieldEnable)}
                    />
                  </View>
                ))}
              </ScrollView>
            </View>
            {Object.keys(removedQcParams)?.length > 0 && (
              <ButtonWithIcon
                icon={
                  isFieldEnable
                    ? require('images/plus.png')
                    : require('images/plus-disable.png')
                }
                text={STRINGS.BUTTON.ADD_PARAMETER}
                textStyle={[
                  global.textMedium,
                  tw`text-sm ml-1.5`,
                  isFieldEnable
                    ? global.textSecondaryOrange
                    : global.textDarkGrey,
                ]}
                onClick={addQcParam}
                disable={!isFieldEnable}
              />
            )}
          </View>
        </Center>
        <HStack safeAreaBottom>
          {/* commented code for Release v0.9.1 */}
          {/* <View style={tw`p-5 w-full bg-white flex-row justify-between`}> */}
          <View style={tw`p-5 w-full bg-white`}>
            {/* commented code for Release v0.9.1 */}
            {/* <Button
              title={STRINGS.BUTTON.SAVE_AS_DRAFT}
              onTap={() => dispatch(toggleBackDraftPOModal())}
              style={[tw`rounded-lg w-5/12`, styles.nextButton]}
              btnType="secondary"
              textStyle={global.textPrimaryGreen}
            /> */}
            <Button
              title={
                id !== undefined ? STRINGS.BUTTON.PREVIEW : STRINGS.BUTTON.NEXT
              }
              onTap={() =>
                id !== undefined
                  ? navigation.navigate('POPreview')
                  : setIndex && setIndex(3)
              }
              style={tw`rounded-lg h-11`}
              // commented code for Release v0.9.1
              // style={tw`rounded-lg h-11 w-1/2`}
              textStyle={tw`text-sm`}
              disable={qcResponsible === null}
            />
          </View>
        </HStack>
        {isDrawerOpen && (
          <SearchOptions
            title={searchOptionsProps.title}
            searchPlaceholder={searchOptionsProps.searchPlaceholder}
            closeDrawer={searchOptionsProps.closeDrawer}
            keyDisplayName={searchOptionsProps.keyDisplayName}
            searchKeys={searchOptionsProps.searchKeys}
            localItemList={searchOptionsProps.serverItemList}
            onLoadValue={searchOptionsProps.onLoadValue}
            apiToLoadOption={searchOptionsProps.apiToLoadOption}
            key={searchOptionsProps.name}
          />
        )}
        {isNCDEXDrawerOpen && (
          <Info
            title={CREATE_PO.NCDEX_PARAMETERS}
            description={CREATE_PO.NCDEX_INFO}
            closeDrawer={() => setNCDEXDrawerOpen(false)}
          />
        )}
      </Box>
    </NativeBaseProvider>
  )
}

export default QC
