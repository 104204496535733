import React from 'react'
import { View, Text, Image } from 'react-native'

import STRINGS from 'constants/strings'

// styles
import global from 'styles/global'
import styles from '../../style'

interface FooterProps {
  voucherCount: number
}

const FooterButton = ({ voucherCount }: FooterProps) => (
  <View
    style={[
      global.hPaddingSixteen,
      global.vPaddingEight,
      global.borderRadiusFour,
      global.bgLightGrey,
      global.directionRow,
      global.alignItemsCenter,
      global.bgGrey,
    ]}
  >
    <Image
      source={require('images/dispatch_truck.png')}
      style={styles.dispatchTruckIcon}
    />
    <Text
      style={[
        global.textMedium,
        global.fontEighteen,
        global.textSecondaryBlue,
        styles.voucherCount,
      ]}
    >
      {voucherCount < 10 ? `0${voucherCount}` : voucherCount}
    </Text>
    <View>
      <Text
        style={[global.fontEight, global.textRegular, global.textSecondaryBlue]}
      >
        {STRINGS.MISCELLANEOUS.DISPATCH}
      </Text>
      <Text
        style={[global.fontEight, global.textRegular, global.textSecondaryBlue]}
      >
        {STRINGS.MISCELLANEOUS.VOUCHERS}
      </Text>
    </View>
  </View>
)

export default FooterButton
