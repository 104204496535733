import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { View, ScrollView } from 'react-native'
import tw from 'tailwind-react-native-classnames'

import STRINGS from 'constants/strings'
import { POViewProps } from 'src/navigations/types'
import { ApplicationState } from 'src/redux/types'
import { PODetails } from 'src/modals/purchase-order'
import { utcToLocalTime } from 'src/utilities/helpers'
import { fetchPODetailsById } from 'src/redux/actions/purchase-order'
import { ModifiedQCParam } from 'types/procurement'

// components
import { voucherDateFormat } from 'src/config/voucher'
import VoucherMetadata from 'src/components/voucher/VoucherMetadata'
import global from 'styles/global'
import VendorCard from '../../common/cards/Vendor'
import ProductCard from '../../common/cards/Product'
import QCCard from '../../common/cards/QC'
import LocationCard from '../../common/cards/Location'

// styles

interface PODetailsProps {
  poDetails: PODetails
}

const POView = ({ route }: POViewProps) => {
  const { id } = route.params
  const dispatch = useDispatch()
  const [qcParams, setQCParams] = useState<Array<ModifiedQCParam>>()

  const { poDetails }: PODetailsProps = useSelector(
    (state: ApplicationState) => state.purchaseOrder,
  )

  const defaultQCState = [
    {
      value: 'N/A',
      name: 'N/A',
    },
  ]

  useEffect(() => {
    dispatch(fetchPODetailsById(id))
    // TODO: Provide an explanation for why all deps are not included or fix it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  useEffect(() => {
    return () => {
      dispatch({
        type: 'SET_PO_DETAILS',
        payload: {},
      })
    }
    // TODO: Provide an explanation for why all deps are not included or fix it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (poDetails?.items?.length && poDetails.items[0].qc_params) {
      const tempQC: Array<ModifiedQCParam> = []
      // FIXME: Instead of push a map with return would have been better
      Object.keys(poDetails.items[0].qc_params).forEach((key: string) => {
        tempQC.push({ value: poDetails.items[0].qc_params[key], name: key })
      })
      setQCParams(tempQC)
    }
  }, [poDetails])

  return (
    <View style={[tw`bg-white`, global.flexOne, global.zeroHeight]}>
      <VoucherMetadata
        id={poDetails?.id}
        lineSeparator={false}
        attributes={[
          {
            attrName: STRINGS.GRNS.PO_ID,
            attrValue: poDetails.po_name || 'NA',
            icon: require('images/id.png'),
            iconStyle: tw`w-7 h-7 pt-1`,
            attrContainerStyle: global.flexThree,
            attrStyle: tw`text-sm`,
          },
          {
            attrName: STRINGS.PURCHASE_ORDER.QC_RESPONSIBILITY,
            attrValue:
              poDetails.items?.length && poDetails.items[0].qc_owned_by
                ? `By ${poDetails.items[0].qc_owned_by}`
                : 'N/A',
            icon: require('images/qc-blue.png'),
            iconStyle: tw`w-7 h-7 pt-1`,
            attrContainerStyle: global.flexThree,
            attrStyle: tw`text-sm`,
          },
        ]}
        containerStyle={tw`px-5`}
      />
      {poDetails && (
        <ScrollView style={tw`pt-8 px-5`}>
          <VendorCard
            nodeName={poDetails?.node || 'N/A'}
            payment={poDetails?.payment_terms || 'N/A'}
            vendorName={poDetails?.vendor_name || 'N/A'}
          />
          {poDetails.items && (
            <ProductCard
              productName={poDetails?.items[0].product_name || 'N/A'}
              quantity={
                `${poDetails?.items[0].quantity} ${poDetails.items[0].unit}` ||
                'N/A'
              }
              pricePerUnit={poDetails?.items[0].price_per_unit || 0}
              totalPrice={`${poDetails?.items[0].amount || 0}`}
              tax={poDetails?.items[0].tax_display_name || 'N/A'}
            />
          )}
          <QCCard
            ownerName={
              poDetails.items?.length && poDetails.items[0].qc_owned_by
                ? poDetails.items[0].qc_owned_by
                : 'N/A'
            }
            parameters={qcParams?.length ? qcParams : defaultQCState}
            ncdex={false}
          />
          <LocationCard
            pickupLocation={
              poDetails.pickup_address ? poDetails.pickup_address[1] : 'N/A'
            }
            customerDeliveryLocation={
              poDetails.delivery_address ? poDetails.delivery_address[1] : 'N/A'
            }
            dehaatLocation={
              poDetails.delivery_warehouse
                ? poDetails.delivery_warehouse[1]
                : 'N/A'
            }
            pickupDate={
              utcToLocalTime(poDetails.pickup_date, voucherDateFormat) || 'N/A'
            }
            deliveryDate={
              utcToLocalTime(poDetails.date_planned, voucherDateFormat) || 'N/A'
            }
          />
        </ScrollView>
      )}
    </View>
  )
}

export default POView
