import { StyleSheet } from 'react-native'
import colors from 'styles/colors'

const styles = StyleSheet.create({
  card: {
    borderWidth: 1,
    borderColor: colors.borderGrey,
  },
  marginBottomFifteen: {
    marginBottom: 15,
  },
  mobileText: {
    paddingLeft: 5,
  },
  icon: {
    height: 12,
    width: 12,
  },
  btnCntrBorder: {
    borderTopWidth: 1,
    borderTopColor: colors.borderGrey,
    paddingBottom: 0,
  },
  outstandCnt: {
    paddingTop: 5,
  },
  orderNameContainer: {
    marginBottom: 8,
  },
  btnCreateOrder: {
    color: colors.primaryGreen,
  },
  warning: {
    width: 50,
    height: 44,
    marginTop: 10,
  },
  warningMsg: {
    marginTop: 30,
  },
  warningDescMsg: {
    marginTop: 5,
  },
  btnContainer: {
    marginTop: 34,
    borderTopWidth: 1,
    borderTopColor: colors.lightGrey,
  },
  btnYesCover: {
    borderWidth: 0,
    borderColor: colors.lightGrey,
  },
  btnNoCover: {
    borderWidth: 0,
    borderLeftWidth: 1,
    borderColor: colors.lightGrey,
  },
  btnNo: {
    color: colors.secondaryOrange,
  },
  iconArrowRight: {
    height: 18,
    width: 18,
  },
})

export default styles
