import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { persistStore, persistReducer } from 'redux-persist'
import { composeWithDevTools } from 'redux-devtools-extension'

import rootReducer from 'src/redux/reducers'
import { PERSISTED_STORE_KEY } from 'constants/app'

const persistConfig = {
  key: PERSISTED_STORE_KEY,
  storage: AsyncStorage,
  whitelist: ['authReducer'],
}
const persistedReducer = persistReducer(persistConfig, rootReducer)
let enhanceMiddleware = null

if (process.env.NODE_ENV !== 'production' && typeof window === 'object') {
  enhanceMiddleware = composeWithDevTools(applyMiddleware(thunk))
} else {
  enhanceMiddleware = applyMiddleware(thunk)
}

export const store = createStore(persistedReducer, enhanceMiddleware)

export const persistor = persistStore(store)
