import React from 'react'
import { View, Text, Image } from 'react-native'

// style
import global from 'styles/global'
import style from './style'

interface Props {
  itemName: string
  itemQuantity: string
  itemRate: string
  imgUrl?: any
}

const Item = ({ imgUrl, itemName, itemQuantity, itemRate }: Props) => (
  <View
    style={[
      global.directionRow,
      global.justifyContentSpaceBetween,
      global.alignItemsCenter,
      style.itemLine,
    ]}
  >
    <View style={global.directionRow}>
      <Image
        source={{ uri: `data:image/png;base64, ${imgUrl}` }}
        style={[global.borderRadiusEight, style.itemThumbnail]}
      />
      <View style={global.hPaddingTen}>
        <Text
          style={[
            global.textMedium,
            global.fontTwelve,
            global.textSecondaryBlue,
          ]}
        >
          {itemName}
        </Text>
        <Text
          style={[
            global.textRegular,
            global.fontTwelve,
            global.textSecondaryBlue,
          ]}
        >
          {itemQuantity}
        </Text>
      </View>
    </View>
    <View style={[global.alignItemsCenter, global.justifyContentCenter]}>
      <Text
        style={[
          global.textMedium,
          global.fontFourteen,
          global.textPrimaryBlack,
        ]}
      >
        {itemRate}
      </Text>
    </View>
  </View>
)

export default Item
