import { StyleSheet } from 'react-native'

// styles
import colors from 'styles/colors'

const styles = StyleSheet.create({
  feature: {
    color: colors.secondaryTextBlue,
    fontSize: 12,
    backgroundColor: colors.borderGrey,
    paddingVertical: 5,
    marginRight: 15,
    marginBottom: 15,
  },
  featureHeading: {
    color: colors.textGrey,
    fontSize: 14,
    paddingBottom: 16,
  },
  footer: {
    paddingVertical: 10,
  },
  footerBtn: {
    height: 45,
    maxHeight: 50,
  },
})

export default styles
