import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  View,
  Image,
  ActivityIndicator,
  TouchableOpacity,
  ScrollView,
} from 'react-native'

import { Invoice, ProductItem, Voucher } from 'src/modals'
import {
  orderStatusObj,
  voucherDateFormat,
  voucherTimeFormat,
} from 'src/config/voucher'
import { utcToLocalTime } from 'src/utilities/helpers'
import STRINGS from 'constants/strings'
import { InvoiceDetailsScreenNavigationProp } from 'src/navigations/types'

// components
import VoucherMetadata from 'src/components/voucher/VoucherMetadata'
import TotalAmount from 'src/components/voucher/TotalAmount'
import VoucherStatus from 'src/components/voucher/VoucherStatus'
import DetailItemCard from 'src/components/voucher/DetailItemCard'
import Button from 'src/components/form-fields/Button'
import {
  OptionsContent,
  AddNoteContent,
  ExpenseContent,
} from 'src/components/drawers'

// styles
import global from 'styles/global'
import colors from 'styles/colors'
import styles from '../style'

const Strings = STRINGS.INVOICE

export interface PrimaryDetailsProps {
  route: {
    invoice: Invoice
    voucher: Voucher
    navigation: InvoiceDetailsScreenNavigationProp
  }
}

const PrimaryDetails = ({ route }: PrimaryDetailsProps) => {
  const { invoice, voucher, navigation } = route
  const [showOptionsDrawer, setShowOptionsDrawer] = useState<boolean>(false)
  const [showAddNoteDrawer, setShowAddNoteDrawer] = useState<boolean>(false)
  const [showExpenseDrawer, setShowExpenseDrawer] = useState<boolean>(false)

  const dispatch = useDispatch()

  const onPriceEditAtIndex = (index: number) => (updatedPrice: string) => {
    dispatch({
      type: 'UPDATE_VOUCHER_ITEM_PRICE',
      payload: {
        index,
        updatedPrice: Number(updatedPrice),
      },
    })
  }

  const onPriceReset = () => {
    dispatch({
      type: 'PRICE_RESET_VOUCHER_ITEM',
    })
    setShowOptionsDrawer(false)
  }

  const onInvokeAddNote = () => {
    setShowOptionsDrawer(false)
    setShowAddNoteDrawer(true)
  }

  const onNoteAdd = (note: string) => {
    setShowAddNoteDrawer(false)
    dispatch({
      type: 'ADD_VOUCHER_NOTE',
      payload: note,
    })
  }

  const invoiceAttributes = [
    {
      icon: require('images/id.png'),
      attrName: Strings.INVOICE_ID,
      attrValue: invoice?.invoice_id!,
    },
    {
      icon: require('images/id.png'),
      attrName: Strings.INVOICE_DATE,
      attrValue: utcToLocalTime(invoice?.write_date!, voucherDateFormat),
    },
    {
      icon: require('images/id.png'),
      attrName: Strings.INVOICE_TIME,
      attrValue: utcToLocalTime(invoice?.write_date!, voucherTimeFormat),
    },
  ]

  return (
    <>
      {invoice && voucher ? (
        <View
          style={[
            (showOptionsDrawer || showAddNoteDrawer || showExpenseDrawer) &&
              global.drawerBackground,
            global.flexOne,
            global.zeroHeight,
          ]}
        >
          <VoucherMetadata attributes={invoiceAttributes} id={invoice.id} />
          <View style={global.bgWhite}>
            <TotalAmount
              amount={voucher.total_amount || 0}
              textStyle={global.textPrimaryBlack}
            />
          </View>
          <View
            style={[
              global.bgWhite,
              global.hPaddingTwenty,
              global.vPaddingSixteen,
              global.directionRow,
              global.justifyContentSpaceBetween,
            ]}
          >
            <VoucherStatus
              dateAligned
              status={voucher.state}
              date={utcToLocalTime(voucher.write_date!, voucherDateFormat)}
              iconPath={
                orderStatusObj[voucher.state] &&
                orderStatusObj[voucher.state].iconPath
              }
              textColor={
                orderStatusObj[voucher.state] &&
                orderStatusObj[voucher.state].color
              }
            />
            <View>
              <TouchableOpacity
                onPress={() => setShowOptionsDrawer(true)}
                style={styles.showMoreContainer}
              >
                <Image
                  source={require('images/more-vertical.png')}
                  style={styles.showMoreStyle}
                />
              </TouchableOpacity>
            </View>
          </View>
          <View style={[global.flexOne, global.zeroHeight]}>
            <ScrollView>
              {voucher.items.map((voucherItem: ProductItem, index: number) => (
                <DetailItemCard
                  // TODO: Need to provide reason for why index key or else fix it
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${voucherItem.product_name}__${index}`}
                  name={voucherItem.product_name}
                  imageS3Key={voucherItem.product_image}
                  doneQty={voucherItem.quantity_done}
                  unitOfMeasure={String(voucherItem.product_uom[1])}
                  unitPrice={voucherItem.price_unit}
                  onPriceEdit={onPriceEditAtIndex(index)}
                  tax={voucherItem.tax_display_name}
                />
              ))}
            </ScrollView>
            <View
              style={[
                global.bgWhite,
                global.vPaddingTen,
                global.hPaddingTwenty,
                global.borderTopWidthOne,
                styles.continueToInvoiceContainer,
              ]}
            >
              <Button
                title={Strings.CONTINUE_TO_REGENERATE_INVOICE}
                style={[
                  global.borderRadiusEight,
                  styles.continueToInvoiceButton,
                ]}
                onTap={() => setShowExpenseDrawer(true)}
              />
            </View>
          </View>
        </View>
      ) : (
        <ActivityIndicator
          size="large"
          color={colors.primaryGreen}
          style={global.vMarginSixteen}
        />
      )}
      {showOptionsDrawer && (
        <OptionsContent
          closeDrawer={() => setShowOptionsDrawer(false)}
          onInvokeAddNote={onInvokeAddNote}
          onPriceReset={onPriceReset}
        />
      )}
      {showAddNoteDrawer && (
        <AddNoteContent setDrawer={setShowAddNoteDrawer} addNote={onNoteAdd} />
      )}
      {showExpenseDrawer && (
        <ExpenseContent
          navigation={navigation}
          voucher={voucher}
          setDrawer={setShowExpenseDrawer}
          regenerate
          invoiceId={invoice.id}
        />
      )}
    </>
  )
}

export default PrimaryDetails
