import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  input: {
    width: '80%',
    minWidth: 40,
  },
})

export default styles
