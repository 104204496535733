import { StyleSheet } from 'react-native'
import colors from 'styles/colors'

const styles = StyleSheet.create({
  filterContainer: {
    borderColor: colors.primaryGreen,
  },
})

export default styles
