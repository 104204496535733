import React, { useState, useEffect } from 'react'
import { View, Image, Text, TouchableOpacity, Pressable } from 'react-native'
import InputSpinner from 'react-native-input-spinner'

import STRINGS from 'constants/strings'
import { formatter } from 'src/utilities/helpers'
import { Product, Item } from 'src/modals'

// components
import Checkbox from 'src/components/form-fields/Checkbox'

// styles
import global from 'styles/global'
import colors from 'styles/colors'
import styles from './style'

interface ProductCardProp {
  data: Product
  itemList?: Item[]
  index?: number
  orderId?: number
  changeQuantity?: Function
  onSelect?: Function
  deletable?: Function
  showQuantity?: boolean
  style?: Object
  innerStyle?: Object
  navigation: { navigate: Function }
  name?: String
  quantity?: number
  unitPrice?: number
  showDrawer?: Function
}

const ProductCard = ({
  data,
  itemList,
  index,
  orderId,
  changeQuantity,
  onSelect,
  deletable,
  showQuantity,
  style,
  innerStyle,
  navigation,
  name,
  quantity,
  unitPrice,
  showDrawer,
}: ProductCardProp) => {
  const [imageSource, setImageSource] = useState<string>()

  const viewDetails = () => {
    if (onSelect || itemList) {
      navigation?.navigate('ProductDetails', {
        data,
        index,
        itemList,
        orderId,
      })
    }
  }

  useEffect(() => {
    if (data.image) {
      setImageSource(`data:image/png;base64, ${data.image}`)
    }
  }, [data.image])

  return (
    <Pressable onPress={() => viewDetails()} style={[style]}>
      <View
        style={[
          global.bgWhite,
          global.paddingTwenty,
          global.vMarginEight,
          global.directionRow,
          innerStyle,
        ]}
      >
        <View style={[global.directionRow, global.widthFull]}>
          <Image
            source={
              imageSource
                ? { uri: imageSource }
                : require('images/product-placeholder.png')
            }
            style={[styles.productThumbnail, global.borderRadiusFour]}
          />
          <View style={[global.flexGrowTwo, global.marginLeftSixteen]}>
            <View
              style={[global.directionRow, global.justifyContentSpaceBetween]}
            >
              <Text
                style={[
                  global.fontTwenty,
                  global.textBold,
                  global.textPrimaryBlack,
                ]}
              >
                {name !== undefined ? `${data.name} (${name})` : data.name}
              </Text>
              {showDrawer && (
                <TouchableOpacity
                  onPress={() => showDrawer()}
                  testID="more-vertical"
                >
                  <Image
                    source={require('images/more-vertical.png')}
                    style={[styles.moreVerticalThumbnail]}
                  />
                </TouchableOpacity>
              )}
              {onSelect && <Checkbox data={data} onSelect={onSelect} />}
              {deletable && (
                <TouchableOpacity onPress={() => deletable(index)}>
                  <Image
                    source={require('images/trash.png')}
                    style={[styles.moreVerticalThumbnail]}
                  />
                </TouchableOpacity>
              )}
            </View>
            <View style={[styles.attributeContainer, global.directionRow]}>
              <Text
                style={[
                  styles.attribute,
                  global.fontTwelve,
                  global.textBold,
                  global.textSecondaryBlue,
                  global.hPaddingTen,
                  global.borderRadiusTwo,
                ]}
              >
                {data.category}
              </Text>
              <Text
                style={[
                  styles.attribute,
                  global.fontTwelve,
                  global.textBold,
                  global.textSecondaryBlue,
                  global.hPaddingTen,
                  global.borderRadiusTwo,
                ]}
              >
                {`${STRINGS.MISCELLANEOUS.UOM}-${data.uom_id[1]}`}
              </Text>
            </View>
            <View
              style={[
                styles.attributeContainer,
                global.directionRow,
                global.justifyContentSpaceBetween,
              ]}
            >
              {showQuantity &&
                quantity !== undefined &&
                (changeQuantity ? (
                  <InputSpinner
                    rounded={false}
                    color={colors.secondaryOrange}
                    height={30}
                    maxLength={5}
                    textColor={colors.primaryTextBlack}
                    style={[styles.inputSpinner]}
                    inputStyle={[global.hPaddingTen]}
                    onChange={qty => changeQuantity(qty)}
                    initialValue={quantity}
                    value={quantity}
                  />
                ) : (
                  <Text
                    style={[
                      styles.attribute,
                      global.fontTwelve,
                      global.textBold,
                      global.textSecondaryBlue,
                      global.hPaddingTen,
                      global.borderRadiusTwo,
                    ]}
                  >
                    {`${STRINGS.MISCELLANEOUS.QTY}-${quantity}`}
                  </Text>
                ))}
              {showQuantity && unitPrice && quantity !== undefined && (
                <Text
                  style={[
                    global.fontSixteen,
                    global.textBold,
                    global.textPrimaryBlack,
                    global.hPaddingTen,
                    global.alignSelfCenter,
                  ]}
                >
                  {formatter.format(unitPrice * (quantity || 0))}
                </Text>
              )}
            </View>
          </View>
        </View>
      </View>
    </Pressable>
  )
}

ProductCard.defaultProps = {
  showQuantity: false,
}

export default ProductCard
