import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  captureContainer: {
    bottom: 0,
  },
  capture: {
    width: 70,
    height: 70,
    bottom: 0,
    borderRadius: 50,
  },
  iconCancel: {
    width: 16,
    height: 16,
  },
})

export default styles
