import { StyleSheet } from 'react-native'

// styles
import colors from 'styles/colors'

const styles = StyleSheet.create({
  marginBottomFifteen: {
    marginBottom: 15,
  },
  containerStyle: {
    paddingBottom: 16,
  },
  exhangeThumbnail: {
    height: 14,
    width: 14,
    marginTop: 2,
  },
  totalText: {
    color: colors.textGrey,
  },
  AToZText: {
    color: colors.primaryGreen,
  },
  continueBtn: {
    height: 45,
    minHeight: 50,
  },
  footer: {
    paddingVertical: 10,
  },
})

export default styles
