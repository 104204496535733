import React, { useState, useEffect } from 'react'
import { View, ScrollView, ActivityIndicator } from 'react-native'

import STRINGS from 'constants/strings'
import {
  getDocName,
  getEndTruncatedString,
  getTruncatedString,
  utcToLocalTime,
} from 'src/utilities/helpers'
import { Invoice, DownloadInvoiceProps } from 'src/modals'
import {
  orderStatusObj,
  voucherDateFormat,
  voucherTimeFormat,
} from 'src/config/voucher'
import { get, URL } from 'src/utilities/axios'
import { DOWNLOAD } from 'src/config/download'
import useDownloadFrmS3 from 'src/utilities/hooks/useDownloadFrmS3'

// components
import TotalAmount from 'src/components/voucher/TotalAmount'
import VoucherMetadata from 'src/components/voucher/VoucherMetadata'
import VoucherStatus from 'src/components/voucher/VoucherStatus'
import DetailItemCard from 'src/components/voucher/DetailItemCard'
import DownloadDoc from 'src/components/drawers/DownloadDoc'
import IconButton from 'src/components/form-fields/ButtonWithIcon'

// styles
import global from 'styles/global'
import colors from 'styles/colors'
import styles from '../style'

const String = STRINGS.INVOICE

export interface PrimaryDetailsProps {
  route: {
    invoice: Invoice
  }
}

interface DrawerProps {
  isDrawerOpen: boolean
  drawerDoc: DownloadInvoiceProps[]
  successMsg: string
}

const PrimaryDetails = ({ route }: PrimaryDetailsProps) => {
  const { invoice } = route
  const [invoiceDocList, setInvoiceDocList] = useState<DownloadInvoiceProps[]>(
    [],
  )
  const [creditNoteDocList, setCreditNoteDocList] = useState<
    DownloadInvoiceProps[]
  >([])
  const [drawer, setDrawer] = useState<DrawerProps>({
    isDrawerOpen: false,
    drawerDoc: [],
    successMsg: '',
  })
  const { downloadDocFrmS3 } = useDownloadFrmS3()

  const setDocsToDownload = (docs: DownloadInvoiceProps[]) => {
    const creditNotesDocs: DownloadInvoiceProps[] = []
    const invoicesDocs: DownloadInvoiceProps[] = []
    for (let i = 0; i < docs?.length; i++) {
      if (docs[i].type === DOWNLOAD.INVOICE_DOC_TYPE) invoicesDocs.push(docs[i])
      else if (docs[i].type === DOWNLOAD.CREDIT_DOC_TYPE)
        creditNotesDocs.push(docs[i])
    }
    setInvoiceDocList(invoicesDocs)
    setCreditNoteDocList(creditNotesDocs)
  }

  const fetchDocIdToDownload = () => {
    if (invoice?.id) {
      get(`${URL.INVOICE_DOWNLOAD}/${invoice?.id}`)
        .then(data => {
          if (data && data.data && data.data.length > 0) {
            setDocsToDownload(data.data)
          }
        })
        .catch(error => console.error(error))
    }
  }

  useEffect(() => {
    fetchDocIdToDownload()
    // TODO: Provide an explanation for why all deps are not included or fix it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoice?.id])

  const onInvoiceClick = () => {
    if (invoiceDocList?.length > 1)
      setDrawer({
        isDrawerOpen: true,
        drawerDoc: invoiceDocList,
        successMsg: String.INVOICE_DOWNLOADED,
      })
    else {
      const docName = getDocName(invoiceDocList[0])
      downloadDocFrmS3(
        invoiceDocList[0]?.store_fname,
        docName,
        String.INVOICE_DOWNLOADED,
      )
    }
  }

  const onCreditNoteClick = () => {
    if (creditNoteDocList?.length > 1)
      setDrawer({
        isDrawerOpen: true,
        drawerDoc: creditNoteDocList,
        successMsg: String.CREDIT_NOTE_DOWNLOADED,
      })
    else {
      const docName = getDocName(creditNoteDocList[0])
      downloadDocFrmS3(
        creditNoteDocList[0]?.store_fname,
        docName,
        String.CREDIT_NOTE_DOWNLOADED,
      )
    }
  }

  return (
    <>
      {invoice ? (
        <View style={[global.flexOne, global.zeroHeight]}>
          <VoucherMetadata
            id={invoice.id}
            attributes={[
              {
                icon: require('images/id.png'),
                attrName: String.INVOICE_ID,
                attrValue:
                  invoice.invoice_id && invoice?.invoice_id.length > 10
                    ? getTruncatedString(invoice?.invoice_id)
                    : invoice.invoice_id || STRINGS.MISCELLANEOUS.NOT_AVAILABLE,
              },
              {
                icon: require('images/calendar.png'),
                attrName: String.INVOICE_DATE,
                attrValue: invoice.write_date
                  ? utcToLocalTime(invoice.write_date, voucherDateFormat)
                  : getEndTruncatedString(
                      STRINGS.MISCELLANEOUS.DATE_NOTE_AVAILABLE,
                    ),
              },
              {
                icon: require('images/clock.png'),
                attrName: String.INVOICE_TIME,
                attrValue: invoice?.write_date
                  ? utcToLocalTime(invoice?.write_date, voucherTimeFormat)
                  : getEndTruncatedString(
                      STRINGS.MISCELLANEOUS.TIME_NOTE_AVAILABLE,
                    ),
              },
            ]}
          />
          <View style={global.bgWhite}>
            <TotalAmount
              amount={invoice.total_amount || 0}
              textStyle={global.textPrimaryBlack}
              containerStyle={[
                global.borderTopWidthOne,
                global.borderTopColorbgLightBlue,
              ]}
            />
          </View>
          <View
            style={[
              global.bgWhite,
              global.hPaddingTwenty,
              global.vPaddingSixteen,
              global.directionRow,
              global.justifyContentSpaceBetween,
            ]}
          >
            <View style={global.alignItemsLeft}>
              <VoucherStatus
                dateAligned={false}
                status={invoice.state}
                date={
                  invoice.write_date
                    ? utcToLocalTime(invoice.write_date, voucherDateFormat)
                    : getEndTruncatedString(
                        STRINGS.MISCELLANEOUS.DATE_NOTE_AVAILABLE,
                      )
                }
                iconPath={
                  orderStatusObj[invoice.state] &&
                  orderStatusObj[invoice.state].iconPath
                }
                textColor={
                  orderStatusObj[invoice.state] &&
                  orderStatusObj[invoice.state].color
                }
              />
            </View>
            <View style={global.directionRow}>
              {creditNoteDocList?.length > 0 && (
                <IconButton
                  text={String.CREDIT_NOTE}
                  containerStyle={[
                    global.hMarginTwenty,
                    global.bgMint,
                    styles.iconButton,
                  ]}
                  icon={require('images/download.png')}
                  onClick={onCreditNoteClick}
                  textStyle={[global.textPrimaryGreen, styles.iconButtonText]}
                />
              )}
              {invoiceDocList?.length > 0 && (
                <IconButton
                  text={String.INVOICE}
                  icon={require('images/download.png')}
                  onClick={onInvoiceClick}
                  textStyle={[global.textPrimaryGreen, styles.iconButtonText]}
                  containerStyle={[global.bgMint, styles.iconButton]}
                />
              )}
            </View>
          </View>
          <View style={[global.flexOne, global.zeroHeight]}>
            <ScrollView>
              {invoice.items?.map((voucherItem, index) => (
                <DetailItemCard
                  // TODO: Need to provide reason for why index key or else fix it
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${voucherItem.product_name}__${index}`}
                  name={voucherItem.product_name}
                  imageS3Key={voucherItem.product_image}
                  doneQty={voucherItem.quantity_done}
                  unitOfMeasure={voucherItem.product_uom[1]}
                  unitPrice={voucherItem.price_unit}
                  tax={voucherItem.tax_display_name}
                />
              ))}
            </ScrollView>
          </View>
          {drawer.isDrawerOpen && (
            <DownloadDoc
              closeDrawer={() =>
                setDrawer({
                  isDrawerOpen: false,
                  drawerDoc: [],
                  successMsg: '',
                })
              }
              docs={drawer.drawerDoc}
              successMsg={drawer.successMsg}
            />
          )}
        </View>
      ) : (
        <ActivityIndicator
          size="large"
          color={colors.primaryGreen}
          style={global.vMarginSixteen}
        />
      )}
    </>
  )
}

export default PrimaryDetails
