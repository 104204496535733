import React from 'react'
import {
  View,
  Text,
  Image,
  Pressable,
  GestureResponderEvent,
} from 'react-native'

import STRINGS from 'constants/strings'

// styles
import global from 'styles/global'
import styles from './style'

const String = STRINGS.VOUCHER

interface OptionsContentProps {
  closeDrawer(event: GestureResponderEvent): void
  onInvokeAddNote(event: GestureResponderEvent): void
  onPriceReset(event: GestureResponderEvent): void
}

const OptionsContent = ({
  closeDrawer,
  onInvokeAddNote,
  onPriceReset,
}: OptionsContentProps) => (
  <View style={global.drawer}>
    <View style={global.alignItemsRight}>
      <Pressable onPress={closeDrawer} style={global.paddingSixteen}>
        <Image
          source={require('images/cancel.png')}
          style={styles.iconCancel}
        />
      </Pressable>
    </View>
    <Pressable onPress={onInvokeAddNote} style={global.widthFull}>
      <Text
        style={[
          global.fontFourteen,
          global.textRegular,
          global.textColorGrey,
          global.textAlignCenter,
          global.paddingSixteen,
          styles.drawerButtonBorder,
        ]}
      >
        {String.ADD_NOTE}
      </Text>
    </Pressable>
    <Pressable onPress={onPriceReset} style={global.widthFull}>
      <Text
        style={[
          global.fontFourteen,
          global.textRegular,
          global.textRed,
          global.textAlignCenter,
          global.paddingSixteen,
          styles.drawerButtonBorder,
        ]}
      >
        {String.RESET_PRICE}
      </Text>
    </Pressable>
    <Pressable onPress={closeDrawer}>
      <Text
        style={[
          global.fontFourteen,
          global.textRegular,
          global.textColorGrey,
          global.textAlignCenter,
          global.paddingSixteen,
        ]}
      >
        {String.CANCEL}
      </Text>
    </Pressable>
  </View>
)

export default OptionsContent
