/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react'
import {
  Animated,
  useWindowDimensions,
  Image,
  View,
  Text,
  BackHandler,
} from 'react-native'
import { useFocusEffect, useIsFocused } from '@react-navigation/native'
import { TabView, TabBar } from 'react-native-tab-view'
import { NativeBaseProvider } from 'native-base'
import tw from 'tailwind-react-native-classnames'
import { useDispatch, useSelector } from 'react-redux'

import STRINGS from 'constants/strings'
import { CreatePOTabProps, TabPressProps } from 'types/procurement'
import { CreatePOProps } from 'src/navigations/types'
import { ApplicationState } from 'src/redux/types'
import { resetCreatePOField } from 'src/redux/actions/procurement'
import { toggleBackModal } from 'src/redux/actions/app'
import { toggleBackDraftPOModal } from 'src/redux/actions/purchase-order'
import { post, URL } from 'src/utilities/axios'
import { showToastNotification } from 'src/utilities/helpers'
import { DraftPODetails } from 'src/modals/purchase-order'

// components
import Vendor from 'src/screens/procurement-manager/CreatePO/tabs/Vendor'
import Product from 'src/screens/procurement-manager/CreatePO/tabs/Product'
import QC from 'src/screens/procurement-manager/CreatePO/tabs/QC'
import Location from 'src/screens/procurement-manager/CreatePO/tabs/Location'
import Modal from 'src/components/Modal'
import Button from 'src/components/form-fields/Button'
import ToastNotification from 'src/components/ToastNotification'

// styles
import global from 'styles/global'
import styles from './styles'

interface RenderLabelRoute {
  route: {
    index: number
    title: string
  }
  focused: boolean
}

const renderScene = (props: { route: CreatePOTabProps }) => {
  switch (props.route.key) {
    case 'vender':
      return <Vendor props={props?.route} />
    case 'product':
      return <Product props={props?.route} />
    case 'qc':
      return <QC props={props?.route} />
    case 'location':
      return <Location props={props?.route} />
    default:
      return <></>
  }
}

const CreatePO = ({ navigation, route }: CreatePOProps) => {
  const dispatch = useDispatch()
  const isFocused = useIsFocused()
  const [index, setIndex] = useState<number>(0)
  const [tabIndex, setTabIndex] = useState<Array<number>>([])
  const [apiInProgress, setApiInProgress] = useState<boolean>(false)
  const layout = useWindowDimensions()
  const isModalOpen = useSelector(
    (state: ApplicationState) => state.app.isModalOpen,
  )
  const { isDraftPOModalOpen, draftPODetails } = useSelector(
    (state: ApplicationState) => state.purchaseOrder,
  )
  const [routes, setRoute] = useState([
    {
      key: 'vender',
      title: `${STRINGS.PURCHASE_ORDER.VENDOR_TAB_TITLE}`,
      testID: 'vendor-tab',
      index: 0,
      setIndex,
      navigation,
    },
    {
      key: 'product',
      title: `${STRINGS.PURCHASE_ORDER.PRODUCT_TAB_TITLE}`,
      testID: 'product-tab',
      index: 1,
      setIndex,
      navigation,
    },
    {
      key: 'qc',
      title: `${STRINGS.PURCHASE_ORDER.QC_TAB_TITLE}`,
      testID: 'qc-tab',
      index: 2,
      setIndex,
      navigation,
    },
    {
      key: 'location',
      title: `${STRINGS.PURCHASE_ORDER.LOCATION_TAB_TITLE}`,
      index: 3,
      testID: 'location-tab',
      navigation,
    },
  ])

  useEffect(() => {
    if (route && route.params && route.params.id !== undefined) {
      setIndex(route.params.id)
      const copyOfRoute = [...routes]
      const modifiedCopyOfRoute = copyOfRoute.map(item => ({
        ...item,
        id: route?.params?.id,
      }))
      setRoute(modifiedCopyOfRoute)
    }
    // TODO: Provide an explanation for why all deps are not included or fix it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route?.params?.id])

  const onPhoneButton = () => {
    if (isFocused) dispatch(toggleBackModal())
    return true
  }

  useFocusEffect(() => {
    BackHandler.addEventListener('hardwareBackPress', onPhoneButton)
    return () =>
      BackHandler.removeEventListener('hardwareBackPress', onPhoneButton)
  })

  useEffect(() => {
    if (tabIndex.indexOf(index) < 0) setTabIndex([...tabIndex, index])
    // TODO: Provide an explanation for why all deps are not included or fix it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index])

  const modalBody = () => (
    <View style={tw`items-center`}>
      <Image style={styles.alertIcon} source={require('images/warning.png')} />
      <View style={tw`w-full`}>
        <Text
          style={[
            tw`text-sm text-center mt-6 mx-5`,
            global.textRegular,
            global.textColorGrey,
            styles.warningMsg,
          ]}
        >
          {STRINGS.CREATE_PO.CREATE_PO_BACK}
        </Text>
      </View>
    </View>
  )

  const onYesClick = () => {
    dispatch(toggleBackModal())
    dispatch(resetCreatePOField())
    navigation.goBack()
  }

  const onSaveDraft = () => {
    const payload: DraftPODetails = draftPODetails
    if (!tabIndex.includes(3)) {
      delete payload.pickup_address
      delete payload.pickup_date
    }
    setApiInProgress(true)
    post(URL.CREATE_DRAFT_PO, payload)
      .then(() => {
        setApiInProgress(false)
        dispatch(toggleBackDraftPOModal())
        dispatch(resetCreatePOField())
        showToastNotification(
          STRINGS.SUCCESS.SUCCESS,
          STRINGS.DRAFT_PO.SAVE_DRAFT_SUCCESS_MSG,
        )
        setTimeout(() => {
          navigation.navigate('Home')
        }, 3000)
      })
      .catch(() => {
        setApiInProgress(false)
        dispatch(toggleBackDraftPOModal())
      })
  }

  const modalFooter = () => (
    <View style={[tw`flex-row justify-between border-t`, styles.btnContainer]}>
      <Button
        title={STRINGS.BUTTON.YES}
        onTap={() => onYesClick()}
        btnType="secondary"
        style={[tw`w-6/12 border-0 border-r rounded-bl-lg`, styles.btnCover]}
        textStyle={[global.textRegular, global.textRed]}
      />
      <Button
        title={STRINGS.BUTTON.NO}
        onTap={() => dispatch(toggleBackModal())}
        btnType="secondary"
        style={[tw`w-6/12 border-0 rounded-br-lg`, styles.btnCover]}
        textStyle={[global.textRegular, global.textPrimaryGreen]}
      />
    </View>
  )

  const draftPOModalBody = () => (
    <View style={tw`items-center`}>
      <Image style={styles.alertIcon} source={require('images/warning.png')} />
      <View style={tw`w-full`}>
        <Text
          style={[
            tw`text-sm text-center mt-6 mx-5`,
            global.textRegular,
            global.textColorGrey,
            styles.warningMsg,
          ]}
        >
          {STRINGS.DRAFT_PO.CREATE_DRAFT_PO}
        </Text>
      </View>
    </View>
  )

  const draftPOModalFooter = () => (
    <View style={[tw`flex-row justify-between border-t`, styles.btnContainer]}>
      <Button
        title={STRINGS.BUTTON.YES}
        onTap={() => onSaveDraft()}
        btnType="secondary"
        style={[tw`w-6/12 border-0 border-r rounded-bl-lg`, styles.btnCover]}
        textStyle={[global.textRegular, global.textRed]}
        disable={apiInProgress}
        loader={apiInProgress}
      />
      <Button
        title={STRINGS.BUTTON.NO}
        onTap={() => dispatch(toggleBackDraftPOModal())}
        btnType="secondary"
        style={[tw`w-6/12 border-0 rounded-br-lg`, styles.btnCover]}
        textStyle={[global.textRegular, global.textPrimaryGreen]}
      />
    </View>
  )

  const renderTabBar = (props: any) => {
    const isEdited = route?.params?.isEdited

    return (
      <TabBar
        {...props}
        style={tw`bg-white`}
        // TODO: Proivde a different name to the variable route
        // eslint-disable-next-line @typescript-eslint/no-shadow
        renderLabel={({ route, focused }: RenderLabelRoute) => {
          return (
            <>
              <View style={tw`flex-row`}>
                {route.index === tabIndex.slice(-1)[0] && !isEdited ? (
                  <Image
                    source={require('images/unlock.png')}
                    style={tw`w-3.5 h-3.5`}
                  />
                ) : tabIndex.includes(route.index) ? (
                  <Image
                    source={require('images/tick.png')}
                    style={tw`w-3.5 h-3.5`}
                  />
                ) : (
                  <Image
                    source={require('images/lock.png')}
                    style={tw`w-3.5 h-3.5`}
                  />
                )}
                <Animated.Text
                  style={
                    focused || tabIndex.includes(route.index)
                      ? [global.textPrimaryBlack, tw`text-sm leading-4 ml-1`]
                      : [global.textSecondaryGrey, tw`text-sm leading-4 ml-1`]
                  }
                >
                  {route.title}
                </Animated.Text>
              </View>
              {focused && (
                <View
                  style={[
                    tw`w-full h-1 rounded-sm absolute -bottom-4`,
                    global.bgSeaGreen,
                  ]}
                />
              )}
            </>
          )
        }}
        indicatorStyle={tw`bg-white`}
        // TODO: Provide a different variable name to props
        // eslint-disable-next-line @typescript-eslint/no-shadow
        onTabPress={(props: TabPressProps) => {
          // TODO: Provide prop type for preventDefault
          // eslint-disable-next-line react/prop-types
          if (tabIndex.indexOf(props.route.index) < 0) props.preventDefault()
        }}
      />
    )
  }

  return (
    <NativeBaseProvider>
      <ToastNotification successContainerStyle={global.bgScondaryGrey} />
      <TabView
        navigationState={{ index, routes }}
        renderScene={renderScene}
        onIndexChange={setIndex}
        renderTabBar={renderTabBar}
        initialLayout={{
          width: layout.width,
        }}
        swipeEnabled={false}
      />
      {isModalOpen && (
        <Modal
          body={modalBody()}
          footer={modalFooter()}
          close={() => dispatch(toggleBackModal())}
          style={tw`p-0`}
        />
      )}
      {isDraftPOModalOpen && (
        <Modal
          body={draftPOModalBody()}
          footer={draftPOModalFooter()}
          close={() => dispatch(toggleBackDraftPOModal())}
          style={tw`p-0`}
        />
      )}
    </NativeBaseProvider>
  )
}

export default CreatePO
