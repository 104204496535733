import { StyleSheet } from 'react-native'

import colors from 'styles/colors'

const style = StyleSheet.create({
  itemLine: {
    borderTopColor: colors.bgLightBlue,
    borderTopWidth: 1,
    paddingTop: 15,
    marginTop: 15,
  },
  itemThumbnail: {
    height: 35,
    width: 35,
  },
})

export default style
