import { StyleSheet } from 'react-native'
import colors from 'styles/colors'

const styles = StyleSheet.create({
  footerBtnContainer: {
    height: 45,
    minHeight: 50,
  },
  footer: {
    paddingVertical: 10,
  },
  warning: {
    width: 50,
    height: 44,
    marginTop: 10,
  },
  warningMsg: {
    marginTop: 34,
  },
  productCard: {
    marginBottom: 0,
    paddingBottom: 0,
  },
  btnContainer: {
    marginTop: 39,
    borderTopWidth: 1,
    borderTopColor: colors.lightGrey,
  },
  btnYesCover: {
    borderWidth: 0,
    borderColor: colors.lightGrey,
  },
  btnNoCover: {
    borderWidth: 0,
    borderLeftWidth: 1,
    borderColor: colors.lightGrey,
  },
  btnNo: {
    color: colors.secondaryOrange,
  },
})

export default styles
