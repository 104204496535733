import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { View, ScrollView, Image, Text } from 'react-native'
import tw from 'tailwind-react-native-classnames'

import { post, URL } from 'src/utilities/axios'
import STRINGS from 'constants/strings'
import { ApplicationState } from 'src/redux/types'
import { POPreviewProps } from 'src/navigations/types'
import { ModifiedQCParam } from 'types/procurement'
import { resetCreatePOField } from 'src/redux/actions/procurement'
import useDownloadFrmS3 from 'src/utilities/hooks/useDownloadFrmS3'
import { showToastNotification } from 'src/utilities/helpers'
import { DOWNLOAD } from 'src/config/download'

// components
import Button from 'src/components/form-fields/Button'
import ToastNotification from 'src/components/ToastNotification'
import global from 'styles/global'
import VendorCard from '../../common/cards/Vendor'
import ProductCard from '../../common/cards/Product'
import QCCard from '../../common/cards/QC'
import LocationCard from '../../common/cards/Location'

// styles
import style from './style'

const POPreview = ({ navigation }: POPreviewProps) => {
  const dispatch = useDispatch()
  const noQCParams: Array<ModifiedQCParam> = [{ name: 'N/A', value: 'N/A' }]
  const [qcParams, setQCParams] = useState<Array<ModifiedQCParam>>(noQCParams)
  const { createPO } = useSelector(
    (state: ApplicationState) => state.procurement,
  )
  const [apiInProgress, setApiInProgress] = useState<boolean>(false)
  const { downloadDocFrmS3 } = useDownloadFrmS3()

  useEffect(() => {
    if (createPO.qcParams) {
      const tempQC: Array<ModifiedQCParam> = []
      Object.keys(createPO.qcParams).forEach((key: string) => {
        tempQC.push({ name: key, value: createPO.qcParams[key].name })
      })
      setQCParams(tempQC)
    }
  }, [createPO])

  const onHomeButton = () => dispatch(resetCreatePOField())

  const createAPO = () => {
    setApiInProgress(true)
    // TODO: provide a different variable name to qcParams
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const qcParams: any = {}
    if (createPO.NCDEX) {
      qcParams.NCDEX = true
    } else if (createPO.qcParams) {
      Object.keys(createPO.qcParams).forEach((key: string) => {
        qcParams[key] = createPO.qcParams[key].id
      })
    }
    const payload: any = {
      vendor_id: createPO.vendor.id,
      node_id: createPO.node.id,
      delivery_date: createPO.delivery.split('/').reverse().join('-'),
      pickup_date: createPO.pickup.split('/').reverse().join('-'),
      pickup_address: createPO.pickupLocation.id,
      delivery_warehouse:
        createPO.deliveryLocationType === 'DeHaat Warehouse'
          ? createPO.dehaatWarehouseLocation?.id
          : createPO.customerWarehouseLocation.id,
      payment_term_id: createPO.paymentTerm.id,
      items: [
        {
          product_id: createPO.product.id,
          product_name: createPO.product.name,
          product_qty: createPO.productQuantity,
          product_uom: createPO.productUOM.id,
          price_unit: createPO.productPrice,
          tax_id: createPO.productTax.id,
          qc_by: createPO.qcBy,
          qc_parameters: qcParams,
        },
      ],
    }
    if (createPO.deliveryLocationType === 'Customer Location') {
      payload.delivery_address = createPO?.customerDeliveryAddress?.id
    }
    post(URL.CREATE_PO, payload)
      .then((res: any) => {
        setApiInProgress(false)
        navigation.navigate('SuccessScreen', {
          iconImg: (
            <Image
              source={require('images/success-icon.png')}
              style={style.successIcon}
            />
          ),
          customMsg: (
            <Text
              style={[
                tw`text-sm p-5 text-center flex-row`,
                global.textRegular,
                global.textPrimaryBlack,
              ]}
            >
              {`${STRINGS.PURCHASE_ORDER.PO_CREATION_SUCCESS_MSG.START}`}
              <Text style={tw`font-bold`}>
                {createPO?.vendor?.display_name || 'N/A'}
              </Text>
              {`${STRINGS.PURCHASE_ORDER.PO_CREATION_SUCCESS_MSG.END}`}
            </Text>
          ),
          greeting: true,
          toastSuccessContainerStyle: [global.bgScondaryGrey],
          footerButtons: [
            {
              title: STRINGS.BUTTON.DOWNLOAD_PO,
              onClick: () =>
                downloadDocFrmS3(
                  res.data?.store_fname,
                  res.data?.po_display_id,
                  STRINGS.CREATE_PO.PO_DOWNLOAD_MSG,
                ),
              buttonStyle: [
                tw`rounded-lg border bg-white`,
                global.borderColorPrimaryGreen,
              ],
              textStyle: [global.textPrimaryGreen, global.textBlack],
            },
          ],
          idFields: [
            {
              title: STRINGS.PURCHASE_ORDER.PO_ID,
              value: res?.data?.po_display_id,
            },
          ],
          homeButtonStyle: [
            tw`border-0 underline`,
            global.textDecorationColorPrimaryGreen,
          ],
          onHomeButton,
        })
      })
      .catch(err => {
        setApiInProgress(false)
        showToastNotification(DOWNLOAD.ERROR, err.response?.data.error)
      })
  }

  return (
    <View style={[tw`bg-white h-full`, global.flexOne]}>
      <ToastNotification />
      <ScrollView style={tw`pt-8 px-5`}>
        <VendorCard
          nodeName={createPO?.node?.name || 'N/A'}
          payment={createPO?.paymentTerm?.name || 'N/A'}
          vendorName={createPO?.vendor?.display_name || 'N/A'}
          onEdit={() =>
            navigation.navigate('CreatePO', { id: 0, isEdited: true })
          }
        />
        <ProductCard
          productName={createPO?.product?.name || 'N/A'}
          quantity={`${createPO?.productQuantity} ${createPO?.productUOM?.name}`}
          pricePerUnit={createPO?.productPrice || 0}
          totalPrice={createPO?.totalAmount}
          tax={createPO?.productTax?.name}
          onEdit={() =>
            navigation.navigate('CreatePO', { id: 1, isEdited: true })
          }
        />
        <QCCard
          ownerName={createPO.qcBy || 'N/A'}
          parameters={qcParams}
          onEdit={() =>
            navigation.navigate('CreatePO', { id: 2, isEdited: true })
          }
          ncdex={createPO?.NCDEX}
        />
        <LocationCard
          pickupLocation={createPO?.pickupLocation?.address || 'N/A'}
          customerDeliveryLocation={
            (createPO.deliveryLocationType === 'Customer Location' &&
              createPO.customerDeliveryAddress.address) ||
            'N/A'
          }
          dehaatLocation={
            createPO.deliveryLocationType === 'DeHaat Warehouse'
              ? createPO.dehaatWarehouseLocation?.name
              : createPO.customerWarehouseLocation.name
          }
          pickupDate={createPO?.pickup}
          deliveryDate={createPO?.delivery}
          onEdit={() =>
            navigation.navigate('CreatePO', { id: 3, isEdited: true })
          }
        />
      </ScrollView>
      <View style={[tw`p-5 border-t`, global.borderColorDarkGrey]}>
        <Button
          title={STRINGS.BUTTON.CREATE_PO}
          onTap={() => createAPO()}
          style={tw`rounded-lg py-2.5 h-11 max-h-12`}
          disable={apiInProgress}
          loader={apiInProgress}
        />
      </View>
    </View>
  )
}

export default POPreview
