import React, { useState, useEffect } from 'react'
import { View, Text, ScrollView } from 'react-native'
import { NativeBaseProvider, Box, HStack, Center } from 'native-base'
import { useDispatch } from 'react-redux'

import { ProductDetailsProp } from 'src/navigations/types'
import STRINGS from 'constants/strings'
import { Attribute, Product } from 'src/modals'

// components
import ProductCard from 'src/components/product/ProductCard'
import Button from 'src/components/form-fields/Button'

// styles
import global from 'styles/global'
import styles from './style'

const ProductDetails = ({ route, navigation }: ProductDetailsProp) => {
  const { data, index, itemList, orderId } = route.params
  const [details, setDetails] = useState(data.quality_attributes_json)
  const dispatch = useDispatch()

  useEffect(() => {
    setDetails(data.quality_attributes_json)
  }, [data.quality_attributes_json])

  const addDefaultVariant = (product: Product) => {
    product.selected_variants = []
    if (product?.variants) {
      let name: string = ''
      let qualityRequest: any
      Object.keys(product.attributes).forEach(
        // TODO: Provide different name for index variable
        // FIXME: can be done in a readable way using map and join
        // eslint-disable-next-line @typescript-eslint/no-shadow
        (attrName: string, index: number) => {
          name += `${index !== 0 ? ', ' : ''}${
            product.attributes[attrName][0].name
          }`
        },
      )
      const dummyVariant = {
        id: product.variants[0].id,
        name,
        price_unit: 0,
        product_uom_qty: 1,
        quality_request: {},
      }
      if (product.quality_attributes_json) {
        // FIXME: spreading qualityRequest is not really necessary on each iteration
        // it causes unnecessary performance bottleneck.
        Object.keys(product.quality_attributes_json).forEach(
          (keyName: string) => {
            qualityRequest = {
              ...qualityRequest,
              [keyName]: product.quality_attributes_json[keyName][0],
            }
          },
        )
      }
      dummyVariant.quality_request = qualityRequest
      product.selected_variants.push(dummyVariant)
      return product
    }
    return undefined
  }

  const next = () => {
    if (itemList && index != null) {
      navigation.navigate('ProductDetailsEdit', {
        index,
        items: itemList,
        orderId,
      })
    } else {
      const productWithDefaultVariant = addDefaultVariant(data)
      dispatch({
        type: 'TOGGLE_PRODUCT',
        payload: data,
      })
      dispatch({
        type: 'ADD_TO_CART',
        payload: productWithDefaultVariant,
      })
      navigation.navigate('ProductList')
    }
  }

  return (
    <NativeBaseProvider>
      <Box flex={1} safeAreaTop>
        <Center flex={1}>
          <ProductCard
            data={data}
            navigation={navigation}
            style={[global.widthFull, global.vPaddingEight]}
            innerStyle={[global.bgNone]}
          />
          <ScrollView style={[global.widthFull, global.hPaddingTwenty]}>
            {!itemList &&
              data.attributes &&
              Object.keys(data.attributes).map((key: string) => (
                <View style={[]} key={key}>
                  <Text style={[styles.featureHeading]}>{key}*</Text>
                  <View style={[global.directionRow, global.flexWrap]}>
                    {data.attributes[key] &&
                      data.attributes[key].map((item: Attribute) => (
                        <Text
                          key={item.id}
                          style={[
                            styles.feature,
                            global.borderRadiusTwo,
                            global.hPaddingSixteen,
                          ]}
                        >
                          {item.name}
                        </Text>
                      ))}
                  </View>
                </View>
              ))}
            {!itemList &&
              details &&
              Object.keys(details).map((key: string) => (
                <View key={key}>
                  <Text style={[styles.featureHeading]}>{key}</Text>
                  <View style={[global.directionRow, global.flexWrap]}>
                    {details &&
                      details[key].map((val: string) => (
                        <Text
                          key={val}
                          style={[
                            global.hPaddingSixteen,
                            styles.feature,
                            global.borderRadiusTwo,
                          ]}
                        >
                          {val}
                        </Text>
                      ))}
                  </View>
                </View>
              ))}
            {itemList &&
              data.quality_request &&
              Object.keys(data.quality_request).map((key: string) => (
                <View
                  key={key}
                  style={[
                    global.directionRow,
                    global.justifyContentSpaceBetween,
                  ]}
                >
                  <Text style={[styles.featureHeading]}>{key}</Text>
                  <View style={[global.directionRow, global.flexWrap]}>
                    <Text
                      style={[
                        global.textSecondaryBlue,
                        global.fontFourteen,
                        global.textMedium,
                      ]}
                    >
                      {data.quality_request[key]}
                    </Text>
                  </View>
                </View>
              ))}
          </ScrollView>
        </Center>
        <HStack safeAreaBottom shadow={6}>
          <View
            style={[
              global.hPaddingTwenty,
              global.widthFull,
              global.bgWhite,
              styles.footer,
            ]}
          >
            <View style={[styles.footerBtn]}>
              <Button
                title={
                  itemList
                    ? STRINGS.BUTTON.EDIT_PRODUCT
                    : STRINGS.BUTTON.SELECT_PRODUCT
                }
                onTap={() => next()}
                style={global.borderRadiusEight}
              />
            </View>
          </View>
        </HStack>
      </Box>
    </NativeBaseProvider>
  )
}

export default ProductDetails
