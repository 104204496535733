import { StyleSheet } from 'react-native'
import colors from 'styles/colors'

const styles = StyleSheet.create({
  btnRemove: {
    textDecorationLine: 'underline',
    color: colors.secondaryOrange,
  },
})

export default styles
