import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  tickIcon: {
    width: 14,
    height: 11,
    marginLeft: 15,
  },
  lineIcon: {
    width: 1,
    height: 32,
    marginVertical: 9,
  },
  closeIcon: {
    height: 10,
    width: 10,
    marginLeft: 17,
    marginRight: 20,
  },
  notification: {
    height: 50,
    width: '90%',
  },
  msg: {
    marginRight: 10,
    marginLeft: 10,
  },
  toastContainer: {
    zIndex: 9999,
  },
})

export default styles
