import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, NativeBaseProvider } from 'native-base'
import {
  ActivityIndicator,
  FlatList,
  Pressable,
  Text,
  View,
  Image,
} from 'react-native'

import STRINGS from 'constants/strings'
import { PurchaseOrder, SortingOrder } from 'src/modals/purchase-order'
import { GRNHistoryProps } from 'src/navigations/types'
import { ApplicationState } from 'src/redux/types'
import {
  fetchPurchaseOrders,
  resetPurchaseOrderList,
} from 'src/redux/actions/purchase-order'
import { getEndTruncatedString, utcToLocalTime } from 'src/utilities/helpers'
import { voucherDateFormat, voucherTimeFormat } from 'src/config/voucher'

// components
import VoucherCard from 'src/components/voucher/VoucherCard'
import Label from 'src/components/card/Label'
import ProfileBar from 'src/components/card/ProfileBar'
import CustomerInformation from 'src/components/CustomerInformation'
import VoucherMetadata from 'src/components/voucher/VoucherMetadata'

// style
import global from 'styles/global'
import colors from 'styles/colors'
import style from './style'

const String = STRINGS.PURCHASE_ORDER
const StrMisc = STRINGS.MISCELLANEOUS

interface POReducerType {
  purchaseOrderList: PurchaseOrder[]
  totalPurchaseOrderCount: number
  isPOListLoading: boolean
}

// TODO: If navigation is not required. Remove it.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GRNHistory = ({ navigation }: GRNHistoryProps) => {
  const dispatch = useDispatch()
  const [listOffset, setListOffset] = useState<number>(0)
  const listFetchLimit: number = 10
  const [sortingOrder, setSortingOrder] = useState<SortingOrder>('DESC')
  const {
    purchaseOrderList,
    totalPurchaseOrderCount,
    isPOListLoading,
  }: POReducerType = useSelector(
    (state: ApplicationState) => state.purchaseOrder,
  )

  useEffect(() => {
    dispatch(fetchPurchaseOrders(listFetchLimit, listOffset, sortingOrder))
    // TODO: Provide an explanation for why all deps are not included or fix it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listOffset, sortingOrder])

  useEffect(() => {
    return () => {
      dispatch(resetPurchaseOrderList())
    }
    // TODO: Provide an explanation for why all deps are not included or fix it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const loadNextPage = () => {
    if (
      purchaseOrderList!.length !== totalPurchaseOrderCount &&
      !isPOListLoading
    ) {
      setListOffset(listOffset + listFetchLimit)
    }
  }

  const toggleSortingOrder = () => {
    setSortingOrder(sortingOrder === 'ASC' ? 'DESC' : 'ASC')
    setListOffset(0)
    dispatch(resetPurchaseOrderList())
  }

  const renderPurchaseOrder = ({ item: po }: { item: PurchaseOrder }) => (
    <Pressable
      onPress={() => {}}
      style={[global.vMarginEight, global.hMarginTwenty]}
    >
      <VoucherCard
        header={
          <Label
            image={require('images/tag.png')}
            text={po.po_name}
            containerStyle={[global.bgMint, style.label]}
            textStyle={[
              global.textPrimaryBlack,
              global.textMedium,
              global.fontTwelve,
              global.hPaddingFive,
            ]}
            imageStyle={style.tagIcon}
          />
        }
        body={
          <>
            <CustomerInformation
              customerName={po.customer.name || StrMisc.NOT_AVAILABLE}
              address={po.customer.address}
              style={global.hPaddingTwenty}
            />
            <VoucherMetadata
              id={po.id}
              attributes={[
                {
                  attrName: String.TOTAL_AMOUNT,
                  attrValue: Intl.NumberFormat('en-IN', {
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0,
                  }).format(po.amount_total),
                  icon: require('images/currency.png'),
                  iconStyle: style.attributeIcons,
                  attrStyle: global.fontFourteen,
                  attrContainerStyle: global.flexFive,
                },
                {
                  attrName: String.TOTAL_GRNS,
                  attrValue: po.grn_count,
                  icon: require('images/note_cascade.png'),
                  iconStyle: style.attributeIcons,
                  attrStyle: global.fontFourteen,
                  attrContainerStyle: global.flexFour,
                },
              ]}
              containerStyle={[
                global.bgNone,
                global.hPaddingSixteen,
                global.paddingTopTen,
              ]}
            />
            <ProfileBar
              name={po.responsible.name || StrMisc.NOT_AVAILABLE}
              imageKey={po.responsible.image || StrMisc.NOT_AVAILABLE}
              designation={po.responsible.designation || StrMisc.NOT_AVAILABLE}
              mobile={po.responsible.mobile || StrMisc.NOT_AVAILABLE}
              containerStyle={global.bgLightBlue}
            />
          </>
        }
        footer={
          <VoucherMetadata
            id={po.id}
            attributes={[
              {
                attrName: String.LAST_UPDATED_GRN_DATE,
                attrValue: po.last_update
                  ? utcToLocalTime(po.last_update, voucherDateFormat)
                  : getEndTruncatedString(
                      STRINGS.MISCELLANEOUS.DATE_NOTE_AVAILABLE,
                    ),
                icon: require('images/calendar.png'),
                iconStyle: style.footerAttributeIcons,
                attrStyle: global.paddingTopFour,
              },
              {
                attrName: String.LAST_UPDATED_GRN_TIME,
                attrValue: po.last_update
                  ? utcToLocalTime(po.last_update, voucherTimeFormat)
                  : getEndTruncatedString(
                      STRINGS.MISCELLANEOUS.TIME_NOTE_AVAILABLE,
                    ),
                icon: require('images/clock.png'),
                iconStyle: style.footerAttributeIcons,
                attrStyle: global.paddingTopFour,
              },
            ]}
            containerStyle={[global.bgNone, global.hPaddingSixteen]}
          />
        }
      />
    </Pressable>
  )

  return (
    <NativeBaseProvider>
      <Box flex={1} safeAreaTop>
        <View
          style={[
            global.hMarginTwenty,
            global.directionRow,
            global.justifyContentSpaceBetween,
            global.alignItemsCenter,
            style.header,
          ]}
        >
          <Text
            style={[
              global.textSecondaryGrey,
              global.textRegular,
              global.fontTwelve,
            ]}
          >
            {`${String.TOTAL_POS}: ${Intl.NumberFormat('en-IN', {
              maximumFractionDigits: 0,
              minimumFractionDigits: 0,
            }).format(totalPurchaseOrderCount || 0)}`}
          </Text>
          <Pressable
            style={[global.directionRow, global.justifyContentSpaceBetween]}
            onPress={toggleSortingOrder}
          >
            <Image
              source={require('images/exchange.png')}
              style={style.iconRecent}
            />
            <Text
              style={[
                global.textMedium,
                global.fontFourteen,
                global.textPrimaryGreen,
                style.marginLeftFive,
              ]}
            >
              {STRINGS.MISCELLANEOUS.SORT_BY_DATE}
            </Text>
          </Pressable>
        </View>
        {totalPurchaseOrderCount && (
          <FlatList
            keyExtractor={item => `${item.id.toString()}purchase-order`}
            renderItem={renderPurchaseOrder}
            data={purchaseOrderList || []}
            onEndReached={loadNextPage}
            onEndReachedThreshold={0.2}
          />
        )}
        {isPOListLoading && (
          <ActivityIndicator size="large" color={colors.primaryGreen} />
        )}
      </Box>
    </NativeBaseProvider>
  )
}

export default GRNHistory
