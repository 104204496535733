import { StyleSheet } from 'react-native'
import colors from 'styles/colors'

const styles = StyleSheet.create({
  progressBar: {
    height: 5,
    borderColor: colors.bgLightBlue,
    backgroundColor: colors.bgLightBlue,
  },
})

export default styles
