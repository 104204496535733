import { StyleSheet } from 'react-native'
import colors from 'styles/colors'

const styles = StyleSheet.create({
  button: {
    padding: 10,
    minHeight: '10%',
    height: '100%',
    maxHeight: 48,
  },
  text: {
    fontSize: 16,
    color: colors.white,
  },
  secondaryBtn: {
    backgroundColor: colors.white,
    borderColor: colors.primaryGreen,
    borderWidth: 1,
  },
  iconSize: {
    width: 16,
    height: 16,
  },
  secondaryBtnDisable: {
    backgroundColor: colors.lightGray,
    borderColor: colors.greenDisabled,
    color: colors.greenDisabled,
    borderWidth: 1,
  },
})

export default styles
