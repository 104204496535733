import { StyleSheet } from 'react-native'
import colors from 'styles/colors'

const styles = StyleSheet.create({
  tabIndicator: {
    width: '14%',
    height: 4,
    borderRadius: 2,
    left: '18%',
    backgroundColor: colors.bgLightGreen,
  },
  iconCancel: {
    width: 10,
    height: 10,
  },
  footerBtn: {
    height: 45,
    maxHeight: 50,
  },
  footer: {
    bottom: 0,
  },
})

export default styles
