import { Product } from 'src/modals'
import { CartActions } from 'src/redux/types'

type CartState = {
  products: Product[]
}

const initialState = {
  products: [],
}

const CartReducer = (state: CartState = initialState, action: CartActions) => {
  switch (action.type) {
    case 'ADD_TO_CART': {
      return {
        ...state,
        products: [...state.products, action.payload],
      }
    }
    case 'REMOVE_FROM_CART': {
      const elementIndex = state.products.findIndex(
        element => element.id === action.payload.id,
      )
      const newArray = [...state.products]
      newArray.splice(elementIndex, 1)
      return {
        ...state,
        products: [...newArray],
      }
    }
    case 'UPDATE_CART': {
      const elementIndex = state.products.findIndex(
        element => element.id === action.payload.id,
      )
      const newArray = [...state.products]
      newArray[elementIndex] = { ...action.payload }
      return {
        ...state,
        products: [...newArray],
      }
    }
    case 'RESET_CART': {
      return {
        ...state,
        products: [],
      }
    }
    default:
      return state
  }
}

export { CartReducer }
