import React, { useState, ReactElement, useEffect } from 'react'
import { View, Text, Image, Pressable } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import STRINGS from 'constants/strings'
import { formatter } from 'src/utilities/helpers'
import { get, URL } from 'src/utilities/axios'
import { Customer } from 'src/modals'
import { ApplicationState } from 'src/redux/types'

// components
import Modal from 'src/components/Modal'
import Button from 'src/components/form-fields/Button'

// styles
import global from 'styles/global'
import styles from './style'

interface CustomerCardProp {
  data: Customer
  navigation: {
    navigate: Function
  }
  footerButton?: ReactElement
  voucherCount?: Number
  orderId?: Number
}

const CustomerCard = ({
  data,
  navigation,
  footerButton,
  voucherCount,
  orderId,
}: CustomerCardProp) => {
  const customerStore = useSelector(
    (state: ApplicationState) => state.customersReducer,
  )
  const productStore = useSelector(
    (state: ApplicationState) => state.productsReducer,
  )
  const cartStore = useSelector((state: ApplicationState) => state.cartReducer)
  const { customer } = customerStore
  const { list } = productStore
  const { products } = cartStore
  const dispatch = useDispatch()
  const [showModal, setShowModal] = useState(false)
  const [modalBody, setModalBody] = useState<ReactElement>(<></>)
  const [modalFooter, setModalFooter] = useState<ReactElement>(<></>)

  const clearStore = () => {
    dispatch({
      type: 'SET_CUSTOMER',
      payload: data,
    })
    if (products.length > 0)
      dispatch({
        type: 'RESET_CART',
      })
    setShowModal(false)
    navigation.navigate('ProductList')
  }

  // Create modal to clear the cart if customer is changed
  const createAndShowModal = () => {
    setModalBody(
      <View style={global.alignItemsCenter}>
        <Image
          style={[styles.warning]}
          source={require('images/warning.png')}
        />
        <Text
          style={[
            global.fontSixteen,
            global.textMedium,
            global.textColorGrey,
            styles.warningMsg,
          ]}
        >
          {STRINGS.ORDER.ITEM_PRESENT_MSG}
        </Text>
        <View style={[global.widthFull]}>
          <Text
            style={[
              global.fontFourteen,
              global.textRegular,
              global.textColorGrey,
              global.textAlignCenter,
              styles.warningDescMsg,
            ]}
          >
            {STRINGS.ORDER.DELETE_CART_ITEMS_WARNING}
          </Text>
        </View>
      </View>,
    )
    setModalFooter(
      <View
        style={[
          global.directionRow,
          global.justifyContentSpaceBetween,
          styles.btnContainer,
        ]}
      >
        <Button
          title={STRINGS.BUTTON.YES}
          onTap={() => clearStore()}
          btnType="secondary"
          style={[global.widthHalf, styles.btnYesCover]}
          textStyle={global.textRegular}
        />
        <Button
          title={STRINGS.BUTTON.NO}
          onTap={() => setShowModal(false)}
          btnType="secondary"
          style={[global.widthHalf, styles.btnNoCover]}
          textStyle={[styles.btnNo, global.textRegular]}
        />
      </View>,
    )
    setShowModal(true)
  }

  const create = () => {
    if (customer.id !== data.id && products.length > 0) createAndShowModal()
    else {
      dispatch({
        type: 'SET_CUSTOMER',
        payload: data,
      })
      navigation.navigate('ProductList')
    }
  }

  const viewOrderDetails = () => {
    if (voucherCount === 0) return
    if (voucherCount && voucherCount > 0)
      get(`${URL.ORDERS}/${orderId}`)
        // TODO: Provide variable 'data' different name
        // eslint-disable-next-line @typescript-eslint/no-shadow
        .then(data => {
          navigation.navigate('OrderHistoryDetails', { data: data?.data })
        })
        .catch(error => console.error(error))
    else navigation.navigate('OrderDetails', { orderId: data.order_id })
  }

  useEffect(() => {
    if (list.length > 0)
      dispatch({
        type: 'RESET_PRODUCT_LIST',
      })
    // TODO: Provide an explanation for why all deps are not included or fix it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer])

  return (
    <Pressable
      style={[
        global.widthFull,
        global.bgWhite,
        global.paddingTwenty,
        global.borderRadiusEight,
        styles.marginBottomFifteen,
        styles.card,
      ]}
      onPress={() => {
        if (data?.order_name) viewOrderDetails()
        else create()
      }}
    >
      <Text
        style={[global.fontSixteen, global.textMedium, global.textPrimaryBlack]}
      >
        {data.name}
      </Text>
      <View
        style={[
          global.directionRow,
          global.vPaddingEight,
          global.justifyContentSpaceBetween,
        ]}
      >
        {data.create_date && (
          <View style={global.directionRow}>
            <Image
              source={require('images/calendar.png')}
              style={[styles.icon]}
            />
            <View>
              <Text
                style={[
                  global.fontTwelve,
                  global.textSecondaryBlue,
                  global.textRegular,
                  styles.mobileText,
                ]}
              >
                {data.create_date}
              </Text>
            </View>
          </View>
        )}
        <View style={global.directionRow}>
          <Image source={require('images/mobile.png')} style={[styles.icon]} />
          <View>
            <Text
              style={[
                global.fontTwelve,
                global.textSecondaryBlue,
                global.textRegular,
                styles.mobileText,
              ]}
            >
              {data.mobile}
            </Text>
          </View>
        </View>
      </View>
      {data?.address && (
        <View style={[global.directionRow, global.vPaddingEight]}>
          <Image
            source={require('images/location.png')}
            style={[styles.icon]}
          />
          <View>
            <Text
              style={[
                global.fontTwelve,
                global.textSecondaryBlue,
                global.textRegular,
                styles.mobileText,
              ]}
            >
              {data?.address}
            </Text>
          </View>
        </View>
      )}
      {data?.order_name ? (
        <View
          style={[
            global.directionRow,
            global.vPaddingEight,
            styles.orderNameContainer,
          ]}
        >
          <Image source={require('images/tag.png')} style={[styles.icon]} />
          <Text
            style={[
              global.fontTwelve,
              global.textSecondaryBlue,
              global.textRegular,
              styles.mobileText,
            ]}
          >
            {data.order_name}
          </Text>
        </View>
      ) : (
        <View style={[global.vPaddingSixteen]}>
          <View>
            <Text
              style={[
                global.fontFourteen,
                global.textRegular,
                global.textPrimaryBlack,
              ]}
            >
              {STRINGS.MISCELLANEOUS.OUTSTANDING_BALANCE}
            </Text>
          </View>
          <View style={[global.directionRow, styles.outstandCnt]}>
            <Text
              style={[
                global.fontSixteen,
                global.textBold,
                global.textPrimaryBlack,
              ]}
            >
              {formatter.format(Number(data.outstanding))}
            </Text>
          </View>
        </View>
      )}
      {data?.order_name ? (
        <View
          style={[
            global.directionRow,
            global.justifyContentSpaceBetween,
            global.vPaddingSixteen,
            styles.btnCntrBorder,
          ]}
        >
          <View>
            <Text style={[global.fontFourteen, global.textRegular]}>
              {STRINGS.BUTTON.TOTAL_ORDER_AMOUNT}
            </Text>
            <Text style={[global.fontSixteen, global.textBold]}>
              {formatter.format(Number(data.amount_total))}
            </Text>
          </View>
          {footerButton || (
            <View style={global.justifyContentCenter}>
              <Image
                source={require('images/arrow-right.png')}
                style={[styles.icon]}
              />
            </View>
          )}
        </View>
      ) : (
        <View
          style={[
            global.directionRow,
            global.justifyContentSpaceBetween,
            global.vPaddingSixteen,
            styles.btnCntrBorder,
          ]}
        >
          <View>
            <Text
              style={[
                global.fontFourteen,
                global.textBold,
                styles.btnCreateOrder,
              ]}
            >
              {STRINGS.BUTTON.CREATE_ORDER}
            </Text>
          </View>
          <View style={global.justifyContentCenter}>
            <Image
              source={require('images/arrow-right.png')}
              style={[styles.iconArrowRight]}
            />
          </View>
        </View>
      )}
      {showModal && (
        <Modal
          body={modalBody}
          footer={modalFooter}
          close={() => setShowModal(false)}
        />
      )}
    </Pressable>
  )
}

export default CustomerCard
