import React, { useState, useEffect } from 'react'
import { View, ScrollView, ActivityIndicator, Image, Text } from 'react-native'

import { DownloadInvoiceProps, Invoice } from 'src/modals'
import STRINGS from 'constants/strings'
import {
  getDocName,
  getEndTruncatedString,
  getTruncatedString,
  utcToLocalTime,
} from 'src/utilities/helpers'
import {
  voucherDateFormat,
  voucherTimeFormat,
  orderStatusObj,
} from 'src/config/voucher'
import { get, post, URL } from 'src/utilities/axios'
import { DOWNLOAD } from 'src/config/download'
import { CreditInvoiceDetailsScreenNavigationProp } from 'src/navigations/types'
import useDownloadFrmS3 from 'src/utilities/hooks/useDownloadFrmS3'

// components
import TotalAmount from 'src/components/voucher/TotalAmount'
import VoucherMetadata from 'src/components/voucher/VoucherMetadata'
import VoucherStatus from 'src/components/voucher/VoucherStatus'
import DetailItemCard from 'src/components/voucher/DetailItemCard'
import IconButton from 'src/components/form-fields/ButtonWithIcon'
import Button from 'src/components/form-fields/Button'
import DownloadDoc from 'src/components/drawers/DownloadDoc'
import OptionSelector from 'src/components/drawers/OptionSelector'
import Modal from 'src/components/Modal'

// styles
import global from 'styles/global'
import colors from 'styles/colors'
import styles from '../style'

const String = STRINGS.INVOICE

export interface PrimaryDetailsProps {
  route: {
    invoice: Invoice
    navigation: CreditInvoiceDetailsScreenNavigationProp
  }
}

interface DownloadDrawerProps {
  isDrawerOpen: boolean
  drawerDoc: DownloadInvoiceProps[]
  successMeg: string
}

export interface UpdatedInvoiceDetailsProps {
  credit_note: number
  new_invoice: Invoice
}

const PrimaryDetails = ({ route }: PrimaryDetailsProps) => {
  const { invoice, navigation } = route
  const [invoiceDoc, setInvoiceDoc] = useState<DownloadInvoiceProps[]>([])
  const [creditNoteDoc, setCreditNoteDoc] = useState<DownloadInvoiceProps[]>([])
  const [downloadDrawer, setDownloadDrawer] = useState<DownloadDrawerProps>({
    isDrawerOpen: false,
    drawerDoc: [],
    successMeg: '',
  })
  const [reasonDrawer, setReasonDrawer] = useState<boolean>(false)
  const [selectedReasonId, setSelectedReasonId] = useState<number>()
  const [creditNoteReason, setCreditNoteReason] = useState<any>([])
  const [showModal, setShowModal] = useState(false)
  const [modalBody, setModalBody] = useState(<></>)
  const [modalFooter, setModalFooter] = useState(<></>)
  const { downloadDocFrmS3 } = useDownloadFrmS3()

  const setDocsToDownload = (docs: DownloadInvoiceProps[]) => {
    const creditNotesDoc: DownloadInvoiceProps[] = []
    const invoicesDoc: DownloadInvoiceProps[] = []
    for (let i = 0; i < docs?.length; i++) {
      if (docs[i].type === DOWNLOAD.INVOICE_DOC_TYPE) invoicesDoc.push(docs[i])
      else if (docs[i].type === DOWNLOAD.CREDIT_DOC_TYPE)
        creditNotesDoc.push(docs[i])
    }
    setInvoiceDoc(invoicesDoc)
    setCreditNoteDoc(creditNotesDoc)
  }

  const navigateToUpdateInvoice = (data: UpdatedInvoiceDetailsProps) => {
    navigation.navigate('UpdateInvoiceDetails', {
      creditNoteId: data?.credit_note,
      invoice: data?.new_invoice,
    })
  }

  const createCreditNote = () => {
    const payload = {
      invoice_id: invoice.id,
      credit_note_category_id: selectedReasonId,
    }
    post(URL.CREATE_CREDIT_NOTE, payload)
      .then(data => data?.data && navigateToUpdateInvoice(data.data))
      .catch(err => console.error(err))
  }

  const openCreateCreditNote = () => {
    setModalBody(
      <View style={global.alignItemsCenter}>
        <Image
          style={styles.fileAddIcon}
          source={require('images/file-add.png')}
        />
        <View style={global.widthFull}>
          <Text
            style={[
              global.fontFourteen,
              global.textRegular,
              global.textColorGrey,
              global.textAlignCenter,
              styles.warningMsg,
            ]}
          >
            {STRINGS.CREDIT_NOTE.CREATE_CREDIT_NOTE}
          </Text>
        </View>
      </View>,
    )
    setModalFooter(
      <View
        style={[
          global.directionRow,
          global.justifyContentSpaceBetween,
          styles.btnContainer,
        ]}
      >
        <Button
          title={STRINGS.BUTTON.NO}
          onTap={() => setShowModal(false)}
          btnType="secondary"
          style={[global.widthHalf, styles.btnNoCover]}
          textStyle={[global.textRegular, global.textSecondaryOrange]}
        />
        <Button
          title={STRINGS.BUTTON.YES}
          onTap={() => {
            createCreditNote()
            setShowModal(false)
          }}
          btnType="secondary"
          style={[global.widthHalf, styles.btnYesCover]}
          textStyle={global.textRegular}
        />
      </View>,
    )
    setShowModal(true)
  }

  const fetchDocIdToDownload = () => {
    if (invoice?.id) {
      get(`${URL.INVOICE_DOWNLOAD}/${invoice?.id}`)
        .then(data => data?.data?.length > 0 && setDocsToDownload(data.data))
        .catch(error => console.error(error))
    }
  }

  useEffect(() => {
    fetchDocIdToDownload()
    // TODO: Provide an explanation for why all deps are not included or fix it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoice?.id])

  const onInvoiceClick = () => {
    if (invoiceDoc?.length > 1)
      setDownloadDrawer({
        isDrawerOpen: true,
        drawerDoc: invoiceDoc,
        successMeg: String.INVOICE_DOWNLOADED,
      })
    else {
      const docName = getDocName(invoiceDoc[0])
      downloadDocFrmS3(
        invoiceDoc[0]?.store_fname,
        docName,
        String.INVOICE_DOWNLOADED,
      )
    }
  }

  const onCreditNoteClick = () => {
    if (creditNoteDoc?.length > 1)
      setDownloadDrawer({
        isDrawerOpen: true,
        drawerDoc: creditNoteDoc,
        successMeg: String.CREDIT_NOTE_DOWNLOADED,
      })
    else {
      const docName = getDocName(creditNoteDoc[0])
      downloadDocFrmS3(
        creditNoteDoc[0]?.store_fname,
        docName,
        String.CREDIT_NOTE_DOWNLOADED,
      )
    }
  }

  const reasonButtonClick = (index: number | null) => {
    if (index !== null) setSelectedReasonId(creditNoteReason[index]?.id)
  }

  const onCreateCreditNoteClick = () => {
    if (creditNoteReason.length === 0) {
      get(URL.CREDIT_NOTE_CATEGORY)
        .then(data => setCreditNoteReason(data?.data))
        .catch(error => console.error(error))
    }
    setReasonDrawer(true)
  }

  return (
    <>
      {invoice ? (
        <View style={[global.flexOne, global.zeroHeight]}>
          <VoucherMetadata
            id={invoice.id}
            attributes={[
              {
                icon: require('images/id.png'),
                attrName: String.INVOICE_ID,
                attrValue:
                  invoice.invoice_id && invoice?.invoice_id.length > 10
                    ? getTruncatedString(invoice?.invoice_id)
                    : invoice.invoice_id || STRINGS.MISCELLANEOUS.NOT_AVAILABLE,
              },
              {
                icon: require('images/calendar.png'),
                attrName: String.INVOICE_DATE,
                attrValue: invoice.write_date
                  ? utcToLocalTime(invoice.write_date, voucherDateFormat)
                  : getEndTruncatedString(
                      STRINGS.MISCELLANEOUS.DATE_NOTE_AVAILABLE,
                    ),
              },
              {
                icon: require('images/clock.png'),
                attrName: String.INVOICE_TIME,
                attrValue: invoice?.write_date
                  ? utcToLocalTime(invoice?.write_date, voucherTimeFormat)
                  : getEndTruncatedString(
                      STRINGS.MISCELLANEOUS.TIME_NOTE_AVAILABLE,
                    ),
              },
            ]}
          />
          <View style={global.bgWhite}>
            <TotalAmount
              amount={invoice.total_amount || 0}
              textStyle={global.textPrimaryBlack}
              containerStyle={[
                global.borderTopWidthOne,
                global.borderTopColorbgLightBlue,
              ]}
            />
          </View>
          <View
            style={[
              global.bgWhite,
              global.hPaddingTwenty,
              global.vPaddingSixteen,
              global.directionRow,
              global.justifyContentSpaceBetween,
            ]}
          >
            <View style={global.alignItemsLeft}>
              <VoucherStatus
                dateAligned={false}
                status={invoice.state}
                date={
                  invoice.write_date
                    ? utcToLocalTime(invoice.write_date, voucherDateFormat)
                    : getEndTruncatedString(
                        STRINGS.MISCELLANEOUS.DATE_NOTE_AVAILABLE,
                      )
                }
                iconPath={
                  orderStatusObj[invoice.state] &&
                  orderStatusObj[invoice.state].iconPath
                }
                textColor={
                  orderStatusObj[invoice.state] &&
                  orderStatusObj[invoice.state].color
                }
              />
            </View>
            <View style={global.directionRow}>
              {creditNoteDoc?.length > 0 && (
                <IconButton
                  text={String.CREDIT_NOTE}
                  containerStyle={[
                    global.hMarginTwenty,
                    global.bgMint,
                    styles.iconButton,
                  ]}
                  icon={require('images/download.png')}
                  onClick={onCreditNoteClick}
                  textStyle={[global.textPrimaryGreen, styles.iconButtonText]}
                />
              )}
              {invoiceDoc?.length > 0 && (
                <IconButton
                  text={String.INVOICE}
                  icon={require('images/download.png')}
                  onClick={onInvoiceClick}
                  textStyle={[global.textPrimaryGreen, styles.iconButtonText]}
                  containerStyle={[global.bgMint, styles.iconButton]}
                />
              )}
            </View>
          </View>
          <View style={[global.flexOne, global.zeroHeight]}>
            <ScrollView>
              {invoice.items?.map((voucherItem, index) => (
                <DetailItemCard
                  // TODO: Need to provide reason for why index key or else fix it
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${voucherItem.product_name}__${index}`}
                  name={voucherItem.product_name}
                  imageS3Key={voucherItem.product_image}
                  doneQty={voucherItem.quantity_done}
                  unitOfMeasure={voucherItem.product_uom[1]}
                  unitPrice={voucherItem.price_unit}
                  tax={voucherItem.tax_display_name}
                />
              ))}
            </ScrollView>
          </View>
          <View
            style={[
              global.bgWhite,
              global.vPaddingTen,
              global.hPaddingTwenty,
              styles.creditFooter,
            ]}
          >
            <Button
              title={STRINGS.BUTTON.CREATE_CREDIT_NOTE}
              style={[global.borderRadiusEight, styles.creditFooterButton]}
              btnType="primary"
              disable={
                invoice?.state === STRINGS.MISCELLANEOUS.CANCEL ||
                invoice?.state === STRINGS.MISCELLANEOUS.DRAFT
              }
              onTap={() => onCreateCreditNoteClick()}
            />
          </View>
          {downloadDrawer.isDrawerOpen && (
            <DownloadDoc
              closeDrawer={() =>
                setDownloadDrawer({
                  isDrawerOpen: false,
                  drawerDoc: [],
                  successMeg: '',
                })
              }
              docs={downloadDrawer.drawerDoc}
              successMsg={downloadDrawer.successMeg}
            />
          )}
          {reasonDrawer && (
            <OptionSelector
              title={STRINGS.CREDIT_NOTE.SELECT_REASON}
              closeDrawer={() => setReasonDrawer(false)}
              optionButtons={creditNoteReason}
              optionOnClick={reasonButtonClick}
              footerButton={{
                title: STRINGS.BUTTON.CONFIRM_CREDIT_NOTE,
                onClick: () => {
                  setReasonDrawer(false)
                  openCreateCreditNote()
                },
              }}
            />
          )}
          {showModal && (
            <Modal
              body={modalBody}
              footer={modalFooter}
              close={() => setShowModal(false)}
            />
          )}
        </View>
      ) : (
        <ActivityIndicator
          size="large"
          color={colors.primaryGreen}
          style={global.vMarginSixteen}
        />
      )}
    </>
  )
}

export default PrimaryDetails
