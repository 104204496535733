import React, { useState } from 'react'
import { View, Image, Pressable, Text } from 'react-native'

// components
import Button from 'src/components/form-fields/Button'
import InputTextField from 'src/components/form-fields/InputTextField'

import STRINGS from 'constants/strings'

// styles
import global from 'styles/global'
import colors from 'styles/colors'
import style from './style'

interface AddNoteProps {
  setDrawer: Function
  addNote: Function
  note?: string
}

// Add note is drawer which require function setDrawer to close drawer and addNote to add note
const AddNoteContent = ({ setDrawer, addNote, note }: AddNoteProps) => {
  const [text, setText] = useState<string>(note || '')

  return (
    <View style={[global.drawer, style.noteDrawerWrapper]}>
      <View style={global.alignItemsRight}>
        <Pressable
          onPress={() => setDrawer(false)}
          style={global.paddingSixteen}
        >
          <Image
            source={require('images/cancel.png')}
            style={style.iconCancel}
          />
        </Pressable>
      </View>
      <View
        style={[
          global.directionRow,
          global.hPaddingTwenty,
          global.alignItemsCenter,
        ]}
      >
        <Text
          style={[
            global.textColorGrey,
            global.fontFourteen,
            global.textRegular,
          ]}
        >
          {note ? STRINGS.VOUCHER.EDIT_NOTE : STRINGS.VOUCHER.ADD_NOTE}
        </Text>
        <Text
          style={[
            global.fontTwelve,
            global.textSecondaryBlue50,
            global.textItalic,
          ]}
        >
          {STRINGS.VOUCHER.NOTE_MAX_LENGTH}
        </Text>
      </View>
      <View style={[global.hPaddingTwenty, global.vPaddingSixteen]}>
        <InputTextField
          placeholder={STRINGS.FORM_FIELDS.ADD_DESCRIPTION}
          onTextChange={(value: string) => setText(value)}
          maxLength={500}
          multiline
          style={[
            global.borderRadiusFour,
            global.borderWidthOne,
            style.inputWrapper,
          ]}
          innerStyle={[
            global.paddingTen,
            global.fontTwelve,
            global.textRegular,
            global.textAlignTop,
            global.textSecondaryBlue70,
            global.widthFull,
          ]}
          placeholderTextColor={colors.secondaryTextBlue50}
          value={text}
        />
      </View>
      <View
        style={[
          global.directionRow,
          global.hPaddingTwenty,
          global.alignItemsCenter,
        ]}
      >
        <Image source={require('images/info.png')} style={style.iconInfo} />
        <Text
          style={[
            global.fontTwelve,
            global.textSecondaryBlue50,
            global.textItalic,
          ]}
        >
          {STRINGS.VOUCHER.NOTE_INFO}
        </Text>
      </View>
      <View style={[global.paddingTwenty, global.widthFull, global.bgWhite]}>
        <View style={style.footerBtn}>
          <Button
            title={
              note ? STRINGS.BUTTON.SAVE_CHANGES : STRINGS.VOUCHER.ADD_NOTE
            }
            onTap={() => addNote(text)}
            style={global.borderRadiusEight}
            disable={text.length === 0}
          />
        </View>
      </View>
    </View>
  )
}

export default AddNoteContent
