import { StyleSheet } from 'react-native'
import colors from 'styles/colors'

const styles = StyleSheet.create({
  logo: {
    height: 37,
    width: 101,
    marginTop: 100,
  },
  titleContainer: {
    marginTop: 45,
  },
  name: {
    fontSize: 18,
    color: colors.primaryTextBlack,
  },
  inputPlaceholder: {
    fontSize: 14,
    paddingTop: 7,
    color: colors.textGrey,
  },
  loginBtn: {
    marginTop: 34,
    height: 45,
    maxHeight: 50,
  },
  inputContainer: {
    marginTop: 30,
  },
  errorMessage: {
    marginTop: 5,
  },
})

export default styles
