import { StyleSheet } from 'react-native'
import colors from 'styles/colors'

const styles = StyleSheet.create({
  orderHistoryTabIndicator: {
    width: '14%',
    height: 4,
    borderRadius: 2,
    left: '18%',
    backgroundColor: colors.bgLightGreen,
  },
  dispatchTruckIcon: {
    width: 19,
    height: 16,
  },
  voucherCount: {
    marginLeft: 5,
    marginRight: 3,
  },
})

export default styles
