import { StyleSheet, Dimensions } from 'react-native'

const windowWidth = Dimensions.get('window').width

const styles = StyleSheet.create({
  amountTile: {
    width: windowWidth / 2 - 30,
    height: 132,
    maxHeight: 132,
    marginLeft: 20,
  },
  tileButtonIcon: {
    width: 24,
    height: 24,
  },
  bgLeftCard: {
    width: 82,
    height: 42,
    left: 0,
    top: 0,
    transform: [{ scaleX: -1 }],
  },
  bgRightCard: {
    width: 86,
    height: 44,
    right: 0,
    bottom: 0,
    transform: [{ scaleY: -1 }],
  },
  textStyle: {
    marginTop: 30,
  },
})

export default styles
