import React from 'react'
import { View, Text, Pressable } from 'react-native'

import STRINGS from 'constants/strings'
import { Attribute, Product } from 'src/modals'

// components
import Dropdown from 'src/components/form-fields/Dropdown'
import InputNumberField from 'src/components/form-fields/InputNumberField'

// styles
import global from 'styles/global'
import styles from './style'

interface ProductEditProps {
  data: Product
  variantIndex: number
  setAttribute: Function
  removeVariant?: Function
  setVariant?: Function
}

const ProductEdit = ({
  data,
  variantIndex,
  setAttribute,
  removeVariant,
  setVariant,
}: ProductEditProps) => {
  let variants: any

  const createVariantDropdownList = (arr: Attribute[]) => {
    const dataPicker: Array<{ label: string; value: string }> = []
    arr.map((item: any) =>
      dataPicker.push({ label: item.name, value: item.id }),
    )
    variants = dataPicker
    return dataPicker
  }

  const createDropdownList = (arr: string[]) => {
    const dataPicker: Array<{ label: string; value: string }> = []
    arr.map((item: string) => dataPicker.push({ label: item, value: item }))
    return dataPicker
  }

  const setVariantOption = (attribute: string, variantId: number) => {
    if (setVariant) {
      setVariant(variantIndex, attribute, variantId)
    }
  }

  return (
    <View>
      <View style={[global.paddingSixteen]}>
        {data.attributes &&
          Object.keys(data?.attributes).map((key: string, index: number) => (
            <View key={key}>
              <View
                style={[
                  global.directionRow,
                  global.justifyContentSpaceBetween,
                  global.vPaddingTen,
                ]}
              >
                <Text
                  style={[
                    global.textColorGrey,
                    global.fontFourteen,
                    global.textRegular,
                  ]}
                >
                  {`${key}*`}
                </Text>
                {data.selected_variants.length > 1 &&
                  index === 0 &&
                  removeVariant && (
                    <Pressable onPress={() => removeVariant(variantIndex)}>
                      <Text
                        style={[
                          styles.btnRemove,
                          global.fontTwelve,
                          global.textRegular,
                        ]}
                      >
                        {STRINGS.BUTTON.REMOVE}
                      </Text>
                    </Pressable>
                  )}
              </View>
              <Dropdown
                options={createVariantDropdownList(data?.attributes[key])}
                onChange={(variantId: number) =>
                  setVariantOption(key, variantId)
                }
                selectedOption={variants[0].id}
                disabled={!setVariant}
              />
            </View>
          ))}

        <View style={[global.vPaddingTen]}>
          <Text
            style={[
              global.textColorGrey,
              global.fontFourteen,
              global.vPaddingTen,
              global.textRegular,
            ]}
          >
            {`${STRINGS.FORM_FIELDS.ENTER_QUANTITY} (${data?.uom_id[1]})*`}
          </Text>
          <InputNumberField
            placeholder={(data.product_uom_qty != null
              ? data.product_uom_qty
              : data.selected_variants[variantIndex].product_uom_qty
            ).toString()}
            onTextChange={(value: string) =>
              setAttribute(variantIndex, 'product_uom_qty', Number(value))
            }
            maxLength={5}
            style={[global.borderRadiusEight, global.paddingSixteen]}
            innerStyle={[global.textColorGrey, global.fontFourteen]}
            value={(data.product_uom_qty != null
              ? data.product_uom_qty
              : data.selected_variants[variantIndex].product_uom_qty
            ).toString()}
          />
        </View>
        <View style={[global.vPaddingEight]}>
          <Text
            style={[
              global.textColorGrey,
              global.fontFourteen,
              global.vPaddingTen,
            ]}
          >
            {STRINGS.FORM_FIELDS.ENTER_PRICE}
          </Text>
          <InputNumberField
            placeholder={(data.price_unit != null
              ? data.price_unit
              : data.selected_variants[variantIndex].price_unit
            ).toString()}
            onTextChange={(value: string) =>
              setAttribute(variantIndex, 'price_unit', Number(value))
            }
            maxLength={5}
            style={[global.borderRadiusEight, global.paddingSixteen]}
            innerStyle={[global.textColorGrey, global.fontFourteen]}
            value={(data.price_unit != null
              ? data.price_unit
              : data.selected_variants[variantIndex].price_unit
            ).toString()}
          />
        </View>
        {data?.quality_attributes_json &&
          Object.keys(data.quality_attributes_json).map((keyName: string) => (
            <View key={keyName}>
              <Text
                style={[
                  global.textColorGrey,
                  global.fontFourteen,
                  global.vPaddingTen,
                ]}
              >
                {keyName}
              </Text>
              <View>
                <Dropdown
                  options={createDropdownList(
                    data.quality_attributes_json[keyName],
                  )}
                  onChange={(selected: string) =>
                    setAttribute(variantIndex, keyName, selected)
                  }
                  selectedOption={
                    data.quality_request
                      ? data.quality_request[keyName]
                      : data.quality_attributes_json[keyName][0]
                  }
                />
              </View>
            </View>
          ))}
      </View>
    </View>
  )
}

export default ProductEdit
