import React from 'react'
import { Image, View } from 'react-native'

// components
import Attribute from 'src/components/voucher/VoucherAttribute'

// style
import global from 'styles/global'
import style from './style'

interface VoucherMetadataProps {
  attributes: Array<{
    icon: any
    attrName: string
    attrValue: any
    iconStyle?: object
    attrStyle?: object
    attrContainerStyle?: object
    attrValueStyle?: object
  }>
  id: number
  lineSeparator?: boolean
  containerStyle?: object
}

const VoucherMetadata = ({
  attributes,
  id,
  lineSeparator = true,
  containerStyle,
}: VoucherMetadataProps) => (
  <View
    style={[
      global.bgLightBlue,
      global.directionRow,
      global.justifyContentSpaceBetween,
      global.vPaddingTwenty,
      global.hPaddingTen,
      containerStyle,
    ]}
  >
    {attributes?.length > 0 &&
      attributes.map((attribute, index) => (
        <View
          // TODO: Need to provide reason for why index key or else fix it
          // eslint-disable-next-line react/no-array-index-key
          key={`${id}${attribute.attrValue}${index}`}
          style={[global.directionRow, attribute.attrContainerStyle]}
        >
          <Attribute
            icon={
              <Image
                source={attribute.icon}
                style={[style.iconHeader, attribute.iconStyle]}
              />
            }
            attrName={attribute.attrName}
            attrValue={attribute.attrValue}
            attrStyle={attribute.attrStyle}
            attrValueStyle={attribute.attrValueStyle}
          />
          {lineSeparator && index !== attributes.length - 1 && (
            <View style={[global.bgWhite, style.verticleLine]} />
          )}
        </View>
      ))}
  </View>
)

export default VoucherMetadata
