import { axiosInstance as axios } from 'src/utilities/axios/config'

const get = (url: string, params: object = {}, headers: object = {}) =>
  axios.get(url, { params, headers })

const remove = (url: string, params: object, headers: object = {}) =>
  axios.delete(url, { params, headers })

const post = (url: string, body: object, headers: object = {}) =>
  axios.post(url, body, { headers })

const put = (url: string, body: object, headers: object = {}) =>
  axios.put(url, body, { headers })

const patch = (url: string, body: object, headers: object = {}) =>
  axios.patch(url, body, { headers })

export { get, remove, post, put, patch }
