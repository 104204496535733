// Auto-generated file created by react-native-storybook-loader
// Do not edit.
//
// https://github.com/elderfo/react-native-storybook-loader.git

function loadStories() {
  require('../src/components/CustomerInformation/index.stories')
}

const stories = ['../src/components/CustomerInformation/index.stories']

module.exports = {
  loadStories,
  stories,
}
