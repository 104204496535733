import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  label: {
    width: 125,
    marginTop: 18,
  },
  iconPosition: {
    marginRight: -3,
    marginBottom: -3,
    top: -9,
    right: -5,
    scaleX: 0.8,
    scaleY: 0.9,
  },
})

export default styles
