import { Dispatch } from 'react'

import { get, URL } from 'src/utilities/axios'
import { store } from 'src/redux/store'
import { ProductListResponse } from 'src/modals'
import { ProductActions } from 'src/redux/types'

export const fetchProductList = (
  category: string,
  offset: number,
  limit: number,
) => {
  return async (dispatch: Dispatch<ProductActions>) => {
    try {
      const res: ProductListResponse = await get(URL.PRODUCT_LIST, {
        category,
        limit,
        offset,
      })
      if (res.error === null) {
        dispatch({
          type: 'SET_PRODUCT_LIST',
          payload: [
            ...store.getState().productsReducer.list,
            ...res.data.products,
          ],
        })
        dispatch({
          type: 'SET_PRODUCT_COUNT',
          payload: res.data.total_count,
        })
      }
    } catch (error) {
      dispatch({
        type: 'SET_PRODUCT_ERROR',
        payload: error,
      })
    }
  }
}

export const resetProductList = () => {
  return async (dispatch: Dispatch<ProductActions>) => {
    dispatch({
      type: 'RESET_PRODUCT_LIST',
    })
  }
}
