import { User } from 'src/modals'
import { UserActions } from 'src/redux/types'

type UserState = {
  error: string | null
  userDetails: User | null
  outstandingAmount: number | null
  unbilledDVCount: number | null
}

const initialState = {
  error: '',
  userDetails: null,
  outstandingAmount: null,
  unbilledDVCount: null,
}

const UserReducer = (state: UserState = initialState, action: UserActions) => {
  switch (action.type) {
    case 'SET_USER_DETAILS': {
      return {
        ...state,
        userDetails: action.payload,
      }
    }
    case 'SET_USER_DETAILS_ERROR': {
      return {
        ...state,
        error: action.payload,
      }
    }
    case 'SET_OUTSTANDING_AMOUNT': {
      return {
        ...state,
        outstandingAmount: action.payload,
      }
    }
    case 'SET_OUTSTANDING_AMOUNT_ERROR': {
      return {
        ...state,
        error: action.payload,
      }
    }
    case 'SET_UNBILLED_DV_COUNT': {
      return {
        ...state,
        unbilledDVCount: action.payload,
      }
    }
    case 'SET_UNBILLED_DV_COUNT_ERROR': {
      return {
        ...state,
        error: action.payload,
      }
    }
    default:
      return state
  }
}

export { UserReducer }
