import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  header: {
    marginTop: 16,
    marginBottom: 8,
  },
  iconRecent: {
    width: 16,
    height: 16,
  },
  marginLeftFive: {
    marginLeft: 5,
  },
  tagIcon: {
    width: 12,
    height: 12,
  },
  label: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    width: 'fit-content',
    marginTop: 18,
  },
  attributeIcons: {
    width: 28,
    height: 28,
  },
  footerAttributeIcons: {
    width: 26,
    height: 26,
  },
})

export default styles
