import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Animated, Dimensions } from 'react-native'
import { NativeBaseProvider } from 'native-base'
import { TabView, TabBar, SceneMap } from 'react-native-tab-view'

import { fetchOrderList } from 'src/redux/actions'
import { OrderHistoryProps } from 'src/navigations/types'
import STRINGS from 'constants/strings'
import { Order } from 'src/modals'
import { ApplicationState } from 'src/redux/types'

// components
import global from 'styles/global'
import Tab, { TabProps } from './Tab'

// styles
import styles from './style'

const renderScene = SceneMap({
  inProgress: ({ route }: TabProps) => <Tab route={route} />,
  pastOrders: ({ route }: TabProps) => <Tab route={route} />,
})

const OrderHistory = ({ navigation }: OrderHistoryProps) => {
  const dispatch = useDispatch()
  const [index, setIndex] = useState<number>(0)
  const [inProgressOrders, setInProgressOrders] = useState<Order[]>([])
  const [pastOrders, setPastOrders] = useState<Order[]>([])

  const orderStore: { list: Order[] } = useSelector(
    (state: ApplicationState) => state.orderReducer,
  )

  useEffect(() => {
    if (orderStore.list && index === 0) setInProgressOrders(orderStore.list)
    else setPastOrders(orderStore.list)
    // TODO: Provide an explanation for why all deps are not included or fix it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderStore.list])

  useEffect(() => {
    dispatch(fetchOrderList(index === 0 ? 'confirmed' : 'closed'))
  }, [dispatch, index])

  useEffect(() => {
    setPastOrders([])
    setInProgressOrders([])
  }, [index])

  const renderTabBar = (props: any) => (
    <TabBar
      {...props}
      style={global.bgWhite}
      renderLabel={({ route, focused }) => (
        <Animated.Text
          style={
            focused
              ? [global.textPrimaryBlack, global.textMedium]
              : [global.textSecondaryGrey, global.textMedium]
          }
        >
          {route.title}
        </Animated.Text>
      )}
      indicatorStyle={styles.orderHistoryTabIndicator}
    />
  )

  const routes = [
    {
      key: 'inProgress',
      title: `${STRINGS.ORDER.IN_PROGRESS}`,
      orderList: inProgressOrders,
      navigation,
    },
    {
      key: 'pastOrders',
      title: `${STRINGS.ORDER.PAST_ORDERS}`,
      orderList: pastOrders,
      navigation,
    },
  ]

  return (
    <NativeBaseProvider>
      <TabView
        navigationState={{ index, routes }}
        renderScene={renderScene}
        renderTabBar={renderTabBar}
        onIndexChange={setIndex}
        initialLayout={{
          width: Dimensions.get('window').width,
        }}
      />
    </NativeBaseProvider>
  )
}

export default OrderHistory
