import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  shoppingCart: {
    height: 90,
    width: 90,
  },
  textContainer: {
    marginTop: 100,
  },
  btnCreateOrder: {
    marginTop: 69,
    bottom: 0,
    height: 45,
  },
})

export default styles
