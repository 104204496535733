import React from 'react'
import { View, Text, Image } from 'react-native'

// style
import global from 'styles/global'

interface LabelProps {
  text?: any
  image?: any
  containerStyle?: object
  textStyle?: object
  imageStyle?: object
}

const Label = ({
  text,
  image,
  containerStyle,
  textStyle,
  imageStyle,
}: LabelProps) => (
  <View
    style={[
      global.hPaddingTwenty,
      global.vPaddingTen,
      global.borderRadiusFive,
      global.directionRow,
      containerStyle,
    ]}
  >
    {image && <Image source={image} style={imageStyle} />}
    {text && <Text style={textStyle}>{text}</Text>}
  </View>
)

export default Label
