import React, { useState, useEffect } from 'react'
import { NavigationContainer } from '@react-navigation/native'
import { createStackNavigator } from '@react-navigation/stack'
import { useSelector, useDispatch } from 'react-redux'
import { View, Text, Image, TouchableOpacity, Pressable } from 'react-native'
import tw from 'tailwind-react-native-classnames'
import AsyncStorage from '@react-native-async-storage/async-storage'

import { ApplicationState } from 'src/redux/types'
import { getVariantsCount } from 'src/utilities/helpers'
import STRINGS from 'constants/strings'
import usePushNotificaton from 'src/utilities/hooks/usePushNotification'
import { toggleBackModal } from 'src/redux/actions/app'
import { useKeycloak } from 'src/utilities/keycloak'
import { autoUpdateToken, handleInterval } from 'src/utilities/keycloak/helpers'

// screen
import Home from 'src/screens/common/Home'
import Login from 'src/screens/common/Login'
import CreateOrder from 'src/screens/sales-manager/CreateOrder'
import ProductList from 'src/screens/sales-manager/CreateOrder/ProductList'
import ProductCart from 'src/screens/sales-manager/CreateOrder/ProductCart'
import OrderDetailsEdit from 'src/screens/sales-manager/CreateOrder/OrderDetailsEdit'
import Orders from 'src/screens/sales-manager/ConfirmOrder'
import EditOrder from 'src/screens/sales-manager/ConfirmOrder/EditOrder'
import OrderDetails from 'src/screens/sales-manager/ConfirmOrder/OrderDetails'
import ProductDetailsEdit from 'src/screens/sales-manager/ConfirmOrder/ProductDetailsEdit'
import OrderHistory from 'src/screens/sales-manager/OrderHistory'
import ProductDetails from 'src/screens/sales-manager/common/ProductDetails'
import SuccessScreen from 'src/screens/common/Success'
import EmptyCart from 'src/screens/sales-manager/common/EmptyCart'
import OrderHistoryDetails from 'src/screens/sales-manager/OrderHistory/OrderHistoryDetails'
import UnbilledDispatchVoucher from 'src/screens/sales-biller/CreateInvoice/VoucherList'
import VoucherDetails from 'src/screens/sales-biller/CreateInvoice/VoucherDetails'
import RegenerateInvoice from 'src/screens/sales-biller/Regenerate/RegenerateInvoice'
import CreditNoteInvoice from 'src/screens/sales-biller/CreditNote/Invoice'
import VoucherList from 'src/screens/sales-biller/History'
import InvoiceList from 'src/screens/sales-biller/History/InvoiceList'
import InvoiceHistoryDetails from 'src/screens/sales-biller/History/InvoiceDetails'
import InvoiceDetails from 'src/screens/sales-biller/Regenerate/InvoiceDetails'
import CreditInvoiceDetails from 'src/screens/sales-biller/CreditNote/InvoiceDetails'
import UpdateInvoiceDetails from 'src/screens/sales-biller/CreditNote/UpdateInvoiceDetails'
import PendingGRNList from 'src/screens/inventory-manager/InwardGRN'
import PendingGRNDetails from 'src/screens/inventory-manager/InwardGRN/GRNDetails'
import UploadBiltyAndInvoice from 'src/screens/inventory-manager/InwardGRN/UploadBilty'
import GRNHistory from 'src/screens/inventory-manager/GRNHistory'
import Reschedule from 'src/screens/inventory-manager/InwardGRN/Reschedule'
import CreatePO from 'src/screens/procurement-manager/CreatePO'
import POList from 'src/screens/procurement-manager/ViewPOS'
import POPreview from 'src/screens/procurement-manager/CreatePO/Preview'
import POView from 'src/screens/procurement-manager/ViewPOS/View'
import ProcGRNList from 'src/screens/procurement-manager/InwardGRN'
import GRNDetails from 'src/screens/procurement-manager/InwardGRN/GRNDetails'
import UploadDocuments from 'src/screens/procurement-manager/InwardGRN/UploadDocuments'
import TransportDetails from 'src/screens/procurement-manager/InwardGRN/TransportDetails'
import ProcReschedule from 'src/screens/procurement-manager/InwardGRN/Reschedule'
import VendorListing from 'src/screens/procurement-manager/AddVendor/VendorListing'
import AddNewVendor from 'src/screens/procurement-manager/AddVendor/AddNewVendor'
import ProcsPO from 'src/screens/procurement-manager/CreateVendorBill/GRNDetails'
import CreateVendorBill from 'src/screens/procurement-manager/CreateVendorBill'
import WHOrderHistory from 'src/screens/warehouse-manager/OrderHistory'
import UpdateVendorBill from 'src/screens/procurement-manager/CreateVendorBill/UpdateVendorBill'
import BillSummary from 'src/screens/procurement-manager/CreateVendorBill/BillSummary'
import WHOrderDetails from 'src/screens/warehouse-manager/OrderHistory/OrderDetails'

// components
import Modal from 'src/components/Modal'
import Button from 'src/components/form-fields/Button'

// styles
import global from 'styles/global'
import colors from 'styles/colors'
import { RootStackParamList } from './types'
import styles from './styles'

const Stack = createStackNavigator<RootStackParamList>()

const Navigation = () => {
  const [showModal, setShowModal] = useState(false)
  const [modalBody, setModalBody] = useState(<></>)
  const [modalFooter, setModalFooter] = useState(<></>)
  const [imageSource, setImageSource] = useState<string>()

  const dispatch = useDispatch()
  const { schedulePushNotification } = usePushNotificaton()
  const { isLoggedIn, logout } = useKeycloak()

  const userData = useSelector((state: ApplicationState) => state.userReducer)
  const cartData = useSelector((state: ApplicationState) => state.cartReducer)
  const { userDetails } = userData
  const [refreshToken, setRefreshToken] = useState<null | string>()

  useEffect(() => {
    if (userDetails?.image) {
      setImageSource(`data:image/png;base64, ${userDetails.image}`)
    }
  }, [userDetails?.image])

  useEffect(() => {
    dispatch({
      type: 'SET_PUSH_NOTIFICATION',
      payload: schedulePushNotification,
    })
    // TODO: Provide an explanation for why all deps are not included or fix it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schedulePushNotification])

  const goLogout = () => {
    setShowModal(false)
    dispatch({
      type: 'RESET_AUTH_TOKEN',
    })
    logout()
  }

  const onLogout = () => {
    setModalBody(
      <View style={global.alignItemsCenter}>
        <Image
          style={[styles.warning]}
          source={require('images/warning.png')}
        />
        <Text
          style={[
            global.fontFourteen,
            global.textRegular,
            global.textColorGrey,
            styles.warningMsg,
          ]}
        >
          {STRINGS.LOGIN.LOGOUT_MSG}
        </Text>
      </View>,
    )
    setModalFooter(
      <View
        style={[
          global.directionRow,
          global.justifyContentSpaceBetween,
          styles.btnContainer,
        ]}
      >
        <Button
          title={STRINGS.BUTTON.YES}
          onTap={() => goLogout()}
          btnType="secondary"
          style={[global.widthHalf, styles.btnYesCover]}
          textStyle={global.textRegular}
        />
        <Button
          title={STRINGS.BUTTON.NO}
          onTap={() => setShowModal(false)}
          btnType="secondary"
          style={[styles.btnNoCover, global.widthHalf]}
          textStyle={[global.textRegular, styles.btnNo]}
        />
      </View>,
    )
    setShowModal(true)
  }

  useEffect(() => {
    const exchangeToken = async () => {
      setRefreshToken(await AsyncStorage.getItem('refreshToken'))
    }
    exchangeToken()
  }, [isLoggedIn])

  useEffect(() => {
    const interval = setInterval(() => {
      autoUpdateToken()
    }, 10000)
    handleInterval.set(interval)
  }, [])

  const headerStyle = {
    headerStyle: { backgroundColor: colors.seaGreen },
    headerTintColor: colors.white,
    headerTitleContainerStyle: { left: 50 },
  }

  return (
    <NavigationContainer>
      <Stack.Navigator>
        {!refreshToken ? (
          <>
            <Stack.Screen
              options={{ headerShown: false }}
              name="Login"
              component={Login}
            />
          </>
        ) : (
          <>
            <Stack.Screen
              name="Home"
              component={Home}
              options={{
                header: ({ navigation }) => {
                  return (
                    <View
                      style={[
                        global.directionRow,
                        global.hPaddingSixteen,
                        styles.homeHeader,
                      ]}
                    >
                      <View
                        style={[
                          global.flexOne,
                          global.paddingEight,
                          global.justifyContentCenter,
                          global.alignItemsLeft,
                        ]}
                      >
                        <Image
                          source={
                            imageSource
                              ? { uri: imageSource }
                              : require('images/dummy.png')
                          }
                          style={[styles.avatar]}
                        />
                      </View>
                      <View
                        style={[
                          global.flexSeven,
                          global.paddingEight,
                          global.justifyContentCenter,
                          styles.userNameContainer,
                        ]}
                      >
                        <View>
                          <Text
                            style={[global.textMedium, global.fontFourteen]}
                          >
                            {userDetails?.name}
                          </Text>
                        </View>
                        {!userDetails?.groups.includes(
                          'FarmsnFarmers / OMS Procurement Manager',
                        ) && (
                          <View
                            style={[
                              global.directionRow,
                              styles.locationContainer,
                            ]}
                          >
                            <Image
                              source={require('images/location-black.png')}
                              style={[styles.locationIcon]}
                            />
                            <Text
                              style={[
                                global.textRegular,
                                global.fontTwelve,
                                styles.location,
                              ]}
                            >
                              {userDetails?.sale_team_location}
                            </Text>
                          </View>
                        )}
                      </View>
                      {userDetails?.groups.includes(
                        'FarmsnFarmers / OMS Sales Manager',
                      ) && (
                        <Pressable
                          style={[
                            global.flexOne,
                            global.paddingEight,
                            global.justifyContentCenter,
                            global.alignItemsRight,
                          ]}
                          onPress={() => {
                            const navigateTo =
                              cartData.products.length > 0
                                ? 'ProductCart'
                                : 'EmptyCart'
                            navigation.navigate(navigateTo)
                          }}
                        >
                          <Image
                            source={require('images/shopping-cart.png')}
                            style={[styles.cart]}
                          />
                          <Text
                            style={[
                              global.fontTwelve,
                              global.positionAbsolute,
                              global.textAlignCenter,
                              global.borderRadiusEight,
                              styles.itemsCountHome,
                            ]}
                          >
                            {getVariantsCount(cartData.products)}
                          </Text>
                        </Pressable>
                      )}

                      <TouchableOpacity
                        style={[
                          global.flexOne,
                          global.paddingEight,
                          global.justifyContentCenter,
                          global.alignItemsRight,
                        ]}
                        onPress={() => onLogout()}
                      >
                        <Image
                          source={require('images/logout.png')}
                          style={[styles.logout]}
                        />
                        {showModal && (
                          <Modal
                            body={modalBody}
                            footer={modalFooter}
                            close={() => setShowModal(false)}
                          />
                        )}
                      </TouchableOpacity>
                    </View>
                  )
                },
              }}
            />
            <Stack.Screen
              name="CreateOrder"
              component={CreateOrder}
              options={({ navigation }) => ({
                ...headerStyle,
                title: STRINGS.SCREEN_TITLE.CREATE_ORDER,
                headerRight: () => (
                  <Pressable
                    style={[
                      global.flexOne,
                      global.paddingEight,
                      global.justifyContentCenter,
                      global.alignItemsRight,
                      global.hMarginEight,
                    ]}
                    onPress={() => {
                      navigation.navigate('ProductCart')
                    }}
                  >
                    <Image
                      source={require('images/shopping-cart-white.png')}
                      style={[styles.cart]}
                    />
                    <Text
                      style={[
                        styles.itemsCount,
                        global.fontTwelve,
                        global.positionAbsolute,
                        global.textAlignCenter,
                        global.borderRadiusEight,
                      ]}
                    >
                      {getVariantsCount(cartData.products)}
                    </Text>
                  </Pressable>
                ),
              })}
            />
            <Stack.Screen
              name="Orders"
              component={Orders}
              options={{ ...headerStyle, title: STRINGS.SCREEN_TITLE.ORDERS }}
            />
            <Stack.Screen
              name="OrderHistory"
              component={OrderHistory}
              options={{
                ...headerStyle,
                title: STRINGS.SCREEN_TITLE.ORDER_HISTORY,
              }}
            />
            <Stack.Screen
              name="ProductList"
              component={ProductList}
              options={{
                ...headerStyle,
                title: STRINGS.SCREEN_TITLE.SELECT_PRODUCTS,
              }}
            />
            <Stack.Screen
              name="ProductDetails"
              component={ProductDetails}
              options={{
                ...headerStyle,
                title: STRINGS.SCREEN_TITLE.PRODUCT_DETAILS,
              }}
            />
            <Stack.Screen
              name="OrderDetailsEdit"
              component={OrderDetailsEdit}
              options={{
                ...headerStyle,
                title: STRINGS.SCREEN_TITLE.ORDER_DETAILS,
              }}
            />
            <Stack.Screen
              name="OrderDetails"
              component={OrderDetails}
              options={{
                ...headerStyle,
                title: STRINGS.SCREEN_TITLE.ORDER_DETAILS,
              }}
            />
            <Stack.Screen
              name="SuccessScreen"
              component={SuccessScreen}
              options={{ headerShown: false }}
            />
            <Stack.Screen
              name="EditOrder"
              component={EditOrder}
              options={{
                ...headerStyle,
                title: STRINGS.SCREEN_TITLE.EDIT_ORDER,
              }}
            />
            <Stack.Screen
              name="ProductCart"
              component={ProductCart}
              options={{ ...headerStyle, title: STRINGS.SCREEN_TITLE.CART }}
            />
            <Stack.Screen
              name="ProductDetailsEdit"
              component={ProductDetailsEdit}
              options={{
                ...headerStyle,
                title: STRINGS.SCREEN_TITLE.PRODUCT_DETAILS,
              }}
            />
            <Stack.Screen
              name="EmptyCart"
              component={EmptyCart}
              options={{ ...headerStyle, title: '' }}
            />
            <Stack.Screen
              name="OrderHistoryDetails"
              component={OrderHistoryDetails}
              options={{
                ...headerStyle,
                title: STRINGS.SCREEN_TITLE.ORDER_DETAILS,
              }}
            />
            <Stack.Screen
              name="UnbilledDispatchVoucher"
              component={UnbilledDispatchVoucher}
              options={{
                ...headerStyle,
                title: STRINGS.SCREEN_TITLE.UNBILLED_DISPATCH_VOUCHERS,
              }}
            />
            <Stack.Screen
              name="VoucherDetails"
              component={VoucherDetails}
              options={{
                ...headerStyle,
                title: STRINGS.VOUCHER.VOUCHER_DETAILS,
              }}
            />
            <Stack.Screen
              name="RegenerateInvoice"
              component={RegenerateInvoice}
              options={{
                ...headerStyle,
                title: STRINGS.SCREEN_TITLE.REGENERATE_INVOICE,
              }}
            />
            <Stack.Screen
              name="CreditNoteInvoice"
              component={CreditNoteInvoice}
              options={{ ...headerStyle, title: STRINGS.INVOICE.INVOICES }}
            />
            <Stack.Screen
              name="VoucherList"
              component={VoucherList}
              options={{
                ...headerStyle,
                title: STRINGS.SCREEN_TITLE.VOUCHER_LISTING,
              }}
            />
            <Stack.Screen
              name="InvoiceList"
              component={InvoiceList}
              options={{
                ...headerStyle,
                title: STRINGS.SCREEN_TITLE.INVOICE_LISTING,
              }}
            />
            <Stack.Screen
              name="InvoiceHistoryDetails"
              component={InvoiceHistoryDetails}
              options={{
                ...headerStyle,
                title: STRINGS.INVOICE.INVOICE_DETAILS,
              }}
            />
            <Stack.Screen
              name="InvoiceDetails"
              component={InvoiceDetails}
              options={{
                ...headerStyle,
                title: STRINGS.SCREEN_TITLE.INVOICE_DETAILS,
              }}
            />
            <Stack.Screen
              name="CreditInvoiceDetails"
              component={CreditInvoiceDetails}
              options={{
                ...headerStyle,
                title: STRINGS.INVOICE.INVOICE_DETAILS,
              }}
            />
            <Stack.Screen
              name="UpdateInvoiceDetails"
              component={UpdateInvoiceDetails}
              options={{
                ...headerStyle,
                title: STRINGS.INVOICE.INVOICE_DETAILS,
              }}
            />
            <Stack.Screen
              name="PendingGRNList"
              component={PendingGRNList}
              options={{
                ...headerStyle,
                title: STRINGS.SCREEN_TITLE.PENDING_GRNS,
              }}
            />
            <Stack.Screen
              name="GRNHistory"
              component={GRNHistory}
              options={{
                ...headerStyle,
                title: STRINGS.SCREEN_TITLE.PO_LISTING,
              }}
            />
            <Stack.Screen
              name="PendingGRNDetails"
              component={PendingGRNDetails}
              options={{
                ...headerStyle,
                title: STRINGS.SCREEN_TITLE.PENDING_GRN_DETAILS,
              }}
            />
            <Stack.Screen
              name="UploadBiltyAndInvoice"
              component={UploadBiltyAndInvoice}
              options={{
                ...headerStyle,
                title: STRINGS.SCREEN_TITLE.UPLOAD_BILTY_AND_INVOICE,
              }}
            />
            <Stack.Screen
              name="Reschedule"
              component={Reschedule}
              options={{
                ...headerStyle,
                title: STRINGS.SCREEN_TITLE.RESCHEDULE,
              }}
            />
            <Stack.Screen
              name="CreatePO"
              component={CreatePO}
              options={() => ({
                ...headerStyle,
                title: STRINGS.SCREEN_TITLE.CREATE_PO,
                headerLeft: () => (
                  <Pressable
                    style={tw`flex-1 justify-center p-2 m-2`}
                    onPress={() => dispatch(toggleBackModal())}
                  >
                    <Image
                      source={require('images/arrow-back.png')}
                      style={styles.backArrow}
                    />
                  </Pressable>
                ),
              })}
            />
            <Stack.Screen
              name="POList"
              component={POList}
              options={{ ...headerStyle, title: STRINGS.SCREEN_TITLE.VIEW_POS }}
            />
            <Stack.Screen
              name="POPreview"
              component={POPreview}
              options={{
                ...headerStyle,
                title: STRINGS.SCREEN_TITLE.CREATE_PO,
              }}
            />
            <Stack.Screen
              name="POView"
              component={POView}
              options={{ ...headerStyle, title: STRINGS.SCREEN_TITLE.VIEW_POS }}
            />
            <Stack.Screen
              name="ProcGRNList"
              component={ProcGRNList}
              options={{
                ...headerStyle,
                title: STRINGS.SCREEN_TITLE.PENDING_GRN_LISTING,
              }}
            />
            <Stack.Screen
              name="ProcGRNDetails"
              component={GRNDetails}
              options={{
                ...headerStyle,
                title: STRINGS.SCREEN_TITLE.GRN_DETAILS,
              }}
            />
            <Stack.Screen
              name="UploadDocuments"
              component={UploadDocuments}
              options={{
                ...headerStyle,
                title: STRINGS.SCREEN_TITLE.UPLOAD_DOCUMENTS,
              }}
            />
            <Stack.Screen
              name="TransportDetails"
              component={TransportDetails}
              options={{
                ...headerStyle,
                title: STRINGS.SCREEN_TITLE.TRANSPORT_DETAILS,
              }}
            />
            <Stack.Screen
              name="ProcsPO"
              component={ProcsPO}
              options={{
                ...headerStyle,
                title: STRINGS.SCREEN_TITLE.CREATE_VENDOR_BILL,
              }}
            />
            <Stack.Screen
              name="ProcReschedule"
              component={ProcReschedule}
              options={{
                ...headerStyle,
                title: STRINGS.SCREEN_TITLE.RESCHEDULE_OR_CANCEL_ORDER,
                headerLeft: () => null,
              }}
            />
            <Stack.Screen
              name="VendorListing"
              component={VendorListing}
              options={{
                ...headerStyle,
                title: STRINGS.SCREEN_TITLE.VENDOR_LISTING,
              }}
            />
            <Stack.Screen
              name="AddNewVendor"
              component={AddNewVendor}
              options={{
                ...headerStyle,
                title: STRINGS.SCREEN_TITLE.ADD_VENDOR,
              }}
            />
            <Stack.Screen
              name="WHOrderHistory"
              component={WHOrderHistory}
              options={{
                ...headerStyle,
                title: STRINGS.SCREEN_TITLE.ORDER_HISTORY,
              }}
            />
            <Stack.Screen
              name="CreateVendorBill"
              component={CreateVendorBill}
              options={{
                ...headerStyle,
                title: STRINGS.SCREEN_TITLE.CREATE_VENDOR_BILL,
              }}
            />
            <Stack.Screen
              name="UpdateVendorBill"
              component={UpdateVendorBill}
              options={{
                ...headerStyle,
                title: STRINGS.SCREEN_TITLE.CREATE_VENDOR_BILL,
              }}
            />
            <Stack.Screen
              name="ProcVendorBillSummary"
              component={BillSummary}
              options={{
                ...headerStyle,
                title: STRINGS.SCREEN_TITLE.VENDOR_BILL_SUMMARY,
              }}
            />
            <Stack.Screen
              name="WHOrderDetails"
              component={WHOrderDetails}
              options={{
                ...headerStyle,
                title: STRINGS.SCREEN_TITLE.VOUCHER_DETAILS,
              }}
            />
          </>
        )}
      </Stack.Navigator>
    </NavigationContainer>
  )
}

export default Navigation
