import React from 'react'
import { ScrollView, View, Text } from 'react-native'

import { GRNMoreDetails } from 'src/modals'
import STRINGS from 'constants/strings'

// compoennt
import ProfileBar from 'src/components/card/ProfileBar'

// style
import global from 'styles/global'
import styles from './style'

export interface MoreDetailsProps {
  route: {
    grnMoreDetails: GRNMoreDetails
  }
}

const MoreDetails = ({ route }: MoreDetailsProps) => {
  const { grnMoreDetails } = route
  return (
    <View style={[global.bgWhite, global.heightFull]}>
      <ScrollView>
        {grnMoreDetails && (
          <View>
            <View
              style={[
                global.hPaddingTwenty,
                global.vPaddingThirty,
                styles.bottomBorder,
              ]}
            >
              <View>
                <Text
                  style={[
                    global.textPink,
                    global.fontTwelve,
                    global.textRegular,
                  ]}
                >
                  {STRINGS.GRNS.VENDOR_NAME}
                </Text>
                <Text
                  style={[
                    global.textSecondaryBlue,
                    global.fontFourteen,
                    global.textRegular,
                    styles.paddingTopThree,
                  ]}
                >
                  {grnMoreDetails.vendor?.name ||
                    STRINGS.MISCELLANEOUS.NOT_AVAILABLE}
                </Text>
              </View>
              <View style={[global.vPaddingTwenty, global.widthThreeForth]}>
                <View
                  style={[
                    global.directionRow,
                    global.justifyContentSpaceBetween,
                  ]}
                >
                  <Text
                    style={[
                      global.textPink,
                      global.fontTwelve,
                      global.textRegular,
                    ]}
                  >
                    {STRINGS.GRNS.PO_ID}
                  </Text>
                  <Text
                    style={[
                      global.textPink,
                      global.fontTwelve,
                      global.textRegular,
                    ]}
                  >
                    {STRINGS.VOUCHER.CONTACT_NUMBER}
                  </Text>
                </View>
                <View
                  style={[
                    global.directionRow,
                    global.justifyContentSpaceBetween,
                  ]}
                >
                  <Text
                    style={[
                      global.textSecondaryBlue,
                      global.fontFourteen,
                      global.textRegular,
                      styles.paddingTopThree,
                    ]}
                  >
                    {grnMoreDetails.po_id ||
                      STRINGS.MISCELLANEOUS.NOT_AVAILABLE}
                  </Text>
                  <Text
                    style={[
                      global.textSecondaryBlue,
                      global.fontFourteen,
                      global.textRegular,
                      styles.paddingTopThree,
                    ]}
                  >
                    {grnMoreDetails.vendor.mobile ||
                      STRINGS.MISCELLANEOUS.NOT_AVAILABLE ||
                      STRINGS.MISCELLANEOUS.NOT_AVAILABLE}
                  </Text>
                </View>
              </View>
              <Text
                style={[global.textPink, global.fontTwelve, global.textRegular]}
              >
                {STRINGS.VOUCHER.DELIVERY_LOCATION}
              </Text>
              <Text
                style={[
                  global.textSecondaryBlue,
                  global.fontFourteen,
                  global.textRegular,
                ]}
              >
                {grnMoreDetails?.delivery_location ||
                  STRINGS.MISCELLANEOUS.NOT_AVAILABLE}
              </Text>
            </View>
            <View
              style={[
                global.hPaddingTwenty,
                global.vPaddingThirty,
                styles.bottomBorder,
              ]}
            >
              <ProfileBar
                name={grnMoreDetails.po_manager?.name}
                imageKey={grnMoreDetails.po_manager?.manager_image}
                designation={grnMoreDetails.po_manager?.designation}
                containerStyle={[
                  global.bgWhite,
                  global.paddingZero,
                  global.vPaddingTwenty,
                ]}
              />
              <View>
                <Text
                  style={[
                    global.textPink,
                    global.fontTwelve,
                    global.textRegular,
                  ]}
                >
                  {STRINGS.VOUCHER.CONTACT_NUMBER}
                </Text>
                <Text
                  style={[
                    global.textSecondaryBlue,
                    global.fontFourteen,
                    global.textRegular,
                    styles.paddingTopThree,
                  ]}
                >
                  {grnMoreDetails.po_manager?.mobile ||
                    STRINGS.MISCELLANEOUS.NOT_AVAILABLE}
                </Text>
              </View>
              <View style={global.vPaddingTwenty}>
                <Text
                  style={[
                    global.textPink,
                    global.fontTwelve,
                    global.textRegular,
                  ]}
                >
                  {STRINGS.GRNS.LOCATION}
                </Text>
                <Text
                  style={[
                    global.textSecondaryBlue,
                    global.fontFourteen,
                    global.textRegular,
                    styles.paddingTopThree,
                  ]}
                >
                  {grnMoreDetails.po_manager?.address ||
                    STRINGS.MISCELLANEOUS.NOT_AVAILABLE}
                </Text>
              </View>
            </View>
          </View>
        )}
      </ScrollView>
    </View>
  )
}

export default MoreDetails
