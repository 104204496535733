import React, { useState, ReactElement, useEffect } from 'react'
import { View, Text, Image, Pressable } from 'react-native'

import STRINGS from 'constants/strings'
import { get, post, put, URL } from 'src/utilities/axios'
import {
  InvoiceDetailsScreenNavigationProp,
  VoucherDetailsScreenNavigationProp,
} from 'src/navigations/types'
import { DOWNLOAD } from 'src/config/download'
import {
  Voucher,
  Invoice,
  BillingAddress,
  AdditionalExpense,
  Bank,
} from 'src/modals'
import useDownloadFrmS3 from 'src/utilities/hooks/useDownloadFrmS3'

// components
import Dropdown from 'src/components/form-fields/Dropdown'
import InputNumberField from 'src/components/form-fields/InputNumberField'
import Button from 'src/components/form-fields/Button'
import Modal from 'src/components/Modal'

// styles
import global from 'styles/global'
import colors from 'styles/colors'
import style from './style'

interface ExpenseContentProps {
  setDrawer: Function
  navigation?:
    | VoucherDetailsScreenNavigationProp
    | InvoiceDetailsScreenNavigationProp
  voucher?: Voucher
  invoice?: Invoice
  regenerate?: boolean
  invoiceId?: number
  editAdditionalExpense?: boolean
}

const createDropdownList = (
  arr: BillingAddress[] | AdditionalExpense[],
  isExpense?: boolean,
) => {
  const dataPicker: Array<{ label: string; value: string }> = []
  if (isExpense) {
    dataPicker.push({ label: STRINGS.FORM_FIELDS.SELECT_A_OPTION, value: '0' })
  }
  arr.map((item: BillingAddress | AdditionalExpense) =>
    dataPicker.push({ label: item.name, value: String(item.id) }),
  )
  return dataPicker
}

const createBankList = (bankList: Bank[]) => {
  const dataPicker: Array<{ label: string; value: string }> = []
  bankList.map((bank: Bank) =>
    dataPicker.push({
      label: `${bank.bank_name} (${bank.acc_number})`,
      value: String(bank.id),
    }),
  )
  return dataPicker
}

const ExpenseContent = ({
  setDrawer,
  navigation,
  voucher,
  invoice,
  regenerate,
  invoiceId,
  editAdditionalExpense,
}: ExpenseContentProps) => {
  // One of `voucher` or `invoice` is necessarily defined
  const voucherOrInvoice = (voucher || invoice)!
  const [expenseList, setExpenseList] = useState<AdditionalExpense[]>([
    { id: 0, name: STRINGS.FORM_FIELDS.SELECT_A_OPTION, price: 0 },
  ])
  const [details, setDetails] = useState({
    bank:
      voucherOrInvoice.metadata.banks.length > 0 &&
      voucherOrInvoice.metadata.banks[0].id,
    address:
      voucherOrInvoice.metadata.billing_addresses.length > 0 &&
      voucherOrInvoice.metadata.billing_addresses[0].id,
    expense: [] as Array<{
      id: number
      expenseId?: number
      price?: number
      editExpenseIndex?: number
      invoiceLineId?: number
    }>,
  })
  const [disabled, setDisabled] = useState<boolean>(false)
  const [showModal, setShowModal] = useState(false)
  const [modalBody, setModalBody] = useState<ReactElement>(<></>)
  const [modalFooter, setModalFooter] = useState<ReactElement>(<></>)
  const [deleteAdditionalExpense, setDeleteAdditionalExpense] = useState<
    AdditionalExpense[]
  >([])
  const { downloadDocFrmS3 } = useDownloadFrmS3()

  // set additional expense for edit
  useEffect(() => {
    if (
      editAdditionalExpense &&
      voucherOrInvoice?.additional_expenses?.length
    ) {
      const additonalExpense = voucherOrInvoice.additional_expenses.map(
        (expense, index) => {
          return {
            id: expense.product_id,
            name: expense.product_name,
            price: expense.price_unit,
            editExpenseIndex: index,
            invoiceLineId: expense.id,
          }
        },
      )
      const newExpenseList = [...additonalExpense, ...expenseList]
      setExpenseList(newExpenseList)
    }
    // TODO: Provide an explanation for why all deps are not included or fix it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [voucherOrInvoice])

  // check whether to disable the create invoice button or not
  const checkDisable = () => {
    if (details.expense.length === 0) {
      setDisabled(false)
      return
    }
    let exists = false
    details.expense.forEach(expense => {
      if (
        expense.price &&
        expense.price !== 0 &&
        expense.expenseId &&
        !exists
      ) {
        setDisabled(false)
      } else {
        setDisabled(true)
        exists = true
      }
    })
  }

  // add or remove expense options
  const addOrRemoveExpense = (index?: number) => {
    const copyOfList = [...expenseList]
    if (index !== undefined) {
      if (
        editAdditionalExpense &&
        copyOfList[index]?.editExpenseIndex != null
      ) {
        const deletedExpense = [...deleteAdditionalExpense]
        deletedExpense.push(copyOfList[index])
        setDeleteAdditionalExpense(deletedExpense)
      }
      copyOfList.splice(index, 1)

      // FIXME: This seems to changing the array on which the iteration is happening
      /**
       * The right way of doing it would be
       * let copyExpenseList = details.expense.filter(({ id }) => id === index)
       * copyExpenseList = copyExpenseList.map(exp => ({...exp, id: index <= exp.id ? 1 : exp.id}))
       * const copyDetails = {...details, expense: copyExpenseList}
       */
      const copyDetails = { ...details }
      copyDetails.expense.forEach((exp, expIndex) => {
        if (exp.id === index) {
          copyDetails.expense.splice(expIndex, 1)
        }
      })
      copyDetails.expense.forEach(expense => {
        if (index <= expense.id) {
          expense.id -= 1
        }
      })
      checkDisable()
      setDetails(copyDetails)
    } else {
      copyOfList.push({
        id: 0,
        name: STRINGS.FORM_FIELDS.SELECT_A_OPTION,
        price: 0,
      })
    }
    setExpenseList(copyOfList)
  }

  const setPriceInExpenseList = (index: number, price: number) => {
    const copyOfExpenseList = [...expenseList]
    copyOfExpenseList[index].price = price
    setExpenseList(copyOfExpenseList)
  }

  const setIdInExpenseList = (index: number, id: number) => {
    const copyOfExpenseList = [...expenseList]
    copyOfExpenseList[index].id = id
    setExpenseList(copyOfExpenseList)
  }

  const setAttribute = (
    attribute: string,
    value: number | string,
    index?: number,
  ) => {
    const copyDetails = { ...details }
    if (attribute === 'bank' || attribute === 'address') {
      copyDetails[attribute] = Number(value)
    }
    if ((attribute === 'expenseId' || attribute === 'price') && index != null) {
      if (attribute === 'expenseId') {
        if (value === '0') {
          // FIXME: Changes the array of iteration itself
          // Better to use findIndex here instead of forEach
          copyDetails.expense.forEach((expense, expIndex) => {
            if (expense.id === index) {
              copyDetails.expense.splice(expIndex, 1)
              setIdInExpenseList(expIndex, 0)
              setPriceInExpenseList(index, 0)
            }
          })
          setDetails(copyDetails)
          checkDisable()
          return
        }
        setIdInExpenseList(index, Number(value))
      }
      if (attribute === 'price') {
        setPriceInExpenseList(index, value === '0' ? 0 : Number(value))
        if (value === '0') {
          copyDetails.expense.forEach((expense, expIndex) => {
            if (expense.id === index && !expense.expenseId) {
              copyDetails.expense.splice(expIndex, 1)
            }
          })
          setDetails(copyDetails)
          checkDisable()
          return
        }
      }
      let isAvailable = false
      // FIXME: use normal for loop. Better performance thanks to break
      /**
       * for (let i=0; i < copyDetails.expense.length; i++) {
       *   if (copyDetails.expense[i].id === index) {
       *      copyDetails.expense[i][attribute] = Number(value)
       *      isAvailable = true
       *      break
       *   }
       * }
       */
      // Another solution can be by using findIndex instead
      /**
       * const expenseIndex = copyDetails.expense.findIndex(({ id }) => id === index)
       * const isAvailable = expenseIndex !== -1
       * if (isAvailable) {
       *   const expense = {...copyDetails.expense[expenseIndex], [attribute]: Number(value)}
       *   copyDetails.expense[expenseIndex] = expense
       * }
       */
      copyDetails.expense.forEach(expense => {
        if (expense.id === index) {
          expense[attribute] = Number(value)
          isAvailable = true
        }
      })
      if (!isAvailable) {
        if (
          editAdditionalExpense &&
          expenseList[index]?.editExpenseIndex !== null
        ) {
          copyDetails.expense.push({
            id: index,
            invoiceLineId: expenseList[index]?.invoiceLineId,
            expenseId: expenseList[index]?.id,
            price: expenseList[index].price,
            editExpenseIndex: expenseList[index].editExpenseIndex,
            [attribute]: Number(value),
          })
        } else {
          copyDetails.expense.push({ id: index, [attribute]: Number(value) })
        }
      }
      checkDisable()
    }
    setDetails(copyDetails)
  }

  const getNote = (data: Voucher | Invoice) => {
    const length = data?.notes?.length
    if (
      length &&
      data!.notes[length - 1]?.origin === STRINGS.VOUCHER.NOTES_CHECK
    )
      return data!.notes[length - 1]?.body
    return undefined
  }

  // Since `getItemList` is being called inside `createInvoice` only,
  // which is called only for a voucher, so can assert that voucher
  // won't be null
  const getItemList = () =>
    voucher!.items?.length > 0
      ? voucher!.items.map(item => ({
          move_line_id: item.move_line_id,
          price_unit: item.price_unit,
        }))
      : []

  const getExpenseList = () =>
    details.expense.length > 0
      ? details.expense.map(exp => {
          return {
            product_id: exp.expenseId,
            price_unit: exp.price,
          }
        })
      : []

  const getUpdateExpenseList = () => {
    // TODO: Provide a different variable name
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const expenseList =
      details.expense.length > 0
        ? details.expense.map(exp => {
            if (exp?.editExpenseIndex != null)
              return {
                price_unit: exp.price,
                invoice_line_id: exp!.invoiceLineId,
                action: 'update',
                product_id: exp?.expenseId,
              }
            return {
              product_id: exp?.expenseId,
              price_unit: exp.price,
              invoice_line_id: -1,
              action: 'add',
            }
          })
        : []
    if (deleteAdditionalExpense.length > 0) {
      deleteAdditionalExpense.forEach(exp => {
        expenseList.push({
          product_id: exp?.id,
          price_unit: exp?.price,
          invoice_line_id: exp?.invoiceLineId,
          action: 'delete',
        })
      })
    }
    return expenseList
  }

  // TODO: Provide a different parameter name
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const downloadInvoice = async (invoiceId: number | string) => {
    const response = await get(`${URL.INVOICE_DOWNLOAD}/${invoiceId}`)
    const invoiceURL = response?.data[0]?.store_fname
    downloadDocFrmS3(
      invoiceURL,
      DOWNLOAD.INVOICE_PDF_FILE_NAME,
      STRINGS.INVOICE.INVOICE_DOWNLOADED,
    )
  }

  // `voucher` won't be null here because the function is being used
  // only for a `voucher`
  const createInvoice = () => {
    const payload: any = {
      dispatch_voucher_id: voucher!.id,
      bank_account_id: details.bank,
      items: getItemList(),
      additional_expenses: getExpenseList(),
    }
    const note = voucher && getNote(voucher)
    if (note) payload.note = note
    if (details?.address) payload.billing_location_id = details.address
    post(URL.INVOICE_LIST, payload)
      .then((res: { data: { invoice_id: number } }) => {
        if (res?.data?.invoice_id) {
          navigation!.navigate('SuccessScreen', {
            iconImg: (
              <Image
                source={require('images/success-icon.png')}
                style={style.successIcon}
              />
            ),
            customMsg: (
              <Text
                style={[
                  global.fontFourteen,
                  global.textRegular,
                  global.textPrimaryBlack,
                  global.paddingTwenty,
                  global.textAlignCenter,
                  global.directionRow,
                ]}
              >
                {`${STRINGS.INVOICE.INVOICES_FOR}`}
                <Text style={global.textBold}>{voucher!.customer.name}</Text>
                {`${STRINGS.INVOICE.INVOICES_CREATED}`}
              </Text>
            ),
            greeting: true,
            idFields: [
              {
                title: STRINGS.MISCELLANEOUS.ORDER_ID,
                value: voucher!.so_id,
              },
              {
                title: STRINGS.INVOICE.INVOICE_ID_HYPHNE,
                value: voucher!.voucher_id,
              },
            ],
            footerButtons: [
              {
                title: STRINGS.BUTTON.DOWNLOAD_INVOICE,
                iconPath: require('images/download-white.png'),
                onClick: () => downloadInvoice(res.data.invoice_id),
                bgColor: colors.primaryGreen,
              },
            ],
          })
        }
      })
      .catch(error => console.error(error))
    setShowModal(false)
  }

  const openCreateInvoiceModal = (onYes: () => void = createInvoice) => {
    setModalBody(
      <View style={global.alignItemsCenter}>
        <Image
          style={[style.fileAddIcon]}
          source={require('images/file-add.png')}
        />
        <View style={[global.widthFull]}>
          <Text
            style={[
              global.fontFourteen,
              global.textRegular,
              global.textColorGrey,
              global.textAlignCenter,
              style.warningMsg,
            ]}
          >
            {regenerate
              ? STRINGS.VOUCHER.REGENERATE_INVOICE_WARNING
              : STRINGS.VOUCHER.CREATE_INVOICE_WARNING}
          </Text>
        </View>
      </View>,
    )
    setModalFooter(
      <View
        style={[
          global.directionRow,
          global.justifyContentSpaceBetween,
          style.btnContainer,
        ]}
      >
        <Button
          title={STRINGS.BUTTON.NO}
          onTap={() => setShowModal(false)}
          btnType="secondary"
          style={[global.widthHalf, style.btnNoCover]}
          textStyle={[global.textRegular, global.textSecondaryOrange]}
        />
        <Button
          title={STRINGS.BUTTON.YES}
          onTap={onYes}
          btnType="secondary"
          style={[global.widthHalf, style.btnYesCover]}
          textStyle={global.textRegular}
        />
      </View>,
    )
    setShowModal(true)
  }

  const regenerateInvoice = () =>
    get(`${URL.CANCEL_INVOICE}/${invoiceId!}`)
      .then(res => {
        if (res.data) {
          createInvoice()
        }
      })
      .catch(error => console.error(error))

  const openRegenerateInvoiceModal = () =>
    openCreateInvoiceModal(regenerateInvoice)

  const getInvoiceList = () =>
    invoice?.items && invoice.items.length > 0
      ? invoice.items.map(item => ({
          invoice_line_id: item.id,
          price_unit: item.price_unit,
        }))
      : []

  const navigateToCreditSuccessScreen = () => {
    if (invoice?.id) {
      navigation!.navigate('SuccessScreen', {
        iconImg: (
          <Image
            source={require('images/success-icon.png')}
            style={style.successIcon}
          />
        ),
        customMsg: (
          <Text
            style={[
              global.fontFourteen,
              global.textRegular,
              global.textPrimaryBlack,
              global.paddingTwenty,
              global.textAlignCenter,
              global.directionRow,
            ]}
          >
            {`${STRINGS.CREDIT_NOTE.CREDIT_NOTE_AND_INVOICE}`}
            <Text style={global.textBold}>{invoice?.customer.name}</Text>
            {`${STRINGS.INVOICE.INVOICES_CREATED}`}
          </Text>
        ),
        greeting: true,
        customFooterConfig: {
          url: `${URL.INVOICE_DOWNLOAD}/${invoice.id}`,
          buttonConfig: [
            {
              type: DOWNLOAD.INVOICE_DOC_TYPE,
              title: STRINGS.INVOICE.INVOICE,
              fileName: `${STRINGS.INVOICE.INVOICE} ${invoice.id}`,
              successMsg: STRINGS.INVOICE.INVOICE_DOWNLOADED,
              buttonIcon: require('images/download-white.png'),
              buttonStyle: [global.bgPrimaryGrren, global.borderRadiusEight],
            },
            {
              type: DOWNLOAD.CREDIT_DOC_TYPE,
              title: STRINGS.INVOICE.CREDIT_NOTE,
              fileName: `${STRINGS.INVOICE.CREDIT_NOTE} ${invoice.id}`,
              successMsg: STRINGS.INVOICE.CREDIT_NOTE_DOWNLOADED,
              buttonIcon: require('images/download-white.png'),
              buttonStyle: [global.bgPrimaryGrren, global.borderRadiusEight],
            },
          ],
        },
        idFields: [
          {
            title: STRINGS.MISCELLANEOUS.ORDER_ID,
            value: invoice?.so_id,
          },
          {
            title: STRINGS.INVOICE.INVOICE_ID_HYPHNE,
            value: invoice?.id,
          },
        ],
      })
    }
  }

  const updateInvoice = () => {
    const payload: any = {
      invoice_id: invoice?.id,
      bank_account_id: details.bank,
      items: getInvoiceList(),
      additional_expenses: getUpdateExpenseList(),
    }
    const note = invoice && getNote(invoice)
    if (note) payload.note = note
    if (details?.address) payload.billing_location_id = details.address
    put(URL.UPDATE_INVOICE, payload)
      .then(data => {
        if (data?.data?.success) {
          navigateToCreditSuccessScreen()
        }
      })
      .catch(error => console.error(error))
    setShowModal(false)
  }

  const openUpdateInvoiceModal = () => openCreateInvoiceModal(updateInvoice)

  return (
    <View style={[global.drawer, style.expenseDrawerWrapper]}>
      <View style={global.alignItemsRight}>
        <Pressable
          onPress={() => setDrawer(false)}
          style={global.paddingSixteen}
        >
          <Image
            source={require('images/cancel.png')}
            style={[style.iconCancel]}
          />
        </Pressable>
      </View>
      <View style={global.hPaddingTwenty}>
        <Text
          style={[global.textColorGrey, global.textRegular, global.fontTwelve]}
        >
          {STRINGS.VOUCHER.UPDATE_BANK}
        </Text>
        <View style={global.vPaddingTen}>
          <Dropdown
            options={createBankList(voucherOrInvoice.metadata.banks)}
            onChange={(bankId: number) => setAttribute('bank', bankId)}
            selectedOption={voucherOrInvoice.metadata.banks[0].id}
            style={[
              global.textColorGrey,
              global.fontFourteen,
              global.textBold,
              style.pickerWrapper,
            ]}
            outerStyle={[global.vPaddingTen, global.borderColorGrey]}
          />
        </View>
      </View>
      {voucherOrInvoice.metadata.billing_addresses.length > 0 && (
        <View style={[global.hPaddingTwenty, global.vPaddingTen]}>
          <Text
            style={[
              global.textColorGrey,
              global.textRegular,
              global.fontTwelve,
              global.paddingBottomTen,
            ]}
          >
            {STRINGS.VOUCHER.BILLING_LOCATION}
          </Text>
          <View style={global.paddingBottomTen}>
            <Dropdown
              options={createDropdownList(
                voucherOrInvoice.metadata.billing_addresses,
              )}
              onChange={(locationId: number) =>
                setAttribute('address', locationId)
              }
              selectedOption={voucherOrInvoice.metadata.billing_addresses[0].id}
              style={[
                global.textColorGrey,
                global.fontFourteen,
                global.textBold,
                style.pickerWrapper,
              ]}
              outerStyle={[global.vPaddingTen, global.borderColorGrey]}
            />
          </View>
        </View>
      )}
      {voucherOrInvoice.metadata.additional_expenses.length > 0 &&
        expenseList.map((expense, index) => (
          <View
            style={[
              global.directionRow,
              global.justifyContentSpaceBetween,
              global.hPaddingTwenty,
            ]}
            // TODO: Need to provide reason for why index key or else fix it
            // eslint-disable-next-line react/no-array-index-key
            key={`${expense.id}${index}`}
          >
            <View style={[global.vPaddingTen, style.expensePickerWrapper]}>
              <View
                style={[global.directionRow, global.justifyContentSpaceBetween]}
              >
                <Text
                  style={[
                    global.textColorGrey,
                    global.textRegular,
                    global.fontTwelve,
                  ]}
                >
                  {STRINGS.VOUCHER.ADD_ADDITIONAL_EXPENSES}
                </Text>
                {expenseList.length > 1 && (
                  <Pressable onPress={() => addOrRemoveExpense(index)}>
                    <Text
                      style={[
                        global.textSecondaryOrange,
                        global.textRegular,
                        global.fontTwelve,
                        global.textDecorationLine,
                      ]}
                    >
                      {STRINGS.BUTTON.REMOVE}
                    </Text>
                  </Pressable>
                )}
              </View>
              <View style={global.vPaddingTen}>
                <Dropdown
                  options={createDropdownList(
                    voucherOrInvoice.metadata.additional_expenses,
                    true,
                  )}
                  onChange={(expenseId: number) =>
                    setAttribute('expenseId', expenseId, index)
                  }
                  style={[
                    global.textColorGrey,
                    global.fontFourteen,
                    global.textBold,
                    style.pickerWrapper,
                  ]}
                  outerStyle={[global.vPaddingTen, global.borderColorGrey]}
                  selectedOption={expense.id}
                />
              </View>
            </View>
            <View style={[global.vPaddingTen, global.widthOneForth]}>
              <View>
                <Text
                  style={[
                    global.textColorGrey,
                    global.textRegular,
                    global.fontTwelve,
                  ]}
                >
                  {disabled
                    ? STRINGS.FORM_FIELDS.ENTER_PRICE
                    : STRINGS.FORM_FIELDS.ENTER_PRICE_WITHOUT_ASTERISK}
                </Text>
                <View style={global.vPaddingTen}>
                  <InputNumberField
                    placeholder="00"
                    onTextChange={(value: number) =>
                      setAttribute('price', value, index)
                    }
                    maxLength={5}
                    style={[global.borderRadiusEight, style.priceInput]}
                    innerStyle={[global.textColorGrey, global.fontFourteen]}
                    value={expense.price?.toString()}
                  />
                </View>
              </View>
            </View>
          </View>
        ))}
      {voucherOrInvoice.metadata.additional_expenses.length > 0 &&
        expenseList.length !==
          voucherOrInvoice.metadata.additional_expenses.length && (
          <View style={[global.hPaddingTwenty, global.directionRow]}>
            <Pressable onPress={() => addOrRemoveExpense()}>
              <Text style={[global.textSecondaryOrange]}>+</Text>
            </Pressable>
            <Pressable onPress={() => addOrRemoveExpense()}>
              <Text
                style={[
                  global.textSecondaryOrange,
                  global.fontFourteen,
                  global.textRegular,
                  global.textDecorationLine,
                  style.expenseBtn,
                ]}
              >
                {STRINGS.BUTTON.ADD_MORE_EXPENSES}
              </Text>
            </Pressable>
          </View>
        )}
      <View
        style={[
          global.hPaddingTwenty,
          global.widthFull,
          global.bgWhite,
          global.positionAbsolute,
          global.vPaddingTen,
          style.footer,
        ]}
      >
        <View style={style.footerBtn}>
          <Button
            title={
              voucher && regenerate
                ? STRINGS.BUTTON.REGENERATE_INVOICE
                : STRINGS.BUTTON.CREATE_INVOICE
            }
            onTap={() => {
              if (voucher) {
                if (regenerate) {
                  openRegenerateInvoiceModal()
                } else {
                  openCreateInvoiceModal()
                }
              } else {
                openUpdateInvoiceModal()
              }
            }}
            style={global.borderRadiusEight}
            disable={disabled}
          />
        </View>
      </View>
      {showModal && (
        <Modal
          body={modalBody}
          footer={modalFooter}
          close={() => setShowModal(false)}
        />
      )}
    </View>
  )
}

export default ExpenseContent
