import React, { useEffect, useState } from 'react'
import {
  Modal,
  View,
  TouchableOpacity,
  Text,
  Pressable,
  Image,
} from 'react-native'
import { Camera as ExpoCamera } from 'expo-camera'

import STRINGS from 'constants/strings'

// styles
import global from 'styles/global'
import style from './style'

interface CameraProps {
  hideCamera: Function
  selectImage: Function
  setPreview: Function
  setImageResolution: Function
}

const Camera = ({
  hideCamera,
  selectImage,
  setPreview,
  setImageResolution,
}: CameraProps) => {
  const [camera, setCamera] = useState<any>(null)
  const [hasPermission, setHasPermission] = useState<boolean | null>(null)

  useEffect(() => {
    ;(async () => {
      const { status } = await ExpoCamera.requestCameraPermissionsAsync()
      setHasPermission(status === 'granted')
    })()
  }, [])

  const capture = async () => {
    if (camera) {
      const data = await camera?.takePictureAsync({
        allowsEditing: false,
      })
      setImageResolution({ width: data.width, height: data.height })
      selectImage(data.uri)
      hideCamera(false)
      setPreview(true)
    }
  }

  if (hasPermission === false) {
    return <Text>{STRINGS.MISCELLANEOUS.NO_CAMERA_ACCESS}</Text>
  }

  return (
    <Modal
      statusBarTranslucent
      presentationStyle="pageSheet"
      visible
      onRequestClose={() => hideCamera(false)}
    >
      <ExpoCamera
        ref={(cam: any) => setCamera(cam)}
        style={global.flexOne}
        type={ExpoCamera.Constants.Type.back}
      >
        <View style={[global.alignItemsRight, global.vPaddingTwenty]}>
          <Pressable
            onPress={() => hideCamera(false)}
            style={global.paddingTwenty}
          >
            <Image
              source={require('images/cancel-white.png')}
              style={style.iconCancel}
            />
          </Pressable>
        </View>
        <View
          style={[
            global.directionRow,
            global.widthFull,
            global.justifyContentSpaceBetween,
            global.positionAbsolute,
            global.flexOne,
            global.paddingTwenty,
            style.captureContainer,
          ]}
        >
          <View
            style={[
              global.flexOne,
              global.alignSelfCenter,
              global.alignItemsCenter,
            ]}
          >
            <TouchableOpacity
              onPress={capture}
              style={[global.bgWhite, style.capture]}
            />
          </View>
        </View>
      </ExpoCamera>
    </Modal>
  )
}

export default Camera
