import React, { useEffect, useState } from 'react'
import {
  View,
  Text,
  Pressable,
  ActivityIndicator,
  FlatList,
} from 'react-native'
import { Box, NativeBaseProvider } from 'native-base'
import { useDispatch, useSelector } from 'react-redux'
import tw from 'tailwind-react-native-classnames'

import STRINGS from 'constants/strings'
import { get, URL } from 'src/utilities/axios'
import { POListProps } from 'src/navigations/types'
import { ApplicationState } from 'src/redux/types'
import { ProcsPO, PurchaseOrderListResponse } from 'src/modals/purchase-order'
import {
  fetchProcsPO,
  resetPurchaseOrderList,
} from 'src/redux/actions/purchase-order'
import { voucherDateFormat } from 'src/config/voucher'
import { getEndTruncatedString, utcToLocalTime } from 'src/utilities/helpers'

// components
import HorizontalScrollableList, {
  FilterType,
} from 'src/components/filters/HorizontalScrollableList'
import CustomerInformation from 'src/components/CustomerInformation'
import VoucherCard from 'src/components/voucher/VoucherCard'
import Label from 'src/components/card/Label'
import VoucherMetadata from 'src/components/voucher/VoucherMetadata'
import NoResult from 'src/components/NoResult'
import SearchBox from 'src/components/SearchBox'

// style
import global from 'styles/global'
import colors from 'styles/colors'
import style from './style'

interface POReducerType {
  purchaseOrderList: ProcsPO[] | null
  totalPurchaseOrderCount: number | null
  isPOListLoading: boolean
}

const POList = ({ navigation }: POListProps) => {
  const dispatch = useDispatch()
  const [filterList, setFilterList] = useState<Array<FilterType>>([])
  const [searchInput, setSearchInput] = useState<string>('')
  const [listOffset, setListOffset] = useState<number>(0)
  const [selectedNode, selectNode] = useState<string | undefined>()
  const listFetchLimit: number = 10
  const {
    purchaseOrderList,
    totalPurchaseOrderCount,
    isPOListLoading,
  }: POReducerType = useSelector(
    (state: ApplicationState) => state.purchaseOrder,
  )

  useEffect(() => {
    dispatch(
      fetchProcsPO({
        limit: listFetchLimit,
        offset: listOffset,
        query: searchInput.length > 3 ? searchInput : undefined,
        node: selectedNode === 'All' ? undefined : selectedNode,
      }),
    )
  }, [dispatch, listOffset, searchInput, selectedNode])

  useEffect(() => {
    return () => {
      dispatch(resetPurchaseOrderList())
    }
  }, [dispatch])

  useEffect(() => {
    get(URL.NODE_LIST).then((res: any) => {
      res?.data.map((item: FilterType) => {
        item.isSelected = false
        return item
      })
      res.data.splice(0, 0, { id: 0, name: 'All', isSelected: true })
      setFilterList(res.data)
    })
  }, [])

  const loadNextPage = () => {
    if (
      purchaseOrderList!.length !== totalPurchaseOrderCount &&
      !isPOListLoading
    ) {
      setListOffset(listOffset + listFetchLimit)
    }
  }

  const selectFilter = (filter: FilterType) => {
    const selectedIndex = filterList.findIndex(
      (item: FilterType) => item.id === filter.id,
    )
    const copyOfFilterList: Array<FilterType> = [...filterList]
    if (copyOfFilterList[selectedIndex].isSelected) {
      return
    }
    copyOfFilterList.map((item: FilterType) => {
      item.isSelected = false
      return item
    })
    copyOfFilterList[selectedIndex].isSelected = true
    selectNode(filter.name)
    setFilterList(copyOfFilterList)
    dispatch(resetPurchaseOrderList())
    setListOffset(0)
    dispatch(
      fetchProcsPO({
        limit: listFetchLimit,
        offset: 0,
        query: searchInput.length > 3 ? searchInput : undefined,
        node: filter.name === 'All' ? undefined : filter.name,
      }),
    )
  }

  const CreatePONav = () => {
    navigation.navigate('CreatePO', { id: undefined })
  }

  const fetchPOByText = async (text: string) => {
    setSearchInput(text)
    if (text.length >= 3) {
      if (listOffset !== 0) {
        setListOffset(0)
        return
      }
      const response: PurchaseOrderListResponse = await get(URL.PROCS_PO_LIST, {
        state: 'purchase',
        limit: listFetchLimit,
        offset: 0,
        query: text.length > 3 ? text : undefined,
        node: selectedNode === 'All' ? undefined : selectedNode,
      })
      if (response.error === null) {
        dispatch(resetPurchaseOrderList())
        dispatch({
          type: 'APPEND_PURCHASE_ORDER_LIST',
          payload: response.data.items as ProcsPO[],
        })
        dispatch({
          type: 'SET_PURCHASE_ORDER_TOTAL_COUNT',
          payload: response.data.total_count as number,
        })
      }
    } else if (text.length < 3)
      dispatch(
        fetchProcsPO({
          limit: listFetchLimit,
          offset: listOffset,
          query: undefined,
          node: selectedNode === 'All' ? undefined : selectedNode,
        }),
      )
  }

  const renderItem = ({ item, index }: { item: ProcsPO; index: number }) => (
    <Pressable
      onPress={() => navigation.navigate('POView', { id: item?.id || index })}
      style={tw`my-2 mx-5`}
    >
      <VoucherCard
        header={
          <Label
            image={require('images/id-black.png')}
            text={item.po_name}
            containerStyle={[global.bgMint, style.label]}
            textStyle={[
              global.textPrimaryBlack,
              global.textMedium,
              global.fontTwelve,
              global.hPaddingFive,
            ]}
            imageStyle={tw`w-3 h-3`}
          />
        }
        body={
          <CustomerInformation
            customerName={item.vendor_name || 'N/A'}
            address={item.node}
            style={[tw`pt-2.5`, global.hPaddingTwenty]}
            product={item.product_info?.name.replace(/\n/g, ' ') || 'N/A'}
            quantity={
              item.product_info
                ? `(${item.product_info?.product_quantity}${item.product_info?.product_unit})`
                : undefined
            }
            productImage={require('images/product.png')}
            addressIconStyle={tw`w-3.5 h-3.5`}
            addressTextStyle={tw`text-sm`}
            qcBy={
              `${STRINGS.PURCHASE_ORDER.QC_BY} ${item?.product_info?.qc_by}` ||
              'N/A'
            }
            qcImage={require('images/qc-blue.png')}
          />
        }
        footer={
          <VoucherMetadata
            id={item.id || 0}
            attributes={[
              {
                attrName: STRINGS.PURCHASE_ORDER.DELIVERY_DATE,
                attrValue: item.date_delivery
                  ? utcToLocalTime(item.date_delivery, voucherDateFormat)
                  : getEndTruncatedString(
                      STRINGS.MISCELLANEOUS.DATE_NOTE_AVAILABLE,
                    ),
                icon: require('images/calendar.png'),
                iconStyle: tw`w-7 h-7`,
                attrStyle: global.fontFourteen,
                attrContainerStyle: global.flexFive,
              },
              {
                attrName: STRINGS.PURCHASE_ORDER.TOTAL_AMOUNT,
                attrValue: Intl.NumberFormat('en-IN', {
                  maximumFractionDigits: 0,
                  minimumFractionDigits: 0,
                }).format(item?.total_amount || 0),
                icon: require('images/currency.png'),
                iconStyle: tw`w-7 h-7`,
                attrStyle: global.fontFourteen,
                attrContainerStyle: global.flexFive,
              },
            ]}
            containerStyle={[global.bgNone, global.hPaddingSixteen, tw`pt-5`]}
          />
        }
      />
    </Pressable>
  )

  return (
    <NativeBaseProvider>
      <Box flex={1} safeAreaTop>
        <View style={tw`p-5 flex-row`}>
          <SearchBox
            placeholder={STRINGS.PURCHASE_ORDER.SEARCH_BY_VENDOR}
            searchInput={searchInput}
            onchangeFunc={fetchPOByText}
          />
        </View>
        <View>
          {filterList.length > 1 && (
            <HorizontalScrollableList
              filterList={filterList}
              setFilter={selectFilter}
            />
          )}
          {totalPurchaseOrderCount && (
            <View style={tw`pb-3 pt-5 px-5`}>
              <Text
                style={[
                  global.textSecondaryGrey,
                  global.textRegular,
                  tw`text-xs`,
                ]}
              >
                {`${STRINGS.PURCHASE_ORDER.ALL_POS}:` +
                  ` ${
                    totalPurchaseOrderCount !== 0
                      ? totalPurchaseOrderCount
                      : '00'
                  }`}
              </Text>
            </View>
          )}
        </View>
        {totalPurchaseOrderCount !== 0 && (
          <FlatList
            keyExtractor={item => `${(item?.id || 0).toString()}po`}
            renderItem={renderItem}
            data={purchaseOrderList}
            onEndReached={loadNextPage}
            onEndReachedThreshold={0.2}
          />
        )}
        {isPOListLoading && (
          <ActivityIndicator size="large" color={colors.primaryGreen} />
        )}
        {(selectedNode === 'All' || selectedNode == null) &&
          searchInput.length === 0 &&
          totalPurchaseOrderCount === 0 &&
          !isPOListLoading && (
            <NoResult
              imgSrc={require('images/not-created.png')}
              header={STRINGS.SEARCH_PO.NO_PO_CREATED}
              description={STRINGS.SEARCH_PO.PLEASE_CREATE_A_PO}
              btnText={STRINGS.BUTTON.CREATE_PO}
              pressFunc={CreatePONav}
              containerStyle={tw`mt-16`}
            />
          )}
        {(searchInput.length !== 0 || selectedNode) &&
          totalPurchaseOrderCount === 0 &&
          !isPOListLoading && (
            <NoResult
              imgSrc={require('images/no-result.png')}
              header={STRINGS.SEARCH_PO.NO_PO_FOUND}
              description={STRINGS.SEARCH_PO.CHANGE_SEARCH_PARAMETERS}
              containerStyle={tw`mt-16`}
            />
          )}
      </Box>
    </NativeBaseProvider>
  )
}

export default POList
