import { StyleSheet } from 'react-native'
import colors from 'styles/colors'

const styles = StyleSheet.create({
  tabIndicator: {
    width: '12%',
    height: 4,
    borderRadius: 2,
    left: '6%',
    backgroundColor: colors.bgLightGreen,
  },
  alertIcon: {
    width: 52,
    height: 45,
    marginTop: 34,
  },
  warningMsg: {
    lineHeight: 22,
    marginBottom: 30,
  },
  btnContainer: {
    borderTopColor: colors.lightGrey,
  },
  btnCover: {
    borderColor: colors.lightGrey,
  },
})

export default styles
