import React from 'react'
import { View, Text } from 'react-native'
import tw from 'tailwind-react-native-classnames'

import STRINGS from 'constants/strings'
import { VendorListingProps } from 'src/navigations/types'

// component
import Button from 'src/components/form-fields/Button'

// style
import global from 'styles/global'
import styles from './style'

const VendorListing = ({ navigation }: VendorListingProps) => {
  return (
    <View style={tw`h-0 flex-1`}>
      <View style={tw`h-0 flex-1`}>
        <Text>{STRINGS.SCREEN_TITLE.VENDOR_LISTING}</Text>
      </View>
      <View
        style={[global.borderColorLightSecondaryGrey, tw`bg-white py-2.5 px-5`]}
      >
        <Button
          title={STRINGS.VENDOR_LISTING.ADD_NEW_VENDOR}
          style={[tw`rounded-lg`, styles.addNewButton]}
          onTap={() => navigation.navigate('AddNewVendor')}
        />
      </View>
    </View>
  )
}

export default VendorListing
