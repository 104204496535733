import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  View,
  Text,
  ActivityIndicator,
  ScrollView,
  Pressable,
  Image,
} from 'react-native'
import { Box, NativeBaseProvider } from 'native-base'

import { fetchInvoicesByDVId } from 'src/redux/actions'
import { InvoiceListProps } from 'src/navigations/types'
import STRINGS from 'constants/strings'
import {
  getEndTruncatedString,
  getTruncatedString,
  utcToLocalTime,
} from 'src/utilities/helpers'
import {
  orderStatusObj,
  voucherDateFormat,
  voucherTimeFormat,
} from 'src/config/voucher'
import { Invoice } from 'src/modals'
import { ApplicationState } from 'src/redux/types'

// components
import VoucherCard from 'src/components/voucher/VoucherCard'
import CustomerInformation from 'src/components/CustomerInformation'
import VoucherMetadata from 'src/components/voucher/VoucherMetadata'
import TotalAmount from 'src/components/voucher/TotalAmount'

// style
import global from 'styles/global'
import colors from 'styles/colors'
import style from './style'

const invoiceSelector = (state: ApplicationState) => state.invoiceReducer

const InvoiceList = ({ navigation, route }: InvoiceListProps) => {
  const { voucher } = route.params
  const dispatch = useDispatch()
  const [invoices, setInvoices] = useState<Invoice[] | null>()
  const { invoiceList } = useSelector(invoiceSelector)

  useEffect(() => {
    setInvoices(invoiceList)
  }, [invoiceList])

  useEffect(() => {
    dispatch(fetchInvoicesByDVId(voucher.id))
    // TODO: Provide an explanation for why all deps are not included or fix it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  return (
    <NativeBaseProvider>
      <Box flex={1} safeAreaTop>
        {voucher ? (
          <>
            <VoucherCard
              header={
                <CustomerInformation
                  customerName={
                    voucher.partner_name || STRINGS.MISCELLANEOUS.NOT_AVAILABLE
                  }
                  mobile={voucher.contact}
                  id={voucher?.so_id}
                  address={voucher.address}
                  style={[global.hPaddingTwenty, global.vPaddingSixteen]}
                />
              }
              body={
                <VoucherMetadata
                  id={voucher.id}
                  attributes={[
                    {
                      attrName: STRINGS.UNBILLED_VOUCHERS.VOUCHER_ID,
                      attrValue:
                        voucher?.voucher_id.length > 10
                          ? getTruncatedString(voucher.voucher_id)
                          : voucher.voucher_id,
                      icon: require('images/id.png'),
                    },
                    {
                      attrName: STRINGS.UNBILLED_VOUCHERS.VOUCHER_DATE,
                      attrValue: voucher.date
                        ? utcToLocalTime(voucher.date, voucherDateFormat)
                        : getEndTruncatedString(
                            STRINGS.MISCELLANEOUS.DATE_NOTE_AVAILABLE,
                          ),
                      icon: require('images/calendar.png'),
                    },
                    {
                      attrName: STRINGS.UNBILLED_VOUCHERS.VOUCHER_TIME,
                      attrValue: voucher.date
                        ? utcToLocalTime(voucher.date, voucherTimeFormat)
                        : getEndTruncatedString(
                            STRINGS.MISCELLANEOUS.TIME_NOTE_AVAILABLE,
                          ),
                      icon: require('images/clock.png'),
                    },
                  ]}
                />
              }
              cardStyle={global.borderRadiusZero}
            />
            <ScrollView style={global.widthFull}>
              {invoices?.map((inv: Invoice) => (
                <Pressable
                  onPress={() =>
                    navigation.navigate('InvoiceHistoryDetails', { id: inv.id })
                  }
                  key={inv.id}
                  style={[global.marginTopFifteen, global.hMarginTwenty]}
                >
                  <VoucherCard
                    header={
                      <View
                        style={[
                          global.vPaddingTen,
                          global.directionRow,
                          global.borderRadiusFive,
                          global.marginTopFifteen,
                          global.bgMint,
                          style.status,
                        ]}
                      >
                        <Image
                          source={
                            orderStatusObj[inv.state] &&
                            orderStatusObj[inv.state].iconPath
                          }
                          style={style.tickIcon}
                        />
                        <Text
                          style={[
                            global.textPrimaryGreen,
                            global.fontTwelve,
                            global.textRegular,
                            global.hPaddingFive,
                            global.textCapitalize,
                          ]}
                        >
                          {inv.state}
                        </Text>
                      </View>
                    }
                    body={
                      <>
                        <View
                          style={[
                            global.hPaddingTwenty,
                            global.paddingTopFifteen,
                          ]}
                        >
                          <Text
                            style={[
                              global.textPrimaryBlack,
                              global.fontSixteen,
                              global.textMedium,
                            ]}
                          >
                            {inv.number || STRINGS.MISCELLANEOUS.NOT_AVAILABLE}
                          </Text>
                        </View>
                        <VoucherMetadata
                          id={inv.id}
                          attributes={[
                            {
                              attrName: STRINGS.INVOICE.INVOICE_DATE,
                              attrValue: inv.create_date
                                ? utcToLocalTime(
                                    inv.create_date,
                                    voucherDateFormat,
                                  )
                                : getEndTruncatedString(
                                    STRINGS.MISCELLANEOUS.DATE_NOTE_AVAILABLE,
                                  ),
                              icon: require('images/calendar.png'),
                            },
                            {
                              attrName: STRINGS.INVOICE.INVOICE_TIME,
                              attrValue: inv.create_date
                                ? utcToLocalTime(
                                    inv.create_date,
                                    voucherTimeFormat,
                                  )
                                : getEndTruncatedString(
                                    STRINGS.MISCELLANEOUS.TIME_NOTE_AVAILABLE,
                                  ),
                              icon: require('images/clock.png'),
                            },
                            {
                              attrName: STRINGS.INVOICE.CREDIT_NOTE,
                              attrValue: `${inv.credit_notes || 0} CD`,
                              icon: require('images/note.png'),
                            },
                          ]}
                          containerStyle={global.bgNone}
                        />
                      </>
                    }
                    footer={
                      <TotalAmount
                        amount={inv.amount_total || 0}
                        containerStyle={[
                          global.borderTopWidthOne,
                          global.borderTopColorbgLightBlue,
                        ]}
                      />
                    }
                  />
                </Pressable>
              ))}
            </ScrollView>
          </>
        ) : (
          <ActivityIndicator size="large" color={colors.primaryGreen} />
        )}
      </Box>
    </NativeBaseProvider>
  )
}

export default InvoiceList
