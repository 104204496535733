import { Platform, StyleSheet } from 'react-native'
import colors from 'styles/colors'

const styles = StyleSheet.create({
  priceInput: {
    width: 70,
    height: 30,
    borderColor: colors.secondaryTextGrey,
    ...Platform.select({
      ios: {
        marginTop: 4,
      },
      android: {
        marginTop: 4,
      },
      default: {
        marginTop: 0,
      },
    }),
  },
  marginLeftFive: {
    marginLeft: 5,
  },
  paddingBottomThirty: {
    paddingBottom: 30,
  },
  paddingLeftTwentyFive: {
    paddingLeft: 25,
  },
  paddingBottomFifteen: {
    paddingBottom: 15,
  },
  widthThirtyOnePercent: {
    width: '31%',
  },
  widthNinteenPercent: {
    width: '19%',
  },
  widthSeventy: {
    width: 70,
  },
  lineHeightTwelve: {
    lineHeight: 12,
  },
})

export default styles
