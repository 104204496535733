import { StyleSheet } from 'react-native'
import colors from 'styles/colors'

const styles = StyleSheet.create({
  showMoreContainer: {
    marginTop: 8,
  },
  showMoreStyle: {
    height: 5,
    width: 24,
    transform: [{ rotate: '90deg' }],
  },
  iconCancel: {
    width: 10,
    height: 10,
  },
  continueToInvoiceContainer: {
    borderTopWidth: 1,
    borderTopColor: colors.lightSecondaryGrey,
  },
  continueToInvoiceButton: {
    height: 45,
  },
})

export default styles
