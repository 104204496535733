import React, { ReactElement } from 'react'
import { View } from 'react-native'

// style
import global from 'styles/global'

interface Props {
  header: ReactElement
  body: ReactElement
  footer?: ReactElement
  cardStyle?: object
}

const VoucherCard = ({ header, body, footer, cardStyle }: Props) => (
  <View
    style={[
      global.bgWhite,
      global.borderRadiusEight,
      global.borderWidthOne,
      global.borderColorGrey,
      cardStyle,
    ]}
  >
    {header}
    {body}
    {footer}
  </View>
)

export default VoucherCard
