import React, { useEffect, useState } from 'react'
import {
  View,
  Text,
  Image,
  TextInput,
  Pressable,
  KeyboardTypeOptions,
  ImageSourcePropType,
  Dimensions,
  Modal,
  StatusBar,
  Platform,
  LayoutChangeEvent,
} from 'react-native'
import { ScrollView } from 'react-native-gesture-handler'
import tw from 'tailwind-react-native-classnames'

import { get } from 'src/utilities/axios/request'
import { useKeyboard } from 'src/utilities/hooks/useKeyboard'

// style
import global from 'styles/global'
import colors from 'styles/colors'

export interface SearchOptionsProps {
  title: string
  searchPlaceholder: string
  closeDrawer: Function
  keyDisplayName: string
  keyObjUnique?: string
  searchKeys: string | Array<string>
  onLoadValue?: any
  renderCustomItem?: Function
  localItemList?: Array<object>
  itemListOnLoad?: Array<object>
  apiToLoadOption?: string
  apiResultEndPoint?: string
  serverItemLimit?: number
  searchBoxContainerStyle?: object
  searchInputBoxStyle?: object
  errorMessage?: string
  errorContainerStyle?: object
  errorTextStyle?: object
  hideSearchBoxItemLimit?: number
  searchBoxLeftImage?: ImageSourcePropType
  searchBoxLeftImageStyle?: object
  searchBoxRightImage?: ImageSourcePropType
  searchBoxRightImageStyle?: object
  searchFieldType?: KeyboardTypeOptions
  key?: string
}

const SearchOptions = (props: SearchOptionsProps) => {
  const {
    title, // Title of drawer
    searchPlaceholder, // Placeholder of search box
    closeDrawer, // Function on close drawer
    keyDisplayName, // Key in server or local list to display name
    keyObjUnique, // Unique key for object for iteration
    searchKeys, // Key to apply search on
    onLoadValue, // Selected value will be bold on load
    renderCustomItem, // Custom display item in list
    localItemList, // Local item list /array of object
    itemListOnLoad, // Item list on first load in server search
    apiToLoadOption, // Api to load item
    apiResultEndPoint, // Key in api result to pick data
    serverItemLimit = 100,
    searchBoxContainerStyle,
    searchInputBoxStyle,
    errorMessage,
    errorContainerStyle,
    errorTextStyle,
    hideSearchBoxItemLimit = 10, // Hide search box
    searchBoxLeftImage,
    searchBoxLeftImageStyle,
    searchBoxRightImage,
    searchBoxRightImageStyle,
    searchFieldType = 'default',
    key,
  } = props
  const [filteredItem, setFilteredItem] = useState<Array<object>>([])
  const [searchParam, setSearchParam] = useState<string>('')
  const [selectedItem, setSelectedItem] = useState<{ id?: number | string }>({})
  const [onLoadItemCount, setOnLoadItemCount] = useState<number>(0)
  const [drawerHeight, setDrawerHeight] = useState<number>(0)
  const keyboardHeight = useKeyboard()

  useEffect(() => {
    if (localItemList && localItemList.length > 0) {
      setFilteredItem(localItemList)
      setOnLoadItemCount(localItemList?.length)
    }
  }, [localItemList])

  const setOnLoadDisplayItem = (setFilterItem: boolean) => {
    if (filteredItem?.length > 0) {
      const onloadDisplayItem = filteredItem.find(
        (item: any) => item?.id === onLoadValue?.id,
      )
      if (onloadDisplayItem) {
        setSelectedItem(onloadDisplayItem)
        if (setFilterItem) {
          setFilteredItem([onloadDisplayItem])
        }
      }
    }
  }

  const setServerData = (data: any) => {
    let endData = []
    if (apiResultEndPoint && data?.data?.[apiResultEndPoint]) {
      endData = data.data[apiResultEndPoint]
    } else if (data.data) {
      endData = data.data
    }
    setFilteredItem(endData)
    return endData
  }

  const getFilteredList = () => {
    if (typeof searchKeys === 'string' && apiToLoadOption) {
      // TODO: Provide a different variable name to searchParam
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const searchParam = {
        limit: serverItemLimit,
        offset: 0,
        [searchKeys]: onLoadValue?.[keyDisplayName],
      }
      get(apiToLoadOption, searchParam)
        .then(data => {
          setSearchParam(onLoadValue?.[keyDisplayName])
          setServerData(data)
          setOnLoadDisplayItem(true)
        })
        .catch(err => console.error(err))
    }
  }

  useEffect(() => {
    if (onLoadValue?.id) {
      if (onLoadItemCount > hideSearchBoxItemLimit) {
        if (apiToLoadOption) getFilteredList()
        if (localItemList) {
          setSearchParam(onLoadValue?.[keyDisplayName])
          setOnLoadDisplayItem(true)
        }
      } else setOnLoadDisplayItem(false)
    }
    // TODO: Provide an explanation for why all deps are not included or fix it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onLoadItemCount])

  useEffect(() => {
    if (itemListOnLoad && itemListOnLoad?.length > 0) {
      setFilteredItem(itemListOnLoad)
      setOnLoadItemCount(itemListOnLoad.length)
    } else if (apiToLoadOption) {
      // TODO: Provide a different variable name to searchParam
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const searchParam = { limit: serverItemLimit, offset: 0 }
      get(apiToLoadOption, searchParam)
        .then(data => {
          const endData = setServerData(data)
          setOnLoadItemCount(endData?.length)
        })
        .catch(err => console.error(err))
    }
    // TODO: Provide an explanation for why all deps are not included or fix it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiToLoadOption])

  const onInputTextChange = (inputText: string) => {
    setSearchParam(inputText)
    if (localItemList && localItemList.length > 0) {
      if (typeof searchKeys === 'string') {
        const filteredItemList = localItemList?.filter((item: any) =>
          item[keyDisplayName]
            ?.toLowerCase()
            ?.toString()
            ?.startsWith(inputText?.toLowerCase()),
        )
        setFilteredItem(filteredItemList || [])
      }
    } else if (apiToLoadOption) {
      if (typeof searchKeys === 'string') {
        get(apiToLoadOption, {
          [searchKeys]: inputText,
          limit: serverItemLimit,
          offset: 0,
        })
          .then(data => setServerData(data))
          .catch(err => console.error(err))
      }
    }
    if (selectedItem?.id !== null) setSelectedItem({})
  }

  const renderText = (item: any) => {
    const searchParamLength = searchParam?.length
    let boldText
    let normalText
    if (searchParamLength && !selectedItem.id) {
      boldText = item[keyDisplayName]?.slice(0, searchParamLength)
      normalText = item[keyDisplayName]?.slice(
        searchParamLength,
        item[keyDisplayName]?.length,
      )
    } else {
      normalText = item[keyDisplayName]
    }
    return (
      <View style={tw`flex-row`}>
        {boldText && (
          <Text
            style={[global.textPrimaryBlack, global.textMedium, tw`text-sm`]}
          >
            {boldText}
          </Text>
        )}
        <Text
          style={[
            global.textColorGrey,
            global.textRegular,
            tw`text-sm`,
            selectedItem?.id === item.id && [
              global.textMedium,
              global.textPrimaryBlack,
            ],
          ]}
        >
          {normalText}
        </Text>
      </View>
    )
  }

  const onLayout = (event: LayoutChangeEvent) => {
    const { height } = event.nativeEvent.layout
    const windowHeight = Dimensions.get('window').height
    const statusBarHeight = StatusBar.currentHeight as number
    const topHeaderMargin = 21

    const topRemovableHeight =
      Platform.OS !== 'web'
        ? statusBarHeight + topHeaderMargin
        : topHeaderMargin

    if (height > windowHeight - topRemovableHeight) {
      setDrawerHeight(windowHeight - topRemovableHeight)
    }
  }

  const renderItem = (item: any) => (
    <Pressable
      key={keyObjUnique ? item[keyObjUnique] : item?.id}
      style={[
        global.borderColorSmokeGrey,
        tw`border border-t-0 border-l-0 border-r-0`,
      ]}
      onPress={() => closeDrawer(item, key)}
    >
      <View style={tw`m-5`}>
        {renderCustomItem
          ? renderCustomItem(item, selectedItem, searchParam)
          : renderText(item)}
      </View>
    </Pressable>
  )

  return (
    <Modal
      animationType="none"
      visible
      transparent
      onRequestClose={() => {
        closeDrawer(selectedItem, key)
      }}
    >
      <View
        style={[
          global.drawer,
          {
            height: drawerHeight === 0 ? 'auto' : drawerHeight - keyboardHeight,
          },
        ]}
        onLayout={(event: LayoutChangeEvent) => onLayout(event)}
      >
        <View style={tw`justify-between flex-row m-5`}>
          <Text style={[global.textMedium, global.textColorGrey, tw`text-sm`]}>
            {title}
          </Text>
          <Pressable onPress={() => closeDrawer(selectedItem, key)}>
            <Image
              source={require('images/cancel.png')}
              style={tw`w-2.5 h-2.5`}
            />
          </Pressable>
        </View>
        {onLoadItemCount > hideSearchBoxItemLimit && (
          <View
            style={
              searchBoxContainerStyle || [
                global.borderColorGrey,
                tw`flex-row mx-5 rounded-md border items-center h-9`,
              ]
            }
          >
            <Image
              source={searchBoxLeftImage || require('images/search.png')}
              style={searchBoxLeftImageStyle || tw`w-3 h-3 ml-3 mr-1.5`}
            />
            <TextInput
              style={
                searchInputBoxStyle || [
                  global.textColorGrey,
                  tw`text-xs ml-1.5 mr-4 border-0 w-9/12`,
                  searchParam.length > 0 && global.textMedium,
                ]
              }
              keyboardType={searchFieldType}
              placeholder={searchPlaceholder}
              placeholderTextColor={colors.textGrey}
              onChangeText={text => onInputTextChange(text)}
              value={searchParam}
            />
            {searchParam.length > 0 && (
              <Pressable onPress={() => onInputTextChange('')}>
                <Image
                  source={
                    searchBoxRightImage || require('images/search-cancel.png')
                  }
                  style={searchBoxRightImageStyle || tw`items-end w-4 h-4`}
                />
              </Pressable>
            )}
          </View>
        )}
        {errorMessage && (
          <View
            style={errorContainerStyle || tw`bg-red-100 rounded-md mx-5 mt-5`}
          >
            <Text
              style={
                errorTextStyle || [
                  global.textRegular,
                  global.textRed,
                  tw`text-xs p-2.5`,
                ]
              }
            >
              {errorMessage}
            </Text>
          </View>
        )}
        <ScrollView>
          {filteredItem.length > 0 &&
            filteredItem.map((item: any) => renderItem(item))}
        </ScrollView>
      </View>
    </Modal>
  )
}

export default SearchOptions
