import React, { useEffect, useState } from 'react'
import { View, Image, ImageSourcePropType, TextInput } from 'react-native'
import { debounce, showToastNotification } from 'src/utilities/helpers'
import tw from 'tailwind-react-native-classnames'

import { DOWNLOAD } from 'src/config/download'
import STRINGS from 'constants/strings'

// styles
import global from 'styles/global'
import colors from 'styles/colors'

export interface SearchBoxProps {
  onchangeFunc: Function
  searchInput: string
  placeholder: string
  imgStyle?: Object
  textStyle?: Object
  imgSrc?: ImageSourcePropType
  placeholderColor?: string
  waitTime?: number
}

const SearchBox = ({
  onchangeFunc,
  searchInput,
  placeholder,
  imgStyle,
  textStyle,
  imgSrc,
  placeholderColor,
  waitTime = 1000,
}: SearchBoxProps) => {
  const [searchText, setSearchText] = useState<string>()
  const debouncedSearch = debounce(onchangeFunc, waitTime)
  const setText = (txt: string) => {
    setSearchText(txt)
    debouncedSearch(txt)
  }

  useEffect(() => {
    if (searchText !== undefined && searchText?.length > 50) {
      try {
        showToastNotification(
          DOWNLOAD.ERROR,
          `${STRINGS.SEARCH_PO.CHANGE_SEARCH_PARAMETERS}`,
        )
      } catch (e) {
        console.error('Toast notification not imported in the component')
      }
    }
  }, [searchText])

  return (
    <View
      style={[
        global.borderColorGrey,
        tw`flex-row flex-1 rounded-md border items-center p-3 bg-white`,
      ]}
    >
      <Image
        source={imgSrc || require('images/search.png')}
        style={[tw`w-3 h-3 mr-1.5`, imgStyle]}
      />
      <TextInput
        style={[
          global.textColorGrey,
          tw`text-xs border-0 w-11/12`,
          searchInput.length > 0 && global.textMedium,
          textStyle,
        ]}
        placeholder={placeholder}
        value={searchText}
        onChangeText={text => setText(text)}
        placeholderTextColor={placeholderColor || colors.textGrey}
      />
    </View>
  )
}

export default SearchBox
