import React from 'react'
import {
  View,
  Text,
  Image,
  ImageSourcePropType,
  ScrollView,
} from 'react-native'
import tw from 'tailwind-react-native-classnames'

// components
import Button from 'src/components/form-fields/Button'

// style
import global from 'styles/global'
import style from './style'

// interface
export interface NoResultProps {
  imgSrc?: ImageSourcePropType
  imgStyle?: Object
  header?: string
  headerStyle?: Object
  description?: string
  descriptionStyle?: Object
  btnText?: string
  pressFunc?: Function
  containerStyle?: Object
}

const NoResult = ({
  imgSrc,
  imgStyle,
  header,
  headerStyle,
  description,
  descriptionStyle,
  btnText,
  pressFunc,
  containerStyle,
}: NoResultProps) => {
  return (
    <ScrollView>
      <View style={[tw`items-center justify-center flex-1`, containerStyle]}>
        {imgSrc && (
          <Image source={imgSrc} style={[style.imagestyle, imgStyle]} />
        )}
        <Text
          style={[
            tw`mt-10 font-bold text-center`,
            global.textRegular,
            global.fontEighteen,
            style.headerStyle,
            headerStyle,
          ]}
        >
          {header}
        </Text>
        <Text
          style={[
            tw`mt-2.5 font-normal text-center`,
            global.textRegular,
            global.fontTwelve,
            style.descriptionStyle,
            descriptionStyle,
          ]}
        >
          {description}
        </Text>
        {pressFunc && btnText && (
          <Button
            title={btnText}
            onTap={() => pressFunc()}
            style={[tw`rounded-lg mt-8`, style.buttonStyle]}
          />
        )}
      </View>
    </ScrollView>
  )
}

export default NoResult
