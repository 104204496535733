import React from 'react'
import { View, Text, Image } from 'react-native'
import { Box, Center, NativeBaseProvider } from 'native-base'

import { EmptyCartProp } from 'src/navigations/types'
import STRINGS from 'constants/strings'

// components
import Button from 'src/components/form-fields/Button'

// styles
import global from 'styles/global'
import colors from 'styles/colors'
import style from './style'

const EmptyCart = ({ navigation }: EmptyCartProp) => {
  const createOrder = () => {
    navigation.navigate('CreateOrder')
  }

  return (
    <NativeBaseProvider>
      <Box flex={1} backgroundColor={colors.white} safeAreaTop>
        <Center flex={1}>
          <View style={[global.alignItemsCenter, global.widthFull]}>
            <Image
              source={require('images/empty-cart.png')}
              style={style.shoppingCart}
            />
            <View style={[global.alignItemsCenter, style.textContainer]}>
              <Text
                style={[
                  global.textBlack,
                  global.fontTwentyFour,
                  global.vMarginSixteen,
                ]}
              >
                {STRINGS.MISCELLANEOUS.EMPTY_CART}
              </Text>
              <Text style={[global.textRegular, global.fontSixteen]}>
                {STRINGS.MISCELLANEOUS.EMPTY_CART_MSG}
              </Text>
            </View>
            <View style={[global.hPaddingTwenty, global.widthFull]}>
              <Button
                style={[global.borderRadiusEight, style.btnCreateOrder]}
                title={STRINGS.BUTTON.CREATE_ORDER}
                onTap={() => createOrder()}
              />
            </View>
          </View>
        </Center>
      </Box>
    </NativeBaseProvider>
  )
}

export default EmptyCart
