import React, { useEffect, useState } from 'react'
import {
  View,
  Text,
  Image,
  Pressable,
  ActivityIndicator,
  FlatList,
} from 'react-native'
import { Box, NativeBaseProvider } from 'native-base'

import STRINGS from 'constants/strings'
import {
  getEndTruncatedString,
  getTruncatedString,
  utcToLocalTime,
} from 'src/utilities/helpers'
import { get, URL } from 'src/utilities/axios'
import { voucherDateFormat, voucherTimeFormat } from 'src/config/voucher'
import { Invoice, InvoicesResponse } from 'src/modals'
import { CreditNoteInvoiceProps } from 'src/navigations/types'

// component
import CustomerInformation from 'src/components/CustomerInformation'
import TotalAmount from 'src/components/voucher/TotalAmount'
import VoucherCard from 'src/components/voucher/VoucherCard'
import VoucherMetadata from 'src/components/voucher/VoucherMetadata'

// style
import global from 'styles/global'
import colors from 'styles/colors'
import style from './style'

const CreditNoteInvoice = ({ navigation }: CreditNoteInvoiceProps) => {
  const [invoices, setInvoices] = useState<InvoicesResponse>({
    items: [],
    total_count: 0,
  })
  const [offset, setOffset] = useState<number>(0)
  const [fetchRecord, setFetchRecord] = useState<boolean>(false)
  const [numberOfInvoice] = useState<number>(10)
  const [sortingOrder, setSortingOrder] = useState<string>('DESC')

  const sortInvoice = () => {
    setInvoices({ items: [], total_count: 0 })
    if (sortingOrder === 'ASC') setSortingOrder('DESC')
    else setSortingOrder('ASC')
  }

  const fetchInvoices = () => {
    setFetchRecord(true)
    get(URL.INVOICE_LIST, {
      limit: numberOfInvoice,
      offset,
      order: sortingOrder,
    })
      .then(data => {
        if (data && data.data) {
          setInvoices({
            items: [...invoices.items, ...data.data?.items],
            total_count: data.data?.total_count,
          })
        }
        setFetchRecord(false)
      })
      .catch(error => console.error(error))
  }

  useEffect(() => {
    if (offset > 0) setOffset(0)
    // TODO: Provide an explanation for why all deps are not included or fix it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortingOrder])

  useEffect(() => {
    fetchInvoices()
    // TODO: Provide an explanation for why all deps are not included or fix it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset])

  const onLoadMore = () => {
    if (invoices.items.length !== invoices.total_count && !fetchRecord)
      setOffset(offset + numberOfInvoice)
  }

  const renderItem = ({ item }: { item: Invoice }) => (
    <Pressable
      key={`${item.origin}${item.id}`}
      style={[global.vMarginEight, global.hMarginTwenty]}
      onPress={() =>
        navigation.navigate('CreditInvoiceDetails', { id: item.id })
      }
    >
      <VoucherCard
        header={
          <VoucherMetadata
            id={item.id}
            lineSeparator={false}
            attributes={[
              {
                attrName: STRINGS.INVOICE.INVOICE_ID,
                attrValue:
                  item?.number.length > 10
                    ? getTruncatedString(item.number)
                    : item.number,
                icon: require('images/id.png'),
              },
              {
                attrName: STRINGS.INVOICE.INVOICE_DATE,
                attrValue: item.date_invoice
                  ? utcToLocalTime(item.date_invoice, voucherDateFormat)
                  : getEndTruncatedString(
                      STRINGS.MISCELLANEOUS.DATE_NOTE_AVAILABLE,
                    ),
                icon: require('images/calendar.png'),
              },
              {
                attrName: STRINGS.INVOICE.INVOICE_TIME,
                attrValue: item.date_invoice
                  ? utcToLocalTime(item.date_invoice, voucherTimeFormat)
                  : getEndTruncatedString(
                      STRINGS.MISCELLANEOUS.DATE_NOTE_AVAILABLE,
                    ),
                icon: require('images/clock.png'),
              },
            ]}
          />
        }
        body={
          <CustomerInformation
            customerName={item.customer?.name}
            mobile={item.customer?.mobile}
            id={item.origin}
            style={global.paddingTwenty}
          />
        }
        footer={
          <TotalAmount
            amount={item.amount_total_signed}
            containerStyle={[
              global.borderTopWidthOne,
              global.borderTopColorbgLightBlue,
            ]}
          />
        }
      />
    </Pressable>
  )

  return (
    <NativeBaseProvider>
      <Box flex={1} safeAreaTop>
        <View
          style={[
            global.hMarginTwenty,
            global.directionRow,
            global.justifyContentSpaceBetween,
            global.alignItemsCenter,
            style.unbilledVoucher,
          ]}
        >
          <Text
            style={[
              global.textSecondaryGrey,
              global.textRegular,
              global.fontTwelve,
            ]}
          >
            {`${STRINGS.INVOICE.INVOICES}: ${invoices.total_count}`}
          </Text>
          <Pressable
            style={[global.directionRow, global.justifyContentSpaceBetween]}
            onPress={() => sortInvoice()}
          >
            <Image
              source={require('images/exchange.png')}
              style={style.iconRecent}
            />
            <Text
              style={[
                global.textMedium,
                global.fontFourteen,
                global.textPrimaryGreen,
                style.marginLeftSeven,
              ]}
            >
              {STRINGS.UNBILLED_VOUCHERS.RECENT}
            </Text>
          </Pressable>
        </View>
        <FlatList
          keyExtractor={item => `${item.number}${item.id}`}
          renderItem={renderItem}
          data={invoices.items}
          onEndReached={onLoadMore}
          onEndReachedThreshold={0.2}
        />
        {fetchRecord && (
          <ActivityIndicator size="large" color={colors.primaryGreen} />
        )}
      </Box>
    </NativeBaseProvider>
  )
}

export default CreditNoteInvoice
