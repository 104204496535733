import React from 'react'
import { View, ActivityIndicator, ScrollView } from 'react-native'
import tw from 'tailwind-react-native-classnames'

import { ProductItem, Voucher } from 'src/modals'
import { voucherDateFormat, voucherTimeFormat } from 'src/config/voucher'
import { formatterWithoutDecimals, utcToLocalTime } from 'src/utilities/helpers'
import STRINGS from 'constants/strings'
import { InvoiceDetailsScreenNavigationProp } from 'src/navigations/types'

// components
import VoucherMetadata from 'src/components/voucher/VoucherMetadata'
import TotalAmount from 'src/components/voucher/TotalAmount'
import DetailItemCard from 'src/components/voucher/DetailItemCard'

// styles
import global from 'styles/global'
import colors from 'styles/colors'
import style from '../style'

export interface PrimaryDetailsProps {
  route: {
    voucher: Voucher
    navigation: InvoiceDetailsScreenNavigationProp
  }
}

const PrimaryDetails = ({ route }: PrimaryDetailsProps) => {
  const { voucher } = route

  const invoiceAttributes = [
    {
      icon: require('images/calendar.png'),
      attrName: STRINGS.PURCHASE_ORDER.DELIVERY_DATE,
      attrValue: utcToLocalTime(voucher?.write_date!, voucherDateFormat),
      iconStyle: style.calendarIcon,
      attrValueStyle: [global.fontThirteen, global.textBold],
    },
    {
      icon: require('images/clock.png'),
      attrName: STRINGS.ORDER.DELIVERY_TIME,
      attrValue: utcToLocalTime(voucher?.write_date!, voucherTimeFormat),
      iconStyle: style.calendarIcon,
      attrValueStyle: [global.fontThirteen, global.textBold],
    },
  ]

  return (
    <>
      {voucher ? (
        <View style={[global.flexOne, global.zeroHeight]}>
          <VoucherMetadata
            attributes={invoiceAttributes}
            id={voucher.id}
            containerStyle={tw`justify-around`}
          />
          <View style={global.bgWhite}>
            <TotalAmount
              amount={
                Number(formatterWithoutDecimals.format(voucher.total_amount)) ||
                0
              }
              textStyle={global.textPrimaryBlack}
            />
          </View>
          <View style={[global.flexOne, global.zeroHeight]}>
            <ScrollView>
              {voucher.items.map((voucherItem: ProductItem) => (
                <DetailItemCard
                  key={`${voucherItem.id}`}
                  name={voucherItem.product_name}
                  imageS3Key={voucherItem.product_image}
                  tax={voucherItem.tax_display_name}
                  totalAmount={formatterWithoutDecimals.format(
                    voucherItem.price_total || 0,
                  )}
                  customUOM={voucherItem.product_uom[1]}
                  detailColumns={[
                    {
                      key: STRINGS.GRNS.UNIT_PRICE,
                      value: `₹${voucherItem?.unit_price}`,
                    },
                    {
                      key: STRINGS.GRNS.DEMAND,
                      value: voucherItem?.product_demand || 'N/A',
                    },
                  ]}
                />
              ))}
            </ScrollView>
          </View>
        </View>
      ) : (
        <ActivityIndicator
          size="large"
          color={colors.primaryGreen}
          style={global.vMarginSixteen}
        />
      )}
    </>
  )
}

export default PrimaryDetails
