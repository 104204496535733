import React, { ReactElement, useEffect, useState } from 'react'
import { Box, NativeBaseProvider } from 'native-base'
import { View, Text, ScrollView, Pressable, Image } from 'react-native'
import tw from 'tailwind-react-native-classnames'
import CalendarPicker from 'react-native-calendar-picker'
import moment, { Moment } from 'moment'
import { useDispatch, useSelector } from 'react-redux'

import STRINGS from 'constants/strings'
import { ApplicationState } from 'src/redux/types'
import { formatterWithoutDecimals, utcToLocalTime } from 'src/utilities/helpers'
import { resetVendorBillFields } from 'src/redux/actions'
import { voucherDateFormat, voucherTimeFormat } from 'src/config/voucher'
import { UpdateVendorBillProps } from 'src/navigations/types'

// components
import Button from 'src/components/form-fields/Button'
import ImageUploadCard from 'src/components/ImageUploadCard'
import InputTextField from 'src/components/form-fields/InputTextField'
import Modal from 'src/components/Modal'
import ToastNotification from 'src/components/ToastNotification'
import VoucherCard from 'src/components/voucher/VoucherCard'
import VoucherMetadata from 'src/components/voucher/VoucherMetadata'
import CardInfo from 'src/components/drawers/CardInfo'
import ItemCard from 'src/screens/procurement-manager/common/ItemCard'

// styles
import global from 'styles/global'
import colors from 'styles/colors'
import style from './style'

const UpdateVendorBill = ({ navigation, route }: UpdateVendorBillProps) => {
  const { id } = route.params
  const dispatch = useDispatch()
  const [documentUploaded, setDocumentUploaded] = useState<string>('')
  const [selectedGRN, setSelectedGRN] = useState<any>('')
  const [invoiceNumber, setInvoiceNumber] = useState<string>('')
  const [invoiceDate, setInvoiceDate] = useState<string>('')
  const [modalBody, setModalBody] = useState<ReactElement>(<></>)
  const [modalFooter, setModalFooter] = useState<ReactElement>(<></>)
  const [comments, setComments] = useState<string>('')
  const [modalType, setModalType] = useState<string>('')
  const poStore = useSelector(
    (state: ApplicationState) => state.purchaseOrder.grnDetailsByPO,
  )

  useEffect(() => {
    dispatch(resetVendorBillFields())
    if (!poStore?.consolidated_vendor_bill_allowed && id) {
      const filteredGRN = poStore?.grn_details?.filter((item: any) => {
        return item.id === id
      })
      setSelectedGRN(filteredGRN)
    } else {
      setSelectedGRN(poStore?.grn_details)
    }
  }, [
    dispatch,
    poStore?.consolidated_vendor_bill_allowed,
    poStore?.grn_details,
    id,
  ])

  const uploadOrDeleteDocument = (image: string, isDeleteTapped?: boolean) => {
    if (isDeleteTapped) {
      setDocumentUploaded('')
    } else {
      setDocumentUploaded(image)
    }
  }

  const leftHeader = (text: string, isAsterik: boolean) => (
    <View style={tw`flex-row items-center`}>
      <Text style={[global.textColorGrey, global.textRegular, tw`text-xs`]}>
        {text}
      </Text>
      {isAsterik && <Text style={global.textRed}>*</Text>}
    </View>
  )

  const paymentDate = (date: string) => {
    const paymentTerm = poStore?.more_details?.payment_terms?.[1]
      .toString()
      .split(' ')[0]
    return moment(date, 'DD-MM-YYYY')
      .add(Number(paymentTerm), 'd')
      .format('DD/MM/YYYY')
      .toString()
  }

  const onDateChange = (date: Moment) => {
    setModalType('')
    setInvoiceDate(date.format('DD/MM/YYYY').toString())
  }

  const goToNextScreen = () => {
    dispatch({
      type: 'SET_VENDOR_INVOICE_DATE',
      payload: invoiceDate,
    })
    dispatch({
      type: 'SET_VENDOR_INVOICE_NUMBER',
      payload: invoiceNumber,
    })
    dispatch({
      type: 'SET_VENDOR_BILL_IMAGE',
      payload: documentUploaded,
    })
    dispatch({
      type: 'SET_VENDOR_BILL_COMMENT',
      payload: comments,
    })
    navigation.navigate('ProcVendorBillSummary', { id })
  }

  const goBackToPreviousScreen = () => {
    setModalType('')
    navigation.goBack()
  }

  const calculateTaxAmount = (item: any) => {
    // eslint-disable-next-line no-useless-escape
    const regex: RegExp = /\@(.*?)\%/
    const regexResult = regex.exec(item?.items[0]?.tax_display_name)
    if (!regexResult) {
      return '0'
    }
    const taxPercentage = item?.items[0]?.tax_display_name ? regexResult[1] : 0
    return (
      (Number(item?.items[0]?.amount) / 100) *
      Number(taxPercentage)
    ).toFixed(2)
  }

  const modal = () => {
    setModalBody(
      <View style={tw`items-center mt-9`}>
        <Image style={tw`h-12 w-14`} source={require('images/warning.png')} />
        <View style={tw`w-full`}>
          <Text
            style={[
              global.textRegular,
              global.textColorGrey,
              tw`text-sm text-center leading-5 mx-4 mt-7`,
            ]}
          >
            {STRINGS.VENDOR_BILL.VENDOR_BILL_CONFIRMATION_MSG}
          </Text>
        </View>
      </View>,
    )
    setModalFooter(
      <View
        style={[
          global.borderColorLightGrey,
          tw`mt-12 flex-row justify-between border-t`,
        ]}
      >
        <Button
          title={STRINGS.BUTTON.YES}
          onTap={() => goBackToPreviousScreen()}
          btnType="secondary"
          style={[
            global.borderColorLightGrey,
            tw`w-1/2 border-0 rounded-bl-lg`,
          ]}
          textStyle={[
            global.textRegular,
            global.textSecondaryOrange,
            tw`text-sm`,
          ]}
        />
        <Button
          title={STRINGS.BUTTON.NO}
          onTap={() => setModalType('')}
          btnType="secondary"
          style={[
            global.borderColorLightGrey,
            tw`w-1/2 border-0 rounded-br-lg`,
          ]}
          textStyle={[global.textRegular, tw`text-sm`]}
        />
      </View>,
    )
    setModalType('backPopup')
  }

  const openModal = () => {
    if (invoiceDate || invoiceNumber) {
      setModalType('backPopup')
      modal()
    } else {
      navigation.goBack()
    }
  }

  const checkQuantityMismatch = (item: any) => {
    if (!item || item?.item === undefined) {
      return false
    }
    return item?.items[0]?.quantity_done > item?.items[0]?.product_uom_qty
  }

  return (
    <NativeBaseProvider>
      <Box flex={1} safeAreaTop>
        <ToastNotification />
        <ScrollView
          style={[tw`flex-1`, modalType === 'GRNS' && global.drawerBackground]}
        >
          <View
            style={[
              global.bgLightGreen,
              tw`px-5 flex-row w-full h-14 justify-between items-center`,
            ]}
          >
            <Text style={tw`text-sm items-center`}>
              <Text
                style={tw`text-sm font-bold`}
              >{`${`0${selectedGRN?.length}`.slice(-2)}`}</Text>
              {STRINGS.VENDOR_BILL.GRN_SELECTED}
            </Text>
            <Pressable onPress={() => setModalType('GRNS')}>
              <Text
                style={[
                  global.textRegular,
                  global.textPrimaryGreen,
                  tw`text-xs underline`,
                ]}
              >
                {STRINGS.MISCELLANEOUS.VIEW_DETAILS}
              </Text>
            </Pressable>
          </View>
          <View style={tw`pt-8 pb-4 px-5 rounded-b-2xl bg-white`}>
            <ImageUploadCard
              setImage={(image: string, isDeleteTapped?: boolean) =>
                uploadOrDeleteDocument(image, isDeleteTapped)
              }
              image={documentUploaded}
              text={
                !documentUploaded
                  ? STRINGS.VENDOR_BILL.UPLOAD_MSG
                  : STRINGS.VENDOR_BILL.SUCCESSFULLY_UPLOADED_MSG
              }
              buttonText={STRINGS.BUTTON.UPLOAD}
              deleteBtnContainer={global.bgCandyRed}
              btnTextStyle={global.textRed}
              btnContainerStyle={global.bgLightGreen}
              isAsterik
            />
          </View>
          <View style={tw`mx-5`}>
            <InputTextField
              leftHeader={leftHeader(
                STRINGS.VENDOR_BILL.VENDOR_INVOICE_NUMBER,
                true,
              )}
              value={invoiceNumber}
              isDisabled={false}
              onTextChange={(value: string) => setInvoiceNumber(value)}
              placeholder={STRINGS.VENDOR_BILL.ENTER_INVOICE_NUMBER}
              style={tw`h-11 mt-2.5 justify-between items-center rounded-md`}
              placeholderTextColor={colors.darkGrey}
              innerStyle={[
                global.textMedium,
                global.textColorGrey,
                tw`text-sm ml-2.5 w-full`,
              ]}
              openKeypadOnTouch
              inputEditable
              containerStyle={tw`mt-5`}
            />
            <InputTextField
              leftHeader={leftHeader(
                STRINGS.VENDOR_BILL.VENDOR_INVOICE_DATE,
                true,
              )}
              value={invoiceDate}
              onClick={() => setModalType('calender')}
              style={tw`h-11 mt-2.5 justify-between items-center rounded-md`}
              innerStyle={[
                global.textColorGrey,
                global.textMedium,
                tw`text-sm ml-2.5 w-4/5`,
              ]}
              openKeypadOnTouch={false}
              placeholder={STRINGS.VENDOR_BILL.SELECT_INVOICE_DATE}
              placeholderTextColor={colors.darkGrey}
              inputEditable={false}
              rightIcon={
                <Image
                  source={require('images/calendar-orange.png')}
                  style={tw`mr-2.5 w-4 h-4`}
                />
              }
              containerStyle={tw`mt-5`}
            />
          </View>
          <View style={tw`flex-row justify-between mt-2.5 mx-5`}>
            <View style={tw`flex-row`}>
              <Text
                style={[global.textRegular, global.textColorGrey, tw`text-xs`]}
              >
                {STRINGS.VENDOR_BILL.PAYMENT_TERMS}
              </Text>
              <Text
                style={[global.textMedium, global.textColorGrey, tw`text-xs`]}
              >
                {poStore?.more_details?.payment_terms?.[1] || 'N/A'}
              </Text>
            </View>
            {poStore?.more_details?.payment_terms && (
              <View>
                <Text
                  style={[
                    global.textMedium,
                    invoiceDate ? global.textColorGrey : global.textDarkGrey,
                    tw`text-xs`,
                  ]}
                >
                  {(invoiceDate && paymentDate(invoiceDate)) || 'DD/MM/YYYY'}
                </Text>
              </View>
            )}
          </View>
          <View style={tw`mx-5 mb-7`}>
            <InputTextField
              leftHeader={leftHeader(STRINGS.VENDOR_BILL.COMMENTS, false)}
              value={comments}
              onTextChange={(value: string) => setComments(value)}
              style={tw`h-20 mt-2.5 rounded-md`}
              innerStyle={[
                global.textColorGrey,
                global.textMedium,
                tw`text-sm px-2.5 mt-1.5 w-full self-start`,
              ]}
              multiline
              placeholderTextColor={colors.darkGrey}
              placeholder={STRINGS.VENDOR_BILL.ADD_YOUR_COMMENTS}
              openKeypadOnTouch
              inputEditable
              containerStyle={tw`mt-10`}
            />
          </View>
        </ScrollView>
        <View
          style={[
            global.borderColorLightSecondaryGrey,
            tw`bg-white py-5 px-5 border-t`,
          ]}
        >
          {modalType === 'calender' && (
            <CalendarPicker
              startFromMonday
              onDateChange={(date: Moment) => onDateChange(date)}
              weekdays={['S', 'M', 'T', 'W', 'T', 'F', 'S']}
              selectedDayTextColor={colors.white}
              previousTitle={STRINGS.BUTTON.PREVIOUS}
              nextTitle={STRINGS.BUTTON.NEXT}
            />
          )}
          <Button
            title={STRINGS.BUTTON.VIEW_VENDOR_BILL_SUMMARY}
            style={tw`rounded-lg h-11`}
            onTap={goToNextScreen}
            disable={!invoiceNumber || !invoiceDate || !documentUploaded}
          />
        </View>
        {modalType === 'GRNS' && (
          <CardInfo
            header={STRINGS.VENDOR_BILL.SELECTED_GRN_DETAILS}
            bodyStyle={tw`mt-0 pb-12 h-full mx-0`}
            body={
              <ScrollView style={tw`px-5`}>
                {selectedGRN?.map((item: any, index: number) => (
                  <View key={item.id}>
                    <VoucherCard
                      header={
                        <View>
                          <VoucherMetadata
                            id={item?.id}
                            attributes={[
                              {
                                attrName: STRINGS.GRNS.GRN_ID,
                                attrValue: item.id,
                                icon: require('images/id.png'),
                                iconStyle: tw`w-7	h-7`,
                                attrContainerStyle: global.flexThree,
                                attrStyle: tw`text-xs`,
                                attrValueStyle: [tw`text-sm`, global.textBold],
                              },
                              {
                                attrName: STRINGS.GRNS.GRN_DATE_AND_TIME,
                                attrValue: `${utcToLocalTime(
                                  item?.scheduled_date || '',
                                  voucherDateFormat,
                                )}${
                                  item.state !== 'assigned'
                                    ? `-${utcToLocalTime(
                                        item?.scheduled_date || '',
                                        voucherTimeFormat,
                                      )}`
                                    : ''
                                }`,
                                icon: require('images/calendar.png'),
                                iconStyle: tw`w-7	h-7`,
                                attrContainerStyle: global.flexFour,
                                attrStyle: tw`text-xs`,
                                attrValueStyle: [tw`text-sm`, global.textBold],
                              },
                            ]}
                            containerStyle={tw`py-5 bg-white`}
                            lineSeparator={false}
                          />
                        </View>
                      }
                      body={
                        <>
                          <ItemCard
                            name={
                              item?.items[0] !== undefined &&
                              item.items[0]?.product_id
                                ? item.items[0]?.product_id[1].toString()
                                : 'N/A'
                            }
                            customUOM={`${
                              item?.items !== undefined &&
                              item?.items[0]?.product_uom
                                ? item?.items[0]?.product_uom[1]
                                : ''
                            }`}
                            tax={item?.items[0]?.tax_display_name || 'N/A'}
                            imageS3Key={item?.items[0]?.product_image}
                            errorQuantityMismatch={checkQuantityMismatch(item)}
                            detailColumns={[
                              {
                                key: STRINGS.GRNS.UNIT_PRICE,
                                value: `₹${
                                  item?.items !== undefined
                                    ? item?.items[0]?.price_unit
                                    : 0
                                }`,
                                keyStyle: global.textColorGrey,
                              },
                              {
                                key: STRINGS.GRNS.DEMAND,
                                value:
                                  item?.items !== undefined
                                    ? item?.items[0]?.product_uom_qty || 0
                                    : 0,
                                keyStyle: global.textColorGrey,
                              },
                            ]}
                            editableField={{
                              key: STRINGS.GRNS.RECEIVED,
                              value:
                                item?.items !== undefined
                                  ? item?.items[0]?.quantity_done || 0
                                  : 0,
                              keyStyle: [tw`pr-5`, global.textColorGrey],
                              valueContainerStyle: tw`mt-2.5 justify-start`,
                              valueStyle: global.textMedium,
                            }}
                            isTaxAmountVisible
                            hideReceivedCurrency
                            totalAmount={formatterWithoutDecimals.format(
                              item?.items[0]?.amount || 0,
                            )}
                            containerStyle={[
                              tw`pt-5 border-0 flex-1`,
                              index < selectedGRN.length - 1 &&
                                tw`border-b my-2.5`,
                              global.borderColorSmokeGrey,
                            ]}
                            totalAmountKeyStyle={global.textColorGrey}
                            taxValueStyle={global.textColorGrey}
                            errorMismatchMsg={
                              STRINGS.PURCHASE_ORDER.RECIEVED_QTY_MISMATCH
                            }
                            taxAmount={calculateTaxAmount(item) || '0'}
                          />
                        </>
                      }
                      cardStyle={tw`border-0`}
                    />
                  </View>
                ))}
              </ScrollView>
            }
            footer={
              <View
                style={[
                  global.borderColorDarkGrey,
                  tw`p-5 w-full border-t bg-white flex-row justify-between`,
                ]}
              >
                <View style={[style.footerBtn, tw`w-1/2 pr-2.5`]}>
                  <Button
                    title={STRINGS.VENDOR_BILL.CHANGE_SELECTION}
                    onTap={openModal}
                    style={tw`rounded-lg`}
                    textStyle={[
                      global.textBlack,
                      global.textPrimaryGreen,
                      tw`text-sm`,
                    ]}
                    btnType="secondary"
                  />
                </View>
                <View style={[style.footerBtn, tw`w-1/2 pl-2.5`]}>
                  <Button
                    title={STRINGS.VENDOR_BILL.OK}
                    onTap={() => setModalType('')}
                    style={tw`rounded-lg`}
                    textStyle={[global.textBlack, tw`text-sm text-white`]}
                    btnType="primary"
                  />
                </View>
              </View>
            }
            closeDrawer={() => setModalType('')}
            containerStyle={[tw`flex-1`, selectedGRN.length > 1 && tw`h-full`]}
          />
        )}
      </Box>
      {modalType === 'backPopup' && (
        <Modal
          body={modalBody}
          footer={modalFooter}
          close={() => setModalType('')}
          style={tw`p-0`}
        />
      )}
    </NativeBaseProvider>
  )
}

export default UpdateVendorBill
