import React from 'react'
import { View } from 'react-native'
import tw from 'tailwind-react-native-classnames'

// styles
import global from 'styles/global'
import style from './style'

interface ProgressBarProps {
  fillerWidth: string
  fillerColor: string
  fillerStyle?: object
  progressStyle?: object
}

const ProgressBar = ({
  fillerWidth,
  fillerColor,
  fillerStyle,
  progressStyle,
}: ProgressBarProps) => (
  <View
    style={[
      tw`relative border`,
      global.borderRadiusTen,
      style.progressBar,
      progressStyle,
    ]}
  >
    <View
      style={[
        tw`h-full`,
        { width: fillerWidth, backgroundColor: fillerColor },
        global.borderRadiusTen,
        fillerStyle,
      ]}
    />
  </View>
)

export default ProgressBar
