import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  card: {
    marginTop: 37,
  },
  biltyInput: {
    paddingVertical: 14,
    paddingHorizontal: 11,
  },
  footerBtn: {
    height: 45,
    maxHeight: 50,
  },
})

export default styles
