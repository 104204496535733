import { useRef, useState, useEffect } from 'react'
import { Platform } from 'react-native'
import * as Notifications from 'expo-notifications'
import Constants from 'expo-constants'

import { DOWNLOAD } from 'src/config/download'

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: false,
    shouldSetBadge: false,
  }),
})

const usePushNotificaton = () => {
  const [eventOnTapNotification, setEventOnTapNotification] =
    useState<Function>(() => () => {})
  const responseListener = useRef<any>()

  async function schedulePushNotification(
    onNotificationTap: Function,
    title?: string,
    body?: string,
  ) {
    await Notifications.scheduleNotificationAsync({
      content: {
        title,
        body,
        data: { data: null },
      },
      trigger: { seconds: 1 },
    })
    setEventOnTapNotification(onNotificationTap)
  }

  async function registerForPushNotificationsAsync() {
    let token
    if (Constants.isDevice) {
      const { status: existingStatus } =
        await Notifications.getPermissionsAsync()
      let finalStatus = existingStatus
      if (existingStatus !== DOWNLOAD.GRANTED) {
        const { status } = await Notifications.requestPermissionsAsync()
        finalStatus = status
      }
      if (finalStatus !== DOWNLOAD.GRANTED) {
        return undefined
      }
      token = (await Notifications.getExpoPushTokenAsync()).data
    }
    if (Platform.OS === DOWNLOAD.PLATFORM_ANDROID) {
      Notifications.setNotificationChannelAsync(DOWNLOAD.DEFAULT, {
        name: DOWNLOAD.DEFAULT,
        importance: Notifications.AndroidImportance.MAX,
        vibrationPattern: [0, 250, 250, 250],
      })
    }
    return token
  }

  useEffect(() => {
    registerForPushNotificationsAsync()
    responseListener.current =
      Notifications.addNotificationResponseReceivedListener(() =>
        eventOnTapNotification(),
      )
    return () => {
      Notifications.removeNotificationSubscription(responseListener.current)
    }
  }, [eventOnTapNotification])

  return { schedulePushNotification }
}

export default usePushNotificaton
