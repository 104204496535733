import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  tileButtonIcon: {
    width: 24,
    height: 24,
  },
  outstandingContainerStyle: {
    paddingTop: 43,
    paddingBottom: 44,
    borderRadius: 10,
  },
  cloudBackground: {
    top: 0,
    right: 0,
    width: 186,
    height: 80,
  },
  displayValueStyle: {
    marginTop: 16,
  },
})

export default styles
