import React, { useState, useEffect } from 'react'
import { View, Text, ScrollView, Image, Pressable } from 'react-native'

import { EditOrderProps } from 'src/navigations/types'
import STRINGS from 'constants/strings'
import { formatter } from 'src/utilities/helpers'
import { put, URL } from 'src/utilities/axios'
import { Item } from 'src/modals'

// components
import Button from 'src/components/form-fields/Button'
import ProductCard from 'src/components/product/ProductCard'

// styles
import global from 'styles/global'
import styles from './styles'

const EditOrder = ({ route, navigation }: EditOrderProps) => {
  const { items, orderId } = route.params
  const [itemList, setItemList] = useState<Item[]>(items)
  const [total, setTotal] = useState<number>(0)
  const [showDrawer, setDrawer] = useState<boolean>(false)
  const [variantIndex, setVariantIndex] = useState<number>(0)

  const getTotal = () => {
    let sum = 0
    itemList.forEach((item: Item) => {
      if (item.product_uom_qty && item.price_unit) {
        sum += item.product_uom_qty * item.price_unit
      }
    })
    setTotal(sum)
  }

  // TODO: Provide a different name to variantIndex parameter
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const changeQuantity = (variantIndex: number, qty: number) => {
    const copyOfItemList = [...itemList]
    const variant = itemList[variantIndex]
    variant.product_uom_qty = qty
    copyOfItemList[variantIndex] = variant
    getTotal()
    setItemList(copyOfItemList)
  }

  const confirm = () => {
    const list = itemList.map(item => ({
      product_id: item.id,
      product_uom_qty: item.product_uom_qty,
      price_unit: item.price_unit,
      quality_request: item.quality_request,
    }))
    const obj = {
      id: orderId,
      op: 'update',
      items: list,
    }
    put(URL.ORDERS, obj).then((res: { data: { success: boolean } }) => {
      if (res.data.success) {
        navigation.navigate('SuccessScreen', {
          iconImg: (
            <Image
              source={require('images/success-icon.png')}
              style={styles.successIcon}
            />
          ),
          successMsg: STRINGS.SUCCESS.ORDER_UPDATE,
        })
      }
    })
  }

  const onDelete = (index: number) => {
    if (itemList.length === 1) {
      return
    }
    const copyOfItemList = [...itemList]
    copyOfItemList.splice(index, 1)
    setItemList(copyOfItemList)
  }

  const onEdit = (index: number) => {
    navigation.navigate('ProductDetailsEdit', {
      index,
      items,
      orderId,
    })
  }

  useEffect(() => {
    getTotal()
    // TODO: Provide an explanation for why all deps are not included or fix it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemList])

  useEffect(() => {
    setItemList(items)
  }, [items])

  const DrawerContent = () => (
    <View style={global.drawer}>
      <View style={global.alignItemsRight}>
        <Pressable
          onPress={() => setDrawer(false)}
          style={global.paddingSixteen}
        >
          <Image
            source={require('images/cancel.png')}
            style={[styles.iconCancel]}
          />
        </Pressable>
      </View>
      <Pressable
        onPress={() => {
          setDrawer(false)
          onEdit(variantIndex)
        }}
        style={[global.widthFull]}
      >
        <Text
          style={[
            global.fontFourteen,
            global.textRegular,
            global.textColorGrey,
            global.textAlignCenter,
            global.paddingSixteen,
            styles.drawerButtonBorder,
          ]}
        >
          {STRINGS.BUTTON.EDIT}
        </Text>
      </Pressable>
      <Pressable
        onPress={() => {
          setDrawer(false)
          onDelete(variantIndex)
        }}
        style={[global.widthFull]}
      >
        <Text
          style={[
            global.fontFourteen,
            global.textRegular,
            global.textColorGrey,
            global.textAlignCenter,
            global.paddingSixteen,
            styles.drawerButtonBorder,
          ]}
        >
          {STRINGS.BUTTON.DELETE}
        </Text>
      </Pressable>
      <Pressable onPress={() => setDrawer(false)}>
        <Text
          style={[
            global.fontFourteen,
            global.textRegular,
            global.textColorGrey,
            global.textAlignCenter,
            global.paddingSixteen,
          ]}
        >
          {STRINGS.BUTTON.CANCEL}
        </Text>
      </Pressable>
    </View>
  )

  return (
    <View style={global.flexOne}>
      <View
        style={[
          global.paddingTwenty,
          global.directionRow,
          global.justifyContentSpaceBetween,
          showDrawer && global.drawerBackground,
        ]}
      >
        <Text style={[global.fontFourteen, global.textRegular]}>
          {`${itemList.length} ${STRINGS.MISCELLANEOUS.PRODUCTS}`}
        </Text>
        <Text
          style={[
            global.fontFourteen,
            global.textBlack,
            global.textSecondaryBlue,
          ]}
        >
          {`${STRINGS.MISCELLANEOUS.TOTAL}: ${formatter.format(total)}`}
        </Text>
      </View>
      <ScrollView style={[showDrawer && global.drawerBackground]}>
        {itemList.map((item: Item, index: number) => (
          <View key={item.id}>
            <ProductCard
              data={item}
              navigation={navigation}
              index={index}
              changeQuantity={(qty: number) => changeQuantity(index, qty)}
              showQuantity
              showDrawer={() => {
                setVariantIndex(index)
                setDrawer(true)
              }}
              quantity={item.product_uom_qty}
              unitPrice={item.price_unit}
            />
          </View>
        ))}
      </ScrollView>
      <View
        style={[
          global.directionRow,
          global.justifyContentSpaceBetween,
          global.bgWhite,
          global.hPaddingTwenty,
          global.widthFull,
          styles.footer,
          showDrawer && global.drawerBackground,
        ]}
      >
        <View style={[styles.footerBtn, global.justifyContentCenter]}>
          <Text
            style={[
              global.fontTwenty,
              global.textBlack,
              global.textPrimaryBlack,
            ]}
          >
            {formatter.format(total)}
          </Text>
        </View>
        <View style={[styles.footerBtn]}>
          <Button
            title={STRINGS.BUTTON.UPDATE_ORDER}
            onTap={() => confirm()}
            style={[global.borderRadiusEight, global.hPaddingTwenty]}
          />
        </View>
      </View>
      {showDrawer && <DrawerContent />}
    </View>
  )
}

export default EditOrder
