import React, { useState } from 'react'
import { View } from 'react-native'
import { Picker } from '@react-native-picker/picker'

// styles
import global from 'styles/global'
import colors from 'styles/colors'

interface Option {
  label: string
  value: string
}

interface DropdownProps {
  options: Option[]
  onChange: Function
  selectedOption?: string | number
  disabled?: boolean
  style?: object
  outerStyle?: object
}

const Dropdown = ({
  options,
  onChange,
  selectedOption,
  disabled,
  style,
  outerStyle,
}: DropdownProps) => {
  const [selected, setSelected] = useState<string | number>(
    selectedOption || options[0].value,
  )

  const onChangeValue = (option: string | number) => {
    setSelected(option)
    onChange(option)
  }

  return (
    <View
      style={[
        global.vPaddingSixteen,
        global.hPaddingTen,
        global.borderRadiusEight,
        global.bgWhite,
        global.borderColorGrey,
        global.borderWidthOne,
        outerStyle,
      ]}
    >
      <Picker
        selectedValue={selected}
        onValueChange={onChangeValue}
        itemStyle={[global.textRegular, global.textColorGrey, style]}
        dropdownIconColor={colors.secondaryOrange}
        enabled={options.length > 0 && !disabled}
        style={[global.textColorGrey, global.fontFourteen, style]}
      >
        {options.map((option: Option) => {
          return (
            <Picker.Item
              label={option.label}
              value={option.value}
              key={option.value}
            />
          )
        })}
      </Picker>
    </View>
  )
}

Dropdown.defaultProps = {
  disabled: false,
}

export default Dropdown
