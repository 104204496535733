import React, { useEffect, useState } from 'react'
import {
  View,
  SafeAreaView,
  Image,
  Text,
  PermissionsAndroid,
  Platform,
  ActivityIndicator,
} from 'react-native'
import tw from 'tailwind-react-native-classnames'
import * as WebBrowser from 'expo-web-browser'

import STRINGS from 'constants/strings'
import { useKeycloak } from 'src/utilities/keycloak'

// components
import Button from 'src/components/form-fields/Button'

// styles
import global from 'styles/global'
import colors from 'styles/colors'
import styles from './style'

const Login = () => {
  const { login, ready } = useKeycloak()
  const [loader, setLoader] = useState(false)

  if (Platform.OS === 'web') {
    WebBrowser.maybeCompleteAuthSession()
  }

  const requestPermissions = async () => {
    try {
      await PermissionsAndroid.requestMultiple([
        PermissionsAndroid.PERMISSIONS.READ_SMS,
        PermissionsAndroid.PERMISSIONS.CAMERA,
        PermissionsAndroid.PERMISSIONS.READ_EXTERNAL_STORAGE,
        PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE,
      ])
    } catch (err) {
      console.warn(err)
    }
  }

  useEffect(() => {
    requestPermissions()
  }, [])

  const goLogin = () => {
    setLoader(true)
    login()
  }

  return (
    <View style={[global.bgWhite, global.paddingTwenty, tw`flex-1`]}>
      <Image source={require('images/logo.png')} style={[styles.logo]} />
      <View style={styles.titleContainer}>
        <Text style={[global.textMedium, styles.name]}>
          {STRINGS.LOGIN.WELCOME}
        </Text>
      </View>
      <SafeAreaView style={styles.inputContainer}>
        <View style={[styles.loginBtn]}>
          {!ready ? (
            <ActivityIndicator color={colors.primaryGreen} />
          ) : (
            <Button
              title={STRINGS.LOGIN.LOGIN_VIA_KEYCLOAK}
              onTap={goLogin}
              style={global.borderRadiusEight}
              loader={loader}
              disable={loader}
            />
          )}
        </View>
      </SafeAreaView>
    </View>
  )
}

export default Login
