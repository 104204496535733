import React from 'react'
import { View, Text, BackHandler } from 'react-native'
import { useFocusEffect } from '@react-navigation/native'

import STRINGS from 'constants/strings'
import { SuccessScreenProps } from 'src/navigations/types'
import DownloadDoc from 'src/components/drawers/DownloadDoc'
import useSetDownloadButton from 'src/utilities/hooks/useSetDownloadConfig'
import { IconButton } from 'types/success'

// components
import Button from 'src/components/form-fields/Button'
import ToastNotification from 'src/components/ToastNotification'

// styles
import global from 'styles/global'
import styles from './style'

const SuccessScreen = ({ route, navigation }: SuccessScreenProps) => {
  const {
    id,
    successMsg,
    iconImg,
    greeting = true,
    idFields,
    footerButtons,
    customMsg,
    customFooterConfig,
    homeButtonStyle,
    onHomeButton,
    toastSuccessContainerStyle,
    homeButtonTextStyle,
    buttonContainerStyle,
  } = route?.params
  const { downloadButtonConfig, downloadDrawerConfig } = useSetDownloadButton(
    customFooterConfig || {},
  )
  const customFooter = customFooterConfig ? downloadButtonConfig : footerButtons

  const onPhoneBackButton = () => {
    navigation.navigate('Home')
    return true
  }

  useFocusEffect(() => {
    BackHandler.addEventListener('hardwareBackPress', onPhoneBackButton)
    return () =>
      BackHandler.removeEventListener('hardwareBackPress', onPhoneBackButton)
  })

  return (
    <>
      <ToastNotification successContainerStyle={toastSuccessContainerStyle} />
      <View
        style={[
          global.flexOne,
          global.bgSmokeGrey,
          global.alignItemsCenter,
          global.justifyContentCenter,
          customFooterConfig &&
            downloadDrawerConfig.isDrawerOpen &&
            global.drawerBackground,
        ]}
      >
        <View
          style={[
            global.flexNine,
            global.alignItemsCenter,
            global.justifyContentCenter,
          ]}
        >
          {iconImg}
          {greeting && (
            <Text
              style={[
                global.fontTwentyFour,
                global.textBold,
                global.textPrimaryBlack,
              ]}
            >
              {STRINGS.SUCCESS.CONGRATULATION}
            </Text>
          )}
          {customMsg || (
            <Text
              style={[
                global.fontFourteen,
                global.textRegular,
                global.textPrimaryBlack,
                global.paddingTwenty,
                global.textAlignCenter,
              ]}
            >
              {successMsg}
            </Text>
          )}
          {id && (
            <View style={[global.directionRow]}>
              <Text style={[global.fontTwelve, global.textRegular]}>
                {STRINGS.MISCELLANEOUS.ORDER_ID}
              </Text>
              <Text
                style={[global.fontTwelve, global.textRegular, global.textBold]}
              >
                {id}
              </Text>
            </View>
          )}
          {idFields?.length &&
            idFields.map(field => (
              <View
                key={field.value}
                style={[
                  global.directionRow,
                  global.vPaddingFive,
                  styles.idField,
                ]}
              >
                <Text style={[global.fontTwelve, global.textRegular]}>
                  {field.title}
                </Text>
                <Text
                  style={[
                    global.fontTwelve,
                    global.textRegular,
                    global.textBold,
                  ]}
                >
                  {field.value}
                </Text>
              </View>
            ))}
        </View>
        <View
          style={[
            global.vMarginSixteen,
            global.justifyContentBottom,
            global.widthNinety,
          ]}
        >
          <View
            style={[
              global.flexWrap,
              global.directionRow,
              global.justifyContentSpaceBetween,
              buttonContainerStyle,
            ]}
          >
            {customFooter &&
              customFooter?.length > 0 &&
              customFooter?.map((button: IconButton, index: number) => (
                <View
                  // TODO: Need to provide reason for why index key or else fix it
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  style={
                    index === customFooter.length - 1 &&
                    (index === 0 || index % 2 === 0)
                      ? [global.widthFull, styles.iconButton, button?.style]
                      : [
                          global.widthFortyFive,
                          styles.iconButton,
                          button?.style,
                        ]
                  }
                >
                  <Button
                    title={button.title}
                    onTap={button.onClick}
                    style={[
                      global.directionRow,
                      global.justifyContentCenter,
                      global.alignItemsCenter,
                      global.borderRadiusFive,
                      styles.iconButtonMain,
                      button.buttonStyle,
                      button?.bgColor && { backgroundColor: button.bgColor },
                    ]}
                    iconPath={button?.iconPath}
                    textStyle={[
                      global.fontFourteen,
                      global.marginLeftFour,
                      button.textStyle,
                    ]}
                  />
                </View>
              ))}
          </View>
          <View style={styles.homeBtn}>
            <Button
              title={STRINGS.BUTTON.GO_TO_HOME}
              onTap={() => {
                onHomeButton?.()
                navigation.navigate('Home')
              }}
              style={[global.borderRadiusFour, homeButtonStyle]}
              btnType="secondary"
              textStyle={homeButtonTextStyle}
            />
          </View>
        </View>
      </View>
      {customFooterConfig && downloadDrawerConfig.isDrawerOpen && (
        <DownloadDoc
          closeDrawer={downloadDrawerConfig?.close}
          docs={downloadDrawerConfig.drawerDoc}
          successMsg={downloadDrawerConfig.successMsg}
        />
      )}
    </>
  )
}

export default SuccessScreen
