import React from 'react'
import { View, Text, Image } from 'react-native'

import STRINGS from 'constants/strings'

// components
import S3Image from 'src/components/S3Image'

// style
import global from 'styles/global'
import style from './style'

interface ProfileBarProps {
  imageKey: string
  name: string
  designation?: string
  mobile?: number | string | boolean
  containerStyle?: object
}

const ProfileBar = ({
  imageKey,
  name,
  designation,
  mobile,
  containerStyle,
}: ProfileBarProps) => (
  <View style={[global.paddingSixteen, global.directionRow, containerStyle]}>
    <S3Image imageKey={imageKey!} style={style.profileIcon} />
    <View style={global.hPaddingTen}>
      <View style={global.directionRow}>
        <Text
          style={[
            global.textMedium,
            global.fontFourteen,
            global.textPrimaryBlack,
          ]}
        >
          {name || STRINGS.MISCELLANEOUS.NOT_AVAILABLE}
        </Text>
        <Text
          style={[
            global.hPaddingFive,
            global.textRegular,
            global.fontTwelve,
            global.textPrimaryBlack,
            style.paddingTopTwo,
          ]}
        >
          {mobile === undefined ? null : designation}
        </Text>
      </View>
      <View>
        {mobile === undefined ? (
          <Text
            style={[
              global.textRegular,
              global.fontTwelve,
              global.textPrimaryBlack,
              style.paddingTopTwo,
            ]}
          >
            {designation}
          </Text>
        ) : (
          <View>
            {mobile === false ? (
              <Text
                style={[
                  global.fontTwelve,
                  global.textRegular,
                  global.textSecondaryBlue,
                ]}
              >
                {STRINGS.MISCELLANEOUS.NOT_AVAILABLE}
              </Text>
            ) : (
              <View
                style={[
                  global.directionRow,
                  global.alignItemsCenter,
                  style.paddingTopTwo,
                ]}
              >
                <Image
                  style={style.phoneIcon}
                  source={require('images/mobile.png')}
                />
                <Text
                  style={[
                    global.fontTwelve,
                    global.textRegular,
                    global.textSecondaryBlue,
                    global.hPaddingFive,
                  ]}
                >
                  {mobile}
                </Text>
              </View>
            )}
          </View>
        )}
      </View>
    </View>
  </View>
)

export default ProfileBar
