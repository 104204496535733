import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Text, View, Image } from 'react-native'
import { NativeBaseProvider, Checkbox } from 'native-base'
import tw from 'tailwind-react-native-classnames'
import RadioForm, {
  RadioButton,
  RadioButtonInput,
  RadioButtonLabel,
} from 'react-native-simple-radio-button'
import { ScrollView } from 'react-native-gesture-handler'
import { AxiosError, AxiosResponse } from 'axios'

import STRINGS from 'constants/strings'
import { ApplicationState } from 'src/redux/types'
import { setAddVendorField } from 'src/redux/actions'
import { get, URL } from 'src/utilities/axios'
import {
  isEmailValid,
  isPhoneNumberValid,
  showToastNotification,
} from 'src/utilities/helpers'
import { DOWNLOAD } from 'src/config/download'
import {
  AddNewVendorTabProps,
  NodeProps,
  VendorTypeProps,
} from 'types/procurement'

// components
import Button from 'src/components/form-fields/Button'
import { SearchOptions } from 'src/components/drawers'
import { SearchOptionsProps } from 'src/components/drawers/SearchOptions'
import InputTextField from 'src/components/form-fields/InputTextField'
import ToastNotification from 'src/components/ToastNotification'

// styles
import global from 'styles/global'
import colors from 'styles/colors'

const { ADD_VENDOR } = STRINGS

const vendorGSTRegisteredOptions = [
  {
    label: STRINGS.MISCELLANEOUS.YES,
    value: true,
  },
  {
    label: STRINGS.MISCELLANEOUS.NO,
    value: false,
  },
]

const VendorTab = ({
  goToNextTab,
  fromPreview,
  navigation,
}: AddNewVendorTabProps) => {
  const dispatch = useDispatch()
  const [isDrawerOpen, setDrawerOpen] = useState<boolean>(false)
  const [vendorTypeOptions, setVendorTypeOptions] = useState<
    Array<VendorTypeProps>
  >([])
  const [nodeList, setNodeList] = useState<Array<NodeProps>>([])
  const [searchOptionsProps, setSearchOptionsProps] = useState<any>({})

  const [contactError, setContactError] = useState<string>()
  const [emailError, setEmailError] = useState<string>()

  const { hasFresh, hasDry, isGSTRegistered, vendor, node } = useSelector(
    (state: ApplicationState) => state.vendor.addVendor,
  )

  useEffect(() => {
    get(URL.VENDOR_TYPES)
      .then((res: AxiosResponse) => {
        setVendorTypeOptions(
          res?.data?.map((name: string) => ({
            name,
          })),
        )
      })
      .catch((err: AxiosError) => {
        showToastNotification(DOWNLOAD.ERROR, err.response?.data.error)
      })
    get(URL.NODE_LIST)
      .then((res: AxiosResponse) => {
        setNodeList(res?.data)
      })
      .catch((err: AxiosError) => {
        showToastNotification(DOWNLOAD.ERROR, err.response?.data.error)
      })
  }, [])

  const toggleHasFreshBusiness = () =>
    dispatch(setAddVendorField('hasFresh', !hasFresh))

  const toggleHasDryBusiness = () =>
    dispatch(setAddVendorField('hasDry', !hasDry))

  const setIsGSTRegistered = (_isGSTRegistered: boolean) =>
    dispatch(setAddVendorField('isGSTRegistered', _isGSTRegistered))

  const onDropdownClick = (drawerProps: SearchOptionsProps) => {
    setDrawerOpen(true)
    setSearchOptionsProps(drawerProps)
  }

  const onContactNumberChange = (_mobileNumber: string) => {
    setContactError(undefined)
    dispatch(
      setAddVendorField('vendor', { ...vendor, mobileNumber: _mobileNumber }),
    )
  }

  const onEmailChange = (_email: string) => {
    setEmailError(undefined)
    dispatch(setAddVendorField('vendor', { ...vendor, email: _email }))
  }

  const onSelectVendorTypeDrawerClose = (
    selectedVendorType: VendorTypeProps,
  ) => {
    setDrawerOpen(false)
    dispatch(
      setAddVendorField('vendor', { ...vendor, type: selectedVendorType.name }),
    )
  }

  const onSelectNodeDrawerClose = (selectedNode: NodeProps) => {
    setDrawerOpen(false)
    dispatch(setAddVendorField('node', selectedNode))
  }

  const getImage = (disabled: boolean) => (
    <Image
      source={
        disabled
          ? require('images/arrow-down-disabled.png')
          : require('images/arrow-down-enabled.png')
      }
      style={tw`mr-2.5 w-2.5 h-1.5`}
    />
  )

  const isNextButtonDisabled = () =>
    !(
      (hasDry || hasFresh) &&
      isGSTRegistered !== null &&
      vendor?.type &&
      node?.id &&
      vendor?.mobileNumber
    )

  const onTapNext = () => {
    let hasError: boolean = false
    if (!isPhoneNumberValid(vendor?.mobileNumber!)) {
      setContactError(STRINGS.MISCELLANEOUS.CONTACT_ERROR)
      hasError = true
    }
    if (vendor?.email && !isEmailValid(vendor?.email)) {
      setEmailError(STRINGS.MISCELLANEOUS.EMAIL_ERROR)
      hasError = true
    }
    if (!hasError) goToNextTab()
  }

  const selectVendorTypeProps = {
    title: ADD_VENDOR.SELECT_VENDOR_TYPE,
    searchPlaceholder: ADD_VENDOR.SEARCH_BY_VENDOR_TYPE,
    onLoadValue: vendor,
    closeDrawer: onSelectVendorTypeDrawerClose,
    keyDisplayName: 'name',
    keyObjUnique: 'name',
    searchKeys: 'name',
    localItemList: vendorTypeOptions,
  }

  const selectNodeProps = {
    title: ADD_VENDOR.SELECT_NODE,
    searchPlaceholder: ADD_VENDOR.SEARCH_BY_NODE_NAME,
    onLoadValue: node,
    closeDrawer: onSelectNodeDrawerClose,
    keyDisplayName: 'name',
    searchKeys: 'name',
    localItemList: nodeList,
  }

  return (
    <View style={tw`h-0 flex-1`}>
      <View style={[tw`h-0 flex-1`, isDrawerOpen && global.drawerBackground]}>
        <ToastNotification />
        <ScrollView style={tw`w-full`}>
          <View style={tw`m-5`}>
            <View style={tw`flex-row`}>
              <Text style={[global.textColorGrey, tw`text-xs font-normal`]}>
                {ADD_VENDOR.SELECT_BUSINESS_TYPE}
              </Text>
              <Text style={global.textRed}>*</Text>
            </View>
            <NativeBaseProvider>
              <View style={tw`mt-3 flex-row`}>
                <View style={tw`flex-1`}>
                  <Checkbox
                    size="sm"
                    colorScheme="green"
                    value={ADD_VENDOR.FRESH_BUSINESS}
                    accessibilityLabel={ADD_VENDOR.FRESH_BUSINESS}
                    onChange={toggleHasFreshBusiness}
                    isChecked={hasFresh}
                    style={tw`flex-1`}
                  >
                    <Text
                      style={[
                        global.textColorGrey,
                        tw`text-sm font-medium ml-1.5`,
                      ]}
                    >
                      {ADD_VENDOR.FRESH_BUSINESS}
                    </Text>
                  </Checkbox>
                </View>
                <View style={tw`flex-1`}>
                  <Checkbox
                    size="sm"
                    colorScheme="green"
                    value={ADD_VENDOR.DRY_BUSINESS}
                    accessibilityLabel={ADD_VENDOR.DRY_BUSINESS}
                    onChange={toggleHasDryBusiness}
                    isChecked={hasDry}
                    style={tw`flex-1`}
                  >
                    <Text
                      style={[
                        global.textColorGrey,
                        tw`text-sm font-medium ml-1.5`,
                      ]}
                    >
                      {ADD_VENDOR.DRY_BUSINESS}
                    </Text>
                  </Checkbox>
                </View>
              </View>
            </NativeBaseProvider>
          </View>
          <View style={tw`mx-5`}>
            <View style={tw`flex-row`}>
              <Text style={[global.textColorGrey, tw`text-xs font-normal`]}>
                {ADD_VENDOR.IS_GST_REGISTERED}
              </Text>
              <Text style={global.textRed}>*</Text>
            </View>
            <View style={tw`mt-3`}>
              <RadioForm formHorizontal>
                <View style={tw`flex-row`}>
                  {vendorGSTRegisteredOptions.map((obj, index) => (
                    <RadioButton labelHorizontal key={obj.label}>
                      <RadioButtonInput
                        obj={obj}
                        index={index}
                        isSelected={obj.value === isGSTRegistered}
                        buttonInnerColor={colors.primaryGreen}
                        buttonOuterColor={colors.primaryGreen}
                        buttonSize={8.3}
                        onPress={() => setIsGSTRegistered(obj.value)}
                        buttonOuterSize={16.67}
                        buttonStyle={tw`mr-2`}
                      />
                      <RadioButtonLabel
                        obj={obj}
                        index={index}
                        labelHorizontal
                        onPress={() => setIsGSTRegistered(obj.value)}
                        labelStyle={[
                          global.textColorGrey,
                          tw`text-sm font-medium pl-0 -mt-0.5`,
                        ]}
                        labelWrapStyle={tw`mr-14`}
                      />
                    </RadioButton>
                  ))}
                </View>
              </RadioForm>
            </View>
          </View>
          <View style={tw`m-4`}>
            <View style={tw`flex-row`}>
              <Text style={[global.textColorGrey, tw`text-xs font-normal`]}>
                {ADD_VENDOR.VENDOR_TYPE}
              </Text>
              <Text style={global.textRed}>*</Text>
            </View>
            <View style={tw`mt-2`}>
              <InputTextField
                value={vendor?.type || ''}
                isDisabled={false}
                onClick={() => onDropdownClick(selectVendorTypeProps)}
                placeholder={ADD_VENDOR.SELECT_VENDOR_TYPE}
                rightIcon={getImage(false)}
                style={tw`h-11 justify-between items-center rounded-md`}
                placeholderTextColor={colors.darkGrey}
                innerStyle={[
                  global.textRegular,
                  global.textColorGrey,
                  tw`text-sm ml-2.5`,
                  vendor?.type && global.textMedium,
                ]}
                openKeypadOnTouch={false}
                inputEditable={false}
              />
            </View>
          </View>
          <View style={tw`m-4`}>
            <View style={tw`flex-row`}>
              <Text style={[global.textColorGrey, tw`text-xs font-normal`]}>
                {ADD_VENDOR.NODE}
              </Text>
              <Text style={global.textRed}>*</Text>
            </View>
            <View style={tw`mt-2`}>
              <InputTextField
                value={node?.name || ''}
                isDisabled={false}
                onClick={() => onDropdownClick(selectNodeProps)}
                placeholder={ADD_VENDOR.SELECT_NODE}
                rightIcon={getImage(false)}
                style={tw`h-11 justify-between items-center rounded-md`}
                placeholderTextColor={colors.darkGrey}
                innerStyle={[
                  global.textRegular,
                  global.textColorGrey,
                  tw`text-sm ml-2.5`,
                  node?.name && global.textMedium,
                ]}
                openKeypadOnTouch={false}
                inputEditable={false}
              />
            </View>
          </View>
          <View style={tw`m-4`}>
            <View style={tw`flex-row`}>
              <Text style={[global.textColorGrey, tw`text-xs font-normal`]}>
                {ADD_VENDOR.VENDOR_CONTACT_NUMBER}
              </Text>
              <Text style={global.textRed}>*</Text>
            </View>
            <View style={tw`mt-2`}>
              <InputTextField
                value={vendor?.mobileNumber || ''}
                onTextChange={onContactNumberChange}
                placeholder={ADD_VENDOR.CONTACT_PLACEHOLDER}
                placeholderTextColor={colors.darkGrey}
                style={[
                  tw`h-11 rounded-md`,
                  contactError && global.borderColorRed,
                ]}
                innerStyle={[
                  global.textRegular,
                  global.textColorGrey,
                  tw`text-sm ml-2.5`,
                  vendor?.mobileNumber && global.textMedium,
                ]}
                keyboardType="numeric"
                errorMessage={contactError}
                errorStyle={[global.textRed, tw`text-xs`]}
              />
            </View>
          </View>
          <View style={tw`m-4`}>
            <Text style={[global.textColorGrey, tw`text-xs font-normal`]}>
              {ADD_VENDOR.VENDOR_EMAIL}
            </Text>
            <View style={tw`mt-2`}>
              <InputTextField
                value={vendor?.email || ''}
                onTextChange={onEmailChange}
                placeholder={ADD_VENDOR.EMAIL_PLACEHOLDER}
                placeholderTextColor={colors.darkGrey}
                style={[
                  tw`h-11 rounded-md`,
                  emailError && global.borderColorRed,
                ]}
                innerStyle={[
                  global.textRegular,
                  global.textColorGrey,
                  tw`text-sm ml-2.5`,
                  vendor?.email && global.textMedium,
                ]}
                inputEditable
                keyboardType="email-address"
                errorMessage={emailError}
                errorStyle={[global.textRed, tw`text-xs`]}
              />
            </View>
          </View>
        </ScrollView>
      </View>
      <View
        style={[global.borderColorLightSecondaryGrey, tw`bg-white py-5 px-5`]}
      >
        <Button
          title={fromPreview ? STRINGS.BUTTON.PREVIEW : STRINGS.BUTTON.NEXT}
          style={[tw`rounded-lg`, global.buttonHeight]}
          disable={isNextButtonDisabled()}
          onTap={() =>
            fromPreview ? navigation.navigate('Home') : onTapNext()
          }
        />
      </View>
      {isDrawerOpen && (
        <SearchOptions
          title={searchOptionsProps.title}
          searchPlaceholder={searchOptionsProps.searchPlaceholder}
          onLoadValue={searchOptionsProps.onLoadValue}
          closeDrawer={searchOptionsProps.closeDrawer}
          keyDisplayName={searchOptionsProps.keyDisplayName}
          keyObjUnique={searchOptionsProps.keyObjUnique}
          searchKeys={searchOptionsProps.searchKeys}
          itemListOnLoad={searchOptionsProps.itemListOnLoad}
          apiToLoadOption={searchOptionsProps.apiToLoadOption}
          hideSearchBoxItemLimit={searchOptionsProps.hideSearchBoxItemLimit}
          localItemList={searchOptionsProps.localItemList}
        />
      )}
    </View>
  )
}

export default VendorTab
