import { Dispatch } from 'react'

import { get, URL } from 'src/utilities/axios'
import { InvoiceActions } from 'src/redux/types'
import { InvoiceListResponse, InvoiceResponse } from 'src/modals'

const fetchInvoicesByDVId =
  (id: number) => async (dispatch: Dispatch<InvoiceActions>) => {
    try {
      const res: InvoiceListResponse = await get(URL.INVOICE_LIST, {
        dv_id: id,
      })
      if (res.error === null) {
        const { data } = res
        dispatch({
          type: 'SET_INVOICE_LIST',
          payload: data.items,
        })
      }
    } catch (error) {
      dispatch({
        type: 'SET_INVOICE_LIST_ERROR',
        payload: String(error),
      })
    }
  }

const fetchInvoiceById =
  (id: number) => async (dispatch: Dispatch<InvoiceActions>) => {
    try {
      const res: InvoiceResponse = await get(`${URL.INVOICE_LIST}/${id}`)
      if (res.error === null) {
        const { data } = res
        for (let i = 0; i < data.items!.length; i++) {
          data.items![i].initial_price = data.items![i].price_unit
        }
        dispatch({
          type: 'SET_INVOICE',
          payload: data,
        })
      }
    } catch (error) {
      dispatch({
        type: 'SET_INVOICE_ERROR',
        payload: String(error),
      })
    }
  }

export { fetchInvoicesByDVId, fetchInvoiceById }
