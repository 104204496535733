import React, { ReactChild } from 'react'
import { View, Text } from 'react-native'

// styles
import global from 'styles/global'
import tw from 'tailwind-react-native-classnames'

interface AttributeProps {
  icon?: ReactChild
  attrName: string
  attrValue?: any
  attrStyle?: object
  styles?: object
  attrValueStyle?: object
}

const Attribute = ({
  icon,
  attrName,
  attrValue,
  attrStyle,
  attrValueStyle,
  styles,
}: AttributeProps) => (
  <View style={[global.directionRow, styles]}>
    <View style={global.justifyContentCenter}>{icon}</View>
    <View style={tw`mx-2 flex-shrink`}>
      <View>
        <Text
          style={[
            global.fontTen,
            global.textRegular,
            global.textColorGrey,
            attrStyle,
          ]}
        >
          {attrName}
        </Text>
      </View>
      <View>
        <Text
          style={[
            global.fontTwelve,
            global.textMedium,
            global.textSecondaryBlue,
            attrStyle,
            attrValueStyle,
          ]}
        >
          {attrValue}
        </Text>
      </View>
    </View>
  </View>
)

export default Attribute
