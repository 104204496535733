import { StyleSheet, Dimensions, Platform } from 'react-native'

import colors from 'styles/colors'

const windowWidth = Dimensions.get('window').width

const style = StyleSheet.create({
  itemLine: {
    borderTopColor: colors.bgLightBlue,
  },
  itemThumbnail: {
    height: 67.26,
    width: 67,
  },
  marginLeftFive: {
    marginLeft: 5,
  },
  itemDetails: {
    width: windowWidth - 106,
  },
  alignUnit: {
    marginLeft: -50,
    marginTop: 5,
  },
  priceInput: {
    width: 70,
    height: 30,
    borderColor: colors.secondaryTextGrey,
    ...Platform.select({
      ios: {
        marginTop: 4,
      },
      android: {
        marginTop: 4,
      },
      default: {
        marginTop: 0,
      },
    }),
  },
  lineHeightFourteen: {
    lineHeight: 14,
  },
})

export default style
