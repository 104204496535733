import { config } from 'aws-sdk'
import { APP_ACCESS_KEY_ID, APP_SECRET_ACCESS_KEY } from 'react-native-dotenv'

import { AWS_APP_REGION } from 'constants/app'

const AWSConfig = {
  accessKeyId: APP_ACCESS_KEY_ID,
  secretAccessKey: APP_SECRET_ACCESS_KEY,
  region: AWS_APP_REGION,
}

config.update(AWSConfig)
