import { S3 } from 'aws-sdk'
import { APP_AWS_BUCKET } from 'react-native-dotenv'

import { AWS_API_VERSION, AWS_APP_REGION } from 'constants/app'

class S3Singleton {
  static instance: any = undefined

  static async getInstance() {
    if (S3Singleton.instance) {
      return S3Singleton.instance
    }
    S3Singleton.instance = await S3Singleton.createInstance()
    return S3Singleton.instance
  }

  static createInstance = async () => {
    return new S3({
      apiVersion: AWS_API_VERSION,
      region: AWS_APP_REGION,
      params: { Bucket: APP_AWS_BUCKET },
    })
  }
}

export default S3Singleton
