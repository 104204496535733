import React from 'react'
import {
  Pressable,
  Image,
  Text,
  GestureResponderEvent,
  ImageSourcePropType,
} from 'react-native'
import tw from 'tailwind-react-native-classnames'

// style
import global from 'styles/global'

interface ButtonWithIconProps {
  text: string
  containerStyle?: object
  icon: ImageSourcePropType
  onClick: (event: GestureResponderEvent) => void
  textStyle?: object
  disable?: boolean
  imageStyle?: object
  imageAfterText?: boolean
}

const ButtonWithIcon = ({
  text,
  icon,
  onClick,
  containerStyle,
  textStyle,
  disable = false,
  imageStyle,
  imageAfterText,
}: ButtonWithIconProps) => (
  <Pressable
    style={[
      global.directionRow,
      global.paddingTen,
      global.borderRadiusFive,
      global.alignItemsCenter,
      containerStyle,
    ]}
    disabled={disable}
    onPress={onClick}
  >
    {!imageAfterText && (
      <Image source={icon} style={[tw`w-4 h-4`, imageStyle]} />
    )}
    <Text
      style={[
        global.textDecorationLine,
        global.fontTwelve,
        global.textMedium,
        textStyle,
      ]}
    >
      {text}
    </Text>
    {imageAfterText && (
      <Image source={icon} style={[tw`w-4 h-4`, imageStyle]} />
    )}
  </Pressable>
)

export default ButtonWithIcon
