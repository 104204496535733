import { Dispatch } from 'react'

import { get, URL } from 'src/utilities/axios'
import { store } from 'src/redux/store'
import {
  UnbilledVoucherResponse,
  VoucherListResponse,
  VoucherResponse,
} from 'src/modals'
import { VoucherActions } from 'src/redux/types'

export const fetchVoucher =
  (id: number) => async (dispatch: Dispatch<VoucherActions>) => {
    try {
      const res: VoucherResponse = await get(`${URL.VOUCHER_DETAILS}/${id}`)
      if (res.error === null) {
        const { data } = res
        for (let i = 0; i < data.items.length; i++) {
          data.items[i].initial_price = data.items[i].price_unit
        }
        dispatch({
          type: 'SET_VOUCHER',
          payload: data,
        })
      }
    } catch (error) {
      dispatch({
        type: 'SET_VOUCHER_ERROR',
        payload: error,
      })
    }
  }

export const fetchBilledVoucher =
  (limit: number, offset: number, order: string) =>
  async (dispatch: Dispatch<VoucherActions>) => {
    try {
      const res: VoucherListResponse = await get(
        URL.BILLED_DISPATCHED_VOUCHERS,
        {
          limit,
          offset,
          order,
          sort_by: 'scheduled_date',
        },
      )
      if (res.error === null) {
        dispatch({
          type: 'SET_VOUCHER_LIST',
          payload: [
            ...store.getState().voucherReducer.voucherList,
            ...res.data.items,
          ],
        })
        dispatch({
          type: 'SET_VOUCHER_LIST_COUNT',
          payload: res.data.total_count,
        })
      }
    } catch (error) {
      dispatch({
        type: 'SET_VOUCHER_LIST_ERROR',
        payload: error,
      })
    }
  }

export const ResetVoucherList = () => {
  return async (dispatch: Dispatch<VoucherActions>) => {
    dispatch({
      type: 'RESET_VOUCHER_LIST',
    })
  }
}

export const fetchUnbilledVoucherCount =
  () => async (dispatch: Dispatch<VoucherActions>) => {
    try {
      const response: UnbilledVoucherResponse = await get(
        URL.UNBILLED_VOUCHERS_COUNT,
      )
      if (response.error === null) {
        dispatch({
          type: 'SET_UNBILLED_VOUCHER_COUNT',
          payload: response.data.count,
        })
      }
    } catch (error) {
      dispatch({
        type: 'SET_UNBILLED_VOUCHER_COUNT_ERROR',
        payload: String(error),
      })
    }
  }

export const fetchVoucherDetails =
  (id: number, url: string) => async (dispatch: Dispatch<VoucherActions>) => {
    try {
      const res: VoucherResponse = await get(`${url}/${id}`)
      if (res.error === null) {
        const { data } = res
        dispatch({
          type: 'SET_VOUCHER',
          payload: data,
        })
      }
    } catch (error) {
      dispatch({
        type: 'SET_VOUCHER_ERROR',
        payload: error,
      })
    }
  }

export const resetVoucher = () => {
  return async (dispatch: Dispatch<VoucherActions>) => {
    dispatch({
      type: 'RESET_VOUCHER',
    })
  }
}
