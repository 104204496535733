import React, { useState, useCallback, ReactElement, useEffect } from 'react'
import { View, Image, Text, ActivityIndicator } from 'react-native'
import { useDispatch } from 'react-redux'
import { Box, NativeBaseProvider } from 'native-base'
import { ScrollView } from 'react-native-gesture-handler'
import tw from 'tailwind-react-native-classnames'

import STRINGS from 'constants/strings'
import { voucherDateFormat } from 'src/config/voucher'
import {
  formatterWithoutDecimals,
  getEndTruncatedString,
  utcToLocalTime,
  isDecimalNumberValid,
} from 'src/utilities/helpers'
import { ProcGRNDetailsScreenNavigationProp } from 'src/navigations/types'
import { GRNDetails } from 'src/modals/grn'

// components
import VoucherMetadata from 'src/components/voucher/VoucherMetadata'
import Button from 'src/components/form-fields/Button'
import Modal from 'src/components/Modal'
import QCParameterList from 'src/screens/procurement-manager/common/QCParametersList'
import ItemCard from 'src/screens/procurement-manager/common/ItemCard'

// styles
import global from 'styles/global'
import colors from 'styles/colors'
import styles from '../style'

export interface GRNPrimaryDetailsProps {
  route: {
    grnDetails: GRNDetails
    navigation: ProcGRNDetailsScreenNavigationProp
  }
}

const PrimaryDetails = ({ route }: GRNPrimaryDetailsProps) => {
  const dispatch = useDispatch()
  const { grnDetails, navigation } = route
  const [isValueEnteredOnce, setIsValueEnteredOnce] = useState<Boolean>(false)
  const [receivedValue, setReceivedValue] = useState<string | number>('')
  const [modalBody, setModalBody] = useState<ReactElement>(<></>)
  const [showModal, setShowModal] = useState<Boolean>(false)
  const [modalFooter, setModalFooter] = useState<ReactElement>(<></>)
  const [qcParamTextInputs, setQcParamTextInputs] = useState<any>()

  useEffect(() => {
    // To create an initial state for qc parameter text inputs
    const getQCParamTextInputInitialState = () => {
      return (
        grnDetails?.quality_request &&
        Object.keys(grnDetails.quality_request).reduce(
          (initialState, currentKey) => {
            return { ...initialState, [currentKey]: '' }
          },
          {},
        )
      )
    }
    setQcParamTextInputs(getQCParamTextInputInitialState())
  }, [grnDetails.quality_request])

  const hasIncompleteQCParam = useCallback(() => {
    return Object.keys(qcParamTextInputs).some(key => {
      if (qcParamTextInputs[key] === '') return true
      return false
    })
  }, [qcParamTextInputs])

  const isDisabledContinueInwardBtn = useCallback(() => {
    let disabled = true
    if (
      Number(receivedValue) &&
      (grnDetails?.qc_by === 'supplier' ||
        !grnDetails?.quality_request ||
        grnDetails?.quality_request?.NCDEX ||
        (qcParamTextInputs && !hasIncompleteQCParam()))
    )
      disabled = false
    return disabled
  }, [
    receivedValue,
    grnDetails?.qc_by,
    grnDetails?.quality_request,
    qcParamTextInputs,
    hasIncompleteQCParam,
  ])

  const onChangeQCParam = (value: string | number, qcParamKey: string) => {
    setQcParamTextInputs((prevState: { [key: string]: string | number }) => {
      return { ...prevState, [qcParamKey]: value }
    })
  }

  const onQuantityEditAtIndex = (e: string) => {
    if (!isValueEnteredOnce) setIsValueEnteredOnce(true)
    if (isDecimalNumberValid(e)) {
      setReceivedValue(e)
    }
  }

  const isQuantityMismatch = useCallback(() => {
    const orderedQuantity =
      grnDetails?.items?.length && grnDetails?.items[0]?.quantity
    if (!isValueEnteredOnce) return false
    if (orderedQuantity) {
      if (orderedQuantity === Number(receivedValue)) return false
      if (
        orderedQuantity > Number(receivedValue) ||
        orderedQuantity < Number(receivedValue)
      )
        return true
    }
    return true
  }, [grnDetails?.items, isValueEnteredOnce, receivedValue])

  const continueToInward = () => {
    if (
      grnDetails?.items === undefined ||
      grnDetails?.items[0].product_name === undefined ||
      grnDetails?.id === undefined
    )
      return
    dispatch({
      type: 'SET_RECEIVED_QUANTITY_DONE',
      payload: Number(receivedValue),
    })
    dispatch({
      type: 'SET_INWARD_GRN_QC_PARAMS',
      payload: {
        required: grnDetails?.quality_request,
        actual: qcParamTextInputs,
      },
    })
    dispatch({
      type: 'SET_PRODUCT_ID',
      payload: Number(grnDetails?.items[0]?.product_name[0]),
    })
    dispatch({
      type: 'SET_MOVE_ID',
      payload: Number(grnDetails?.items[0]?.id),
    })
    navigation.navigate('UploadDocuments', { id: grnDetails?.id })
  }

  useEffect(() => {
    const modal = (navigationEvent: any) => {
      setModalBody(
        <View style={tw`items-center`}>
          <Image
            style={styles.warning}
            source={require('images/warning.png')}
          />
          <View style={tw`w-full`}>
            <Text
              style={[
                global.textRegular,
                global.textColorGrey,
                styles.warningMsg,
                tw`text-sm text-center leading-5 mx-5 mt-7`,
              ]}
            >
              {STRINGS.PURCHASE_ORDER.WARNING_MSG}
            </Text>
          </View>
        </View>,
      )
      setModalFooter(
        <View
          style={[
            styles.btnContainer,
            tw`mt-12 flex-row justify-between border-t`,
          ]}
        >
          <Button
            title={STRINGS.BUTTON.YES}
            onTap={() => {
              setShowModal(false)
              navigation.dispatch(navigationEvent.data.action)
            }}
            btnType="secondary"
            style={[
              tw`w-1/2 border-0 border-r rounded-bl-lg`,
              styles.btnYesCover,
            ]}
            textStyle={[global.textRegular, global.textSecondaryOrange]}
          />
          <Button
            title={STRINGS.BUTTON.NO}
            onTap={() => setShowModal(false)}
            btnType="secondary"
            style={[tw`w-1/2 border-0 rounded-br-lg`, styles.btnNoCover]}
            textStyle={global.textRegular}
          />
        </View>,
      )
      setShowModal(true)
    }
    const hasAnyChangeQCActualValue = () => {
      return (
        qcParamTextInputs &&
        Object.keys(qcParamTextInputs).some((key: any) => {
          return qcParamTextInputs[key]
        })
      )
    }
    const unsubscribe = navigation.addListener('beforeRemove', (e: any) => {
      if (receivedValue || hasAnyChangeQCActualValue()) {
        e.preventDefault()
        modal(e)
      }
    })
    return unsubscribe
  }, [navigation, receivedValue, qcParamTextInputs])

  return (
    <NativeBaseProvider>
      <Box flex={1}>
        <View style={tw`flex-1 bg-white`}>
          <ScrollView>
            <View style={global.bgLightBlue}>
              <VoucherMetadata
                id={grnDetails?.id || 0}
                attributes={[
                  {
                    attrName: STRINGS.PURCHASE_ORDER.DELIVERY_DATE,
                    attrValue: grnDetails?.delivery_date
                      ? utcToLocalTime(
                          grnDetails.delivery_date,
                          voucherDateFormat,
                        )
                      : getEndTruncatedString(STRINGS.MISCELLANEOUS.LOADING),
                    icon: require('images/calendar.png'),
                    iconStyle: styles.attributeIcons,
                    attrContainerStyle: global.flexFour,
                    attrStyle: tw`text-xs`,
                    attrValueStyle: [
                      tw`text-sm`,
                      global.textBold,
                      styles.paddingTopThree,
                    ],
                  },
                  {
                    attrName: STRINGS.PURCHASE_ORDER.TOTAL_AMOUNT,
                    attrValue:
                      grnDetails?.total_amount ||
                      getEndTruncatedString(STRINGS.MISCELLANEOUS.LOADING),
                    icon: require('images/currency.png'),
                    iconStyle: styles.currencyIcons,
                    attrContainerStyle: global.flexFour,
                    attrStyle: tw`text-xs`,
                    attrValueStyle: [
                      tw`text-sm`,
                      global.textBold,
                      styles.paddingTopThree,
                    ],
                  },
                ]}
                containerStyle={[
                  tw`bg-transparent`,
                  styles.paddingLeftTwentyThree,
                ]}
                lineSeparator={false}
              />
            </View>
            <View style={tw`px-5 flex-col`}>
              {grnDetails?.items?.length ? (
                <>
                  <ItemCard
                    name={
                      grnDetails?.items[0]?.product_name
                        ? grnDetails?.items[0]?.product_name[1].toString()
                        : 'N/A'
                    }
                    customUOM={`${
                      grnDetails?.items[0]?.product_uom
                        ? grnDetails?.items[0]?.product_uom[1]
                        : 'N/A'
                    }`}
                    tax={grnDetails?.items[0]?.tax_display_name || 'N/A'}
                    onPriceEdit={(value: string) =>
                      onQuantityEditAtIndex(value)
                    }
                    imageS3Key={grnDetails?.items[0]?.product_image}
                    errorQuantityMismatch={isQuantityMismatch()}
                    errorMismatchMsg={
                      (grnDetails.items[0]?.quantity || 0) < receivedValue
                        ? STRINGS.GRNS.RECEIVED_QUANTITY_MORE_THAN_DEMANDED
                        : STRINGS.GRNS.QUANTITY_MISMATCH
                    }
                    detailColumns={[
                      {
                        key: STRINGS.GRNS.UNIT_PRICE,
                        value: `₹${grnDetails?.items[0]?.price_unit}`,
                      },
                      {
                        key: STRINGS.GRNS.DEMAND,
                        value: grnDetails?.items[0]?.quantity || 0,
                      },
                    ]}
                    editableField={{
                      key: STRINGS.GRNS.RECEIVED,
                      value: receivedValue,
                    }}
                    isTaxAmountVisible
                    hideReceivedCurrency
                    totalAmount={formatterWithoutDecimals.format(
                      Number(receivedValue) *
                        (grnDetails?.items[0]?.price_unit !== undefined
                          ? grnDetails?.items[0]?.price_unit
                          : 0),
                    )}
                    containerStyle={styles.paddingTopThirty}
                  />
                  <View style={[styles.lineSeperator, tw`border-t`]} />
                  {!grnDetails?.quality_request.NCDEX && qcParamTextInputs && (
                    <QCParameterList
                      qualityRequest={grnDetails?.quality_request}
                      qcResponsibility={grnDetails?.qc_by || 'N/A'}
                      {...{ qcParamTextInputs, onChangeQCParam }}
                    />
                  )}
                </>
              ) : (
                <ActivityIndicator size="large" color={colors.primaryGreen} />
              )}
            </View>
          </ScrollView>
        </View>
        <View
          style={[
            tw`bg-white py-5 px-5 border-t`,
            styles.continueToInwardGRNContainer,
          ]}
        >
          <Button
            title={STRINGS.PURCHASE_ORDER.CONTINUE_TO_INWARD_GRN}
            style={[tw`rounded-lg`, styles.continueToInwardGRNButton]}
            onTap={continueToInward}
            disable={isDisabledContinueInwardBtn()}
          />
        </View>
      </Box>
      {showModal && (
        <Modal
          body={modalBody}
          footer={modalFooter}
          close={() => setShowModal(false)}
          style={tw`p-0`}
        />
      )}
    </NativeBaseProvider>
  )
}

export default PrimaryDetails
