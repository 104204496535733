import { StyleSheet } from 'react-native'
import colors from 'styles/colors'

const styles = StyleSheet.create({
  name: {
    color: colors.primaryTextBlack,
  },
  metaData: {
    color: colors.secondaryTextBlue,
  },
  headerIcon: {
    width: 12,
    height: 12,
    marginRight: 6,
  },
  totalAmount: {
    color: colors.secondaryTextBlue,
  },
  productNumber: {
    color: colors.secondaryTextBlue,
  },
  footer: {
    paddingVertical: 12,
  },
  footerBtn: {
    height: 45,
    minHeight: 50,
  },
  btnEditContainer: {
    height: 24,
  },
  btnEdit: {
    color: colors.secondaryOrange,
  },
  warning: {
    width: 50,
    height: 44,
    marginTop: 10,
  },
  warningMsg: {
    marginTop: 40,
  },
  btnContainer: {
    borderTopWidth: 1,
    borderTopColor: colors.lightGrey,
  },
  btnYesCover: {
    borderWidth: 0,
    borderColor: colors.lightGrey,
  },
  btnNoCover: {
    borderWidth: 0,
    borderLeftWidth: 1,
    borderColor: colors.lightGrey,
  },
  btnNo: {
    color: colors.secondaryOrange,
  },
  successIcon: {
    width: 200,
    height: 200,
  },
})

export default styles
