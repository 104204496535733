import React, { ReactElement } from 'react'
import { View, Text, Image } from 'react-native'

// styles
import globals from 'styles/global'
import styles from './styles'

interface SummaryTileProps {
  amountType: string
  textStyle?: object
  displayValue: number | string
  displayValueStyle?: object
  containerStyle?: object
  backgroundImg?: ReactElement
}

const SummaryTile = ({
  amountType,
  textStyle,
  displayValue,
  displayValueStyle,
  containerStyle,
  backgroundImg,
}: SummaryTileProps) => (
  <View
    style={[
      globals.hPaddingTwenty,
      globals.borderRadiusEight,
      globals.vMarginSixteen,
      styles.container,
      containerStyle,
    ]}
  >
    {backgroundImg || (
      <Image
        source={require('images/cloud.png')}
        style={[globals.positionAbsolute, styles.cloud]}
      />
    )}
    <Text
      style={[
        globals.textRegular,
        globals.fontEighteen,
        globals.textPrimaryBlack,
        styles.zIndexOne,
        textStyle,
      ]}
    >
      {amountType}
    </Text>
    <Text
      style={[
        globals.textBold,
        globals.fontTwentyEight,
        globals.textPrimaryBlack,
        styles.topMargin,
        styles.zIndexOne,
        displayValueStyle,
      ]}
    >
      {displayValue}
    </Text>
  </View>
)

export default SummaryTile
