import React from 'react'
import { View, Image } from 'react-native'
import { useSelector } from 'react-redux'

import { ApplicationState } from 'src/redux/types'
import STRING from 'constants/strings'
import { HomeProps } from 'src/navigations/types'
import { formatter, formatterWithoutDecimals } from 'src/utilities/helpers'

// components
import TileButton from 'src/components/home/TileButton'
import SummaryTile from 'src/components/home/SummaryTile'

// styles
import globals from 'styles/global'
import styles from './style'

const SalesBiller = ({ navigation }: HomeProps) => {
  const { outstandingAmount, unbilledDVCount } = useSelector(
    (state: ApplicationState) => state.userReducer,
  )

  const getFormattedOutstanding: () => string | number = () => {
    if (outstandingAmount) {
      if (Math.round(outstandingAmount / 10000000) > 0) {
        return `${formatter.format(outstandingAmount / 10000000)} Cr`
      }
      if (Math.round(outstandingAmount / 100000) > 0) {
        return `${formatter.format(outstandingAmount / 100000)} L`
      }
      return formatterWithoutDecimals.format(outstandingAmount)
    }
    return 0
  }

  return (
    <View>
      <View style={globals.directionRow}>
        <SummaryTile
          amountType={STRING.HOME.OUTSTANDING_INVOICES}
          textStyle={[
            globals.fontFourteen,
            globals.textAlignCenter,
            styles.textStyle,
          ]}
          displayValue={getFormattedOutstanding()}
          displayValueStyle={[globals.fontTwentyFour, globals.textAlignCenter]}
          containerStyle={styles.amountTile}
          backgroundImg={
            <Image
              source={require('images/cloud.png')}
              style={[globals.positionAbsolute, styles.bgLeftCard]}
            />
          }
        />
        <SummaryTile
          amountType={STRING.HOME.UNBILLED_DISPATCH_VOUCHERS}
          textStyle={[
            globals.fontFourteen,
            globals.textAlignCenter,
            styles.textStyle,
          ]}
          displayValue={unbilledDVCount || 0}
          displayValueStyle={[globals.fontTwentyFour, globals.textAlignCenter]}
          containerStyle={styles.amountTile}
          backgroundImg={
            <Image
              source={require('images/cloud.png')}
              style={[globals.positionAbsolute, styles.bgRightCard]}
            />
          }
        />
      </View>
      <View style={globals.hMarginTwenty}>
        <View
          style={[
            globals.directionRow,
            globals.justifyContentSpaceBetween,
            globals.vMarginEight,
          ]}
        >
          <TileButton
            icon={require('images/create-order.png')}
            iconStyle={styles.tileButtonIcon}
            title={STRING.HOME.CREATE_INVOICE}
            onPress={() => navigation.navigate('UnbilledDispatchVoucher')}
          />
          <TileButton
            icon={require('images/regenerate-invoice.png')}
            iconStyle={styles.tileButtonIcon}
            title={STRING.HOME.REGENERATE_INVOICE}
            onPress={() => navigation.navigate('RegenerateInvoice')}
          />
          <TileButton
            icon={require('images/create-note.png')}
            iconStyle={styles.tileButtonIcon}
            title={STRING.HOME.CREATE_CREDIT_NOTE}
            onPress={() => navigation.navigate('CreditNoteInvoice')}
          />
        </View>
        <View
          style={[
            globals.directionRow,
            globals.justifyContentSpaceBetween,
            globals.vMarginEight,
          ]}
        >
          <TileButton
            icon={require('images/order-history.png')}
            iconStyle={styles.tileButtonIcon}
            title={STRING.HOME.INVOICE_HISTORY}
            onPress={() => navigation.navigate('VoucherList')}
          />
        </View>
      </View>
    </View>
  )
}

export default SalesBiller
