import { StyleSheet, Dimensions } from 'react-native'

const windowWidth = Dimensions.get('window').width

const styles = StyleSheet.create({
  tileContainer: {
    width: windowWidth / 2 - 30,
  },
  outstandingContainerStyle: {
    paddingTop: 43,
    paddingBottom: 44,
    borderRadius: 10,
  },
  cloudBackground: {
    width: 186,
  },
  paddingTopFifty: {
    paddingTop: 50,
  },
  textStyle: {
    width: 90,
  },
})

export default styles
